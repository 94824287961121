import React, { useContext, useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import swal from "sweetalert";

import Layout from "../Layout";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";
import { _DEF } from "../../../modules/config";

import { SearchInput, SelectForm, InputForm, SegmentedForm, TextForm, DatePicker, AddressForm } from "../../../components/UI/Form";
import Paging, { ToPage, GetParams } from "../../../components/UI/Paging";
import SNEditor from "../../../components/UI/SNEditor";
import { Separator } from "../../../components/UI/Wrapper";

import  DualListBox  from 'react-dual-listbox'; 

const LOC_STATUS = [
    { label: "대기", value: "W" },
    { label: "정상", value: "Y" },
    { label: "사용안함", value: "N" },
];

export default (props) => {

    const { cfg } = useContext(AppContext);
    const { history, location, match } = props;
    const cp = { history, location, match };

    const [mode, setMode] = useState("");
    const [keyword, setKeyword] = useState("");
    const [list, setList] = useState([]);
    const [sites, setSites] = useState([]);
    const [paging, setPaging] = useState(null);
    const [siteIdx, setSiteIdx] = useState(cfg.idx);

    const onAddClick = () => {
        setMode("ADD");
    };

    const onModify = (idx) => {
        setMode(idx);
    };

    const loadPage = (data) => {
        data.siteIdx = siteIdx;
        const req = { path: "/v1/a/location/list", data };
        _API.get(req).then((res) => {
            const { list, siteList, paging } = res.data;
            setList(list);
            setSites(siteList);
            setPaging(paging);
        });
    };

    const movePage = (page) => {
        ToPage(location, history, { keyword, page });
    };

    const onSearch = (e) => {
        ToPage(location, history, { keyword, page: 1 });
    };

    const onRefresh = () => {
        loadPage({ keyword, page: paging.pageNo });
    };

    const onMemberChange = () => {
        setMode("");
        onRefresh();
    };

    useEffect(() => {
        const params = GetParams(location);
        if (params) {
            setKeyword(params.keyword);
            loadPage(params);
        } else {
            loadPage({ page: 1 });
        }
    }, [location]);

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-8">
                    <h2>장소 목록</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/admin">HOME</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to={`${location.pathname}`}>장소 관리</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>장소 목록</strong>
                        </li>
                    </ol>
                </div>
                <div className="col-sm-4">
                    <div className="title-action">
                        <button type="button" className="btn btn-primary" onClick={onAddClick.bind(this)}>
                            <i className="fa fa-plus"></i> 추가
                        </button>
                    </div>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className={cx({ "col-md-8": mode, "col-md-12": !mode })}>
                        <LocationList {...cp} onModify={onModify} sites={sites} />
                    </div>
                    {mode && (
                        <div className="col-md-4">
                            <LocationForm idx={mode} onChange={onModify} sites={sites} />
                        </div>
                    )}
                </div>
               
            </div>
        </Layout>
    );
};

const LocationList = (props) => {
    const { history, onModify, sites } = props;
    const { cfg } = useContext(AppContext);
    const [keyword, setKeyword] = useState("");
    const [list, setList] = useState([]);
    const [paging, setPaging] = useState(null);
    const [siteIdx, setSiteIdx] = useState(cfg.idx);

    const loadList = (page) => {
        _API.get({
            path: "/v1/a/location/list",
            data: {
                siteIdx,
                keyword,
            },
        }).then((res) => {
            const { paging, list } = res.data;
            setList(list);
            setPaging(paging);
        });
    };

    const onReceivedMessage = (e) => {
        _U.parseMessage(e).then((data) => {
            switch (data.code) {
                case "SITE-LOCATION-CHANGED":
                    loadList(1);
                    break;
            }
        });
    };

    const onModifyClick = (idx) => {
        onModify && onModify(idx);
    };
    const onDeleteClick = (idx) => {
        _API.delete({
            path: "/v1/a/location",
            data: {
                siteIdx,
                idx,
            },
        }).then((res) => {
            loadList(1);
        });
    };

    useEffect(() => {
        loadList(1);
        window.addEventListener("message", onReceivedMessage);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, [siteIdx,keyword]);
    
    const onChangeState = (item, col, val, e) => {
        if (e) e.preventDefault();

        swal({
            title: `장소관리`,
            text: `상태를 변경하시겠습니까?`,
            icon: "info",
            buttons: ["취소", "예"],
            dangerMode: val === "D" ? true : false,
        }).then((ok) => {
            if (ok) {
                _API.post({
                    path: "/v1/a/location/status",
                    data: {
                        idx: item.idx
                        , status: val
                    },
                }).then((res) => {
                    loadList(paging?.pageNo || 1)
                });
            }
        });
    }

    return (
        <div className="ibox">
            <div className="ibox-title">장소 목록</div>
            <div className="ibox-content ibox-heading">
                <div className="row">
                    <div className="col-md-4">
                        {/* <SelectForm title="사이트" name="siteIdx" items={sites.map( (site) => { return { label : site.title, value : site.idx} } )} value={keyword} onChange={(e) => {setKeyword(e.target.value)}} nullTitle="사이트 선택" /> */}
                        {/* <SearchInput value={keyword} onChange={(e) => setKeyword(e.target.value)} onClick={loadList} placeholder="검색어를 입력해 주세요." /> */}
                    </div>
                </div>
            </div>
            <div className="ibox-content">
                <div className="table-responsive">
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th>장소명</th>
                                <th className="text-center">연락처</th>
                                <th className="text-center">우편번호</th>
                                <th className="text-center">주소</th>
                                <th className="text-center">상세 주소</th>
                                <th className="text-center">GEO</th>
                                <th className="text-center">상태</th>
                                {/* <th className="text-center">세부장소</th> */}
                                <th className="text-center">편집</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list?.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        {/* <Link to={`/a/site/locations/${item.idx}`}>{item.title}</Link> */}
                                        {item.title}
                                    </td>
                                    <td className="text-center">{item.phone}</td>
                                    <td className="text-center">{item.zipCode}</td>
                                    <td className="text-center">{item.addr}</td>
                                    <td className="text-center">{item.addrDtl}</td>
                                    <td className="text-center">
                                        {item.lat && item.lng ? (
                                            <a href={"https://www.google.com/maps/search/?api=1&query=" + item.addr} target="_blank">
                                                <i className="fa fa-map-marker"></i>
                                            </a>
                                        ) : (
                                            "-"
                                        )}
                                    </td>
                                    <td className="text-center">
                                        <div className="btn-group">
                                            <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "Y", "btn-success": item.status === "Y" })} onClick={onChangeState.bind(this, item, "status", "Y")}>
                                                정상
                                            </button>
                                            <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "N", "btn-success": item.status === "N" })} onClick={onChangeState.bind(this, item, "status", "N")}>
                                                미사용
                                            </button>
                                            <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "D", "btn-success": item.status === "D" })} onClick={onChangeState.bind(this, item, "status", "D")}>
                                                삭제
                                            </button>
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <div className="btn-group">
                                            <button type="button" className="btn btn-xs btn-warning" onClick={onModifyClick.bind(this, item.idx)}>
                                                <i className="fa fa-edit"></i> 수정
                                            </button>
                                            <Link to={`/admin/location/${item.idx}/detail/list`} className="btn btn-xs btn-primary">
                                                <i className="fa fa-edit"></i> 세부장소 편집
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

const LocationForm = (props) => {
    const { idx, onChange, sites } = props;
    const { cfg } = useContext(AppContext);
    const [form, setForm] = useState({status:'Y'});
    const [content, setContent] = useState("");
    const [siteIdx, setSiteIdx] = useState(cfg.idx)

    const onFormChange = (key, value) => {
        setForm({ ...form, [key]: value });
    };

    const onAddressChange = (addr) => {
        setForm({ ...form, ...addr });
    };

    const loadData = () => {
        if (idx === "ADD") {
            setForm({status:'Y'});
            return;
        }
        _API.get({
            path: "/v1/a/location/info",
            data: {
                siteIdx,
                idx,
            },
        }).then((res) => {
            setForm({...res.data, latitude:res.data.lat, longitude:res.data.lng, zipcode:res.data.zipCode});
            setContent(res.data.transport);
        });
    };

    const onSave = () => {
        const data = { ...form, siteIdx };
        // if(!data.siteIdx) data.siteIdx = siteIdx;
        if (idx !== "ADD") {
            data.idx = idx;
        }
        _API.post({
            path: "/v1/a/location/save",
            data: {...data, transport:content},
        }).then((res) => {
            swal({ title: "알림", text: `장소가 ${idx === "ADD" ? "등록" : "수정"} 되었습니다.` }).then(() => {
                onChange && onChange();
                _U.postMessage("SITE-LOCATION-CHANGED");
            });
        });
    };

    useEffect(() => {
        loadData();
    }, [siteIdx, idx]);

    return (
        <div className="ibox">
            <div className="ibox-title">장소 {idx === "ADD" ? "등록" : "수정"}</div>
            <div className="ibox-content">
                {/* <SelectForm title="사이트" name="siteIdx" items={sites.map( (site) => { return { label : site.title, value : site.idx} } )} value={form?.siteIdx} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} nullTitle="사이트 선택" /> */}
                <Separator />
                <InputForm title="장소명" name="title" placeholder="장소명" value={form?.title} onChange={(e) => onFormChange(e.target.name, e.target.value)} />
                <InputForm title="연락처" name="phone" placeholder="연락처 (대표 연락처)" value={form?.phone} onChange={(e) => onFormChange(e.target.name, e.target.value)} />
                <AddressForm title="주소" values={form} isGeo onChange={onAddressChange} />
                {/* <Separator />
                <SegmentedForm title="상태" items={LOC_STATUS} value={form?.status} onClick={(v) => onFormChange("status", v)} /> */}
                <Separator />
                <h4>대중교통안내</h4>
                <SNEditor content={content} onChange={(c) => setContent(c)} height="200" />
                <Separator />
                <div className="row">
                    <div className="col-md-12 text-right">
                        <button type="button" className="btn btn-primary" onClick={onSave}>
                            <i className="fa fa-save"></i> {idx === "ADD" ? "등록" : "수정"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};