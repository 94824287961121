import React, { Fragment, useContext, useEffect, useState } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

import styled, { css } from "styled-components";
import swal from "sweetalert";
import moment from "moment";
import qs from "query-string";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U, _CK } from "../../../modules/utils";

import { ConnectionButton, ProgramButton, H2, H3, ItemEmpty } from "./UI/Components";

export default (props) => {
    const { cfg, auth, iam } = useContext(AppContext);
    const { location } = props;
    const event = cfg.event || null;
    const [channelList, setChannelList] = useState([]);

    if (!event) {
        return null;
    }

    const { startDate, endDate, channel } = event;
    if (!startDate || !endDate || channel?.length === 0) {
        return null;
    }
    const [channelDateList, setChannelDateList] = useState([]);
    const [selectDate, setSelectDate] = useState(qs.parse(location?.search)?.lectureDate ? qs.parse(location?.search)?.lectureDate : moment().format("YYYYMMDD"));

    const isExsitsChannel = () => {
        if (channel.filter((item) => item.progressDate == selectDate).length === 0) {
            console.log("당일 진행 채널 없음 : " + selectDate);
            return false;
        }

        return true;
    };

    const onEnter = (item) => {
        if (!_CK.isLogin() && event?.loginYn === "Y") {
            swal("로그인 후 이용이 가능합니다").then(() => {
                window.location.reload();
            });
        } else {
            if (item?.lectures?.length > 0) {
                const startDate = item.progressDate + item.lectures[0].sessionStartTime.replace(":", "") + "00";
                const endDate = item.progressDate + item.lectures[item.lectures?.length - 1].sessionEndTime.replace(":", "") + "00";
                const now1 = moment().add(1, 'hours').format("YYYYMMDDHHmmss");
                const now2 = moment().add(-1, 'hours').format("YYYYMMDDHHmmss");
                const toDay = moment().format("YYYYMMDD");
                if (item.progressDate == toDay) {
                    //2021-10-20 도대표님 요청으로 당일 채널 시간관계 없이 입장가능
                    window.open(`/#/channel/${item.idx}`);
                    _U.postMessage("CLOSE-MODAL");
                }
                else if (startDate <= now1 && endDate >= now2) {
                    window.open(`/#/channel/${item.idx}`);
                    _U.postMessage("CLOSE-MODAL");
                } else {
                    swal("이용가능 시간이 아닙니다.");
                }
            } else {
                swal("강좌정보가 없습니다.");
            }
        }
    };

    const onEnterReplay = (item) => {
        if (!_CK.isLogin() && event?.loginYn === "Y") {
            swal("로그인 후 이용이 가능합니다").then(() => {
                window.location.reload();
            });
        } else {
            window.open(`/#/channel/replay/${item.idx}`);
            _U.postMessage("CLOSE-MODAL");
        }
    };

    useEffect(() => {
        const tmpChannelDateList = channel?.map(item => { return item.progressDate });
        tmpChannelDateList.push(moment().format("YYYYMMDD"));
        tmpChannelDateList.sort((a, b) => (a > b ? 1 : a < b ? -1 : 0));
        setChannelDateList(tmpChannelDateList);
    }, []);

    useEffect(() => {
        if (isExsitsChannel()) {
            const channels = channel.filter((item) => {
                if (item.progressDate == selectDate) {
                    return item;
                }
            });

            setChannelList(channels);
        }
    }, [selectDate]);

    const getDateTab = () => {
        const result = [];
        const tmpChannelDateList = [];
        for (let i in channelDateList) {
            const item = channelDateList[i];
            let dup = 0;
            for (let j in tmpChannelDateList) {
                if (tmpChannelDateList[j] === item) {
                    dup++;
                }
            }
            if (dup === 0) {
                tmpChannelDateList.push(item);
            }
        }

        for (let i in tmpChannelDateList) {
            const m = tmpChannelDateList[i];
            result.push(
                <DateTab
                    ison={selectDate == m}
                    key={i}
                    onClick={() => {
                        setSelectDate(m);
                    }}
                >
                    {moment(m).format("YYYY.MM.DD(ddd)")}
                </DateTab>
            );
        }

        // if (isMobile) {
        //     result.push(<ItemEmpty length={tmpChannelDateList?.length || 0} number={2} component={<DateTab />} />);
        // }

        return result;
    };

    return (
        <>
            <BaseWrapper>
                {/* <H2 style={{ textAlign: "center" }}>{moment().format("YYYY. MM. DD(ddd)")}</H2> */}
                <TabWrapper>{getDateTab()}</TabWrapper>
                <Wrapper>
                    {isExsitsChannel() ? (
                        <>
                            {channelList.map((item, i) => (
                                <Article key={i}>
                                    <ArticleInfo>
                                        <span>{item.title}</span>
                                        <Date>
                                            {moment(item.progressDate).format("YYYY.MM.DD(ddd) ")}
                                            {item.lectures?.length > 0 && item.lectures[0].sessionStartTime + "~" + item.lectures[item.lectures?.length - 1].sessionEndTime}
                                        </Date>
                                        <p>{item.description}</p>
                                    </ArticleInfo>
                                    <ButtonGroup>
                                        {item.progressDate == moment().format("YYYYMMDD") && (
                                            <>
                                                <ConnectionButton onClick={onEnter.bind(this, item)}>입장하기</ConnectionButton>
                                                <div style={{ padding: "5px" }}></div>
                                            </>
                                        )}
                                        {item.progressDate < moment().format("YYYYMMDD") && item.video2FileIdx && (
                                            <>
                                                <ConnectionButton onClick={onEnterReplay.bind(this, item)}>다시보기</ConnectionButton>
                                                <div style={{ padding: "5px" }}></div>
                                            </>
                                        )}
                                        <ProgramButton onClick={(e) => _U.postMessage("AGENDA-LECTURE", { cid: item.idx, url: "CONTENT" })}>Program</ProgramButton>
                                    </ButtonGroup>
                                </Article>
                            ))}
                            <ItemEmpty length={channelList?.length} number={3} component={<Article />} />
                        </>
                    ) : (
                        <EmptyWrapper>
                            <H3>오늘 진행 예정 세션이 없습니다.</H3>
                        </EmptyWrapper>
                    )}
                </Wrapper>
            </BaseWrapper>
        </>
    );
};

const BaseWrapper = styled.div`
    width: 1200px;
    margin: 0 auto;
    padding-top: 3rem;
    // min-height: calc(100% - 192px);
    height: auto;

    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                width: 100%;
                padding: 1rem;
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                width: 100%;
                padding: 1rem;
            }
        `;
        }
    }}

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        width: 100%;
        padding: 1rem;
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: space-around;

    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                flex-direction: column;
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                flex-direction: column;
            }
        `;
        }
    }}

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        flex-direction: column;
    }
`;

const Date = styled.div`
    margin-top: 5px;
    font-family: ${(props) => props.theme.fontFamily.medium};
    font-size: 12px;
    text-align: left;
    color: ${(props) => props.theme.basic.color.primary};
`;

const Article = styled.div`
    align-items: center;
    flex-basis: 30%;
    background-color: ${(props) => props.theme.basic.bg.background};
    margin-bottom: 25px;

    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                flex-basis: 100%;
                flex: 1;
                width: 100%;
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                flex-basis: 100%;
                flex: 1;
                width: 100%;
            }
        `;
        }
    }}

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        flex-basis: 100%;
        flex: 1;
        width: 100%;
    }
`;

const ArticleInfo = styled.div`
    border: solid 0px ${(props) => props.theme.basic.line.default};
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 15px;

    box-shadow: 2.5px 4.3px 15px 0 rgba(0, 0, 0, 0.15);

    & span {
        font-family: ${(props) => props.theme.fontFamily.medium};
        font-size: 20px;
        color: ${(props) => props.theme.basic.color.primary};
        min-height: 60px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    & p {
        margin-top: 14px;
        font-family: ${(props) => props.theme.fontFamily.medium};
        font-size: 14px;
        color: ${(props) => props.theme.basic.color.primary};

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
`;

const EmptyWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ButtonGroup = styled.div`
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    width: 100%;

    & button {
        position: relative;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    }
`;

const TabWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom:2rem;
`;
const DateTab = styled.div`
    flex: 1;
    margin-right: 10px;
    font-size: 16px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 14px;
    cursor: pointer;

    ${(props) =>
        props.ison
            ? `
    color: ${props.theme.basic.color.white};    
    background-color: ${props.theme.basic.bg.default};
    border-radius:21px;
    `
            : `
    color: ${props.theme.basic.color.description};
    `}

    font-family: ${(props) => props.theme.fontFamily.regular};
    text-align: center;
    // :last-child {
    //     margin-right: 0px;
    // }
`;