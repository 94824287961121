import React from "react";
import cx from "classnames";
import qs from "query-string";

import { _U } from "../../modules/utils";

export default class Paging extends React.Component {
    handlePageClicked(page) {
        window.scrollTo( 0, 0 );
        this.props.pageCallback && this.props.pageCallback(page < 1 ? 1 : page);
    }

    render() {
        const { paging } = this.props;
        const pagingItems = [];
        if (paging) {
            for (let i = paging.startPageNo; i <= paging.endPageNo; i++) {
                pagingItems.push(
                    <button key={i} type="button" className={cx({ btn: true, "btn-white": true, active: paging.pageNo === i })} onClick={this.handlePageClicked.bind(this, i)}>
                        {i}
                    </button>
                );
            }
        }

        return (
            <div className="text-center">
                {paging && (
                    <div className="btn-group" style={{ marginTop: "15px" }}>
                        <button type="button" className="btn btn-white" onClick={this.handlePageClicked.bind(this, 1)}>
                            ««
                        </button>
                        <button type="button" className="btn btn-white" onClick={this.handlePageClicked.bind(this, paging.prevBlockNo)}>
                            «
                        </button>
                        {pagingItems}
                        <button type="button" className="btn btn-white" onClick={this.handlePageClicked.bind(this, paging.nextBlockNo)}>
                            »
                        </button>
                        <button type="button" className="btn btn-white" onClick={this.handlePageClicked.bind(this, paging.finalPageNo)}>
                            »»
                        </button>
                    </div>
                )}
            </div>
        );
    }
}

export const ToPage = (location, history, params) => {
    const url = `${location.pathname}?q=${_U.encode(params)}`;
    history.push(url);
};

export const GetParams = (location) => {
    const search = location?.search;
    if (!search) return null;

    const p = qs.parse(search);
    if (!p.q) {
        return null;
    }

    return _U.decode(p.q);
};
