import React, { useContext } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";

import Layout from "../Layout";

export default (props) => {
    const handleCompleteClicked = () => {
        swal("Complete");
    };
    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-8">
                    <h2>샘플</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/dashboard">HOME</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/dev/dashboard">개발자</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>샘플</strong>
                        </li>
                    </ol>
                </div>
                <div className="col-sm-4">
                    <div className="title-action">
                        <button type="button" className="btn btn-primary" onClick={handleCompleteClicked.bind(this)}>
                            <i className="fa fa-check"></i> 완료
                        </button>
                    </div>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox">
                            <div className="ibox-title">샘플</div>
                            <div className="ibox-content">샘플</div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
