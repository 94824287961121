import React, { useContext, useState, useEffect, Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import swal from "sweetalert";
import Modal from "react-modal";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";

import Layout from "../Layout";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";
import { _DEF } from "../../../modules/config";

import { SearchInput, SelectForm, InputForm, SegmentedForm, TimePicker } from "../../../components/UI/Form";
import Paging, { ToPage, GetParams } from "../../../components/UI/Paging";
import { Separator } from "../../../components/UI/Wrapper";
import { Theme } from "../../../components/GlobalStyles";
import { ModalCloseButton } from "../../../components/UI/Button";


export default (props) => {

    const { history, location, match } = props;
    const { cfg } = useContext(AppContext);

    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [eventIdx, setEventIdx] = useState("");
    const [channelIdx, setChannelIdx] = useState("");
    const [keyword, setKeyword] = useState("");
    const [list, setList] = useState([]);
    const [paging, setPaging] = useState(null);
    const [siteList, setSiteList] = useState([]);
    const [eventList, setEventList] = useState([]);
    const [channelList, setChannelList] = useState([]);
    const [participantInfo, setParticipantInfo] = useState(null);

    const [exportExcelList, setExportExcelList] = useState([]);
    const [eventInfo, setEventInfo] = useState({});
    let excel = useRef(null);

    const [statistics, setStatistics] = useState();
    const [attendSessionList, setAttendSessionList] = useState();
    const [isStatisticsDetail, setIsStatisticsDetail] = useState(false);
    const [getStatistics, setGetStatistics] = useState(false);

    const loadList = (page) => {
        if(!eventIdx){
            swal({ title: "알림", text: "이벤트를 선택해 주세요." });
            return;
        }
        const data = {
            siteIdx,
            eventIdx,
            channelIdx,
            keyword,
            page,
        };
        const req = { path: "/v1/a/event/attend/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setList(list);
            setPaging(paging);
        });
    };

    const loadEventList = (siteIdx) => {
        const data = {
            siteIdx,
        };
        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setEventList(list);
        });
    };

    const loadChannelList = (eventIdx) => {
        const data = {
            eventIdx,
            sort: "TERM_DATE"
        };
        const req = { path: "/v1/a/event/channel/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setChannelList(list);
        });
    };

    const movePage = (page) => {
        loadList(page);
    };

    const onSearch = (e) => {
        if (e) e.preventDefault();
        loadList(1);
    };

    useEffect(() => {
        // loadList(1);
        loadEventList(siteIdx);
    }, []);


    useEffect(() => {
        if (eventIdx) loadChannelList(eventIdx);
        else setChannelList([]);

        setChannelIdx("");
    }, [eventIdx]);

    useEffect(() => {
        loadList(1);
    }, [eventIdx, channelIdx]);

    useEffect(() => {
        loadStatistics();
    }, [channelIdx]);

    const onDetail = (item) => {
        setParticipantInfo(item);
    }

    const saveAfter = () => {
        setParticipantInfo(null);
        loadList(1);
    }

    const onExcelDown = async () => {
        if (!siteIdx || !eventIdx) {
            swal({ title: "알림", text: "이벤트를 선택해 주세요." });
            return;
        }
        const data = {
            siteIdx,
            eventIdx,
            channelIdx,
            keyword,
        };
        const result = await _API.get({ path: `/v1/a/event/attend/list`, data });
        if (result && result.data) {
            const excelList = result.data.list;
            setExportExcelList(excelList || null);
            if (excelList) {
                excel && excel.current.save();
            }
        }
    };

    const onSessionExcelDown = async () => {
        if (!siteIdx || !eventIdx) {
            swal({ title: "알림", text: "이벤트를 선택해 주세요." });
            return;
        }
        const data = {
            siteIdx,
            eventIdx,
            channelIdx,
        };
        const result = await _API.get({ path: `/v1/a/event/session/attend/list`, data });
        if (result && result.data) {
            const excelList = result.data.list;
            setExportExcelList(excelList || null);
            if (excelList) {
                excel && excel.current.save();
            }
        }
    };

    const onLectureExcelDown = async () => {
        if (!siteIdx || !eventIdx) {
            swal({ title: "알림", text: "이벤트를 선택해 주세요." });
            return;
        }
        const data = {
            siteIdx,
            eventIdx,
            channelIdx,
        };
        const result = await _API.get({ path: `/v1/a/event/lecture/attend/list`, data });
        if (result && result.data) {
            const excelList = result.data.list;
            setExportExcelList(excelList || null);
            if (excelList) {
                excel && excel.current.save();
            }
        }
    };

    const loadStatistics = async () => {
        if (!eventIdx || !channelIdx) return;
        setGetStatistics(true);
        setStatistics(null);
        setAttendSessionList(null);
        const data = {
            eventIdx,
            channelIdx,
        };
        const result = await _API.get({ path: `/v1/a/event/participant/statistics`, data });
        if (result?.data) {
            setStatistics(result.data.statistics);
            setAttendSessionList(result.data.sessionList);
        }
        setGetStatistics(false);
    }

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-7">
                    <h2>출결관리</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/admin">HOME</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>출결관리</strong>
                        </li>
                    </ol>
                </div>
                <div className="col-sm-5">
                    <div className="title-action">
                        <button
                            type="button"
                            className="btn btn-success ml-1"
                            onClick={(e) => {
                                onSessionExcelDown();
                            }}
                        >
                            <i className="fa fa-file-excel-o"></i> 세션별출결 엑셀다운
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary ml-1"
                            onClick={(e) => {
                                onLectureExcelDown();
                            }}
                        >
                            <i className="fa fa-file-excel-o"></i> 강좌별출결 엑셀다운
                        </button>
                        <button
                            type="button"
                            className="btn btn-warning ml-1"
                            onClick={(e) => {
                                onExcelDown();
                            }}
                        >
                            <i className="fa fa-file-excel-o"></i> 엑셀다운
                        </button>
                    </div>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className={cx({ "col-md-12": true, "col-md-4": false })}>
                        <div className="ibox">
                            <div className="ibox-title">참석자목록</div>
                            <div className="ibox-content ibox-heading">
                                <div className="row">
                                    <div className="col-md-2">
                                        <select value={eventIdx} onChange={(e) => setEventIdx(e.target.value)} className="form-control">
                                            <option value="">이벤트선택</option>
                                            {eventList?.map((item, i) => (
                                                <option key={i} value={item.eventIdx}>
                                                    {item.title}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-2">
                                        <select value={channelIdx} onChange={(e) => setChannelIdx(e.target.value)} className="form-control">
                                            <option value="">채널선택</option>
                                            {channelList?.map((item, i) => (
                                                <option key={i} value={item.idx}>
                                                    {item.title + " (" + moment(item.progressDate).format("YYYY-MM-DD") + ")"}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <SearchInput value={keyword} onChange={(e) => setKeyword(e.target.value)} onClick={onSearch} placeholder="참석자를 입력해 주세요." />
                                    </div>
                                </div>
                                {getStatistics && (
                                    <div >* 집계정보 조회중..</div>
                                )}
                                {statistics && (
                                    <div className="row">
                                        <div className="col-sm-10 table-responsive">
                                            <table className="table table-striped table-hover">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center">로그인</th>
                                                        <th className="text-center">룸 입장</th>
                                                        <th className="text-center">최종 퇴장</th>
                                                        <th className="text-center">총 인원</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="text-center">{statistics.loginCnt}</td>
                                                        <td className="text-center">{statistics.inCnt}</td>
                                                        <td className="text-center">{statistics.outCnt}</td>
                                                        <td className="text-center">{statistics.totalCnt}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col-sm-2" style={{ display: "flex", alignItems: "center" }}>
                                            <button type="button" className="btn btn-xs btn-warning" onClick={(e) => { console.log(attendSessionList); setIsStatisticsDetail(true); }}>
                                                <i className="fa fa-eye"></i> 상세통계
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="ibox-content">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                {/* <th className="text-center">ID</th> */}
                                                {/* <th className="text-center">사이트명</th> */}
                                                <th className="text-center">이벤트명</th>
                                                <th className="text-center">채널명</th>
                                                <th className="text-center">이름</th>
                                                <th className="text-center">소속</th>
                                                <th className="text-center">휴대폰번호</th>
                                                <th className="text-center">로그인</th>
                                                <th className="text-center">접속시간</th>
                                                <th className="text-center">참여시간</th>
                                                <th className="text-center">접속기기</th>
                                                <th className="text-center">상세</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.map((item, i) => (
                                                <tr key={i}>
                                                    {/* <td className="text-center">{item.participantIdx}</td> */}
                                                    {/* <td className="text-center">{item.siteName}</td> */}
                                                    <td className="text-center">{item.eventName}</td>
                                                    <td className="text-center">{item.channelName}</td>
                                                    <td className="text-center">{item.name}</td>
                                                    <td className="text-center">{item.companyName}{item.deptName && "(" + item.deptName + ")"}</td>
                                                    <td className="text-center">{item.phone}</td>
                                                    <td className="text-center">최초 : {item.firstLoginDate ? moment(item.firstLoginDate).format("YYYY-MM-DD HH:mm") : "-"}
                                                        <br />최종 : {item.lastLoginDate ? moment(item.lastLoginDate).format("YYYY-MM-DD HH:mm") : "-"}
                                                    </td>
                                                    <td className="text-center">{item.startDate && moment(item.startDate).format("HH:mm")} ~ {item.endDate && moment(item.endDate).format("HH:mm")}</td>
                                                    <td className="text-center">{item.attendTimeMinute}분</td>
                                                    <td className="text-center">{item.loginDevice}</td>
                                                    <td className="text-center">
                                                        <div className="btn-group">
                                                            <button type="button" className="btn btn-xs btn-warning" onClick={(e) => onDetail(item)}>
                                                                <i className="fa fa-eye"></i> 상세
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {paging && (
                                <div className="ibox-footer">
                                    <Paging paging={paging} pageCallback={movePage} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {participantInfo && (
                <Modal isOpen={participantInfo ? true : false} style={Theme.modal.common} ariaHideApp={false}>
                    <ModalCloseButton
                        className="btn btn-default"
                        onClick={() => {
                            setParticipantInfo(null);
                        }}
                    >
                        <i className="fa fa-remove"></i>
                    </ModalCloseButton>
                    <AttendDetailView participantInfo={participantInfo} saveAfter={saveAfter} />
                </Modal>
            )}
            {isStatisticsDetail && (
                <Modal isOpen={isStatisticsDetail ? true : false} style={Theme.modal.common} ariaHideApp={false}>
                    <ModalCloseButton
                        className="btn btn-default"
                        onClick={() => {
                            setIsStatisticsDetail(false);
                        }}
                    >
                        <i className="fa fa-remove"></i>
                    </ModalCloseButton>
                    <StatisticsDetailView attendSessionList={attendSessionList} />
                </Modal>
            )}
            <ExcelDownloader data={exportExcelList} ref={excel} />
        </Layout>
    );
};

const AttendDetailView = (props) => {
    const { participantInfo } = props;
    const [list, setList] = useState([]);

    const loadList = () => {
        const data = {
            siteIdx: participantInfo.siteIdx,
            eventIdx: participantInfo.eventIdx,
            channelIdx: participantInfo.channelIdx,
            participantIdx: participantInfo.participantIdx,
        };
        const req = { path: "/v1/a/event/attend/info", data };
        _API.get(req).then((res) => {
            const attendList = res.data;
            const newList = [];
            for (let i in attendList) {
                const item = attendList[i];
                if (item.sessionType === "Break") {
                    continue;
                }
                if (item.startDate) item.startDate = moment(item.progressDate, "YYYYMMDD").format("YYYY-MM-DD") + moment(item.startDate).format(" HH:mm");
                if (item.endDate) item.endDate = moment(item.progressDate, "YYYYMMDD").format("YYYY-MM-DD") + moment(item.endDate).format(" HH:mm");
                if (item.sessionAttendStartDate) item.sessionAttendStartDate = moment(item.sessionAttendStartDate, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm");
                if (item.sessionAttendEndDate) item.sessionAttendEndDate = moment(item.sessionAttendEndDate, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm");
                newList.push(item);
            }
            setList(newList);
        });
    };

    useEffect(() => {
        loadList();
    }, []);

    const dateChange = (col, item, value) => {
        const newList = [];
        for (let i in list) {
            const data = list[i];
            if (data.siteIdx == item.siteIdx
                && data.eventIdx == item.eventIdx
                && data.channelIdx == item.channelIdx
                && data.sessionIdx == item.sessionIdx
                && value
            ) {
                data[col] = moment(data.progressDate, "YYYYMMDD").format("YYYY-MM-DD") + moment(value).format(" HH:mm");
                if (col === "startDate") data.sessionAttendStartDate = data.startDate;
                if (col === "endDate") data.sessionAttendEndDate = data.endDate;
                if (data.sessionAttendStartDate && data.sessionAttendEndDate) {
                    data.sessionAttendTime = moment.duration(moment(data.sessionAttendEndDate).diff(moment(data.sessionAttendStartDate))).asMinutes() + "분";
                } else {
                    data.sessionAttendTime = "0분";
                }
            }
            newList.push(data);
        }
        setList(newList);
    }

    const onSave = () => {
        if (!list || list.length === 0) {
            swal("등록할 정보가 없습니다.");
            return;
        }
        if (!participantInfo.participantIdx) {
            swal("참여자 정보가 없습니다.");
            return;
        }
        const req = { path: "/v1/a/event/attend/info/save", data: { attendSessionList: list, participantIdx: participantInfo.participantIdx } };
        _API.post(req).then((res) => {
            swal(res.data + "건의 출결정보가 저장되었습니다.");
            props.saveAfter && props.saveAfter();
        });
    }

    const bindingAttendTime = (item, startTime, endTime) => {
        const data = list.map((o) => {
            if (
                o.siteIdx == item.siteIdx
                && o.eventIdx == item.eventIdx
                && o.channelIdx == item.channelIdx
                && o.sessionIdx == item.sessionIdx
            ) {
                // o.startTime = startTime;
                // o.endTime = endTime;
                o.startDate = moment(o.progressDate, "YYYYMMDD").format("YYYY-MM-DD") + ` ${startTime}`;
                o.endDate = moment(o.progressDate, "YYYYMMDD").format("YYYY-MM-DD") + ` ${endTime}`;
                o.sessionAttendStartDate = o.startDate;
                o.sessionAttendEndDate = o.endDate;
                o.sessionAttendTime = moment.duration(moment(o.endDate).diff(moment(o.startDate))).asMinutes() + "분";
            }
            return o;
        });
        setList(data);
    }

    const onInit = () => {
        const data = list.map((o) => {
            o.startDate = null;
            o.endDate = null;
            o.sessionAttendStartDate = null;
            o.sessionAttendEndDate = null;
            o.sessionAttendTime = "0분";
            return o;
        });

        setList(data);
    }

    return (
        <div className="wrapper wrapper-content">
            <h2>출결정보</h2>
            <div className="row mt-3">
                <div className="col-md-12">
                    <div className="ibox">
                        <div className="ibox-content">
                            <div className="table-responsive" style={{ maxHeight: "430px" }}>
                                <table className="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th className="text-center">사이트명</th>
                                            <th className="text-center">이벤트명</th>
                                            <th className="text-center">채널명</th>
                                            <th className="text-center">세션명</th>
                                            <th className="text-center">참가자명</th>
                                            <th className="text-center">참가자소속</th>
                                            <th className="text-center">접속시작</th>
                                            <th className="text-center">접속종료</th>
                                            <th className="text-center">참여시간</th>
                                            <th className="text-center">참여처리</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list?.map((item, i) => (
                                            <tr key={i}>
                                                <td className="text-center">{item.siteName}</td>
                                                <td className="text-center">{item.eventName}</td>
                                                <td className="text-center">{item.channelName}</td>
                                                <td className="text-center">{item.sessionName}{"(" + item.startTime + "~" + item.endTime + ")"}</td>
                                                <td className="text-center">{item.name}</td>
                                                <td className="text-center">{item.companyName}{item.deptName && "(" + item.deptName + ")"}</td>
                                                <td className="text-center">
                                                    <TimePicker value={item.sessionAttendStartDate} onChange={(v) => dateChange("startDate", item, v.value)} />
                                                </td>
                                                <td className="text-center">
                                                    <TimePicker value={item.sessionAttendEndDate} onChange={(v) => dateChange("endDate", item, v.value)} />
                                                </td>
                                                <td className="text-center">{item.attendTimeMinute}</td>
                                                <td className="text-center">
                                                    <div className="btn-group">
                                                        <button type="button" className="btn btn-xs btn-warning" onClick={(e) => bindingAttendTime(item, item.startTime, item.endTime)}>
                                                            <i className="fa fa-clock-o"></i> 참여처리
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {list?.length > 0 && (
                                <div>
                                    <div className="hr-line-dashed"></div>
                                    <div className="row">
                                        <div className="col-md-12 text-right">
                                            <button className="btn btn-success " onClick={onInit}>
                                                <i className="fa fa-clock-o"></i> 초기화
                                            </button>
                                            <button className="btn btn-primary ml-2" onClick={onSave}>
                                                <i className="fa fa-save"></i> 저장
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const StatisticsDetailView = (props) => {
    const { attendSessionList } = props;

    useEffect(() => {
    }, []);

    return (
        <div className="wrapper wrapper-content">
            <h2>출결통계정보</h2>
            <div className="row mt-3">
                <div className="col-md-12">
                    <div className="ibox">
                        <div className="ibox-content">
                            <div className="table-responsive" style={{ maxHeight: "430px" }}>
                                <table className="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th className="text-center">세션명</th>
                                            <th className="text-center">시간</th>
                                            <th className="text-center">입장</th>
                                            <th className="text-center">퇴장</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {attendSessionList?.map((item, i) => (
                                            <tr key={i}>
                                                <td className="text-center">{item.title}</td>
                                                <td className="text-center">{item.startTime} ~ {item.endTime}</td>
                                                <td className="text-center">{item.inCnt || 0}</td>
                                                <td className="text-center">{item.outCnt || 0}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

class ExcelDownloader extends React.Component {
    constructor(props) {
        super(props);

        const state = {
            list: props.data || [],
            excelName: props.eventName,
        };
        this.state = state;
    }

    componentWillReceiveProps = (props) => {
        this.setState({ list: props.data, excelName: props.excelName });
    };

    save() {
        this.excel && this.excel.save();
    }

    getList() {
        const { list } = this.state;
        // console.log("excel > getList", list);
        for (let i in list) {
            const item = list[i];
            item.regDate = item.regDate ? moment(item.regDate).format("YYYY-MM-DD HH:mm:ss") : "";
            item.companyName = item.companyName + (item.deptName ? "(" + item.deptName + ")" : "");
            item.startDate = item.startDate ? moment(item.startDate).format("YYYY-MM-DD HH:mm:ss") : "";
            item.endDate = item.endDate ? moment(item.endDate).format("YYYY-MM-DD HH:mm:ss") : "";
            item.attendTime = item.startDate + " ~ " + item.endDate;
            if (item.sessionAttendStartDate) {
                item.sessionAttendStartDate = item.sessionAttendStartDate ? moment(item.sessionAttendStartDate).format("YYYY-MM-DD HH:mm:ss") : "";
                item.sessionAttendEndDate = item.sessionAttendEndDate ? moment(item.sessionAttendEndDate).format("YYYY-MM-DD HH:mm:ss") : "";
                item.attendTime = item.sessionAttendStartDate + " ~ " + item.sessionAttendEndDate;
            }
            item.phone = _U.phoneFomatter(item.phone);
            if (item.sessionName) item.sessionTime = item.startTime + "~" + item.endTime;
            item.progressDate = moment(item.progressDate, "YYYYMMDD").format("YYYY-MM-DD");
            item.firstLoginDate = item.firstLoginDate ? moment(item.firstLoginDate).format("YYYY-MM-DD HH:mm") : "-";
            item.lastLoginDate = item.lastLoginDate ? moment(item.lastLoginDate).format("YYYY-MM-DD HH:mm") : "-";
            if (item.sessionAttendTime) item.attendTimeMinute = item.sessionAttendTime;
            if (item.lectureName) {
                item.lectureTime = item.lectureS + "~" + item.lectureE;
                item.attendTimeMinute = item.lectureAttendTime ? item.lectureAttendTime + "분" : "0";
                item.lectureAttendTime = (item.lectureAttendStartDate ? item.lectureAttendStartDate : "") + "~" + (item.lectureAttendEndDate ? item.lectureAttendEndDate : "")
            }
        }
        return list;
    }

    isChannel() {
        const { list } = this.state;
        if (list && list.length > 0 && list[0].sessionName) {
            return true;
        }
        return false;
    }

    isLecture() {
        const { list } = this.state;
        if (list && list.length > 0 && list[0].lectureName) {
            return true;
        }
        return false;
    }

    render() {
        const excelName = this.state.excelName || "출결정보";
        const list = this.getList();
        const today = moment().format("YYYYMMDDHHmmss");
        if (!list) {
            return "";
        }

        return (
            <ExcelExport data={list} fileName={`${excelName}_${today}.xlsx`} ref={(ref) => (this.excel = ref)}>
                <ExcelExportColumn field="siteName" title="사이트명" width={250} />
                <ExcelExportColumn field="eventName" title="이벤트명" width={250} />
                <ExcelExportColumn field="channelName" title="채널명" width={250} />
                <ExcelExportColumn field="progressDate" title="채널 진행일자" width={250} />
                {this.isChannel() && (
                    <>
                        <ExcelExportColumn field="sessionName" title="세션명" width={250} />
                        <ExcelExportColumn field="sessionTime" title="세션 진행시간" width={250} />
                    </>
                )}
                {this.isLecture() && (
                    <>
                        <ExcelExportColumn field="lectureName" title="강좌명" width={250} />
                        <ExcelExportColumn field="lectureTime" title="강좌 진행시간" width={250} />
                    </>
                )}
                <ExcelExportColumn field="name" title="이름" width={250} />
                <ExcelExportColumn field="companyName" title="소속" width={250} />
                <ExcelExportColumn field="email" title="이메일" width={250} />
                <ExcelExportColumn field="phone" title="전화번호" width={250} />
                <ExcelExportColumn field="firstLoginDate" title="최초 로그인" width={250} />
                <ExcelExportColumn field="lastLoginDate" title="최종 로그인" width={250} />
                <ExcelExportColumn field="attendTime" title="전체 접속시간" width={400} />
                {this.isLecture() && (
                    <ExcelExportColumn field="lectureAttendTime" title="강좌 참여시간" width={250} />
                )}
                <ExcelExportColumn field="attendTimeMinute" title="참여시간" width={250} />
                <ExcelExportColumn field="loginDevice" title="접속기기" width={250} />
            </ExcelExport>
        );
    }
}