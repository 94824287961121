import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import swal from "sweetalert";

import { AppContext } from "../../components/AppProvider";
import { InputForm, SelectForm, H2, HRDash, ButtonSubmit, ButtonDefault, ModalVideoTitle } from "../../views/template/basic/UI/Components";
import { _API, _U } from "../../modules/utils";
import { _DEF } from "../../modules/config";
import Video from "./Video";


export default (props) => {
    const { cfg, auth, iam } = useContext(AppContext);
    const { code, contentType, contentIdx, videoData } = props;
    const event = cfg.event || null;
    const { siteIdx, eventIdx } = event;
    const [data, setData] = useState({});

    useEffect(() => {
        if (contentType === "VOD_FILE" || contentType === "REPLAY") {
            saveVodLog();
        }
    }, [])

    const loadData = () => {
        if (contentIdx) {
            const param = { siteIdx, eventIdx, code, contentIdx };
            _API.getContent({ data: param }).then((res) => {
                const { data: content, files } = res.data;
                setData(content);
            });
        } else {
            setData({
                videoUrl: props.videoUrl,
                vodSignedUrl: props.videoUrl,
                vodEncodedUrl: props.vodEncodedUrl,
                contentType: props.contentType,
                title: props.title || "",
                description: props.description || "",
                content: props.code,
                videoData: props.data,
            });
        }
    };

    const getUrl = () => {
        return data?.vodEncodedUrl || data?.vodSignedUrl;
    };

    const saveVodLog = () => {
        _API.post({
            path: "/v1/site/event/vod/log",
            data: {
                siteIdx,
                eventIdx,
                eventContentIdx: contentIdx,
                lectureIdx: videoData?.idx,
                logType: "VIEW"
            },
        }).then((res) => {
        });
    };

    useEffect(() => {
        loadData();
    }, [siteIdx, eventIdx, contentIdx, auth]);

    const getVodPaddingTop = () => {
        return "56.25%";
    }

    // useEffect(() => {
    //     console.log("data", data);
    // }, [data]);

    return (
        <Wrapper>
            <ContentBooth maxHeight={window.innerHeight - 80}>
                {data?.vodEncodedUrl ? (
                    <Video url={data?.vodEncodedUrl} autoPlay={true} muted={false} removeCustomSize={true} width="100%" height="100%" fluid={false} paddingTop={getVodPaddingTop()} />
                ) : (
                    <video src={data?.vodSignedUrl} playsInline autoPlay={true} width="100%" height="100%" controls controlsList="nodownload" style={{ maxHeight: (window.innerHeight - 80) + "px" }} />
                )}
                {/* <Video url={getUrl()} autoPlay={true} muted={false} removeCustomSize={true} width="100%" height="100%" fluid={false} paddingTop={getVodPaddingTop()} /> */}
            </ContentBooth>
            <ModalVideoTitle left={"1rem"}>{data?.title}</ModalVideoTitle>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const ContentBooth = styled.div`
    flex: 1;
    width: 100%;
    height: 100%;
    max-height:${(props) => props.maxHeight ? props.maxHeight + "px" : "100%"};
    overflow-x: hidden;
    /* overflow-y: hidden; */

    .image img {
        max-width: 100%;
    }
    video {
        max-height: 100%;
    }
`;
