import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import moment from "moment";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";
import { _DEF } from "../../../modules/config";

import Layout from "../Layout";
import { SearchInput, SelectForm, InputForm, SegmentedForm, FileForm, DatePicker, TextForm } from "../../../components/UI/Form";
import Wrapper, { Row, FormGroup, Separator } from "../../../components/UI/Wrapper";
import SNEditor from "../../../components/UI/SNEditor";
import EBCanvas from "../../../components/UI/EBCanvas";
import EMCanvas from "../../../components/UI/EMCanvas";

export default (props) => {
    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-8">
                    <h2>e부스</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/admin">HOME</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <strong>이벤트</strong>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>e부스</strong>
                        </li>
                    </ol>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <EboothView {...props} />
            </div>
        </Layout>
    )
}
export const EboothView = props => {
    const { history, location, match } = props;
    const { companyIdx } = match.params;

    const { cfg } = useContext(AppContext);
    const [eboothIdx, setEboothIdx] = useState(props.eboothIdx);
    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [eventIdx, setEventIdx] = useState(props.eventIdx);
    const [siteList, setSiteList] = useState([]);
    const [eventList, setEventList] = useState([]);

    const [eventInfo, setEventInfo] = useState({});
    const [eboothType, setEboothType] = useState("LINK");
    const [eboothTypeList, setEboothTypeList] = useState([{ label: "BASIC", value: "BASIC" }, { label: "LINK", value: "LINK" }]);
    const [baseVodFileIdx, setBaseVodFileIdx] = useState(null);
    const [baseVodUrl, setBaseVodUrl] = useState(null);
    const [brochure1FileIdx, setBrochure1FileIdx] = useState(null);
    const [brochure2FileIdx, setBrochure2FileIdx] = useState(null);
    const [brochure1FileUrl, setBrochure1FileUrl] = useState(null);
    const [brochure2FileUrl, setBrochure2FileUrl] = useState(null);
    const [description, setDescription] = useState("");
    const [vodName, setVodName] = useState(null);
    const [brochure1Name, setBrochure1Name] = useState(null);
    const [brochure2Name, setBrochure2Name] = useState(null);

    const [content, setContent] = useState({});
    // const [footer, setFooter] = useState("");
    const [status, setStatus] = useState("");
    const [ratio, setRatio] = useState(_DEF.EB_RATIO[0].value);

    const loadEventInfo = () => {
        if (!eventIdx) return;
        _API.get({
            path: `/v1/a/event/info`,
            data: { eventIdx },
        })
            .then((res) => res.data)
            .then((data) => {
                setEventInfo(data);
                if (data.eboothGradeYn === "Y") {
                    setEboothTypeList([{ label: "PREMIUM", value: "PREMIUM" }, { label: "BASIC", value: "BASIC" }, { label: "LINK", value: "LINK" }]);
                } else {
                    setEboothTypeList([{ label: "BASIC", value: "BASIC" }, { label: "LINK", value: "LINK" }]);
                }
            });
    }

    const loadData = () => {
        if (!eboothIdx) {
            return;
        }
        _API.get({
            path: `/v1/a/event/ebooth/${eboothIdx}`,
        })
            .then((res) => res.data)
            .then((data) => {
                console.log("data", data);
                setContent(data?.content ? JSON.parse(data.content) : {});
                setStatus(data?.status);
                setSiteIdx(data?.siteIdx);
                setEventIdx(data?.eventIdx);
                setEboothType(data?.eboothType);
                setBaseVodFileIdx(data?.baseVodFileIdx);
                setBaseVodUrl(data?.baseVodFileSignedUrl);
                setBrochure1FileIdx(data?.brochure1FileIdx);
                setBrochure2FileIdx(data?.brochure2FileIdx);
                setDescription(data?.description);
                setBrochure1FileUrl(data?.brochure1SignedUrl);
                setBrochure2FileUrl(data?.brochure2SignedUrl);
                setVodName(data?.vodName);
                setBrochure1Name(data?.brochure1Name);
                setBrochure2Name(data?.brochure2Name);
            });
    };



    useEffect(() => {
        setEventIdx(props.eventIdx);
        setEboothIdx(props.eboothIdx);
        loadEventInfo();
    }, [props.eventIdx, props.eboothIdx])

    const loadEventList = (siteIdx) => {

        if (!siteIdx) {
            setEventList([]);
            return;
        }
        const data = {
            siteIdx,
        };
        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            const newEventList = [];
            for (let i in list) {
                newEventList.push({ label: list[i].title, value: list[i].idx });
            }
            setEventList(newEventList);
        });
    };

    const isRegContent = (content) => {
        return content['bg'] && content['bg'].length > 0;
    }

    const handleave = (e) => {
        if (e) e.preventDefault();
        if (eboothType === "PREMIUM") {
            if (!content?.bg) {
                swal({ title: "알림", text: "컨텐츠의 배경화면을 등록해 주세요." });
                return;
            } else if (!content?.bg["16x9"]) {
                swal({ title: "알림", text: "16x9 PC 해상도 배경화면을 등록해 주세요." });
                return;
            } else if (!content?.bg["9x16"]) {
                swal({ title: "알림", text: "9x16 MOBILE 해상도 배경화면을 등록해 주세요." });
                return;
            }
        }
        else if (eboothType === "BASIC") {
            if (!baseVodFileIdx) {
                swal({ title: "알림", text: "이부스 기본영상을 등록해 주세요." });
                return;
            }
            // if (!brochure1FileIdx) {
            //     swal({ title: "알림", text: "이부스 브로셔 파일을 등록해 주세요." });
            //     return;
            // }
            // if (!description) {
            //     swal({ title: "알림", text: "이부스 설명내용을 등록해 주세요." });
            //     return;
            // }
        }
        _API.post({
            path: "/v1/a/event/ebooth",
            data: {
                siteIdx,
                eventIdx,
                companyIdx,
                eboothIdx,
                content: JSON.stringify(content),
                status,
                eboothType,
                baseVodFileIdx,
                brochure1FileIdx,
                brochure2FileIdx,
                description,
            },
        }).then((r) => {
            swal({ title: "알림", text: "저장되었습니다." }).then(() => {
                window.location.href = "#/admin/event/ebooth/company";
            })
            // setEboothIdx(r.data.eboothIdx);
        });
    };

    const fileUpload = (name, item) => {
        const bg = content?.bg || {};
        bg[ratio] = item.url;
        setContent({ ...content, bg: { ...bg } });
    }

    const fileVodUpload = async (name, item) => {
        const urlName = name.replace("FileIdx", "SignedUrl");
        await _API.post({
            path: "/v1/a/video",
            data: item,
        }).then((res) => res.data)
            .then((data) => {
                swal({ title: "알림", text: "업로드 되었습니다." });
            });
        setBaseVodFileIdx(item.id);
        setBaseVodUrl(item.url);
    }

    useEffect(() => {
        loadData();
    }, [eboothIdx]);

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12">
                    <div className="ibox">
                        <div className="ibox-title">
                            <div className="row">
                                <div className="col-md-8">
                                    설정
                                </div>
                                <div className="col-md-4">
                                    <div className="float-right">
                                        <button onClick={handleave} className="btn btn-primary mr-1">
                                            <i className="fa fa-ave"></i> 저장
                                        </button>
                                        <button className="btn btn-default" onClick={() => window.location.href = "#/admin/event/ebooth/company"}>
                                            <i className="fa fa-remove"></i> 취소
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ibox-content">
                            <div className="row">
                                {!props.isEboothTab && (
                                    <div className="col-md-6">
                                        <SelectForm title="사이트명" name="siteIdx" value={siteIdx} items={siteList} onChange={(e) => setSiteIdx(e.target.value)} nullTitle="사이트 선택" />
                                        <SelectForm title="이벤트명" name="eventIdx" value={eventIdx} items={eventList} onChange={(e) => setEventIdx(e.target.value)} nullTitle="이벤트 선택" />
                                        {/* <InputForm title="코드" value={code} onChange={(e) => setCode(e.target.value)} placeholder="코드" /> */}
                                        {/* <InputForm title="제목" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="제목" /> */}
                                        {/* <FileForm title="인트로영상(16x9)" accept="video/*" onChange={(v) => fileVodUpload("introVodFileIdx", v)} path="EVENT/EBOOTH/INTRO_VOD" url={introVodUrl} /> */}
                                    </div>
                                )}
                                <div className="col-md-6">
                                    <SegmentedForm title="이부스 타입" items={eboothTypeList} value={eboothType} onClick={(v) => setEboothType(v)} />
                                    {eboothType === "BASIC" && (
                                        <>
                                            <FileForm title="이부스 기본영상" accept="video/*" onChange={(v) => fileVodUpload("baseVodFileIdx", v)} path={`EVENT/EBOOTH/BASE_VOD/${moment().format("YYYY/MM/DD")}`} url={baseVodUrl} fileName={vodName} />
                                            <FileForm title="브로셔1" accept="application/pdf" onChange={(v) => setBrochure1FileIdx(v.id)} path={`EVENT/EBOOTH/BROCHURE/${moment().format("YYYY/MM/DD")}`} url={brochure1FileUrl} fileName={brochure1Name} />
                                            <FileForm title="브로셔2" accept="application/pdf" onChange={(v) => { console.log(v); setBrochure2FileIdx(v.id); }} path={`EVENT/EBOOTH/BROCHURE/${moment().format("YYYY/MM/DD")}`} url={brochure2FileUrl} fileName={brochure2Name} />
                                            <TextForm title="설명" name="description" value={description} onChange={(e) => setDescription(e.target.value)} placeholder="이부스 설명을 입력해 주세요" />
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {eboothType === "PREMIUM" && (
                <div className="row">
                    <div className="col-md-9">
                        <div className="ibox">
                            <div className="ibox-title">편집창</div>
                            <div className="ibox-content ibox-heading">
                                <div className="row ibox-content">
                                    <div className="col-md-4">
                                        <SelectForm title="비율" nullTitle="비율" items={_DEF.EB_RATIO} value={ratio} onChange={(e) => setRatio(e.target.value)} />
                                    </div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-6">
                                        <FileForm title="배경화면" accept="image/*" onChange={(v) => fileUpload("bg", v)} path={`EVENT/EBOOTH/BG/${moment().format("YYYY/MM/DD")}`} description={ratio === "16x9" ? "*권장해상도 1920 x 1080 인쇄용 or 5760 x 3240(16:9)" : "*권장해상도 1080x1920 인쇄용 or 3240 x 5760(9:16)"} />
                                    </div>
                                </div>
                            </div>
                            <div className="ibox-content">
                                <EBCanvas debug ratio={ratio} elements={content} bg={content.bg} onChange={setContent} />
                            </div>
                        </div>
                    </div>
                    <ContentBinder eboothIdx={eboothIdx} eventIdx={eventIdx} companyIdx={companyIdx} ratio={ratio} elements={content} onChange={setContent} />
                </div>
            )}
        </Fragment>
    );
};

const ContentBinder = (props) => {
    const { ratio, elements, eboothIdx, onChange, eventIdx, companyIdx } = props;

    const [el, setEl] = useState(null);
    const [contents, setContents] = useState([]);

    const loadContents = () => {
        if (!eventIdx) {
            return;
        }
        const req = {
            path: "/v1/a/event/content/list",
            data: {
                eventIdx,
                category: "EBOOTH",
                companyIdx,
                contentStatus: 'Y'
            },
        };
        _API.get(req)
            .then((res) => res.data)
            .then((data) => {
                const { list } = data;
                setContents(list);
            });
    };

    const handleContentSelect = (e) => {
        const cs = contents.filter((o) => o.idx === parseInt(e.target.value));
        if (cs.length === 0) {
            return;
        }
        const content = cs[0];

        const list = elements[ratio];
        if (list) {
            list.map((item) => {
                if (item.id === el.id) {
                    item.cid = content.idx;
                    item.title = content.title;
                    item.contentType = content.contentType;
                    if (content.contentType === "LINK") item.url = content.content;
                }
                return item;
            });
            onChange && onChange({ ...elements, [ratio]: [...list] });
        }
    };

    useEffect(() => {
        setEl(null);
        const els = elements[ratio];
        if (els && els.length > 0) {
            const ef = els.filter((el) => el.select === true);
            if (ef.length > 0) {
                setEl(ef[0]);
            }
        }
    }, [elements]);

    useEffect(() => {
        loadContents();
    }, [eventIdx]);

    return (
        <div className="col-md-3">
            <div className="ibox">
                <div className="ibox-title">콘텐츠 연결</div>
                <div className="ibox-content">
                    <div className="row">
                        <div className="col-md-12">ID : {el?.id}</div>
                    </div>
                    <Separator />
                    <div className="row">
                        <div className="col-md-12">
                            <select className="form-control" value={`${el?.cid}`} onChange={handleContentSelect}>
                                <option value="">콘텐츠 선택</option>
                                {contents.map((item, i) => (
                                    <option key={i} value={item.idx}>
                                        {item.title}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const EboothMainView = props => {
    const { history, location, match } = props;
    // const { eventIdx } = match.params;
    const [idx, setIdx] = useState(props.idx);
    const [siteIdx, setSiteIdx] = useState(props.siteIdx);
    const [eventIdx, setEventIdx] = useState(props.eventIdx);
    // const [companyIdx, setCompanyIdx] = useState(props.companyIdx||"");
    const [siteList, setSiteList] = useState([]);
    const [eventList, setEventList] = useState([]);
    const [eventInfo, setEventInfo] = useState({});

    // const [code, setCode] = useState("");
    // const [title, setTitle] = useState("");
    const [content, setContent] = useState({});
    const [status, setStatus] = useState("");
    const [ratio, setRatio] = useState(_DEF.EB_RATIO[0].value);

    const [awsKey, setAwsKey] = useState(null);
    const [file, setFile] = useState(null);

    const loadKeys = () => {
        _API.loadUploadKeys()
            .then((res) => res.data)
            .then((data) => {
                setAwsKey(data);
            });
    };

    const loadData = () => {
        if (!idx && !eventIdx) {
            return;
        }

        const req = { path: "/v1/a/event/eboothmain/main/check", data: { eventIdx, idx } };
        _API.get(req).then((res) => {
            const { eventInfo: selectEventInfo, mainInfo } = res.data;
            if (mainInfo) {
                setContent(mainInfo?.content ? JSON.parse(mainInfo.content) : {});
                setStatus(mainInfo?.status);
                setSiteIdx(mainInfo?.siteIdx);
                setEventIdx(mainInfo?.eventIdx);
            }
            setEventInfo(selectEventInfo);

        }).catch((err) => {
            // window.location.href="#/event/ebooth/main"
        });
    };

    const loadSitetList = () => {
        const data = {};
        const req = { path: "/v1/a/site", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            const newSiteList = [];
            for (let i in list) {
                newSiteList.push({ label: list[i].title, value: list[i].idx });
            }
            setSiteList(newSiteList);
        });
    };

    useEffect(() => {
        setSiteIdx(props.siteIdx)
        setEventIdx(props.eventIdx)
        setIdx(props.idx)
    }, [props.siteIdx, props.eventIdx, props.idx])

    const loadEventList = (siteIdx) => {

        if (!siteIdx) {
            setEventList([]);
            return;
        }
        const data = {
            siteIdx,
        };
        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            const newEventList = [];
            for (let i in list) {
                newEventList.push({ label: list[i].title, value: list[i].idx });
            }
            setEventList(newEventList);
        });
    };

    const isRegContent = content => {
        return ((content['16x9'] && content['16x9'].length && content['16x9'][0].cid)
            || (content['9x16'] && content['9x16'].length && content['9x16'][0].cid));
    }

    const handleave = (e) => {
        if (e) e.preventDefault();

        if (!content?.bg) {
            swal({ title: "알림", text: "컨텐츠의 배경화면을 등록해 주세요." });
            return;
        } else if (!content?.bg["16x9"]) {
            swal({ title: "알림", text: "16x9 PC 해상도 배경화면을 등록해 주세요." });
            return;
        } else if (!content?.bg["9x16"]) {
            swal({ title: "알림", text: "9x16 MOBILE 해상도 배경화면을 등록해 주세요." });
            return;
        }

        if (!isRegContent(content)) {
            swal({ title: "알림", text: "컨텐츠를 등록해 주세요." });
            return;
        }

        _API.post({
            path: "/v1/a/event/eboothmain/main",
            data: {
                idx,
                siteIdx,
                eventIdx,
                content: JSON.stringify(content),
                status,
            },
        }).then((r) => {
            swal({ title: "알림", text: "저장되었습니다." }).then(() => {
                window.location.href = "#/admin/event/ebooth/main";
            })
            // setEboothIdx(r.data.eboothIdx);
        });
    };

    const fileUpload = (name, item) => {
        const bg = content?.bg || {};
        bg[ratio] = item.url;
        setContent({ ...content, bg: { ...bg } });
    }

    useEffect(() => {
        loadKeys();
        // loadSitetList();
    }, []);

    useEffect(() => {
        if (idx || eventIdx) {
            loadData();
        }
    }, [idx, eventIdx]);

    // useEffect(() => {
    //     loadEventList(siteIdx);
    // }, [siteIdx]);

    const getEboothBgFileDescription = () => {
        console.log(ratio, eventInfo, eventInfo?.templeteCode == "EBOOTH")
        if (ratio == "16x9") {
            if (eventInfo?.templeteCode == "EBOOTH") {
                return "*권장해상도 1920 x 1080 인쇄용 or 5760 x 3240(16:9)";
            }
            return "*권장해상도 1920 x 960 인쇄용 or 5760 x 2880(16:8)";
        } else {
            return "*권장해상도 1080x1920 인쇄용 or 3240 x 5760(9:16)";
        }
    }

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12">
                    <div className="ibox">
                        <div className="ibox-title">
                            <div className="row">
                                <div className="col-md-8">
                                    설정 {eventInfo?.title && "-[" + eventInfo.title + "]"}
                                </div>
                                <div className="col-md-4">
                                    <div className="float-right">
                                        <button onClick={handleave} className="btn btn-primary mr-1">
                                            <i className="fa fa-ave"></i> 저장
                                        </button>
                                        <button className="btn btn-default" onClick={() => window.location.href = "#/admin/event/ebooth/main"}>
                                            <i className="fa fa-remove"></i> 취소
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* {content.eboothType === "PREMIUM" &&  */}
                        <div className="ibox-content">
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="ibox">
                                        {/* <div className="ibox-title">편집창</div> */}
                                        <div className="ibox-content ibox-heading">
                                            <div className="row ibox-content">
                                                <div className="col-md-4">
                                                    <SelectForm title="비율" nullTitle="비율" items={_DEF.EB_RATIO} value={ratio} onChange={(e) => setRatio(e.target.value)} />
                                                </div>
                                                <div className="col-md-2"></div>
                                                <div className="col-md-6">
                                                    <FileForm title="배경화면" accept="image/*" onChange={(v) => fileUpload("bg", v)} path={`EVENT/EBOOTH/PREMIUM/BG/${moment().format("YYYY/MM/DD")}`} description={getEboothBgFileDescription()} />
                                                    {/* <div className="input-group">
                                                            <input type="file" className="form-control" onChange={onFileChange} accept="image/*" style={{ overflow: "hidden" }} />
                                                            <div className="input-group-append">
                                                                <button type="button" className="btn btn-primary" onClick={onFileUpload}>
                                                                    <i className="fa fa-upload"></i>
                                                                </button>
                                                            </div>
                                                        </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ibox-content">
                                            <>
                                                {eventInfo?.templeteCode == "EBOOTH" ? (
                                                    <EBCanvas debug ratio={ratio} elements={content} bg={content.bg} onChange={setContent} />
                                                )
                                                    : (
                                                        <EMCanvas debug ratio={ratio} elements={content} bg={content?.bg} onChange={setContent} />
                                                    )}
                                            </>
                                        </div>
                                    </div>
                                </div>
                                <CompanyBinder idx={idx} eventIdx={eventIdx} ratio={ratio} elements={content} onChange={setContent} />
                            </div>
                        </div>
                        {/* } */}

                    </div>
                </div>
            </div>


        </Fragment>
    );
};

const CompanyBinder = (props) => {
    const { ratio, elements, idx, onChange, eventIdx } = props;

    const [el, setEl] = useState(null);
    const [contents, setContents] = useState([]);

    const loadContents = () => {
        if (!eventIdx) {
            return;
        }
        const data = {
            eventIdx,
            eboothYn: "Y",
        };

        const req = { path: "/v1/a/event/company", data };
        _API.get(req).then((res) => {
            const { list } = res.data;
            setContents(list);
        });
    };

    const handleContentSelect = (e) => {
        const cs = contents.filter((o) => o.idx === parseInt(e.target.value));
        if (cs.length === 0) {
            return;
        }
        const content = cs[0];

        const list = elements[ratio];
        if (list) {
            list.map((item) => {
                if (item.id === el.id) {
                    item.cid = content.idx;
                    item.title = content.name;
                    item.eboothType = content.eboothType;
                    if (!content.eboothItemIdx || content.eboothType != "PREMIUM") item.url = content.homepageUrl || content.content;
                }
                return item;
            });
            onChange && onChange({ ...elements, [ratio]: [...list] });
        }
    };

    useEffect(() => {
        setEl(null);
        const els = elements[ratio];
        if (els && els.length > 0) {
            const ef = els.filter((el) => el.select === true);
            if (ef.length > 0) {
                setEl(ef[0]);
            }
        }
    }, [elements]);

    useEffect(() => {
        loadContents();
    }, [eventIdx]);

    return (
        <div className="col-md-3">
            <div className="ibox">
                <div className="ibox-title">협력업체 연결</div>
                <div className="ibox-content">
                    <div className="row">
                        <div className="col-md-12">ID : {el?.id}</div>
                    </div>
                    <Separator />
                    <div className="row">
                        <div className="col-md-12">
                            <select className="form-control" value={`${el?.cid}`} onChange={handleContentSelect}>
                                <option value="">협력업체 선택</option>
                                {contents.map((item, i) => (
                                    <option key={i} value={item.idx}>
                                        {item.name} {"(" + (item.eboothType || "LINK") + ")"} {/* EBOOTH_ITEM_IDX */}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};