import React, { useContext, useEffect, useState, Fragment } from "react";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";
import { isMobile } from "react-device-detect";

import { AppContext } from "../../../components/AppProvider";

import { _API, _U, _CK } from "../../../modules/utils";

import { InputBox, ButtonSubmit } from "./UI/Components";

export default (props) => {
    const { auth } = useContext(AppContext);
    const { cfg, saveAuth, saveIam } = useContext(AppContext);
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [passCode, setPassCode] = useState("");
    const [passwd, setPasswd] = useState("");
    const [name, setName] = useState("");
    const { event } = cfg;

    const onLogin = () => {
        // let loginProcess = false;
        // if (event.loginYn === "Y") {
        //     if (event?.startDate && event?.endDate) {
        //         if (moment(event?.startDate) <= moment() && moment(event?.endDate + "235959", "YYYYMMDDHHmmss") >= moment()) {
        //             loginProcess = true;
        //         }
        //     }
        // }

        // if (!loginProcess) {
        //     swal({ title: "알림", text: "이벤트 진행기간이 아닙니다." });
        //     return;
        // }

        if (event.loginType === "PHONE" && !mobile) {
            swal({ title: "알림", text: "휴대폰 번호를 입력해 주세요." });
            return;
        }
        if (event.loginType === "PHONE" && (!mobile.startsWith("01") || mobile.length < 10 || mobile.length > 13)) {
            swal({ title: "알림", text: "올바른 휴대폰 번호를 입력해 주세요." });
            return;
        }

        if (event.loginType === "EMAIL" && !email) {
            swal({ title: "알림", text: "이메일을 입력해 주세요." });
            return;
        }
        if (event.loginType === "EMAIL" && !_U.isEmail(email)) {
            swal({ title: "알림", text: "올바른 이메일 형식을 입력해 주세요." });
            return;
        }
        if (event.loginType === "PASSCODE" && !passCode) {
            swal({ title: "알림", text: "패스코드를 입력해 주세요." });
            return;
        }
        if (event.loginType === "NAME" && !name) {
            swal({ title: "알림", text: "이름을 입력해 주세요." });
            return;
        }
        if (event.passwdYn === "Y" && !passwd) {
            swal({ title: "알림", text: "비밀번호를 입력해 주세요." });
            return;
        }

        _API.post({
            path: "/v1/site/event/login",
            data: {
                siteIdx: cfg.idx,
                eventIdx: cfg.event?.eventIdx,
                mobile,
                email,
                passCode,
                loginType: event.loginType,
                passwd,
                loginDevice: isMobile ? "MOBILE" : "PC",
                name,
            },
        }).then((res) => {
            const { accessKey: auth, data: iam } = res.data;
            _CK.setAuth(auth);
            saveAuth(auth);
            saveIam(iam);
            if (!event?.loginAlert) swal({ title: "로그인", text: `${iam.name ? `${iam.name}님 ` : ``}방문해 주셔서 감사합니다.` }).then(() => { });
        });
    };

    return (
        <>
            <Wrapper>
                <Title>로그인</Title>
                {event.loginType === "PHONE" && <InputBox name="mobile" placeholder="휴대폰번호를 입력해 주세요." onChange={(e) => setMobile(e.target.value)} value={mobile} />}
                {event.loginType === "EMAIL" && <InputBox name="email" placeholder="이메일을 입력해 주세요." onChange={(e) => setEmail(e.target.value)} value={email} />}
                {event.loginType === "PASSCODE" && <InputBox name="passCode" placeholder="인증코드를 입력해 주세요." onChange={(e) => setPassCode(e.target.value)} value={passCode} />}
                {event.loginType === "NAME" && <InputBox name="name" placeholder="이름을 입력해 주세요." onChange={(e) => setName(e.target.value)} value={name} />}
                {event.passwdYn === "Y" && <InputBox type="password" name="passwd" style={{ marginTop: "10px" }} placeholder="비밀번호를 입력해 주세요." onChange={(e) => setPasswd(e.target.value)} value={passwd} />}

                {!event.loginDescription && event.loginType === "PHONE" && <div style={{ marginTop: "10px" }} >* 로그인은 사전등록에 사용한 휴대전화 번호를 입력해 주시기 바랍니다.</div>}
                {!event.loginDescription && event.loginType === "EMAIL" && <div style={{ marginTop: "10px" }} >* 로그인은 사전등록에 사용한 이메일 주소를 입력해 주시기 바랍니다.</div>}
                {!event.loginDescription && event.loginType === "PASSCODE" && <div style={{ marginTop: "10px" }} >* 로그인은 사전등록시 발급받은 인증코드를 입력해 주시기 바랍니다.</div>}
                {!event.loginDescription && event.loginType === "NAME" && <div style={{ marginTop: "10px" }} >* 로그인은 사전등록된 이름을 입력해 주시기 바랍니다.</div>}
                {event.loginDescription && <div style={{ marginTop: "10px" }} dangerouslySetInnerHTML={{ __html: event.loginDescription, }} />}
                <ButtonSubmit margin="20px 0 0 0" onClick={onLogin}>
                    로그인
                </ButtonSubmit>
            </Wrapper>
        </>
    );
};

const Wrapper = styled.div`
    width: 480px;
    min-height: 200px;
    margin: 20px auto;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    padding: 20px;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        width: 90%;
    }
`;

const Title = styled.h3`
    color: ${(props) => props.theme.basic.color.primary};
    font-family: ${(props) => props.theme.fontFamily.medium};
`;
