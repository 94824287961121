import React, { useContext, useState, useEffect, Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import swal from "sweetalert";

import Layout from "../Layout";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";
import { _DEF } from "../../../modules/config";

import Wrapper, { Row, FormGroup, Separator } from "../../../components/UI/Wrapper";
import { SearchInput, SelectForm, InputForm, SegmentedForm, FileForm, DatePicker } from "../../../components/UI/Form";
import Button from "../../../components/UI/Button";
import Paging, { ToPage, GetParams } from "../../../components/UI/Paging";

import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";

export default (props) => {

    const { history, location, match } = props;

    const { cfg } = useContext(AppContext);

    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [eventIdx, setEventIdx] = useState("");
    const [channelIdx, setChannelIdx] = useState("");
    const [sessionIdx, setSessionIdx] = useState("");
    const [lectureIdx, setLectureIdx] = useState("");
    const [keyword, setKeyword] = useState("");

    const [list, setList] = useState([]);
    const [paging, setPaging] = useState(null);
    const [eventList, setEventList] = useState([]);
    const [channelList, setChannelList] = useState([]);
    const [sessionList, setSessionList] = useState([]);
    const [lectureList, setLectureList] = useState([]);
    const [quizIdx, setQuizIdx] = useState(null);
    const [mode, setMode] = useState("");
    const [aws, setAws] = useState();

    const [exportInfo, setExportInfo] = useState();
    let excel = useRef(null);

    const loadList = (page) => {
        // if(!eventIdx){
        //     swal({ title: "알림", text: "이벤트를 선택해 주세요." });
        //     return;
        // }
        const data = {
            siteIdx,
            eventIdx,
            channelIdx,
            sessionIdx,
            lectureIdx,
            keyword,
            page,
        };
        const req = { path: "/v1/a/event/feedback/quiz/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setList(list);
            setPaging(paging);
        });
    };

    const loadEventList = (siteIdx) => {
        const data = {
            siteIdx,
        };
        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setEventList(list);
        });
    };

    const loadChannelList = (eventIdx) => {
        const data = {
            eventIdx,
            sort: "TERM_DATE"
        };
        const req = { path: "/v1/a/event/channel/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setChannelList(list);
        });
    };

    const loadSessionList = (channelIdx) => {
        const data = {
            channelIdx,
            sort: "TERM_DATE"
        };
        const req = { path: "/v1/a/event/session/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setSessionList(list);
        });
    };

    const loadLectureList = (sessionIdx) => {
        const data = {
            sessionIdx,
            sort: "TERM_DATE"
        };
        const req = { path: "/v1/a/event/lecture/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setLectureList(list);
        });
    };

    const movePage = (page) => {
        loadList(page);
    };

    const onSearch = (e) => {
        if (e) e.preventDefault();
        loadList(1);
    };

    useEffect(() => {
        loadList(1);
        if (siteIdx) loadEventList(siteIdx);
        else setEventList([]);

        setChannelList([]); setSessionList([]); setLectureList([]);
        setEventIdx(""); setChannelIdx(""); setSessionIdx(""); setLectureIdx("");
    }, []);


    useEffect(() => {
        if (eventIdx) loadChannelList(eventIdx);
        else setChannelList([]);

        setSessionList([]); setLectureList([]);
        setChannelIdx(""); setSessionIdx(""); setLectureIdx("");
    }, [eventIdx]);

    useEffect(() => {
        if (channelIdx) loadSessionList(channelIdx);
        else setSessionList([]);

        setLectureList([]);
        setSessionIdx(""); setLectureIdx("");
    }, [channelIdx]);

    useEffect(() => {
        if (sessionIdx) loadLectureList(sessionIdx);
        else setLectureList([]);

        setLectureIdx("");
    }, [sessionIdx]);

    useEffect(() => {
        loadList(1);
    }, [eventIdx, channelIdx, sessionIdx, lectureIdx])

    const onForm = (idx, e) => {
        if (e) e.preventDefault();

        setQuizIdx(idx);
        setMode("form");

    }

    const onChangeState = (item, col, val, e) => {
        if (e) e.preventDefault();

        swal({
            title: `퀴즈`,
            text: `상태를 변경하시겠습니까?`,
            icon: "info",
            buttons: ["취소", "예"],
            dangerMode: val === "D" ? true : false,
        }).then((ok) => {
            if (ok) {
                _API.post({
                    path: "/v1/a/event/category/status",
                    data: {
                        idx: item.idx
                        , category: "quiz"
                        , status: val
                    },
                }).then((res) => {
                    loadList(paging?.pageNo ? paging?.pageNo : 1)
                });
            }
        });
    }

    const changeMode = (mode) => {
        setMode(mode);
        if (!mode) loadList(paging?.pageNo ? paging?.pageNo : 1);
    }

    const onExcelDown = async () => {
        if (!siteIdx || !eventIdx || !channelIdx) {
            swal({ title: "알림", text: "채널을 선택해 주세요." });
            return;
        }

        const data = {
            siteIdx,
            eventIdx,
            channelIdx,
            sessionIdx,
        };

        const result = await _API.get({ path: "/v1/a/event/feedback/quiz/answer/list", data });
        const info = result?.data;
        if (!info || !info.quizList || !info.answerList || info.quizList.length == 0 || info.answerList.length == 0) {
            swal({ title: "알림", text: "퀴즈내역이 없습니다." });
            return;
        }

        info.eventName = eventList?.filter((c) => c.eventIdx == eventIdx)[0]?.title;
        info.channelName = channelList?.filter((c) => c.idx == channelIdx)[0]?.title;
        info.sessionName = sessionList?.filter((c) => c.idx == sessionIdx)[0]?.title;
        setExportInfo(info);
        excel && excel.current.save();
    };

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-8">
                    <h2>퀴즈</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/admin">HOME</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <strong>이벤트</strong>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>퀴즈</strong>
                        </li>
                    </ol>
                </div>
                <div className="col-sm-4">
                    <div className="title-action">
                        <button type="button" className="btn btn-primary" onClick={onForm.bind(this, "")}>
                            <i className="fa fa-plus"></i> 추가
                        </button>
                        <button
                            type="button"
                            className="btn btn-warning ml-1"
                            onClick={(e) => {
                                onExcelDown();
                            }}
                        >
                            <i className="fa fa-file-excel-o"></i> 답변내역 엑셀다운
                        </button>
                    </div>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className={cx({ "col-md-12": !mode, "col-md-6": mode })}>
                        <div className="ibox">
                            <div className="ibox-title">퀴즈 목록</div>
                            <div className="ibox-content ibox-heading">
                                <div className="row">
                                    <div className="col-md-2">
                                        <select value={eventIdx} onChange={(e) => setEventIdx(e.target.value)} className="form-control">
                                            <option value="">이벤트선택</option>
                                            {eventList?.map((item, i) => (
                                                <option key={i} value={item.eventIdx}>
                                                    {item.title}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-2">
                                        <select value={channelIdx} onChange={(e) => setChannelIdx(e.target.value)} className="form-control">
                                            <option value="">채널선택</option>
                                            {channelList?.map((item, i) => (
                                                <option key={i} value={item.idx}>
                                                    {item.title + " (" + moment(item.progressDate).format("YYYY-MM-DD") + ")"}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-2">
                                        <select value={sessionIdx} onChange={(e) => setSessionIdx(e.target.value)} className="form-control">
                                            <option value="">세션선택</option>
                                            {sessionList?.map((item, i) => (
                                                <option key={i} value={item.idx}>
                                                    {item.title + " (" + item.startTime + " ~ " + item.endTime + ")"}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-2">
                                        <select value={lectureIdx} onChange={(e) => setLectureIdx(e.target.value)} className="form-control">
                                            <option value="">강좌선택</option>
                                            {lectureList?.map((item, i) => (
                                                <option key={i} value={item.idx}>
                                                    {item.title + " (" + item.startTime + " ~ " + item.endTime + ")"}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-2">
                                        <SearchInput value={keyword} onChange={(e) => setKeyword(e.target.value)} onClick={onSearch} placeholder="검색어를 입력해 주세요." />
                                    </div>
                                </div>
                            </div>
                            <div className="ibox-content">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th className="text-center">퀴즈ID</th>
                                                <th className="text-center">이벤트명</th>
                                                <th className="text-center">채널명</th>
                                                <th className="text-center">세션명</th>
                                                <th className="text-center">강좌명</th>
                                                <th className="text-center">퀴즈문항</th>
                                                <th className="text-center">상태</th>
                                                <th className="text-center">등록일</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.map((item, i) => (
                                                <tr key={i}>
                                                    <td className="text-center">{item.idx}</td>
                                                    <td className="text-center">{item.eventName}</td>
                                                    <td className="text-center">{item.channelName}</td>
                                                    <td className="text-center">{item.sessionName}</td>
                                                    <td className="text-center">{item.lectureName}</td>
                                                    <td className="text-center">
                                                        <a href="" onClick={onForm.bind(this, item.idx)}>{_U.shortText(item.question, 5, "...")}</a>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="btn-group">
                                                            {/* <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "W", "btn-success": item.status === "W" })} onClick={onChangeState.bind(this, item, "status", "W")}>
                                                                대기
                                                            </button> */}
                                                            <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "Y", "btn-success": item.status === "Y" })} onClick={onChangeState.bind(this, item, "status", "Y")}>
                                                                정상
                                                            </button>
                                                            <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "N", "btn-success": item.status === "N" })} onClick={onChangeState.bind(this, item, "status", "N")}>
                                                                미사용
                                                            </button>
                                                            <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "D", "btn-success": item.status === "D" })} onClick={onChangeState.bind(this, item, "status", "D")}>
                                                                삭제
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">{moment(item.regDate).format("YYYY-MM-DD")}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {paging && (
                                <div className="ibox-footer">
                                    <Paging paging={paging} pageCallback={movePage} />
                                </div>
                            )}
                        </div>
                    </div>
                    {mode && (
                        <div className={cx({ "col-md-6": true })}>
                            <EventQuizForm {...props} quizIdx={quizIdx} changeMode={changeMode} aws={aws} />
                        </div>
                    )}
                </div>
            </div>
            <ExcelDownloader data={exportInfo} ref={excel} />
        </Layout>
    );
};

const EventQuizForm = (props) => {
    const { history, location, match } = props;
    const { quizIdx, aws } = props;
    const { cfg } = useContext(AppContext);

    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [data, setData] = useState({});
    const [eventList, setEventList] = useState([]);
    const [channelList, setChannelList] = useState([]);
    const [sessionList, setSessionList] = useState([]);
    const [lectureList, setLectureList] = useState([]);
    const sorts = _DEF.getRange(1, 100, {});

    const loadEventList = (siteIdx) => {
        const data = {
            siteIdx,
        };
        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            const newEventList = [];
            for (let i in list) {
                newEventList.push({ label: list[i].title, value: list[i].idx });
            }
            setEventList(newEventList);
        });
    };

    const loadChannelList = (eventIdx) => {
        const data = {
            eventIdx,
            sort: "TERM_DATE"
        };
        const req = { path: "/v1/a/event/channel/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            const newList = [];
            for (let i in list) {
                newList.push({ label: list[i].title + " (" + moment(list[i].progressDate).format("YYYY-MM-DD") + ")", value: list[i].idx });
            }
            setChannelList(newList);
        });
    };

    const loadSessionList = (channelIdx) => {
        const data = {
            channelIdx,
            sort: "TERM_DATE"
        };
        const req = { path: "/v1/a/event/session/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            const newList = [];
            for (let i in list) {
                newList.push({ label: list[i].title + " (" + list[i].startTime + " ~ " + list[i].endTime + ")", value: list[i].idx });
            }
            setSessionList(newList);
        });
    };

    const loadLectureList = (sessionIdx) => {
        const data = {
            sessionIdx,
            sort: "TERM_DATE"
        };
        const req = { path: "/v1/a/event/lecture/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            const newList = [];
            for (let i in list) {
                newList.push({ label: list[i].title + " (" + list[i].startTime + " ~ " + list[i].endTime + ")", value: list[i].idx });
            }
            setLectureList(newList);
        });
    };

    const loadData = () => {
        if (!quizIdx) {
            return;
        }
        const data = {};
        const req = { path: `/v1/a/event/feedback/quiz/${quizIdx}`, data };
        _API.get(req)
            .then((res) => res.data)
            .then((data) => {
                if (data.siteIdx) loadEventList(data.siteIdx);
                if (data.eventIdx) loadChannelList(data.eventIdx);
                if (data.channelIdx) loadSessionList(data.channelIdx);
                if (data.sessionIdx) loadLectureList(data.sessionIdx);
                setData(data);
            });
    };

    const handleDataChange = (e) => {
        let { name, value } = e.target;

        if (name === "siteIdx") {
            if (value) loadEventList(value);
            else setEventList([]);
            setChannelList([]);
            setSessionList([]);
            setLectureList([]);
            setData({ ...data, [name]: value, eventIdx: null, channelIdx: null, sessionIdx: null, lectureIdx: null });
        } else if (name === "eventIdx") {
            if (value) loadChannelList(value);
            else setChannelList([]);
            setSessionList([]);
            setLectureList([]);
            setData({ ...data, [name]: value, channelIdx: null, sessionIdx: null, lectureIdx: null });
        } else if (name === "channelIdx") {
            if (value) loadSessionList(value);
            else setSessionList([]);
            setLectureList([]);
            setData({ ...data, [name]: value, sessionIdx: null, lectureIdx: null });
        } else if (name === "sessionIdx") {
            if (value) loadLectureList(value);
            else setLectureList([]);
            setData({ ...data, [name]: value, lectureIdx: null });
        } else {
            setData({ ...data, [name]: value });
        }
    };

    const setDataChange = (name, value) => {
        setData({ ...data, [name]: value });
    };

    const fileUpload = (name, item) => {
        const urlName = name.replace("FileIdx", "SignedUrl");
        setData({ ...data, [name]: item.id, [urlName]: item.signedUrl });
    }

    const handleSaveClick = async () => {
        if (!data.siteIdx) {
            swal({ title: "알림", text: "사이트를 선택해 주세요." });
            return;
        }
        if (!data.eventIdx) {
            swal({ title: "알림", text: "이벤트를 선택해 주세요." });
            return;
        }
        if (!data.channelIdx) {
            swal({ title: "알림", text: "채널을 선택해 주세요." });
            return;
        }
        if (!data.question) {
            swal({ title: "알림", text: "설문을 입력해 주세요." });
            return;
        }

        _API.post({
            path: "/v1/a/event/feedback/quiz",
            data: {
                quizIdx: data.quizIdx,
                channelIdx: data.channelIdx,
                sessionIdx: data.sessionIdx || null,
                lectureIdx: data.lectureIdx || null,
                question: data.question,
                item1: data.item1 || null,
                item2: data.item2 || null,
                item3: data.item3 || null,
                item4: data.item4 || null,
                item5: data.item5 || null,
                item1FileIdx: data.item1FileIdx || null,
                item2FileIdx: data.item2FileIdx || null,
                item3FileIdx: data.item3FileIdx || null,
                item4FileIdx: data.item4FileIdx || null,
                item5FileIdx: data.item5FileIdx || null,
                monitorYn: data.monitorYn || "N",
                graphYn: data.graphYn || "N",
                status: data.status || "Y",
            },
        }).then((res) => {
            swal({ title: "알림", text: `퀴즈가 ${!data.quizIdx ? "생성" : "수정"}되었습니다.` }).then(() => {
                // _U.postMessage("EVENT-CHANGED");
                onCancel();

            });
        });
    };

    const onCancel = () => {
        props.changeMode && props.changeMode("");
    }


    useEffect(() => {
        loadEventList(siteIdx);
        loadData();
        if (!quizIdx) setData({ siteIdx });
    }, [quizIdx]);

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="ibox">
                    <div className="ibox-title">퀴즈 {!data?.eventIdx ? "등록" : "수정"}</div>
                    <div className="ibox-content">
                        <SelectForm title="이벤트명" name="eventIdx" value={data?.eventIdx} items={eventList} onChange={handleDataChange} nullTitle="이벤트 선택" />
                        <SelectForm title="채널명" name="channelIdx" value={data?.channelIdx} items={channelList} onChange={handleDataChange} nullTitle="채널 선택" />
                        <SelectForm title="세션명" name="sessionIdx" value={data?.sessionIdx} items={sessionList} onChange={handleDataChange} nullTitle="세션 선택" />
                        <SelectForm title="강좌명" name="lectureIdx" value={data?.lectureIdx} items={lectureList} onChange={handleDataChange} nullTitle="강좌 선택" />
                        <Separator />
                        <InputForm title="설문문항" name="question" placeholder="설문문항" value={data?.question} onChange={handleDataChange} />
                        <Separator />
                        <InputForm title="보기1" name="item1" placeholder="보기1" value={data?.item1} onChange={handleDataChange} />
                        <InputForm title="보기2" name="item2" placeholder="보기2" value={data?.item2} onChange={handleDataChange} />
                        <InputForm title="보기3" name="item3" placeholder="보기3" value={data?.item3} onChange={handleDataChange} />
                        <InputForm title="보기4" name="item4" placeholder="보기4" value={data?.item4} onChange={handleDataChange} />
                        <InputForm title="보기5" name="item5" placeholder="보기5" value={data?.item5} onChange={handleDataChange} />
                        <Separator />
                        <FileForm title="보기1 파일" accept="image/*" onChange={(v) => fileUpload("item1FileIdx", v)} path="EVENT/QUIZ" url={data?.item1SignedUrl} />
                        <FileForm title="보기2 파일" accept="image/*" onChange={(v) => fileUpload("item2FileIdx", v)} path="EVENT/QUIZ" url={data?.item2SignedUrl} />
                        <FileForm title="보기3 파일" accept="image/*" onChange={(v) => fileUpload("item3FileIdx", v)} path="EVENT/QUIZ" url={data?.item3SignedUrl} />
                        <FileForm title="보기4 파일" accept="image/*" onChange={(v) => fileUpload("item4FileIdx", v)} path="EVENT/QUIZ" url={data?.item4SignedUrl} />
                        <FileForm title="보기5 파일" accept="image/*" onChange={(v) => fileUpload("item5FileIdx", v)} path="EVENT/QUIZ" url={data?.item5SignedUrl} />
                        <Separator />
                        <SegmentedForm title="모니터출력여부" items={_DEF.STATUS} value={data?.monitorYn} onClick={(v) => setDataChange("monitorYn", v)} />
                        <SegmentedForm title="그래프출력여부" items={_DEF.STATUS} value={data?.graphYn} onClick={(v) => setDataChange("graphYn", v)} />
                        {/* <SegmentedForm title="사용여부" items={_DEF.STATUS} value={data?.status} onClick={(v) => setDataChange("status", v)} /> */}
                        <Separator />
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <div className="btn-group">
                                    <Button onClick={handleSaveClick} className="btn btn-primary">
                                        {!data.quizIdx ? "생성" : "수정"}
                                    </Button>
                                    <Button onClick={onCancel}>취소</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

class ExcelDownloader extends React.Component {
    constructor(props) {
        super(props);

        const state = {
            quizList: props?.data?.quizList || [],
            answerList: props?.data?.answerList || [],
            eventName: props?.data?.eventName || "",
            channelName: props?.data?.channelName || "",
            sessionName: props?.data?.sessionName || ""
        };
        this.state = state;
    }

    componentWillReceiveProps = (props) => {
        this.setState({
            quizList: props?.data?.quizList || [],
            answerList: props?.data?.answerList || [],
            eventName: props?.data?.eventName || "",
            channelName: props?.data?.channelName || "",
            sessionName: props?.data?.sessionName || "",
        });
    };

    save() {
        this.excel && this.excel.save();
    }

    getList() {
        const { answerList, quizList } = this.state;
        for (let i in answerList) {
            const item = answerList[i];
            if (!item.participantName) {
                item.participantName = item.participantEmail;
            }
            const quizInfoList = item.quizInfo?.split(",");
            for (let j in quizInfoList) {
                const quizInfo = quizInfoList[j]?.split(":");
                if (quizInfo && quizInfo.length == 2) item[quizInfo[0]] = quizInfo[1];
            }

        }
        return answerList;
    }

    getExcelTitle() {
        const title = [];
        this.state.eventName && title.push(this.state.eventName + "_");
        this.state.channelName && title.push(this.state.channelName + "_");
        this.state.sessionName && title.push(this.state.sessionName + "_");
        title.push("_퀴즈 결과_");
        title.push(moment().format("YYYYMMDDHHmmss"));
        return title.join("");
    }

    getOptionalColumn() {
        const { quizList } = this.state;
        const list = [];
        for (let i in quizList) {
            const item = quizList[i];
            list.push(<ExcelExportColumn field={`QUIZ_${item.idx}`} title={`${item.question}`} width={250} />);
        }

        return list;
    }

    render() {
        const excelName = this.getExcelTitle();
        const list = this.getList();
        const today = moment().format("YYYYMMDDHHmmss");
        if (!list) {
            return "";
        }

        return (
            <ExcelExport data={list} fileName={`${excelName}.xlsx`} ref={(ref) => (this.excel = ref)}>

                <ExcelExportColumn field="participantName" title="참가자명" width={250} />
                {this.getOptionalColumn().map((item, i) => (
                    <Fragment key={i}>
                        {item}
                    </Fragment>
                ))}
            </ExcelExport>
        );
    }
}
