import React, { useContext, useState, useEffect, Fragment } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import swal from "sweetalert";

import Layout from "../Layout";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";
import { _DEF } from "../../../modules/config";

import { SearchInput, SelectForm, InputForm, SegmentedForm, TextForm, DatePicker } from "../../../components/UI/Form";
import Paging, { ToPage, GetParams } from "../../../components/UI/Paging";
import { Separator } from "../../../components/UI/Wrapper";

import qs from "query-string";

const MEMBER_TYPES = [
    { label: "개발자", value: "DEVELOPER" },
    { label: "슈퍼어드민", value: "SUPERADMIN" },
    { label: "어드민", value: "ADMIN" },
    { label: "운영자", value: "OPERATOR" },
    { label: "커리어", value: "MEMBER" },
];

const CAREER_STATUS = [
    { label: "대기", value: "W" },
    { label: "정상", value: "Y" },
    { label: "사용안함", value: "N" },
];

const CAREER_TYPE = [
    { label: "EDUCATION", value: "EDU" },
    { label: "EXPERIENCE", value: "EXP" },
];

const CAREER_TYPE_NM = {
     EDU : "EDUCATION"
    ,EXP : "EXPERIENCE"
}

export default (props) => {

    const { history, location, match } = props;

    let { lecturerIdx } = useParams();
    // const history = useHistory();
    
    const [mode, setMode] = useState("");
    const [name, setName] = useState("");
    const [keyword, setKeyword] = useState("");
    const [list, setList] = useState([]);
    const [paging, setPaging] = useState(null);

    const onAddClick = () => {
        setMode("ADD");
    };

    const onModify = (idx) => {
        setMode(idx);
    };

    const getBeforePageNo = () => {
        const search = location?.search;
        if (!search) return null;

        const params = qs.parse(search);
        return params.before_page || 1;
    }

    const loadPage = (data) => {
        const param = { ...data, cvIdx : lecturerIdx === "all" ? "" : lecturerIdx };
        // const param = { page : 1 };

        const req = { path: "/v1/a/lecturer/career/list", data: param };
        _API.get(req).then((res) => {
            const { lecturerCareerList, info, paging } = res.data;
            setList(lecturerCareerList);
            setPaging(paging);
            
            info && setName(info.lastName + " " + info.firstName);
        });
    };

    const movePage = (page) => {
        ToPage(location, history, { keyword, page });
    };

    const onSearch = (e) => {
        ToPage(location, history, { keyword, page: 1 });
    };

    const onRefresh = () => {
        loadPage({ keyword, page: paging.pageNo });
    };

    const onLecturerChange = () => {
        setMode("");
        onRefresh();
    };

    useEffect(() => {
        const params = GetParams(location);
        if (params) { 
            setKeyword(params.keyword);
            loadPage({...params});
        } else {
            loadPage({ page: 1});
        }

    }, [location, lecturerIdx]);

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-8">
                    <h2>커리어 목록</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/admin">HOME</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to={`${location.pathname}`}>CV 관리</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>커리어</strong>
                        </li>
                    </ol>
                </div>
                <div className="col-sm-4">
                    <div className="title-action">
                        { lecturerIdx !== "all"
                            && 
                                <Fragment>
                                    <button type="button" className="btn btn-info" onClick={(e) => history.replace(`/admin/lecturer/list?page=${getBeforePageNo()}`)} style={{marginRight:"5px"}}>
                                        <i className="fa fa-arrow-left"></i> 뒤로
                                    </button>
                                    <button type="button" className="btn btn-primary" onClick={onAddClick.bind(this)}>
                                        <i className="fa fa-plus"></i> 추가
                                    </button>
                                </Fragment> 
                        }
                    </div>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className={cx({ "col-md-12": !mode, "col-md-8": mode })}>
                        <div className="ibox">
                            <div className="ibox-title">목록
                                { lecturerIdx !== "all" && name &&
                                    <span className="label label-info">{name}</span>
                                }
                            </div>
                            {/* <div className="ibox-content ibox-heading">
                                <div className="row">
                                    <div className="col-md-4">
                                        <SearchInput value={keyword} onChange={(e) => setKeyword(e.target.value)} onClick={onSearch} placeholder="검색어를 입력해 주세요." />
                                    </div>
                                </div>
                            </div> */}
                            <div className="ibox-content">
                                <LecturerCareerList idx={lecturerIdx} list={list} paging={paging} onChange={onRefresh} onModify={onModify} />
                            </div>
                            {paging && (
                                <div className="ibox-footer">
                                    <Paging paging={paging} pageCallback={movePage} />
                                </div>
                            )}
                        </div>
                    </div>
                    {mode && (
                        <div className={cx({ "col-md-4": true })}>
                            <CareerForm idx={mode} cvIdx={lecturerIdx} onChange={onLecturerChange} />
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
};

const LecturerCareerList = (props) => {
    const { idx, onChange, list, paging, onModify } = props;
    
    const movePage = (page) => {
        ToPage(location, history, { keyword, page });
    };

    return (
        <Fragment>
            <div className="table-responsive">
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th className="text-center">경력구분</th>
                            <th className="text-center">경력명</th>
                            <th className="text-center">기간</th>
                            <th className="text-center">{ idx && idx !== `all` && `편집` }</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list?.map((item, i) => (
                            <tr key={i}>
                                <td className="text-center">{CAREER_TYPE_NM[item.careerType]}</td>
                                <td className="text-center">{item.title}</td>
                                <td className="text-center">{moment(item.startDate).format("YYYY-MM-DD") + "~" + moment(item.endDate).format("YYYY-MM-DD")}</td>
                                    <td className="text-center">
                                        { idx && idx !== 'all' && 
                                        <div className="btn-group">
                                            <button type="button" className="btn btn-xs btn-warning" onClick={(e) => onModify && onModify(item.idx)}>
                                                <i className="fa fa-edit"></i> 수정
                                            </button>
                                        </div>
                                        }
                                    </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </Fragment>
    );
};

const CareerForm = (props) => {
    const { idx, cvIdx, onChange } = props;
    const { iam } = useContext(AppContext);

    const [career, setCareer] = useState({});
    
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    
    const onDelete = (idxKey, urlKey) => {
        onSave({ ...data, [idxKey]: null, [urlKey]: null });
    };
    
    const onSave = () => {
        const data = { ...career };
        data.startDate = moment(startDate).format("YYYY-MM-DD");
        data.endDate = moment(endDate).format("YYYY-MM-DD");
        data.cvIdx = cvIdx;

        var a = moment

        if (idx !== "ADD") {
            data.idx = idx;
        }
        if (!data.title) {
            swal({ title: "알림", text: "경력명을 입력해주세요." });
            return;
        }
        if (!data.careerType) {
            swal({ title: "알림", text: "경력구분을 선택해주세요." });
            return;
        }
        if (!startDate) {
            swal({ title: "알림", text: "기간을 입력해주세요." });
            return;
        }
        if (!endDate) {
            swal({ title: "알림", text: "기간을 입력해주세요." });
            return;
        }
        _API.post({
            path: "/v1/a/lecturer/career/save",
            data: data,
        }).then((res) => {
            swal({ title: "알림", text: `경력이 ${( idx !== "ADD" && idx ) ? "수정" : "등록"} 되었습니다.` }).then(() => {
                onChange && onChange();
            });
        });
    };

    
    const loadData = () => {
        if (idx === "ADD") {
            setCareer({});
            setStartDate(null);
            setEndDate(null);
            return;
        }
        const req = {
            path: "/v1/a/lecturer/career/info",
            data: { idx },
        };
        _API.get(req).then((res) => {
            setStartDate(moment(res.data.startDate).format("YYYY-MM-DD"));
            setEndDate(moment(res.data.endDate).format("YYYY-MM-DD"));
            setCareer({ ...res.data});

        });
    };

    useEffect(() => {
        loadData();
    }, [idx]);
    
    useEffect(() => {
    }, []);

    return (
        <div className="ibox">
            <div className="ibox-title">커리어 등록/수정</div>
            <div className="ibox-content">
                <InputForm title="경력명" name="title" value={career?.title} onChange={(e) => setCareer({ ...career, [e.target.name]: e.target.value })} placeholder="경력명을 입력해 주세요" />
                <SegmentedForm title="경력구분" items={CAREER_TYPE} value={career?.careerType} onClick={(v) => setCareer({ ...career, careerType: v })} />
                <DatePicker title="기간" value={startDate} onChange={(v) => setStartDate(v.value)} />
                <DatePicker title=" " value={endDate} onChange={(v) => setEndDate(v.value)} />
                <Separator />
                <SegmentedForm title="상태" items={CAREER_STATUS} value={career?.status} onClick={(v) => setCareer({ ...career, status: v })} />
                <Separator />
                <TextForm title="설명" name="description" placeholder="설명" value={career?.description} onChange={(e) => setCareer({ ...career, [e.target.name]: e.target.value })} />
                <Separator />
                <div className="row">
                    <div className="col-md-12 text-right">
                        <div className="btn-group">
                            <button type="button" className="btn btn-primary" onClick={onSave}>
                                <i className="fa fa-save"></i> {idx === "ADD" ? "등록" : "수정"}
                            </button>
                            <button type="button" className="btn btn-default" onClick={onChange}>
                                <i className="fa fa-times"></i> 취소
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};