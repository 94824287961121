import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import swal from "sweetalert";

import { AppContext } from "../../../components/AppProvider";

import { Content, InputForm, SelectForm, ButtonForm, HRDash, Wrapper, H2 } from "./Components";
import { _API, _U } from "../../../modules/utils";

import { _DEF } from "../../../modules/config";

export default (props) => {
    const { code, history, location, match } = props;

    const { cfg } = useContext(AppContext);
    const [idx, setIdx] = useState(null);
    const [item, setItem] = useState({});
    const [readOnly, setReadOnly] = useState(false);
    
    const sessionCode = "_REGISTRATION_MODIFY";

    useEffect(() => {
        _U.postMessage("LOCATION-CHANGED");

        if (!location?.pathname.includes("modify")) {
            _U.removeSession(sessionCode);
            setReadOnly(false);
        }else{
            setReadOnly(true);
        }

        const session = _U.getSession(sessionCode);

        if (session) {
            if (session.code === sessionCode && session && session.idx) {
                setIdx(session.idx);
            }
        }
    }, []);

    const loadData = () => {
        if (idx) {
            const data = { idx: idx, siteIdx: cfg.idx, eventIdx: cfg.event?.eventIdx };

            _API.get({
                path: "/v1/site/event/registration/item",
                data: data,
            }).then((res) => {
                if (res.error === 0) {
                    setItem(res.data);
                } else {
                    swal(res.message);
                }
            });
        }
    };

    useEffect(() => {
        loadData();
    }, [idx]);

    const onComplete = () => {
        history?.push("/registration/check");
    };

    return (
        <Wrapper>
            <H2>사전등록</H2>
            <Content code="registration" />
            <Form onComplete={onComplete} item={item}/>
        </Wrapper>
    );
};

const Form = (props) => {
    const { cfg } = useContext(AppContext);
    const [item, setItem] = useState({});
    const [regTypeName, setRegTypeName] = useState("");
    const {readOnly} = props;

    useEffect(() => {
        if (props.item) {
            setItem(props.item);
            setRegTypeName(props.item.regTypeName);
        }
    }, [props.item]);

    const onSetItem = (e) => {
        const target = e.target;
        setItem((o) => {
            return { ...o, [target.name]: target.value };
        });
    };

    const getTeamWareSize = () => {
        let size = cfg.event?.preRegTeamWareSize;

        if (size) {
            size = size.split(",");

            const items = [];
            for (let i in size) {
                items.push({
                    label: size[i],
                    value: size[i],
                });
            }
            return <SelectForm title="팀복 사이즈" name="teamWareSize" nullTitle="선택" items={items} value={item.teamWareSize || ""} onChange={onSetItem} />;
        }

        return "";
    };

    const onSave = () => {
        // 필수 여부 체크
        const { companyEsYn , emailEsYn, licenseEsYn, nameEsYn, phoneEsYn, regTypeEsYn } = cfg.event;
        if (companyEsYn === "Y" && !item.companyName) {
            swal("소속을 입력하세요.");
            return false;
        }

        if (emailEsYn === "Y" && !item.email) {
            swal("이메일을 입력하세요.");
            return false;
        }
        
        if (emailEsYn === "Y" && !_U.isEmail(item.email)) {
            swal("올바른 이메일을 입력하세요.");
            return false;
        }

        if (licenseEsYn === "Y" && !item.licenseNumber) {
            swal("면허번호를 입력하세요.");
            return false;
        }

        if (nameEsYn === "Y" && !item.name) {
            swal("성명을 입력하세요.");
            return false;
        }

        if (phoneEsYn === "Y" && !item.phone) {
            swal("휴대폰을 입력하세요.");
            return false;
        }

        if (regTypeEsYn === "Y" && !item.regType) {
            swal("등록구분을 선택하세요.");
            return false;
        }
        
        if (regTypeEsYn === "Y" && item.regType === "ETC" && regTypeName) {
            swal("등록구분(기타)를 입력하세요.");
            return false;
        }

        const data = { ...item, siteIdx: cfg.idx, eventIdx: cfg.event?.eventIdx, regTypeName };
        // console.log(data); return;
        _API.post({
            path: "/v1/site/event/registration",
            data: data,
        }).then((res) => {
            swal({ title: "알림", text: item.idx ? `사전등록 수정이 완료 되었습니다.` : `사전등록이 완료 되었습니다.` }).then(() => {
                props.onComplete && props.onComplete();
            });
        });
    };

    const onBack = () => {
        history.back();
    };

    const regTypeNameHandler = (e) => {
        onSetItem(e);
        setRegTypeName(e.target.value === "ETC" ? "" : e.target[e.target.selectedIndex].innerText);
    }

    useEffect(() => {
        if(item.regType === "ETC"){
            setItem({...item, regTypeName : "regTypeName"});
        }
    }, [item.regType])

    return (
        <Fragment>
            <InputForm title="성명" name="name" value={item.name || ""} onChange={onSetItem} />
            <InputForm title="이메일" name="email" value={item.email || ""} onChange={onSetItem} />
            <InputForm title="휴대전화번호" name="phone" value={item.phone || ""} onChange={onSetItem} />
            <InputForm title="소속" name="companyName" value={item.companyName || ""} onChange={onSetItem} />
            <InputForm title="부서명" name="deptName" value={item.deptName || ""} onChange={onSetItem} />
            <InputForm title="직책" name="position" value={item.position || ""} onChange={onSetItem} />
            <InputForm title="면허번호" name="licenseNumber" value={item.licenseNumber} onChange={onSetItem} placeholder="면허번호를 입력해 주세요" />
            <SelectForm
                title="등록구분"
                name="regType"
                items={_DEF.PARTICIPANT_REG_TYPE}
                value={item.regType}
                onChange={regTypeNameHandler}
                nullTitle="등록구분 선택"
            />
            {
                item.regType === "ETC" &&
                <InputForm title="" name="regTypeName" value={regTypeName} onChange={(e) => setRegTypeName(e.target.value)} placeholder={`[등록구분 - 기타] 입력해 주세요.`} />
            }
            <HRDash />
            <ButtonForm title="확인" type="SUBMIT" onClick={onSave} />
            <ButtonForm title="취소" type="RESET" onClick={onBack} />
        </Fragment>
    );
};
