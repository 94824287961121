import React, { useContext } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";

import Layout from "../Layout";
import { Separator } from "../../../components/UI/Wrapper";

import { _CFG } from "../../../modules/config";
import { _CK, _API } from "../../../modules/utils";

export default (props) => {
    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-12">
                    <h2>대시보드</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/dashboard">HOME</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/dev/dashboard">개발자</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>대시보드</strong>
                        </li>
                    </ol>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox">
                            <div className="ibox-title">Author</div>
                            <div className="ibox-content" style={{ wordBreak: "break-all" }}>
                                System APIKey : {_CFG.apiKey}
                                <Separator />
                                Site APIKey : {_API.apiKey}
                                <Separator />
                                Authorization : {_CK.getAuth()}
                                <Separator />
                                Authorization URL Encoded : {encodeURIComponent(_CK.getAuth())}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
