import React, { Fragment, useContext, useEffect, useState } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Modal from "react-modal";
import LazyLoad from "react-lazyload";

import styled, { css } from "styled-components";
import swal from "sweetalert";
import moment from "moment";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U, _CK } from "../../../modules/utils";
import { Loading } from "../../../components/UI/Loading";
import { Theme } from "../../../components/GlobalStyles";

import { ConnectionButton, H2, H3, ItemEmpty, CloseButton } from "./UI/Components";

import { InputForm, SegmentedForm } from "../../../components/UI/Form";
import { _DEF } from "../../../modules/config";
import {
    ICFavoriteOn, ICFavoriteOnSet, ICFavoriteOff, ICFavoriteOffSet, ICBottom, ICBottomSet, ICTop, ICTopSet, getBgSet
    , PosterOnImg, PosterOnImgSet, PosterOffImg, PosterOffImgSet, ListOnImg, ListOnImgSet, ListOffImg, ListOffImgSet 
} from "./asset";

import EposterVideo from "./Eposter.Video";

export default (props) => {
    const { cfg, auth, iam } = useContext(AppContext);
    const event = cfg.event || null;
    const { siteIdx, eventIdx } = event;

    const [isLoading, setIsLoading] = useState(true);
    const [list, setList] = useState([]);
    const [categorys, setCategorys] = useState([]);
    const [searchType, setSearchType] = useState("ALL");
    const [video, setVideo] = useState(null);

    const [eposterIdx, setEposterIdx] = useState(null);

    const [keyword, setKeyword] = useState("");
    const [selectCategory, setSelectCategory] = useState("");

    const [mode, setMode] = useState("POSTER");

    const loadList = () => {
        _API.get({
            path: "/v1/site/event/eposter",
            data: {
                siteIdx,
                eventIdx,
                category: selectCategory,
                keyword,
                searchType,
            },
        }).then((res) => {
            const { list } = res.data;
            console.log("loadList -> list", list)

            setList(list);
            setIsLoading(false);
        });
    };

    const loadCategoryList = () => {
        _API.get({
            path: "/v1/site/event/eposter/category",
            data: {
                siteIdx,
                eventIdx,
                // keyword,
                searchType,
            },
        }).then((res) => {
            const { list } = res.data;
            console.log("loadCategoryList -> list", list)
            setCategorys(list);
        });
    };

    useEffect(() => {
        loadList();
        loadCategoryList();
    }, [siteIdx, eventIdx, selectCategory, searchType]);

    useEffect(() => {
        // loadCategoryList();
        loadList();
    }, [keyword, searchType]);

    const onPosterClick = (item) => {
        if (item.finalFileType === "VIDEO") {
            // setVideo(item);
            setEposterIdx(item.idx);
        } else {
            // _CK.set("pdfInfo", item);
            _CK.set("eposterIdx", item.idx);
            window.open("/#/eposter/pdf");
        }
    };

    if (isLoading) {
        return <Loading title="E-POSTER를 조회하고 있습니다." background="rgba(0, 0, 0, 0.1)" />;
    }

    return (
        <Wrapper>
            <H2>E-POSTER</H2>
            {/* <ModeButton onClick={e => setMode(mode === "POSTER" ? "LIST" : "POSTER")}>{mode === "POSTER" ? "LIST" : "POSTER"}</ModeButton> */}
            <Container>
                {event.eposterOptYn === 'Y' &&
                    <ButtonWrapper>
                        <ModeButtonWrapper>
                            <PosterButton onClick={e => setMode("POSTER")} isClicked={mode==="POSTER"} />
                            <ListButton onClick={e => setMode("LIST")} isClicked={mode==="LIST"} />
                        </ModeButtonWrapper>
                        {/* <SegmentedForm
                            items={_DEF.EPOSTER_SEARCH}
                            onClick={(v) => {
                                setSearchType(v);
                                setSelectCategory("");
                            }}
                            value={searchType}
                        /> */}

                        <SearchGridWrapper>
                            <FavoriteButton
                                ison={searchType === "FAVORITE"}
                                onClick={(v) => {
                                    searchType === "ALL" ? setSearchType("FAVORITE") : setSearchType("ALL");
                                    setSelectCategory("");
                                }} />

                            <InputForm placeholder="Search" name="keyword" value={keyword} onChange={(e) => { setKeyword(e.target.value) }} style={{ borderRadius: "5px", fontSize: "12px" }}
                            />
                        </SearchGridWrapper>

                        {/* <ButtonItem
                            ison={searchType === "ALL"}
                            onClick={(v) => {
                                setSearchType("ALL");
                                setSelectCategory("");
                            }}
                        >
                            ALL
                        </ButtonItem>
                        <ButtonItem
                            ison={searchType === "FAVORITE"}
                            onClick={(v) => {
                                setSearchType("FAVORITE");
                                setSelectCategory("");
                            }}
                        >
                            Favorite
                        </ButtonItem> */}
                    </ButtonWrapper>
                }
                <CategoryWrapper>
                    <CategorySearch
                        categorys={categorys}
                        keyword={keyword}
                        searchType={searchType}
                        onSearch={(e) => {
                            setKeyword(e.target.value);
                        }}
                        onClick={(category) => {
                            setSelectCategory(category);
                        }}
                        selectCategory={selectCategory}
                    />
                </CategoryWrapper>

                {list?.length > 0 ? (mode === "POSTER" ? (
                        <ListWrapper>
                            {list?.map((item, i) => (
                                <Fragment key={i}>
                                    <PosterItem>
                                        <LazyLoad>
                                            <PImageWrapper onClick={onPosterClick.bind(this, item)}>
                                                <PImage src={item.thumbnailSignedUrl} alt={item.finalFileName} title={item.finalFileName} />
                                            </PImageWrapper>
                                            <PTitle>{item.title}</PTitle>
                                            <PAuthor>
                                                {item.name} {item.companyName ? ` (${item.companyName})` : ""} <PAuthorComments>{`댓글 ${item.commentCnt}`}</PAuthorComments>
                                            </PAuthor>
                                        </LazyLoad>
                                    </PosterItem>
                                </Fragment>
                            ))}
                            <ItemEmpty length={list?.length} number={4} component={<PosterItem />} />
                        </ListWrapper>
                    ) : (
                <div className="table-responsive">
                    <table className="table table-striped table-hover" >
                        <thead>
                                <tr>
                                    <th className="text-center">분야</th>
                                    <th className="text-center">제목</th>
                                    <th className="text-center">발표자</th>
                                </tr>
                        </thead>
                            <tbody>
                                {list?.map((item, i) => (
                                    <tr key={i}>
                                        <td className="text-center">{item.categoryName}</td>
                                        <td className="text-center"><a onClick={onPosterClick.bind(this, item)} >{item.title}</a></td>
                                        <td className="text-center">{item.name}</td>
                                    </tr>
                                ))}
                            </tbody>
                    </table>
                </div>
                    ))
                 : (
                    <EmptyWrapper>
                        <H3>등록된 E-POSTER가 없습니다.</H3>
                    </EmptyWrapper>
                )}
                {eposterIdx && (
                    <Modal isOpen={eposterIdx ? true : false} ariaHideApp={false} style={isMobile ? Theme.basic.modal.eposter.mobile : Theme.basic.modal.eposter.pc}>
                        <CloseButton onClick={() => setEposterIdx("")} />
                        <EposterVideo idx={eposterIdx} />
                    </Modal>
                )}
            </Container>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 1200px;
    margin: 0 auto;
    padding-top: 3rem;
    // min-height: calc(100% - 192px);
    height: auto;

    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                width: 100%;
                padding: 1rem;
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                width: 100%;
                padding: 1rem;
            }
          `;
        }
    }}

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        width: 100%;
        padding: 1rem;
    }
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: 20% 70% 10%;
    gap: 10px;

    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                display: block;
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                display: block;
            }
          `;
        }
    }}
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        display: block;
    }
`;

const ButtonWrapper = styled.div`
    grid-column-start: 2;
    grid-column-end: 4;
    display: grid;
    grid-template-columns: 50% 50%;

    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                grid-template-columns: 10% 90%;
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                grid-template-columns: 10% 90%;
            }
          `;
        }
    }}
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        grid-template-columns: 10% 90%;
    }
`;

const ButtonItem = styled.div`
    grid-column-start: 1;
    grid-column-end: 2;
    text-align: center;
    position: relative;
    font-size: 14px;
    cursor: pointer;
    font-family: ${(props) => props.theme.fontFamily.medium};

    ${(props) =>
        props.ison
            ? `
        color:${props.theme.basic.color.title};
    `
            : `
        color:${props.theme.basic.color.off};
    `}

    :first-child {
        :after {
            content: "";
            position: absolute;
            width: 1px;
            height: 8px;
            border-left: 1px solid ${(props) => props.theme.basic.line.bar};
            right: 5px;
            top: 5px;
        }
    }

    :last-child {
        grid-column-start: 2;
        grid-column-end: 3;
    }

    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                grid-column-start: 2;
                grid-column-end: 3;

                :last-child {
                    grid-column-start: 3;
                    grid-column-end: 4;
                }
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                grid-column-start: 2;
                grid-column-end: 3;

                :last-child {
                    grid-column-start: 3;
                    grid-column-end: 4;
                }
            }
          `;
        }
    }}
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        grid-column-start: 2;
        grid-column-end: 3;

        :last-child {
            grid-column-start: 3;
            grid-column-end: 4;
        }
    }
`;

const CategoryWrapper = styled.div`
    .btn-group .btn {
        flex: 1;
    }

    .form-group {
        margin-bottom: 0.5rem;
    }

    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                margin-top: 15px;
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                margin-top: 15px;
            }
          `;
        }
    }}
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        margin-top: 15px;
    }
`;

const ListWrapper = styled.div`
    grid-column-start: 2;
    grid-column-end: 4;

    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
`;

const CategorySearch = (props) => {
    const { categorys, onSearch, keyword, onClick, selectCategory } = props;

    const [isMore, setIsMore] = useState(false);

    return (
        <>
            {/* <InputForm placeholder="Search" name="keyword" value={keyword} onChange={onSearch} style={{ borderRadius: "5px", fontSize: "12px" }} /> */}
            <CListWrapper ison={isMore}>
                <CItem onClick={onClick.bind(this, "")} ison={selectCategory === ""}>
                    All
                </CItem>
                {categorys?.map((item, i) => (
                    <CItem key={i} onClick={onClick.bind(this, item.keyword)} ison={selectCategory === item.keyword}>
                        {item.value}
                    </CItem>
                ))}
                {isMobile && <More onClick={() => setIsMore(!isMore)} ison={isMore} />}
            </CListWrapper>
        </>
    );
};

const CListWrapper = styled.div`
    padding: 1rem 0.5rem 0;
    overflow-y: auto;

    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                position: relative;

                margin-bottom: 0.5rem;

                ${(props) =>
                    props.ison
                        ? `
                max-height: auto;
                overflow: auto;        
                `
                        : `
                max-height: 80px;
                overflow: hidden;        
                `}
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                position: relative;

                margin-bottom: 0.5rem;

                ${(props) =>
                    props.ison
                        ? `
                max-height: auto;
                overflow: auto;        
                `
                        : `
                max-height: 80px;
                overflow: hidden;        
                `}
            }
          `;
        }
    }}

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        position: relative;

        margin-bottom: 0.5rem;

        ${(props) =>
        props.ison
            ? `
        max-height: auto;
        overflow: auto;        
        `
            : `
        max-height: 80px;
        overflow: hidden;        
        `}
    }
`;
const CItem = styled.div`
    margin-bottom: 0.5rem;
    font-size: 14px;

    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    ${(props) =>
        props.ison
            ? `
        color: ${props.theme.basic.color.title};
        font-family: ${props.theme.fontFamily.bold};
    `
            : `
        color: ${props.theme.basic.color.primary};
        font-family: ${props.theme.fontFamily.regular};
    `}

    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                display: inline-block;
                width: auto;
                margin-right: 0.5rem;
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                display: inline-block;
                width: auto;
                margin-right: 0.5rem;
            }
          `;
        }
    }}

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        display: inline-block;
        width: auto;
        margin-right: 0.5rem;
    }
`;

const More = styled.div`
    position: absolute;
    right: 5px;
    top: 60px;

    ${(props) =>
        props.ison
            ? `
        background-image: ${`url(${ICTop})`};
        background-image: -webkit-image-set(${getBgSet(ICTopSet)});
    `
            : `
    background-image: ${`url(${ICBottom})`};
    background-image: -webkit-image-set(${getBgSet(ICBottomSet)});    
    `}

    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    width: 13px;
    height: 8px;
`;

const PosterItem = styled.div`
    flex-basis: 23%;
    margin-left: 1%;
    margin-bottom: 25px;
    max-width:23%;
    :last-child {
        margin-left: 0px;
    }

    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                flex-basis: 48%;
                max-width:48%;
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                flex-basis: 48%;
                max-width:48%;
            }
          `;
        }
    }}
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        flex-basis: 48%;
        max-width:48%;
    }
`;

const PImageWrapper = styled.div`
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.basic.line.default};
    height: 290px;
    width: 100%;
    cursor: pointer;

    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                height: 230px;
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                height: 230px;
            }
          `;
        }
    }}
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        height: 230px;
    }
`;

const PImage = styled.img.attrs((props) => {
    return {
        src: props.src,
        srcSet: props.src,
    };
})`
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
`;
const PTitle = styled.div`
    color: ${(props) => props.theme.basic.color.primary};
    font-family: ${(props) => props.theme.fontFamily.medium};
    font-size: 16px;
    padding: 0 2px;
    margin-top: 3px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;
const PAuthor = styled.div`
    color: ${(props) => props.theme.basic.color.off};
    font-family: ${(props) => props.theme.fontFamily.regular};
    font-size: 14px;
    padding: 0 2px;
    margin-top: 5px;
`;
const PAuthorComments = styled.span`
    color: #dbdbdb;
    font-family: ${(props) => props.theme.fontFamily.regular};
    font-size: 12px;
    padding: 0 2px;
    margin-top: 5px;
`;

const EmptyWrapper = styled.div`
    grid-column-start: 2;
    grid-column-end: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 28px - 2rem);
    min-height: 300px;
`;

const FavoriteButton = styled.span`

    grid-column-start: 1;
    grid-column-end: 2;

    cursor: pointer;
    margin-left: 2rem;
    display: inline-block;

    ${(props) =>
        props.ison
            ? `
            background-image: ${`url(${ICFavoriteOn})`};
            background-image: -webkit-image-set(${getBgSet(ICFavoriteOnSet)});
        `
            : `
            background-image: ${`url(${ICFavoriteOff})`};
            background-image: -webkit-image-set(${getBgSet(ICFavoriteOffSet)});    
        `}

    background-repeat: no-repeat;
    background-position: center 2px;
    background-size: 100%;
    width: 22px;
    height: 34px;

    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                grid-column-start: 1;
                grid-column-end: 1;
                margin-left:auto;
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                grid-column-start: 1;
                grid-column-end: 1;
                margin-left:auto;
            }
          `;
        }
    }}

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        grid-column-start: 1;
        grid-column-end: 1;
        margin-left:auto;
    }
`;
const ModeButtonWrapper = styled.div`
    grid-column-start: 1;
    display: flex;
    justify-content: start;
    align-items: center;
`;

const SearchGridWrapper = styled.div`
    display: grid;
    grid-template-columns: 13% 84%;
    gap: 10px;
    grid-column-start: 2;

    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                grid-template-columns: 40% 57%;
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                grid-template-columns: 40% 57%;
            }
          `;
        }
    }}
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        grid-template-columns: 40% 57%;
    }
`;

const PosterButton = styled.label`
    width: 74px;
    height: 27px;
    margin-bottom: 0;
    background-image: 
        ${props => (props.isClicked ? `url(${PosterOnImg})` : `url(${PosterOffImg})`)};
    background-image:
        ${props => (props.isClicked ? `-webkit-image-set(${getBgSet(PosterOnImgSet)})` : `-webkit-image-set(${getBgSet(PosterOffImgSet)})`)};
    margin-left: 20px;
    cursor: pointer;
`;

const ListButton = styled.label`
    width: 74px;
    height: 18px;
    margin-bottom: 0;
    background-image: 
        ${props => (props.isClicked ? `url(${ListOnImg})` : `url(${ListOffImg})`)};
    background-image:
        ${props => (props.isClicked ? `-webkit-image-set(${getBgSet(ListOnImgSet)})` : `-webkit-image-set(${getBgSet(ListOffImgSet)})`)};
    margin-left: 20px;
    cursor: pointer;
`;