import React, { useContext, useEffect, useState } from "react";

import styled, { css } from "styled-components";
import swal from "sweetalert";
import moment from "moment";
import { isMobile } from "react-device-detect";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U, _CK } from "../../../modules/utils";
import { Loading } from "../../../components/UI/Loading";

import { H2, H3, ItemEmpty } from "./UI/Components";

import { chain } from 'lodash';

export default (props) => {
    const { cfg, auth, iam } = useContext(AppContext);
    const event = cfg.event || null;
    const [isShown, setIsShown] = useState({});
    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    if (!event) {
        return null;
    }

    const { siteIdx, eventIdx, channel, startDate, endDate } = event;

    if (!startDate || !endDate || channel?.length === 0) {
        return null;
    }
    useEffect(() => {
        loadList();
    }, []);

    const loadList = () => {

        const data = {
            siteIdx,
            eventIdx,
        };

        _API.get({ path: "/v1/event/company/satellite", data }).then(res => {
            const { list } = res.data;
            setList(list);
            setIsLoading(false);
        })
    }

    const onClickLogo = async (item) => {
        if (!_CK.isLogin()) {
            swal("로그인 후 이용이 가능합니다").then(() => {
                window.location.reload();
            });
        } else {
            window.open(`/#/channel/${item.channelIdx}`);
            _U.postMessage("CLOSE-MODAL");
        }
    };

    const onClickVideo = (item) => {
        _U.postMessage("ONLY-VIDEO"
            , {
                ...item
                , videoSignedUrl: item.satelliteVodSignedUrl
                , vodEncodedUrl: item.vodEncodedUrl
                , contentType: 'SATELLITE'
                , title: `SATELLITE SESSION - ${item.name}`
                , description: '화면에 재생버튼을 클릭하여 영상을 실행 시켜주세요.'
            });
    };

    const onClickProgram = (item) => {
        _U.postMessage("SATELLITE-AGENDA"
            , {
                ...item
                , contentType: 'SATELLITE-AGENDA'
                , title: `SATELLITE SESSION - ${item.name}`
                , satelliteIdx: item.satelliteIdx
            });
    };

    if (isLoading) {
        return <Loading title="SATELLITE를 조회하고 있습니다." background="rgba(0, 0, 0, 0.1)" />;
    }

    return (
        <Wrapper>
            <H2>Satellite Session</H2>
            {list?.length > 0 ? (
                <WrapperCompany>
                    {list.map((item, i) => (
                        <WrapperSatellite key={i} >
                            <Article>
                                <ArticleInfo>
                                    <EboothImg>
                                        <img src={item.finalSignedUrl} />
                                    </EboothImg>
                                </ArticleInfo>
                            </Article>
                            <WrapperButtons>
                                <SButton onClick={() => onClickVideo(item)} video={true}>video</SButton>
                                <SButton onClick={() => onClickProgram(item)}>program</SButton>
                            </WrapperButtons>
                            <WrapperCompanyName>{item.name}</WrapperCompanyName>
                        </WrapperSatellite>
                    ))}
                    {/* <ItemEmpty length={list?.length} number={4} component={<Article />} /> */}
                </WrapperCompany>
            ) : (
                <EmptyWrapper>
                    <H3>등록된 SATELLITE가 없습니다.</H3>
                </EmptyWrapper>
            )}
        </Wrapper>
    );
};
const Wrapper = styled.div`
    width: 1200px;
    margin: 0 auto;
    padding-top: 3rem;
    // min-height: calc(100% - 192px);
    height: auto;


    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                width: 100%;
                padding: 1rem;
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                width: 100%;
                padding: 1rem;
            }
        `;
        }
    }}

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        width: 100%;
        padding: 1rem;
    }
`
const SButton = styled.div`
    font-size: 16px;
    height: 42px;
    width: 137px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 14px;
    cursor: pointer;

    ${(props) =>
        props.video
            ? `
    color: ${props.theme.basic.color.white};    
    background-color: ${props.theme.basic.bg.default};
    border-radius:21px;
    `
            : `
    color: ${props.theme.basic.color.buttonConnection};
    border: solid 1px ${props.theme.basic.bg.default};
    border-radius:21px;
    `}

    font-family: ${(props) => props.theme.fontFamily.regular};
    text-align: center;
`

const WrapperSatellite = styled.div`

    width: 285px;
    margin: 0px 20px 30px 0px;
    :nth-child(4n) {
        margin: 0px 0px 30px 0px;
    }

    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                margin: 0;
                padding: 0 0 30px 0;
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                margin: 0;
                padding: 0 0 30px 0;
            }
        `;
        }
    }}

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        margin: 0;
        padding: 0 0 30px 0;
    }
`

const WrapperButtons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0 20px 0;
`

const WrapperCompanyName = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 16px;
`

const WrapperCompany = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                justify-content: center;
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                justify-content: center;
            }
        `;
        }
    }}

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        justify-content: center;
    }
`;

const EboothImg = styled.div`

    font-family: ${(props) => props.theme.fontFamily.medium};
    font-size: 12px;
    text-align: center;
    color: ${(props) => props.theme.basic.color.primary};

    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;

    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                width: 100%;
                height: 100%;
                min-height: 80px;
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                width: 100%;
                height: 100%;
                min-height: 80px;
            }
        `;
        }
    }}

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        width: 100%;
        height: 100%;
        min-height: 80px;
    }

    & img {
        width: 100%;
        min-height: 3rem;

        ${props => {
        if (isMobile) {
            return css`
                            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                                width: 80%;
                            }
                            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                                width: 80%;
                            }
                        `;
        }
    }}


        @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
            width: 80%;
        }
    }
`;

const Article = styled.div`
    align-items: center;
    flex-basis: 12%;
    background-color: ${(props) => props.theme.basic.bg.background};
    position: relative;

    height: 180px;

    ${props => {
        if (isMobile) {
            return css`
                    @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                        flex-basis: 31%;
                        margin: 0 0 8px 0;
                    }
                    @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                        flex-basis: 31%;
                        margin: 0 0 8px 0;
                    }
                `;
        }
    }}
    
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        flex-basis: 31%;
        margin: 0 0 8px 0;
    }
`;

const ArticleInfo = styled.div`
    position: relative;
    border: solid 1px ${(props) => props.theme.basic.line.default};
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;

    ${props => {
        if (isMobile) {
            return css`
                    @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                        padding: 5px;
                    }
                    @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                        padding: 5px;
                    }
                `;
        }
    }}

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        padding: 5px;
    }
`;

const HoverEffect = styled.div`
    position: absolute;
    padding-top: 10px;
    top: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    color: #ffffff !important;
    font-size: 14px;
    background-color: #000000;
    border-radius: 10px;
`;

const EmptyWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
