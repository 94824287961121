import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

import Layout from "../Layout";

export default (props) => {
    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-12">
                    <h2>인코딩/디코딩</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/dashboard">HOME</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/dev/dashboard">개발자</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>인코딩/디코딩</strong>
                        </li>
                    </ol>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="ibox">
                            <div className="ibox-title">
                                <h5>
                                    인코딩 <small>(URL Encode -&gt; Base64)</small>
                                </h5>
                            </div>
                            <div className="ibox-content">
                                <Encoder />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="ibox">
                            <div className="ibox-title">
                                <h5>
                                    디코딩 <small>(Base64 -&gt; URL Decode)</small>
                                </h5>
                            </div>
                            <div className="ibox-content">
                                <Decoder />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

const Encoder = () => {
    const [content, setContent] = useState("");
    const [convert, setConvert] = useState("");

    const handleConvert = () => {
        const data = btoa(encodeURIComponent(content));
        console.log(data);
        setConvert(data);
    };

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12">
                    <textarea
                        value={content}
                        className="form-control"
                        rows={5}
                        onChange={(e) => {
                            setContent(e.target.value);
                        }}
                    ></textarea>
                </div>
            </div>
            <div className="hr-line-dashed"></div>
            <div className="row">
                <div className="col-md-12">
                    <button type="button" className="btn btn-primary btn-block" onClick={handleConvert}>
                        <i className="fa fa-refresh" /> 변환
                    </button>
                </div>
            </div>
            <div className="hr-line-dashed"></div>
            <div className="row">
                <div className="col-md-12" style={{ wordBreak: "break-all" }}>
                    {convert}
                </div>
            </div>
        </Fragment>
    );
};

const Decoder = () => {
    const [content, setContent] = useState("");
    const [convert, setConvert] = useState("");

    const handleConvert = () => {
        const data = decodeURIComponent(atob(content));
        console.log(data);
        setConvert(data);
    };

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12">
                    <textarea
                        value={content}
                        className="form-control"
                        rows={5}
                        onChange={(e) => {
                            setContent(e.target.value);
                        }}
                    ></textarea>
                </div>
            </div>
            <div className="hr-line-dashed"></div>
            <div className="row">
                <div className="col-md-12">
                    <button type="button" className="btn btn-primary btn-block" onClick={handleConvert}>
                        <i className="fa fa-refresh" /> 변환
                    </button>
                </div>
            </div>
            <div className="hr-line-dashed"></div>
            <div className="row">
                <div className="col-md-12" style={{ wordBreak: "break-all" }}>
                    {convert}
                </div>
            </div>
        </Fragment>
    );
};
