import React, { useContext, useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import styled from "styled-components";
import swal from "sweetalert";

import Socket from "../../../../components/UI/Socket";
import Paging from "../../../../components/UI/Paging";

import { _API, _U } from "../../../../modules/utils";

let _TIMER = null;

export default (props) => {
    const { history, location, match } = props;
    const { idx } = match.params;

    const [time, setTime] = useState(-1);
    const [isConnect, setIsConnect] = useState(false);
    const [survey, setSurvey] = useState(null);
    const [fontSize, setFontSize] = useState(6);
    const [imgSize, setImgSize] = useState(6);
    const [graphSize, setGraphSize] = useState(8);
    const [isSizeControl, setIsSizeControl] = useState(false);

    const [paging, setPaging] = useState(null);
    const [surveyList, setSurveyList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [category, setCategory] = useState({ before: false, after: false });
    const [compareStatus, setCompareStatus] = useState();
    const [compareSurvey, setCompareSurvey] = useState({});

    const movePage = (page) => {
        if (page && surveyList && selectedCategory) {
            setPaging({ ...paging, pageNo: page });
            setSurvey(surveyList.filter(l => l.surveyCategory === selectedCategory)[page - 1]);
        }
    };

    const debugSocket = false; // _CFG.isLocal();
    const sendChannel = () => {
        _U.postMessage("SOCKET-SEND", {
            action: "CHANNEL",
            data: {
                type: "SURVEY-MONITOR",
                channel: idx,
            },
        });
    };

    const onConnected = () => {
        setIsConnect(true);
        sendChannel();
    };
    const onDisconnected = () => {
        setIsConnect(false);
    };

    const loadData = (liveUpdate) => {
        _API.get({
            path: "/v1/a/event/console/survey/monitor",
            data: {
                channelIdx: idx,
            },
        })
            .then((res) => res.data)
            .then((data) => {
                if (liveUpdate) {
                    setSurveyList(data?.list);
                } else {
                    const firstSelectedCategory = (!data?.beforeYn && data?.afterYn) ? "AFTER" : "BEFORE";
                    setSurveyList(data?.list);

                    setCategory({
                        before: data?.beforeYn,
                        after: data?.afterYn
                    });

                    setPaging({
                        startPageNo: 1,
                        endPageNo: data.list.filter(l => l.surveyCategory === firstSelectedCategory).length,
                        pageNo: 1,
                        needNotFirstPage: 'Y'
                    });

                    setSelectedCategory(firstSelectedCategory);
                }

            });
    };

    const onReceivedMessage = (e) => {
        _U.parseMessage(e)
            .then((data) => {
                switch (data.code) {
                    case "SOCKET-RECEIVED":
                        return data.data ? JSON.parse(data.data) : null;
                }
            })
            .then((data) => {
                if (data?.mode === "SURVEY-MONITOR") {
                    loadData(true);
                }
            });
    };

    const onArrowButton = (direction) => {

        const pageNo = paging?.pageNo;

        if ((direction === "back") && (paging?.startPageNo == pageNo)) {
            return;
        }
        if ((direction === "forward") && (paging?.endPageNo == pageNo)) {
            return;
        }

        movePage(direction === "forward" ? pageNo + 1 : pageNo - 1);
    }

    useEffect(() => {
        loadData();
        window.addEventListener("message", onReceivedMessage);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, []);

    useEffect(() => {
        sendChannel();
        const timer = setInterval(sendChannel, 10000);
        return () => clearInterval(timer);
    }, [idx]);

    useEffect(() => {
        setPaging({
            startPageNo: 1,
            endPageNo: surveyList.filter(l => l.surveyCategory === selectedCategory).length,
            pageNo: 1,
            needNotFirstPage: 'Y'
        })
        setSurvey(surveyList.filter(l => l.surveyCategory === selectedCategory)[0]);
    }, [selectedCategory]);

    useEffect(() => {
        movePage(paging?.pageNo || 1);
    }, [surveyList]);

    useEffect(() => {
        if (getCompareStatus() && paging) {
            const c = surveyList.filter(l => l.surveyCategory === (selectedCategory === "AFTER" ? "BEFORE" : "AFTER"))[paging?.pageNo - 1];
            if (c) {
                c.perAnswer1 = parseInt(c.cntAnswer1 === 0 ? 0 : (c.cntAnswer1 / c.cntTotal) * 100);
                c.perAnswer2 = parseInt(c.cntAnswer2 === 0 ? 0 : (c.cntAnswer2 / c.cntTotal) * 100);
                c.perAnswer3 = parseInt(c.cntAnswer3 === 0 ? 0 : (c.cntAnswer3 / c.cntTotal) * 100);
                c.perAnswer4 = parseInt(c.cntAnswer4 === 0 ? 0 : (c.cntAnswer4 / c.cntTotal) * 100);
                c.perAnswer5 = parseInt(c.cntAnswer5 === 0 ? 0 : (c.cntAnswer5 / c.cntTotal) * 100);
                setCompareSurvey(c);
            } else {
                setCompareSurvey(null);
            }
        }
    }, [survey]);

    const getCompareStatus = () => {
        if (category.before
            && category.after
            && survey.questionType != "SUBJECTIVE"
            && survey.questionType != "ANSWER"
            && surveyList.filter(l => l.surveyCategory === "BEFORE")?.length === surveyList.filter(l => l.surveyCategory === "AFTER")?.length
        ) {
            return true;
        }
        return false;
    }

    const surveyClose = () => {
        if (idx && survey) {
            _API.post({
                path: "/v1/a/event/console/survey/close",
                data: {
                    channelIdx: idx,
                    closeYn: survey?.closeYn === "Y" ? "N" : "Y",
                    surveyCategory: selectedCategory,
                },
            })
                .then((res) => {
                    swal((selectedCategory === "BEFORE" ? "사전" : "사후") + (survey?.closeYn === "Y" ? "설문마감이 취소되었습니다." : "설문이 마감되었습니다."));
                    loadData(true);
                });
        }
    }

    if (survey) {
        survey.perAnswer1 = parseInt(survey.cntAnswer1 === 0 ? 0 : (survey.cntAnswer1 / survey.cntTotal) * 100);
        survey.perAnswer2 = parseInt(survey.cntAnswer2 === 0 ? 0 : (survey.cntAnswer2 / survey.cntTotal) * 100);
        survey.perAnswer3 = parseInt(survey.cntAnswer3 === 0 ? 0 : (survey.cntAnswer3 / survey.cntTotal) * 100);
        survey.perAnswer4 = parseInt(survey.cntAnswer4 === 0 ? 0 : (survey.cntAnswer4 / survey.cntTotal) * 100);
        survey.perAnswer5 = parseInt(survey.cntAnswer5 === 0 ? 0 : (survey.cntAnswer5 / survey.cntTotal) * 100);
    }


    return (
        <Container>
            {survey ? (
                <Wrapper>
                    <Question fontSize={fontSize} imgSize={imgSize}>
                        <ButtonWrapper>
                            <CategoryWrapper>
                                {category.before && <button className={cx({ btn: true, "btn-xl": true, "btn-white": selectedCategory !== "BEFORE", "btn-success": selectedCategory === "BEFORE" })} onClick={(e) => setSelectedCategory("BEFORE")}>사전</button>}
                                {category.after && <button className={cx({ btn: true, "btn-xl": true, "btn-white": selectedCategory !== "AFTER", "btn-success": selectedCategory === "AFTER" })} onClick={(e) => setSelectedCategory("AFTER")}>사후</button>}
                                {getCompareStatus() &&
                                    <button
                                        className={cx({ btn: true, "btn-xl": true, "btn-white": compareStatus !== "Y", "btn-success": compareStatus === "Y" })}
                                        style={{ marginLeft: "5px" }}
                                        onClick={
                                            (e) => {
                                                setCompareStatus(compareStatus === "Y" ? "N" : "Y");
                                            }
                                        }>사전/사후 비교</button>
                                }
                                <button
                                    className={cx({ btn: true, "btn-xl": true, "btn-white": survey?.closeYn !== "Y", "btn-success": survey?.closeYn === "Y" })}
                                    style={{ marginLeft: "5px" }}
                                    onClick={(e) => surveyClose()}>{survey?.closeYn === "Y" ? "설문마감 취소" : "설문마감"}</button>
                            </CategoryWrapper>
                            <ArrowWrapper>
                                <ArrowButton onClick={() => onArrowButton("back")} type="button" className="btn btn-white" disalbed={paging?.startPageNo == paging?.pageNo} > « </ArrowButton>
                                <ArrowButton onClick={() => onArrowButton("forward")} type="button" className="btn btn-white" disalbed={paging?.endPageNo == paging?.pageNo}> » </ArrowButton>
                            </ArrowWrapper>
                        </ButtonWrapper>
                        {isSizeControl && (
                            <div className="row">
                                <div className="col-md-1 text-right" style={{ margin: "7px 15px 0 0" }}>
                                    질문창
                                </div>
                                <div className="col-md-2">
                                    <div className="input-group">
                                        <input type="number" className="form-control" value={fontSize} onChange={(e) => setFontSize(e.target.value)} />
                                        <div className="input-group-append">
                                            <div className="btn-group">
                                                <button className="btn btn-success" onClick={(e) => setFontSize(parseFloat((fontSize + 0.1).toFixed(1)))}>
                                                    <i className="fa fa-plus"></i>
                                                </button>
                                                <button className="btn btn-danger" onClick={(e) => setFontSize(parseFloat((fontSize - 0.1).toFixed(1)))}>
                                                    <i className="fa fa-minus"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-1 text-right" style={{ margin: "7px 15px 0 0" }}>
                                    질문 이미지
                                </div>
                                <div className="col-md-2">
                                    <div className="input-group">
                                        <input type="number" className="form-control" value={imgSize} onChange={(e) => setImgSize(e.target.value)} />
                                        <div className="input-group-append">
                                            <div className="btn-group">
                                                <button className="btn btn-success" onClick={(e) => setImgSize(parseFloat((imgSize + 0.1).toFixed(1)))}>
                                                    <i className="fa fa-plus"></i>
                                                </button>
                                                <button className="btn btn-danger" onClick={(e) => setImgSize(parseFloat((imgSize - 0.1).toFixed(1)))}>
                                                    <i className="fa fa-minus"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-1 text-right" style={{ margin: "7px 15px 0 0" }}>
                                    그래프
                                </div>
                                <div className="col-md-2">
                                    <div className="input-group">
                                        <input type="number" className="form-control" value={graphSize} onChange={(e) => setGraphSize(e.target.value)} />
                                        <div className="input-group-append">
                                            <div className="btn-group">
                                                <button className="btn btn-success" onClick={(e) => setGraphSize(parseFloat((graphSize + 0.1).toFixed(1)))}>
                                                    <i className="fa fa-plus"></i>
                                                </button>
                                                <button className="btn btn-danger" onClick={(e) => setGraphSize(parseFloat((graphSize - 0.1).toFixed(1)))}>
                                                    <i className="fa fa-minus"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="title" dangerouslySetInnerHTML={{ __html: survey.question }} onClick={(e) => setIsSizeControl(!isSizeControl)} />
                        {survey.answer1 && (
                            <div className="answer">
                                {survey.answer1}
                            </div>
                        )}
                        {survey.answer2 && (
                            <div className="answer">
                                {survey.answer2}
                            </div>
                        )}
                        {survey.answer3 && (
                            <div className="answer">
                                {survey.answer3}
                            </div>
                        )}
                        {survey.answer4 && (
                            <div className="answer">
                                {survey.answer4}
                            </div>
                        )}
                        {survey.answer5 && (
                            <div className="answer">
                                {survey.answer5}
                            </div>
                        )}

                    </Question>
                    <Chart fontSize={graphSize}>
                        <div className="title">설문결과</div>
                        {survey.answer1 && (
                            <div className="answer">
                                <h3>
                                    {survey.answer1} <small>{survey.perAnswer1}%</small>
                                </h3>
                                <Graph fontSize={graphSize} percent={survey.perAnswer1}>
                                    <div />
                                </Graph>
                                {getCompareStatus() && compareStatus === "Y" &&
                                    <>
                                        <h3 style={{ marginTop: "10px" }}>
                                            &nbsp; <small>{compareSurvey.perAnswer1}%</small>
                                        </h3>
                                        <Graph2 fontSize={graphSize} percent={compareSurvey.perAnswer1}>
                                            <div />
                                        </Graph2>
                                    </>
                                }
                            </div>
                        )}
                        {survey.answer2 && (
                            <div className="answer">
                                <h3>
                                    {survey.answer2} <small>{survey.perAnswer2}%</small>
                                </h3>
                                <Graph fontSize={graphSize} percent={survey.perAnswer2}>
                                    <div />
                                </Graph>
                                {getCompareStatus() && compareStatus === "Y" &&
                                    <>
                                        <h3 style={{ marginTop: "10px" }}>
                                            &nbsp; <small>{compareSurvey.perAnswer2}%</small>
                                        </h3>
                                        <Graph2 fontSize={graphSize} percent={compareSurvey.perAnswer2}>
                                            <div />
                                        </Graph2>
                                    </>
                                }
                            </div>
                        )}
                        {survey.answer3 && (
                            <div className="answer">
                                <h3>
                                    {survey.answer3} <small>{survey.perAnswer3}%</small>
                                </h3>
                                <Graph fontSize={graphSize} percent={survey.perAnswer3}>
                                    <div />
                                </Graph>
                                {getCompareStatus() && compareStatus === "Y" &&
                                    <>
                                        <h3 style={{ marginTop: "10px" }}>
                                            &nbsp; <small>{compareSurvey.perAnswer3}%</small>
                                        </h3>
                                        <Graph2 fontSize={graphSize} percent={compareSurvey.perAnswer3}>
                                            <div />
                                        </Graph2>
                                    </>
                                }
                            </div>
                        )}
                        {survey.answer4 && (
                            <div className="answer">
                                <h3>
                                    {survey.answer4} <small>{survey.perAnswer4}%</small>
                                </h3>
                                <Graph fontSize={graphSize} percent={survey.perAnswer4}>
                                    <div />
                                </Graph>
                                {getCompareStatus() && compareStatus === "Y" &&
                                    <>
                                        <h3 style={{ marginTop: "10px" }}>
                                            &nbsp; <small>{compareSurvey.perAnswer4}%</small>
                                        </h3>
                                        <Graph2 fontSize={graphSize} percent={compareSurvey.perAnswer4}>
                                            <div />
                                        </Graph2>
                                    </>
                                }
                            </div>
                        )}
                        {survey.answer5 && (
                            <div className="answer">
                                <h3>
                                    {survey.answer5} <small>{survey.perAnswer5}%</small>
                                </h3>
                                <Graph fontSize={graphSize} percent={survey.perAnswer5}>
                                    <div />
                                </Graph>
                                {getCompareStatus() && compareStatus === "Y" &&
                                    <>
                                        <h3 style={{ marginTop: "10px" }}>
                                            &nbsp; <small>{compareSurvey.perAnswer5}%</small>
                                        </h3>
                                        <Graph2 fontSize={graphSize} percent={compareSurvey.perAnswer5}>
                                            <div />
                                        </Graph2>
                                    </>
                                }
                            </div>
                        )}
                    </Chart>
                </Wrapper>
            ) : (
                <Wait>
                    <div>SURVEY</div>
                </Wait>
            )}
            <SocketWrapper debug={debugSocket}>
                <Socket debug={debugSocket} onConnected={onConnected} onDisconnected={onDisconnected} />
            </SocketWrapper>
        </Container>
    );
};

const Container = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
`;

const Wait = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;

    align-items: center;
    justify-content: center;

    div {
        padding: 1vh 3vw;
        background: #3e3e3e;
        font-size: 10vw;
        font-weight: bold;
        color: #ffffff;
    }
`;
const SocketWrapper = styled.div`
    position: fixed;
    right: 0;
    bottom: 0;
    ${(props) =>
        props.debug &&
        `
        width:450px; height:250px;
    `}
`;

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
`;

const Question = styled.div`
    width: 70vw;
    padding: 2rem 4rem;
    color: #3e3e3e;
    .title {
        margin: 0 0 2rem;
        font-size: ${(props) => props.fontSize - 3}rem;
        cursor: pointer;
    }
    .answer {
        margin: 0 0 1rem;
        font-size: ${(props) => props.fontSize - 4}rem;
    }
    .answer img {
        margin-left: 1vw;
        max-width: ${(props) => props.imgSize}rem;
        max-height: ${(props) => props.imgSize}rem;
    }
    p,
    div {
        /* margin: 0; */
        padding: 0;
    }
`;

const Chart = styled.div`
    width: 30vw;
    padding: 2rem;
    background: #10224a;
    color: white;
    .title {
        margin-bottom: 3vh;
        font-size: ${(props) => props.fontSize - 6}rem;
        font-weight: bold;
        text-align: center;
    }
    .answer {
        margin-bottom: 3vh;
    }
    .answer h3 {
        font-size: ${(props) => props.fontSize - 7}rem;
    }
    .answer h3 small {
        float: right;
        clear: right;
    }
    p,
    div {
        margin: 0;
        padding: 0;
    }
`;

const Graph = styled.div`
    width: 100%;
    height: ${(props) => props.fontSize - 7}rem;
    border-radius: ${(props) => (props.fontSize - 7) / 2}rem;
    overflow: hidden;
    background: white;
    div {
        width: ${(props) => Math.max(props.percent, 1)}%;
        height: 100%;
        background: #0c80e3;
    }
`;
const Graph2 = styled.div`
    width: 100%;
    height: ${(props) => props.fontSize - 7}rem;
    border-radius: ${(props) => (props.fontSize - 7) / 2}rem;
    overflow: hidden;
    background: white;
    div {
        width: ${(props) => Math.max(props.percent, 1)}%;
        height: 100%;
        background: #ff2d4a;
    }
`;

const Running = styled.div`
    width: 30vw;
    background: #10224a;
    color: white;
    display: flex;

    align-items: center;
    justify-content: center;
    div {
        width: 20vw;
        height: 20vw;
        border: #0c80e3 solid 1px;
        border-radius: 10vw;
        padding: 1vw;
        display: flex;
    }
    div p {
        width: 18vw;
        height: 18vw;
        font-size: ${(props) => props.fontSize - 6}rem;
        line-height: 18vw;
        background: #0c80e3;
        color: #ffffff;
        border-radius: 9vw;
        text-align: center;
        font-weight: bold;
    }
`;
const ButtonWrapper = styled.div`
    margin-bottom: 2rem;
`

const CategoryWrapper = styled.div`
    display: inline;
`

const ArrowWrapper = styled.div`
    float: right;
    display: inline;
`
const ArrowButton = styled.button`
    ${({ disalbed }) => disalbed && `
        color : #ccc;
        background-color: #e9ecef;
    `}
`
