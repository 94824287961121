import React from "react";
import axios from "axios";
import NumberFormat from "react-number-format";

const utils = {};

utils.isEmail = (email) => {
    if (!email) {
        return false;
    }
    const reg = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/gim;
    if (reg.test(email.toLowerCase())) {
        return true;
    }
    return false;
};

utils.encode = (o) => {
    return btoa(encodeURIComponent(JSON.stringify(o)));
};

utils.decode = (str) => {
    return JSON.parse(decodeURIComponent(atob(str)));
};

utils.setSession = (code, value) => {
    window.sessionStorage.setItem(code, utils.encode(value));
};

utils.getSession = (code) => {
    if (window.sessionStorage.getItem(code)) {
        return utils.decode(window.sessionStorage.getItem(code));
    }
    return false;
};

utils.removeSession = (code) => {
    window.sessionStorage.removeItem(code);
};

utils.postMessage = (code, data) => {
    window.postMessage(JSON.stringify({ code: code, data: data }), window.location.origin);
};

utils.parseMessage = (e) => {
    return new Promise((resolve, reject) => {
        if (!e.data) {
            reject && reject();
            return;
        }
        let data = null;
        try {
            data = typeof e.data === "string" && e.data.startsWith("{") ? JSON.parse(e.data) : e.data;
        } catch (e) {
            reject && reject();
            return;
        }
        try {
            if (data && data.code) {
                resolve && resolve(data);
            }
        } catch (e) {
            reject && reject(e);
        }
    });
};

utils.getGEO = (key, addr) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`https://maps.googleapis.com/maps/api/geocode/json?key=${key}&sensor=false&language=ko&address=${addr}`)
            .then((res) => {
                if (res.status !== 200) {
                    throw new Error(res.statusText);
                }

                const { data } = res;
                if (data.results.length > 0) {
                    const locate = data.results[0].geometry.location;
                    resolve(locate);
                } else {
                    throw new Error("GEO ������ Ȯ�� �� �� ���� �ּ� �Դϴ�.");
                }
            })
            .catch((e) => {
                reject(e);
            });
    });
};

utils.getDefinedLabel = (defineds, value, nullText) => {
    if (!defineds || !value) return "";
    let returnText = "";
    defineds?.map((item, i) => {
        if (item.value === value) {
            returnText = item.label;
        }
    });
    return returnText ? returnText : nullText || "";
}

utils.shortText = (text, maxLength, tail) => {
    if (!text || !maxLength) return "";
    let shortText = [];
    for (let i = 0; i < text.length && i < maxLength; i++) {
        shortText.push(text.charAt(i));
    }
    return shortText.join("").lenth < text.length ? shortText.join("") + tail : shortText.join("");
}

utils.lpad = (ori, len, ad) => {
    try {
        const text = ori + "";
        if (!ad || !len || len <= 0) return "";
        if (text.length >= len) return ori;
        const addData = [];
        for (let i = 0; i < len - text.length; i++) {
            addData.push(ad);
        }
        return addData.join("") + text;
    } catch (e) {
        return "";
    }
}

utils.jsonParse = (value) => {
    try {
        return JSON.parse(value);
    } catch (e) {
        return null;
    }
}

utils.phoneFomatter = (num, type) => {
    if (!num) return "";
    let formatNum = '';
    if (num.length == 11) {
        if (type == 0) {
            formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-****-$3');
        } else {
            formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
        }
    } else if (num.length == 8) {
        formatNum = num.replace(/(\d{4})(\d{4})/, '$1-$2');
    } else {
        if (num.indexOf('02') == 0) {
            if (type == 0) {
                formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-****-$3');
            } else {
                formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
            }
        } else {
            if (type == 0) {
                formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-***-$3');
            } else {
                formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
            }
        }
    }
    return formatNum;
}

utils.numberFormat = (number) => {
    if (!number) {
        return 0;
    }
    return <NumberFormat value={number} displayType={"text"} thousandSeparator={true} prefix={""} />;
};

export default utils;
