import React, { useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";

import { _DEF } from "../../../modules/config";
import swal from "sweetalert";

import Booth from "../../../components/Booth";

export default (props) => {
    const { code, history, match } = props;
    const { companyIdx } = match.params;

    const { cfg } = useContext(AppContext);
    const event = cfg.event || null;
    const {siteIdx, eventIdx} = event;


    const [content, setContent] = useState(null);

    useEffect(() => { 
        loadData();
    }, [])

    const getBoothContent = () => {
        return new Promise((resolve, reject) => {
            const data = {
                siteIdx,
                eventIdx,
                companyIdx
            };

            const req = { path: "/v1/event/ebooth", data };
            _API.get(req).then((res) => {
                const { content } = res.data;
                resolve(content?.content);
                // resolve(JSON.parse(content?.content));
            }).catch(err => {
                close();
            });
        })
    }

    const companyLog = () => {
        return new Promise((resolve, reject) => {
            const data = {
                companyIdx
            };
            const req = { path: "/v1/event/company/log", data };
            _API.get(req).then((res) => {
                resolve(true);
            })
        });
    }

    const loadData = async () => {
        setContent(await getBoothContent());
        await companyLog();
    }

    const handlerOnClick = (ele) => {
        // console.log(ele);
        if(ele.contentType === "LINK") window.open(ele.url);
        else _U.postMessage("SELECT-MENU", {...ele, url : "CONTENT"});
    }

    return <Wrapper>{content && <Booth content={content} onClick={(element) => handlerOnClick(element)} />}</Wrapper>;
};


const Wrapper = styled.div``;
