import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import swal from "sweetalert";

import { AppContext } from "../../../components/AppProvider";

import { InputForm, SelectForm, H2, HRDash, ButtonSubmit, ButtonDefault, ModalVideoTitle } from "./UI/Components";

import { default as Lecture } from "./lecture";
import { default as Introduce } from "./introduce";
import { default as Program } from "./program";
import { default as Notice } from "./notice";
import { default as Faq } from "./faq";
import { default as Location } from "./location";
import { default as Agenda } from "./agenda";
import { default as AgendaLecture } from "./agendaLecture";
import { default as Ebooth } from "./ebooth";
import { default as Content } from "./content";
import { default as EboothBasic } from "./eboothBasic";
import { default as Eposter } from "./eposter";
import { default as Satellite } from "./satellite";
import { default as OnlyVideo } from "./OnlyVideo";

import { default as SatelliteAgenda } from "./satelliteAgenda";
import { default as AddDesc } from "./addDesc";
import { default as ModalAlert } from "./modalAlert";


import { _API, _U } from "../../../modules/utils";

import { _DEF } from "../../../modules/config";

export default (props) => {
    const { code, contentType, contentIdx } = props;
    const { cfg } = useContext(AppContext);

    if (!code) return;

    return (
        <Wrapper>
            {/* 메뉴 */}
            {code === "INTRODUCE" && <Introduce />}
            {code === "PROGRAM" && <Program />}
            {code === "NOTICE" && <Notice />}
            {code === "LOCATION" && <Location />}
            {code === "AGENDA" && <Agenda />}
            {code === "LECTURE" && <Lecture />}
            {code === "FAQ" && <Faq />}
            {code === "EBOOTH" && <Ebooth />}
            {/* SATELLITE 컨텐츠 */}
            {code === "SATELLITE" && <Satellite />}
            {/* Ebooth 컨텐츠 */}
            {(code === "CONTENT" || code === "content") && <Content {...props} />}
            {code === "EPOSTER" && <Eposter />}
            {code === "SATELLITE-AGENDA" && <SatelliteAgenda {...props} />}
            {code === "ONLY-VIDEO" && <OnlyVideo {...props} />}
            {/* Ebooth - BASIC 컨텐츠 */}
            {code === "EBOOTH-BASIC" && <EboothBasic {...props} />}
            {/* Lecture - Program */}
            {code === "AGENDA-LECTURE" && <AgendaLecture {...props} />}
            {code?.startsWith("ADDDESC") && <AddDesc {...props} />}
            {code === "LOGIN-ALERT" && <ModalAlert {...props} />}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    /* -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    } */
`;
