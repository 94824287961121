import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import swal from "sweetalert";

import { AppContext } from "../../../components/AppProvider";

import { InputForm, SelectForm, H2, HRDash, ButtonSubmit, ButtonDefault, ModalVideoTitle } from "./UI/Components";

import { default as Ebooth } from "./ebooth";
import { default as Content } from "./content";
import { default as EboothBasic } from "./eboothBasic";

import { _API, _U } from "../../../modules/utils";

import { _DEF } from "../../../modules/config";

export default (props) => {
    const { code, contentType, contentIdx } = props;
    const { cfg } = useContext(AppContext);

    if (!code) return;

    return (
        <Wrapper>
            {/* 메뉴 */}
            {code === "EBOOTH" && <Ebooth />}
            {/* Ebooth 컨텐츠 */}
            {(code === "CONTENT" || code === "content") && <Content {...props} />}
            {/* Ebooth - BASIC 컨텐츠 */}
            {code === "EBOOTH-BASIC" && <EboothBasic {...props} />}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
`;
