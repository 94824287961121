import React, { Fragment, useContext, useEffect, useState } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U, _CK } from "../../../modules/utils";

import EposterBasic from "./Components.Eposter.Basic";
import EposterPremium from "./Components.Eposter.Premium";

export default (props) => {
    const { cfg, auth, iam } = useContext(AppContext);
    const event = cfg.event || null;
    const { siteIdx, eventIdx } = event;

    return <EposterBasic {...props} />;
};
