import React, { useContext, useState, useEffect, Fragment, forwardRef, useRef, useImperativeHandle } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import swal from "sweetalert";
import * as FilePond from "filepond";
import { FilePond as FilePondForm } from "react-filepond";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import Layout from "../Layout";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";
import { _DEF, _CFG } from "../../../modules/config";

import Wrapper, { Row, FormGroup, Separator } from "../../../components/UI/Wrapper";
import { SearchInput, SelectForm, InputForm, SegmentedForm, FileForm, DatePicker } from "../../../components/UI/Form";
import Button from "../../../components/UI/Button";
import SNEditor from "../../../components/UI/SNEditor";
import Paging, { ToPage, GetParams } from "../../../components/UI/Paging";

import { difference } from 'lodash';

export default (props) => {

    const childRef = useRef();

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-8">
                    <h2>컨텐츠</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/admin">HOME</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <strong>이벤트</strong>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>컨텐츠</strong>
                        </li>
                    </ol>
                </div>
                <div className="col-sm-4">
                    <div className="title-action">
                        <button type="button" className="btn btn-primary" onClick={() => childRef.current.callOnForm()}>
                            <i className="fa fa-plus"></i> 추가
                        </button>
                    </div>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <ContentView {...props} ref={childRef} />
            </div>
        </Layout>
    )

}

export const ContentView = forwardRef((props, ref) => {

    const { history, location, match } = props;

    // const { siteIdx } = useContext(AppContext);

    const { cfg } = useContext(AppContext);

    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [eventIdx, setEventIdx] = useState(props.eventIdx || "");
    const [companyIdx, setCompanyIdx] = useState(props.companyIdx || "");
    const [category, setCategory] = useState(props.isEboothTab ? "EBOOTH" : "");
    const [contentType, setContentType] = useState("");
    const [keyword, setKeyword] = useState("");

    const [list, setList] = useState([]);
    const [paging, setPaging] = useState(null);
    const [siteList, setSiteList] = useState([]);
    const [eventList, setEventList] = useState([]);
    const [contentIdx, setContentIdx] = useState(props.contentIdx);
    const [mode, setMode] = useState(props.mode);
    const [aws, setAws] = useState();

    useImperativeHandle(ref, () => ({
        callOnForm() {
            onForm("");
        }
    }))

    const onForm = (idx, e) => {
        if (e) e.preventDefault();

        setContentIdx(idx);
        setMode("form");
    }

    const loadList = (page) => {
        // if(!eventIdx){
        //     swal({ title: "알림", text: "이벤트를 선택해 주세요." });
        //     return;
        // }
        const data = {
            siteIdx,
            eventIdx,
            keyword,
            category,
            contentType,
            page,
            companyIdx
        };
        const req = { path: "/v1/a/event/content/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setList(list);
            setPaging(paging);
        });
    };

    const loadEventList = (page) => {
        const data = {
            siteIdx,
        };
        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setEventList(list);
        });
    };

    const movePage = (page) => {
        loadList(page);
    };

    const onSearch = (e) => {
        loadList(1);
    };

    useEffect(() => {
        loadList(1);
        loadEventList();
        _API.get({ path: "/v1/upload" }).then((res) => {
            setAws(res.data);
        });
    }, []);

    useEffect(() => {
        loadList(1);
    }, [eventIdx, category, contentType])

    const onChangeState = (item, col, val, e) => {
        if (e) e.preventDefault();

        swal({
            title: `컨텐츠`,
            text: `상태를 변경하시겠습니까?`,
            icon: "info",
            buttons: ["취소", "예"],
            dangerMode: val === "D" ? true : false,
        }).then((ok) => {
            if (ok) {
                _API.post({
                    path: "/v1/a/event/category/status",
                    data: {
                        idx: item.idx
                        , category: "content"
                        , status: val
                    },
                }).then((res) => {
                    loadList(paging?.pageNo ? paging?.pageNo : 1)
                });
            }
        });
    }

    const changeMode = (mode) => {
        setMode(mode);
        if (!mode) loadList(paging?.pageNo ? paging?.pageNo : 1);
    }

    return (

        <Fragment>
            <div className="row">
                <div className={cx({ "col-md-12": !mode, "col-md-6": mode })}>
                    <div className="ibox">
                        <div className="ibox-title">
                            <div className="row">
                                <div className="col-md-8">
                                    이벤트 컨텐츠 목록
                                </div>
                                {props.isEboothTab && (
                                    <div className="col-md-4 text-right">
                                        <button type="button" className="btn btn-primary mr-1" onClick={e => onForm("")}>
                                            <i className="fa fa-plus"></i> 추가
                                    </button>
                                        <button type="button" className="btn btn-default" onClick={() => window.location.href = "#/admin/event/ebooth/company"}>
                                            <i className="fa fa-remove"></i> 취소
                                    </button>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="ibox-content ibox-heading">
                            {!props.isEboothTab && (
                                <div className="row">
                                    <div className="col-md-2">
                                        <select value={eventIdx} onChange={(e) => setEventIdx(e.target.value)} className="form-control">
                                            <option value="">이벤트선택</option>
                                            {eventList.map((item, i) => (
                                                <option key={i} value={item.eventIdx}>
                                                    {item.eventName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-2">
                                        <select value={category} onChange={(e) => setCategory(e.target.value)} className="form-control">
                                            <option value="">컨텐츠종류</option>
                                            {_DEF.EB_CONTENT_CATEGORY.map((item, i) => (
                                                <option key={i} value={item.value}>
                                                    {item.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-2">
                                        <select value={contentType} onChange={(e) => setContentType(e.target.value)} className="form-control">
                                            <option value="">컨텐츠구분</option>
                                            {_DEF.EB_CONTENT_TYPE.map((item, i) => (
                                                <option key={i} value={item.value}>
                                                    {item.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <SearchInput value={keyword} onChange={(e) => setKeyword(e.target.value)} onClick={onSearch} placeholder="검색어를 입력해 주세요." />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="ibox-content">
                            <div className="table-responsive">
                                <table className="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th className="text-center">컨텐츠ID</th>
                                            {/* <th className="text-center">사이트명</th> */}
                                            <th className="text-center">이벤트명</th>
                                            <th className="text-center">컨텐츠명</th>
                                            <th className="text-center">종류</th>
                                            <th className="text-center">구분</th>
                                            <th className="text-center">상태</th>
                                            <th className="text-center">등록일</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list.map((item, i) => (
                                            <tr key={i}>
                                                <td className="text-center">{item.contentIdx}</td>
                                                {/* <td className="text-center">{item.siteName}</td> */}
                                                <td className="text-center">{item.eventName}</td>
                                                <td className="text-center">
                                                    <a href="" onClick={onForm.bind(this, item.contentIdx)}>{item.title}</a>
                                                </td>
                                                <td className="text-center">{_DEF.getDefinedLabel(_DEF.EB_CONTENT_CATEGORY, item.category)}</td>
                                                <td className="text-center">{_DEF.getDefinedLabel(_DEF.EB_CONTENT_TYPE, item.contentType)}</td>
                                                <td className="text-center">
                                                    <div className="btn-group">
                                                        {/* <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "W", "btn-success": item.status === "W" })} onClick={onChangeState.bind(this, item, "status", "W")}>
                                                            대기
                                                        </button> */}
                                                        <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "Y", "btn-success": item.status === "Y" })} onClick={onChangeState.bind(this, item, "status", "Y")}>
                                                            정상
                                                        </button>
                                                        <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "N", "btn-success": item.status === "N" })} onClick={onChangeState.bind(this, item, "status", "N")}>
                                                            미사용
                                                        </button>
                                                        <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "D", "btn-success": item.status === "D" })} onClick={onChangeState.bind(this, item, "status", "D")}>
                                                            삭제
                                                        </button>
                                                    </div>
                                                </td>
                                                <td className="text-center">{moment(item.regDate).format("YYYY-MM-DD")}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {paging && (
                            <div className="ibox-footer">
                                <Paging paging={paging} pageCallback={movePage} />
                            </div>
                        )}
                    </div>
                </div>
                {mode && (
                    <div className={cx({ "col-md-6": true })}>
                        <EventContentForm {...props} siteList={siteList} contentIdx={contentIdx} changeMode={changeMode} aws={aws} />
                    </div>
                )}
            </div>
        </Fragment>
    );
});

const EventContentForm = (props) => {
    const { history, location, match } = props;
    const { contentIdx, siteList: siteListInfo, aws } = props;

    const { cfg } = useContext(AppContext);

    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [data, setData] = useState({ status: 'Y' });
    const [siteList, setSiteList] = useState([]);
    const [eventList, setEventList] = useState([]);
    const [filePondFiles, setFilePondFiles] = useState();
    const [uploadFileList, setUploadFileList] = useState([]);
    const [companyList, setCompanyList] = useState([]);

    const [content, setContent] = useState("");

    useEffect(() => {
        const pondIdxList = filePondFiles?.map(fp => (fp.id));
        const uploadIdxList = uploadFileList.map(up => (up.pondId));
        const deletedIdx = difference(uploadIdxList, pondIdxList);

        setUploadFileList(list => list?.filter(i => (i.pondId != deletedIdx)));
    }, [filePondFiles])

    const loadData = () => {
        if (!contentIdx) {
            return;
        }
        const data = { contentIdx };
        const req = { path: `/v1/a/event/content/info`, data };
        _API.get(req)
            .then((res) => res.data)
            .then((data) => {
                setFilePondFiles([]);
                setUploadFileList([]);
                loadEventList(siteIdx);
                setData(data);
                setContent(data.content);
                let pond = "";

                if (data.contentType === "IMAGE") {
                    setUploadFileList(data.content);
                }
            });
    };

    const handleDataChange = (e) => {

        let { name, value } = e.target;
        if (name === "contentType") {
            if (data?.category === "ONDEMAND") {
                setData({ ...data, [name]: value, content: null, category: null });
            } else {
                setData({ ...data, [name]: value, content: null });
            }
            setFilePondFiles([]);
            setUploadFileList([]);
        } else if (name === "category" && e.target.value === "ONDEMAND") {
            setData({ ...data, [name]: value, contentType: "VOD_FILE" });
        } else {
            setData({ ...data, [name]: value });
        }
        if (name === "siteIdx") {
            value ? loadEventList(value) : setEventList([]);
            setData({ ...data, [name]: value, eventIdx: null });
        }
    };

    const loadEventList = () => {
        const data = {
            siteIdx,
        };
        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            const newEventList = [];
            for (let i in list) {
                newEventList.push({ label: list[i].title, value: list[i].idx });
            }
            setEventList(newEventList);
        });
    };

    const setDataChange = (name, value) => {
        setData({ ...data, [name]: value });
    };

    const fileVodUpload = async (name, item) => {
        const urlName = name.replace("FileIdx", "SignedUrl");
        await _API.post({
            path: "/v1/a/video",
            data: item,
        }).then((res) => res.data)
            .then((data) => {
                swal({ title: "알림", text: "업로드 되었습니다." });
            });
        setData({ ...data, [name]: item.id, [urlName]: item.signedUrl, content: item.path });
    }

    const handleSaveClick = async () => {
        if (!data.siteIdx) {
            swal({ title: "알림", text: "사이트를 선택해 주세요." });
            return;
        }
        if (!data.eventIdx) {
            swal({ title: "알림", text: "이벤트를 선택해 주세요." });
            return;
        }
        if (!data.title) {
            swal({ title: "알림", text: "컨텐츠명을 입력해 주세요." });
            return;
        }
        if (!data.category) {
            swal({ title: "알림", text: "컨텐츠종류를 선택해 주세요." });
            return;
        }
        if (!data.contentType) {
            swal({ title: "알림", text: "컨텐츠타입을 선택해 주세요." });
            return;
        }
        if (data.contentType === "HTML" && !content) {
            swal({ title: "알림", text: "컨텐츠를 등록해 주세요." });
            return;
        }
        if ((data.contentType === "VOD_FILE" || data.contentType === "PDF") && !data.vodFileIdx) {
            swal({ title: "알림", text: "컨텐츠를 등록해 주세요." });
            return;
        }
        if (data.contentType === "LINK" && (!data.content || data.content?.indexOf("http") < 0)) {
            swal({ title: "알림", text: "링크는 http:// 혹은 https:// 를 포함하여 등록해 주세요." });
            return;
        }

        const newContent = [];
        if (data.contentType === "IMAGE") {
            uploadFileList?.map((uploadFile) => {
                if (!uploadFile.pondId) {
                    newContent.push({ fileIdx: uploadFile.fileIdx, path: uploadFile.path });
                } else {
                    filePondFiles?.map((fileItem) => {
                        if (uploadFile.pondId === fileItem.id) {
                            newContent.push({ fileIdx: uploadFile.fileIdx, path: uploadFile.path });
                        }
                    });
                }

            });
        }
        if (newContent.length < 1 && data.contentType === "IMAGE") {
            swal({ title: "알림", text: "컨텐츠를 등록해 주세요." });
            return;
        }

        _API.post({
            path: "/v1/a/event/content",
            data: {
                contentIdx: data.contentIdx,
                siteIdx: data.siteIdx,
                eventIdx: data.eventIdx,
                category: data.category,
                contentType: data.contentType,
                title: data.title,
                content: data.contentType === "IMAGE" ? newContent : (data.contentType === "VOD_FILE" || data.contentType === "PDF") ? data.vodFileIdx : data.contentType === "LINK" ? data.content : content,
                vodFileIdx: data.vodFileIdx,
                status: data.status || "Y",
                companyIdx: data.category === "EBOOTH" ? data.companyIdx || null : null
            },
        }).then((res) => {
            swal({ title: "알림", text: `이벤트 컨텐츠가 ${!data.contentIdx ? "생성" : "수정"}되었습니다.` }).then(() => {
                // _U.postMessage("EVENT-CHANGED");
                onCancel();

            });
        });
    };

    const onCancel = () => {
        props.changeMode && props.changeMode("");
    }
    useEffect(() => {
        const newSiteList = [];
        for (let i in siteListInfo) {
            newSiteList.push({ label: siteListInfo[i].title, value: siteListInfo[i].idx });
        }
        setSiteList(newSiteList);
    }, [siteListInfo]);

    FilePond.registerPlugin(FilePondPluginFileValidateType);

    useEffect(() => {
        FilePond.setOptions({
            onaddfile: (error, file) => {
                const req = { aws, file: file.file, path: "EVENT/CONTENT/IMAGE", type: "SN" };
                _API.upload(req).then((res) => {
                    setUploadFileList((o) => [...o
                        , {
                        fileIdx: res.data.id
                        , signedUrl: res.data.signedUrl
                        , pondId: file.id
                        , path: res.data.path
                        , name: res.data.name
                    }]);
                });
            },
        });
        loadEventList()
    }, []);

    useEffect(() => {
        loadData();
        if (!contentIdx) {
            setData({
                siteIdx: props.siteIdx || cfg.idx || ""
                , eventIdx: props.eventIdx || ""
                , companyIdx: props.companyIdx || ""
                , category: props.isEboothTab ? "EBOOTH" : ""
            });
            setContent("");
        }
    }, [contentIdx]);

    const deleteFile = (fileIdx, e) => {
        if (e) e.preventDefault();

        const newUploadFileList = [];
        uploadFileList?.map((item, i) => {
            if (item.fileIdx !== fileIdx) {
                newUploadFileList.push(item);
            }
        });
        setUploadFileList(newUploadFileList);
    };

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="ibox">
                    <div className="ibox-title">콘텐츠 {!data?.eventIdx ? "등록" : "수정"}</div>
                    <div className="ibox-content">
                        {!props.isEboothTab ? (
                            <>
                                {!siteIdx && (
                                    <SelectForm title="사이트명" name="siteIdx" value={data?.siteIdx} items={siteList} onChange={handleDataChange} nullTitle="사이트 선택" />
                                )}
                                <SelectForm title="이벤트명" name="eventIdx" value={data?.eventIdx} items={eventList} onChange={handleDataChange} nullTitle="이벤트 선택" />
                                <Separator />
                                <InputForm title="컨텐츠명" name="title" placeholder="컨텐츠명" value={data?.title} onChange={handleDataChange} />
                                {!props.isEboothTab && (
                                    <SelectForm title="컨텐츠종류" name="category" value={data?.category} items={_DEF.EB_CONTENT_CATEGORY} onChange={handleDataChange} nullTitle="컨텐츠종류" />
                                )}
                            </>
                        ) : (
                            <InputForm title="컨텐츠명" name="title" placeholder="컨텐츠명" value={data?.title} onChange={handleDataChange} />
                        )}
                        {/* {data?.category === "EBOOTH" &&
                            (
                            <SelectForm title="입점 업체" name="companyIdx" value={data?.companyIdx} items={companyList} onChange={handleDataChange} nullTitle="입점 업체" />
                            )
                        } */}
                        <SelectForm title="컨텐츠타입" name="contentType" value={data?.contentType} items={_DEF.EB_CONTENT_TYPE} onChange={handleDataChange} nullTitle="컨텐츠타입" />
                        <Separator />
                        {data?.contentType === "VOD_FILE" && (
                            <FileForm title="영상파일" accept="video/*" onChange={(v) => fileVodUpload("vodFileIdx", v)} path={`EVENT/CONTENT/VOD_FILE/${moment().format("YYYY/MM/DD")}`} url={data?.vodSignedUrl} fileName={data?.fileName} />
                        )}
                        {data?.contentType === "IMAGE" && (
                            <div className="form-group row">
                                <label className="col-sm-3 control-label">이미지파일</label>
                                <div className="col-sm-9">
                                    <FilePondForm allowMultiple={true}
                                        acceptedFileTypes={['image/*']}
                                        files={filePondFiles}
                                        onupdatefiles={setFilePondFiles}
                                    />

                                    {uploadFileList?.map((item, i) => (
                                        <div className="row" key={i}>
                                            <div className="col-md-10">
                                                <img src={`${_CFG.APIUrl()}/download/sn/${item.fileIdx}/${encodeURIComponent((item.name || item.path)?.replace(/\/?.*\//gi, '').replace(/ /gi, "_"))}`} alt="" style={{ maxWidth: "100%" }} />
                                            </div>
                                            <div className="col-md-2">
                                                <button type="button" className="btn btn-xs btn-danger" onClick={deleteFile.bind(this, item.fileIdx)}><i className="fa fa-trash"></i></button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        {(data?.contentType === "VOD_URL" || data?.contentType === "LINK") && (
                            <InputForm title={data?.contentType === "VOD_URL" ? "영상경로" : "링크주소"} name="content" placeholder={"e.g https://www.abc.com"} value={data?.content} onChange={handleDataChange} description="링크주소는 http:// 혹은 https://를 포함하여 등록해 주세요." />
                        )}
                        {data?.contentType === "HTML" && (
                            <div className="form-group row">
                                <label className="col-sm-3 control-label">컨텐츠(HTML)</label>
                                <div className="col-sm-9">
                                    <div className="input-group">
                                        <SNEditor content={content} onChange={(v) => setContent(v)} />
                                    </div>
                                </div>
                            </div>
                        )}
                        {data?.contentType === "PDF" && (
                            <FileForm title="PDF파일" accept="application/pdf" onChange={(v) => setData({ ...data, vodFileIdx: v.id })} path={`EVENT/CONTENT/PDF/${moment().format("YYYY/MM/DD")}`} url={data?.pdfSignedUrl} fileName={data?.fileName} />
                        )}

                        {/* <Separator />
                        <SegmentedForm title="사용여부" items={_DEF.STATUS} value={data?.status} onClick={(v) => setDataChange("status", v)} /> */}
                        {/* <TextForm title="설명" name="description" placeholder="설명" value={data?.description} onChange={handleDataChange} /> */}
                        <Separator />
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <div className="btn-group">
                                    <Button onClick={handleSaveClick} className="btn btn-primary">
                                        {!data.contentIdx ? "생성" : "수정"}
                                    </Button>
                                    <Button onClick={onCancel}>취소</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};