import React, { useContext, useState, useEffect, Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import swal from "sweetalert";
import moment from "moment";

import { AppContext } from "../../components/AppProvider";

import { _API, _U } from "../../modules/utils";
import { _DEF } from "../../modules/config";
import Paging from "../UI/Paging";

import SNEditor from "../../components/UI/SNEditor";

import Input, { SearchInput, SelectForm, InputForm, SegmentedForm, TextForm } from "./Form";

export default (props) => {

    const { cfg } = useContext(AppContext);

    const { code, callback, sendType } = props;

    const [eventList, setEventList] = useState([]);
    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [eventIdx, setEventIdx] = useState("");

    const [keyword, setKeyword] = useState("");
    const [list, setList] = useState([]);
    const [paging, setPaging] = useState(null);

    const [data, setData] = useState(null);

    const loadEventList = (siteIdx) => {
        if (!siteIdx) {
            setEventList([]);
            setEventIdx("");
            return;
        }

        const data = {
            siteIdx,
        };

        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list } = res.data;
            setEventList(list);
        });
    };

    const loadList = (page) => {
        const data = {
            siteIdx,
            eventIdx,
            keyword,
            page,
            sendType,
            numPerPage: 8,
        };

        const req = { path: "/v1/a/send/manage/template", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setList(list);
            setPaging(paging);
        });
    };

    useEffect(() => {
        loadList(1);
        loadEventList(cfg.idx)
    }, []);

    const movePage = (page) => {
        loadList(page);
    };

    const onSearch = (e) => {
        loadList(1);
    };

    useEffect(() => {
        loadList(1);
    }, [siteIdx, eventIdx])

    return (
        <div className="wrapper wrapper-content">
            <h2>템플릿 설정 {sendType === "sms" ? "(SMS)" : "(EMAIL)"}</h2>
            <div className="row mt-3">
                <div className="col-md-8">
                    <div className="ibox">
                        <div className="ibox-title">목록</div>
                        <div className="ibox-content ibox-heading">
                            <div className="row">
                                <div className="col-md-4">
                                    <SelectForm
                                        title="이벤트"
                                        name="eventIdx"
                                        sendTyp={sendType}
                                        items={eventList.map((event) => {
                                            return { label: event.eventName, value: event.idx };
                                        })}
                                        value={eventIdx}
                                        onChange={(e) => {
                                            setEventIdx(e.target.value);
                                        }}
                                        nullTitle={`이벤트 선택`}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <SearchInput value={keyword} onChange={(e) => setKeyword(e.target.value)} onClick={onSearch} placeholder="검색어를 입력해 주세요." />
                                </div>
                            </div>
                        </div>
                        <div className="ibox-content">
                            <div className="table-responsive">
                                <table className="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th className="text-center">사이트명</th>
                                            <th className="text-center">이벤트명</th>
                                            {/* <th className="text-center">발송방식</th> */}
                                            <th className="text-center">제목</th>
                                            <th className="text-center">등록일시</th>
                                            <th className="text-center">상태</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list?.map((item, i) => (
                                            <ListItem
                                                {...item}
                                                key={i}
                                                sendType={sendType}
                                                onModify={(data) => {
                                                    console.log(data);
                                                    setData(data);
                                                }}
                                                refresh={() => {
                                                    loadList(paging?.pageNo || 1);
                                                }}
                                            />
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {paging && (
                            <div className="ibox-footer">
                                <Paging paging={paging} pageCallback={movePage} />
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-md-4">
                    <Form
                        {...props}
                        data={data}
                        callback={() => {
                            loadList(paging?.pageNo || 1);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

const ListItem = (props) => {

    const onChangeStatus = (status) => {
        const req = { path: "/v1/a/send/manage/template/status", data: { idx: props.idx, status } };
        _API.post(req).then((res) => {
            props.refresh && props.refresh();
        });
    };

    const onRemove = () => {
        swal({
            title: "정말 삭제 하시겠습니까?",
            text: "삭제된 데이터는 복구가 불가합니다 .",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                _API.post({ path: `/v1/a/send/manage/template/status`, data: { idx: props.idx, status: "D" } }).then((res) => {
                    swal({ title: "알림", text: `항목이 삭제되었습니다.` }).then(() => {
                        props.refresh && props.refresh();
                    });
                });
            }
        });
    };

    const weights = [...Array(100).keys()];

    return (
        <tr>
            <td className="text-center">{props.siteTitle}</td>
            <td className="text-center">{props.eventTitle}</td>
            <td className="text-center">{props.title}</td>
            <td className="text-center">{moment(props.regDate).format("YYYY-MM-DD")}</td>
            <td className="text-center">
                <div className="btn-group">
                    <button className={cx({ btn: true, "btn-xs": true, "btn-warning": true })} onClick={props.onModify.bind(this, props)}>
                        <i className="fa fa-edit"></i>
                    </button>
                    <button className={cx({ btn: true, "btn-xs": true, "btn-danger": true })} onClick={onRemove}>
                        <i className="fa fa-trash"></i>
                    </button>
                </div>
            </td>
        </tr>
    );
};

const Form = (props) => {
    const { cfg } = useContext(AppContext);

    const { callback, data, sendType } = props;

    let contentRef = useRef();
    const [content, setContent] = useState("");

    const [form, setForm] = useState({
        status: "Y",
        siteIdx: cfg.idx
    });

    const [eventList, setEventList] = useState([]);

    const onSave = () => {
        if (!form?.eventIdx) {
            swal("이벤트를 선택하세요.");
            return;
        }
        if (!content) {
            swal("양식을 입력하세요.");
            return;
        }
        if (!form?.title) {
            swal("제목을 입력하세요.");
            return;
        }

        const req = { path: "/v1/a/send/manage/template", data: { ...form, sendType, content } };
        _API.post(req).then((res) => {
            setForm({
                status: "Y",
                siteIdx: cfg.idx
            });
            setContent("");
            swal(form?.idx ? `수정 되었습니다.` : `저장 되었습니다.`).then(() => {
                callback && callback();
            });
        });
    };

    const onTagClick = (e, tagName) => {
        e.preventDefault();

        setContent(`${content || ""}#{${tagName}}`);
        if (sendType === "mail") contentRef.summernote.focus();
        else contentRef.current.focus();
    }

    const loadEventList = (siteIdx) => {
        const data = {
            siteIdx,
        };

        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list } = res.data;
            setEventList(list);
        });
    };

    useEffect(() => {
        if (data?.idx) {
            setForm(data);
            setContent(data?.content);
            loadEventList(cfg.idx);
        }
    }, [data]);

    useEffect(() => {
        loadEventList(cfg.idx);
    }, [])

    const weights = [...Array(100).keys()];

    return (
        <div className="ibox">
            <div className="ibox-title">{form?.idx ? `수정` : `등록`}</div>
            <div className="ibox-tools" style={{ top: "0px" }}>
                <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-info": true })} onClick={(e) => { setForm({ status: "Y", siteIdx: cfg.idx }); setContent(""); }} style={{ margin: "12px 20px" }}>
                    신규
                </button>
            </div>
            <div className="ibox-content">
                <SelectForm
                    title="이벤트"
                    name="eventIdx"
                    items={eventList.map((event) => {
                        return { label: event.eventName, value: event.idx };
                    })}
                    value={form?.eventIdx}
                    onChange={(e) => {
                        setForm({ ...form, [e.target.name]: e.target.value });
                    }}
                    nullTitle={`이벤트를 선택하세요.`}
                />
                {/* <InputForm title="코드" name="keyword" value={form?.keyword} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="코드 입력" description="코드는 영문으로 시작 해야 하며 영문,숫자 조합" /> */}
                <InputForm title="제목" name="title" value={form?.title} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="제목 입력" />

                <div className="form-group row">
                    <label className="col-sm-3 control-label m-t-sm">변수정보</label>
                    <div className="col-sm-9">
                        <p><code> 라벨을 선택하면 해당 정보가 양식에 추가됩니다. </code></p>
                        <ul className="tag-list" style={{ padding: "0" }}>
                            <li><a onClick={(e) => onTagClick(e, "name")}><i className="fa fa-tag"></i>참가자명</a></li>
                            <li><a onClick={(e) => onTagClick(e, "mobile")}><i className="fa fa-tag"></i>사이트 전화번호</a></li>
                            <li><a onClick={(e) => onTagClick(e, "email")}><i className="fa fa-tag"></i>사이트 이메일</a></li>
                            <li><a onClick={(e) => onTagClick(e, "companyName")}><i className="fa fa-tag"></i>참가자 소속</a></li>
                            <li><a onClick={(e) => onTagClick(e, "deptName")}><i className="fa fa-tag"></i>참가자 부서</a></li>
                            <li><a onClick={(e) => onTagClick(e, "eventName")}><i className="fa fa-tag"></i>이벤트명</a></li>
                            <li><a onClick={(e) => onTagClick(e, "url")}><i className="fa fa-tag"></i>사이트 URL</a></li>
                        </ul>
                    </div>
                </div>

                {sendType === "mail" && (
                    <div className="form-group row" id="editerDiv">
                        <label className="col-sm-3 control-label">발송양식</label>
                        <div className="col-sm-9">
                            <SNEditor content={content} onChange={(v) => setContent(v)} ref={(ref) => (contentRef = ref)} />
                        </div>
                    </div>
                )}

                {sendType !== "mail" && <TextForm title="발송양식" value={form?.content} onChange={(e) => setForm({ ...form, "content": e.target.value })} placeholder="양식을 입력해 주세요" ref={contentRef} />}
                <TextForm title="설명" value={form?.description} onChange={(e) => setForm({ ...form, "description": e.target.value })} placeholder="내용을 입력해 주세요" />
                {/* <SegmentedForm title="상태" items={_DEF.STATUS} value={form?.status} onClick={(v) => setForm({ ...form, status: v })} /> */}
                <div className="hr-line-dashed"></div>
                <div className="row">
                    <div className="col-md-12 text-right">
                        <button className="btn btn-primary" onClick={onSave}>
                            <i className="fa fa-save"></i> 저장
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
