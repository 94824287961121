import React, { useContext, useState, useEffect, Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import swal from "sweetalert";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import Layout from "../Layout";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";
import { _DEF } from "../../../modules/config";

import { ContentView } from "./event.Content";
import { EboothView } from "./event.ebooth.editor";


import Wrapper, { Row, FormGroup, Separator } from "../../../components/UI/Wrapper";
import { SearchInput, SelectForm, InputForm, SegmentedForm, FileForm, DatePicker, DateTimePicker } from "../../../components/UI/Form";
import Button from "../../../components/UI/Button";
import Paging, { ToPage, GetParams } from "../../../components/UI/Paging";
import EventMainVisualForm from "./event.visual";


export default (props) => {
    const [tabIndex, setTabIndex] = useState(0);
    const { history, location, match } = props;
    const [siteList, setSiteList] = useState([]);
    // const { siteIdx, eventIdx, companyIdx, eboothIdx } = match.params;
    const { companyIdx } = match.params;
    const [data, setData] = useState({ siteIdx });

    const { cfg } = useContext(AppContext);
    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [eventIdx, setEventIdx] = useState();
    const [eboothIdx, setEboothIdx] = useState();
    const [companyName, setCompanyName] = useState();

    const childRef = useRef();


    useEffect(() => {
        checkCompanyIdx();
    }, [])

    const checkCompanyIdx = () => {
        const data = {
            siteIdx,
            companyIdx
        };

        const req = { path: "/v1/a/event/company/check", data };
        _API.get(req).then((res) => {
            const { companyInfo } = res.data;

            setSiteIdxAndEventIdxForCompany(companyInfo);
        }).catch((err) => {
            window.location.href = "#/admin/event/ebooth/company"
        });
    }

    const setSiteIdxAndEventIdxForCompany = companyInfo => {
        setEventIdx(companyInfo.eventIdx);
        setEboothIdx(companyInfo.eboothIdx);
        setCompanyName(companyInfo.companyName);
    }

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-8">
                    <h2>EBOOTH</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/admin">HOME</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to={`${location.pathname}`}>이벤트</Link>
                        </li>
                        <li className="breadcrumb-item ">
                            <Link to={`/admin/event/ebooth/company`}>입점업체</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <strong>EBOOTH</strong>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>{companyName}</strong>
                        </li>
                    </ol>
                </div>
                <div className="col-sm-4">
                    <div className="title-action">
                    </div>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className="col-md-12">
                        <Tabs>
                            <TabList>
                                <Tab>이부스</Tab>
                                <Tab>콘텐츠</Tab>
                            </TabList>
                            <TabPanel>
                                <EboothView {...props} eboothIdx={eboothIdx} siteIdx={siteIdx} eventIdx={eventIdx} companyIdx={companyIdx} isEboothTab />
                            </TabPanel>
                            <TabPanel>
                                <ContentView ref={childRef} siteIdx={siteIdx} eventIdx={eventIdx} companyIdx={companyIdx} isEboothTab />
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
