import React, { useState, useEffect, useRef } from "react";
import { _CK } from "../modules/utils";

const AmazonIVSWorkaround = (props) => {
    let { url, poster } = props;

    const videoEl = useRef(null);
    const [isPlay, setIsPlay] = useState(false);
    const [isEnd, setIsEnd] = useState(true);
    const [player, setPlayer] = useState(null);

    // let player = null;

    useEffect(() => {
        if (player) {
            try {
                player.delete();
            } catch (e) {
                setPlayer(null);
            }
        }
        if (IVSPlayer.isPlayerSupported) {
            setPlayer(IVSPlayer.create());
        }
    }, [url]);

    useEffect(() => {
        if (player) {
            player.attachHTMLVideoElement(document.getElementById("video-player"));
            player.load(url);
            player.play();
        }

    }, [player]);

    return <video id="video-player" ref={videoEl} poster={poster} playsInline autoPlay width="100%" height="100%" controls controlsList="nodownload" />;
};

function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

export default AmazonIVSWorkaround;
