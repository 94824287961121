import React, { useContext, useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import styled from "styled-components";
import Socket from "../../../../components/UI/Socket";

import { _API, _U } from "../../../../modules/utils";

let _TIMER = null;

export default (props) => {
    const { history, location, match } = props;
    const { idx } = match.params;

    const [time, setTime] = useState(-1);
    const [isConnect, setIsConnect] = useState(false);
    const [quiz, setQuiz] = useState(null);
    const [fontSize, setFontSize] = useState(6);
    const [imgSize, setImgSize] = useState(6);
    const [graphSize, setGraphSize] = useState(8);
    const [isSizeControl, setIsSizeControl] = useState(false);

    const debugSocket = false; // _CFG.isLocal();
    const sendChannel = () => {
        _U.postMessage("SOCKET-SEND", {
            action: "CHANNEL",
            data: {
                type: "QUIZ-MONITOR",
                channel: idx,
            },
        });
    };

    const onConnected = () => {
        setIsConnect(true);
        sendChannel();
    };
    const onDisconnected = () => {
        setIsConnect(false);
    };

    const loadData = () => {
        _API.get({
            path: "/v1/a/event/console/quiz/monitor",
            data: {
                channelIdx: idx,
            },
        })
            .then((res) => res.data)
            .then((data) => {
                setQuiz(data);
            });
    };

    const onReceivedMessage = (e) => {
        _U.parseMessage(e)
            .then((data) => {
                switch (data.code) {
                    case "SOCKET-RECEIVED":
                        return data.data ? JSON.parse(data.data) : null;
                }
            })
            .then((data) => {
                if (data?.mode === "QUIZ-MONITOR") {
                    if (data.time) {
                        let time = data.time;
                        setTime(time);
                        if (_TIMER) {
                            clearInterval(_TIMER);
                        }
                        _TIMER = setInterval(() => {
                            time--;
                            setTime(time);
                            if (time < 0) {
                                setTime(-1);
                                clearInterval(_TIMER);
                            }
                        }, 1000);
                    }
                    loadData();
                }
            });
    };

    useEffect(() => {
        loadData();
        window.addEventListener("message", onReceivedMessage);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, []);

    useEffect(() => {
        sendChannel();
        const timer = setInterval(sendChannel, 10000);
        return () => clearInterval(timer);
    }, [idx]);

    if (quiz?.graphYn === "Y") {
        quiz.perAnswer1 = parseInt(quiz.cntAnswer1 === 0 ? 0 : (quiz.cntAnswer1 / quiz.cntTotal) * 100);
        quiz.perAnswer2 = parseInt(quiz.cntAnswer2 === 0 ? 0 : (quiz.cntAnswer2 / quiz.cntTotal) * 100);
        quiz.perAnswer3 = parseInt(quiz.cntAnswer3 === 0 ? 0 : (quiz.cntAnswer3 / quiz.cntTotal) * 100);
        quiz.perAnswer4 = parseInt(quiz.cntAnswer4 === 0 ? 0 : (quiz.cntAnswer4 / quiz.cntTotal) * 100);
        quiz.perAnswer5 = parseInt(quiz.cntAnswer5 === 0 ? 0 : (quiz.cntAnswer5 / quiz.cntTotal) * 100);
    }

    return (
        <Container>
            {quiz ? (
                <Wrapper>
                    <Question fontSize={fontSize} imgSize={imgSize}>
                        {isSizeControl && (
                            <div className="row">
                                <div className="col-md-1 text-right" style={{ margin: "7px 15px 0 0" }}>
                                    질문창
                                </div>
                                <div className="col-md-2">
                                    <div className="input-group">
                                        <input type="number" className="form-control" value={fontSize} onChange={(e) => setFontSize(e.target.value)} />
                                        <div className="input-group-append">
                                            <div className="btn-group">
                                                <button className="btn btn-success" onClick={(e) => setFontSize(parseFloat((fontSize + 0.1).toFixed(1)))}>
                                                    <i className="fa fa-plus"></i>
                                                </button>
                                                <button className="btn btn-danger" onClick={(e) => setFontSize(parseFloat((fontSize - 0.1).toFixed(1)))}>
                                                    <i className="fa fa-minus"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-1 text-right" style={{ margin: "7px 15px 0 0" }}>
                                    질문 이미지
                                </div>
                                <div className="col-md-2">
                                    <div className="input-group">
                                        <input type="number" className="form-control" value={imgSize} onChange={(e) => setImgSize(e.target.value)} />
                                        <div className="input-group-append">
                                            <div className="btn-group">
                                                <button className="btn btn-success" onClick={(e) => setImgSize(parseFloat((imgSize + 0.1).toFixed(1)))}>
                                                    <i className="fa fa-plus"></i>
                                                </button>
                                                <button className="btn btn-danger" onClick={(e) => setImgSize(parseFloat((imgSize - 0.1).toFixed(1)))}>
                                                    <i className="fa fa-minus"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-1 text-right" style={{ margin: "7px 15px 0 0" }}>
                                    그래프
                                </div>
                                <div className="col-md-2">
                                    <div className="input-group">
                                        <input type="number" className="form-control" value={graphSize} onChange={(e) => setGraphSize(e.target.value)} />
                                        <div className="input-group-append">
                                            <div className="btn-group">
                                                <button className="btn btn-success" onClick={(e) => setGraphSize(parseFloat((graphSize + 0.1).toFixed(1)))}>
                                                    <i className="fa fa-plus"></i>
                                                </button>
                                                <button className="btn btn-danger" onClick={(e) => setGraphSize(parseFloat((graphSize - 0.1).toFixed(1)))}>
                                                    <i className="fa fa-minus"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="title" dangerouslySetInnerHTML={{ __html: quiz.question }} onClick={(e) => setIsSizeControl(!isSizeControl)} />
                        {quiz.answer1 && (
                            <div className="answer">
                                {quiz.answer1}
                                {quiz.answer1SignedUrl && <img src={`${quiz.answer1SignedUrl}`} alt="" />}
                            </div>
                        )}
                        {quiz.answer2 && (
                            <div className="answer">
                                {quiz.answer2}
                                {quiz.answer2SignedUrl && <img src={`${quiz.answer2SignedUrl}`} alt="" />}
                            </div>
                        )}
                        {quiz.answer3 && (
                            <div className="answer">
                                {quiz.answer3}
                                {quiz.answer3SignedUrl && <img src={`${quiz.answer3SignedUrl}`} alt="" />}
                            </div>
                        )}
                        {quiz.answer4 && (
                            <div className="answer">
                                {quiz.answer4}
                                {quiz.answer4SignedUrl && <img src={`${quiz.answer4SignedUrl}`} alt="" />}
                            </div>
                        )}
                        {quiz.answer5 && (
                            <div className="answer">
                                {quiz.answer5}
                                {quiz.answer5SignedUrl && <img src={`${quiz.answer5SignedUrl}`} alt="" />}
                            </div>
                        )}
                    </Question>
                    {quiz.graphYn === "Y" ? (
                        <Chart fontSize={graphSize}>
                            <div className="title">투표결과</div>
                            {quiz.answer1 && (
                                <div className="answer">
                                    <h3>
                                        {quiz.answer1} <small>{quiz.perAnswer1}%</small>
                                    </h3>
                                    <Graph fontSize={graphSize} percent={quiz.perAnswer1}>
                                        <div />
                                    </Graph>
                                </div>
                            )}
                            {quiz.answer2 && (
                                <div className="answer">
                                    <h3>
                                        {quiz.answer2} <small>{quiz.perAnswer2}%</small>
                                    </h3>
                                    <Graph fontSize={graphSize} percent={quiz.perAnswer2}>
                                        <div />
                                    </Graph>
                                </div>
                            )}
                            {quiz.answer3 && (
                                <div className="answer">
                                    <h3>
                                        {quiz.answer3} <small>{quiz.perAnswer3}%</small>
                                    </h3>
                                    <Graph fontSize={graphSize} percent={quiz.perAnswer3}>
                                        <div />
                                    </Graph>
                                </div>
                            )}
                            {quiz.answer4 && (
                                <div className="answer">
                                    <h3>
                                        {quiz.answer4} <small>{quiz.perAnswer4}%</small>
                                    </h3>
                                    <Graph fontSize={graphSize} percent={quiz.perAnswer4}>
                                        <div />
                                    </Graph>
                                </div>
                            )}
                            {quiz.answer5 && (
                                <div className="answer">
                                    <h3>
                                        {quiz.answer5} <small>{quiz.perAnswer5}%</small>
                                    </h3>
                                    <Graph fontSize={graphSize} percent={quiz.perAnswer5}>
                                        <div />
                                    </Graph>
                                </div>
                            )}
                        </Chart>
                    ) : (
                        <Running fontSize={graphSize}>
                            <div>
                                <p>{time > -1 ? time : "투표완료"}</p>
                            </div>
                        </Running>
                    )}
                </Wrapper>
            ) : (
                <Wait>
                    <div>VOTE</div>
                </Wait>
            )}
            <SocketWrapper debug={debugSocket}>
                <Socket debug={debugSocket} onConnected={onConnected} onDisconnected={onDisconnected} />
            </SocketWrapper>
        </Container>
    );
};

const Container = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
`;

const Wait = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;

    align-items: center;
    justify-content: center;

    div {
        padding: 1vh 3vw;
        background: #3e3e3e;
        font-size: 10vw;
        font-weight: bold;
        color: #ffffff;
    }
`;
const SocketWrapper = styled.div`
    position: fixed;
    right: 0;
    bottom: 0;
    ${(props) =>
        props.debug &&
        `
        width:450px; height:250px;
    `}
`;

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
`;

const Question = styled.div`
    width: 70vw;
    padding: 2rem 4rem;
    color: #3e3e3e;
    .title {
        margin: 0 0 2rem;
        font-size: ${(props) => props.fontSize - 3}rem;
        cursor: pointer;
    }
    .answer {
        margin: 0 0 1rem;
        font-size: ${(props) => props.fontSize - 4}rem;
    }
    .answer img {
        margin-left: 1vw;
        max-width: ${(props) => props.imgSize}rem;
        max-height: ${(props) => props.imgSize}rem;
    }
    p,
    div {
        margin: 0;
        padding: 0;
    }
`;

const Chart = styled.div`
    width: 30vw;
    padding: 2rem;
    background: #10224a;
    color: white;
    .title {
        margin-bottom: 3vh;
        font-size: ${(props) => props.fontSize - 6}rem;
        font-weight: bold;
        text-align: center;
    }
    .answer {
        margin-bottom: 3vh;
    }
    .answer h3 {
        font-size: ${(props) => props.fontSize - 7}rem;
    }
    .answer h3 small {
        float: right;
        clear: right;
    }
    p,
    div {
        margin: 0;
        padding: 0;
    }
`;

const Graph = styled.div`
    width: 100%;
    height: ${(props) => props.fontSize - 7}rem;
    border-radius: ${(props) => (props.fontSize - 7) / 2}rem;
    overflow: hidden;
    background: white;
    div {
        width: ${(props) => Math.max(props.percent, 1)}%;
        height: 100%;
        background: #0c80e3;
    }
`;

const Running = styled.div`
    width: 30vw;
    background: #10224a;
    color: white;
    display: flex;

    align-items: center;
    justify-content: center;
    div {
        width: 20vw;
        height: 20vw;
        border: #0c80e3 solid 1px;
        border-radius: 10vw;
        padding: 1vw;
        display: flex;
    }
    div p {
        width: 18vw;
        height: 18vw;
        font-size: ${(props) => props.fontSize - 6}rem;
        line-height: 18vw;
        background: #0c80e3;
        color: #ffffff;
        border-radius: 9vw;
        text-align: center;
        font-weight: bold;
    }
`;
