import React, { useEffect, useState } from "react";
import qs from "query-string";
import { isMobile } from "react-device-detect";

import GlobalStyles, { ThemeProvider, Theme } from "./components/GlobalStyles";

// App Provider
import { AppProvider } from "./components/AppProvider";

// Utils
import { _CK, _API } from "./modules/utils";

// UI
import { Loading } from "./components/UI/Loading";

// Router
import Views from "./views";

export default () => {
    const [loaded, setLoaded] = useState(false);
    const [auth, setAuth] = useState("");
    const [iam, setIam] = useState(null);
    const [cfg, setCfg] = useState(null);
    const [isAuthLogin, setIsAuthLogin] = useState(false);
    // console.log("App.js")
    const preLoad = async () => {
        try {
            const cfg = await _API.site();
            setCfg(cfg);

            const parsedQs = qs.parse(window.location.search);
            

			if (parsedQs && parsedQs.ac && parsedQs.ak) {
				setIsAuthLogin(true);
				await authCodeLogin(parsedQs.ac, parsedQs.ak, cfg);
			} else if( window.location.hash.includes('?') && window.location.hash.includes('ac=') && window.location.hash.includes('ak=')) {
                const search = qs.parse(window.location.hash.split('?')[1]);
                setIsAuthLogin(true);
				await authCodeLogin(search.ac, search.ak, cfg);
            }

            if (_CK.isLogin()) {
                const data = await _API.iam();
                const { data: iam } = data;

                // 관리자 페이지
                if (window.location.hash.includes("admin")) {
                    // 이벤트 사이트 로그인 상태
                    if (iam.root === "eventSite") {
                        _CK.clearAuth();
                    } else {
                        if (iam.status === "Y") {
                            setAuth(_CK.getAuth());
                            setIam(iam);
                        } else {
                            console.log("회원 상태를 확인해주세요.");
                        }
                    }
                }

                // 이벤트 페이지
                if (!window.location.hash.includes("admin")) {
                    if (!iam.loginType) _CK.clearAuth();
                    setAuth(_CK.getAuth());
                    setIam(iam);
                }
            }
            setLoaded(true);
            // console.log(":::: App Pre Loaded ::::");
        } catch (e) {
            console.log("APP PreLoad Error", e);
            _CK.clearAuth();
            //document.location.href = "/";
        }
    };

    const authCodeLogin = async (linkLoginId, linkLoginAccessKey, cfg) => {
		await _API
			.post({
				path: "/v1/site/event/login/authCode",
				data: {
					siteIdx: cfg.idx,
					eventIdx: cfg.event?.eventIdx,
					linkLoginId,
                    linkLoginAccessKey,
                    loginDevice: isMobile ? "MOBILE" : "PC",
				},
			})
			.then((res) => {
				const { accessKey: auth } = res.data;
				_CK.setAuth(auth);
                if(window.location.hash.includes('?')){
                    window.location.href = "/"+window.location.hash.split('?')[0];
                    window.location.reload();
                } else {
                    document.location.href = "/";
                }
			})
			.catch((e) => {
				_CK.clearAuth();
				document.location.href = "/";
			});
	};

    useEffect(() => {
        preLoad();
    }, []);

    if (isAuthLogin) {
		return "";
	}

    if (!loaded) {
        return (
            <ThemeProvider theme={Theme}>
                <Loading />
                <GlobalStyles />
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider theme={Theme}>
            <AppProvider auth={auth} iam={iam} cfg={cfg}>
                <Views />
                <GlobalStyles />
            </AppProvider>
        </ThemeProvider>
    );
};
