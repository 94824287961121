import React, { useContext, useState, useEffect, Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import swal from "sweetalert";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import styled, { css } from "styled-components";

import Layout from "../Layout";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";
import { _DEF } from "../../../modules/config";

import { SearchCondition, DatePicker } from "../../../components/UI/Form";
import Paging, { ToPage, GetParams } from "../../../components/UI/Paging";

export default (props) => {

    const { history, location, match } = props;

    const [mode, setMode] = useState("");

    const { cfg } = useContext(AppContext);

    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [eventIdx, setEventIdx] = useState("");
    const [channelIdx, setChannelIdx] = useState("");
    const [keyword, setKeyword] = useState("");
    const [list, setList] = useState([]);
    const [paging, setPaging] = useState(null);
    const [eventList, setEventList] = useState([]);
    const [channelList, setChannelList] = useState([]);

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const loadList = (page) => {


        if (!isChecked()) {
            return;
        }

        const data = {
            siteIdx,
            eventIdx,
            channelIdx,
            keyword,
            page,
            startDate: isEmptyDate(startDate) ? null : (startDate+" 00:00:00"),
            endDate: isEmptyDate(endDate) ? null : (endDate+" 23:59:59"),
        };
        const req = { path: "/v1/a/event/vod/log", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setList(list);
            setPaging(paging);
        });
    };

    const onClickDateSearch = () => {
        loadList(1);
    }

    const isEmptyDate = date => {
        if (!date || date === "Invalid date") {
            return true;
        }
        return false;
    }

    const isChecked = () => {

        if (isEmptyDate(startDate) && isEmptyDate(endDate)) {
            return true;
        }

        if (isEmptyDate(startDate)) {
            setStartDate("");
            alert("시작날짜를 선택해주세요.");
            return false;
        }

        if (isEmptyDate(endDate)) {
            setEndDate("");
            alert("종료날짜를 선택해주세요.");
            return false;
        }

        if (startDate > endDate) {
            alert("날짜를 확인해주세요.");
            return false;
        }

        return true;
    }


    const movePage = (page) => {
        loadList(page);
    };

    const onSearch = (e) => {
        loadList(1);
    };

    useEffect(() => {
        loadList(paging?.pageNo || 1);
    }, []);

    useEffect(() => {
        loadList(1);
    }, [eventIdx, channelIdx])

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-12">
                    <h2>다시보기 집계</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/admin">HOME</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to={`${location.pathname}`}>이벤트</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>다시보기 집계</strong>
                        </li>
                    </ol>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className={cx({ "col-md-12": !mode, "col-md-8": mode })}>
                        <div className="ibox">
                            <div className="ibox-title">다시보기 집계</div>
                            <div className="ibox-content ibox-heading">
                                <SearchCondition sync={true}
                                    eventYn={"Y"}
                                    channelYn={"Y"}
                                    onChange={(e) => {
                                        setEventIdx(e.eventIdx);
                                        setChannelIdx(e.channelIdx);
                                        setKeyword(e.keyword);
                                    }}
                                    onSearch={onSearch}
                                    value={{ siteIdx, eventIdx, channelIdx, keyword }}
                                    setEventList={setEventList}
                                    setChannelList={setChannelList}
                                />
                                <DateWrapper>
                                    <DatePicker value={startDate} onChange={(v) => setStartDate(v.value)} />
                                    <span style={{paddingTop:"5px"}}> ~ </span>
                                    <DatePicker value={endDate} onChange={(v) => setEndDate(v.value)} />
                                    <button style={{ height: "37.5px" }} className={cx({ btn: true, "btn-xs": true, "btn-default": true })}
                                        onClick={onClickDateSearch}>날짜검색</button>
                                </DateWrapper>
                            </div>
                            <div className="ibox-content">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th className="text-center">사이트명</th>
                                                <th className="text-center">이벤트명</th>
                                                <th className="text-center">채널명</th>
                                                <th className="text-center">세션명</th>
                                                <th className="text-center">강좌명</th>
                                                <th className="text-center">연자명</th>
                                                <th className="text-center">조회수</th>
                                                <th className="text-center">추천수</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.map((item, i) => (
                                                <tr key={i}>
                                                    <td className="text-center">{item.siteTitle}</td>
                                                    <td className="text-center">{item.eventTitle}</td>
                                                    <td className="text-center">{item.channelTitle}</td>
                                                    <td className="text-center">{item.sessionTitle}</td>
                                                    <td className="text-center">{item.lectureTitle}</td>
                                                    <td className="text-center">
                                                        <div dangerouslySetInnerHTML={{ __html: (item.lectureMgrName||"").replace(/,/g, "</br>") }} />
                                                    </td>
                                                    <td className="text-center">{item.viewCnt}</td>
                                                    <td className="text-center">{item.recommendCnt}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {paging && (
                                <div className="ibox-footer">
                                    <Paging paging={paging} pageCallback={movePage} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

const DateWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 15px;
`;