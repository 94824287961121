import React, { Fragment, useContext, useEffect, useState } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";

import styled, { css } from "styled-components";
import swal from "sweetalert";
import moment from "moment";
import { isMobile } from "react-device-detect";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U, _CK } from "../../../modules/utils";
import { Loading } from "../../../components/UI/Loading";

import { ConnectionButton, H2, H3, ItemEmpty } from "./UI/Components";
import MainBooth from "../../../components/MainBooth";
import Booth from "../../../components/Booth";

import eboothBg from "../../../resources/image/basic/bg-e-booth.png";
import eboothBgOn from "../../../resources/image/basic/bg-e-booth-on.png";

export default (props) => {
    const { cfg, auth, iam } = useContext(AppContext);
    const event = cfg.event || null;

    const [isLoading, setIsLoading] = useState(true);

    const [isShown, setIsShown] = useState({});
    const [list, setList] = useState([]);
    const [showWindow, setShowWindow] = useState(false);
    const [clickedItem, setClickedItem] = useState({});
    const [showCompanyCnt, setShowCompantCnt] = useState(0);

    if (!event) {
        return null;
    }

    const { siteIdx, eventIdx, channel, startDate, endDate, eboothDesc } = event;

    if (!startDate || !endDate) {
        return null;
    }
    useEffect(() => {
        setIsLoading(true);
        loadList();
    }, []);

    const loadList = () => {
        const data = {
            siteIdx,
            eventIdx,
            eboothYn: "Y",
        };

        const req = { path: "/v1/event/company", data };
        _API.get(req).then((res) => {
            const { list } = res.data;
            const newList = list.filter((item) => item.eboothItemIdx || item.homepageUrl);
            let cnt = 0;
            for (let i in newList) {
                if (newList[i].isClicked) cnt++;
            }
            // console.log(list, newList);
            setList(newList);
            setShowCompantCnt(cnt);
            setIsLoading(false);
        });
    };

    // const onEnter = (idx) => {
    //     if (!_CK.isLogin()) {
    //         swal("로그인 후 이용이 가능합니다").then(() => {
    //             window.location.reload();
    //         });
    //     } else {
    //         window.open(`/#/channel/${idx}`);
    //         _U.postMessage("CLOSE-MODAL");
    //     }
    // };

    const companyLog = (item) => {
        if (event?.loginYn != "Y" || event?.templeteCode == "EBOOTH") {
            return;
        }
        return new Promise((resolve, reject) => {
            const data = {
                companyIdx: item.idx,
            };
            const req = { path: "/v1/event/company/log", data };
            _API.get(req).then((res) => {
                resolve(true);
            });
        });
    };

    const onClickLogo = async (item) => {

        if (item.cid) {
            item.idx = item.cid;
            item.companyIdx = item.cid;
        } else {
            item.cid = item.idx;
        }

        setIsShown({});

        switch (item.eboothType) {
            case "PREMIUM":
                window.open(`/#/ebooth/popup/${item.idx || item.cid}`);
                // await companyLog(item);
                break;
            case "BASIC":
                _U.postMessage("EBOOTH-BASIC", { ...item, url: "CONTENT" });
                await companyLog(item);
                break;
            case "LINK":
            default:
                window.open(item.homepageUrl || item.url);
                await companyLog(item);
                break;
        }

        // if (item.eboothItemIdx) {
        //     window.open(`/#/ebooth/popup/${item.idx || item.cid}`);
        // } else {
        //     window.open(item.homepageUrl || item.url);
        //     await companyLog(item);
        // }

        !item.isClicked && setShowCompantCnt(showCompanyCnt + 1);
        clickedItemShowBadge(item);
    };

    const clickedItemShowBadge = (item) => {
        setList(
            list.map((c) => {
                if (c.idx === item.idx) {
                    c.isClicked = true;
                }
                return c;
            })
        );
    };

    const rtnPopupSpec = () => {
        const popupWidth = 1600;
        const popupHeight = 900;

        const popupX = window.screen.width / 2 - popupWidth / 2;
        const popupY = window.screen.height / 2 - popupHeight / 2;

        return `width=${popupWidth},height=${popupHeight},left=${popupX},top=${popupY},location=no,status=no`;
    };

    const getEboothGrade = () => {
        let grade = "DEFAULT";
        // event.eboothMainIdx
        if (cfg.siteEboothGradeYn === "Y" &&
            event.eboothGradeYn === "Y" &&
            event.eboothMainIdx &&
            event.eboothMainContent) {
            grade = "PREMIUM";
        }

        return grade;
    }

    if (isLoading) {
        return <Loading background="rgba(0, 0, 0, 0.1)" />;
    }


    return (
        getEboothGrade() === "PREMIUM" ? (
            <PremiumEboothView
                list={list}
                isShown={isShown}
                setIsShown={setIsShown}
                showCompanyCnt={showCompanyCnt}
                onClickLogo={onClickLogo}
            />
        ) : (
            <DefaultEboothView
                list={list}
                isShown={isShown}
                setIsShown={setIsShown}
                showCompanyCnt={showCompanyCnt}
                onClickLogo={onClickLogo}
                eboothDesc={eboothDesc}
            />
        )
    );
};

const DefaultEboothView = (props) => {

    const { showCompanyCnt, list, setIsShown, isShown, onClickLogo, eboothDesc } = props;
    return (
        <Wrapper>
            <H2>E-BOOTH</H2>
            {eboothDesc && (<EboothDescWrapper dangerouslySetInnerHTML={{ __html: eboothDesc || "" }} />)}

            {(list && list.length > 0) ? (
                <Fragment>
                    {/* <ViewLayer>{`부스이벤트 ${list?.length || 0}개중 `}<ViewLog>{showCompanyCnt || 0}</ViewLog>{`건의 부스를 열람하였습니다.`}</ViewLayer> */}
                    <Container>
                        {list.map((item, i) => (
                            <EboothWrapper key={i} onClick={(e) => onClickLogo(item)} isClicked={item.isClicked}>
                                <EboothInner>
                                    {/* {item.isClicked && (
                                        <EboothInnerAttend>참여완료</EboothInnerAttend>
                                    )} */}
                                    <EboothInnerImg>
                                        <img src={item.finalSignedUrl} />
                                    </EboothInnerImg>
                                </EboothInner>
                            </EboothWrapper>
                        ))}
                    </Container>
                </Fragment>
            ) : (
                <EmptyWrapper>
                    <H3 style={{ fontSize: "25px" }}>준비중 입니다.</H3>
                </EmptyWrapper>
            )}
        </Wrapper>
    )
}
const EboothWrapper = styled.div`
    align-items: center;
    position: relative;
    width: 219px;
    height: 218px;
    object-fit: contain;
    background-image: ${(props) => `url(${props?.isClicked ? eboothBgOn : eboothBg})`};
    cursor:pointer;
    margin-right:20px;
    margin-bottom:25px;
    :last-child {
        margin-right:0px;
    }

    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                margin-right:0px;
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                margin-right:0px;
            }
          `;
        }
    }}
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        // flex-basis: 98%;
        // margin: 0 0 8px 0;
        margin-right:0px;
    }
`;

const EboothInner = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    padding : 77px 56px 94px 46px;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        // padding: 5px;
        // margin:0 auto;
    }
`;

const EboothInnerImg = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    

    font-family: ${(props) => props.theme.fontFamily.medium};
    font-size: 12px;
    text-align: center;
    color: ${(props) => props.theme.basic.color.primary};
    height: 46px;
    width: 116px;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        /* width: 100%;
        height: 100%;*/
        // min-height: 94px;
    }

    & img {
        width: 100%;
        // max-width:100%;
        // height:46px;
        object-fit: contain;

        @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
            
        }
    }
`;

const EboothInnerAttend = styled.div`
    position: absolute;
    top: 42px;
    left: 83px;
    height: 24px;
    padding: 3px 0 3px 0;
    border-top: 1px solid #d24040;
    border-bottom: 1px solid #d24040;
    color:#d24040;
    font-size:12px;
    font-weight:500;
`;

const PremiumEboothView = (props) => {

    const { showCompanyCnt, list, setIsShown, isShown, onClickLogo } = props;

    const { cfg } = useContext(AppContext);
    const event = cfg.event || null;

    const Wrapper = styled.div`
        // min-height: calc(100% - 192px);
        height: auto;
        ${props => {
            if (isMobile) {
                return css`
                @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                    width: 100%;
                    padding-top: 1rem;
                }
                @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                    width: 100%;
                    padding-top: 1rem;
                }
            `;
            }
        }}
        @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
            width: 100%;
            padding-top: 1rem;
        }
    `;

    return (
        <Fragment>
            {event?.eboothMainContent ? (
                <Wrapper>
                    <Booth content={event?.eboothMainContent} onClick={(element) => onClickLogo(element)} />
                </Wrapper>
            ) : (
                <Wrapper>
                    <H2>E-BOOTH</H2>
                    <EmptyWrapper>
                        <H3 style={{ fontSize: "25px" }}>준비중 입니다.</H3>
                    </EmptyWrapper>
                </Wrapper>
            )}
        </Fragment>
    )
}

const ClickedCompany = styled.div`
    position: absolute;
    top: 10px;
    left: 0px;
    height: 22px;

    & img {
        height: 100%;
    }
`;

const BoothWrapper = styled.div`
    widows: 100%;
    height: 100%;
`;

const BaseWrapper = styled.div`
/* width: 1200px; */
width : 100%;
margin: 0 auto;
padding-top: 3rem;

@media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
    width: 100%;
    padding: 1rem;
}
`;

const Wrapper = styled.div`
    width: 1200px;
    margin: 0 auto;
    padding-top: 3rem;
    // min-height: calc(100% - 192px);
    height: auto;

    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                width: 100%;
                padding: 1rem;
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                width: 100%;
                padding: 1rem;
            }
        `;
        }
    }}

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        width: 100%;
        padding: 1rem;
    }
`;

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: flex-start;
    
    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                justify-content: space-around;
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                justify-content: space-around;
            }
        `;
        }
    }}
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        justify-content: space-around;
    }
`;

const EboothImg = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: ${(props) => props.theme.fontFamily.medium};
    font-size: 12px;
    text-align: center;
    color: ${(props) => props.theme.basic.color.primary};
    min-height: 112px;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        /* width: 100%;
        height: 100%;*/
        min-height: 94px;
    }

    & img {
        width: 100%;
        object-fit: contain;

        @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
            
        }
    }
`;

const Article = styled.div`
    align-items: center;
    flex-basis: 12%;
    background-color: ${(props) => props.theme.basic.bg.background};
    margin: 8px 16px 8px 0px;
    position: relative;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        flex-basis: 31%;
        margin: 0 0 8px 0;
    }
`;

const ArticleInfo = styled.div`
    position: relative;
    border: solid 1px ${(props) => props.theme.basic.line.default};
    padding: 15px;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    display: flex;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        padding: 5px;
        margin:0 auto;
    }
`;

const HoverEffect = styled.div`
    position: absolute;
    padding-top: 10px;
    top: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    color: #ffffff !important;
    font-size: 14px;
    background-color: #000000;
    border-radius: 10px;
`;

const ViewLayer = styled.div`
    width: 100%;
    font-size:15px;
    text-align:right;
    padding-right:6px;
    margin-bottom:20px;

    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                font-size:13px;
                padding-left: 5px;
                padding-bottom: 8px;
                padding-right:0px;
                text-align:left;
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                font-size:13px;
                padding-left: 5px;
                padding-bottom: 8px;
                padding-right:0px;
                text-align:left;
            }
        `;
        }
    }}
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        font-size:13px;
        padding-left: 5px;
        padding-bottom: 8px;
        padding-right:0px;
        text-align:left;
    }
`;

const ViewLog = styled.span`
    font-weight:600;
    color:red;
`;

const EmptyWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const EboothDescWrapper = styled.div`
    padding-left: 10px;
    line-height: 0.8rem;
    margin-bottom: 1.5rem;
`