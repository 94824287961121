import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import swal from "sweetalert";
import cx from "classnames";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";
import { _DEF } from "../../../modules/config";

import Layout from "../Layout";
import { SearchInput, SelectForm, InputForm, SegmentedForm, FileForm, DatePicker } from "../../../components/UI/Form";
import Wrapper, { Row, FormGroup, Separator } from "../../../components/UI/Wrapper";
import SNEditor from "../../../components/UI/SNEditor";
import { BlockButton } from "../../../components/UI/Button";
import EMCanvas from "../../../components/UI/EMCanvas";
import EBCanvas from "../../../components/UI/EBCanvas";

export default (props) => {
    const { history, location, match } = props;
    const { eventIdx } = match.params;
    const [data, setData] = useState({});
    const [content, setContent] = useState({});
    const [html, setHtml] = useState("");
    const [ratio, setRatio] = useState(_DEF.EB_RATIO[0].value);
    const [menuBaseUrl, setMenuBaseUrl] = useState(_DEF.MENU_BASE_URL)

    const loadData = () => {
        if (!eventIdx) {
            return;
        }
        const req = { path: `/v1/a/event/info`, data: { eventIdx } };
        _API.get(req)
            .then((res) => res.data)
            .then((data) => {
                setData(data);
                setContent(data?.content ? JSON.parse(data.content) : {});
                setHtml(data.footer);

                const addMenu = [..._DEF.MENU_BASE_URL]
                data?.eposterYn === "Y" && addMenu.push({ label: "이포스터", value: "EPOSTER", sort: 9 });
                data?.eboothYn === "Y" && addMenu.push({ label: "이부스", value: "EBOOTH", sort: 10 });
                data?.attendanceYn === "Y" && addMenu.push({ label: "출결확인", value: "ATTENDANCE", sort: 11 });
                const addDescs = _U.jsonParse(data?.addDesc);
                for (let i in addDescs) {
                    const item = addDescs[i];
                    addMenu.push({ label: item.label, value: `ADDDESC_${i}`, sort: parseInt(i) + 12 });
                }
                changeEventMenu(addMenu.sort((a, b) => (a.sort > a.sort ? 1 : a.sort < b.sort ? -1 : 0)));
            });
    };

    const changeEventMenu = async bMenu => {
        const menuList = await getMenuList();
        for (let menu in menuList) {
            bMenu.map(m => {
                if (m.value === menu.toUpperCase() && menuList[menu].name) {
                    m.label = menuList[menu].name;
                }
            })
        }
        setMenuBaseUrl(bMenu);
    }

    const getMenuList = () => {
        if (!eventIdx) {
            return;
        }
        const param = { eventIdx };

        return new Promise((resolve, reject) => {
            const req = { path: `/v1/a/event/menu/`, data: param };
            _API.get(req)
                .then((res) => res.data)
                .then((data) => {
                    resolve(data.reduce((acc, cur) => {
                        return { ...acc, ...{ [cur.code.toLowerCase()]: cur } }
                    }, {}));
                });
        });
    };

    const isRegContent = content => {
        return ((content['16x9'] && content['16x9'].length && content['16x9'][0].url)
            || (content['9x16'] && content['9x16'].length && content['9x16'][0].url));
    }

    const handleSave = (e) => {
        if (e) e.preventDefault();
        const params = { ...data, footer: html };

        // if (!isRegContent(content)) {
        //     swal({ title: "알림", text: "컨텐츠를 등록해 주세요." });
        //     return;
        // }
        if (!params.eventIdx) {
            swal({ title: "알림", text: "이벤트정보가 없습니다." });
            return;
        }
        if (!params.siteIdx) {
            swal({ title: "알림", text: "이벤트정보가 없습니다." });
            return;
        }
        // if (!params.templeteCode) {
        //     swal({ title: "알림", text: "템플릿을 선택해 주세요." });
        //     return;
        // }
        if (!params.templeteCode) {
            params.templeteCode = "BASIC";
        }
        params.content = content;

        _API.post({
            path: "/v1/a/event/visual",
            data: params,
        }).then((res) => {
            swal({ title: "알림", text: `이벤트 비주얼정보가 ${!data.eventVisualIdx ? "생성" : "수정"}되었습니다.` }).then(() => {
                // _U.postMessage("EVENT-CHANGED");
                props.reSetEventInfo && props.reSetEventInfo();
                loadData();
            });
        });
    };

    const fileUpload = (name, item) => {
        if (name === "bg") {
            const bg = content?.bg || {};
            bg[ratio] = item.url;
            setContent({ ...content, bg: { ...bg } });
        } else {
            const urlName = name.replace("FileIdx", "SignedUrl");
            setData({ ...data, [name]: item.id, [urlName]: item.signedUrl });
        }
    };

    useEffect(() => {
        loadData();
    }, [eventIdx]);

    const getEboothBgFileDescription = () => {
        console.log(ratio, data, data?.headerShowYn)
        if (ratio == "16x9") {
            if (data?.headerShowYn == "N") {
                return "*권장해상도 1920 x 1080 인쇄용 or 5760 x 3240(16:9)";
            }
            return "*권장해상도 1920 x 960 인쇄용 or 5760 x 2880(16:8)";
        } else {
            return "*권장해상도 1080x1920 인쇄용 or 3240 x 5760(9:16)";
        }
    }

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12">
                    <div className="ibox">
                        <div className="ibox-title">설정</div>
                        <div className="ibox-content">
                            <div className="row">
                                <div className="col-md-6">
                                    <SelectForm
                                        title="템플릿"
                                        name="templeteCode"
                                        items={_DEF.TEMPLATE}
                                        value={data?.templeteCode || "BASIC"}
                                        onChange={(e) => {
                                            setData({ ...data, [e.target.name]: e.target.value });
                                        }}
                                        nullTitle="템플릿 선택"
                                    />
                                </div>
                                <div className="col-md-6">
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <FileForm title="상단로고" onChange={(v) => fileUpload("logoTopPcFileIdx", v)} path={`EVENT/VISUAL/${moment().format("YYYY/MM/DD")}`} url={data?.logoTopPcSignedUrl} description="*권장해상도 144 x 30" />
                                    <FileForm title="로그인 메인비주얼(행사전)-PC" onChange={(v) => fileUpload("mVisualPcFileIdx", v)} path={`EVENT/VISUAL/${moment().format("YYYY/MM/DD")}`} url={data?.mVisualPcSignedUrl} description="*권장해상도 1920 x 544" />
                                    <FileForm title="로그인 메인비주얼(행사전)-MOBILE" onChange={(v) => fileUpload("mVisualMbFileIdx", v)} path={`EVENT/VISUAL/${moment().format("YYYY/MM/DD")}`} url={data?.mVisualMbSignedUrl} description="*권장해상도 1125x900(5:4)" />
                                    <FileForm title="로그인 메인비주얼(행사기간)-PC" onChange={(v) => fileUpload("bgPcFileIdx", v)} path={`EVENT/VISUAL/${moment().format("YYYY/MM/DD")}`} url={data?.bgPcSignedUrl} description="*권장해상도 1920 x 544" />
                                    <FileForm title="로그인 메인비주얼(행사기간)-MOBILE" onChange={(v) => fileUpload("bgMbFileIdx", v)} path={`EVENT/VISUAL/${moment().format("YYYY/MM/DD")}`} url={data?.bgMbSignedUrl} description="*권장해상도 1125x900(5:4)" />
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-3 control-label">푸터(HTML)</label>
                                        <div className="col-sm-9">
                                            <div className="input-group">
                                                <SNEditor content={html} onChange={(v) => setHtml(v)} height="200" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <BlockButton onClick={handleSave} className="btn-primary">
                                        <i className="fa fa-save"></i> 저장하기
                                    </BlockButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-9">
                    <div className="ibox">
                        <div className="ibox-title">이벤트 메인 편집창</div>
                        <div className="ibox-content ibox-heading">
                            <div className="row">
                                <div className="col-md-4">
                                    <SelectForm title="비율" nullTitle="비율" items={_DEF.EB_RATIO} value={ratio} onChange={(e) => setRatio(e.target.value)} />
                                </div>
                                <div className="col-md-4"></div>
                                <div className="col-md-4">
                                    <FileForm title="배경화면" accept="image/*" onChange={(v) => fileUpload("bg", v)} path={`EVENT/VISUAL/BG/${moment().format("YYYY/MM/DD")}`} description={getEboothBgFileDescription()} />
                                </div>
                            </div>
                        </div>
                        <div className="ibox-content">
                            <>
                                {data?.headerShowYn == "N" ? (
                                    <EBCanvas debug ratio={ratio} elements={content} bg={content.bg} onChange={setContent} />
                                )
                                    : (
                                        <EMCanvas debug ratio={ratio} elements={content} bg={content?.bg} onChange={setContent} />
                                    )}
                            </>
                        </div>
                    </div>
                </div>
                <ContentBinder eventIdx={eventIdx} ratio={ratio} elements={content} onChange={setContent} menuBaseUrl={menuBaseUrl} />
            </div>
        </Fragment>
    );
};

const ContentBinder = (props) => {
    const { ratio, elements, onChange, eventIdx, menuBaseUrl } = props;

    const [el, setEl] = useState(null);
    const [contents, setContents] = useState([]);

    const loadContents = () => {
        if (!eventIdx) {
            return;
        }
        const req = {
            path: "/v1/a/event/content/list",
            data: {
                eventIdx,
                category: "EMAIN",
            },
        };
        _API.get(req)
            .then((res) => res.data)
            .then((data) => {
                const { list } = data;
                setContents(list);
            });
    };

    const handleContentSelect = (name, value, label) => {
        if (name === "cid") {
            const cs = contents.filter((o) => o.idx === parseInt(value));
            if (cs.length === 0) {
                return;
            }
            const content = cs[0];

            const list = elements[ratio];
            if (list) {
                list.map((item) => {
                    if (item.id === el.id) {
                        item.cid = content.idx;
                        item.title = content.title;
                        item.url = "content";
                        item.contentType = "content";
                        item.itemType = content.contentType;
                        if (content.contentType === "LINK") item.itemUrl = content.content;
                    }
                    return item;
                });
                onChange && onChange({ ...elements, [ratio]: [...list] });
            }
        } else {
            const list = elements[ratio];
            if (list) {
                list.map((item) => {
                    if (item.id === el.id) {
                        item.cid = null;
                        item.title = label;
                        item.url = value;
                        item.contentType = value === "content" ? "content" : "link";
                    }
                    return item;
                });
                onChange && onChange({ ...elements, [ratio]: [...list] });
            }
        }
    };

    useEffect(() => {
        setEl(null);
        const els = elements[ratio];
        if (els && els.length > 0) {
            const ef = els.filter((el) => el.select === true);
            if (ef.length > 0) {
                setEl(ef[0]);
            }
        }
    }, [elements]);

    useEffect(() => {
        loadContents();
    }, [eventIdx]);

    return (
        <div className="col-md-3">
            <div className="ibox">
                <div className="ibox-title">콘텐츠 연결</div>
                <div className="ibox-content">
                    <div className="row">
                        <div className="col-md-12">ID : {el?.id}</div>
                    </div>
                    <Separator />
                    <div className="row">
                        <div className="col-md-12">
                            <select className="form-control" value={`${el?.url}`} onChange={(e) => handleContentSelect("contentType", e.target.value, e.target[e.target.selectedIndex].text)}>
                                <option value="">연결구분 선택</option>
                                {menuBaseUrl?.map((item, i) => (
                                    <option key={i} value={item.value}>
                                        {item.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    {el?.contentType === "content" && (
                        <Fragment>
                            <Separator />
                            <div className="row">
                                <div className="col-md-12">
                                    <select className="form-control" value={`${el?.cid}`} onChange={(e) => handleContentSelect("cid", e.target.value)}>
                                        <option value="">콘텐츠 선택</option>
                                        {contents.map((item, i) => (
                                            <option key={i} value={item.idx}>
                                                {item.title}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};
