import React, { useState } from "react";
import { Link } from "react-router-dom";

import Layout from "../Layout";

import SNEditor from "../../../components/UI/SNEditor";

export default (props) => {
    const [content, setContent] = useState("<p>Hi~~</p>");

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-12">
                    <h2>위지윅 에디터</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/dashboard">HOME</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/dev/dashboard">개발자</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>위지윅 에디터</strong>
                        </li>
                    </ol>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="ibox">
                            <div className="ibox-title">에디터</div>
                            <div className="ibox-content">
                                <SNEditor content={content} onChange={setContent} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="ibox">
                            <div className="ibox-title">결과</div>
                            <div
                                className="ibox-content"
                                dangerouslySetInnerHTML={{
                                    __html: content,
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
