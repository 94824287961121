import React, { Fragment, useContext, useEffect, useState } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";

import Modal from "react-modal";
import styled from "styled-components";
import swal from "sweetalert";
import moment from 'moment';

import { AppContext } from "../../../components/AppProvider";
import { _API, _U, _CK } from "../../../modules/utils";

import { H2 } from "./UI/Components";

import { Theme } from "../../../components/GlobalStyles";

export default (props) => {
    const { cfg, auth, iam } = useContext(AppContext);
    const event = cfg.event || null;
    const { code } = props;
    const [addDesc, setAddDesc] = useState(_U.jsonParse(event.addDesc));
    const [content, setContent] = useState(null);

    if (!event) {
        return null;
    }

    useEffect(() => {
        if (code) {
            try {
                const index = code.split('ADDDESC_')[1];
                setContent(addDesc[index]);
            } catch (e) {
                setContent({ label: "인사말", value: "안내문을 등록해 주세요." });
            }
        }
    }, [code])

    return (
        <>
            <H2>{content?.label}</H2>
            <div
                className=""
                dangerouslySetInnerHTML={{
                    __html: content?.value,
                }}
            ></div>
        </>
    );
};