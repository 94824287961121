import React, { useContext, useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import swal from "sweetalert";
import Modal from "react-modal";

import Layout from "../Layout";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";
import { _DEF } from "../../../modules/config";

import { SearchInput, SelectForm, InputForm, SegmentedForm } from "../../../components/UI/Form";
import Paging, { ToPage, GetParams } from "../../../components/UI/Paging";
import { Separator } from "../../../components/UI/Wrapper";
import { Theme } from "../../../components/GlobalStyles";
import { ModalCloseButton } from "../../../components/UI/Button";


export default (props) => {

    const { history, location, match } = props;

    const { cfg } = useContext(AppContext);

    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [eventIdx, setEventIdx] = useState("");
    const [companyIdx, setCompanyIdx] = useState("");
    const [keyword, setKeyword] = useState("");
    const [list, setList] = useState([]);
    const [paging, setPaging] = useState(null);
    const [siteList, setSiteList] = useState([]);
    const [eventList, setEventList] = useState([]);
    const [companyList, setCompanyList] = useState([]);

    const loadList = (page) => {
        // if(!eventIdx){
        //     swal({ title: "알림", text: "이벤트를 선택해 주세요." });
        //     return;
        // }
        const data = {
            siteIdx,
            eventIdx,
            companyIdx,
            keyword,
            page,
        };
        const req = { path: "/v1/a/event/ebooth/company/access/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setList(list);
            setPaging(paging);
        });
    };

    const loadEventList = (siteIdx) => {
        const data = {
            siteIdx,
        };
        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setEventList(list);
        });
    };

    const loadCompanyList = (eventIdx) => {
        const data = {
            eventIdx,
        };
        const req = { path: "/v1/a/event/company", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setCompanyList(list);
        });
    };

    const movePage = (page) => {
        loadList(page);
    };

    const onSearch = (e) => {
        if (e) e.preventDefault();
        loadList(1);
    };

    useEffect(() => {
        loadList(1);
        loadEventList(siteIdx);
    }, []);

    useEffect(() => {
        if (eventIdx) loadCompanyList(eventIdx);
        else setCompanyList([]);

        setCompanyIdx("");
    }, [eventIdx]);

    useEffect(() => {
        loadList(1);
    }, [eventIdx, companyIdx]);

    // const onDetail = (item) => {
    //     setParticipantInfo(item);
    // }

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-8">
                    <h2>이부스열람</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/admin">HOME</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>이부스 열람정보</strong>
                        </li>
                    </ol>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className={cx({ "col-md-12": true, "col-md-4": false })}>
                        <div className="ibox">
                            <div className="ibox-title">참석자목록</div>
                            <div className="ibox-content ibox-heading">
                                <div className="row">
                                    <div className="col-md-2">
                                        <select value={eventIdx} onChange={(e) => setEventIdx(e.target.value)} className="form-control">
                                            <option value="">이벤트선택</option>
                                            {eventList?.map((item, i) => (
                                                <option key={i} value={item.eventIdx}>
                                                    {item.title}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-2">
                                        <select value={companyIdx} onChange={(e) => setCompanyIdx(e.target.value)} className="form-control">
                                            <option value="">업체선택</option>
                                            {companyList?.map((item, i) => (
                                                <option key={i} value={item.idx}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <SearchInput value={keyword} onChange={(e) => setKeyword(e.target.value)} onClick={onSearch} placeholder="참석자를 입력해 주세요." />
                                    </div>
                                </div>
                                <div className="" style={{ textAlign: "right", paddingRight: "15px" }}>
                                    총 열람 수 : {paging?.totalSize || 0}
                                </div>
                            </div>
                            <div className="ibox-content">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                {/* <th className="text-center">ID</th> */}
                                                {/* <th className="text-center">사이트명</th> */}
                                                <th className="text-center">이벤트명</th>
                                                <th className="text-center">업체명</th>
                                                <th className="text-center">이름</th>
                                                <th className="text-center">소속</th>
                                                <th className="text-center">휴대폰번호</th>
                                                <th className="text-center">최종접속시간</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.map((item, i) => (
                                                <tr key={i}>
                                                    {/* <td className="text-center">{item.participantIdx}</td> */}
                                                    {/* <td className="text-center">{item.siteName}</td> */}
                                                    <td className="text-center">{item.eventName}</td>
                                                    <td className="text-center">{item.companyName}</td>
                                                    <td className="text-center">{item.participantName}</td>
                                                    <td className="text-center">{item.participantCompanyName}{item.participantDeptName && "(" + item.participantDeptName + ")"}</td>
                                                    <td className="text-center">{item.participantPhone}</td>
                                                    <td className="text-center">{item.logModDate && moment(item.logModDate).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {paging && (
                                <div className="ibox-footer">
                                    <Paging paging={paging} pageCallback={movePage} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};