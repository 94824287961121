import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import nl2br from "nl2br";
import { isMobile } from "react-device-detect";
import cx from "classnames";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U, _CK } from "../../../modules/utils";
import { Loading } from "../../../components/UI/Loading";
import Video from "../../../components/UI/Video";
import { H2 } from "./UI/Components";

import { ICFavoriteOn, ICFavoriteOnSet, ICFavoriteOff, ICFavoriteOffSet, getBgSet } from "./asset";

import { default as Comment } from "./Eposter.Comment";

export default (props) => {
    const { cfg, auth, iam } = useContext(AppContext);
    const event = cfg.event || null;
    const { siteIdx, eventIdx } = event;
    const { idx } = props;

    const [item, setItem] = useState(null);
    const [isFavorite, setIsFavorite] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const onFavorite = () => {
        _API.post({
            path: "/v1/site/event/eposter/favorite",
            data: {
                siteIdx,
                eventIdx,
                abstractIdx: props.idx,
            },
        }).then((res) => {
            setIsFavorite(res.data === "I");
        });
    };

    const loadData = () => {
        _API.get({
            path: "/v1/site/event/eposter/item",
            data: {
                siteIdx,
                eventIdx,
                idx,
            },
        }).then((res) => {
            setItem(res.data);
            setIsFavorite(res.data.isFavorite);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        loadData();
    }, [idx]);

    if (isLoading) {
        return <Loading title="E-POSTER를 조회하고 있습니다." background="rgba(0, 0, 0, 0.1)" />;
    }

    return (
        <Wrapper>
            <H2 style={{ padding: "0 1rem" }}>
                {item.title}
                {event.eposterOptYn === 'Y' &&
                    <FavoriteButton onClick={onFavorite} ison={isFavorite} />
                }
            </H2>
            <Author>
                {item.name} {item.companyName ? ` (${item.companyName})` : ""}
            </Author>
            {item.description && <Desc dangerouslySetInnerHTML={{ __html: nl2br(item.description) }} />}

            <VideoWrapper>
                <Video url={item.finalEncodedUrl} poster={item.thumbnailSignedUrl} autoPlay={true} removeCustomSize={true} width="100%" height="100%" fluid={false} />
            </VideoWrapper>
            {event.eposterOptYn === 'Y' && <Comment abstractIdx={idx} url={item.finalEncodedUrl} />}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const Author = styled.div`
    color: ${(props) => props.theme.basic.color.off};
    font-family: ${(props) => props.theme.fontFamily.regular};
    font-size: 14px;
    padding: 0 1rem 1rem;
`;

const VideoWrapper = styled.div`
    width:1160px;
    height:500px;
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        width:100%;
        height:50%;
    }
`;

const Desc = styled.p`
    color: ${(props) => props.theme.basic.color.primary};
    font-family: ${(props) => props.theme.fontFamily.regular};
    font-size: 14px;
    padding: 0 1rem;
`;

const FavoriteButton = styled.span`
    cursor: pointer;
    margin-left: 1rem;
    display: inline-block;

    ${(props) =>
        props.ison
            ? `
            background-image: ${`url(${ICFavoriteOn})`};
            background-image: -webkit-image-set(${getBgSet(ICFavoriteOnSet)});
        `
            : `
            background-image: ${`url(${ICFavoriteOff})`};
            background-image: -webkit-image-set(${getBgSet(ICFavoriteOffSet)});    
        `}

    background-repeat: no-repeat;
    background-position: center 2px;
    background-size: 100%;
    width: 16px;
    height: 22px;
`;
