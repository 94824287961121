import React, { useContext, useState, useEffect, Fragment } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import swal from "sweetalert";

import Layout from "../Layout";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";
import { _DEF } from "../../../modules/config";

import { SearchInput, SelectForm, InputForm, SegmentedForm, TextForm, DatePicker } from "../../../components/UI/Form";
import Paging, { ToPage, GetParams } from "../../../components/UI/Paging";
import { Separator } from "../../../components/UI/Wrapper";

const DETAIL_STATUS = [
    { label: "대기", value: "W" },
    { label: "정상", value: "Y" },
    { label: "사용안함", value: "N" },
];

const CAREER_TYPE = [
    { label: "EDUCATION", value: "EDU" },
    { label: "EXPERIENCE", value: "EXP" },
];

const CAREER_TYPE_NM = {
     EDU : "EDUCATION"
    ,EXP : "EXPERIENCE"
}

export default (props) => {

    const { history, location, match } = props;

    let { locationIdx } = useParams();
    // const history = useHistory();
    
    const [mode, setMode] = useState("");
    const [info, setInfo] = useState("");
    const [keyword, setKeyword] = useState("");
    const [list, setList] = useState([]);
    const [paging, setPaging] = useState(null);
    
    const onAddClick = () => {
        setMode("ADD");
    };

    const onModify = (idx) => {
        setMode(idx);
    };

    const loadPage = (data) => {
        const param = { ...data, locationIdx : locationIdx === "all" ? "" : locationIdx };
        const req = { path: "/v1/a/location/detail/list", data : param };
        _API.get(req).then((res) => {
            const { locationDetailList, info } = res.data;
            setList(locationDetailList);
            locationIdx !== "all" && setInfo({...info, name : `${info.title} (${info.zipCode}) ${info.addr} ${info.addrDtl}`});
        });
    };

    const onSearch = (e) => {
        ToPage(location, history, { keyword });
    };

    const onRefresh = () => {
        loadPage({ keyword });
    };

    useEffect(() => {
        const params = GetParams(location);
        if (params) {
            setKeyword(params.keyword);
            loadPage({...params, locationIdx : locationIdx !== "all" ? locationIdx : ""});
        } else {
            loadPage({ locationIdx : locationIdx !== "all" ? locationIdx : ""});
        }
    }, [location, locationIdx]);

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-8">
                    <h2>세부장소 목록</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/admin">HOME</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to={`${location.pathname}`}>장소 관리</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>세부장소</strong>
                        </li>
                    </ol>
                </div>
                <div className="col-sm-4">
                    <div className="title-action">
                        { locationIdx !== "all"
                            && 
                                <Fragment>
                                    <button type="button" className="btn btn-info" onClick={(e) => history.replace("/admin/location/list")} style={{marginRight:"5px"}}>
                                        <i className="fa fa-arrow-left"></i> 뒤로
                                    </button>
                                    {/* <button type="button" className="btn btn-primary" onClick={onAddClick.bind(this)}>
                                        <i className="fa fa-plus"></i> 추가
                                    </button> */}
                                </Fragment> 
                        }
                    </div>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className={cx({ "col-md-12": !mode, "col-md-8": mode })}>
                        <div className="ibox">
                            <div className="ibox-title">세부장소 목록
                                { locationIdx !== "all" && info?.name &&
                                    <span className="label label-info">{info.name}</span>
                                }
                                {info.lat && info.lng && (
                                    <a href={"https://www.google.com/maps/search/?api=1&query=" + info.addr} target="_blank" style={{marginLeft: "5px"}}>
                                        <i className="fa fa-map-marker"></i>
                                    </a>
                                )}
                            </div>
                            <div className="ibox-content">
                                <LocationDetailList list={list} onChange={onRefresh} onModify={onModify} locationIdx={locationIdx} history={history}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

const LocationDetailList = (props) => {
    const { onChange, list, onModify, locationIdx, history } = props;
    
    return (
        <div className="row">
            {list.map((item, i) => (
                <LocationDTLItem key={i} {...item} locationIdx={locationIdx} onChange={onChange} history={history} />
            ))}
            { locationIdx && locationIdx !== "all" && <LocationDTLItem locationIdx={locationIdx} onChange={onChange} history={history}/> }
            
        </div>
    );
};

const LocationDTLItem = (props) => {
    const { locationIdx, idx, title: titleInProps, onChange, history } = props;
    // const { siteIdx } = useContext(AppContext);

    const [title, setTitle] = useState("");

    const onSave = () => {

        if (!title) {
            return;
        }
        const data = { locationIdx, idx, title, status : "Y" };
        _API.post({
            path: "/v1/a/location/detail/save",
            data: data,
        }).then((res) => {
            swal({ title: "알림", text: `세부장소가 ${idx ? "수정" : "등록"} 되었습니다.` }).then(() => {
                // _U.postMessage("SITE-LOCATION-DTL-CHANGED");
                onChange && onChange();
                !idx && setTitle("");
            });
        });
    };

    const onDelete = () => {

        swal({
            // title: `[${title}] 세부장소를 삭제하시겠습니까?`,
            text: `[${title}] 세부장소를 삭제하시겠습니까?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                _API.post({
                    path: "/v1/a/location/detail/save",
                    data: {
                        locationIdx,
                        idx,
                        title,
                        status : "D",
                    },
                }).then((res) => {
                    swal({ title: "알림", text: `세부장소가 삭제 되었습니다.` }).then(() => {
                        // _U.postMessage("SITE-LOCATION-DTL-CHANGED");
                        onChange && onChange();
                        // setTitle("");
                    });
                });
            }
          });

    };

    useEffect(() => {
        setTitle(titleInProps);
    }, [idx]);

    return (
        <div className="col-lg-2">
            <div className="contact-box center-version">
                <div className="row">
                    <div className="col-sm-12">
                        <input type="text" className="form-control" value={title || ""} onChange={(e) => setTitle(e.target.value)} />
                    </div>
                </div>
                { locationIdx !== "all" && 
                    <div className="contact-box-footer">
                        {idx ? (
                            <div className="btn-group">
                                <button className="btn btn-xs btn-warning" onClick={onSave}>
                                    수정
                                </button>
                                <button className="btn btn-xs btn-danger" onClick={onDelete}>
                                    삭제
                                </button>
                            </div>
                        ) : (
                            <div className="btn-group">
                                <button className="btn btn-xs btn-primary" onClick={onSave}>
                                    추가
                                </button>
                            </div>
                        )}
                    </div>
                }
            </div>
        </div>
    );
};
