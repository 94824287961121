import React, { useContext, useEffect, useState, Fragment } from "react";
import styled from "styled-components";
import swal from "sweetalert";

import { AppContext } from "../../../components/AppProvider";

import { _API, _U, _CK } from "../../../modules/utils";

export default (props) => {
    const { auth } = useContext(AppContext);

    const [isLogin, setIsLogin] = useState(false);
    const [isForm, setIsForm] = useState(false);

    const onLogin = () => {
        setIsForm(true);
    };

    const onLoginCancel = () => {
        setIsForm(false);
    };

    const onReceivedMessage = (e) => {
        _U.parseMessage(e).then((data) => {
            switch (data.code) {
                case "OPEN-LOGIN-FORM":
                    setIsForm(true);
                    break;
            }
        });
    };

    useEffect(() => {
        setIsLogin(_CK.isLogin());
        setIsForm(false);

        window.addEventListener("message", onReceivedMessage);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, [auth]);

    return (
        <Fragment>
            <LoginContainer>{!isLogin ? <BeforeLogin onLogin={onLogin} /> : <AfterLogin />}</LoginContainer>
            {isForm && <Form onCancel={onLoginCancel} />}
        </Fragment>
    );
};

const BeforeLogin = (props) => {
    const { onLogin } = props;
    return (
        <LoginWrapper>
            <LoginButton onClick={onLogin}>
                <i className="fa fa-sign-in"></i> 로그인
            </LoginButton>
        </LoginWrapper>
    );
};

const AfterLogin = (props) => {
    const { saveAuth, saveIam } = useContext(AppContext);
    const onLogout = () => {
        _CK.clearAuth();
        saveAuth(null);
        saveIam(null);
    };
    return (
        <LoginWrapper>
            <LoginButton onClick={onLogout}>
                <i className="fa fa-sign-out"></i> 로그아웃
            </LoginButton>
        </LoginWrapper>
    );
};

const LoginContainer = styled.div`
    margin: 0 0 1rem;
`;
const LoginWrapper = styled.div``;
const LoginButton = styled.button`
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    font-weight: normal;
    background: #ffffff;
    border: ${(props) => props.theme.color.secondary} solid 1px;
    border-radius: 0.3rem;
    width: 100%;
    display: block;
`;

/** Form *************************/
const Form = (props) => {
    const { onCancel } = props;
    const { cfg, saveAuth, saveIam } = useContext(AppContext);
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [cert, setCert] = useState("");
    const [isSended, setIsSended] = useState(false);
    const [isUnlock, setIsUnlock] = useState(false);
    const { event } = cfg;

    const onRequestCert = () => {
        if (isSended) {
            return;
        }
        if (event.loginType === "PHONE" && !mobile) {
            swal({ title: "알림", text: "휴대폰 번호를 입력해 주세요." });
            return;
        }
        if (event.loginType === "PHONE" && (!mobile.startsWith("01") || mobile.length < 10 || mobile.length > 13)) {
            swal({ title: "알림", text: "올바른 휴대폰 번호를 입력해 주세요." });
            return;
        }
        
        if (event.loginType === "EMAIL" && !email) {
            swal({ title: "알림", text: "이메일을 입력해 주세요." });
            return;
        }
        if (event.loginType === "EMAIL" && !_U.isEmail(email)) {
            swal({ title: "알림", text: "올바른 이메일 형식을 입력해 주세요." });
            return;
        }

        _API.post({
            path: isUnlock ? "/v1/site/event/login" : ( event.loginType === "PHONE" ? "/v1/site/event/login/sms/send" : "/v1/site/event/login/email/send"),
            data: {
                siteIdx: cfg.idx,
                eventIdx: cfg.event?.eventIdx,
                mobile,
                email,
                loginType : event.loginType,
            },
        })
        .then((res) => {
            if (isUnlock) {
                onLogin(res.data);
            } else {
                setIsSended(true);
                setTimeout(() => {
                    try {
                        setIsSended(false);
                    } catch (e) {}
                }, 180000);
            }
        });
    };

    const onCert = () => {
        if (!cert) {
            swal({ title: "알림", text: "인증번호를 입력해 주세요." });
            return;
        }
        if (cert.length !== 6) {
            swal({ title: "알림", text: "올바른 인증번호를 입력해 주세요." });
            return;
        }

        _API.post({
            path: "/v1/site/event/login/sms/cert",
            data: {
                siteIdx: cfg.idx,
                siteEventIdx: cfg.event?.idx,
                mobile,
                cert,
            },
        }).then((res) => {
            onLogin(res.data);
        });
    };

    const onLogin = (data) => {
        const { accessKey: auth, data: iam } = data;
        _CK.setAuth(auth);
        saveAuth(auth);
        saveIam(iam);
        swal({ title: "로그인", text: `${iam.name}님 방문해 주셔서 감사합니다.` }).then(() => {});
    };

    useEffect(() => {
        setIsUnlock(cfg.event.useLoginUnlock !== "Y");
    }, [cfg]);

    return (
        <FContainer>
            <FWrapper>
                <FTitle>로그인</FTitle>
                { event.loginType === "PHONE" &&
                <FItem>
                    <FInput type="text" value={mobile} placeholder="휴대폰번호를 입력해 주세요." onChange={(e) => setMobile(e.target.value)} disabled={isSended} />
                    <FInputButton onClick={onRequestCert}>
                        {isUnlock ? (
                            <Fragment>
                                <i className="fa fa-sign-in"></i> 로그인
                            </Fragment>
                        ) : (
                            "인증번호발송"
                        )}
                    </FInputButton>
                </FItem>}
                { event.loginType === "EMAIL" &&
                <FItem>
                    <FInput type="text" value={email} placeholder="이메일을 입력해 주세요." onChange={(e) => setEmail(e.target.value)} disabled={isSended} />
                    <FInputButton onClick={onRequestCert}>
                        {isUnlock ? (
                            <Fragment>
                                <i className="fa fa-sign-in"></i> 로그인
                            </Fragment>
                        ) : (
                            "인증번호발송"
                        )}
                    </FInputButton>
                </FItem>}
                {isSended && (
                    <Fragment>
                        <FItem>
                            <FInput type="number" value={cert} placeholder="인증번호를 입력해 주세요." onChange={(e) => setCert(e.target.value)} />
                            <FInputButton onClick={onCert}>
                                <i className="fa fa-sign-in"></i> 로그인
                            </FInputButton>
                        </FItem>
                    </Fragment>
                )}
                <FItem>
                    <FInputButton block onClick={onCancel}>
                        <i className="fa fa-times"></i> 취소
                    </FInputButton>
                </FItem>
            </FWrapper>
        </FContainer>
    );
};

const FContainer = styled.div`
    padding: 1rem;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    flex-direction: column;

    background: rgba(0, 0, 0, 0.7);
    border: none;
    z-index: 2000;
    align-items: center;
    justify-content: center;
`;
const FTitle = styled.h3`
    margin-bottom: 1rem;
`;
const FWrapper = styled.div`
    padding: 1rem;
    border: #cccccc solid 1px;
    border-radius: 0.5rem;
    background: #ffffff;
`;
const FItem = styled.div`
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
`;
const FInput = styled.input`
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    font-weight: normal;
    flex: 1;
    border: #ddddd3 solid 1px;
`;
const FInputButton = styled.button`
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    font-weight: normal;
    border: #ddddd3 solid 1px;

    ${(props) =>
        props.block
            ? `
        background:#ffffff;
        border-radius: 0.3rem;
        flex:1;
    `
            : `
        flex-basis: 9rem;
        border-left: none;
    `}
`;
