import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import swal from "sweetalert";
import { isMobile } from "react-device-detect";

import { AppContext } from "../../../components/AppProvider";

import { InputForm, SelectForm, H2, HRDash, ButtonSubmit, ButtonDefault } from "./UI/Components";

import { _API, _U, _CK } from "../../../modules/utils";

import { _DEF } from "../../../modules/config";
import MainBooth from "../../../components/MainBooth";

// import GUIDE from "../resources/images/guide.gif";
import GUIDE from "../../../resources/image/basic/guide.gif";

export default (props) => {
    const { code, history } = props;
    const { cfg } = useContext(AppContext);
    const event = cfg.event || null;
    const frameSize = (window.innerHeight) + "px";

    const [isGuide, setIsGuide] = useState(true);

    const onClickGuid = () => {
        _CK.set("clickedGuid", true);
        setIsGuide(false);
    }

    const clickedEnter = () => {
        return _CK.get("clickedEnter");
    }

    return (
        <Wrapper >
            {event?.main3DPath && ((event?.main3DPcYn === "Y" && !isMobile) || (event?.main3DMbYn === "Y" && isMobile)) && (
                <>
                    {!_CK.get("clickedGuid") && isGuide && (
                        <GuideWrapper onClick={onClickGuid}>
                            <img src={GUIDE} />
                            <GuideText>마우스 휠을 위/아래로 하시면 화면 확대/축소가 됩니다.</GuideText>
                        </GuideWrapper>
                    )}
                    <iframe src={`/${event?.main3DPath}/index.htm${clickedEnter() ? '?media-index=2' : ''}`}
                        style={{ width: '1px', minWidth: '100%', minHeight: frameSize, border: "0px" }}
                    />
                    {/* <iframe src={`https://test.md-webinar.com/kams_vr/index.htm${clickedEnter() ? '?media-index=2' : ''}`}
                        style={{ width: '1px', minWidth: '100%', minHeight: frameSize, border: "0px" }}
                    /> */}
                </>
            )}
            {event?.content &&
                (!event?.main3DPath ||
                    (
                        (event?.main3DPcYn !== "Y" && !isMobile) ||
                        (event?.main3DMbYn !== "Y" && isMobile)
                    )
                ) &&
                <MainBooth content={event?.content} onClick={(element) => _U.postMessage("SELECT-MENU", element)} />
            }
        </Wrapper>);
};

const Wrapper = styled.div`
height: auto;
@media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
    width: 100%;
    /* padding-top: 1rem; */
}
`;

const GuideWrapper = styled.div`
    z-index: 10;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media only screen and (max-width: ${(props) => props.theme.basic.width}px) {
        img {
            width: 90%;
        }
    }
`;

const GuideText = styled.div`
    font-size: 50px;
    color: white;
    position: fixed;
    bottom: 120px;
    z-index: 11;
`;