import React, { useState, useEffect, Fragment, useRef } from "react";
import cx from "classnames";
import moment from "moment";
import styled from "styled-components";
import { isIOS, isMobile } from "react-device-detect";
import videojs from "video.js";
import "video.js/dist/video-js.min.css";

import { _CFG } from "../modules/config";
import { _CK, _API } from "../modules/utils";

export default (props) => {
    const { url, poster, isLive, selectVodTime, removeCustomSize, type, videoRefProp } = props;
    const { autoPlay, controls, muted, loop, uiOff, uiFeed, fluid, width, height, paddingTop } = props;
    const { streamType, controlsList } = props;

    const videoNode = videoRefProp ? videoRefProp : useRef(null);
    const player = useRef(null);

    useEffect(() => {
        if (url) {
            if (!player.current) {
                player.current = videojs(videoNode.current);
            }
            player.current.src({ type: "application/x-mpegURL", src: getAuthUrl(url, streamType) });

            if (autoPlay && (muted || muted == null || muted == undefined)) {
                player.current.autoplay("muted");
            }
            if (videoRefProp && isMobile) {
                videoNode.current = player.current;
            }
        }
    }, [url]);

    useEffect(() => {
        return () => {
            if (player.current) {
                player.current.dispose();
                player.current = null;
            }
        };
    }, []);

    return (
        <VideoWrapper
            ref={videoNode}
            className="video-js"
            controls
            preload="auto"
            poster={poster}
            width={width}
            height={height}
            playsInline
            autoPlay={autoPlay}
            muted={muted}
            loop={loop}
            fluid={fluid}
            paddingTop={paddingTop}
            controlsList={controlsList}
        />
    );
};

const getAuthUrl = (url, streamType) => {
    if (!url) return "";
    else if (streamType === "OUT") return `${url}`;
    else if (url.indexOf("?") > -1) {
        return `${url}`;
    } else {
        return `${url}?apikey=${encodeURIComponent(_CFG.apiKey)}`;
    }
}

const VideoWrapper = styled.video`
    width: 100%;
    position: relative;
    /* height:100%; */
    max-height: 100%;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        height: 100%;
    }
    

    ${(props) =>
        props.paddingTop
            ?
            `padding-top: ${props.paddingTop};`
            // : `padding-top: 45.60%;`
            : (window.innerHeight && window.innerHeight < 800) ? `padding-top:${(45.60 - ((800 - window.innerHeight) / 10))}%;` : `padding-top: 45.60%;`
    }

    .vjs-big-play-button {
        top: 50%;
        left: 50%;
        margin-top: -0.75em;
        margin-left: -1.5em;
    }
`;