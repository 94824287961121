import React, { useContext, useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import swal from "sweetalert";
import { AppContext } from "../../components/AppProvider";

import { _API, _U } from "../../modules/utils";
import { _DEF } from "../../modules/config";
import Paging from "../../components/UI/Paging";

import Input, { SearchInput, SelectForm, InputForm, SegmentedForm } from "../../components/UI/Form";

export default (props) => {
    const { code, callback } = props;
    const { cfg } = useContext(AppContext);

    const [eventList, setEventList] = useState([]);
    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [eventIdx, setEventIdx] = useState("");

    const [keyword, setKeyword] = useState("");
    const [list, setList] = useState([]);
    const [paging, setPaging] = useState(null);

    const [data, setData] = useState(null);

    const loadEventList = () => {
        if (!siteIdx) {
            setEventList([]);
            setEventIdx("");
            return;
        }

        const data = {
            siteIdx,
        };

        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list } = res.data;
            setEventList(list);
        });
    };

    const loadList = (page) => {
        const data = {
            siteIdx,
            eventIdx,
            keyword,
            page,
            numPerPage: 5,
        };

        const req = { path: "/v1/a/event/common", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setList(list);
            setPaging(paging);
        });
    };

    useEffect(() => {
        loadList(1);
        loadEventList();
    }, []);

    const movePage = (page) => {
        loadList(page);
    };

    const onSearch = (e) => {
        loadList(1);
    };

    return (
        <div className="wrapper wrapper-content">
            <h2>카테고리 설정</h2>
            <div className="row mt-3">
                <div className="col-md-8">
                    <div className="ibox">
                        <div className="ibox-title">목록</div>
                        <div className="ibox-content ibox-heading">
                            <div className="row">
                                <div className="col-md-4">
                                    <SelectForm
                                        title="이벤트"
                                        name="eventIdx"
                                        items={eventList.map((event) => {
                                            return { label: event.eventName, value: event.idx };
                                        })}
                                        value={eventIdx}
                                        onChange={(e) => {
                                            setEventIdx(e.target.value);
                                        }}
                                        nullTitle={eventList?.length > 0 ? `이벤트 선택` : `사이트를 먼저 선택하세요.`}
                                    />
                                </div>
                                <div className="col-md-8">
                                    <SearchInput value={keyword} onChange={(e) => setKeyword(e.target.value)} onClick={onSearch} placeholder="검색어를 입력해 주세요." />
                                </div>
                            </div>
                        </div>
                        <div className="ibox-content">
                            <div className="table-responsive">
                                <table className="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th className="text-center">이벤트명</th>
                                            <th className="text-center">코드</th>
                                            <th className="text-center">이름</th>
                                            <th className="text-center">순번</th>
                                            <th className="text-center">상태</th>
                                            <th className="text-center">관리</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list?.map((item, i) => (
                                            <ListItem
                                                {...item}
                                                key={i}
                                                onModify={(data) => {
                                                    setData(data);
                                                }}
                                                refresh={() => {
                                                    loadList(paging?.pageNo || 1);
                                                }}
                                            />
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {paging && (
                            <div className="ibox-footer">
                                <Paging paging={paging} pageCallback={movePage} />
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-md-4">
                    <Form
                        {...props}
                        data={data}
                        callback={() => {
                            loadList(paging?.pageNo || 1);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

const ListItem = (props) => {
    const onChangeSort = (e) => {
        const req = { path: "/v1/a/event/common/sort", data: { idx: props.idx, sort: e.target.value } };
        _API.post(req).then((res) => {
            props.refresh && props.refresh();
        });
    };

    const onChangeStatus = (status) => {
        const req = { path: "/v1/a/event/common/status", data: { idx: props.idx, status } };
        _API.post(req).then((res) => {
            props.refresh && props.refresh();
        });
    };

    const onRemove = () => {
        swal({
            title: "정말 삭제 하시겠습니까?",
            text: "삭제된 데이터는 복구가 불가합니다 .",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                _API.post({ path: `/v1/a/event/common/status`, data: { idx: props.idx, status: "D" } }).then((res) => {
                    swal({ title: "알림", text: `항목이 삭제되었습니다.` }).then(() => {
                        props.refresh && props.refresh();
                    });
                });
            }
        });
    };

    const weights = [...Array(100).keys()];

    return (
        <tr>
            <td className="text-center">{props.eventTitle}</td>
            <td className="text-center">{props.keyword}</td>
            <td className="text-center">{props.value}</td>
            <td className="text-center">
                <select name="sort" onChange={onChangeSort.bind(this)} value={props.sort} className="form-control">
                    {weights.map((i) => (
                        <option value={i + 1} key={i}>
                            {i + 1}
                        </option>
                    ))}
                </select>
            </td>
            <td className="text-center">
                <div className="btn-group">
                    <button className={cx({ btn: true, "btn-xs": true, "btn-default": props.status !== "Y", "btn-success": props.status === "Y" })} onClick={onChangeStatus.bind(this, "Y")}>
                        사용
                    </button>
                    <button className={cx({ btn: true, "btn-xs": true, "btn-default": props.status !== "N", "btn-success": props.status === "N" })} onClick={onChangeStatus.bind(this, "N")}>
                        사용안함
                    </button>
                </div>
            </td>
            <td className="text-center">
                <div className="btn-group">
                    <button className={cx({ btn: true, "btn-xs": true, "btn-warning": true })} onClick={props.onModify.bind(this, props)}>
                        <i className="fa fa-edit"></i>
                    </button>
                    <button className={cx({ btn: true, "btn-xs": true, "btn-danger": true })} onClick={onRemove}>
                        <i className="fa fa-trash"></i>
                    </button>
                </div>
            </td>
        </tr>
    );
};

const Form = (props) => {
    const { callback, data } = props;
    const { cfg } = useContext(AppContext);

    const [form, setForm] = useState({
        status: "Y",
        code: props.code,
        siteIdx: cfg.idx,
    });

    const [eventList, setEventList] = useState([]);

    const onSave = () => {
        if (!form?.siteIdx) {
            swal("사이트를 선택하세요.");
            return;
        }
        if (!form?.eventIdx) {
            swal("이벤트를 선택하세요.");
            return;
        }
        // if (!form?.keyword) {
        //     swal("코드를 입력하세요.");
        //     return;
        // }
        if (!form?.value) {
            swal("이름을 입력하세요.");
            return;
        }

        const req = { path: "/v1/a/event/common", data: {...form, code : "ABSTRACT"} };
        _API.post(req).then((res) => {
            setForm({
                status: "Y",
                code: props.code,
                siteIdx: cfg.idx,
            });
            swal(form?.idx ? `수정 되었습니다.` : `저장 되었습니다.`).then(() => {
                callback && callback();
            });
        });
    };

    const loadEventList = () => {
        if (!form?.siteIdx) {
            setEventList([]);
            setEventIdx("");
            return;
        }

        const data = {
            siteIdx: form?.siteIdx,
        };

        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list } = res.data;
            setEventList(list);
        });
    };

    useEffect(() => {
        if (data?.idx) {
            setForm(data);
        }
    }, [data]);

    useEffect(() => {
        loadEventList();
    }, []);

    const weights = [...Array(100).keys()];

    return (
        <div className="ibox">
            <div className="ibox-title">{form?.idx ? `수정` : `등록`}</div>
            <div className="ibox-content">
                <SelectForm
                    title="이벤트"
                    name="eventIdx"
                    items={eventList.map((event) => {
                        return { label: event.eventName, value: event.idx };
                    })}
                    value={form?.eventIdx}
                    onChange={(e) => {
                        setForm({ ...form, [e.target.name]: e.target.value });
                    }}
                    nullTitle={eventList?.length > 0 ? `이벤트 선택` : `사이트를 먼저 선택하세요.`}
                />
                {/* <InputForm title="코드" name="keyword" value={form?.keyword} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="코드 입력" description="코드는 영문으로 시작 해야 하며 영문,숫자 조합" /> */}
                <InputForm title="이름" name="value" value={form?.value} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="이름 입력" />

                <SelectForm
                    title="정렬순서"
                    name="sort"
                    items={weights.map((sort) => {
                        return { label: sort + 1, value: sort + 1 };
                    })}
                    value={form?.sort}
                    onChange={(e) => {
                        setForm({ ...form, [e.target.name]: e.target.value });
                    }}
                    nullTitle={"정렬 순서를 선택하세요."}
                />

                {/* <SegmentedForm title="상태" items={_DEF.STATUS} value={form?.status} onClick={(v) => setForm({ ...form, status: v })} /> */}
                <div className="hr-line-dashed"></div>
                <div className="row">
                    <div className="col-md-12 text-right">
                        <button className="btn btn-primary" onClick={onSave}>
                            <i className="fa fa-save"></i> 저장
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
