import React, { Fragment, useContext, useEffect, useState } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";

import Modal from "react-modal";
import styled from "styled-components";
import swal from "sweetalert";
import moment from 'moment';
import { isMobile } from "react-device-detect";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U, _CK } from "../../../modules/utils";

import { H2, Hr, ModalVideoTitle } from "./UI/Components";

import { Theme } from "../../../components/GlobalStyles";

import Video from "../../../components/UI/Video";

import { ModeImg, ModeImgSet, HomeImg, HomeImgSet, PhoneImg, PhoneImgSet, MessageImg, MessageImgSet, getBgSet } from "./asset";

export default (props) => {
    const { cfg, auth, iam } = useContext(AppContext);
    const event = cfg.event || null;

    const { code, contentIdx: companyIdx } = props;
    const [contentType, setContentType] = useState("");
    const { siteIdx, eventIdx } = event;

    const [data, setData] = useState({});
    const [baseVodUrl, setBaseVodUrl] = useState(null);
    const [baseVodFileIdx, setBaseVodFileIdx] = useState(null);
    const [brochure1FileIdx, setBrochure1FileIdx] = useState(null);
    const [brochure2FileIdx, setBrochure2FileIdx] = useState(null);
    const [brochure1FileUrl, setBrochure1FileUrl] = useState(null);
    const [brochure2FileUrl, setBrochure2FileUrl] = useState(null);
    const [description, setDescription] = useState("");
    const [vodEncodedUrl, setVodEncodedUrl] = useState(null);

    const [content, setContent] = useState({});
    // const [footer, setFooter] = useState("");
    const [status, setStatus] = useState("");
    // const [ratio, setRatio] = useState(_DEF.EB_RATIO[0].value);

    if (!event) {
        return null;
    }

    const loadData = () => {
        const data = { siteIdx, eventIdx, code, companyIdx };

        _API.get({ path: "/v1/site/event/ebooth/basic", data })
            .then((res) => res.data)
            .then((data) => data.data)
            .then((data) => {
                setData(data);
                setContent(data?.content ? JSON.parse(data.content) : {});
                setStatus(data?.status);
                // setSiteIdx(data?.siteIdx);
                // setEventIdx(data?.eventIdx);
                // setEboothType(data?.eboothType);
                setBaseVodFileIdx(data?.baseVodFileIdx);
                setBaseVodUrl(data?.baseVodFileSignedUrl);
                setVodEncodedUrl(data?.vodEncodedUrl);
                setBrochure1FileIdx(data?.brochure1FileIdx);
                setBrochure2FileIdx(data?.brochure2FileIdx);
                setDescription(data?.description);
                setBrochure1FileUrl(data?.brochure1SignedUrl);
                setBrochure2FileUrl(data?.brochure2SignedUrl);
            });

    }

    const goUrl = (url) => {
        window.open(url);
    }

    const getUrl = () => {
        return data?.vodSignedUrl;
    };

    useEffect(() => {
        // _U.postMessage("LOCATION-CHANGED");
        loadData();
    }, [siteIdx, eventIdx, companyIdx, auth]);

    return (
        <Wrapper>
            <ContentHeader>
                <H2 style={{ textAlign: "center" }}>
                    {data?.companyLogoSignedUrl
                        ? <img alt="image" style={{ width: "116px", height: "auto" }} src={data?.companyLogoSignedUrl} />
                        : data?.companyName
                    }
                </H2>
                <HRSolid />
                <Desc>
                    {data?.description}
                </Desc>
            </ContentHeader>

            <ContentWrapper>
                <VideoContent>
                    {vodEncodedUrl ? (
                        <Video url={vodEncodedUrl} autoPlay={true} removeCustomSize={true} />
                    ) : (
                        <video src={baseVodUrl} playsInline autoPlay={true} width="100%" height={isMobile ? "100%" : "400"} controls />
                    )}
                </VideoContent>
                <OtherContent>
                    <Buttons>
                        {data?.companyHomepageUrl &&
                            <button className="homepage" onClick={() => goUrl(data.companyHomepageUrl)}><HomeImgFile />Go Homepage</button>
                        }
                        {brochure1FileUrl &&
                            <button onClick={() => goUrl(brochure1FileUrl)}><ModeImgFile />Brochure</button>
                        }

                        {brochure2FileUrl &&
                            <button onClick={() => goUrl(brochure2FileUrl)}><ModeImgFile />Brochure</button>
                        }
                    </Buttons>
                    {(data?.companyHomepageUrl || brochure1FileUrl || brochure2FileUrl) && (
                        <HRSolid style={{ marginTop: "30px", marginBottom: "30px" }} />
                    )}
                    <Contact>
                        <div>
                            <p className="contact-us">Contact US</p>
                            <p className="contact-default"><MessageImgFile />{data?.companyEmail}</p>
                            <p className="contact-default"><PhoneImgFile />{data?.companyPhone}</p>
                        </div>
                    </Contact>
                </OtherContent>
            </ContentWrapper>
            {/* <p>{JSON.stringify(data)}</p> */}

            {/* <p>{data?.companyAddr}</p>
            <p>{data?.companyAddrDtl}</p>
            <p>{data?.companyMgrName}</p>
            <p>{data?.companyMgrPhone}</p>
            <p>{data?.companyMgrEmail}</p> */}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding-top: 10px;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        width: 100%;
        padding-top: 0px;
    }
`;

const Desc = styled.div`
    width: 100%;
    /* height: 60%; */
    max-height: 155px;
    overflow: auto;
    margin-bottom: 10px;

    word-break: break-all;
    
    ::-webkit-scrollbar {
        display: none;
    }

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        width: 100%;
    }
`;

const HRSolid = styled.hr`
    border-top: 1px solid #e7e7e7;
    width: 100%;
`;


const ContentHeader = styled.div`
    /* height: 50%; */
    width: 100%;
`;
const ContentWrapper = styled.div`
    width: 100%;
    height: 50%;
    display: flex;
    gap: 2%;
    flex-wrap: wrap;
    /* flex-direction: row;
    justify-content: space-between; */
`;

const VideoContent = styled.div`
    flex: 0 0 49%;
    height: 100%;
    margin-bottom:10px;
    
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        flex: 0 0 100%;
    }
`;

const OtherContent = styled.div`
    flex: 0 0 49%;
    width: 50%;
    
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        flex: 0 0 100%;

        & button {
            width: 100%
        }
    }
`;

const Buttons = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.7rem;
    font-size: 16px;

    .homepage {
        flex: 0 0 100% !important;

        @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
            margin-top: 0.7rem;
        }
        
    }

    & button {
        color: ${(props) => props.theme.basic.color.buttonSubmit};
        border-radius: 5px;
        background-color: ${(props) => props.theme.basic.color.buttonConnection};
        border: none;
        height: 60px;
        flex: 0 0 49% ;

        @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
            flex: 0 0 100%;
            height: 37px;
            font-size: 11px;
        }
        
    }
`;

const Contact = styled.div`
    & div {
        border-left : 3px solid;
        padding-left: 15px;
        color: ${(props) => props.theme.basic.bg.default};
    }

    .contact-us {
        font-size: 14px;
        font-weight: 500;
        color: ${(props) => props.theme.basic.bg.default};
    }

    .contact-default {
        color: ${(props) => props.theme.basic.color.off};
    }
`;

const HomeImgFile = styled.span`
    width: 32px;
    height: 28px;

    background-size: contain;
    background-image: ${`url(${HomeImg})`};
    background-image: -webkit-image-set(${getBgSet(HomeImgSet)});
    background-repeat: no-repeat;

    background-position: center center;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    cursor: pointer;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        width: 18.7px;
        height: 17.2px;
    }

`;

const ModeImgFile = styled.span`
    width: 33px;
    height: 31px;

    background-size: contain;
    background-image: ${`url(${ModeImg})`};
    background-image: -webkit-image-set(${getBgSet(ModeImgSet)});
    background-repeat: no-repeat;

    background-position: center center;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    cursor: pointer;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        width: 19px;
        height: 15.3px;
    }
    
`;

const PhoneImgFile = styled.span`
    width: 16px;
    height: 21px;

    background-size: contain;
    background-image: ${`url(${PhoneImg})`};
    background-image: -webkit-image-set(${getBgSet(PhoneImgSet)});
    background-repeat: no-repeat;

    background-position: center center;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    cursor: pointer;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        width: 14.7px;
        height: 20.2px;
    }
    
`;

const MessageImgFile = styled.span`
    width: 20px;
    height: 14px;

    background-size: contain;
    background-image: ${`url(${MessageImg})`};
    background-image: -webkit-image-set(${getBgSet(MessageImgSet)});
    background-repeat: no-repeat;

    background-position: center center;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    cursor: pointer;
    
`;