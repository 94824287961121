import React, { Fragment, useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import swal from "sweetalert";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";
import { _DEF, _CFG } from "../../../modules/config";

import { SearchInput, InputForm, TextForm, DateTimePicker, SearchCondition } from "../../../components/UI/Form";

import Paging, { ToPage, GetParams } from "../../../components/UI/Paging";

export default (props) => {

    return (
        <Fragment>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-12">
                    <h2>발송내역</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/">HOME</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/send/manage/sms">발송관리</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>발송내역</strong>
                        </li>
                    </ol>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className="col-md-8">
                        <List />
                    </div>
                    <div className="col-md-4">
                        <SendForm />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

// export { default as SMSCertView } from "./cert";
// export { default as SMSErrorView } from "./error";

const SendForm = (props) => {
    const [sender, setSender] = useState("07077700701");
    const [receiver, setReceiver] = useState("");
    const [content, setContent] = useState("");
    const [senddate, setSenddate] = useState(null);

    const handleSend = () => {
        _API.post({
            path: "https://api.dm9.io/v1/sms/send",
            data: { sender, receiver, content, senddate: senddate ? moment(senddate).format("YYYYMMDDHHmmss") : null },
            apiKey: _CFG.dm9ApiKey,
        }).then((res) => {
            swal({ title: "알림", text: "발송되었습니다." }).then(() => {
                setContent("");
                _U.postMessage("SMS-CHANGED");
            });
        });
    };

    return (
        <div className="ibox">
            <div className="ibox-title">문자발송</div>
            <div className="ibox-content">
                <InputForm title="발송번호" type="number" name="sender" value={sender} onChange={(e) => setSender(e.target.value)} placeholder="발송번호를 입력해 주세요" />
                <InputForm title="수신번호" type="number" name="receiver" value={receiver} onChange={(e) => setReceiver(e.target.value)} placeholder="수신번호를 입력해 주세요" />
                <TextForm title="내용" name="content" value={content} onChange={(e) => setContent(e.target.value)} placeholder="내용을 입력해 주세요" hr />
                <DateTimePicker title="예약발송" value={senddate} onChange={(d) => setSenddate(d.value)} hr />
                <div className="row">
                    <div className="col-md-12">
                        <button type="button" className="btn btn-block btn-primary" onClick={handleSend}>
                            <i className="fa fa-send"></i> 발송
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const List = (props) => {

    const { cfg } = useContext(AppContext);

    const [keyword, setKeyword] = useState("");
    const [list, setList] = useState([]);
    const [siteIdx, setSiteIdx] = useState("");
    const [eventIdx, setEventIdx] = useState("");
    
    const [paging, setPaging] = useState(null);

    const loadPage = (page) => {
        // const req = { path: "https://api.dm9.io/v1/sms", data: { nextId: id, keyword }, apiKey: _CFG.dm9ApiKey, };
        const req = { path: "/v1/a/send/manage/item/list", data: { siteIdx, eventIdx, sendType : "sms" ,keyword, page }};

        _API.get(req)
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                const { list, paging } = data;
                if (list.length === 0) {
                    swal({ title: "알림", text: "모든 정보를 조회 하였습니다." });
                } else {
                    setList(list);
                }
                setPaging(paging);
            });
    };

    const movePage = (page) => {
        loadPage(page);
    };

    const onSearch = (e) => {
        setList([]);
        loadPage(paging?.pageNo || 1);
    };

    const setEllipsis = (text) => {
        return text.length > 50 ? (text.substr(0,50) + "...") : text;
    }
    
    const onReceivedMessage = (e) => {
        _U.parseMessage(e).then((data) => {
            switch (data.code) {
                case "SMS-CHANGED":
                    setList([]);
                    loadPage(paging?.pageNo || 1);
                    break;
            }
        });
    };

useEffect(() => {
        onSearch();
    }, [eventIdx]);

    useEffect(() => {
        loadPage(paging?.pageNo || 1);
        window.addEventListener("message", onReceivedMessage);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, []);

    return (
        <div className="ibox">
            <div className="ibox-title">발송내역</div>
            <div className="ibox-content ibox-heading">
                <div className="row">
                    <div className="col-md-6">
                        <SearchCondition
                            sync={true}
                            siteYn={"N"}
                            eventYn={"Y"}
                            onChange={(e) => {
                                setSiteIdx(cfg.idx);
                                setEventIdx(e.eventIdx);
                                setKeyword(e.keyword);
                            }}
                            onSearch={onSearch}
                            value={{ siteIdx : cfg.idx, eventIdx, keyword }}
                        />
                    </div>
                </div>
            </div>
            <div className="ibox-content">
                <div className="table-responsive">
                <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th className="text-center">No.</th>
                                <th className="text-center">발신자</th>
                                <th className="text-center">수신자</th>
                                <th className="text-center">내용</th>
                                <th className="text-center">상태</th>
                                {/* <th className="text-center">예약일</th> */}
                                <th className="text-center">발송일</th>
                                <th className="text-center">등록일</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((item, i) => (
                                <tr key={i}>
                                    <td className="text-center">{i + 1}</td>
                                    <td className="text-center">{item.sender}</td>
                                    <td className="text-center">{item.receiver}</td>
                                    <td className="text-center">{setEllipsis(item.content)}</td>
                                    <td className="text-center">{_DEF.getDefinedLabel(_DEF.SEND_STATUS_TYPES, item.sendStatus)}</td>
                                    {/* <td className="text-center">{item.schDate === 0 ? "-" : moment.unix(item.schDate).format("YYYY-MM-DD HH:mm:ss")}</td> */}
                                    <td className="text-center">{item.sendDate ? moment(item.sendDate, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss") : "-"}</td>
                                    <td className="text-center">{item.regDate ? moment(item.regDate, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss") : "-"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {paging && (
                <div className="ibox-footer">
                    <Paging paging={paging} pageCallback={movePage} />
                </div>
            )}
        </div>
    );
};
