import React from "react";
import styled from "styled-components";
import cx from "classnames";

const Button = styled.button.attrs((props) => {
    return {
        type: props.type || "button",
        className: props.className || "btn btn-default",
    };
})``;

const blockButton = styled.button.attrs((props) => {
    return {
        type: props.type || "button",
        className: `btn btn-block ${props.className}`,
    };
})``;

export const ModalCloseButton = styled.button`
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    z-index: 9999;
    color: #000;
    font-size: 1.2rem;
    cursor: pointer;
    background: #fff;
    line-height: unset;
`;

export default Button;
export const BlockButton = blockButton;
