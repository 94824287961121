import React, { Fragment, useContext, useEffect, useState } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Modal from "react-modal";

import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U, _CK } from "../../../modules/utils";
import { Loading } from "../../../components/UI/Loading";
import { Theme } from "../../../components/GlobalStyles";

import { ConnectionButton, H2, H3, ItemEmpty, CloseButton } from "./UI/Components";

import { InputForm, SegmentedForm } from "../../../components/UI/Form";
import { _DEF } from "../../../modules/config";

import EposterVideo from "./Eposter.Video";

export default (props) => {
    const { cfg, auth, iam } = useContext(AppContext);
    const event = cfg.event || null;
    const { siteIdx, eventIdx } = event;

    const [isLoading, setIsLoading] = useState(true);

    return (
        <Wrapper>
            <H2>E-POSTER</H2>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 1200px;
    margin: 0 auto;
    padding-top: 3rem;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        width: 100%;
        padding: 1rem;
    }
`;
