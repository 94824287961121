import { createGlobalStyle, ThemeProvider as Provider } from "styled-components";
import reset from "styled-reset";

import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.css";
import "filepond/dist/filepond.min.css";
import "react-summernote/dist/react-summernote.css";
import "react-datepicker/dist/react-datepicker.min.css";
import "react-dual-listbox/lib/react-dual-listbox.css";
import "react-datetime/css/react-datetime.css";
import "react-tabs/style/react-tabs.css";
import "../resources/styles.css";
import "../resources/app.css";
import { unset } from "lodash";

const theme = {
    color: {
        primary: "#2d3436",
        secondary: "#6c5ce7",
        background: "#ffffff",
    },
    fontFamily: {
        regular: "NotoSans-R",
        bold: "NotoSans-B",
        medium: "NotoSans-M",
        light: "NotoSans-L",
    },
    basic: {
        fontSize: 16,
        pcWidth: 1200,
        mobileWidth: 918,
        padWidth: 1024,
        padMaxLandWidth: 1366,
        color: {
            primary: "#454545",
            description: "#7c7c7c",
            buttonSubmit: "#ffffff",
            buttonDefault: "#1e2151",
            buttonConnection: "#393c6f",
            title: "#393c6f",
            date: "#a7a7a7",
            white: "#ffffff",
            name: "#bababa",
            off: "#8d8d8d",
            play: "#39bbff",
            favorite: "#ffdf2e",
        },
        line: {
            input: {
                default: "#dbdbdb",
                active: "#393c6f",
                mobileChat: "#707070",
            },
            default: "#dbdbdb",
            buttonDefault: "#393c6f",
            header: "#eeeeee",
            darkHr: "#9c9c9c",
            inputBottom: "#3c3c3c",
            bar: "#8d8d8d",
            agenda: "#cbcbd9",
            agendaLecture: "#e7e7e7",
            tr: "#e7e7e7",
        },
        bg: {
            default: "#393c6f",
            background: "#ffffff",
            buttonSubmit: "#393c6f",
            buttonDefault: "#ffffff",
            footer: "#eeeeee",
            darkBackground: "#303030",
            purpleBackground: "#8a76ec",
            skyblueBackground: "#6bd4f6",
            buttonSubmit2: "#588cf8",
            chat: "#eeeeee",
            agenda: "#f2f2f5",
            break: "#f1f1f1",
            file1: "#84ccf6",
            file2: "#f189fd",
            file3: "#82e6cd",
            file4: "#fabf7a",
            boardContent: "#fafafa",
            mobileInput: "#696969",
            channelTab: "#c7c7c7",
            channelTabOn: "#393c6f",
        },
        modal: {
            registration: {
                pc: {
                    overlay: { zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.75)" },
                    content: {
                        top: "5rem",
                        left: "auto",
                        right: "auto",
                        bottom: "auto",
                        paddingBottom: "1rem",
                        width: "700px",
                        inset: "3rem auto",
                        transform: "translate(-50%, 0%)",
                        marginLeft: "50%",
                        border: "0",
                        // overflowX: "hidden",
                        overflow: "visible",
                        borderRadius: "10px",
                        maxHeight: "760px",
                    },
                },
                mobile: {
                    overlay: { zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.75)" },
                    content: {
                        top: "3rem",
                        left: "1rem",
                        right: "1rem",
                        bottom: "auto",
                        paddingBottom: "1rem",
                        border: "0",
                        overflow: "visible",
                        borderRadius: "10px",
                        maxHeight: "460px",
                        overflow: "hidden auto",
                    },
                },
            },

            registrationCheck: {
                pc: {
                    overlay: { zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.75)" },
                    content: {
                        top: "10rem",
                        left: "auto",
                        right: "auto",
                        bottom: "autu",
                        paddingBottom: "1rem",
                        width: "700px",
                        transform: "translate(-50%, 0%)",
                        marginLeft: "50%",
                        border: "0",
                        // overflowX: "hidden",
                        overflow: "visible",
                        borderRadius: "10px",
                    },
                },
                mobile: {
                    overlay: { zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.75)" },
                    content: {
                        top: "3rem",
                        left: "1rem",
                        right: "1rem",
                        bottom: "auto",
                        paddingBottom: "1rem",
                        border: "0",
                        overflow: "visible",
                        borderRadius: "10px",
                    },
                },
            },

            contents: {
                pc: {
                    overlay: { zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.75)" },
                    content: {
                        top: "5rem",
                        left: "auto",
                        right: "auto",
                        bottom: "5rem",
                        paddingBottom: "1rem",
                        width: "1200px",
                        transform: "translate(-50%, 0%)",
                        marginLeft: "50%",
                        border: "0",
                        overflow: "visible",
                        borderRadius: "10px",
                    },
                },
                mobile: {
                    overlay: { zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.75)" },
                    content: {
                        top: "3rem",
                        left: "1rem",
                        right: "1rem",
                        bottom: "3rem",
                        paddingBottom: "1rem",
                        border: "0",
                        overflow: "visible",
                        borderRadius: "10px",
                    },
                },
                mobileVideo: {
                    overlay: { zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.75)" },
                    content: {
                        top: "50%",
                        left: "50%",
                        right: "auto",
                        bottom: "auto",
                        marginRight: "-50%",
                        transform: "translate(-50%, -50%)",
                        width: "100%",
                        border: "0",
                        overflow: "visible",
                        padding: "0 1rem",
                        background: "unset",
                    },
                },
            },

            eposter: {
                pc: {
                    overlay: { zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.75)" },
                    content: {
                        top: "3rem",
                        left: "auto",
                        right: "auto",
                        bottom: "2rem",
                        paddingBottom: "1rem",
                        width: "1200px",
                        transform: "translate(-50%, 0%)",
                        marginLeft: "50%",
                        border: "0",
                        overflow: "visible",
                        borderRadius: "10px",
                    },
                },
                mobile: {
                    overlay: { zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.75)" },
                    content: {
                        top: "3rem",
                        left: "1rem",
                        right: "1rem",
                        bottom: "3rem",
                        paddingBottom: "1rem",
                        border: "0",
                        overflow: "visible",
                        borderRadius: "10px",
                    },
                },
            },
        },
    },
    modal: {
        participantMulti: {
            overlay: { zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.75)" },
            content: {
                top: "5rem",
                left: "5rem",
                right: "5rem",
                bottom: "5rem",
                paddingBottom: "0",
                border: "0",
                overflow: "hidden",
            },
        },
        template: {
            overlay: { zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.75)" },
            content: {
                top: "5rem",
                left: "5rem",
                right: "5rem",
                bottom: "5rem",
                paddingBottom: "0",
                border: "0",
                overflow: "auto",
            },
        },
        common: {
            overlay: { zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.75)" },
            content: {
                top: "5rem",
                left: "5rem",
                right: "5rem",
                bottom: "5rem",
                paddingBottom: "0",
                border: "0",
                overflow: "hidden",
            },
        },
        loadingBar: {
            overlay: { zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.75)" },
            content: {
                top: "5rem",
                left: "5rem",
                right: "5rem",
                bottom: "5rem",
                paddingBottom: "0",
                border: "0",
                overflow: "auto",
                border: "none",
                background: "none",
            },
        },
    },
};

const globalStyle = createGlobalStyle`
    * {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        outline: none !important;
    }
    body {
        font-family: "NotoSansCJKkr-R";
        font-size:13px;
    }
    input, textarea {
        -webkit-user-select: text;
        -khtml-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
    }
`;

export const Theme = theme;
export const ThemeProvider = Provider;
export default globalStyle;
