import React, { Fragment, useContext, useEffect, useState } from "react";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U, _CK } from "../../../modules/utils";

import { H2 } from "./UI/Components";

export default (props) => {
    const { cfg, auth, iam } = useContext(AppContext);

    useEffect(() => {

    }, [])

    return (
        <>
            <H2>{props.title || "인사말"}</H2>
            <div
                className=""
                dangerouslySetInnerHTML={{
                    __html: props.content || "",
                }}
            ></div>
        </>
    );
};