import React, { useContext, useState, useEffect, Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import swal from "sweetalert";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import Layout from "../Layout";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";
import { _DEF } from "../../../modules/config";

import { ContentView } from "./event.Content";
import { EboothMainView } from "./event.ebooth.editor";

import Wrapper, { Row, FormGroup, Separator } from "../../../components/UI/Wrapper";
import { SearchInput, SelectForm, InputForm, SegmentedForm, FileForm, DatePicker, DateTimePicker } from "../../../components/UI/Form";
import Button from "../../../components/UI/Button";
import Paging, { ToPage, GetParams } from "../../../components/UI/Paging";
import EventMainVisualForm from "./event.visual";

export default (props) => {
    const [tabIndex, setTabIndex] = useState(0);
    const { history, location, match } = props;
    const [siteList, setSiteList] = useState([]);
    // const { siteIdx, eventIdx, mainIdx } = match.params;
    const { eventIdx } = match.params;
    const [data, setData] = useState({});

    const [siteIdx, setSiteIdx] = useState();

    const childRef = useRef();

    useEffect(() => {
        checkMainIdx();
    }, [])

    const checkMainIdx = () => {
        const data = {
            eventIdx,
        };

        const req = { path: "/v1/a/event/eboothmain/main/check", data };
        _API.get(req).then((res) => {
            const { eventInfo, mainInfo } = res.data;
            setSiteIdx(eventInfo.siteIdx);
            setData(mainInfo);
            console.log(mainInfo);
        }).catch((err) => {
            window.location.href="#/admin/event/ebooth/main"
        });
    }

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-8">
                    <h2>프리미엄 이부스</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/admin">HOME</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to={`${location.pathname}`}>이벤트</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>프리미엄 이부스</strong>
                        </li>
                    </ol>
                </div>
                <div className="col-sm-4">
                    <div className="title-action">
                    </div>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className="col-md-12">
                        <EboothMainView {...props} idx={data?.idx} siteIdx={siteIdx} eventIdx={eventIdx} />
                    </div>
                </div>
            </div>
        </Layout>
    );
};
