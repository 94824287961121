import React, { useContext, useState, useEffect, Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import swal from "sweetalert";

import Layout from "../Layout";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";
import { _DEF } from "../../../modules/config";

import Wrapper, { Row, FormGroup, Separator } from "../../../components/UI/Wrapper";
import { SearchInput, SelectForm, InputForm, SegmentedForm, FileForm, DatePicker } from "../../../components/UI/Form";
import SNEditor from "../../../components/UI/SNEditor";
import Button from "../../../components/UI/Button";
import Paging, { ToPage, GetParams } from "../../../components/UI/Paging";

import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";

export default (props) => {

    const { history, location, match } = props;

    const { cfg } = useContext(AppContext);

    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [eventIdx, setEventIdx] = useState("");
    const [channelIdx, setChannelIdx] = useState("");
    const [sessionIdx, setSessionIdx] = useState("");
    const [lectureIdx, setLectureIdx] = useState("");
    const [surveyCategory, setSurveyCategory] = useState("");
    const [keyword, setKeyword] = useState("");

    const [list, setList] = useState([]);
    const [paging, setPaging] = useState(null);
    const [eventList, setEventList] = useState([]);
    const [channelList, setChannelList] = useState([]);
    const [sessionList, setSessionList] = useState([]);
    const [lectureList, setLectureList] = useState([]);
    const [surveyIdx, setSurveyIdx] = useState(null);
    const [mode, setMode] = useState("");
    const [aws, setAws] = useState();

    const [exportInfo, setExportInfo] = useState();
    let excel = useRef(null);

    const loadList = (page) => {
        // if(!eventIdx){
        //     swal({ title: "알림", text: "이벤트를 선택해 주세요." });
        //     return;
        // }
        const data = {
            siteIdx,
            eventIdx,
            channelIdx,
            sessionIdx,
            lectureIdx,
            surveyCategory,
            keyword,
            page,
        };
        const req = { path: "/v1/a/event/feedback/survey/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setList(list);
            setPaging(paging);
        });
    };

    const loadEventList = (siteIdx) => {
        const data = {
            siteIdx,
        };
        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setEventList(list);
        });
    };

    const loadChannelList = (eventIdx) => {
        const data = {
            eventIdx,
            sort: "TERM_DATE"
        };
        const req = { path: "/v1/a/event/channel/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setChannelList(list);
        });
    };

    const loadSessionList = (channelIdx) => {
        const data = {
            channelIdx,
            sort: "TERM_DATE"
        };
        const req = { path: "/v1/a/event/session/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setSessionList(list);
        });
    };

    const loadLectureList = (sessionIdx) => {
        const data = {
            sessionIdx,
            sort: "TERM_DATE"
        };
        const req = { path: "/v1/a/event/lecture/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setLectureList(list);
        });
    };

    const movePage = (page) => {
        loadList(page);
    };

    const onSearch = (e) => {
        if (e) e.preventDefault();
        loadList(1);
    };

    useEffect(() => {
        loadList(1);
        if (siteIdx) loadEventList(siteIdx);
        else setEventList([]);
        setChannelList([]); setSessionList([]); setLectureList([]);
        setEventIdx(""); setChannelIdx(""); setSessionIdx(""); setLectureIdx("");
    }, []);

    useEffect(() => {
        if (eventIdx) loadChannelList(eventIdx);
        else setChannelList([]);

        setSessionList([]); setLectureList([]);
        setChannelIdx(""); setSessionIdx(""); setLectureIdx("");
    }, [eventIdx]);

    useEffect(() => {
        if (channelIdx) loadSessionList(channelIdx);
        else setSessionList([]);

        setLectureList([]);
        setSessionIdx(""); setLectureIdx("");
    }, [channelIdx]);

    // useEffect(() => {
    //     if (sessionIdx) loadLectureList(sessionIdx);
    //     else setLectureList([]);

    //     setLectureIdx("");
    // }, [sessionIdx]);

    useEffect(() => {
        loadList(1);
    }, [eventIdx, channelIdx, sessionIdx, lectureIdx, surveyCategory])

    const onForm = (idx, e) => {
        if (e) e.preventDefault();

        setSurveyIdx(idx);
        setMode("form");

    }

    const onChangeState = (item, col, val, e) => {
        if (e) e.preventDefault();

        swal({
            title: `설문`,
            text: `상태를 변경하시겠습니까?`,
            icon: "info",
            buttons: ["취소", "예"],
            dangerMode: val === "D" ? true : false,
        }).then((ok) => {
            if (ok) {
                _API.post({
                    path: "/v1/a/event/category/status",
                    data: {
                        idx: item.idx
                        , category: "survey"
                        , status: val
                    },
                }).then((res) => {
                    loadList(paging?.pageNo ? paging?.pageNo : 1)
                });
            }
        });
    }

    const onExcelDown = async () => {
        if (!siteIdx || !eventIdx || !channelIdx) {
            swal({ title: "알림", text: "채널을 선택해 주세요." });
            return;
        }

        const data = {
            siteIdx,
            eventIdx,
            channelIdx,
            sessionIdx,
            surveyCategory,
        };

        const result = await _API.get({ path: "/v1/a/event/feedback/survey/answer/list", data });
        const info = result?.data;
        if (!info || !info.surveyList || !info.answerList || info.surveyList.length == 0 || info.answerList.length == 0) {
            swal({ title: "알림", text: "설문내역이 없습니다." });
            return;
        }

        info.surveyCategory = surveyCategory;
        info.surveyCategoryName = _DEF.FEEDBACK_CATEGORY.filter((c) => c.value == surveyCategory)[0]?.label;
        info.eventName = eventList?.filter((c) => c.eventIdx == eventIdx)[0]?.title;
        info.channelName = channelList?.filter((c) => c.idx == channelIdx)[0]?.title;
        info.sessionName = sessionList?.filter((c) => c.idx == sessionIdx)[0]?.title;
        setExportInfo(info);
        excel && excel.current.save();
    };

    const changeMode = (mode) => {
        setMode(mode);
        if (!mode) loadList(paging?.pageNo ? paging?.pageNo : 1);
    }

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-8">
                    <h2>설문</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/admin">HOME</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <strong>이벤트</strong>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>설문</strong>
                        </li>
                    </ol>
                </div>
                <div className="col-sm-4">
                    <div className="title-action">
                        <button type="button" className="btn btn-primary" onClick={onForm.bind(this, "")}>
                            <i className="fa fa-plus"></i> 추가
                        </button>
                        <button
                            type="button"
                            className="btn btn-warning ml-1"
                            onClick={(e) => {
                                onExcelDown();
                            }}
                        >
                            <i className="fa fa-file-excel-o"></i> 답변내역 엑셀다운
                        </button>
                    </div>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className={cx({ "col-md-12": !mode, "col-md-6": mode })}>
                        <div className="ibox">
                            <div className="ibox-title">설문 목록</div>
                            <div className="ibox-content ibox-heading">
                                <div className="row">
                                    <div className="col-md-2">
                                        <select value={eventIdx} onChange={(e) => setEventIdx(e.target.value)} className="form-control">
                                            <option value="">이벤트선택</option>
                                            {eventList?.map((item, i) => (
                                                <option key={i} value={item.eventIdx}>
                                                    {item.title}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-2">
                                        <select value={channelIdx} onChange={(e) => setChannelIdx(e.target.value)} className="form-control">
                                            <option value="">채널선택</option>
                                            {channelList?.map((item, i) => (
                                                <option key={i} value={item.idx}>
                                                    {item.title + " (" + moment(item.progressDate).format("YYYY-MM-DD") + ")"}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-2">
                                        <select value={sessionIdx} onChange={(e) => setSessionIdx(e.target.value)} className="form-control">
                                            <option value="">세션선택</option>
                                            {sessionList?.map((item, i) => (
                                                <option key={i} value={item.idx}>
                                                    {item.title + " (" + item.startTime + " ~ " + item.endTime + ")"}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-2">
                                        {/* <select value={lectureIdx} onChange={(e) => setLectureIdx(e.target.value)} className="form-control">
                                            <option value="">강좌선택</option>
                                            {lectureList?.map((item, i) => (
                                                <option key={i} value={item.idx}>
                                                    {item.title + " (" + item.startTime + " ~ " + item.endTime + ")"}
                                                </option>
                                            ))}
                                        </select> */}
                                        <select value={surveyCategory} onChange={(e) => setSurveyCategory(e.target.value)} className="form-control">
                                            <option value="">설문구분</option>
                                            {_DEF.FEEDBACK_CATEGORY?.map((item, i) => (
                                                <option key={i} value={item.value}>
                                                    {item.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-2">
                                        <SearchInput value={keyword} onChange={(e) => setKeyword(e.target.value)} onClick={onSearch} placeholder="검색어를 입력해 주세요." />
                                    </div>
                                </div>
                            </div>
                            <div className="ibox-content">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th className="text-center">설문ID</th>
                                                <th className="text-center">이벤트명</th>
                                                <th className="text-center">채널명</th>
                                                <th className="text-center">세션명</th>
                                                <th className="text-center">강좌명</th>
                                                <th className="text-center">설문구분</th>
                                                <th className="text-center">질문구분</th>
                                                <th className="text-center">설문문항</th>
                                                <th className="text-center">정렬</th>
                                                <th className="text-center">상태</th>
                                                <th className="text-center">등록일</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.map((item, i) => (
                                                <tr key={i}>
                                                    <td className="text-center">{item.idx}</td>
                                                    <td className="text-center">{item.eventName}</td>
                                                    <td className="text-center">{item.channelName}</td>
                                                    <td className="text-center">{item.sessionName}</td>
                                                    <td className="text-center">{item.lectureName}</td>
                                                    <td className="text-center">{_U.getDefinedLabel(_DEF.FEEDBACK_CATEGORY, item.surveyCategory, "")}</td>
                                                    <td className="text-center">{_U.getDefinedLabel(_DEF.FEEDBACK_TYPE, item.questionType, "")}</td>
                                                    <td className="text-center">
                                                        <a href="" onClick={onForm.bind(this, item.idx)}>{_U.shortText(item.question, 5, "...")}</a>
                                                    </td>
                                                    <td className="text-center">{item.sort}</td>
                                                    <td className="text-center">
                                                        <div className="btn-group">
                                                            {/* <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "W", "btn-success": item.status === "W" })} onClick={onChangeState.bind(this, item, "status", "W")}>
                                                                대기
                                                            </button> */}
                                                            <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "Y", "btn-success": item.status === "Y" })} onClick={onChangeState.bind(this, item, "status", "Y")}>
                                                                정상
                                                            </button>
                                                            <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "N", "btn-success": item.status === "N" })} onClick={onChangeState.bind(this, item, "status", "N")}>
                                                                미사용
                                                            </button>
                                                            <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "D", "btn-success": item.status === "D" })} onClick={onChangeState.bind(this, item, "status", "D")}>
                                                                삭제
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">{moment(item.regDate).format("YYYY-MM-DD")}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {paging && (
                                <div className="ibox-footer">
                                    <Paging paging={paging} pageCallback={movePage} />
                                </div>
                            )}
                        </div>
                    </div>
                    {mode && (
                        <div className={cx({ "col-md-6": true })}>
                            <EventSurveyForm {...props} surveyIdx={surveyIdx} changeMode={changeMode} />
                        </div>
                    )}
                </div>
            </div>
            <ExcelDownloader data={exportInfo} ref={excel} />
        </Layout>
    );
};

const EventSurveyForm = (props) => {
    const { history, location, match } = props;
    const { surveyIdx, aws } = props;
    const { cfg } = useContext(AppContext);

    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [data, setData] = useState({});
    const [eventList, setEventList] = useState([]);
    const [channelList, setChannelList] = useState([]);
    const [sessionList, setSessionList] = useState([]);
    const [lectureList, setLectureList] = useState([]);
    const [html, setHtml] = useState(null);
    const sorts = _DEF.getRange(1, 100, {});


    const loadEventList = (siteIdx) => {
        const data = {
            siteIdx,
        };
        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            const newEventList = [];
            for (let i in list) {
                newEventList.push({ label: list[i].title, value: list[i].idx });
            }
            setEventList(newEventList);
        });
    };

    const loadChannelList = (eventIdx) => {
        const data = {
            eventIdx,
            sort: "TERM_DATE"
        };
        const req = { path: "/v1/a/event/channel/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            const newList = [];
            for (let i in list) {
                newList.push({ label: list[i].title + " (" + moment(list[i].progressDate).format("YYYY-MM-DD") + ")", value: list[i].idx });
            }
            setChannelList(newList);
        });
    };

    const loadSessionList = (channelIdx) => {
        const data = {
            channelIdx,
            sort: "TERM_DATE"
        };
        const req = { path: "/v1/a/event/session/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            const newList = [];
            for (let i in list) {
                newList.push({ label: list[i].title + " (" + list[i].startTime + " ~ " + list[i].endTime + ")", value: list[i].idx });
            }
            setSessionList(newList);
        });
    };

    const loadLectureList = (sessionIdx) => {
        const data = {
            sessionIdx,
            sort: "TERM_DATE"
        };
        const req = { path: "/v1/a/event/lecture/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            const newList = [];
            for (let i in list) {
                newList.push({ label: list[i].title + " (" + list[i].startTime + " ~ " + list[i].endTime + ")", value: list[i].idx });
            }
            setLectureList(newList);
        });
    };

    const loadData = () => {
        if (!surveyIdx) {
            return;
        }
        const data = {};
        const req = { path: `/v1/a/event/feedback/survey/${surveyIdx}`, data };
        _API.get(req)
            .then((res) => res.data)
            .then((data) => {
                if (data.siteIdx) loadEventList(data.siteIdx);
                if (data.eventIdx) loadChannelList(data.eventIdx);
                if (data.channelIdx) loadSessionList(data.channelIdx);
                if (data.sessionIdx) loadLectureList(data.sessionIdx);
                setHtml(data?.headDescription);
                setData(data);
            });
    };

    const handleDataChange = (e) => {
        let { name, value } = e.target;

        if (name === "siteIdx") {
            if (value) loadEventList(value);
            else setEventList([]);
            setChannelList([]);
            setSessionList([]);
            setLectureList([]);
            setData({ ...data, [name]: value, eventIdx: null, channelIdx: null, sessionIdx: null, lectureIdx: null });
        } else if (name === "eventIdx") {
            if (value) loadChannelList(value);
            else setChannelList([]);
            setSessionList([]);
            setLectureList([]);
            setData({ ...data, [name]: value, channelIdx: null, sessionIdx: null, lectureIdx: null });
        } else if (name === "channelIdx") {
            if (value) loadSessionList(value);
            else setSessionList([]);
            setLectureList([]);
            setData({ ...data, [name]: value, sessionIdx: null, lectureIdx: null });
        } else if (name === "sessionIdx") {
            if (value) loadLectureList(value);
            else setLectureList([]);
            setData({ ...data, [name]: value, lectureIdx: null });
        } else {
            setData({ ...data, [name]: value });
        }
    };

    const setDataChange = (name, value) => {
        setData({ ...data, [name]: value });
    };

    const handleSaveClick = async () => {
        if (!data.siteIdx) {
            swal({ title: "알림", text: "사이트를 선택해 주세요." });
            return;
        }
        if (!data.eventIdx) {
            swal({ title: "알림", text: "이벤트를 선택해 주세요." });
            return;
        }
        if (!data.channelIdx) {
            swal({ title: "알림", text: "채널을 선택해 주세요." });
            return;
        }
        if (!data.surveyCategory) {
            swal({ title: "알림", text: "설문구분을 선택해 주세요." });
            return;
        }
        if (!data.questionType) {
            swal({ title: "알림", text: "질문구분을 선택해 주세요." });
            return;
        }
        if (!data.question) {
            swal({ title: "알림", text: "설문을 입력해 주세요." });
            return;
        }
        if (data.questionType === "SINGLE" || data.questionType === "MULTIPLE") {
            if (!data.item1 && !data.item2 && !data.item3 && !data.item4 && !data.item5) {
                swal({ title: "알림", text: "객관 입력해 주세요." });
                return;
            }
        }

        _API.post({
            path: "/v1/a/event/feedback/survey",
            data: {
                surveyIdx: data.surveyIdx,
                channelIdx: data.channelIdx,
                sessionIdx: data.sessionIdx || null,
                lectureIdx: data.lectureIdx || null,
                surveyCategory: data.surveyCategory,
                questionType: data.questionType,
                question: data.question,
                item1: data.item1 || null,
                item2: data.item2 || null,
                item3: data.item3 || null,
                item4: data.item4 || null,
                item5: data.item5 || null,
                sort: data.sort || 1,
                status: data.status || "Y",
                headDescription: html || null,
                thumbnailFileIdx: data.thumbnailFileIdx || null,
            },
        }).then((res) => {
            swal({ title: "알림", text: `설문이 ${!data.surveyIdx ? "생성" : "수정"}되었습니다.` }).then(() => {
                // _U.postMessage("EVENT-CHANGED");
                onCancel();

            });
        });
    };

    const onCancel = () => {
        props.changeMode && props.changeMode("");
    }


    useEffect(() => {
        loadEventList(siteIdx);
        loadData();
        if (!surveyIdx) setData({ siteIdx });
    }, [surveyIdx]);

    const fileUpload = (name, item) => {
        const urlName = name.replace("Idx", "SignedUrl");
        const fileName = name.replace("Idx", "Name");
        setData({ ...data, [name]: item.id, [urlName]: item.signedUrl, [fileName]: item.name });
    }
    return (
        <div className="row">
            <div className="col-md-12">
                <div className="ibox">
                    <div className="ibox-title">설문 {!data?.eventIdx ? "등록" : "수정"}</div>
                    <div className="ibox-content">
                        <SelectForm title="이벤트명" name="eventIdx" value={data?.eventIdx} items={eventList} onChange={handleDataChange} nullTitle="이벤트 선택" />
                        <SelectForm title="채널명" name="channelIdx" value={data?.channelIdx} items={channelList} onChange={handleDataChange} nullTitle="채널 선택" />
                        <SelectForm title="세션명" name="sessionIdx" value={data?.sessionIdx} items={sessionList} onChange={handleDataChange} nullTitle="세션 선택" />
                        <SelectForm title="강좌명" name="lectureIdx" value={data?.lectureIdx} items={lectureList} onChange={handleDataChange} nullTitle="강좌 선택" />
                        <Separator />
                        <SelectForm title="설문구분" name="surveyCategory" value={data?.surveyCategory} items={_DEF.FEEDBACK_CATEGORY} onChange={handleDataChange} nullTitle="설문구분" />
                        <SelectForm title="질문구분" name="questionType" value={data?.questionType} items={_DEF.FEEDBACK_TYPE} onChange={handleDataChange} nullTitle="질문구분" />
                        <Separator />
                        <FileForm title="상단이미지" onChange={(v) => fileUpload("thumbnailFileIdx", v)} path={`EVENT/SURVEY/${moment().format("YYYY/MM/DD")}`} url={data?.thumbnailFileSignedUrl} fileName={data?.thumbnailFileName} description={"*권장해상도 800px * 200px(4:1)"} 
                                  onFileDelete={() => setData({ ...data, thumbnailFileIdx: null, thumbnailFileSignedUrl: null, thumbnailFileName: null })} />
                        <div className="form-group row">
                            <label className="col-sm-3 control-label m-t-sm">안내문</label>
                            <div className={cx({ "col-sm-9": true, "col-sm-12": false })}>
                                <SNEditor content={html} onChange={(v) => setHtml(v)} />
                            </div>
                        </div>
                        <InputForm title="설문문항" name="question" placeholder="설문문항" value={data?.question} onChange={handleDataChange} />
                        {(data?.questionType === "SINGLE" || data?.questionType === "MULTIPLE") && (
                            <Fragment>
                                <InputForm title="보기1" name="item1" placeholder="보기1" value={data?.item1} onChange={handleDataChange} />
                                <InputForm title="보기2" name="item2" placeholder="보기2" value={data?.item2} onChange={handleDataChange} />
                                <InputForm title="보기3" name="item3" placeholder="보기3" value={data?.item3} onChange={handleDataChange} />
                                <InputForm title="보기4" name="item4" placeholder="보기4" value={data?.item4} onChange={handleDataChange} />
                                <InputForm title="보기5" name="item5" placeholder="보기5" value={data?.item5} onChange={handleDataChange} />
                            </Fragment>
                        )}
                        <SelectForm title="정렬" name="sort" value={data?.sort} items={sorts} onChange={handleDataChange} nullTitle="정렬" />
                        {/* <SegmentedForm title="사용여부" items={_DEF.STATUS} value={data?.status} onClick={(v) => setDataChange("status", v)} /> */}
                        <Separator />
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <div className="btn-group">
                                    <Button onClick={handleSaveClick} className="btn btn-primary">
                                        {!data.surveyIdx ? "생성" : "수정"}
                                    </Button>
                                    <Button onClick={onCancel}>취소</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

class ExcelDownloader extends React.Component {
    constructor(props) {
        super(props);

        const state = {
            surveyList: props?.data?.surveyList || [],
            answerList: props?.data?.answerList || [],
            surveyCategory: props?.data?.surveyCategory || "",
            surveyCategoryName: props?.data?.surveyCategoryName || "",
            eventName: props?.data?.eventName || "",
            channelName: props?.data?.channelName || "",
            sessionName: props?.data?.sessionName || ""
        };
        this.state = state;
    }

    componentWillReceiveProps = (props) => {
        this.setState({
            surveyList: props?.data?.surveyList || [],
            answerList: props?.data?.answerList || [],
            surveyCategory: props?.data?.surveyCategory || "",
            surveyCategoryName: props?.data?.surveyCategoryName || "",
            eventName: props?.data?.eventName || "",
            channelName: props?.data?.channelName || "",
            sessionName: props?.data?.sessionName || "",
        });
    };

    save() {
        this.excel && this.excel.save();
    }

    getList() {
        const { answerList, surveyList } = this.state;
        for (let i in answerList) {
            const item = answerList[i];
            if (!item.participantName) {
                item.participantName = item.participantEmail;
            }
            const surveyInfoList = item.surveyInfo?.split(",");
            for (let j in surveyInfoList) {
                const surveyInfo = surveyInfoList[j]?.split(":");
                if (surveyInfo && surveyInfo.length == 2) item[surveyInfo[0]] = surveyInfo[1];
            }

        }
        return answerList;
    }

    getExcelTitle() {
        const title = [];
        this.state.eventName && title.push(this.state.eventName + "_");
        this.state.channelName && title.push(this.state.channelName + "_");
        this.state.sessionName && title.push(this.state.sessionName + "_");
        this.state.surveyCategoryName && title.push(this.state.surveyCategoryName + "_");
        if (title.length == 0) title.push("이벤트 설문 결과_");
        title.push(moment().format("YYYYMMDDHHmmss"));
        return title.join("");
    }

    getOptionalColumn() {
        const { surveyList } = this.state;
        const list = [];
        for (let i in surveyList) {
            const item = surveyList[i];
            list.push(<ExcelExportColumn field={`SURVEY_${item.idx}`} title={`${item.question}`} width={250} />);
        }

        return list;
    }

    render() {
        const excelName = this.getExcelTitle();
        const list = this.getList();
        const today = moment().format("YYYYMMDDHHmmss");
        if (!list) {
            return "";
        }

        return (
            <ExcelExport data={list} fileName={`${excelName}.xlsx`} ref={(ref) => (this.excel = ref)}>

                <ExcelExportColumn field="participantName" title="참가자명" width={250} />
                {this.getOptionalColumn().map((item, i) => (
                    <Fragment key={i}>
                        {item}
                    </Fragment>
                ))}
            </ExcelExport>
        );
    }
}
