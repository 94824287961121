import React, { useContext, useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import swal from "sweetalert";
import Modal from "react-modal";

import { _API, _U } from "../../../modules/utils";
import { _DEF } from "../../../modules/config";

import Layout from "../Layout";

import { AppContext } from "../../../components/AppProvider";
import Common from "../../../components/UI/Common";
import { ModalCloseButton } from "../../../components/UI/Button";
import { Theme } from "../../../components/GlobalStyles";
import Input, { SearchInput, SelectForm, InputForm, SegmentedForm, FileForm, AddressForm } from "../../../components/UI/Form";
import Paging from "../../../components/UI/Paging";
import { Separator } from "../../../components/UI/Wrapper";

export default (props) => {
    const { cfg } = useContext(AppContext);
    const { history, location, match } = props;

    const [mode, setMode] = useState("");
    const [siteList, setSiteList] = useState([]);
    const [eventList, setEventList] = useState([]);
    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [eventIdx, setEventIdx] = useState("");

    const [keyword, setKeyword] = useState("");
    const [list, setList] = useState([]);
    const [paging, setPaging] = useState(null);

    const [data, setData] = useState(null);

    const loadList = (page) => {
        const data = {
            siteIdx,
            eventIdx,
            keyword,
            page,
        };
        const req = { path: "/v1/a/event/eboothmain/main/event/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setList(list);
            setPaging(paging);
        });
    };

    const loadEventList = (siteIdx) => {
        if (!siteIdx) {
            setEventList([]);
            setEventIdx("");
            return;
        }

        const data = {
            siteIdx,
        };

        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list } = res.data;
            setEventList(list);
        });
    };

    useEffect(() => {
        loadList(1);
        loadEventList(cfg.idx);
    }, []);

    useEffect(() => {
        loadList(1);
    }, [siteIdx, eventIdx]);

    const movePage = (page) => {
        loadList(page);
    };

    const onSearch = (e) => {
        loadList(1);
    };

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-8">
                    <h2>프리미엄 이부스</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/admin">HOME</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to={`${location.pathname}`}>이벤트</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>프리미엄 이부스</strong>
                        </li>
                    </ol>
                </div>
                {/* <div className="col-sm-4">
                    <div className="title-action">
                        <button
                            type="button"
                            className="btn btn-primary ml-2"
                            onClick={(e) => {
                                setMode("ADD");
                                setData(null);
                            }}
                        >
                            <i className="fa fa-plus"></i> 추가
                        </button>
                    </div>
                </div> */}
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className={cx({ "col-md-12": !mode, "col-md-8": mode === "ADD" })}>
                        <div className="ibox">
                            <div className="ibox-title">프리미엄 이부스 이벤트 목록</div>
                            <div className="ibox-content ibox-heading">
                                <div className="row">
                                    {/* <div className="col-md-3">
                                        <SelectForm
                                            name="siteIdx"
                                            items={siteList.map((site) => {
                                                return { label: site.title, value: site.idx };
                                            })}
                                            value={siteIdx}
                                            onChange={(e) => {
                                                setSiteIdx(e.target.value);
                                                loadEventList(e.target.value);
                                            }}
                                            nullTitle="사이트 선택"
                                        />
                                    </div> */}
                                    <div className="col-md-3">
                                        <SelectForm
                                            name="eventIdx"
                                            items={eventList.map((event) => {
                                                return { label: event.eventName, value: event.idx };
                                            })}
                                            value={eventIdx}
                                            onChange={(e) => {
                                                setEventIdx(e.target.value);
                                            }}
                                            nullTitle={eventList?.length > 0 ? `이벤트 선택` : `사이트를 먼저 선택하세요.`}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <SearchInput value={keyword} onChange={(e) => setKeyword(e.target.value)} onClick={onSearch} placeholder="검색어를 입력해 주세요." />
                                    </div>
                                </div>
                            </div>
                            
                            <div className="ibox-content">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th className="text-center">사이트명</th>
                                                <th className="text-center">이벤트명</th>
                                                <th className="text-center">기간</th>
                                                <th className="text-center">입점업체(수)</th>
                                                <th className="text-center">사용유무</th>
                                                <th className="text-center">상태</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list?.map((item, i) => (
                                                <ListItem
                                                    {...item}
                                                    key={i}
                                                    onModify={(data) => {
                                                        setData(data);
                                                        setMode("ADD");
                                                    }}
                                                    refresh={() => {
                                                        loadList(paging?.pageNo || 1);
                                                    }}
                                                />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {/* {paging && (
                                <div className="ibox-footer">
                                    <Paging paging={paging} pageCallback={movePage} />
                                </div>
                            )} */}
                        </div>
                    </div>
                    {mode === "ADD" && (
                        <div className="col-md-4">
                            <Form
                                siteList={siteList}
                                data={data}
                                onClose={() => {
                                    setMode("");
                                }}
                                callback={() => {
                                    loadList(1);
                                    setMode("");
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
};

const ListItem = (props) => {
    
    const onChangeStatus = (status) => {
        swal({
            title: ``,
            text: `사용유무를 변경하시겠습니까?`,
            icon: "info",
            buttons: ["취소", "예"],
            dangerMode: status === "D" ? true : false,
        }).then((ok) => {
            if (ok) {
                const req = { path: "/v1/a/event/eboothmain/status", data: { idx: props.eboothMainIdx, status } };
                _API.post(req).then((res) => {
                    props.refresh && props.refresh();
                });
            }
        });
    };

    const onRemove = () => {
        swal({
            title: "정말 삭제 하시겠습니까?",
            text: "삭제된 데이터는 복구가 불가합니다 .",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                _API.post({ path: `/v1/a/event/eboothmain/status`, data: { idx: props.eboothMainIdx, status: "D" } }).then((res) => {
                    swal({ title: "알림", text: `해당 이벤트의 프리미엄 이부스가 삭제되었습니다.` }).then(() => {
                        props.refresh && props.refresh();
                    });
                });
            }
        });
    };

    const weights = [...Array(100).keys()];

    const openEboothMainForm = (eboothMainIdx) => {
        window.location.href = `#/admin/event/ebooth/main/${props.idx}`;
    }

    return (
        <tr>
            <td className="text-center">{props.siteName}</td>
            <td className="text-center">{props.eventName}</td>
            <td className="text-center">{props.startDate && moment(props.startDate).format("YYYY-MM-DD")} ~ { props.endDate && moment(props.endDate).format("YYYY-MM-DD")}</td>
            <td className="text-center">{props.companyCnt}</td>
            {/* <td className="text-center">
                <div className="btn-group">
                    <button className={cx({ btn: true, "btn-xs": true, "btn-default": props.status !== "Y", "btn-success": props.status === "Y" })} onClick={onChangeStatus.bind(this, "Y")}>
                        사용
                    </button>
                    <button className={cx({ btn: true, "btn-xs": true, "btn-default": props.status !== "N", "btn-success": props.status === "N" })} onClick={onChangeStatus.bind(this, "N")}>
                        사용안함
                    </button>
                </div>
            </td> */}
            <td className="text-center">
                {props.eboothMainIdx ?
                    <div className="btn-group">
                        <button className={cx({ btn: true, "btn-xs": true, "btn-default": props.status !== "Y", "btn-success": props.status === "Y" })} onClick={onChangeStatus.bind(this, "Y")}>
                            사용
                        </button>
                        <button className={cx({ btn: true, "btn-xs": true, "btn-default": props.status !== "N", "btn-success": props.status === "N" })} onClick={onChangeStatus.bind(this, "N")}>
                            사용안함
                        </button>
                    </div> : "미등록"
                }
            </td>
            <td className="text-center">
                <div className="btn-group">
                    {props.eboothMainIdx ?
                        <Fragment>
                            <button className={cx({ btn: true, "btn-xs": true, "btn-warning": true })} onClick={(e) => openEboothMainForm(props.eboothMainIdx)}>
                                <i className="fa fa-edit"></i>
                            </button> 
                            {/* <button className={cx({ btn: true, "btn-xs": true, "btn-danger": true })} onClick={onRemove}>
                                <i className="fa fa-trash"></i>
                            </button> */}
                        </Fragment> :
                        <button type="button" className="btn btn-primary ml-2" onClick={(e) => openEboothMainForm()}>
                            <i className="fa fa-plus"></i> 등록
                        </button>
                    }
                </div>
            </td>
        </tr>
    );
};