import React, { useContext, useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import swal from "sweetalert";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import Layout from "../Layout";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";
import { _DEF } from "../../../modules/config";

import Wrapper, { Row, FormGroup, Separator } from "../../../components/UI/Wrapper";
import Input, { SearchInput, SelectForm, InputForm, SegmentedForm, FileForm, DatePicker } from "../../../components/UI/Form";
import Button from "../../../components/UI/Button";
import SNEditor from "../../../components/UI/SNEditor";
import Paging, { ToPage, GetParams } from "../../../components/UI/Paging";
import EventMainVisualForm from "./event.visual";

import qs from "query-string";

export default (props) => {

    const { history, location, match } = props;

    const { cfg } = useContext(AppContext);

    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [keyword, setKeyword] = useState("");
    const [list, setList] = useState([]);
    const [paging, setPaging] = useState(null);

    const loadList = (page) => {
        const data = {
            siteIdx,
            keyword,
            page,
        };
        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setList(list);
            setPaging(paging);
        });
    };

    const movePage = (page) => {
        loadList(page);
    };

    const onSearch = (e) => {
        loadList(1);
    };

    useEffect(() => {
        // loadList(1);
        loadList(getBeforePageNo());
    }, []);

    const getBeforePageNo = () => {
        const search = location?.search;
        if (!search) return 1;

        const params = qs.parse(search);
        return params.page || 1;
    }

    const onForm = (e, eventIdx) => {
        if (eventIdx) {
            history.push(`/admin/event/form/${eventIdx}?before_page=${paging?.pageNo || 1}`);
        } else {
            // swal({ title: "알림", text: "사이트를 선택해 주세요." });
            history.push(`/admin/event/form?before_page=${paging?.pageNo || 1}`);
        }
    }

    const onChangeState = (item, col, val, e) => {
        if (e) e.preventDefault();

        let req = "";
        let text = "";
        let isReturn = false;

        switch (col) {
            case "status":
                text = "상태";
                req = `/v1/a/event/category/status`;
                break;
            case "mainYn":
                text = "운영상태";
                req = `/v1/a/event/status`;
                isReturn = list.filter((row) => { return (item.siteIdx === row.siteIdx && item.mainYn === "N" && val === "Y" && item.idx != row.idx && row.mainYn === "Y") ? true : false; })?.length > 0 ? true : false;
                break;
            case "registrationYn":
                text = "사전등록상태";
                req = `/v1/a/event/status`;
                break;
            case "attendanceYn":
                text = "출결상태";
                req = `/v1/a/event/status`;
                break;
            case "eposterYn":
                text = "이포스터상태";
                req = `/v1/a/event/status`;
                break;
            case "eboothYn":
                text = "이부스상태";
                req = `/v1/a/event/status`;
                break;
            case "qnaYn":
                text = "Q&A상태";
                req = `/v1/a/event/status`;
                break;
            case "quizYn":
                text = "퀴즈상태";
                req = `/v1/a/event/status`;
                break;
            default:
                break;
        }

        if (isReturn) {
            swal({
                title: `이벤트`,
                text: `이미 운영중인 이벤트가 있습니다.\n\n선택한 이벤트 [${item.eventName}]를 운영으로 전환하시겠습니까?`,
                icon: "info",
                buttons: ["취소", "예"],
                dangerMode: true,
            }).then((ok) => {
                if (ok) {
                    _API.post({
                        path: req,
                        data: {
                            idx: item.idx
                            , category: col === "status" ? "event" : col
                            , status: val
                        },
                    }).then((res) => {
                        loadList(paging?.pageNo || 1);
                    });
                }
            });
        }

        if (isReturn) return;

        if (!req) {
            swal({
                title: `이벤트`,
                text: `잘못된 접근입니다.`,
                icon: "info",
                buttons: ["취소", "예"],
                dangerMode: true,
            })
            return;
        }

        swal({
            title: `이벤트`,
            text: `${text}를 변경하시겠습니까?`,
            icon: "info",
            buttons: ["취소", "예"],
            dangerMode: val === "D" ? true : false,
        }).then((ok) => {
            if (ok) {
                _API.post({
                    path: req,
                    data: {
                        idx: item.idx
                        , category: col === "status" ? "event" : col
                        , status: val
                    },
                }).then((res) => {
                    loadList(paging?.pageNo || 1);
                });
            }
        });
    }

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-8">
                    <h2>이벤트</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/admin">HOME</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>이벤트</strong>
                        </li>
                    </ol>
                </div>
                <div className="col-sm-4">
                    <div className="title-action">
                        <button type="button" className="btn btn-primary" onClick={onForm}>
                            <i className="fa fa-plus"></i> 추가
                        </button>
                    </div>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className={cx({ "col-md-12": true, "col-md-4": false })}>
                        <div className="ibox">
                            <div className="ibox-title">이벤트목록</div>
                            <div className="ibox-content ibox-heading">
                                <div className="row">
                                    <div className="col-md-4">
                                        <SearchInput value={keyword} onChange={(e) => setKeyword(e.target.value)} onClick={onSearch} placeholder="검색어를 입력해 주세요." />
                                    </div>
                                </div>
                            </div>
                            <div className="ibox-content">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th className="text-center">이벤트ID</th>
                                                <th className="text-center">이벤트명</th>
                                                <th className="text-center">사전등록</th>
                                                <th className="text-center">출결</th>
                                                <th className="text-center">이포스터</th>
                                                <th className="text-center">이부스</th>
                                                <th className="text-center">Q&A</th>
                                                <th className="text-center">퀴즈</th>
                                                <th className="text-center">운영</th>
                                                <th className="text-center">상태</th>
                                                <th className="text-center">등록일</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.map((item, i) => (
                                                <tr key={i}>
                                                    <td className="text-center">{item.eventIdx}</td>
                                                    <td className="text-center">
                                                        <a href={`/#/admin/event/form/${item.eventIdx}?before_page=${paging?.pageNo || 1}`} >{item.eventName}</a>
                                                    </td>
                                                    <td className="text-center">
                                                        <button type="button"
                                                            className={cx({
                                                                btn: true,
                                                                "btn-xs": true,
                                                                "btn-default": item.registrationYn !== "Y",
                                                                "btn-info": item.registrationYn === "Y"
                                                            })}
                                                            onClick={onChangeState.bind(this, item, "registrationYn", item.registrationYn === "Y" ? "N" : "Y")}>
                                                            {item.registrationYn === "Y" ? "사용" : "미사용"}
                                                        </button>
                                                    </td>
                                                    <td className="text-center">
                                                        <button type="button"
                                                            className={cx({
                                                                btn: true,
                                                                "btn-xs": true,
                                                                "btn-default": item.attendanceYn !== "Y",
                                                                "btn-info": item.attendanceYn === "Y"
                                                            })}
                                                            onClick={onChangeState.bind(this, item, "attendanceYn", item.attendanceYn === "Y" ? "N" : "Y")}>
                                                            {item.attendanceYn === "Y" ? "사용" : "미사용"}
                                                        </button>
                                                    </td>
                                                    <td className="text-center">
                                                        <button type="button"
                                                            className={cx({
                                                                btn: true,
                                                                "btn-xs": true,
                                                                "btn-default": item.eposterYn !== "Y",
                                                                "btn-info": item.eposterYn === "Y"
                                                            })}
                                                            onClick={onChangeState.bind(this, item, "eposterYn", item.eposterYn === "Y" ? "N" : "Y")}>
                                                            {item.eposterYn === "Y" ? "사용" : "미사용"}
                                                        </button>
                                                    </td>
                                                    <td className="text-center">
                                                        <button type="button"
                                                            className={cx({
                                                                btn: true,
                                                                "btn-xs": true,
                                                                "btn-default": item.eboothYn !== "Y",
                                                                "btn-info": item.eboothYn === "Y"
                                                            })}
                                                            onClick={onChangeState.bind(this, item, "eboothYn", item.eboothYn === "Y" ? "N" : "Y")}>
                                                            {item.eboothYn === "Y" ? "사용" : "미사용"}
                                                        </button>
                                                    </td>
                                                    <td className="text-center">
                                                        <button type="button"
                                                            className={cx({
                                                                btn: true,
                                                                "btn-xs": true,
                                                                "btn-default": item.qnaYn !== "Y",
                                                                "btn-info": item.qnaYn === "Y"
                                                            })}
                                                            onClick={onChangeState.bind(this, item, "qnaYn", item.qnaYn === "Y" ? "N" : "Y")}>
                                                            {item.qnaYn === "Y" ? "사용" : "미사용"}
                                                        </button>
                                                    </td>
                                                    <td className="text-center">
                                                        <button type="button"
                                                            className={cx({
                                                                btn: true,
                                                                "btn-xs": true,
                                                                "btn-default": item.quizYn !== "Y",
                                                                "btn-info": item.quizYn === "Y"
                                                            })}
                                                            onClick={onChangeState.bind(this, item, "quizYn", item.quizYn === "Y" ? "N" : "Y")}>
                                                            {item.quizYn === "Y" ? "사용" : "미사용"}
                                                        </button>
                                                    </td>
                                                    <td className="text-center">
                                                        <button type="button"
                                                            className={cx({
                                                                btn: true,
                                                                "btn-xs": true,
                                                                "btn-default": item.mainYn !== "Y",
                                                                "btn-success": item.mainYn === "Y"
                                                            })}
                                                            onClick={onChangeState.bind(this, item, "mainYn", item.mainYn === "Y" ? "N" : "Y")}>
                                                            {item.mainYn === "Y" ? "운영" : "미운영"}
                                                        </button>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="btn-group">
                                                            {/* <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "W", "btn-success": item.status === "W" })} onClick={onChangeState.bind(this, item, "status", "W")}>
                                                                대기
                                                            </button> */}
                                                            <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "Y", "btn-success": item.status === "Y" })} onClick={onChangeState.bind(this, item, "status", "Y")}>
                                                                정상
                                                            </button>
                                                            <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "N", "btn-success": item.status === "N" })} onClick={onChangeState.bind(this, item, "status", "N")}>
                                                                미사용
                                                            </button>
                                                            <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "D", "btn-success": item.status === "D" })} onClick={onChangeState.bind(this, item, "status", "D")}>
                                                                삭제
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">{item.regDate && moment(item.regDate).format("YYYY-MM-DD")}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {paging && (
                                <div className="ibox-footer">
                                    <Paging paging={paging} pageCallback={movePage} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};


export const EventForm = (props) => {
    const { history, location, match } = props;
    const { cfg } = useContext(AppContext);

    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const { eventIdx } = match.params;
    const [data, setData] = useState({ siteIdx: siteIdx });

    const loadData = () => {
        if (!eventIdx) {
            return;
        }
        const data = { eventIdx };
        const req = { path: `/v1/a/event/info`, data };
        _API.get(req)
            .then((res) => res.data)
            .then((data) => {
                setData(data);
            });
    };

    const getBeforePageNo = () => {
        const search = location?.search;
        if (!search) return 1;

        const params = qs.parse(search);
        return params.before_page || 1;
    }


    useEffect(() => {
        loadData();
    }, [eventIdx]);

    const onCancel = () => {
        history.push(`/admin/event/list?page=${getBeforePageNo()}`);
    }

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-8">
                    <h2>이벤트</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/admin">HOME</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>이벤트</strong>
                        </li>
                    </ol>
                </div>
                <div className="col-sm-4">
                    <div className="title-action">
                        <button type="button" className="btn btn-info" onClick={onCancel}>
                            <i className="fa fa-arrow-left"></i> 뒤로
                        </button>
                    </div>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className="col-md-12">
                        <Tabs>
                            <TabList>
                                <Tab>이벤트</Tab>
                                {eventIdx && (
                                    <Fragment>
                                        <Tab>메뉴</Tab>
                                        <Tab>비주얼</Tab>
                                        <Tab>인사말</Tab>
                                        <Tab>메뉴설명</Tab>
                                        {/* <Tab>프로그램소개</Tab> */}
                                        {data?.registrationYn === "Y" && (
                                            <Tab>사전등록</Tab>
                                        )}
                                        {data?.linkLoginYn === "Y" && (
                                            <Tab>외부링크 로그인</Tab>
                                        )}
                                    </Fragment>
                                )}
                            </TabList>
                            <TabPanel>
                                <EventBaseForm {...props} eventInfo={data} reSetEventInfo={loadData} getBeforePageNo={getBeforePageNo} />
                            </TabPanel>
                            {eventIdx && (
                                <Fragment>
                                    <TabPanel>
                                        <EventMenuForm {...props} siteIdx={siteIdx} eventIdx={data?.eventIdx} reSetEventInfo={loadData} getBeforePageNo={getBeforePageNo} />
                                    </TabPanel>
                                    <TabPanel>
                                        {/* <EventVisualForm {...props} eventInfo={data} reSetEventInfo={loadData} /> */}
                                        <EventMainVisualForm {...props} reSetEventInfo={loadData} />
                                    </TabPanel>
                                    <TabPanel>
                                        <EventIntroduceForm {...props} eventInfo={data} reSetEventInfo={loadData} />
                                    </TabPanel>
                                    <TabPanel>
                                        <EventMenuDesc {...props} eventInfo={data} reSetEventInfo={loadData} />
                                    </TabPanel>
                                    {/* <TabPanel>
                                        <EventProgramForm {...props} eventInfo={data} reSetEventInfo={loadData} />
                                    </TabPanel> */}
                                    {data?.registrationYn === "Y" && (
                                        <TabPanel>
                                            <EventRegistrationForm {...props} eventInfo={data} reSetEventInfo={loadData} />
                                        </TabPanel>
                                    )}
                                    {data?.linkLoginYn === "Y" && (
                                        <TabPanel>
                                            <EventLinkLoginForm {...props} eventInfo={data} reSetEventInfo={loadData} />
                                        </TabPanel>
                                    )}
                                </Fragment>
                            )}
                        </Tabs>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export const EventBaseForm = (props) => {
    const { history, location, match } = props;
    const { iam } = useContext(AppContext);
    const { eventInfo } = props;
    const [data, setData] = useState({});
    const { cfg } = useContext(AppContext);
    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [locationList, setLocationList] = useState([]);
    const [siteInfo, setSiteInfo] = useState({
        loginYn: cfg.loginYn,
        registrationYn: cfg.registrationYn,
        attendanceYn: cfg.attendanceYn,
        eposterYn: cfg.eposterYn,
        eboothYn: cfg.eboothYn,
        qnaYn: cfg.qnaYn,
        quizYn: cfg.quizYn,
        emailYn: cfg.emailYn,
        smsYn: cfg.smsYn,
        eposterOptYn: cfg.eposterOptYn || "N",
        eboothGradeYn: cfg.eboothGradeYn || "N",
        pdfEbookYn: cfg.pdfEbookYn || "N",
    });
    const [vodContentList, setVodContentList] = useState([]);

    const initDataYn = {
        attendanceYn: "N",
        eboothYn: "N",
        eposterYn: "N",
        loginType: "PHONE",
        loginYn: "N",
        passwdYn: "N",
        dupLoginKickYn: "N",
        mainYn: "N",
        qnaYn: "N",
        quizYn: "N",
        registrationYn: "N",
        emailYn: "N",
        smsYn: "N",
        status: "Y",
        loginDescription: "",
        eposterOptYn: "N",
        eboothGradeYn: "N",
        pdfEbookYn: "N",
        footerFlowYn: "Y",
        chatYn: "Y",
        speakerYn: "Y",
        headerShowYn: "Y",
        main3DPcYn: "N",
        main3DMbYn: "N",
        main3DPath: "",
        replayRecommendYn: "N",
        lectureReplayAttendYn: "N",
        linkLoginYn: "N",
        introVodYn : "N",
        introVodContentIdx : null,
    }

    const handleDataChange = (e) => {
        let { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const setDataChange = (name, value) => {
        if (name === "eposterYn" && value === "N") {
            setData({ ...data, [name]: value, eposterOptYn: "N" });
        }
        else if (name === "eboothYn" && value === "N") {
            setData({ ...data, [name]: value, eboothGradeYn: "N" });
        }
        else if (name === "main3DPcYn" && value === "N") {
            setData({ ...data, [name]: value, main3DMbYn: "N" });
        }
        else {
            setData({ ...data, [name]: value });
        }
    };

    const handleSaveClick = () => {
        const params = { ...data };
        if (!params.title) {
            swal({ title: "알림", text: "이벤트명을 입력해 주세요." });
            return;
        }
        // if (!params.locIdx) {
        //     swal({ title: "알림", text: "장소를 입력해 주세요." });
        //     return;
        // }
        if (!(params.startDate && params.endDate)) {
            swal({ title: "알림", text: "기간을 입력해 주세요." });
            return;
        }
        if (siteInfo?.loginYn !== "Y") {
            params.loginYn = "N";
        }
        if (siteInfo?.registrationYn !== "Y") {
            params.registrationYn = "N";
        }
        if (siteInfo?.attendanceYn !== "Y") {
            params.attendanceYn = "N";
        }
        if (siteInfo?.eposterYn !== "Y") {
            params.eposterYn = "N";
        }
        if (siteInfo?.eboothYn !== "Y") {
            params.eboothYn = "N";
        }
        if (siteInfo?.qnaYn !== "Y") {
            params.qnaYn = "N";
        }
        if (siteInfo?.quizYn !== "Y") {
            params.quizYn = "N";
        }
        if (siteInfo?.emailYn !== "Y") {
            params.emailEYn = "N";
        }
        if (siteInfo?.smsYn !== "Y") {
            params.smsYn = "N";
        }
        if (siteInfo?.eposterOptYn !== "Y") {
            params.eposterOptYn = "N";
        }
        if (siteInfo?.eboothGradeYn !== "Y") {
            params.eboothGradeYn = "N";
        }
        if (siteInfo?.pdfEbookYn !== "Y") {
            params.pdfEbookYn = "N";
        }

        _API.post({
            path: "/v1/a/event",
            data: params,
        }).then((res) => {
            swal({ title: "알림", text: `이벤트가 ${!data.eventIdx ? "생성" : "수정"}되었습니다.` }).then(() => {
                // _U.postMessage("EVENT-CHANGED");
                const newEventIdx = res.data.eventIdx;
                if (params.eventIdx) {
                    props.reSetEventInfo && props.reSetEventInfo();
                } else {
                    history.push(`admin/event/list?page=${props.getBeforePageNo()}`);
                }
                // else {
                //     history.push(`/event/form/${newEventIdx}`);
                // }

                // history.push(`/admin/event/list?page=${props.getBeforePageNo()}`);

            });
        });
    };

    const onCancel = () => {
        history.push(`/admin/event/list?page=${props.getBeforePageNo()}`);
    }

    const loadLocationList = (siteIdx) => {
        if (!siteIdx) {
            return;
        }
        const data = { siteIdx };
        const req = { path: `/v1/a/location/list`, data };
        _API.get(req)
            .then((res) => res.data)
            .then((data) => {
                setLocationList(data.list);
            });
    };

    const loadIntroContentList = () => {
        if (!eventInfo?.eventIdx) {
            return;
        }
        const data = { eventIdx: eventInfo?.eventIdx, category:"EMAIN", contentType:"VOD_FILE", contentStatus:"Y"};
        const req = { path: `/v1/sa/event/content/list`, data };
        _API.get(req)
            .then((res) => res.data)
            .then((data) => {
                setVodContentList(data.list);
            });
    };

    useEffect(() => {
        loadLocationList(siteIdx);
    }, []);

    useEffect(() => {
        eventInfo.eventIdx ? setData(eventInfo) : setData({ ...eventInfo, ...initDataYn });
        loadIntroContentList();
    }, [eventInfo]);

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="ibox">
                    <div className="ibox-title">이벤트 {!data?.eventIdx ? "등록" : "수정"}</div>
                    <div className="ibox-content">
                        <InputForm title="이벤트명" name="title" placeholder="이벤트명" value={data?.title} onChange={handleDataChange} />
                        <SelectForm title="장소" name="locIdx" items={locationList?.map((loc) => { return { label: loc.title + "(" + _U.shortText(loc.addr, 20, "...") + ")", value: loc.idx } })} value={data?.locIdx} onChange={handleDataChange} nullTitle="장소 선택" />
                        <Separator />
                        <DatePicker title="기간" value={data?.startDate ? moment(data?.startDate).format("YYYY-MM-DD") : ""} onChange={(v) => setDataChange("startDate", v.value)} />
                        <DatePicker title=" " value={data?.endDate ? moment(data?.endDate).format("YYYY-MM-DD") : ""} onChange={(v) => setDataChange("endDate", v.value)} />
                        <Separator />
                        {siteInfo?.loginYn === "Y" && (
                            <SegmentedForm title="로그인 사용여부" items={_DEF.STATUS} value={data?.loginYn} onClick={(v) => setDataChange("loginYn", v)} />
                        )}

                        {data?.loginYn === "Y" && (
                            <Fragment>
                                <SegmentedForm title="외부링크 로그인" items={_DEF.STATUS} value={data?.linkLoginYn} onClick={(v) => setDataChange("linkLoginYn", v)} />
                                <div className="form-group row">
                                    <label className="col-sm-3 control-label m-t-sm">로그인 설명</label>
                                    <div className="col-sm-9 btn-group">
                                        <div className="input-group">
                                            <SNEditor content={data?.loginDescription} onChange={(v) => setDataChange("loginDescription", v)} height="200" />
                                        </div>
                                    </div>
                                </div>
                                <SegmentedForm title="로그인 구분" items={_DEF.LOGIN_TYPE} value={data?.loginType} onClick={(v) => setDataChange("loginType", v)} />
                                <SegmentedForm title="비밀번호 사용여부" items={_DEF.STATUS} value={data?.passwdYn} onClick={(v) => setDataChange("passwdYn", v)} />
                                <SegmentedForm title="중복로그인 차단여부" items={_DEF.STATUS} value={data?.dupLoginKickYn} onClick={(v) => setDataChange("dupLoginKickYn", v)} />
                                <Separator />
                            </Fragment>
                        )}

                        {siteInfo?.registrationYn === "Y" && (
                            <SegmentedForm title="사전등록 사용여부" items={_DEF.STATUS} value={data?.registrationYn} onClick={(v) => setDataChange("registrationYn", v)} />
                        )}
                        {siteInfo?.attendanceYn === "Y" && (
                            <SegmentedForm title="출결 사용여부" items={_DEF.STATUS} value={data?.attendanceYn} onClick={(v) => setDataChange("attendanceYn", v)} />
                        )}
                        {siteInfo?.eposterYn === "Y" && (
                            <SegmentedForm title="이포스터 사용여부" items={_DEF.STATUS} value={data?.eposterYn} onClick={(v) => setDataChange("eposterYn", v)} />
                        )}
                        {siteInfo?.eposterOptYn === "Y" && siteInfo?.eposterYn === "Y" && data?.eposterYn === "Y" && (
                            <SegmentedForm title="이포스터 추가기능(댓글,즐겨찾기)" items={_DEF.STATUS} value={data?.eposterOptYn} onClick={(v) => setDataChange("eposterOptYn", v)} />
                        )}
                        {siteInfo?.eboothYn === "Y" && (
                            <SegmentedForm title="이부스 사용여부" items={_DEF.STATUS} value={data?.eboothYn} onClick={(v) => setDataChange("eboothYn", v)} />
                        )}
                        {siteInfo?.eboothGradeYn === "Y" && siteInfo?.eboothYn === "Y" && data?.eboothYn === "Y" && (
                            <SegmentedForm title="이부스 등급" items={_DEF.STATUS} value={data?.eboothGradeYn} onClick={(v) => setDataChange("eboothGradeYn", v)} />
                        )}
                        {siteInfo?.qnaYn === "Y" && (
                            <SegmentedForm title="Q&A 사용여부" items={_DEF.STATUS} value={data?.qnaYn} onClick={(v) => setDataChange("qnaYn", v)} />
                        )}
                        {siteInfo?.quizYn === "Y" && (
                            <SegmentedForm title="QUIZ 사용여부" items={_DEF.STATUS} value={data?.quizYn} onClick={(v) => setDataChange("quizYn", v)} />
                        )}
                        {siteInfo?.emailYn === "Y" && (
                            <SegmentedForm title="이메일 발송 사용여부" items={_DEF.STATUS} value={data?.emailEYn || "N"} onClick={(v) => setDataChange("emailEYn", v)} />
                        )}
                        {siteInfo?.smsYn === "Y" && (
                            <SegmentedForm title="SMS 발송 사용여부" items={_DEF.STATUS} value={data?.smsYn || "N"} onClick={(v) => setDataChange("smsYn", v)} />
                        )}
                        {siteInfo?.pdfEbookYn === "Y" && (
                            <SegmentedForm title="PDF E-BOOK뷰어" items={_DEF.STATUS} value={data?.pdfEbookYn || "N"} onClick={(v) => setDataChange("pdfEbookYn", v)} />
                        )}
                        <SegmentedForm title="채널하단 롤링효과" items={_DEF.STATUS} value={data?.footerFlowYn || "Y"} onClick={(v) => setDataChange("footerFlowYn", v)} />
                        <SegmentedForm title="채팅 사용여부" items={_DEF.STATUS} value={data?.chatYn || "Y"} onClick={(v) => setDataChange("chatYn", v)} />
                        <SegmentedForm title="연자소개 사용여부" items={_DEF.STATUS} value={data?.speakerYn || "Y"} onClick={(v) => setDataChange("speakerYn", v)} />
                        <SegmentedForm title="다시보기 추천 사용여부" items={_DEF.STATUS} value={data?.replayRecommendYn || "N"} onClick={(v) => setDataChange("replayRecommendYn", v)} />
                        <Separator />
                        <SegmentedForm title="상단메뉴바 사용여부" items={_DEF.STATUS} value={data?.headerShowYn || "Y"} onClick={(v) => setDataChange("headerShowYn", v)} />
                        <SegmentedForm title="메인화면 3D 사용여부(PC)" items={_DEF.STATUS} value={data?.main3DPcYn || "N"} onClick={(v) => setDataChange("main3DPcYn", v)} />
                        {data?.main3DPcYn === "Y" && (
                            <SegmentedForm title="메인화면 3D 사용여부(MOBILE)" items={_DEF.STATUS} value={data?.main3DMbYn || "N"} onClick={(v) => setDataChange("main3DMbYn", v)} />
                        )}
                        {data?.main3DPcYn === "Y" && (iam?.memType === "DEVELOPER" || iam?.memType === "SUPERADMIN") && (
                            <InputForm title="메인화면 3D 경로명" name="main3DPath" placeholder="e.g. kams_vr" value={data?.main3DPath} onChange={handleDataChange} />
                        )}
                        <SegmentedForm title="VOD 출결체크" items={_DEF.STATUS} value={data?.lectureReplayAttendYn || "N"} onClick={(v) => setDataChange("lectureReplayAttendYn", v)} />

                        {eventInfo?.eventIdx && (
                            <Fragment>
                                <Separator />
                                <SegmentedForm title="인트로영상 노출여부" items={_DEF.STATUS} value={data?.introVodYn || "N"} onClick={(v) => setDataChange("introVodYn", v)} />
                                {data?.introVodYn == "Y" && (
                                    <SelectForm title="인트로영상" name="introVodContentIdx" items={vodContentList?.map((m) => { return { label: m.title , value: m.idx } })} value={data?.introVodContentIdx} onChange={handleDataChange} nullTitle="인트로영상 선택" />
                                )}
                            </Fragment>
                        )}
                        
                        {/* <Separator /> */}
                        {/* <SegmentedForm title="운영여부" items={_DEF.STATUS} value={data?.mainYn} onClick={(v) => setDataChange("mainYn", v)} /> */}
                        {/* <SegmentedForm title="사용여부" items={_DEF.STATUS} value={data?.status} onClick={(v) => setDataChange("status", v)} /> */}
                        {/* <TextForm title="설명" name="description" placeholder="설명" value={data?.description} onChange={handleDataChange} /> */}
                        <Separator />
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <div className="btn-group">
                                    <Button onClick={handleSaveClick} className="btn btn-primary">
                                        {!data.eventIdx ? "생성" : "수정"}
                                    </Button>
                                    <Button onClick={onCancel}>취소</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const EventMenuForm = (props) => {
    const { history, location, match } = props;
    const { siteIdx, siteList, eventIdx } = props;
    const [data, setData] = useState({});
    const [isModifyMenu, setIsModifyMenu] = useState(false);

    const handleDataChange = (e) => {
        let { name, value } = e.target;
        const tempData = JSON.parse(JSON.stringify(data))
        if (!tempData[name]) {
            tempData[name] = {};
        }
        tempData[name].name = value;
        setData(tempData);
    };

    const handleSaveClick = () => {

        const menuList = [];
        for (let menu in data) {
            menuList.push(
                data[menu].idx ?
                    data[menu] :
                    { name: data[menu].name, code: menu.toUpperCase() }
            )
        }

        const params = { menuList, eventIdx };

        _API.post({
            path: "/v1/a/event/menu/save",
            data: params,
        }).then((res) => {
            swal({ title: "알림", text: `메뉴가 ${isModifyMenu ? "수정" : "생성"}되었습니다.` }).then(() => {
                props.reSetEventInfo && props.reSetEventInfo();
            });
        });
    };

    const onCancel = () => {
        history.push(`/admin/event/list?page=${props.getBeforePageNo()}`);
    }

    const loadMenuList = () => {
        if (!eventIdx) {
            return;
        }
        const param = { eventIdx };

        const req = { path: `/v1/a/event/menu/`, data: param };
        _API.get(req)
            .then((res) => res.data)
            .then((data) => {
                setIsModifyMenu(!!data.length);
                setData(data.reduce((acc, cur) => {
                    return { ...acc, ...{ [cur.code.toLowerCase()]: cur } }
                }, {}))

            });
    };

    useEffect(() => {
        loadMenuList();
    }, []);

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="ibox">
                    <div className="ibox-title">메뉴 {!data?.eventIdx ? "등록" : "수정"}</div>
                    <div className="ibox-content">
                        <InputForm title="INFORMATION" name="information" placeholder="INFORMATION" value={data?.information?.name} onChange={handleDataChange} />
                        <InputForm title="LECTURE" name="lecture" placeholder="LECTURE" value={data?.lecture?.name} onChange={handleDataChange} />
                        <InputForm title="SATELLITE SESSION" name="satellite" placeholder="SATELLITE SESSION" value={data?.satellite?.name} onChange={handleDataChange} />
                        <InputForm title="E-POSTER" name="eposter" placeholder="E-POSTER" value={data?.eposter?.name} onChange={handleDataChange} />
                        <InputForm title="E-BOOTH" name="ebooth" placeholder="E-BOOTH" value={data?.ebooth?.name} onChange={handleDataChange} />
                        <InputForm title="MY PAGE" name="mypage" placeholder="MY PAGE" value={data?.mypage?.name} onChange={handleDataChange} />
                        <InputForm title="아젠다" name="agenda" placeholder="아젠다" value={data?.agenda?.name} onChange={handleDataChange} />
                        <Separator />
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <div className="btn-group">
                                    <Button onClick={handleSaveClick} className="btn btn-primary">
                                        {isModifyMenu ? "수정" : "생성"}
                                    </Button>
                                    <Button onClick={onCancel}>취소</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export const EventVisualForm = (props) => {
    const { history, location, match } = props;
    const { eventInfo } = props;
    const [data, setData] = useState(eventInfo);
    const [html, setHtml] = useState("");

    const handleDataChange = (e) => {
        let { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const setDataChange = (name, value) => {
        setData({ ...data, [name]: value });
    };

    const fileUpload = (name, item) => {
        const urlName = name.replace("FileIdx", "SignedUrl");
        setData({ ...data, [name]: item.id, [urlName]: item.signedUrl });
    }

    const handleSaveClick = () => {
        if (!data.eventIdx) {
            swal({ title: "알림", text: "이벤트정보가 없습니다." });
            return;
        }
        if (!data.templeteCode) {
            swal({ title: "알림", text: "템플릿을 선택해 주세요." });
            return;
        }

        _API.post({
            path: "/v1/a/event/visual",
            data: { ...data, footer: html },
        }).then((res) => {
            swal({ title: "알림", text: `이벤트 비주얼정보가 ${!data.eventVisualIdx ? "생성" : "수정"}되었습니다.` }).then(() => {
                // _U.postMessage("EVENT-CHANGED");
                props.reSetEventInfo && props.reSetEventInfo();
            });
        });
    };

    const onCancel = () => {
        history.push(`/admin/event/list`);
    };

    useEffect(() => {
        setData(eventInfo);
        setHtml(eventInfo.footer);
    }, [eventInfo]);

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="ibox">
                    <div className="ibox-title">이벤트 비주얼 {!data?.eventVisualIdx ? "등록" : "수정"}</div>
                    <div className="ibox-content">
                        <SelectForm title="템플릿" name="templeteCode" value={data?.templeteCode} items={_DEF.TEMPLATE} onChange={handleDataChange} nullTitle="템플릿 선택" />
                        <InputForm title="티저영상 주소" name="teaserUrl" placeholder="티저영상 주소" value={data?.teaserUrl} onChange={handleDataChange} />
                        <Separator />
                        <FileForm title="상단로고(PC)" onChange={(v) => fileUpload("logoTopPcFileIdx", v)} path="EVENT/VISUAL" url={data?.logoTopPcSignedUrl} />
                        <FileForm title="상단로고(MOBILE)" onChange={(v) => fileUpload("logoTopMbFileIdx", v)} path="EVENT/VISUAL" url={data?.logoTopMbSignedUrl} />
                        <Separator />
                        <FileForm title="메인비주얼(PC)" onChange={(v) => fileUpload("mVisualPcFileIdx", v)} path="EVENT/VISUAL" url={data?.mVisualPcSignedUrl} />
                        <FileForm title="메인비주얼(MOBILE)" onChange={(v) => fileUpload("mVisualMbFileIdx", v)} path="EVENT/VISUAL" url={data?.mVisualMbSignedUrl} />
                        <Separator />
                        <FileForm title="배경화면(PC)" onChange={(v) => fileUpload("bgPcFileIdx", v)} path="EVENT/VISUAL" url={data?.bgPcSignedUrl} />
                        <FileForm title="배경화면(MOBILE)" onChange={(v) => fileUpload("bgMbFileIdx", v)} path="EVENT/VISUAL" url={data?.bgMbSignedUrl} />
                        <Separator />
                        <div className="form-group row">
                            <label className="col-sm-3 control-label m-t-sm">푸터</label>
                            <div className={cx({ "col-sm-9": true, "col-sm-12": false })}>
                                <SNEditor content={html} onChange={(v) => setHtml(v)} />
                            </div>
                        </div>
                        <Separator />
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <div className="btn-group">
                                    <Button onClick={handleSaveClick} className="btn btn-primary">
                                        {!data.eventVisualIdx ? "생성" : "수정"}
                                    </Button>
                                    <Button onClick={onCancel}>취소</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const EventIntroduceForm = (props) => {
    const { history, location, match } = props;
    const { eventInfo } = props;
    const [data, setData] = useState(eventInfo);
    const [html, setHtml] = useState("");
    const [addHtml, setAddHtml] = useState(null);
    const [html0, setHtml0] = useState("");
    const [html1, setHtml1] = useState("");
    const [html2, setHtml2] = useState("");
    const [html3, setHtml3] = useState("");
    const [html4, setHtml4] = useState("");
    const [html5, setHtml5] = useState("");
    const [html6, setHtml6] = useState("");
    const [html7, setHtml7] = useState("");
    const [html8, setHtml8] = useState("");
    const [html9, setHtml9] = useState("");
    const [loginAlertHtml, setLoginAlertHtml] = useState(null);
    const [channelDescHtml, setChannelDescHtml] = useState(null);

    const handleDataChange = (e) => {
        let { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const setDataChange = (name, value) => {
        setData({ ...data, [name]: value });
    };

    const fileUpload = (name, item) => {
        const urlName = name.replace("FileIdx", "SignedUrl");
        setData({ ...data, [name]: item.id, [urlName]: item.signedUrl });
    }

    const handleSaveClick = () => {
        if (!data.eventIdx) {
            swal({ title: "알림", text: "이벤트정보가 없습니다." });
            return;
        }

        const newAddHtmls = [];
        if (addHtml && addHtml.length > 0) {
            for (let i in addHtml) {
                const c = addHtml[i];
                if (!c.label || !getHtmlData(i)) {
                    swal({ title: "알림", text: "추가안내문의 내용이 누락되었습니다." });
                    return;
                }
                newAddHtmls.push({ ...c, value: getHtmlData(i) });
            }
        }
        const newAddHtml = newAddHtmls && newAddHtmls.length > 0 ? JSON.stringify(newAddHtmls) : null;
        _API.post({
            path: "/v1/a/event",
            data: { ...data, introduce: html, addDesc: newAddHtml, loginAlert: loginAlertHtml, channelDesc: channelDescHtml },
        }).then((res) => {
            swal({ title: "알림", text: `이벤트 인사말이 저장되었습니다.` }).then(() => {
                // _U.postMessage("EVENT-CHANGED");
                props.reSetEventInfo && props.reSetEventInfo();
            });
        });
    };

    const onCancel = () => {
        history.push(`/event/list`);
    };

    useEffect(() => {
        setData(eventInfo);
        setHtml(eventInfo.introduce);
        const tmpAddHtml = _U.jsonParse(eventInfo.addDesc);
        for (let i in tmpAddHtml) {
            const c = tmpAddHtml[i];
            htmlDataChange(i, c.value);
        }
        setAddHtml(tmpAddHtml);
        setLoginAlertHtml(eventInfo.loginAlert);
        setChannelDescHtml(eventInfo.channelDesc);
    }, [eventInfo]);

    const addColumn = () => {
        let newAddHtml = [];
        if (addHtml && addHtml.length > 0) {
            if (addHtml.length >= 10) {
                swal({ title: "알림", text: "최대 10개까지만 추가 가능합니다.." });
                return;
            }
            newAddHtml = JSON.parse(JSON.stringify(addHtml));
        }
        let i = addHtml ? addHtml.length : 0;
        newAddHtml.push({ label: "", value: "" });
        htmlDataChange(i, "");
        setAddHtml(newAddHtml);
    }

    const changeAddHtmlLabel = (index, name, value) => {
        let newAddHtml = [];
        for (let i in addHtml) {
            const c = addHtml[i];
            if (index == i) {
                newAddHtml.push({ ...c, [name]: value });
            } else {
                newAddHtml.push(c);
            }
        }
        setAddHtml(newAddHtml);
    }

    const delColumn = (index) => {
        let newAddHtml = [];
        for (let i in addHtml) {
            const c = addHtml[i];
            if (index != i) {
                newAddHtml.push({ ...c, value: getHtmlData(i) });
            }
        }
        for (let i in newAddHtml) {
            const c = newAddHtml[i];
            htmlDataChange(i, c.value);
        }
        for (let i = newAddHtml.length; i < 10; i++) {
            htmlDataChange(i, "");
        }
        setAddHtml(newAddHtml);
    }

    const htmlDataChange = (i, val) => {
        i == 0 && setHtml0(val);
        i == 1 && setHtml1(val);
        i == 2 && setHtml2(val);
        i == 3 && setHtml3(val);
        i == 4 && setHtml4(val);
        i == 5 && setHtml5(val);
        i == 6 && setHtml6(val);
        i == 7 && setHtml7(val);
        i == 8 && setHtml8(val);
        i == 9 && setHtml9(val);
    }

    const getHtmlData = (i) => {
        let returnValue = "";
        switch (parseInt(i)) {
            case 0:
                returnValue = html0;
                break;
            case 1:
                returnValue = html1;
                break;
            case 2:
                returnValue = html2;
                break;
            case 3:
                returnValue = html3;
                break;
            case 4:
                returnValue = html4;
                break;
            case 5:
                returnValue = html5;
                break;
            case 6:
                returnValue = html6;
                break;
            case 7:
                returnValue = html7;
                break;
            case 8:
                returnValue = html8;
                break;
            case 9:
                returnValue = html9;
                break;
            default:
                break;
        }
        return returnValue;
    }

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="ibox">
                    <div className="ibox-title">이벤트 인사말</div>
                    <div className="ibox-content">
                        <div className="form-group row">
                            <label className="col-sm-3 control-label m-t-sm">로그인 팝업 공지</label>
                            <div className={cx({ "col-sm-9": true, "col-sm-12": false })}>
                                <SNEditor content={loginAlertHtml} onChange={(v) => setLoginAlertHtml(v)} />
                            </div>
                        </div>
                        <Separator />
                        <div className="form-group row">
                            <label className="col-sm-3 control-label m-t-sm">인사말</label>
                            <div className={cx({ "col-sm-9": true, "col-sm-12": false })}>
                                {/* {JSON.stringify(data?.footer)} */}
                                <SNEditor content={html} onChange={(v) => setHtml(v)} />
                            </div>
                        </div>
                        <Separator />
                        <div className="form-group row">
                            <label className="col-sm-3 control-label m-t-sm">채널하단 안내문</label>
                            <div className={cx({ "col-sm-9": true, "col-sm-12": false })}>
                                {/* {JSON.stringify(data?.footer)} */}
                                <SNEditor content={channelDescHtml} onChange={(v) => setChannelDescHtml(v)} />
                            </div>
                        </div>
                        <Separator />
                        <div className="form-group row">
                            <label className="col-sm-3 control-label m-t-sm">추가안내문입력</label>
                            <div className={cx({ "col-sm-9": true, "col-sm-12": false })}>
                                <button type="button" className="btn btn-primary" onClick={addColumn}>
                                    <i className="fa fa-plus"></i> 추가
                                </button>
                            </div>
                        </div>
                        {addHtml && addHtml.length > 0 && addHtml.map((item, i) => (
                            <Fragment key={i}>
                                <div className="form-group row">
                                    <div className="col-sm-3 control-label m-t-sm">
                                        <InputForm type="text" value={item?.label} placeholder="안내문 이름" onChange={(e) => changeAddHtmlLabel(i, 'label', e.target.value)} />
                                    </div>
                                    <div className={cx({ "col-sm-8": true, "col-sm-12": false })}>
                                        <SNEditor content={getHtmlData(i)} onChange={(v) => htmlDataChange(i, v)} />
                                    </div>
                                    <div className={cx({ "col-sm-1": true, "m-t-sm": true })}>
                                        <button type="button" className="btn btn-danger" onClick={(e) => delColumn(i)}><i className="fa fa-trash"></i> 삭제</button>
                                    </div>
                                </div>
                                <Separator />
                            </Fragment>
                        ))}
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <div className="btn-group">
                                    <Button onClick={handleSaveClick} className="btn btn-primary">
                                        {!data.eventVisualIdx ? "생성" : "수정"}
                                    </Button>
                                    <Button onClick={onCancel}>취소</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const EventProgramForm = (props) => {
    const { history, location, match } = props;
    const { eventInfo } = props;
    const [data, setData] = useState(eventInfo);
    const [html, setHtml] = useState("");

    const handleDataChange = (e) => {
        let { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const setDataChange = (name, value) => {
        setData({ ...data, [name]: value });
    };

    const fileUpload = (name, item) => {
        const urlName = name.replace("FileIdx", "SignedUrl");
        setData({ ...data, [name]: item.id, [urlName]: item.signedUrl });
    }

    const handleSaveClick = () => {
        if (!data.eventIdx) {
            swal({ title: "알림", text: "이벤트정보가 없습니다." });
            return;
        }

        _API.post({
            path: "/v1/a/event",
            data: { ...data, program: html },
        }).then((res) => {
            swal({ title: "알림", text: `이벤트 프로그램 소개가 저장되었습니다.` }).then(() => {
                // _U.postMessage("EVENT-CHANGED");
                props.reSetEventInfo && props.reSetEventInfo();
            });
        });
    };

    const onCancel = () => {
        history.push(`/event/list`);
    };

    useEffect(() => {
        setData(eventInfo);
        setHtml(eventInfo.program);
    }, [eventInfo]);

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="ibox">
                    <div className="ibox-title">이벤트 프로그램 소개</div>
                    <div className="ibox-content">
                        <div className="form-group row">
                            <label className="col-sm-3 control-label m-t-sm">프로그램 소개</label>
                            <div className={cx({ "col-sm-9": true, "col-sm-12": false })}>
                                {/* {JSON.stringify(data?.footer)} */}
                                <SNEditor content={html} onChange={(v) => setHtml(v)} />
                            </div>
                        </div>
                        <Separator />
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <div className="btn-group">
                                    <Button onClick={handleSaveClick} className="btn btn-primary">
                                        {!data.eventVisualIdx ? "생성" : "수정"}
                                    </Button>
                                    <Button onClick={onCancel}>취소</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const EventMenuDesc = (props) => {
    const { history, location, match } = props;
    const { eventInfo } = props;
    const [data, setData] = useState(eventInfo);
    const [attendHtml, setAttendHtml] = useState("");
    const [eboothHtml, setEboothHtml] = useState("");

    const handleDataChange = (e) => {
        let { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const setDataChange = (name, value) => {
        setData({ ...data, [name]: value });
    };

    const fileUpload = (name, item) => {
        const urlName = name.replace("FileIdx", "SignedUrl");
        setData({ ...data, [name]: item.id, [urlName]: item.signedUrl });
    }

    const handleSaveClick = () => {
        if (!data.eventIdx) {
            swal({ title: "알림", text: "이벤트정보가 없습니다." });
            return;
        }
        _API.post({
            path: "/v1/a/event",
            data: { ...data, attendDesc: attendHtml, eboothDesc: eboothHtml },
        }).then((res) => {
            swal({ title: "알림", text: `이벤트 메뉴설명이 저장되었습니다.` }).then(() => {
                // _U.postMessage("EVENT-CHANGED");
                props.reSetEventInfo && props.reSetEventInfo();
            });
        });
    };

    const onCancel = () => {
        history.push(`/event/list`);
    };

    useEffect(() => {
        setData(eventInfo);
        setAttendHtml(eventInfo.attendDesc);
        setEboothHtml(eventInfo.eboothDesc);
    }, [eventInfo]);

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="ibox">
                    <div className="ibox-title">이벤트 메뉴설명</div>
                    <div className="ibox-content">
                        <div className="form-group row">
                            <label className="col-sm-3 control-label m-t-sm">출결 안내문</label>
                            <div className={cx({ "col-sm-9": true, "col-sm-12": false })}>
                                {/* {JSON.stringify(data?.footer)} */}
                                <SNEditor content={attendHtml} onChange={(v) => setAttendHtml(v)} />
                            </div>

                            <label className="col-sm-3 control-label m-t-sm">이부스 안내문</label>
                            <div className={cx({ "col-sm-9": true, "col-sm-12": false })}>
                                {/* {JSON.stringify(data?.footer)} */}
                                <SNEditor content={eboothHtml} onChange={(v) => setEboothHtml(v)} />
                            </div>
                        </div>
                        <Separator />
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <div className="btn-group">
                                    <Button onClick={handleSaveClick} className="btn btn-primary">
                                        수정
                                    </Button>
                                    <Button onClick={onCancel}>취소</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const EventRegistrationForm = (props) => {
    const { history, location, match } = props;
    const { eventInfo } = props;
    const [data, setData] = useState(eventInfo);
    const [html, setHtml] = useState("");
    const [selectHtml, setSelectHtml] = useState("");
    const [topDesc, setTopDesc] = useState("");
    const [lastDesc, setLastDesc] = useState("");
    const [regMailForm, setRegMailForm] = useState("");
    const [confirmMailForm, setConfirmMailForm] = useState("");
    const [addColumnList, setAddColumnList] = useState([]);
    const [delColumnList, setDelColumnList] = useState([]);
    const [termsSelect2, setTermsSelect2] = useState("");
    const [termsSelect3, setTermsSelect3] = useState("");
    const [termsSelect4, setTermsSelect4] = useState("");
    const [termsSelectSize, setTermsSelectSize] = useState(0);

    const handleDataChange = (e) => {
        let { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const setDataChange = (name, value) => {
        if (name.toLowerCase().indexOf("startdate") > -1) {
            setData({ ...data, [name]: value + " 00:00:00" });
        } else if (name.toLowerCase().indexOf("enddate") > -1) {
            setData({ ...data, [name]: value + " 23:59:59" });
        } else if (name === "emailYn") {
            if (value === "N") setData({ ...data, [name]: value, emailEsYn: "N" });
            else setData({ ...data, [name]: value });
        } else if (name === "phoneYn") {
            if (value === "N") setData({ ...data, [name]: value, phoneEsYn: "N" });
            else setData({ ...data, [name]: value });
        } else if (name === "nameYn") {
            if (value === "N") setData({ ...data, [name]: value, nameEsYn: "N" });
            else setData({ ...data, [name]: value });
        } else if (name === "companyYn") {
            if (value === "N") setData({ ...data, [name]: value, companyEsYn: "N" });
            else setData({ ...data, [name]: value });
        } else if (name === "licenseYn") {
            if (value === "N") setData({ ...data, [name]: value, licenseEsYn: "N" });
            else setData({ ...data, [name]: value });
        } else if (name === "regTypeYn") {
            if (value === "N") setData({ ...data, [name]: value, regTypeEsYn: "N" });
            else setData({ ...data, [name]: value });
        } else if (name === "emailEsYn") {
            if (data.emailYn === "Y") setData({ ...data, [name]: value });
            else {
                swal({ title: "알림", text: "사용여부가 [사용] 상태에서만 필수 설정이 사용 가능합니다." });
            }
        } else if (name === "phoneEsYn") {
            if (data.phoneYn === "Y") setData({ ...data, [name]: value });
            else {
                swal({ title: "알림", text: "사용여부가 [사용] 상태에서만 필수 설정이 사용 가능합니다." });
            }
        } else if (name === "nameEsYn") {
            if (data.nameYn === "Y") setData({ ...data, [name]: value });
            else {
                swal({ title: "알림", text: "사용여부가 [사용] 상태에서만 필수 설정이 사용 가능합니다." });
            }
        } else if (name === "companyEsYn") {
            if (data.companyYn === "Y") setData({ ...data, [name]: value });
            else {
                swal({ title: "알림", text: "사용여부가 [사용] 상태에서만 필수 설정이 사용 가능합니다." });
            }
        } else if (name === "licenseEsYn") {
            if (data.licenseYn === "Y") setData({ ...data, [name]: value });
            else {
                swal({ title: "알림", text: "사용여부가 [사용] 상태에서만 필수 설정이 사용 가능합니다." });
            }
        } else if (name === "registrationSendYn" && value === "N") {
            setData({ ...data, [name]: value, regMailForm: null, confirmMailForm: null });
            setRegMailForm("");
            setConfirmMailForm("");
        } else {
            setData({ ...data, [name]: value });
        }
    };

    const fileUpload = (name, item) => {
        const urlName = name.replace("Idx", "SignedUrl");
        setData({ ...data, [name]: item.id, [urlName]: item.signedUrl });
    }

    const handleSaveClick = () => {
        if (!data.eventIdx) {
            swal({ title: "알림", text: "이벤트정보가 없습니다." });
            return;
        }
        for (let i in addColumnList) {
            const item = addColumnList[i];
            if (!item.labelName) {
                swal({ title: "알림", text: "추가정보 입력의 항목명이 누락 되었습니다." });
                return;
            }
            if (item.questionType == "SINGLE" || item.questionType == "MULTIPLE") {
                if (!item.choiceItems || !item.choiceItems.length === 0) {
                    swal({ title: "알림", text: "추가정보 보기항목이 누락 되었습니다." });
                    return;
                }
                for (let j in item.choiceItems) {
                    const cItem = item.choiceItems[j];
                    if (!cItem) {
                        swal({ title: "알림", text: "추가정보 보기항목이 누락 되었습니다." });
                        return;
                    }
                }
            }
        }
        _API.post({
            path: "/v1/a/event/registration",
            data: {
                registrationOptIdx: data.registrationOptIdx,
                siteIdx: data.siteIdx,
                eventIdx: data.eventIdx,
                emailYn: data.emailYn || "Y",
                emailEsYn: data.emailEsYn || "N",
                phoneYn: data.phoneYn || "Y",
                phoneEsYn: data.phoneEsYn || "N",
                nameYn: data.nameYn || "Y",
                nameEsYn: data.nameEsYn || "N",
                companyYn: data.companyYn || "Y",
                companyEsYn: data.companyEsYn || "N",
                licenseYn: data.licenseYn || "Y",
                licenseEsYn: data.licenseEsYn || "N",
                regTypeYn: data.regTypeYn || "Y",
                regTypeEsYn: data.regTypeEsYn || "N",
                periodLimitYn: data.periodLimitYn || "N",
                registrationStartDate: data.registrationStartDate ? moment(data?.registrationStartDate).format("YYYY-MM-DD HH:mm:ss") : null,
                registrationEndDate: data.registrationEndDate ? moment(data?.registrationEndDate).format("YYYY-MM-DD HH:mm:ss") : null,
                termsPrivacy: html || "",
                termsSelectPrivacy: selectHtml || "",
                termsSelectDesc : data.termsSelectDesc || null,
                addColumnList: addColumnList,
                delColumnList: delColumnList,
                registrationSendYn: data.registrationSendYn || "N",
                regMailForm: regMailForm || null,
                confirmMailForm: confirmMailForm || null,
                confirmProcessYn: data.confirmProcessYn || "Y",
                licenseDesc: data.licenseDesc || null,
                lastDesc: lastDesc || null,
                topImgIdx: data.topImgIdx || null,
                topDesc: topDesc || null,
                companyLabelName: data.companyLabelName || null,
                termsSelect2 : termsSelect2 || null,
                termsSelect3 : termsSelect3 || null,
                termsSelect4 : termsSelect4 || null,
                termsSelect2Desc : data.termsSelect2Desc || null,
                termsSelect3Desc : data.termsSelect3Desc || null,
                termsSelect4Desc : data.termsSelect4Desc || null,
            },
        }).then((res) => {
            swal({ title: "알림", text: `이벤트 사전등록 정보가 ${!data.registrationOptIdx ? "생성" : "수정"}되었습니다.` }).then(() => {
                // _U.postMessage("EVENT-CHANGED");
                props.reSetEventInfo && props.reSetEventInfo();
            });
        });
    };

    const onCancel = () => {
        history.push(`/admin/event/list`);
    };

    const loadAddColumnList = () => {
        const data = {
            eventIdx: eventInfo.eventIdx,
        };
        const req = { path: "/v1/a/event/registration/item", data };
        _API.get(req).then((res) => {
            const { list } = res.data;
            const newList = [];
            for (let i in list) {
                const item = list[i];
                item.choiceItems = JSON.parse(item.choiceItems);
                newList.push(item);
            }
            setAddColumnList(newList);
        });
    };

    useEffect(() => {
        setData(eventInfo);
        setHtml(eventInfo.termsPrivacy);
        setSelectHtml(eventInfo.termsSelectPrivacy);
        setTopDesc(eventInfo.topDesc);
        setLastDesc(eventInfo.lastDesc);
        setRegMailForm(eventInfo.regMailForm);
        setConfirmMailForm(eventInfo.confirmMailForm);
        setTermsSelect2(eventInfo.termsSelect2);
        setTermsSelect3(eventInfo.termsSelect3);
        setTermsSelect4(eventInfo.termsSelect4);
        let size = 0;
        if(eventInfo.termsSelect2 || eventInfo.termsSelect2Desc) size++;
        if(eventInfo.termsSelect3 || eventInfo.termsSelect3Desc) size++;
        if(eventInfo.termsSelect4 || eventInfo.termsSelect4Desc) size++;
        setTermsSelectSize(size);
        if (eventInfo?.eventIdx) loadAddColumnList();
    }, [eventInfo]);

    const addColumn = () => {
        setAddColumnList([...addColumnList, { eventIdx: eventInfo.eventIdx, labelName: "", questionType: "SUBJECTIVE", esYn: "N", sort: "1", status: "Y", choiceItems: null }]);
    }

    const addColumnChange = (name, index, value) => {
        const newList = [...addColumnList];
        if (newList.length >= index) {
            const item = newList[index];
            item[name] = value;
        }
        setAddColumnList(newList);
    }
    const weights = [...Array(100).keys()];

    const delColumn = (index) => {
        const newList = [];
        const newDelList = [...delColumnList];
        for (let i in addColumnList) {
            const item = addColumnList[i];
            if (i != index) newList.push(item);
            else if (item.idx) newDelList.push(item.idx);
        }
        setAddColumnList(newList);
        setDelColumnList(newDelList);
    }

    const addColumnItem = (index) => {
        const newList = [...addColumnList];
        for (let i in newList) {
            const item = newList[i];
            if (i == index) {
                if (!item.choiceItems) item.choiceItems = [];
                item.choiceItems.push("");
            }
        }
        setAddColumnList(newList);
    }

    const addColumnItemChange = (index, col, val) => {
        const newList = [...addColumnList];
        for (let i in newList) {
            const item = newList[i];
            if (i == index) {
                const newColItem = [];
                for (let j in item.choiceItems) {
                    const cItem = item.choiceItems[j];
                    newColItem.push(j == col ? { label: val, value: val } : cItem || "");
                }
                item.choiceItems = newColItem;
            }
        }
        setAddColumnList(newList);
    }

    const addTerms = () => {
        if(termsSelectSize >= 3) {
            swal({ title: "알림", text: `선택약관을 더이상 추가 할 수 없습니다.` });
            return;
        }
        setTermsSelectSize(termsSelectSize+1);
    }

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="ibox">
                    <div className="ibox-title">이벤트 사전등록 {!data?.registrationOptIdx ? "등록" : "수정"}</div>
                    <div className="ibox-content">
                        <SegmentedForm title="이메일 사용여부" items={_DEF.STATUS} value={data?.emailYn} onClick={(v) => setDataChange("emailYn", v)} />
                        <SegmentedForm title="이메일 필수여부" items={_DEF.ESSENTIAL_TYPE} value={data?.emailEsYn} onClick={(v) => setDataChange("emailEsYn", v)} />
                        <Separator />
                        <SegmentedForm title="전화번호 사용여부" items={_DEF.STATUS} value={data?.phoneYn} onClick={(v) => setDataChange("phoneYn", v)} />
                        <SegmentedForm title="전화번호 필수여부" items={_DEF.ESSENTIAL_TYPE} value={data?.phoneEsYn} onClick={(v) => setDataChange("phoneEsYn", v)} />
                        <Separator />
                        <SegmentedForm title="이름 사용여부" items={_DEF.STATUS} value={data?.nameYn} onClick={(v) => setDataChange("nameYn", v)} />
                        <SegmentedForm title="이름 필수여부" items={_DEF.ESSENTIAL_TYPE} value={data?.nameEsYn} onClick={(v) => setDataChange("nameEsYn", v)} />
                        <Separator />
                        <SegmentedForm title="소속정보 사용여부" items={_DEF.STATUS} value={data?.companyYn} onClick={(v) => setDataChange("companyYn", v)} />
                        <SegmentedForm title="소속정보 필수여부" items={_DEF.ESSENTIAL_TYPE} value={data?.companyEsYn} onClick={(v) => setDataChange("companyEsYn", v)} />
                        <InputForm title="소속정보 라벨명" type="text" value={data?.companyLabelName} placeholder="라벨명" onChange={(e) => setDataChange("companyLabelName", e.target.value)} />
                        <Separator />
                        <SegmentedForm title="면허번호 사용여부" items={_DEF.STATUS} value={data?.licenseYn} onClick={(v) => setDataChange("licenseYn", v)} />
                        <SegmentedForm title="면허번호 필수여부" items={_DEF.ESSENTIAL_TYPE} value={data?.licenseEsYn} onClick={(v) => setDataChange("licenseEsYn", v)} />
                        <Separator />
                        <SegmentedForm title="등록구분 사용여부" items={_DEF.STATUS} value={data?.regTypeYn} onClick={(v) => setDataChange("regTypeYn", v)} />
                        <SegmentedForm title="등록구분 필수여부" items={_DEF.ESSENTIAL_TYPE} value={data?.regTypeEsYn} onClick={(v) => setDataChange("regTypeEsYn", v)} />
                        <Separator />
                        <SegmentedForm title="사전등록 승인절차 사용여부" items={_DEF.STATUS} value={data?.confirmProcessYn || "Y"} onClick={(v) => setDataChange("confirmProcessYn", v)} />
                        <SegmentedForm title="사전등록기간 사용여부" items={_DEF.STATUS} value={data?.periodLimitYn} onClick={(v) => setDataChange("periodLimitYn", v)} />
                        {data?.periodLimitYn === "Y" && (
                            <Fragment>
                                <DatePicker title="기간" value={data?.registrationStartDate ? moment(data?.registrationStartDate).format("YYYY-MM-DD") : ""} onChange={(v) => setDataChange("registrationStartDate", v.value)} />
                                <DatePicker title=" " value={data?.registrationEndDate ? moment(data?.registrationEndDate).format("YYYY-MM-DD") : ""} onChange={(v) => setDataChange("registrationEndDate", v.value)} />
                            </Fragment>
                        )}
                        <Separator />
                        <div className="form-group row">
                            <label className="col-sm-3 control-label m-t-sm">필수 약관</label>
                            <div className={cx({ "col-sm-9": true, "col-sm-12": false })}>
                                <SNEditor content={html} onChange={(v) => setHtml(v)} />
                            </div>
                        </div>

                        <Separator />
                        <InputForm title="선택 약관 안내문" type="text" value={data?.termsSelectDesc || ""} placeholder="e.g 본 사이트에서 제공하는 서비스 이용과 관련한 개인정보에 대한 동의합니다." onChange={(e) => setDataChange("termsSelectDesc", e.target.value)} />
                        <div className="form-group row">
                            <label className="col-sm-3 control-label m-t-sm">선택 약관</label>
                            <div className={cx({ "col-sm-9": true, "col-sm-12": false })}>
                                <SNEditor content={selectHtml} onChange={(v) => setSelectHtml(v)} />
                            </div>
                        </div>
                        { termsSelectSize > 0 && (
                            <Fragment>
                                <Separator />
                                <InputForm title="선택 약관2 안내문" type="text" value={data?.termsSelect2Desc || ""} placeholder="e.g 본 사이트에서 제공하는 서비스 이용과 관련한 개인정보에 대한 동의합니다." onChange={(e) => setDataChange("termsSelect2Desc", e.target.value)} />
                                <div className="form-group row">
                                    <label className="col-sm-3 control-label m-t-sm">선택 약관2</label>
                                    <div className={cx({ "col-sm-9": true, "col-sm-12": false })}>
                                        <SNEditor content={termsSelect2} onChange={(v) => setTermsSelect2(v)} />
                                    </div>
                                </div>
                            </Fragment>
                        )}
                        { termsSelectSize > 1 && (
                            <Fragment>
                                <Separator />
                                <InputForm title="선택 약관3 안내문" type="text" value={data?.termsSelect3Desc || ""} placeholder="e.g 본 사이트에서 제공하는 서비스 이용과 관련한 개인정보에 대한 동의합니다." onChange={(e) => setDataChange("termsSelect3Desc", e.target.value)} />
                                <div className="form-group row">
                                    <label className="col-sm-3 control-label m-t-sm">선택 약관3</label>
                                    <div className={cx({ "col-sm-9": true, "col-sm-12": false })}>
                                        <SNEditor content={termsSelect3} onChange={(v) => setTermsSelect3(v)} />
                                    </div>
                                </div>
                            </Fragment>
                        )}
                        { termsSelectSize > 2 && (
                            <Fragment>
                                <Separator />
                                <InputForm title="선택 약관4 안내문" type="text" value={data?.termsSelect4Desc || ""} placeholder="e.g 본 사이트에서 제공하는 서비스 이용과 관련한 개인정보에 대한 동의합니다." onChange={(e) => setDataChange("termsSelect4Desc", e.target.value)} />
                                <div className="form-group row">
                                    <label className="col-sm-3 control-label m-t-sm">선택 약관4</label>
                                    <div className={cx({ "col-sm-9": true, "col-sm-12": false })}>
                                        <SNEditor content={termsSelect4} onChange={(v) => setTermsSelect4(v)} />
                                    </div>
                                </div>
                            </Fragment>
                        )}
                        { termsSelectSize < 3 && (
                            <Fragment>
                                <Separator />
                                <div className="form-group row">
                                    <label className="col-sm-3 control-label m-t-sm">선택약관 추가</label>
                                    <div className={cx({ "col-sm-9": true, "col-sm-12": false })}>
                                        <button type="button" className="btn btn-primary" onClick={addTerms}>
                                            <i className="fa fa-plus"></i> 추가
                                        </button>
                                    </div>
                                </div>
                            </Fragment>
                        )}

                        <Separator />
                        <FileForm title="상단이미지" onChange={(v) => fileUpload("topImgIdx", v)} path={`EVENT/REGISTRATION/${moment().format("YYYY/MM/DD")}`} url={data?.topImgSignedUrl} />

                        <Separator />
                        <div className="form-group row">
                            <label className="col-sm-3 control-label m-t-sm">사전등록 상단 안내문</label>
                            <div className={cx({ "col-sm-9": true, "col-sm-12": false })}>
                                <SNEditor content={topDesc} onChange={(v) => setTopDesc(v)} />
                            </div>
                        </div>
                        <Separator />
                        <div className="form-group row">
                            <label className="col-sm-3 control-label m-t-sm">사전등록 하단 안내문</label>
                            <div className={cx({ "col-sm-9": true, "col-sm-12": false })}>
                                <SNEditor content={lastDesc} onChange={(v) => setLastDesc(v)} />
                            </div>
                        </div>
                        <Separator />
                        <SegmentedForm title="사전등록 알림메일발송" items={_DEF.STATUS} value={data?.registrationSendYn} onClick={(v) => setDataChange("registrationSendYn", v)} />
                        {data?.registrationSendYn === "Y" && (
                            <>
                                <div className="form-group row">
                                    <div className="col-sm-3">메일양식 변수정보</div>
                                    <div className="col-sm-9">
                                        <div>{"사전등록자명 : #{name}"}</div>
                                        <div>{"휴대전화번호 : #{mobile}"}</div>
                                        <div>{"소속명 : #{companyName}"}</div>
                                        <div>{"진료과 : #{deptName}"}</div>
                                        <div>{"이메일 : #{email}"}</div>
                                        <div>{"이벤트명 : #{eventName}"}</div>
                                        <div>{"접속URL : #{url}"}</div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-3 control-label m-t-sm">등록 시 발송메일</label>
                                    <div className={cx({ "col-sm-9": true, "col-sm-12": false })}>
                                        <SNEditor content={regMailForm} onChange={(v) => setRegMailForm(v)} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-3 control-label m-t-sm">승인 시 발송메일</label>
                                    <div className={cx({ "col-sm-9": true, "col-sm-12": false })}>
                                        <SNEditor content={confirmMailForm} onChange={(v) => setConfirmMailForm(v)} />
                                    </div>
                                </div>
                            </>
                        )}
                        <Separator />
                        <div className="form-group row">
                            <label className="col-sm-3 control-label m-t-sm">추가정보입력</label>
                            <div className={cx({ "col-sm-9": true, "col-sm-12": false })}>
                                <button type="button" className="btn btn-primary" onClick={addColumn}>
                                    <i className="fa fa-plus"></i> 추가
                                </button>
                            </div>
                        </div>

                        {addColumnList?.map((item, i) => (
                            <div key={i} >
                                <Separator />
                                <div className="form-group row" >
                                    <div className="col-sm-3 m-t-sm">
                                        <InputForm title="라벨명" type="text" value={item.labelName} placeholder="라벨명" onChange={(e) => addColumnChange("labelName", i, e.target.value)} />
                                    </div>
                                    <div className={cx({ "col-sm-3": true, "m-t-sm": true })}>
                                        <SegmentedForm title="필수여부" items={_DEF.ESSENTIAL_TYPE} value={item.esYn} onClick={(v) => addColumnChange("esYn", i, v)} />
                                    </div>
                                    <div className={cx({ "col-sm-4": true, "m-t-sm": true })}>
                                        <SegmentedForm title="입력방식" items={_DEF.INPUT_TYPE} value={item.questionType} onClick={(v) => addColumnChange("questionType", i, v)} />
                                    </div>
                                    <div className={cx({ "col-sm-1": true, "m-t-sm": true })}>
                                        <select name="sort" onChange={(e) => addColumnChange("sort", i, e.target.value)} value={item.sort} className="form-control">
                                            {weights.map((i) => (
                                                <option value={i + 1} key={i}>
                                                    {i + 1}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className={cx({ "col-sm-1": true, "m-t-sm": true })}>
                                        <button type="button" className="btn btn-danger" onClick={(e) => delColumn(i)}><i className="fa fa-trash"></i> 삭제</button>
                                    </div>
                                </div>
                                {(item?.questionType === "SINGLE" || item?.questionType === "MULTIPLE") && (
                                    <div>
                                        <div className="form-group row" >
                                            <label className="col-sm-2 control-label m-t-sm">항목추가</label>
                                            <div className={cx({ "col-sm-10": true, "col-sm-12": false })}>
                                                <button type="button" className="btn btn-primary" onClick={addColumnItem.bind(this, i)}>
                                                    <i className="fa fa-plus"></i> 추가
                                                </button>
                                            </div>
                                        </div>
                                        <div className="form-group row" >
                                            {item?.choiceItems?.map((cItem, j) => (
                                                <div className="col-sm-3 m-t-sm" key={j}>
                                                    {/* <Input className="" type="text" value={item.labelName} placeholder="항목명" onChange={(e) => addColumnChange("labelName", i, e.target.value)} /> */}
                                                    <InputForm title={"보기" + (j + 1)} type="text" value={cItem.value} placeholder="보기" onChange={(e) => addColumnItemChange(i, j, e.target.value)} />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                        <Separator />
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <div className="btn-group">
                                    <Button onClick={handleSaveClick} className="btn btn-primary">
                                        {!data.registrationOptIdx ? "생성" : "수정"}
                                    </Button>
                                    <Button onClick={onCancel}>취소</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const EventLinkLoginForm = (props) => {
    const { history, location, match } = props;
    const { siteIdx, siteList, eventInfo } = props;
    const [list, setList] = useState([]);

    const loadList = () => {
        if(!eventInfo?.eventIdx) return;

        const data = {
            eventIdx: eventInfo.eventIdx,
        };
        const req = { path: "/v1/a/event/linklogin/list", data };
        _API.get(req).then((res) => {
            const { list } = res.data;
            setList(list);
        });
    };

    const setDataChange = (index, name, value) => {
        const newList =  [...list];
        const item = newList[index];
        item[name] = value;
        setList(newList);
    };

    const Save = (status, item) => {
        const swalOption = {
            title: `정말 ${status == "D" ? "삭제":item.idx ? "수정":"등록"} 하시겠습니까?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        };
        if(status == "D") swalOption.text = "삭제된 데이터는 복구가 불가합니다 .";
        swal(swalOption).then((willDelete) => {
            if (willDelete) {
                _API.post({ path: `/v1/a/event/linklogin/save`, data: { ...item, status } }).then((res) => {
                    swal({ title: "알림", text: `항목이 ${status == "D" ? "삭제":item.idx ? "수정":"등록"}되었습니다.` }).then(() => {
                        loadList();
                    });
                });
            }
        });
    };

    const addList = () => {
        const newList = [...list];
        newList.push({eventIdx:eventInfo.eventIdx, siteIdx:eventInfo.siteIdx, name:"", accessKey:"", status:"Y"});
        setList(newList);
    }

    useEffect(() => {
        if (eventInfo?.eventIdx) loadList();
    }, [eventInfo]);

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="ibox">
                    <div className="ibox-title">이벤트 외부링크 로그인 설정</div>
                    <div className="ibox-content">
                        <div className="form-group row">
                            <label className="col-sm-3 control-label m-t-sm">링크추가</label>
                            <div className={cx({ "col-sm-9": true, "col-sm-12": false })}>
                                <button type="button" className="btn btn-primary" onClick={addList}>
                                    <i className="fa fa-plus"></i> 추가
                                </button>
                            </div>
                        </div>

                        {list?.map((item, i) => (
                            <div key={i} >
                                <Separator />
                                <div className="form-group row" >
                                    <div className="col-sm-4 m-t-sm">
                                        <InputForm title="링크명" type="text" value={item.name} placeholder="링크명" onChange={(e) => setDataChange(i, "name", e.target.value)} />
                                    </div>
                                    <div className="col-sm-4 m-t-sm">
                                        <InputForm title="접근키" type="text" value={item.accessKey} placeholder="접근키" onChange={(e) => setDataChange(i, "accessKey", e.target.value)} />
                                    </div>
                                    <div className={cx({ "col-sm-4": true, "m-t-sm": true })}>
                                        <div className="btn-group">
                                            <button type="button" className="btn btn-success" onClick={(e) => Save("Y", item)}><i className="fa fa-save"></i> 저장</button>
                                            <button type="button" className="btn btn-danger ml-1" onClick={(e) => Save("D", item)}><i className="fa fa-trash"></i> 삭제</button>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        ))}
                        <Separator />
                    </div>
                </div>
            </div>
        </div>
    );
};