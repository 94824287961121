import React, { useContext, useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import styled from "styled-components";
import moment from "moment";
import Modal from "react-modal";

import { AppContext } from "../../../../components/AppProvider";
import { _API, _U } from "../../../../modules/utils";
import { _DEF } from "../../../../modules/config";
import Socket from "../../../../components/UI/Socket";
import Input, { SearchInput, SelectForm, InputForm, SegmentedForm } from "../../../../components/UI/Form";
import swal from "sweetalert";
import AmazonIVSWorkaround from "../../../../components/AmazonIVSWorkaround";

import { Files, Participant, Quiz, Question, Chatting } from "../../../../components/UI/Monitor";

export default (props) => {
    const { history, location, match } = props;
    const { idx } = match.params;
    const [sessionList, setSessionList] = useState([]);
    const [lectureList, setLectureList] = useState([]);
    const [lectureIdx, setLectureIdx] = useState("");
    const [sessionIdx, setSessionIdx] = useState("");
    const [isConnect, setIsConnect] = useState(false);
    const [sessionTitle, setSessionTitle] = useState("");
    const [lectureTitle, setLectureTitle] = useState("");
    const [channelInfo, setChannelInfo] = useState(null);
    const [isLoadSession, setIsLoadSession] = useState(false);
    const [viewModal, setViewModal] = useState("");
    const [messages, setMessages] = useState([]);

    const debugSocket = false; //_CFG.isLocal();

    const sendChannel = () => {
        _U.postMessage("SOCKET-SEND", {
            action: "CHANNEL",
            data: {
                type: "OPERATOR",
                channel: idx,
            },
        });
    };

    const onConnected = () => {
        setIsConnect(true);
        sendChannel();
    };
    const onDisconnected = () => {
        setIsConnect(false);
    };

    const loadData = (idx) => {
        const req = { path: "/v1/a/event/console", data: { idx, sessionIdx, lectureIdx } };
        _API.get(req).then((res) => {
            const { list, channelInfo, lecture: selectLecture, session: selectSession } = res.data;
            setSessionList(list);
            setChannelInfo(channelInfo);
            setSessionIdx(selectSession?.idx || null);
            setLectureIdx(selectLecture?.idx || null);

            setIsLoadSession(true);
        });
    };

    const onReceivedMessage = (e) => {
        _U.parseMessage(e)
            .then((data) => {
                switch (data.code) {
                    case "SOCKET-RECEIVED":
                        return data.data ? JSON.parse(data.data) : null;
                }
            })
            .then((data) => {
                if (data?.mode === "CHAT") {
                    setMessages((o) => [...o.slice(-99), data]);
                }
            });
    };

    useEffect(() => {
        window.addEventListener("message", onReceivedMessage);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, []);

    useEffect(() => {
        loadData(idx);
    }, [idx]);

    return (
        <Wrapper>
            <div className="consoleItem">
                <Player
                    idx={idx}
                    lectureIdx={lectureIdx}
                    sessionList={sessionList}
                    onSetLectureIdx={setLectureIdx}
                    sessionIdx={sessionIdx}
                    onSetSessionIdx={(value) => {
                        setSessionIdx(value);
                    }}
                    // lectureList={lectureList}
                    // onSetSessionTitle={setSessionTitle}
                    // onSetLectureTitle={setLectureTitle}
                    channelInfo={channelInfo}
                />
                {channelInfo?.quizYn === "Y" &&
                    <Quiz
                        idx={idx} lectureIdx={lectureIdx} sessionIdx={sessionIdx}
                        sessionList={sessionList} channelInfo={channelInfo}
                        onViewModal={(sessionId, lectureId) => {
                            setSessionIdx(sessionId);
                            setLectureIdx(lectureId)
                            setViewModal("Quiz");
                        }}
                    />
                }
            </div>
            <div className="consoleItem">
                {/* <Files idx={idx} lectureIdx={lectureIdx} sessionIdx={sessionIdx} sessionTitle={sessionTitle} lectureTitle={lectureTitle} /> */}
                <Chatting idx={idx} channelInfo={channelInfo}
                    onViewModal={() => {
                        setViewModal("Chatting");
                    }}
                />
                {channelInfo?.qnaYn === "Y" &&
                    <Question idx={idx} lectureIdx={lectureIdx} sessionIdx={sessionIdx}
                        sessionList={sessionList} channelInfo={channelInfo}
                        onViewModal={(sessionId, lectureId) => {
                            setSessionIdx(sessionId);
                            setLectureIdx(lectureId)
                            setViewModal("Question");
                        }}
                    />
                }
            </div>
            <div className="consoleItem">
                <Participant idx={idx} />
            </div>

            <SocketWrapper debug={debugSocket}>
                <Socket debug={debugSocket} onConnected={onConnected} onDisconnected={onDisconnected} />
            </SocketWrapper>
            {channelInfo?.quizYn === "Y" && viewModal === "Quiz" && (
                <Modal isOpen={viewModal ? true : false} style={modalStyles} ariaHideApp={false}>
                    <Quiz idx={idx} lectureIdx={lectureIdx} sessionIdx={sessionIdx}
                        sessionList={sessionList} channelInfo={channelInfo}
                        isModal={true}
                        onViewModal={() => {
                            setViewModal("");
                        }}
                    />
                </Modal>
            )}
            {channelInfo?.qnaYn === "Y" && viewModal === "Question" && (
                <Modal isOpen={viewModal ? true : false} style={modalStyles} ariaHideApp={false}>
                    <Question idx={idx} lectureIdx={lectureIdx} sessionIdx={sessionIdx}
                        sessionList={sessionList} channelInfo={channelInfo}
                        isModal={true}
                        onViewModal={() => {
                            setViewModal("");
                        }}
                    />
                </Modal>
            )}
            {viewModal === "Chatting" && (
                <Modal isOpen={viewModal ? true : false} style={modalStyles} ariaHideApp={false}>
                    <Chatting idx={idx} channelInfo={channelInfo}
                        isModal={true}
                        initMessages={messages}
                        onViewModal={() => {
                            setViewModal("");
                        }}
                    />
                </Modal>
            )}
        </Wrapper>
    );
};

const Player = (props) => {
    const { idx, sessionList, channelInfo } = props;
    const { onSetLectureIdx, onSetSessionIdx } = props;
    // const { idx, sessionList, sessionIdx, lectureIdx, onSetLectureIdx, onSetSessionIdx, lectureList } = props;
    // const { onSetSessionTitle, onSetLectureTitle } = props;

    const [data, setData] = useState(null);

    const [sessionIdx, setSessionIdx] = useState(props.sessionIdx || null);
    const [lectureIdx, setLectureIdx] = useState(props.lectureIdx || null);
    const [lectureList, setLectureList] = useState([]);
    const [streamId, setStreamId] = useState();

    const loadData = () => {
        const req = { path: "/v1/a/event/console/channel", data: { idx } };
        _API.get(req).then((res) => {
            setData(res.data);
        });
    };

    const onPlayLecture = () => {
        if (!sessionIdx) {
            swal("시작할 세션을 선택하세요.");
            return;
        }

        // if (!lectureIdx) {
        //     swal("시작할 강의를 선택하세요.");
        //     return;
        // }
        const validLectureIdx = getValidLectureIdx();

        swal({
            title: "선택한 강의를 시작 하시겠습니까?",
            text: "좌장, 연자 모니터에 출력됩니다.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((result) => {
            if (result) {
                const req = { path: "/v1/a/event/console/active", data: { idx, sessionIdx, lectureIdx: validLectureIdx } };
                _API.post(req).then((res) => {
                    _U.postMessage("SOCKET-SEND", {
                        action: "CHANGE-LECTURE",
                        data: {
                            channel: idx,
                            sessionIdx: sessionIdx,
                            lectureIdx: lectureIdx,
                        },
                    });
                });
            }
        });
    };

    const getValidLectureIdx = () => {
        const session = sessionList?.filter((s) => {
            return s.idx == sessionIdx;
        })[0];
        const lecture = session?.lectureList?.filter((s) => {
            return s.idx == lectureIdx;
        })[0];
        if (lecture) {
            return lectureIdx;
        }
        return "";
    }

    const onReceivedMessage = (e) => {
        _U.parseMessage(e)
            .then((data) => {
                switch (data.code) {
                    case "SOCKET-RECEIVED":
                        return data.data ? JSON.parse(data.data) : null;
                }
            })
            .then((data) => {
                if (data?.mode === "SPEAKER-MONITOR") {
                    // loadData();
                }
                if (data?.mode === "CHANGE-LECTURE") {
                    setSessionIdx(data?.sessionIdx);
                    setLectureIdx(data?.lectureIdx);
                }
            });
    };

    // useEffect(() => {
    //     loadData();
    //     window.addEventListener("message", onReceivedMessage);
    //     return () => {
    //         window.removeEventListener("message", onReceivedMessage);
    //     };
    // }, [idx, sessionIdx, lectureIdx]);

    const isIvs = (playUrl) => {
        if (!playUrl) {
            return;
        }
        return playUrl.includes("live-video.net");
    };

    useEffect(() => {
        setStreamUrl();//순서지켜야 함
        window.addEventListener("message", onReceivedMessage);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, [channelInfo]);

    useEffect(() => {
        setSessionIdx(props.sessionIdx);
    }, [props.sessionIdx]);

    useEffect(() => {
        setLectureIdx(props.lectureIdx);
    }, [props.lectureIdx]);

    useEffect(() => {
        let tmpLectureList = [];
        if (sessionIdx) {
            const session = sessionList.filter((s) => {
                return s.idx == sessionIdx;
            })[0];
            if (session) {
                tmpLectureList = session.lectureList;
            }
        }
        // onSetSessionIdx && onSetSessionIdx(sessionIdx);
        // setLectureIdx(null);
        setLectureList(tmpLectureList);
        setStreamUrl();//순서지켜야 함
    }, [sessionIdx]);

    useEffect(() => {
        // onSetLectureIdx && onSetLectureIdx(lectureIdx);
        setStreamUrl();//순서지켜야 함
    }, [lectureIdx]);

    useEffect(() => {
        setStreamUrl();//순서지켜야 함
    }, [streamId]);

    const setStreamUrl = () => {
        let streamUrl = null;
        let playTarget = "channel";
        let dualStream = false;
        if ((channelInfo?.channelIvs2PlaybackUrl || channelInfo?.channelVod2EncodedUrl) && (channelInfo?.channelIvsPlaybackUrl || channelInfo?.channelVodEncodedUrl)) {
            dualStream = true;
        }

        if (streamId === "Foreign" && (channelInfo?.channelIvs2PlaybackUrl || channelInfo?.channelVod2EncodedUrl)) {
            if (channelInfo?.channelVod2EncodedUrl) {
                console.log("streamType channel Vod 2");
                streamUrl = channelInfo?.channelVod2EncodedUrl;
            } else {
                console.log("streamType channel Stream 2");
                streamUrl = channelInfo?.channelIvs2PlaybackUrl;
            }
        } else {
            if (channelInfo?.channelVodEncodedUrl) {
                console.log("streamType channel Vod 1");
                streamUrl = channelInfo?.channelVodEncodedUrl;
            } else {
                console.log("streamType channel Stream 1");
                streamUrl = channelInfo?.channelIvsPlaybackUrl;
            }
        }

        const session = sessionList?.filter((s) => {
            return s.idx == sessionIdx;
        })[0];
        if (session?.sessionVodEncodedUrl) {
            console.log("streamType session Vod");
            streamUrl = session?.sessionVodEncodedUrl;
            playTarget = "session";
        } else if (session?.sessionIvsPlaybackUrl) {
            console.log("streamType session Stream");
            streamUrl = session?.sessionIvsPlaybackUrl;
            playTarget = "session";
        }

        if (lectureIdx) {
            if (session) {
                const lecture = session.lectureList?.filter((s) => {
                    return s.idx == lectureIdx;
                })[0];
                if (lecture?.lectureVodEncodedUrl) {
                    console.log("streamType lecture Vod");
                    streamUrl = lecture?.lectureVodEncodedUrl;
                    playTarget = "lecture";
                } else if (lecture?.lectureIvsPlaybackUrl) {
                    console.log("streamType lecture Stream");
                    streamUrl = lecture?.lectureIvsPlaybackUrl;
                    playTarget = "lecture";
                }
            }
        }
        console.log("playTarget", playTarget, "streamUrl", streamUrl, "dualStream", dualStream);
        setData({ playbackUrl: streamUrl, playTarget, dualStream });
    }

    return (
        <div className="ibox">
            <div className="ibox-title">
                <h5>강의화면
                    {props?.channelInfo?.title && (
                        <small className="m-l-sm">{props?.channelInfo?.title}</small>
                    )}
                </h5>

                <SessionSelectWrapper>
                    <div className="col-lg-10">
                        <SelectForm
                            name="sessionIdx"
                            items={sessionList.map((session) => {
                                return { label: session.title, value: session.idx };
                            })}
                            value={sessionIdx}
                            onChange={(e) => {
                                // onSetSessionIdx && onSetSessionIdx(e.target.value);
                                // onSetLectureIdx && onSetLectureIdx("");
                                // onSetSessionTitle && onSetSessionTitle(e.target[e.target.selectedIndex].innerText);
                                // onSetLectureTitle && onSetLectureTitle("");
                                setSessionIdx(e.target.value);
                            }}
                            nullTitle="전체 세션"
                        />
                    </div>
                    {/* <div className="col-lg-5">
                        <SelectForm
                            name="lectureIdx"
                            items={lectureList.map((lecture) => {
                                return { label: lecture.title, value: lecture.idx };
                            })}
                            value={lectureIdx}
                            onChange={(e) => {
                                // onSetLectureIdx && onSetLectureIdx(e.target.value);
                                // onSetLectureTitle && onSetLectureTitle(e.target[e.target.selectedIndex].innerText);
                                setLectureIdx(e.target.value);
                            }}
                            nullTitle="전체 강의"
                        />
                    </div> */}
                    <div className="col-lg-2">
                        <button className="btn btn-primary" onClick={onPlayLecture}>
                            <i className="fa fa-play"></i>
                        </button>
                    </div>
                </SessionSelectWrapper>
            </div>
            <div className={cx({ "ibox-content": true, top: true })}>
                {data?.playbackUrl &&
                    <>
                        {isIvs(data?.playbackUrl) ?
                            <AmazonIVSWorkaround url={data?.playbackUrl} />
                            : <video width="320" height="240" src={data?.playbackUrl} controls autoPlay></video>
                        }
                    </>
                }
                {data?.dualStream && data?.playTarget === "channel" && (
                    <>
                        <button className={cx({ btn: true, "btn-xs": true, "btn-default": streamId === "Foreign", "btn-primary": streamId !== "Foreign" })} style={{ marginRight: "8px" }} onClick={() => setStreamId("Native")}>
                            Korean
                        </button>
                        <button className={cx({ btn: true, "btn-xs": true, "btn-default": streamId !== "Foreign", "btn-primary": streamId === "Foreign" })} style={{ marginRight: "8px" }} onClick={() => setStreamId("Foreign")}>
                            English
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

const Wrapper = styled.div`
    position: relative;
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    height: calc(100vh - 1rem - 25px);

    .consoleItem {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0 5px;

        .ibox {
            flex: 1;
            :nth-child(2) {
                margin-top: 30px;
            }
        }
    }
    .ibox-content {
        overflow-x: hidden;
        overflow-y: auto;
        height: 100%;
    }
`;

const SocketWrapper = styled.div`
    position: fixed;
    right: 0;
    bottom: 0;
    ${(props) =>
        props.debug &&
        `
        width:450px; height:250px;
    `}
`;

const SessionSelectWrapper = styled.div.attrs({
    className: "row",
})`
    position: absolute;
    right: 36px;
    top: 8px;
    width: 50%;
`;

const modalStyles = {
    overlay: { zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.75)" },
    content: {
        top: "2%",
        left: "2%",
        right: "2%",
        bottom: "2%",
        borderRadius: "10px",
        paddingBottom: "3rem",
        border: "0",
        overflowX: "hidden",
    },
};
