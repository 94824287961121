import React, { useContext, useState, useEffect, useRef, Fragment } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import swal from "sweetalert";
import Modal from "react-modal";
import readXlsxFile from "read-excel-file";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";

import Layout from "../Layout";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";
import { _DEF } from "../../../modules/config";

import { Separator } from "../../../components/UI/Wrapper";
import Input, { SearchInput, SelectForm, InputForm, SegmentedForm, CheckMultiForm, DatePicker } from "../../../components/UI/Form";
import { ModalCloseButton } from "../../../components/UI/Button";
import Paging from "../../../components/UI/Paging";
import { Theme } from "../../../components/GlobalStyles";

export default (props) => {
    const { history, location, match } = props;

    const [mode, setMode] = useState("");
    const { cfg } = useContext(AppContext);

    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [eventIdx, setEventIdx] = useState("");
    const [siteList, setSiteList] = useState([]);
    const [eventList, setEventList] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [selectItem, setSelectItem] = useState(null);

    const [list, setList] = useState([]);
    const [paging, setPaging] = useState(null);

    const [exportExcelList, setExportExcelList] = useState([]);
    const [registrationItemList, setRegistrationItemList] = useState([]);
    const [eventInfo, setEventInfo] = useState({});
    const [summary, setSummary] = useState({});
    let excel = useRef(null);

    const loadEventList = (siteIdx) => {
        if (!siteIdx) {
            setEventList([]);
            setEventIdx("");
            return;
        }

        const data = {
            siteIdx,
        };

        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list } = res.data;
            setEventList(list);
        });
    };

    const loadList = (page) => {
        const data = {
            siteIdx,
            eventIdx,
            keyword,
            page,
        };
        const req = { path: "/v1/a/event/participant", data };
        _API.get(req).then((res) => {
            const { list, paging, summary } = res.data;
            setList(list);
            setPaging(paging);
            setSummary(summary);
        });
    };

    useEffect(() => {
        loadEventList(siteIdx);
        loadList(1);
    }, []);

    useEffect(() => {
        loadList(1);
    }, [eventIdx])

    const movePage = (page) => {
        loadList(page);
    };

    const onSearch = (e) => {
        loadList(1);
    };

    const onRefresh = () => {
        loadList(paging.pageNo);
    };

    const onSaveChange = () => {
        setMode("");
        onRefresh();
    };

    const onExcelDown = async () => {
        if (!siteIdx || !eventIdx) {
            swal({ title: "알림", text: "이벤트를 선택해 주세요." });
            return;
        }
        const data = {
            siteIdx,
            eventIdx,
            keyword,
            excelDown: "Y",
        };

        const result = await _API.get({ path: `/v1/a/event/info`, data: { eventIdx } });
        if (result && result.data) {
            setEventInfo(result.data);
        }

        const req = { path: "/v1/a/event/participant", data };
        _API.get(req).then((res) => {
            const excelList = res.data.list;
            setExportExcelList(excelList || null);
            setRegistrationItemList(res.data.registrationItemList);
            if (excelList) {
                excel && excel.current.save();
            }
        });
    };

    return (
        <>
            <Layout {...props}>
                <div className="row wrapper border-bottom white-bg page-heading">
                    <div className="col-sm-8">
                        <h2>참여자</h2>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/admin">HOME</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to={`${location.pathname}`}>이벤트</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <strong>참여자</strong>
                            </li>
                        </ol>
                    </div>
                    <div className="col-sm-4">
                        <div className="title-action">
                            <button type="button" className="btn btn-success" onClick={(e) => setMode("MULTI-ADD")}>
                                <i className="fa fa-plus"></i> 일괄등록
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary ml-1"
                                onClick={(e) => {
                                    setMode("SINGLE-ADD");
                                    setSelectItem("");
                                }}
                            >
                                <i className="fa fa-plus"></i> 개별등록
                            </button>
                            <button
                                type="button"
                                className="btn btn-warning ml-1"
                                onClick={(e) => {
                                    onExcelDown();
                                }}
                            >
                                <i className="fa fa-file-excel-o"></i> 엑셀다운
                            </button>
                        </div>
                    </div>
                </div>
                <div className="wrapper wrapper-content">
                    <div className="row">
                        <div className={cx({ "col-md-12": !mode, "col-md-8": mode })}>
                            <div className="ibox">
                                <div className="ibox-title">참여자 목록</div>
                                <div className="ibox-content ibox-heading">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <SelectForm
                                                name="eventIdx"
                                                items={eventList.map((event) => {
                                                    return { label: event.eventName, value: event.idx };
                                                })}
                                                value={eventIdx}
                                                onChange={(e) => {
                                                    setEventIdx(e.target.value);
                                                }}
                                                nullTitle={eventList?.length > 0 ? `이벤트 선택` : `사이트를 먼저 선택하세요.`}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <SearchInput value={keyword} onChange={(e) => setKeyword(e.target.value)} onClick={onSearch} placeholder="검색어를 입력해 주세요." />
                                        </div>
                                    </div>
                                    <div style={{ textAlign: "right", marginRight: "15px" }}>
                                        전체 : {_U.numberFormat(summary?.totalCnt || 0)}명, 사전등록 : {_U.numberFormat(summary?.registrationCnt || 0)}명, 운영등록 : {_U.numberFormat(summary ? (summary?.totalCnt - summary?.registrationCnt) : 0)}명
                                    </div>
                                </div>
                                <div className="ibox-content">
                                    <List
                                        list={list}
                                        onChange={onRefresh}
                                        onModify={(item) => {
                                            setMode("SINGLE-ADD");
                                            setSelectItem(item);
                                        }}
                                    />
                                </div>
                                {paging && (
                                    <div className="ibox-footer">
                                        <Paging paging={paging} pageCallback={movePage} />
                                    </div>
                                )}
                            </div>
                        </div>
                        {mode == "SINGLE-ADD" && (
                            <div className={cx({ "col-md-4": true })}>
                                <Form onChange={onSaveChange} item={selectItem} />
                            </div>
                        )}

                        {mode == "MULTI-ADD" && (
                            <Modal isOpen={mode == "MULTI-ADD"} style={Theme.modal.participantMulti} ariaHideApp={false}>
                                <ModalCloseButton
                                    className="btn btn-default"
                                    onClick={() => {
                                        setMode("");
                                        onRefresh();
                                    }}
                                >
                                    <i className="fa fa-remove"></i>
                                </ModalCloseButton>
                                <MultiForm onChange={onSaveChange} />
                            </Modal>
                        )}
                    </div>
                </div>
                <ExcelDownloader data={exportExcelList} registrationItemList={registrationItemList} eventInfo={eventInfo} ref={excel} />
            </Layout>
        </>
    );
};

const List = (props) => {
    const { list, onChange, onModify } = props;
    return (
        <div className="table-responsive">
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        {/* <th className="text-center">사이트명</th> */}
                        <th className="text-center">이벤트명</th>
                        <th className="text-center">이름</th>
                        <th className="text-center">이메일</th>
                        <th className="text-center">전화번호</th>
                        <th className="text-center">패스코드</th>
                        <th className="text-center">소속</th>
                        {/* <th className="text-center">부서</th>
                        <th className="text-center">직책</th> */}
                        <th className="text-center">면허번호</th>
                        <th className="text-center">등록구분</th>
                        <th className="text-center">등록일</th>
                        <th className="text-center">상태</th>
                        <th className="text-center">관리</th>
                    </tr>
                </thead>
                <tbody>
                    {list.map((item, i) => (
                        <ListItem {...item} key={i} onChange={onChange} onModify={onModify} />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const ListItem = (props) => {
    const { onChange, onModify } = props;

    const onStatusChange = (status, beforeStatus) => {
        if (status === beforeStatus) {
            return;
        }
        swal({
            title: `참여자`,
            text: `상태를 변경하시겠습니까?`,
            icon: "info",
            buttons: ["취소", "예"],
            dangerMode: status === "D" ? true : false,
        }).then((ok) => {
            if (ok) {
                _API.post({
                    path: "/v1/a/event/participant/status",
                    data: {
                        idx: props.idx,
                        siteIdx: props.siteIdx,
                        eventIdx: props.eventIdx,
                        status,
                    },
                }).then((res) => {
                    onChange && onChange();
                });
            }
        });
    };

    const onDelete = (status) => {
        swal({
            title: "정말 삭제 하시겠습니까?",
            text: "삭제된 데이터는 복구가 불가합니다 .",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                _API.delete({
                    path: "/v1/a/event/participant",
                    data: {
                        idx: props.idx,
                        siteIdx: props.siteIdx,
                        eventIdx: props.eventIdx,
                        status,
                    },
                }).then((res) => {
                    onChange && onChange();
                });
            }
        });
    };

    return (
        <tr>
            {/* <td className="text-center">{props.siteName}</td> */}
            <td className="text-center">{props.eventName}</td>
            <td className="text-center">{props.name}</td>
            <td className="text-center">{props.email}</td>
            <td className="text-center">{props.phone}</td>
            <td className="text-center">{props.passCode}</td>
            <td className="text-center">{props.companyName}</td>
            {/* <td className="text-center">{props.deptName}</td>
            <td className="text-center">{props.position}</td> */}
            <td className="text-center">{props.licenseNumber}</td>
            <td className="text-center">{props.regTypeName}</td>
            <td className="text-center">{props.regDate ? moment(props.regDate).format("YYYY-MM-DD") : ""}</td>
            <td className="text-center">
                <div className="btn-group">
                    {_DEF.PARTICIPANT_STATUS.filter(((filterItem, i) => filterItem.value !== "W")).map((item, i) => (
                        <button key={i} type="button" onClick={onStatusChange.bind(this, item.value, props.status)} className={cx({ btn: true, "btn-xs": true, "btn-default": props.status !== item.value, "btn-success": props.status === item.value })}>
                            {item.label}
                        </button>
                    ))}
                </div>
            </td>
            <td className="text-center">
                <div className="btn-group">
                    <button type="button" onClick={onDelete.bind(this, "D")} className={cx({ btn: true, "btn-xs": true, "btn-danger": true })}>
                        <i className="fa fa-trash"></i> 삭제
                    </button>
                    <button type="button" onClick={onModify.bind(this, props)} className={cx({ btn: true, "btn-xs": true, "btn-warning": true })}>
                        <i className="fa fa-edit"></i> 수정
                    </button>
                </div>
            </td>
        </tr>
    );
};

const Form = (props) => {
    const { onChange, item } = props;
    const { cfg } = useContext(AppContext);

    const [siteIdx, setSiteIdx] = useState(cfg.idx);

    const [form, setForm] = useState({
        status: "Y",
        siteIdx: siteIdx,
    });

    const [regTypeName, setRegTypeName] = useState("");
    const [eventList, setEventList] = useState([]);
    const [participantInfoList, setParticipantInfoList] = useState([]);

    const loadRegistrationAddItemList = (item) => {
        if (!item?.eventIdx) {
            setParticipantInfoList([]);
            return;
        }
        const data = {
            participantIdx: item.idx || null,
            eventIdx: item.eventIdx || null,
        };
        const req = { path: "/v1/a/event/participant/item", data };
        _API.get(req).then((res) => {
            const { list } = res.data;
            setParticipantInfoList(_U.jsonParse(JSON.stringify(list)));
        });
    };

    useEffect(async () => {
        await loadEventList(siteIdx);
        if (item) {
            setForm(item);
        } else {
            setForm({ status: "Y", siteIdx: siteIdx, passwdYn: "N" });
        }
    }, [item]);

    useEffect(() => {
        loadRegistrationAddItemList({ eventIdx: form?.eventIdx || null, idx: form?.idx || null });
        reSetPasswdYn();
    }, [form?.eventIdx]);

    const reSetPasswdYn = (list) => {
        const eList = list || eventList;
        if (form?.eventIdx != undefined && eList) {
            let passwdYn = "N";
            for (let i in eList) {
                const event = eList[i];
                if (event.eventIdx == form?.eventIdx) {
                    passwdYn = event.passwdYn || "N";
                    break;
                }
            }
            setForm({ ...form, passwdYn });
        }
    }

    const loadEventList = async (siteIdx) => {
        if (!siteIdx) {
            setEventList([]);
            return;
        }

        const data = {
            siteIdx,
        };

        const req = { path: "/v1/a/event/list", data };
        const res = await _API.get(req);
        if (res) {
            const { list } = res.data;
            setEventList(list);
            reSetPasswdYn(list);
        }
    };

    const onSave = () => {
        const data = { ...form };

        if (!data.siteIdx) {
            swal({ title: "알림", text: "사이트를 선택해 주세요." });
            return;
        }

        if (!data.eventIdx) {
            swal({ title: "알림", text: "이벤트를 선택해 주세요." });
            return;
        }

        if (!data.name) {
            swal({ title: "알림", text: "이름을 입력해 주세요." });
            return;
        }
        if (!data.email) {
            swal({ title: "알림", text: "이메일을 입력해 주세요." });
            return;
        }
        if (!data.phone) {
            swal({ title: "알림", text: "전화번호를 입력해 주세요." });
            return;
        }
        // if (!data.regType) {
        //     swal({ title: "알림", text: "등록 구분을 선택해 주세요." });
        //     return;
        // }
        // if (data.regType === "ETC" && !data.regTypeName) {
        //     swal({ title: "알림", text: "등록 구분을 입력해 주세요." });
        //     return;
        // }
        if (data.passwdYn === "Y" && !data.passwd) {
            swal({ title: "알림", text: "비밀번호를 입력해 주세요." });
            return;
        }

        if (data.regType !== "ETC") {
            data.regTypeName = regTypeName;
        }

        if (!data.siteIdx && siteIdx) {
            data.siteIdx = siteIdx;
        }

        for (let i in participantInfoList) {
            const addItem = participantInfoList[i];
            if (addItem.esYn === "Y" && !addItem.answer) {
                swal(addItem.labelName + "의 값을 입력하세요.");
                return;
            }
        }
        data.participantInfoList = participantInfoList;

        _API.post({
            path: "/v1/a/event/participant",
            data: data,
        }).then((res) => {
            swal({ title: "알림", text: `참여자가 ${!form.idx ? "등록" : "수정"} 되었습니다.` }).then(() => {
                onChange && onChange();
            });
        });
    };

    const changeInfoList = (addItem, value) => {
        const newParticipantInfoList = [];
        for (let i in participantInfoList) {
            const newItem = participantInfoList[i];
            if (addItem.registrationItemIdx === newItem.registrationItemIdx) {
                newItem.answer = value;
            }
            newParticipantInfoList.push(newItem);
        }
        setParticipantInfoList(newParticipantInfoList);
    }

    const changeInfoListFromCheck = (addItem, item) => {
        const newParticipantInfoList = [];
        for (let i in participantInfoList) {
            const newItem = participantInfoList[i];
            if (addItem.registrationItemIdx === newItem.registrationItemIdx) {
                const answers = _U.jsonParse(newItem.answer) || [];
                let newAnswers = [];
                if (item.checked) {
                    answers.push(item.value);
                    newAnswers = [...answers];
                } else {
                    for (let j in answers) {
                        const answer = answers[j];
                        if (answer !== item.value) {
                            newAnswers.push(answer);
                        }
                    }
                }
                newItem.answer = JSON.stringify(newAnswers);
            }
            newParticipantInfoList.push(newItem);
        }
        setParticipantInfoList(newParticipantInfoList);
    }

    return (
        <div className="ibox">
            <div className="ibox-title">참여자 {form?.idx ? "수정" : "등록"}</div>
            <div className="ibox-content">
                {/* <SelectForm
                    title="사이트"
                    name="siteIdx"
                    items={siteList.map((site) => {
                        return { label: site.title, value: site.idx };
                    })}
                    value={form?.siteIdx}
                    onChange={(e) => {
                        setForm({ ...form, eventIdx: "", [e.target.name]: e.target.value });
                        loadEventList(e.target.value);
                    }}
                    nullTitle="사이트 선택"
                /> */}

                <SelectForm
                    title="이벤트"
                    name="eventIdx"
                    items={eventList.map((event) => {
                        return { label: event.eventName, value: event.idx };
                    })}
                    value={form?.eventIdx}
                    onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                    nullTitle={eventList?.length > 0 ? `이벤트 선택` : `사이트를 먼저 선택하세요.`}
                />
                <Separator />
                <InputForm title="이름" name="name" value={form?.name} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="이름을 입력해 주세요" />
                <InputForm title="이메일" name="email" value={form?.email} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="이메일을 입력해 주세요" />
                <InputForm title="전화번호" name="phone" value={form?.phone} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="연락처를 입력해 주세요" />
                {form?.passwdYn === "Y" && (
                    <InputForm title="비밀번호" name="passwd" value={form?.passwd} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="비밀번호를 입력해 주세요" />
                )}
                <InputForm title="소속" name="companyName" value={form?.companyName} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="소속을 입력해 주세요" />
                {/* <InputForm title="부서" name="deptName" value={form?.deptName} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="부서를 입력해 주세요" />
                <InputForm title="직책" name="position" value={form?.position} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="직책을 입력해 주세요" /> */}
                <InputForm title="면허번호" name="licenseNumber" value={form?.licenseNumber} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="면허번호를 입력해 주세요" />
                <SelectForm
                    title="등록구분"
                    name="regType"
                    items={_DEF.PARTICIPANT_REG_TYPE}
                    value={form?.regType}
                    onChange={(e) => {
                        setForm({ ...form, [e.target.name]: e.target.value });
                        setRegTypeName(e.target[e.target.selectedIndex].innerText);
                    }}
                    nullTitle="등록구분 선택"
                />
                {form?.regType === "ETC" && <InputForm title=" " name="regTypeName" value={form?.regTypeName} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="등록구분을 입력해 주세요" />}
                <Separator />
                {participantInfoList?.map((addItem, i) => (
                    <Fragment key={i} >
                        {
                            addItem.questionType === "SUBJECTIVE" && (
                                <InputForm title={addItem.labelName} value={addItem.answer} onChange={(e) => changeInfoList(addItem, e.target.value)} />
                            )
                        }
                        {
                            addItem.questionType === "MULTIPLE" && (
                                <CheckMultiForm title={addItem.labelName} values={_U.jsonParse(addItem.answer)} items={_U.jsonParse(addItem.choiceItems)} onChange={(e) => changeInfoListFromCheck(addItem, e)} />
                            )
                        }
                        {
                            addItem.questionType === "SINGLE" && (
                                <SelectForm title={addItem.labelName} value={addItem.answer} items={_U.jsonParse(addItem.choiceItems)} onChange={(e) => changeInfoList(addItem, e.target.value)} nullTitle={`${addItem.labelName}을 선택해 주세요.`} />
                            )
                        }
                        {
                            addItem.questionType === "DATE" && (
                                <DatePicker title={addItem.labelName} value={addItem.answer} onChange={(e) => changeInfoList(addItem, e.value)} />
                            )
                        }
                    </Fragment>
                ))}
                {/* <SegmentedForm title="상태" items={_DEF.PARTICIPANT_STATUS} value={form?.status} onClick={(v) => setForm({ ...form, status: v })} /> */}
            </div>
            <div className="ibox-footer">
                <div className="row">
                    <div className="col-md-12 text-right">
                        <div className="btn-group">
                            <button type="button" className="btn btn-primary" onClick={onSave}>
                                <i className="fa fa-save"></i> {form?.idx ? "수정" : "등록"}
                            </button>
                            <button type="button" className="btn btn-default" onClick={onChange}>
                                <i className="fa fa-times"></i> 취소
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const MultiForm = (props) => {
    const { onChange } = props;
    const { cfg } = useContext(AppContext);

    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [eventIdx, setEventIdx] = useState("");
    const [eventList, setEventList] = useState([]);
    const [showExample, setShowExample] = useState(false);

    const [excelFile, setExcelFile] = useState(false);
    const [excelList, setExcelList] = useState([]);

    const [check, setCheck] = useState([]);
    const [registrationItemList, setRegistrationItemList] = useState([]);
    const fileInput = useRef();

    const onUpload = async () => {
        if (!siteIdx) {
            swal("등록할 사이트를 선택하세요.");
            return;
        }
        if (!eventIdx) {
            swal("등록할 이벤트를 선택하세요.");
            return;
        }

        const f = excelFile[0];
        const rows = await readXlsxFile(f);
        const filterd = rows.filter((r, i) => i !== 0 && r[0] && r[1] && r[2]);

        const list = filterd.map((item) => {
            const returnItem = {
                name: item[0],
                email: item[1],
                phone: item[2],
                companyName: item[3],
                deptName: item[4],
                position: item[5],
                licenseNumber: item[6],
                regTypeName: item[7],
                regType: _DEF.getDefinedValue(_DEF.PARTICIPANT_REG_TYPE, item[7]) || "ETC",
                passwd:item[8],
            };
            if (item.length > 9) {
                for (let i = 9; i < item.length; i++) {
                    returnItem["addItem_" + i] = item[i];
                }
            }
            return returnItem;
        });
        excelCheck(list);

    };

    const excelCheck = (list) => {
        console.log(list);
        if (!list || list.length === 0) {
            setExcelList([]);
            setRegistrationItemList([]);
            return;
        }
        const req = {
            path: "/v1/a/event/participant/excel/check",
            data: {
                siteIdx,
                eventIdx,
                data: list,
            },
        };
        _API.post(req).then((res) => {
            setExcelList(res.data.list);
            setRegistrationItemList(res.data.registrationItemList);
        });
    }

    const onFileSelect = (e) => {
        setExcelFile(e.target.files);
    };

    const loadEventList = (siteIdx) => {
        if (!siteIdx) {
            setEventList([]);
            return;
        }

        const data = {
            siteIdx,
        };

        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list } = res.data;
            setEventList(list);
        });
    };

    const onCheck = (checked, value) => {
        if (checked) {
            setCheck([...check, value]);
        } else {
            setCheck(check.filter((el) => el !== value));
        }
    };

    const onCheckRemove = () => {
        const tmpList = JSON.parse(JSON.stringify(excelList));

        const tmpResult = tmpList.filter((t, i) => {
            return !check.includes(i);
        });
        setCheck([]);
        excelCheck(tmpResult);
    };

    const onSave = (status) => {
        if (excelList?.length === 0) {
            swal("등록대상이 없습니다.");
            return;
        }
        for (let i in excelList) {
            if (excelList[i].isExcelExsits === "Y" || excelList[i].isDBExsits === "Y") {
                swal("중복된 데이터가 존재합니다.\n중복제거 후 등록해주세요.");
                return;
            }
        }
        const req = {
            path: "/v1/a/event/participant/excel",
            data: {
                siteIdx,
                eventIdx,
                status,
                data: excelList,
            },
        };
        _API.post(req).then((res) => {
            console.log(res);
            const { data } = res;
            swal(`일괄 등록 되었습니다. ${data.success}건 성공, ${data.fail}건 실패`).then(() => {
                onChange && onChange();
            });
        });
    };

    useEffect(() => {
        loadEventList(siteIdx);
    }, []);

    return (
        <>
            <h2>일괄등록</h2>
            <div className="ibox mt-5">
                <div className="row">
                    <div className="col-md-12 text-right">
                        <button
                            className="btn btn-warning"
                            onClick={() => {
                                setShowExample(!showExample);
                            }}
                        >
                            <i className="fa fa-question-circle"></i> 예시
                        </button>
                        <a
                            className="btn btn-primary ml-1"
                            href="/participant_upload_sample.xlsx"
                            target="_blank"
                        >
                            <i className="fa fa-file-excel-o"></i> 샘플파일 다운
                        </a>
                    </div>
                </div>
                {showExample && (
                    <div className="ibox-content ibox-title mt-2">
                        <ExcelExample />
                    </div>
                )}
                <div className="ibox-content ibox-heading mt-2">
                    <div className="row">
                        <div className="col-md-4">
                            <SelectForm
                                title="이벤트"
                                name="eventIdx"
                                items={eventList.map((event) => {
                                    return { label: event.eventName, value: event.idx };
                                })}
                                value={eventIdx}
                                onChange={(e) => {
                                    setEventIdx(e.target.value);
                                    fileInput.current.value = '';
                                    setExcelList([]);
                                    setRegistrationItemList([]);
                                }}
                                nullTitle={eventList?.length > 0 ? `이벤트 선택` : `사이트를 먼저 선택하세요.`}
                            />
                        </div>
                        <div className="col-md-4">
                            <div className="input-group">
                                <Input name={props.name} type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={onFileSelect.bind(this)} ref={fileInput} />

                                <div className="input-group-append">
                                    <button type="button" className="btn btn-default" onClick={onUpload.bind(this)}>
                                        업로드
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ibox-content">
                    <div className="row">
                        <div className="col-md-8 text-danger"></div>
                        <div className="col-md-4 text-right">
                            <button className="btn btn-danger" onClick={onCheckRemove}>
                                <i className="fa fa-trash"></i> 체크삭제
                            </button>
                            {/* <button className="btn btn-success ml-1" onClick={onSave.bind(this, "Y")}>
                                <i className="fa fa-edit"></i> 대기 등록
                            </button> */}
                            <button className="btn btn-primary ml-1" onClick={onSave.bind(this, "Y")}>
                                <i className="fa fa-edit"></i> 승인 등록
                            </button>
                        </div>
                    </div>
                    <div className="table-responsive mt-2" style={{ maxHeight: "300px" }}>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th className="text-center">체크</th>
                                    <th className="text-center">이름</th>
                                    <th className="text-center">이메일</th>
                                    <th className="text-center">전화번호</th>
                                    <th className="text-center">비밀번호</th>
                                    <th className="text-center">소속</th>
                                    <th className="text-center">부서</th>
                                    <th className="text-center">직책</th>
                                    <th className="text-center">면허번호</th>
                                    <th className="text-center">등록구분</th>
                                    {registrationItemList?.map((item, i) => (
                                        <th key={i} className="text-center">{item.labelName}</th>
                                    ))}
                                    <th className="text-center">파일내중복</th>
                                    <th className="text-center">DB중복</th>
                                </tr>
                            </thead>
                            <tbody>
                                {excelList?.map((item, i) => (
                                    <tr key={i} className={cx({ "bg-warning": item.isExcelExsits === "Y", "bg-danger": item.isDBExsits === "Y" })}>
                                        <td className="text-center">
                                            <input
                                                type="checkbox"
                                                onChange={(e) => {
                                                    onCheck(e.target.checked, i);
                                                }}
                                                value={i}
                                                checked={check.includes(i)}
                                            />
                                        </td>
                                        <td className="text-center">{item.name}</td>
                                        <td className="text-center">{item.email}</td>
                                        <td className="text-center">{item.phone}</td>
                                        <td className="text-center">{item.passwd}</td>
                                        <td className="text-center">{item.companyName}</td>
                                        <td className="text-center">{item.deptName}</td>
                                        <td className="text-center">{item.position}</td>
                                        <td className="text-center">{item.licenseNumber}</td>
                                        <td className="text-center">{item.regTypeName}</td>
                                        {registrationItemList?.map((rItem, r) => (
                                            <td key={r} className="text-center">{item["addItem_" + (r + 8)]}</td>
                                        ))}
                                        <td className="text-center">
                                            {item.isExcelExsits}
                                            <div>{item.excelExsitsMsg}</div>
                                        </td>
                                        <td className="text-center">
                                            {item.isDBExsits}
                                            <div>{item.dbExsitsMsg}</div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

const ExcelExample = () => {
    return (
        <div className="table-responsive">
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th className="text-center">이름</th>
                        <th className="text-center">이메일</th>
                        <th className="text-center">전화번호</th>
                        <th className="text-center">비밀번호</th>
                        <th className="text-center">소속</th>
                        <th className="text-center">부서</th>
                        <th className="text-center">직책</th>
                        <th className="text-center">면허번호</th>
                        <th className="text-center">등록구분</th>
                        <th className="text-center">추가정보1</th>
                        <th className="text-center">...</th>
                        <th className="text-center">추가정보N</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="text-center">홍길동</td>
                        <td className="text-center">test@gmail.com</td>
                        <td className="text-center">010-0000-1111</td>
                        <td className="text-center">password1234</td>
                        <td className="text-center">서울대학교병원</td>
                        <td className="text-center">내과</td>
                        <td className="text-center">과장</td>
                        <td className="text-center">112233</td>
                        <td className="text-center">봉직의</td>
                        <td className="text-center">1추가정보</td>
                        <td className="text-center">...</td>
                        <td className="text-center">N추가정보</td>
                    </tr>
                    <tr>
                        <td className="text-center">임꺽정</td>
                        <td className="text-center">test2@gmail.com</td>
                        <td className="text-center">010-1111-0000</td>
                        <td className="text-center">password1234</td>
                        <td className="text-center">oo병원</td>
                        <td className="text-center">소아과</td>
                        <td className="text-center">병원장</td>
                        <td className="text-center">332211</td>
                        <td className="text-center">개원의</td>
                        <td className="text-center">1추가정보</td>
                        <td className="text-center">...</td>
                        <td className="text-center">N추가정보</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

class ExcelDownloader extends React.Component {
    constructor(props) {
        super(props);

        const state = {
            list: props.data || [],
            excelName: props.eventName,
            registrationItemList: props.registrationItemList,
            eventInfo: props.eventInfo,
        };
        this.state = state;
    }

    componentWillReceiveProps = (props) => {
        this.setState({ list: props.data, excelName: props.excelName, registrationItemList: props.registrationItemList, eventInfo: props.eventInfo });
    };

    save() {
        this.excel && this.excel.save();
    }

    getList() {
        const { list } = this.state;
        for (let i in list) {
            const item = list[i];
            item.regDate = item.regDate ? moment(item.regDate).format("YYYY-MM-DD HH:mm:ss") : "";
            item.statusName = item.status ? (item.status === "Y" ? "승인" : item.status === "W" ? "대기" : "거절") : "";
            item.phone = _U.phoneFomatter(item.phone);
            for (let j in item.answers) {
                const answerItem = item.answers[j];
                item[`question_${answerItem.idx}`] = answerItem.answer;
            }
        }

        return list;
    }

    getAddQuestion() {
        const { registrationItemList } = this.state;
        const list = [];
        for (let i in registrationItemList) {
            const item = registrationItemList[i];
            list.push(<ExcelExportColumn field={`question_${item.idx}`} title={`${item.labelName}`} width={250} />);
        }
        return list;
    }

    getOptionalColumn() {
        const { eventInfo } = this.state;
        const list = [];
        if (eventInfo.nameYn != "N") list.push(<ExcelExportColumn field="name" title="이름" width={200} />);
        if (eventInfo.emailYn != "N") list.push(<ExcelExportColumn field="email" title="이메일" width={250} />);
        if (eventInfo.phoneYn != "N") list.push(<ExcelExportColumn field="phone" title="전화번호" width={250} />);
        if (eventInfo.companyYn != "N") list.push(<ExcelExportColumn field="companyName" title={eventInfo.companyLabelName || "소속"} width={250} />);
        if (eventInfo.licenseYn != "N") list.push(<ExcelExportColumn field="licenseNumber" title="면허번호" width={250} />);
        if (eventInfo.regTypeYn != "N") list.push(<ExcelExportColumn field="regTypeName" title="등록구분" width={250} />);
        return list;
    }

    render() {
        const excelName = this.state.excelName || "참가자정보";
        const list = this.getList();
        const today = moment().format("YYYYMMDDHHmmss");
        if (!list) {
            return "";
        }

        return (
            <ExcelExport data={list} fileName={`${excelName}_${today}.xlsx`} ref={(ref) => (this.excel = ref)}>
                <ExcelExportColumn field="siteName" title="사이트명" />
                <ExcelExportColumn field="eventName" title="이벤트명" width={250} />
                {/* <ExcelExportColumn field="name" title="이름" width={200} />
                <ExcelExportColumn field="email" title="이메일" width={250} />
                <ExcelExportColumn field="phone" title="전화번호" width={250} />
                <ExcelExportColumn field="companyName" title="소속" width={250} />
                <ExcelExportColumn field="licenseNumber" title="면허번호" width={250} />
                <ExcelExportColumn field="regTypeName" title="등록구분" width={250} /> */}
                {this.getOptionalColumn().map((item, i) => (
                    <Fragment key={i}>
                        {item}
                    </Fragment>
                ))}
                {this.getAddQuestion().map((item, i) => (
                    <Fragment key={i}>
                        {item}
                    </Fragment>
                ))}
                <ExcelExportColumn field="regDate" title="등록일자" width={250} />
                <ExcelExportColumn field="statusName" title="상태" width={250} />
            </ExcelExport>
        );
    }
}