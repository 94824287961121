import React, { Fragment, useContext, useEffect, useState } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";

import Board from "./admin/Board";
import EmergencyBoard from "./admin/EmergencyBoard";
import { AppContext } from "../components/AppProvider";
import { _U, _CK, _API } from "../modules/utils";

// Admin
import { AdminLoginView, AdminDashboardView, AdminBoardView, AdminBoardDetailView } from "./admin";
import {
	AdminDevDashboardView,
	AdminDevAPIEncoderView,
	AdminDevUploadExampleView,
	AdminDevUploadVideoView,
	AdminDevWysiwygView,
	AdminDevSocketView,
	AdminDevSampleView,
} from "./admin/dev";
import {
	AdminEventListView,
	AdminEventFormView,
	AdminEventContentListView,
	AdminEventEboothListView,
	AdminEventEboothFormView,
	AdminEventChannelView,
	AdminEventSessionView,
	AdminEventLectureView,
	AdminEventVodLogView,
	AdminEventAbstractView,
	AdminEventEboothMain,
	AdminEventEboothMainFormView,
} from "./admin/event";
import AdminMemberListView from "./admin/member/MemberList";
import {
	AdminEventSurveyView,
	AdminEventQuizView,
	AdminEventQnaView,
	AdminEventRegistrationView,
	AdminEventParticipantView,
	EventCompanyView,
	EventCompanyFormView,
	EventCompanySatelliteFormView,
} from "./admin/event";

import { LecturerListView, CareerListView } from "./admin/lecturer";
import { LocationListView, LocationDetailListView } from "./admin/location";

import {
	EventConsoleMonitorView,
	EventConsolePrincipalView,
	EventConsoleSpeakerView,
	EventConsoleQuizView,
	EventConsoleQuestionView,
	EventConsoleSurveyView,
} from "./admin/event/console";

import { AttendListView, EboothAccessListView, EboothAttendView } from "./admin/member";

import AdminStatistics from "./admin/Statistics";
import SendSms from "./admin/send/sms";
import SendMail from "./admin/send/mail";
import SendStatistics from "./admin/send/statistics";

// Template
import { TplTestViews, TplBasicViews, TplEboothViews } from "./template";

export default (props) => {
	const { auth, cfg } = useContext(AppContext);
	const { history, location, match } = props;

	useEffect(() => {}, [auth]);

	const isLogin = _CK.isLogin();

	if (!_CK.isLogin() && window.location.hash.includes("admin")) {
		return <AdminLoginView />;
	}

	if (!cfg) {
		return (
			<Router>
				<Switch>
					<Route path="/" exact component={Root} />
					<Redirect path="*" to="/" />
				</Switch>
			</Router>
		);
	}

	if (!window.location.hash.includes("admin")) {
		const { event } = cfg;
		if (event)
			switch (event.templeteCode) {
				case "TEST":
					return <TplTestViews />;
				case "BASIC":
					return <TplBasicViews />;
				case "EBOOTH":
					return <TplEboothViews />;
				default:
					return <TplBasicViews />;
			}
		else return <div>이벤트가 존재하지 않습니다.</div>;
	}

	return (
		<Router>
			<Switch>
				{!isLogin ? (
					<Route path="/admin" component={AdminLoginView} />
				) : (
					<Route path="/admin">
						<Route path="/admin" exact render={() => <Redirect to="/admin/dashboard" />} />
						<Route path="/admin/dashboard" component={AdminDashboardView} />
						<Route path="/admin/member" component={AdminMemberListView} />
						<Route path="/admin/board" exact component={AdminBoardView} />
						<Route path="/admin/board/detail" component={AdminBoardDetailView} />
						<Route path="/admin/dev">
							<Route path="/admin/dev/dashboard" component={AdminDevDashboardView} />
							<Route path="/admin/dev/api/encoder" component={AdminDevAPIEncoderView} />
							<Route path="/admin/dev/upload/example" component={AdminDevUploadExampleView} />
							<Route path="/admin/dev/upload/video" component={AdminDevUploadVideoView} />
							<Route path="/admin/dev/wysiwyg" component={AdminDevWysiwygView} />
							<Route path="/admin/dev/socket" component={AdminDevSocketView} />
							<Route path="/admin/dev/sample" component={AdminDevSampleView} />
						</Route>

						<Route path="/admin/lecturer">
							<Route path="/admin/lecturer/list" component={LecturerListView} exact />
							<Route path="/admin/lecturer/:lecturerIdx/career" component={CareerListView} />
						</Route>
						<Route path="/admin/location">
							<Route path="/admin/location/list" component={LocationListView} exact />
							<Route path="/admin/location/:locationIdx/detail/list" component={LocationDetailListView} />
						</Route>

						<Route path="/admin/event/list" component={AdminEventListView} exact />
						<Route path="/admin/event/form" component={AdminEventFormView} exact />
						<Route path="/admin/event/form/:eventIdx" component={AdminEventFormView} exact />
						<Route path="/admin/event/content" component={AdminEventContentListView} exact />
						<Route path="/admin/event/ebooth" component={AdminEventEboothListView} exact />
						<Route path="/admin/event/ebooth/form" component={AdminEventEboothFormView} exact />
						<Route path="/admin/event/ebooth/form/:eboothIdx" component={AdminEventEboothFormView} exact />
						<Route path="/admin/event/ebooth/company/content/:companyIdx" component={EventCompanyFormView} exact />
						<Route path="/admin/event/ebooth/company" component={EventCompanyView} exact />
						<Route path="/admin/event/ebooth/company/satellite/:companyIdx" component={EventCompanySatelliteFormView} exact />

						<Route path="/admin/event/ebooth/main" component={AdminEventEboothMain} exact />
						<Route path="/admin/event/ebooth/main/:eventIdx" component={AdminEventEboothMainFormView} exact />

						<Route path="/admin/event/channel" component={AdminEventChannelView} exact />
						<Route path="/admin/event/channel/session" component={AdminEventSessionView} exact />
						<Route path="/admin/event/channel/session/lecture" component={AdminEventLectureView} exact />
						<Route path="/admin/event/channel/session/vod/log" component={AdminEventVodLogView} exact />

						<Route path="/admin/emergency" component={EmergencyBoard} />
						<Route path="/admin/bbs/:bbsCategory" component={Board} />

						<Route path="/admin/event/channel/survey" component={AdminEventSurveyView} exact />
						<Route path="/admin/event/channel/quiz" component={AdminEventQuizView} exact />
						<Route path="/admin/event/channel/qna" component={AdminEventQnaView} exact />
						<Route path="/admin/event/registration" component={AdminEventRegistrationView} exact />
						<Route path="/admin/event/participant" component={AdminEventParticipantView} exact />
						<Route path="/admin/event/abstract" component={AdminEventAbstractView} />
						{/* <Route path="/admin/event/company" component={AdminEventCompanyView} /> */}
						<Route path="/admin/attend/list" component={AttendListView} exact />
						<Route path="/admin/company/access/list" component={EboothAccessListView} exact />
						<Route path="/admin/company/attend" component={EboothAttendView} exact />

						<Route path="/admin/event/console">
							<Route path="/admin/event/console/monitor/:idx" component={EventConsoleMonitorView} />
							<Route path="/admin/event/console/principal/:idx" component={EventConsolePrincipalView} />
							<Route path="/admin/event/console/speaker/:idx" component={EventConsoleSpeakerView} />
							<Route path="/admin/event/console/quiz/:idx" component={EventConsoleQuizView} />
							<Route path="/admin/event/console/question/:idx" component={EventConsoleQuestionView} />
							<Route path="/admin/event/console/survey/:idx" component={EventConsoleSurveyView} />
						</Route>

						<Route path="/admin/statistics/attend" component={AdminStatistics} />
						<Route path="/admin/send/manage/sms" component={SendSms} />
						<Route path="/admin/send/manage/mail" component={SendMail} />
						<Route path="/admin/send/manage/statistics" component={SendStatistics} />
					</Route>
				)}
				<Redirect path="*" to="/" />
			</Switch>
		</Router>
	);
};
