import React, { Fragment, useContext, useEffect, useState } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";
import styled, { css } from "styled-components";
import moment from "moment";
import { isMobile } from "react-device-detect";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U, _CK } from "../../../modules/utils";
import { Loading } from "../../../components/UI/Loading";

import { H2, H3, ItemEmpty } from "./UI/Components";

export default (props) => {
    const { cfg, auth, iam } = useContext(AppContext);
    const event = cfg.event || null;
    const { siteIdx, eventIdx, startDate, endDate, attendDesc } = event;

    const [isLoading, setIsLoading] = useState(true);
    const [channelDateList, setChannelDateList] = useState(event.channel?.map(item => { return item.progressDate }).sort((a, b) => (a > b ? 1 : a < b ? -1 : 0)));
    const [selectDate, setSelectDate] = useState(channelDateList?.filter(item => item === moment().format("YYYYMMDD"))[0] || channelDateList[0]);

    const [channel, setChannel] = useState([]);
    const [selectChannel, setSelectChannel] = useState(null);
    const [attends, setAttends] = useState([]);
    const [loginInfo, setLoginInfo] = useState({});

    useEffect(() => {
        loadLoginInfo();
    }, [])

    const loadAttend = () => {
        _API.get({
            path: "/v1/site/event/attend",
            data: {
                siteIdx,
                eventIdx,
                date: selectDate,
                channelIdx: selectChannel,
            },
        }).then((res) => {
            const { channels, attends } = res.data;

            if (!selectChannel && selectDate) {
                setChannel(channels);
            }
            if (channels.length > 0 && !selectChannel) {
                setSelectChannel(channels[0]?.idx);
            }
            setAttends(attends);
            setIsLoading(false);
        });
    };

    const loadLoginInfo = () => {
        _API.get({
            path: "/v1/site/event/login/info",
            data: {
                siteIdx,
                eventIdx,
            },
        }).then((res) => {
            const { loginInfo } = res.data;
            setLoginInfo(loginInfo);
        });
    };

    useEffect(() => {
        setIsLoading(true);
        if (selectDate) {
            loadAttend();
        }
    }, [selectDate, selectChannel]);

    const getDateTab = () => {
        const result = [];
        const tmpChannelDateList = [];
        for (let i in channelDateList) {
            const item = channelDateList[i];
            let dup = 0;
            for (let j in tmpChannelDateList) {
                if (tmpChannelDateList[j] === item) {
                    dup++;
                }
            }
            if (dup === 0) {
                tmpChannelDateList.push(item);
            }
        }

        for (let i in tmpChannelDateList) {
            const m = tmpChannelDateList[i];
            result.push(
                <DateTab
                    ison={selectDate == m}
                    key={i}
                    onClick={() => {
                        setSelectDate(m);
                        setSelectChannel(null);
                        setAttends([]);
                    }}
                >
                    {moment(m).format("YYYY.MM.DD(ddd)")}
                </DateTab>
            );
        }

        // if (isMobile) {
        //     result.push(<ItemEmpty length={channelDateList?.length || 0} number={3} component={<DateTab />} />);
        // }

        return result;
    };

    const getAttendTime = (attendTime) => {
        const time = moment.duration(attendTime, "seconds");
        const viewTime = [];
        viewTime.push(_U.lpad(time.hours(), 2, "0"));
        viewTime.push(":");
        viewTime.push(_U.lpad(time.minutes(), 2, "0"));
        viewTime.push(":");
        viewTime.push(_U.lpad(time.seconds(), 2, "0"));
        return viewTime.join("");
    };

    const getAttendTotalTime = () => {
        let attendTime = 0;

        for (let i in attends) {
            attendTime += attends[i].attendTime;
        }

        const time = moment.duration(attendTime, "seconds");
        const viewTime = [];
        viewTime.push(_U.lpad(time.hours(), 2, "0"));
        viewTime.push(":");
        viewTime.push(_U.lpad(time.minutes(), 2, "0"));
        viewTime.push(":");
        viewTime.push(_U.lpad(time.seconds(), 2, "0"));
        return viewTime.join("");
    };

    if (isLoading) {
        return <Loading background="rgba(0, 0, 0, 0.1)" />;
    }

    return (
        <>
            <Wrapper>
                <H2>참여시간</H2>
                {attendDesc && (<AttendDescWrapper dangerouslySetInnerHTML={{ __html: attendDesc || "" }} />)}
                <TabWrapper>{getDateTab()}</TabWrapper>

                {channel?.length > 0 && (
                    <ChannelWrapper>
                        {channel?.map((c, i) => (
                            <CItem
                                key={i}
                                ison={selectChannel === c.idx}
                                onClick={() => {
                                    setSelectChannel(c.idx);
                                    setAttends([]);
                                }}
                            >
                                {c.title}
                            </CItem>
                        ))}
                    </ChannelWrapper>
                )}
                <table>
                    <thead>
                        <tr>
                            <th>세션</th>
                            <th width={"20%"}>입장</th>
                            <th width={"20%"}>퇴장</th>
                            <th width={"20%"}>참여시간</th>
                        </tr>
                    </thead>
                    <tbody>
                        {attends?.length > 0 ? (
                            <>
                                {attends?.map((item, i) => (
                                    <tr key={i}>
                                        <td>
                                            {item.sessionTitle}
                                            {isMobile && <br />}
                                            <span>
                                                ({item.sessionStartTime}~{item.sessionEndTime})
                                            </span>
                                        </td>
                                        <td>{item.startDate && moment(item.startDate).format("HH:mm:ss")}</td>
                                        <td>{item.endDate && moment(item.endDate).format("HH:mm:ss")}</td>
                                        <td>{getAttendTime(item.attendTime)}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td>총 참여시간</td>
                                    <td colSpan={3}>{getAttendTotalTime()}</td>
                                </tr>
                            </>
                        ) : (
                            <tr>
                                <td colSpan={4}>검색된 출결 현황이 없습니다</td>
                            </tr>
                        )}
                    </tbody>
                </table>

                <table>
                    <thead>
                        <tr>
                            <th width={"50%"}>최초로그인</th>
                            <th width={"50%"}>최종로그인</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{loginInfo.firstLoginDate ? moment(loginInfo.firstLoginDate).format("YYYY.MM.DD HH:mm:ss") : ""}</td>
                            <td>{loginInfo.lastLoginDate ? moment(loginInfo.lastLoginDate).format("YYYY.MM.DD HH:mm:ss") : ""}</td>
                        </tr>
                    </tbody>
                </table>

            </Wrapper>
        </>
    );
};

const Wrapper = styled.div`
    width: 1200px;
    margin: 0 auto;
    padding-top: 3rem;
    // min-height: calc(100% - 192px);
    height: auto;

    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                width: 100%;
                padding: 1rem;
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                width: 100%;
                padding: 1rem;
            }
        `;
        }
    }}

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        width: 100%;
        padding: 1rem;
    }

    table {
        width: 100%;
        margin-top: 10px;

        thead {
            border-top: 1px solid ${(props) => props.theme.basic.line.agenda};
            border-bottom: 1px solid ${(props) => props.theme.basic.line.agenda};

            th {
                background-color: ${(props) => props.theme.basic.bg.agenda};
                color: ${(props) => props.theme.basic.color.title};
                font-size: 16px;
                text-align: center;
                font-family: ${(props) => props.theme.fontFamily.medium};
                height: 45px;
            }
        }

        tbody {
            border-bottom: 1px solid ${(props) => props.theme.basic.line.agenda};
            tr {
                border-bottom: 1px solid ${(props) => props.theme.basic.line.tr};
            }
            tr > td {
                height: 66px;
                font-size: 16px;

                ${props => {
        if (isMobile) {
            return css`
                        @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                            font-size: 14px;
                        }
                        @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                            font-size: 14px;
                        }
                    `;
        }
    }}
                @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
                    font-size: 14px;
                }

                :first-child {
                    color: ${(props) => props.theme.basic.color.title};
                    border-right: 1px solid ${(props) => props.theme.basic.line.tr};

                    :after {
                        content: "";
                        display: none;
                    }
                }

                color: ${(props) => props.theme.color.primary};
                font-family: ${(props) => props.theme.fontFamily.medium};
                text-align: center;
                position: relative;

                :after {
                    content: "";
                    position: absolute;
                    background-color: ${(props) => props.theme.basic.line.tr};
                    width: 1px;
                    height: 15px;
                    right: 0;
                    top: 25px;
                }

                :last-child {
                    :after {
                        content: "";
                        display: none;
                    }
                }

                span {
                    color: ${(props) => props.theme.basic.color.title};
                    font-family: ${(props) => props.theme.fontFamily.regular};
                    font-size: 14px;
                }
            }
        }
    }
`;

const TabWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap;
`;
const DateTab = styled.div`
    flex: 1;
    margin-right: 10px;
    font-size: 16px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 14px;
    cursor: pointer;

    ${(props) =>
        props.ison
            ? `
    color: ${props.theme.basic.color.white};    
    background-color: ${props.theme.basic.bg.default};
    border-radius:21px;
    `
            : `
    color: ${props.theme.basic.color.description};
    `}

    font-family: ${(props) => props.theme.fontFamily.regular};
    text-align: center;
    // :last-child {
    //     margin-right: 0px;
    // }
`;

const ChannelWrapper = styled.ul`
    padding: 0px;
    margin: 24px 0 0 0px;
    list-style-type: none;
    display: block;
`;
const CItem = styled.li`
    display: inline-block;
    position: relative;
    padding: 0 15px;
    font-size: 14px;
    cursor: pointer;

    ${(props) =>
        props.ison
            ? `
    color: ${props.theme.basic.color.title};
    font-family: ${props.theme.fontFamily.medium};    
    `
            : `
    color: ${props.theme.basic.color.off};
    font-family: ${props.theme.fontFamily.regular};
    `}

    :last-child {
        :after {
            content: unset;
        }
    }

    :after {
        content: "";
        position: absolute;
        top: 6px;
        right: 0;
        border-right: 1px solid ${(props) => props.theme.basic.line.bar};
        height: 50%;
    }
`;

const EmptyWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${(props) => (props.height ? props.height : `calc(100% - 28px - 2rem)`)};
`;

const AttendDescWrapper = styled.div`
    padding-left: 10px;
    line-height: 0.8rem;
    margin-bottom: 1.5rem;
`