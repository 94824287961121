import React, { useContext, Fragment, useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import swal from "sweetalert";

import Layout from "./Layout";

import { AppContext } from "../../components/AppProvider";
import { _DEF } from "../../modules/config";
import { _API, _U } from "../../modules/utils";

import Wrapper, { Row, FormGroup, Separator } from "../../components/UI/Wrapper";
import { SearchInput, SelectForm, InputForm, SegmentedForm, SearchCondition } from "../../components/UI/Form";
import SNEditor from "../../components/UI/SNEditor";

import Paging, { ToPage, GetParams } from "../../components/UI/Paging";

export default (props) => {

    const { history, location, match } = props;
    const cp = { history, location, match };

    const [mode, setMode] = useState("");

    const { cfg } = useContext(AppContext);

    const [siteList, setSiteList] = useState([]);
    const [eventList, setEventList] = useState([]);
    const [siteIdx, setSiteIdx] = useState(cfg.idx);

    const loadEventList = (page) => {
        const data = {
            // siteIdx,
        };
        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setEventList(list);
        });
    };

    const handleAddClick = () => {
        setMode("ADD");
    };

    const handleModifyClick = (idx) => {
        setMode(idx);
    };

    const handleDeleteClick = (idx) => {
        swal({
            title: "정말 삭제 하시겠습니까?",
            text: "삭제된 데이터는 복구가 불가합니다 .",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                _API.delete({ path: `/v1/a/board/${idx}` }).then((res) => {
                    swal({ title: "알림", text: `게시판이 삭제되었습니다.` }).then(() => {
                        _U.postMessage("NOTICE-CHANGED");
                    });
                });
            }
        });
    };

    const onBoardChange = () => {
        setMode("");
        _U.postMessage("NOTICE-CHANGED");
    };

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-8">
                    <h2>긴급공지</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/admin">HOME</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>긴급공지</strong>
                        </li>
                    </ol>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className={cx({ "col-md-12": !mode, "col-md-8": mode })}>
                        <List {...cp} mode={mode}
                            listTitle={"긴급공지"}
                            handleModifyClick={handleModifyClick}
                            handleDeleteClick={handleDeleteClick}
                            bbsCategory={"EMERGENCY"}
                            siteList={siteList}
                            eventList={eventList}
                            history={history}
                        />
                    </div>
                    {mode && (
                        <div className={cx({ "col-md-4": true })}>
                            <BoardForm idx={mode} onChange={onBoardChange} siteList={siteList} eventList={eventList} bbsCategory={"EMERGENCY"} history={history} />
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
};


const List = (props) => {
    const { mode, listTitle, handleModifyClick, handleDeleteClick, bbsCategory, siteList, eventList, history } = props;

    const { cfg } = useContext(AppContext);

    const [keyword, setKeyword] = useState("");
    const [list, setList] = useState([]);
    const [paging, setPaging] = useState(null);
    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [eventIdx, setEventIdx] = useState(null);
    const [channelIdx, setChannelIdx] = useState(null);

    const loadList = (page) => {
        _API.get({
            path: "/v1/a/board",
            data: {
                keyword,
                bbsCategory,
                page,
                siteIdx,
                eventIdx,
                channelIdx,
            },
        }).then((res) => {
            const { paging, list } = res.data;
            setList(list);
            setPaging(paging);
        });
    };

    const onSearch = (e) => {
        loadList(paging?.pageNo || 1);
    };

    useEffect(() => {
        loadList(1);
    }, [siteIdx, eventIdx, channelIdx]);

    let virtualNo = paging ? paging.virtualRecordNo : -1;

    const movePage = (page) => {
        ToPage({ pathname: "" }, history, { keyword, page, bbsCategory:"EMERGENCY" });
        loadList(page);
    };

    return (
        <div className="ibox">
            <div className="ibox-title">{listTitle} 목록</div>
            <div className="ibox-content ibox-heading">
                <div className="row">
                    <div className={cx({ "col-md-8": !mode, "col-md-12": mode })}>
                        <SearchCondition
                            sync={true}
                            eventYn={"Y"}
                            channelYn={"Y"}
                            onChange={(e) => {
                                setEventIdx(e.eventIdx);
                                setChannelIdx(e.channelIdx);
                                setKeyword(e.keyword);
                            }}
                            onSearch={onSearch}
                            value={{ siteIdx, eventIdx, channelIdx, keyword }}
                        />
                    </div>
                </div>
            </div>
            <div className="ibox-content">
                <div className="table-responsive">
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th className="text-center">번호</th>
                                <th className="text-center">사이트명</th>
                                <th className="text-center">이벤트명</th>
                                <th className="text-center">채널명</th>
                                <th className="text-center">내용</th>
                                <th className="text-center">등록일자</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list?.map((item, index) => (
                                <tr key={index}>
                                    <td className="text-center">{item.noticeYn === "Y" ? <span className="label " style={{ backgroundColor: "#2f4050", color: "white" }}>공지</span> : virtualNo--}</td>
                                    <td className="text-center">{item.siteTitle}</td>
                                    <td className="text-center">{item.eventTitle}</td>
                                    <td className="text-center">{item.channelTitle}</td>
                                    <td className="text-center"><div dangerouslySetInnerHTML={{ __html: item.content }}/></td>
                                    <td className="text-center">{item.regDate && moment(item.regDate).format("YYYY-MM-DD")}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {paging && (
                <div className="ibox-footer">
                    <Paging paging={paging} pageCallback={movePage} />
                </div>
            )}
        </div>
    );
};

const BoardForm = (props) => {
    const { idx, onChange, siteList, eventList, bbsCategory, history } = props;
    const { iam, cfg } = useContext(AppContext);

    const [form, setForm] = useState({});
    const [answer, setAnswer] = useState("");
    const [content, setContent] = useState("");

    const [siteIdx, setSiteIdx] = useState(cfg.idx);

    const onSave = () => {
        const data = { ...form, answer: content, content, bbsCategory };
        if (idx !== "ADD") {
            data.idx = idx;
        }
        if (!data.eventIdx) {
            swal({ title: "알림", text: "이벤트를 선택해주세요." });
            return;
        }
        // if (!data.noticeYn) {
        //     swal({ title: "알림", text: "공지여부 선택해 주세요." });
        //     return;
        // }
        if (!data.title) {
            swal({ title: "알림", text: "제목을 입력해 주세요." });
            return;
        }
        // if (!data.status) {
        //     swal({ title: "알림", text: "상태를 선택해 주세요." });
        //     return;
        // }
        if (!data.siteIdx && siteIdx) {
            data.siteIdx = siteIdx;
        }
        _API.post({
            path: `/v1/a/board/${data.idx || "new"}`,
            data: data,
        }).then((res) => {
            swal({ title: "알림", text: `게시판이 ${idx === "ADD" ? "등록" : "수정"} 되었습니다.` }).then(() => {
                onChange && onChange();
            });
        });
    };

    const onEventChange = (item, e) => {
        const targetItem = item.filter((target, index) => target.idx == e.target.value)[0];
        if (targetItem) {
            setForm({ ...form, siteIdx, [e.target.name]: e.target.value });
        } else {
            setForm({ ...form, siteIdx, [e.target.name]: "" });
        }
    }

    const loadData = () => {
        if (idx === "ADD") {
            setForm({});
            setAnswer("");
            setContent("");
            return;
        }
        const req = {
            path: `/v1/a/board/${idx}`,
        };
        _API.get(req).then((res) => {
            setForm({ ...res.data });
            setContent(res.data.content);
            setAnswer(res.data.answer);
        });
    };

    useEffect(() => {
        loadData();
    }, [idx]);

    return (
        <div className="ibox">
            <div className="ibox-title">게시판 {idx === "ADD" ? "등록" : "수정"}</div>
            <div className="ibox-content">
                <SelectForm title="이벤트" name="eventIdx" items={eventList.map((event) => { return { label: event.eventName, value: event.idx } })} value={form?.eventIdx} onChange={(e) => { onEventChange(eventList, e); }} nullTitle="이벤트 선택" />
                <Separator />
                <SegmentedForm title="공지여부" items={_DEF.NOTICE_YN} value={form?.noticeYn} onClick={(v) => setForm({ ...form, noticeYn: v })} />
                <Separator />
                <InputForm title="제목" name="title" value={form?.title} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="이름을 입력해 주세요" />
                <Separator />
                <h4>내용</h4>
                <SNEditor content={content} onChange={(c) => setContent(c)} height="200" />
                <Separator />
                {/* <SegmentedForm title="상태" items={_DEF.BOARD_STATUS} value={form?.status} onClick={(v) => setForm({ ...form, status: v })} /> */}
                <Separator />
                <div className="row">
                    <div className="col-md-12 text-right">
                        <div className="btn-group">
                            <button type="button" className="btn btn-primary" onClick={onSave}>
                                <i className="fa fa-save"></i> {idx === "ADD" ? "등록" : "수정"}
                            </button>
                            <button type="button" className="btn btn-default" onClick={onChange}>
                                <i className="fa fa-times"></i> 취소
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};