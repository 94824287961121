import React, { useContext, useState, useEffect, Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import swal from "sweetalert";
import * as FilePond from "filepond";
import { FilePond as FilePondForm } from "react-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

import Layout from "../Layout";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";
import { _DEF } from "../../../modules/config";

import Wrapper, { Row, FormGroup, Separator } from "../../../components/UI/Wrapper";
import { SearchInput, SelectForm, InputForm, SegmentedForm, TextForm, DatePicker } from "../../../components/UI/Form";
import Button from "../../../components/UI/Button";
import Paging, { ToPage, GetParams } from "../../../components/UI/Paging";

import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";

export default (props) => {
    const { history, location, match } = props;

    const { cfg } = useContext(AppContext);

    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [eventIdx, setEventIdx] = useState("");
    const [channelIdx, setChannelIdx] = useState("");
    const [sessionIdx, setSessionIdx] = useState("");
    const [lectureIdx, setLectureIdx] = useState("");
    const [keyword, setKeyword] = useState("");

    const [list, setList] = useState([]);
    const [paging, setPaging] = useState(null);
    const [eventList, setEventList] = useState([]);
    const [channelList, setChannelList] = useState([]);
    const [sessionList, setSessionList] = useState([]);
    const [lectureList, setLectureList] = useState([]);
    const [qnaIdx, setQnaIdx] = useState(null);
    const [mode, setMode] = useState("");
    const [aws, setAws] = useState();

    const [exportExcelList, setExportExcelList] = useState([]);
    let excel = useRef(null);

    const loadList = (page) => {
        // if(!eventIdx){
        //     swal({ title: "알림", text: "이벤트를 선택해 주세요." });
        //     return;
        // }
        const data = {
            siteIdx,
            eventIdx,
            channelIdx,
            sessionIdx,
            lectureIdx,
            keyword,
            page,
        };
        const req = { path: "/v1/a/event/feedback/qna/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setList(list);
            setPaging(paging);
        });
    };

    const loadEventList = (siteIdx) => {
        const data = {
            siteIdx,
        };
        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setEventList(list);
        });
    };

    const loadChannelList = (eventIdx) => {
        const data = {
            eventIdx,
            sort: "TERM_DATE"
        };
        const req = { path: "/v1/a/event/channel/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setChannelList(list);
        });
    };

    const loadSessionList = (channelIdx) => {
        const data = {
            channelIdx,
            sort: "TERM_DATE"
        };
        const req = { path: "/v1/a/event/session/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setSessionList(list);
        });
    };

    const loadLectureList = (sessionIdx) => {
        const data = {
            sessionIdx,
            sort: "TERM_DATE"
        };
        const req = { path: "/v1/a/event/lecture/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setLectureList(list);
        });
    };

    const movePage = (page) => {
        loadList(page);
    };

    const onSearch = (e) => {
        if (e) e.preventDefault();
        loadList(1);
    };

    useEffect(() => {
        loadList(1);
        if (siteIdx) loadEventList(siteIdx);
        else setEventList([]);

        setChannelList([]);
        setSessionList([]);
        setLectureList([]);
        setEventIdx("");
        setChannelIdx("");
        setSessionIdx("");
        setLectureIdx("");
    }, []);

    useEffect(() => {
        if (eventIdx) loadChannelList(eventIdx);
        else setChannelList([]);

        setSessionList([]);
        setLectureList([]);
        setChannelIdx("");
        setSessionIdx("");
        setLectureIdx("");
    }, [eventIdx]);

    useEffect(() => {
        if (channelIdx) loadSessionList(channelIdx);
        else setSessionList([]);

        setLectureList([]);
        setSessionIdx("");
        setLectureIdx("");
    }, [channelIdx]);

    useEffect(() => {
        if (sessionIdx) loadLectureList(sessionIdx);
        else setLectureList([]);

        setLectureIdx("");
    }, [sessionIdx]);

    useEffect(() => {
        loadList(1);
    }, [eventIdx, channelIdx, sessionIdx, lectureIdx])

    const onForm = (idx, e) => {
        if (e) e.preventDefault();

        setQnaIdx(idx);
        setMode("form");
    };

    const onChangeState = (item, col, val, e) => {
        if (e) e.preventDefault();

        swal({
            title: `Q&A`,
            text: `상태를 변경하시겠습니까?`,
            icon: "info",
            buttons: ["취소", "예"],
            dangerMode: val === "D" ? true : false,
        }).then((ok) => {
            if (ok) {
                _API.post({
                    path: "/v1/a/event/category/status",
                    data: {
                        idx: item.idx
                        , category: "qna"
                        , status: val
                    },
                }).then((res) => {
                    loadList(paging?.pageNo ? paging?.pageNo : 1)
                });
            }
        });
    }

    const changeMode = (mode) => {
        setMode(mode);
        if (!mode) loadList(paging?.pageNo ? paging?.pageNo : 1);
    };

    const onExcelDown = async () => {
        if (!siteIdx || !eventIdx || !channelIdx) {
            swal({ title: "알림", text: "채널을 선택해 주세요." });
            return;
        }

        const data = {
            siteIdx,
            eventIdx,
            channelIdx,
            sessionIdx,
            lectureIdx,
            keyword,
        };

        const result = await _API.get({ path: "/v1/a/event/feedback/qna/list", data });
        if (result && result.data) {
            const excelList = result.data.list;
            setExportExcelList(excelList || null);
            if (excelList) {
                excel && excel.current.save();
            }
        }
    };

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-8">
                    <h2>Q&A</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/admin">HOME</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <strong>이벤트</strong>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>Q&A</strong>
                        </li>
                    </ol>
                </div>
                <div className="col-sm-4">
                    <div className="title-action">
                        <button type="button" className="btn btn-primary" onClick={onForm.bind(this, "")}>
                            <i className="fa fa-plus"></i> 추가
                        </button>
                        <button
                            type="button"
                            className="btn btn-warning ml-1"
                            onClick={(e) => {
                                onExcelDown();
                            }}
                        >
                            <i className="fa fa-file-excel-o"></i> 엑셀다운
                        </button>
                    </div>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className={cx({ "col-md-12": !mode, "col-md-6": mode })}>
                        <div className="ibox">
                            <div className="ibox-title">Q&A 목록</div>
                            <div className="ibox-content ibox-heading">
                                <div className="row">
                                    <div className="col-md-2">
                                        <select value={eventIdx} onChange={(e) => setEventIdx(e.target.value)} className="form-control">
                                            <option value="">이벤트선택</option>
                                            {eventList?.map((item, i) => (
                                                <option key={i} value={item.eventIdx}>
                                                    {item.title}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-2">
                                        <select value={channelIdx} onChange={(e) => setChannelIdx(e.target.value)} className="form-control">
                                            <option value="">채널선택</option>
                                            {channelList?.map((item, i) => (
                                                <option key={i} value={item.idx}>
                                                    {item.title + " (" + moment(item.progressDate).format("YYYY-MM-DD") + ")"}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-2">
                                        <select value={sessionIdx} onChange={(e) => setSessionIdx(e.target.value)} className="form-control">
                                            <option value="">세션선택</option>
                                            {sessionList?.map((item, i) => (
                                                <option key={i} value={item.idx}>
                                                    {item.title + " (" + item.startTime + " ~ " + item.endTime + ")"}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-2">
                                        <select value={lectureIdx} onChange={(e) => setLectureIdx(e.target.value)} className="form-control">
                                            <option value="">강좌선택</option>
                                            {lectureList?.map((item, i) => (
                                                <option key={i} value={item.idx}>
                                                    {item.title + " (" + item.startTime + " ~ " + item.endTime + ")"}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-2">
                                        <SearchInput value={keyword} onChange={(e) => setKeyword(e.target.value)} onClick={onSearch} placeholder="검색어를 입력해 주세요." />
                                    </div>
                                </div>
                            </div>
                            <div className="ibox-content">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th className="text-center">ID</th>
                                                <th className="text-center">이벤트명</th>
                                                <th className="text-center">채널명</th>
                                                <th className="text-center">세션명</th>
                                                <th className="text-center">강좌명</th>
                                                <th className="text-center">질문내용</th>
                                                <th className="text-center">상태</th>
                                                <th className="text-center">등록일</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.map((item, i) => (
                                                <tr key={i}>
                                                    <td className="text-center">{item.idx}</td>
                                                    <td className="text-center">{item.eventName}</td>
                                                    <td className="text-center">{item.channelName}</td>
                                                    <td className="text-center">{item.sessionName}</td>
                                                    <td className="text-center">{item.lectureName}</td>
                                                    <td className="text-center">
                                                        <a href="" onClick={onForm.bind(this, item.idx)}>
                                                            {_U.shortText(item.question, 5, "...")}
                                                        </a>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="btn-group">
                                                            {/* <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "W", "btn-success": item.status === "W" })} onClick={onChangeState.bind(this, item, "status", "W")}>
                                                                대기
                                                            </button> */}
                                                            <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "Y", "btn-success": item.status === "Y" })} onClick={onChangeState.bind(this, item, "status", "Y")}>
                                                                정상
                                                            </button>
                                                            <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "N", "btn-success": item.status === "N" })} onClick={onChangeState.bind(this, item, "status", "N")}>
                                                                미사용
                                                            </button>
                                                            <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "D", "btn-success": item.status === "D" })} onClick={onChangeState.bind(this, item, "status", "D")}>
                                                                삭제
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">{moment(item.regDate).format("YYYY-MM-DD")}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {paging && (
                                <div className="ibox-footer">
                                    <Paging paging={paging} pageCallback={movePage} />
                                </div>
                            )}
                        </div>
                    </div>
                    {mode && (
                        <div className={cx({ "col-md-6": true })}>
                            <EventQnaForm {...props} qnaIdx={qnaIdx} changeMode={changeMode} />
                        </div>
                    )}
                </div>
            </div>
            <ExcelDownloader data={exportExcelList} ref={excel} />
        </Layout>
    );
};

const EventQnaForm = (props) => {
    const { history, location, match } = props;
    const { qnaIdx, aws } = props;
    const { cfg } = useContext(AppContext);

    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [data, setData] = useState({});
    const [siteList, setSiteList] = useState([]);
    const [eventList, setEventList] = useState([]);
    const [channelList, setChannelList] = useState([]);
    const [sessionList, setSessionList] = useState([]);
    const [lectureList, setLectureList] = useState([]);
    const sorts = _DEF.getRange(1, 100, {});

    const loadEventList = (siteIdx) => {
        const data = {
            siteIdx,
        };
        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            const newEventList = [];
            for (let i in list) {
                newEventList.push({ label: list[i].title, value: list[i].idx });
            }
            setEventList(newEventList);
        });
    };

    const loadChannelList = (eventIdx) => {
        const data = {
            eventIdx,
            sort: "TERM_DATE"
        };
        const req = { path: "/v1/a/event/channel/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            const newList = [];
            for (let i in list) {
                newList.push({ label: list[i].title + " (" + moment(list[i].progressDate).format("YYYY-MM-DD") + ")", value: list[i].idx });
            }
            setChannelList(newList);
        });
    };

    const loadSessionList = (channelIdx) => {
        const data = {
            channelIdx,
            sort: "TERM_DATE"
        };
        const req = { path: "/v1/a/event/session/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            const newList = [];
            for (let i in list) {
                newList.push({ label: list[i].title + " (" + list[i].startTime + " ~ " + list[i].endTime + ")", value: list[i].idx });
            }
            setSessionList(newList);
        });
    };

    const loadLectureList = (sessionIdx) => {
        const data = {
            sessionIdx,
            sort: "TERM_DATE"
        };
        const req = { path: "/v1/a/event/lecture/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            const newList = [];
            for (let i in list) {
                newList.push({ label: list[i].title + " (" + list[i].startTime + " ~ " + list[i].endTime + ")", value: list[i].idx });
            }
            setLectureList(newList);
        });
    };

    const loadData = () => {
        if (!qnaIdx) {
            return;
        }
        const data = {};
        const req = { path: `/v1/a/event/feedback/qna/${qnaIdx}`, data };
        _API.get(req)
            .then((res) => res.data)
            .then((data) => {
                if (data.siteIdx) loadEventList(data.siteIdx);
                if (data.eventIdx) loadChannelList(data.eventIdx);
                if (data.channelIdx) loadSessionList(data.channelIdx);
                if (data.sessionIdx) loadLectureList(data.sessionIdx);
                setData(data);
            });
    };

    const handleDataChange = (e) => {
        let { name, value } = e.target;

        if (name === "siteIdx") {
            if (value) loadEventList(value);
            else setEventList([]);
            setChannelList([]);
            setSessionList([]);
            setLectureList([]);
            setData({ ...data, [name]: value, eventIdx: null, channelIdx: null, sessionIdx: null, lectureIdx: null });
        } else if (name === "eventIdx") {
            if (value) loadChannelList(value);
            else setChannelList([]);
            setSessionList([]);
            setLectureList([]);
            setData({ ...data, [name]: value, channelIdx: null, sessionIdx: null, lectureIdx: null });
        } else if (name === "channelIdx") {
            if (value) loadSessionList(value);
            else setSessionList([]);
            setLectureList([]);
            setData({ ...data, [name]: value, sessionIdx: null, lectureIdx: null });
        } else if (name === "sessionIdx") {
            if (value) loadLectureList(value);
            else setLectureList([]);
            setData({ ...data, [name]: value, lectureIdx: null });
        } else {
            setData({ ...data, [name]: value });
        }
    };

    const setDataChange = (name, value) => {
        setData({ ...data, [name]: value });
    };

    const fileUpload = (name, item) => {
        const urlName = name.replace("FileIdx", "SignedUrl");
        setData({ ...data, [name]: item.id, [urlName]: item.signedUrl });
    };

    const handleSaveClick = async () => {
        if (!data.siteIdx) {
            swal({ title: "알림", text: "사이트를 선택해 주세요." });
            return;
        }
        if (!data.eventIdx) {
            swal({ title: "알림", text: "이벤트를 선택해 주세요." });
            return;
        }
        if (!data.channelIdx) {
            swal({ title: "알림", text: "채널을 선택해 주세요." });
            return;
        }
        if (!data.question) {
            swal({ title: "알림", text: "설문을 입력해 주세요." });
            return;
        }

        _API.post({
            path: "/v1/a/event/feedback/qna",
            data: {
                qnaIdx: data.qnaIdx,
                channelIdx: data.channelIdx,
                sessionIdx: data.sessionIdx || null,
                lectureIdx: data.lectureIdx || null,
                lectureMgrIdx: data.lectureMgrIdx || null,
                questionerType: data.questionerType || "MEMBER",
                participantIdx: data.participantIdx || null,
                question: data.question,
                answerYn: data.answerYn || "N",
                answer: data.answer || null,
                answerDate: data.answerDate ? moment(data.answerDate).format("YYYY-MM-DD HH:mm:ss") : null,
                principalYn: data.principalYn || "N",
                monitorYn: data.monitorYn || "N",
                status: data.status || "Y",
            },
        }).then((res) => {
            swal({ title: "알림", text: `Q&A가 ${!data.qnaIdx ? "생성" : "수정"}되었습니다.` }).then(() => {
                // _U.postMessage("EVENT-CHANGED");
                onCancel();
            });
        });
    };

    const onCancel = () => {
        props.changeMode && props.changeMode("");
    };

    useEffect(() => {
        loadEventList(siteIdx);
        loadData();
        if (!qnaIdx) setData({ siteIdx });
    }, [qnaIdx]);

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="ibox">
                    <div className="ibox-title">Q&A {!data?.qnaIdx ? "등록" : "수정"}</div>
                    <div className="ibox-content">
                        <SelectForm title="이벤트명" name="eventIdx" value={data?.eventIdx} items={eventList} onChange={handleDataChange} nullTitle="이벤트 선택" />
                        <SelectForm title="채널명" name="channelIdx" value={data?.channelIdx} items={channelList} onChange={handleDataChange} nullTitle="채널 선택" />
                        <SelectForm title="세션명" name="sessionIdx" value={data?.sessionIdx} items={sessionList} onChange={handleDataChange} nullTitle="세션 선택" />
                        <SelectForm title="강좌명" name="lectureIdx" value={data?.lectureIdx} items={lectureList} onChange={handleDataChange} nullTitle="강좌 선택" />
                        <Separator />
                        <SegmentedForm title="질문자구분" items={_DEF.QUESTIONER_TYPE} value={data?.questionerType} onClick={(v) => setDataChange("questionerType", v)} />
                        <InputForm title="질문" name="question" placeholder="설문문항" value={data?.question} onChange={handleDataChange} />
                        <Separator />
                        <SegmentedForm title="답변여부" items={_DEF.ANSWER_COMPLETE_TYPE} value={data?.answerYn} onClick={(v) => setDataChange("answerYn", v)} />
                        <TextForm title="답변" name="answer" placeholder="답변내용" value={data?.answer} onChange={handleDataChange} />
                        <DatePicker title="답변일자" name="answerDate" value={data.answerDate ? moment(data.answerDate).format("YYYY-MM-DD") : ""} onChange={(v) => setDataChange("answerDate", v.value)} />
                        <Separator />
                        <SegmentedForm title="좌장출력여부" items={_DEF.STATUS} value={data?.principalYn} onClick={(v) => setDataChange("principalYn", v)} />
                        <SegmentedForm title="모니터출력여부" items={_DEF.STATUS} value={data?.monitorYn} onClick={(v) => setDataChange("monitorYn", v)} />
                        {/* <SegmentedForm title="사용여부" items={_DEF.STATUS} value={data?.status} onClick={(v) => setDataChange("status", v)} /> */}
                        <Separator />
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <div className="btn-group">
                                    <Button onClick={handleSaveClick} className="btn btn-primary">
                                        {!data.qnaIdx ? "생성" : "수정"}
                                    </Button>
                                    <Button onClick={onCancel}>취소</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

class ExcelDownloader extends React.Component {
    constructor(props) {
        super(props);

        const state = {
            list: props.data || [],
            excelName: props.eventName,
        };
        this.state = state;
    }

    componentWillReceiveProps = (props) => {
        this.setState({ list: props.data, excelName: props.excelName });
    };

    save() {
        this.excel && this.excel.save();
    }

    getList() {
        const { list } = this.state;
        for (let i in list) {
            const item = list[i];
            item.regDate = item.regDate ? moment(item.regDate).format("YYYY-MM-DD") : "";
            item.status = item.status === "Y" ? "정상" : item.status === "N" ? "미사용" : "";
            item.answerDate = item.answerDate ? moment(item.answerDate).format("YYYY-MM-DD") : "";
            item.questionerType = item.questionerType === "MEMBER" ? "운영자" : item.questionerType === "PATICIPANT" ? "참가자" : "";
            item.answerYn = item.answerYn === "Y" ? "완료" : item.answerYn === "N" ? "미완료" : "";
            item.principalYn = item.principalYn === "Y" ? "사용" : item.principalYn === "N" ? "사용안함" : "";
            item.monitorYn = item.monitorYn === "Y" ? "사용" : item.monitorYn === "N" ? "사용안함" : "";
            item.questionerInfo = "";
            if (item.questionerName) {
                item.questionerInfo = item.questionerName || item.questionerEmail;
                item.questionerType = "참가자";
            } else {
                item.questionerInfo = "운영자";
                item.questionerType = "운영자";
            }
        }
        return list;
    }

    render() {
        const excelName = this.state.excelName || "Q&A";
        const list = this.getList();
        const today = moment().format("YYYYMMDDHHmmss");
        if (!list) {
            return "";
        }

        return (
            <ExcelExport data={list} fileName={`${excelName}_${today}.xlsx`} ref={(ref) => (this.excel = ref)}>

                <ExcelExportColumn field="eventName" title="이벤트명" width={250} />
                <ExcelExportColumn field="channelName" title="채널명" width={250} />
                <ExcelExportColumn field="sessionName" title="세션명" width={250} />
                <ExcelExportColumn field="lectureName" title="강좌명" width={250} />

                <ExcelExportColumn field="questionerType" title="질문자구분" width={250} />
                <ExcelExportColumn field="questionerInfo" title="질문자명" width={250} />
                <ExcelExportColumn field="question" title="질문내용" width={250} />
                <ExcelExportColumn field="answerYn" title="답변여부" width={250} />
                <ExcelExportColumn field="answer" title="답변" width={250} />
                <ExcelExportColumn field="answerDate" title="답변일자" width={250} />
                <ExcelExportColumn field="principalYn" title="좌장출력여부" width={250} />
                <ExcelExportColumn field="monitorYn" title="모니터출력여부" width={250} />

                <ExcelExportColumn field="status" title="상태" width={250} />
                <ExcelExportColumn field="regDate" title="등록일" width={250} />
            </ExcelExport>
        );
    }
}

