import React, { useContext, useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import swal from "sweetalert";
import Modal from "react-modal";

import Layout from "../Layout";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";
import { _DEF } from "../../../modules/config";

import { ModalCloseButton } from "../../../components/UI/Button";
import { SearchInput, SelectForm, InputForm, SegmentedForm, FileForm, DatePicker, TextForm, SearchCondition } from "../../../components/UI/Form";
import { Theme } from "../../../components/GlobalStyles";
import Paging from "../../../components/UI/Paging";

import Common from "../../../components/UI/Common";

export default (props) => {
    const { history, location, match } = props;
    const { cfg } = useContext(AppContext);

    const [mode, setMode] = useState("");
    const [eventList, setEventList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);

    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [eventIdx, setEventIdx] = useState("");
    const [categoryCode, setCategoryCode] = useState("");

    const [keyword, setKeyword] = useState("");
    const [list, setList] = useState([]);
    const [paging, setPaging] = useState(null);

    const [data, setData] = useState(null);

    const loadList = (page) => {
        const data = {
            siteIdx,
            eventIdx,
            keyword,
            categoryCode,
            page,
        };

        const req = { path: "/v1/a/event/abstract", data };
        _API.get(req).then((res) => {
            console.log(res);
            const { list, paging } = res.data;
            setList(list);
            setPaging(paging);
        });
    };

    const loadEventList = () => {
        const data = {
            siteIdx,
        };

        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list } = res.data;
            setEventList(list);
        });
    };

    const loadCategory = () => {
        const data = {
            siteIdx: siteIdx,
            eventIdx: eventIdx,
            code: "ABSTRACT",
        };

        const req = { path: "/v1/a/event/common/info", data };
        _API.get(req).then((res) => {
            setCategoryList(res.data);
        });
    };

    useEffect(() => {
        if (siteIdx && eventIdx) {
            loadCategory();
        } else {
            setCategoryList([]);
        }
    }, [siteIdx, eventIdx]);

    useEffect(() => {
        loadEventList();
        loadList(1);
    }, []);

    useEffect(() => {
        loadList(1);
    }, [eventIdx, categoryCode]);

    const movePage = (page) => {
        loadList(page);
    };

    const onSearch = (e) => {
        loadList(1);
    };

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-8">
                    <h2>초록/포스터</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/admin">HOME</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to={`${location.pathname}`}>이벤트</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>초록/포스터</strong>
                        </li>
                    </ol>
                </div>
                <div className="col-sm-4">
                    <div className="title-action">
                        <button
                            type="button"
                            className="btn btn-warning"
                            onClick={(e) => {
                                setMode("CATEGORY");
                            }}
                        >
                            <i className="fa fa-gear"></i> 카테고리 설정
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary ml-2"
                            onClick={(e) => {
                                setMode("ADD");
                                setData(null);
                            }}
                        >
                            <i className="fa fa-plus"></i> 추가
                        </button>
                    </div>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className={cx({ "col-md-12": !mode, "col-md-8": mode === "ADD" })}>
                        <div className="ibox">
                            <div className="ibox-title">목록</div>
                            <div className="ibox-content ibox-heading">
                                <div className="row">
                                    <div className="col-md-4">
                                        <SelectForm
                                            name="eventIdx"
                                            items={eventList.map((event) => {
                                                return { label: event.eventName, value: event.idx };
                                            })}
                                            value={eventIdx}
                                            onChange={(e) => {
                                                setEventIdx(e.target.value);
                                                setCategoryCode("");
                                            }}
                                            nullTitle={`이벤트 선택`}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <SelectForm
                                            name="categoryCode"
                                            items={categoryList.map((c) => {
                                                return { label: c.value, value: c.keyword };
                                            })}
                                            value={categoryCode}
                                            onChange={(e) => {
                                                setCategoryCode(e.target.value);
                                            }}
                                            nullTitle={categoryList?.length > 0 ? `카테고리 선택` : `이벤트를 먼저 선택하세요.`}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <SearchInput value={keyword} onChange={(e) => setKeyword(e.target.value)} onClick={onSearch} placeholder="검색어를 입력해 주세요." />
                                    </div>
                                </div>
                            </div>
                            <div className="ibox-content">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th className="text-center">이벤트명</th>
                                                <th className="text-center">타입</th>
                                                <th className="text-center">카테고리</th>
                                                <th className="text-center">제목</th>
                                                <th className="text-center">제출자</th>
                                                <th className="text-center">자료</th>
                                                <th className="text-center">상태</th>
                                                <th className="text-center">관리</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list?.map((item, i) => (
                                                <ListItem
                                                    {...item}
                                                    key={i}
                                                    onModify={(data) => {
                                                        setData(data);
                                                        setMode("ADD");
                                                    }}
                                                    refresh={() => {
                                                        loadList(paging?.pageNo || 1);
                                                    }}
                                                />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {paging && (
                                <div className="ibox-footer">
                                    <Paging paging={paging} pageCallback={movePage} />
                                </div>
                            )}
                        </div>
                    </div>
                    {mode === "ADD" && (
                        <div className="col-md-4">
                            <Form
                                data={data}
                                onClose={() => {
                                    setMode("");
                                }}
                                callback={() => {
                                    loadList(paging?.pageNo ? paging?.pageNo : 1);
                                    setMode("");
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
            {mode === "CATEGORY" && (
                <Modal isOpen={mode == "CATEGORY"} style={Theme.modal.common}>
                    <ModalCloseButton
                        className="btn btn-default"
                        onClick={() => {
                            setMode("");
                        }}
                    >
                        <i className="fa fa-remove"></i>
                    </ModalCloseButton>
                    <Common code="ABSTRACT" />
                </Modal>
            )}
        </Layout>
    );
};

const ListItem = (props) => {
    const onChangeStatus = (status) => {
        swal({
            title: `초록/포스터`,
            text: `상태를 변경하시겠습니까?`,
            icon: "info",
            buttons: ["취소", "예"],
            dangerMode: status === "D" ? true : false,
        }).then((ok) => {
            if (ok) {
                const req = { path: "/v1/a/event/abstract/status", data: { idx: props.idx, status } };
                _API.post(req).then((res) => {
                    props.refresh && props.refresh();
                });
            }
        });
    };

    const onRemove = () => {
        swal({
            title: "정말 삭제 하시겠습니까?",
            text: "삭제된 데이터는 복구가 불가합니다 .",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                _API.post({ path: `/v1/a/event/abstract/status`, data: { idx: props.idx, status: "D" } }).then((res) => {
                    swal({ title: "알림", text: `초록/포스터가 삭제되었습니다.` }).then(() => {
                        props.refresh && props.refresh();
                    });
                });
            }
        });
    };

    return (
        <tr>
            <td className="text-center">{props.eventTitle}</td>
            <td className="text-center">{_DEF.getDefinedLabel(_DEF.ABSTRACT_TYPE, props.type)}</td>
            <td className="text-center">{props.categoryName}</td>
            <td className="text-center">{props.title}</td>
            <td className="text-center">
                {props.name}
                {props.companyName ? `(${props.companyName})` : ""}
            </td>
            <td className="text-center">
                {props.finalFileIdx && (
                    <a href={props.finalSignedUrl} target="_blank">
                        <i className="fa fa-download"></i>
                    </a>
                )}
            </td>
            <td className="text-center">
                <div className="btn-group">
                    <button className={cx({ btn: true, "btn-xs": true, "btn-default": props.status !== "Y", "btn-success": props.status === "Y" })} onClick={onChangeStatus.bind(this, "Y")}>
                        사용
                    </button>
                    <button className={cx({ btn: true, "btn-xs": true, "btn-default": props.status !== "N", "btn-success": props.status === "N" })} onClick={onChangeStatus.bind(this, "N")}>
                        사용안함
                    </button>
                </div>
            </td>
            <td className="text-center">
                <div className="btn-group">
                    <button className={cx({ btn: true, "btn-xs": true, "btn-warning": true })} onClick={props.onModify.bind(this, props)}>
                        <i className="fa fa-edit"></i>
                    </button>
                    <button className={cx({ btn: true, "btn-xs": true, "btn-danger": true })} onClick={onRemove}>
                        <i className="fa fa-trash"></i>
                    </button>
                </div>
            </td>
        </tr>
    );
};

const Form = (props) => {
    const { onClose, data, callback } = props;
    const { cfg } = useContext(AppContext);

    const [eventList, setEventList] = useState([]);
    const [category, setCategory] = useState([]);

    const [form, setForm] = useState({
        status: "Y",
        type: "POSTER",
        siteIdx: cfg.idx,
    });

    const onSave = () => {
        if (!form?.siteIdx) {
            swal("사이트를 선택하세요.");
            return;
        }
        if (!form?.eventIdx) {
            swal("이벤트를 선택하세요.");
            return;
        }
        if (!form?.categoryCode) {
            swal("카테고리를 선택하세요.");
            return;
        }
        if (!form?.type) {
            swal("타입을 선택하세요.");
            return;
        }
        if (!form?.title) {
            swal("제목을 입력하세요.");
            return;
        }
        if (!form?.finalFileIdx) {
            swal("자료를 첨부하세요.");
            return;
        }
        if (!form?.thumbnailFileIdx) {
            swal("썸네일 이미지를 첨부하세요.");
            return;
        }
        if (!form?.name) {
            swal("제출자명을 입력하세요.");
            return;
        }
        if (!form?.companyName) {
            swal("제출자 소속을 입력하세요.");
            return;
        }

        const req = { path: "/v1/a/event/abstract", data: form };
        _API.post(req).then((res) => {
            setForm({
                status: "Y",
                type: "POSTER",
                siteIdx: cfg.idx,
            });
            swal(form?.idx ? `수정 되었습니다.` : `저장 되었습니다.`).then(() => {
                try {
                    document.getElementById("finalFile").value = "";
                    document.getElementById("thumbnailFile").value = "";
                } catch (e) { }
                callback && callback();
            });
        });
    };

    const loadEventList = () => {
        if (!form?.siteIdx) {
            setEventList([]);
            return;
        }

        const data = {
            siteIdx: form?.siteIdx,
        };

        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list } = res.data;
            setEventList(list);
        });
    };

    const loadCategory = () => {
        const data = {
            siteIdx: form?.siteIdx,
            eventIdx: form?.eventIdx,
            code: "ABSTRACT",
        };

        const req = { path: "/v1/a/event/common/info", data };
        _API.get(req).then((res) => {
            setCategory(res.data);
        });
    };

    useEffect(() => {
        if (form?.siteIdx && form?.eventIdx) {
            loadCategory();
        } else {
            setCategory([]);
        }
    }, [form?.siteIdx, form?.eventIdx]);

    useEffect(() => {
        if (data?.idx) {
            setForm(data);
        }
    }, [data]);

    useEffect(() => {
        loadEventList();
    }, []);

    const fileUpload = async (name, item) => {
        const urlName = name.replace("FileIdx", "SignedUrl");
        const itemName = name.replace("FileIdx", "FileName");

        if (item?.type.includes("video")) {
            await _API
                .post({
                    path: "/v1/a/video",
                    data: item,
                })
                .then((res) => res.data)
                .then((data) => {
                    swal({ title: "알림", text: "업로드 되었습니다." });
                });
        }
        setForm({ ...form, [name]: item.id, [urlName]: item.signedUrl, [itemName]: item.name });
    };

    return (
        <div className="ibox">
            <div className="ibox-title">초록/포스터 {form?.idx ? `수정` : `등록`}</div>
            <div className="ibox-content">
                <SelectForm
                    title="이벤트"
                    name="eventIdx"
                    items={eventList.map((event) => {
                        return { label: event.eventName, value: event.idx };
                    })}
                    value={form?.eventIdx}
                    onChange={(e) => {
                        setForm({ ...form, [e.target.name]: e.target.value, categoryCode: "" });
                    }}
                    nullTitle={`이벤트 선택`}
                />

                <SelectForm
                    title="카테고리"
                    name="categoryCode"
                    items={category.map((c) => {
                        return { label: c.value, value: c.keyword };
                    })}
                    value={form?.categoryCode}
                    onChange={(e) => {
                        setForm({ ...form, [e.target.name]: e.target.value });
                    }}
                    nullTitle={category?.length > 0 ? `카테고리 선택` : `이벤트를 먼저 선택하세요.`}
                />

                <SegmentedForm title="타입" items={_DEF.ABSTRACT_TYPE} value={form?.type} onClick={(v) => setForm({ ...form, type: v })} />
                <InputForm title="제목" name="title" value={form?.title} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="제목 입력" />
                <TextForm title="한줄소개" name="description" value={form?.description} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="한줄소개를 입력해 주세요." />
                <FileForm title="자료(PDF, Video)" onChange={(v) => fileUpload("finalFileIdx", v)} path={`EVENT/ABSTRACT/${moment().format("YYYY/MM/DD")}`} url={form?.finalSignedUrl} accept=".pdf, video/*" fileName={form?.finalFileName} id="finalFile" />
                <FileForm title="썸네일" onChange={(v) => fileUpload("thumbnailFileIdx", v)} path={`EVENT/ABSTRACT/${moment().format("YYYY/MM/DD")}`} url={form?.thumbnailSignedUrl} accept="image/*" fileName={form?.thumbnailFileName} id="thumbnailFile" description="*권장해상도 215 x 312 (3:4)" />
                <InputForm title="제출자명" name="name" value={form?.name} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="제출자명 입력" />
                <InputForm title="제출자 소속" name="companyName" value={form?.companyName} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="e.g. 한국병원" />
                <InputForm title="제출자 부서" name="deptName" value={form?.deptName} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="e.g. 신경외과" />
                <InputForm title="제출자 직책/직함" name="position" value={form?.position} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="e.g. 전공의" />
                <InputForm title="제출자 연락처" name="phone" value={form?.phone} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="e.g. 01012345678" />
                <InputForm title="제출자 이메일" name="email" value={form?.email} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="e.g. abc@abc.com" />
                {/* mentedForm title="상태" items={_DEF.STATUS} value={form?.status} onClick={(v) => setForm({ ...form, status: v })} /> */}
                <div className="hr-line-dashed"></div>
                <div className="row">
                    <div className="col-md-12 text-right">
                        <button className="btn btn-primary ml-2" onClick={onSave}>
                            <i className="fa fa-save"></i> 저장
                        </button>
                        <button className="btn btn-default" onClick={onClose}>
                            <i className="fa fa-remove"></i> 취소
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
