import React, { useContext } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";

import Socket from "../../../components/UI/Socket";

import Layout from "../Layout";

export default (props) => {
    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-12">
                    <h2>소켓</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/dashboard">HOME</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/dev/dashboard">개발자</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>소켓</strong>
                        </li>
                    </ol>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="ibox">
                            <div className="ibox-title">소켓 1</div>
                            <div className="ibox-content">
                                <Socket debug />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="ibox">
                            <div className="ibox-title">소켓 2</div>
                            <div className="ibox-content">
                                <Socket debug />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="ibox">
                            <div className="ibox-title">소켓 3</div>
                            <div className="ibox-content">
                                <Socket debug />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="ibox">
                            <div className="ibox-title">소켓 4</div>
                            <div className="ibox-content">
                                <Socket debug />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
