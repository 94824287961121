import React, { useState, useEffect, Fragment } from "react";
import cx from "classnames";
import moment from "moment";
import styled from "styled-components";
// import Hls from "hls.js";
// import { Player, BigPlayButton, ControlBar, PlayToggle } from "video-react";
// import "video-react/dist/video-react.css";
import { isIOS, isMobile } from "react-device-detect";
import VideoJs from "video.js";
import "video.js/dist/video-js.min.css";

import { _CFG } from "../../modules/config";
import { _CK, _API } from "../../modules/utils";
import AmazonIVSWorkaround from "../AmazonIVSWorkaround";
import VodPlayer from "../VideoHook";

export default class Video extends React.Component {
    constructor(props) {
        super(props);

        this.state = { iosUrl: "", vLayerId: `video${moment().format("YYYYMMDDHHmmss")}`, url: props.url, poster: props.poster };
    }

    componentDidMount() {
        // if (isIOS && !this.isIvs(this.props.url)) {
        //     console.log(0, this.props.url);
        //     this.getIosUrl(this.props.url);
        // }
    }

    componentWillReceiveProps(props) {
        if (props.selectVodTime) {
            this.player.seek(moment.duration(props.selectVodTime).asSeconds());
        }
    }

    isIvs = (streamUrl) => {
        if (!streamUrl) {
            return false;
        }
        return streamUrl.includes("live-video.net");
    };

    getStreamType = () => {
        return this.props.getStreamType ? this.props.getStreamType() : "";
    }

    render() {
        const { url, poster, isLive, selectVodTime, removeCustomSize } = this.props;
        const { autoPlay, controls, muted, loop, uiOff, uiFeed, fluid, width, height, paddingTop } = this.props;
        const { videoRefProp } = this.props;

        return (
            <>
                {this.isIvs(url) ? (
                    <>
                        <AmazonIVSWorkaround url={url} poster={poster} />
                    </>
                ) : (
                    <>
                        <VodPlayer url={url} controls preload="auto" poster={poster} width={width} height={height} playsInline autoPlay={autoPlay} muted={muted} loop={loop} fluid={fluid} paddingTop={paddingTop} streamType={this.getStreamType()} controlsList="nodownload" videoRefProp={videoRefProp} />
                    </>
                )}
            </>
        );
    }
}

const getAuthUrl = (url, streamType) => {
    if (!url) return "";
    else if (streamType === "OUT") return `${url}`;
    else if (url.indexOf("?") > -1) {
        return `${url}`;
    } else {
        return `${url}?apikey=${encodeURIComponent(_CFG.apiKey)}`;
    }
}

// const VideoWrapper = styled.div`
//     height: 100%;

//     ${(props) =>
//         props.removeCustomSize
//             ? `
//     `
//             : `
//     .video-react {
//         padding-top: 0 !important;
//         height: 100% !important;
//     }    
//     `}
// `;
const VideoWrapper = styled.video`
    width: 100%;
    position: relative;
    max-height: 100%;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        height: 100%;
    }
    

    ${(props) =>
        props.paddingTop
            ?
            `padding-top: ${props.paddingTop};`
            // : `padding-top: 45.60%;`
            : (window.innerHeight && window.innerHeight < 800) ? `padding-top:${(45.60 - ((800 - window.innerHeight) / 10))}%;` : `padding-top: 45.60%;`
    }

    .vjs-big-play-button {
        top: 50%;
        left: 50%;
        margin-top: -0.75em;
        margin-left: -1.5em;
    }
`;

const VideoSource = (props) => {
    const { autoPlay, muted } = props;

    useEffect(() => {
        const player = VideoJs(props.vLayerId);
        if (autoPlay && (muted || muted == null || muted == undefined)) {
            player.autoplay("muted");
        } else if (autoPlay) {
            player.autoplay("play");
        }
        // return () => {
        //     player.dispose();
        // };
    }, [props.src]);

    return <source src={getAuthUrl(props.src, props.streamType)} type={props.type || "application/x-mpegURL"} />;
};