import React, { useContext, useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import swal from "sweetalert";

import Layout from "../Layout";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";
import { _DEF } from "../../../modules/config";

import { SearchInput, SelectForm, InputForm, SegmentedForm } from "../../../components/UI/Form";
import Paging, { ToPage, GetParams } from "../../../components/UI/Paging";
import { Separator } from "../../../components/UI/Wrapper";

import  DualListBox  from 'react-dual-listbox'; 

const MEMBER_TYPES = [
    { label: "어드민", value: "ADMIN" },
    { label: "운영자", value: "OPERATOR" }
];

const MEMBER_STATUS = [
    { label: "대기", value: "W" },
    { label: "정상", value: "Y" },
    { label: "차단", value: "N" },
];

export default (props) => {

    const { history, location, match } = props;

    const { cfg } = useContext(AppContext);

    const [mode, setMode] = useState("");
    const [keyword, setKeyword] = useState("");
    const [list, setList] = useState([]);
    const [paging, setPaging] = useState(null);
    const [siteIdx, setSiteIdx] = useState(cfg.idx);

    const onAddClick = () => {
        setMode("ADD");
    };

    const onModify = (idx) => {
        setMode(idx);
    };

    const loadPage = (data) => {
        if (siteIdx) {
            data.siteIdx = siteIdx;
        }
        const req = { path: "/v1/a/member/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setList(list);
            setPaging(paging);
        });
    };

    const movePage = (page) => {
        ToPage(location, history, { keyword, page });
    };

    const onSearch = (e) => {
        ToPage(location, history, { keyword, page: 1 });
    };

    const onRefresh = () => {
        loadPage({ keyword, page: paging.pageNo });
    };

    const onMemberChange = () => {
        setMode("");
        onRefresh();
    };

    useEffect(() => {
        const params = GetParams(location);
        if (params) {
            setKeyword(params.keyword);
            loadPage(params);
        } else {
            loadPage({ page: 1 });
        }
    }, [location]);

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-8">
                    <h2>회원관리</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/admin">HOME</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>회원관리</strong>
                        </li>
                    </ol>
                </div>
                <div className="col-sm-4">
                    <div className="title-action">
                        <button type="button" className="btn btn-primary" onClick={onAddClick.bind(this)}>
                            <i className="fa fa-plus"></i> 추가
                        </button>
                    </div>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className={cx({ "col-md-12": !mode, "col-md-6": mode })}>
                        <div className="ibox">
                            <div className="ibox-title">회원목록</div>
                            <div className="ibox-content ibox-heading">
                                <div className="row">
                                    <div className="col-md-4">
                                        <SearchInput value={keyword} onChange={(e) => setKeyword(e.target.value)} onClick={onSearch} placeholder="검색어를 입력해 주세요." />
                                    </div>
                                </div>
                            </div>
                            <div className="ibox-content">
                                <MemberList list={list} paging={paging} onChange={onRefresh} onModify={onModify} />
                            </div>
                            {paging && (
                                <div className="ibox-footer">
                                    <Paging paging={paging} pageCallback={movePage} />
                                </div>
                            )}
                        </div>
                    </div>
                    {mode && (
                        <div className={cx({ "col-md-4": true })}>
                            <MemberForm idx={mode} onChange={onMemberChange} />
                        </div>
                    )}
                    {/* {mode && (
                        <div className={cx({ "col-md-4": true })}>
                            <MemberSiteForm idx={mode} onChange={onMemberChange} />
                        </div>
                    )} */}
                </div>
            </div>
        </Layout>
    );
};

const MemberList = (props) => {
    const { list, paging, onModify } = props;

    const onChangeState = (info, status) => {

        swal({
            title: `회원관리`,
            text: `회원 [${info.name}] 님의 상태를 변경 하시겠습니까?`,
            icon: "info",
            dangerMode: status === "D" ? true : false,
            buttons: ["취소", "예"],
        }).then((ok) => {
            if (ok) {
                const req = { path: "/v1/a/member/save/status", data: { idx : info.idx, status } };
                _API.post(req).then((res) => {
                    props.onChange && props.onChange();
                });
            }
        });
        
    };

    let virtualNo = paging ? paging.virtualRecordNo : -1;
    return (
        <div className="table-responsive">
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th className="text-center">No.</th>
                        <th className="text-center">이름</th>
                        <th className="text-center">회원구분</th>
                        <th className="text-center">이메일</th>
                        <th className="text-center">휴대폰번호</th>
                        <th className="text-center">등록일</th>
                        <th className="text-center">수정일</th>
                        <th className="text-center">상태</th>
                    </tr>
                </thead>
                <tbody>
                    {list.map((item, i) => (
                        <tr key={i}>
                            <td className="text-center">{virtualNo--}</td>
                            <td className="text-center">{item.name}</td>
                            <td className="text-center">
                                {item.memType === "DEVELOPER" && <span className="label label-info">개발자</span>}
                                {item.memType === "ADMIN" && <span className="label label-danger">관리자</span>}
                                {item.memType === "SUPERADMIN" && <span className="label label-danger">슈퍼관리자</span>}
                                {item.memType === "OPERATOR" && <span className="label label-warning">운영자</span>}

                                {(item.memType === "MEMBER" || !item.memType) && <span className="label label-default">회원</span>}
                            </td>
                            <td className="text-center">{item.email}</td>
                            <td className="text-center">{item.mobile}</td>
                            <td className="text-center">{moment(item.regDate).format("YYYY-MM-DD")}</td>
                            <td className="text-center">{ item.modDate && moment(item.modDate).format("YYYY-MM-DD")}</td>
                            <td className="text-center">
                                <div className="btn-group">
                                    {/* <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "W", "btn-success": item.status === "W" })} onClick={onChangeState.bind(this, item, "W")}>
                                        대기
                                    </button> */}
                                    <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "Y", "btn-success": item.status === "Y" })} onClick={onChangeState.bind(this, item, "Y")}>
                                        정상
                                    </button>
                                    <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "N", "btn-success": item.status === "N" })} onClick={onChangeState.bind(this, item, "N")}>
                                        차단
                                    </button>
                                    <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "D", "btn-success": item.status === "D" })} onClick={onChangeState.bind(this, item, "D")}>
                                        탈퇴
                                    </button>
                                    <button type="button" className="btn btn-xs btn-warning" onClick={(e) => onModify && onModify(item.idx)}>
                                        <i className="fa fa-edit"></i> 수정
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const MemberForm = (props) => {
    const { idx, onChange } = props;
    const { siteIdx, iam, cfg } = useContext(AppContext);

    const [sites, setSites] = useState([]);
    const [user, setUser] = useState({status:'Y'});
    const [selected, setSelected] = useState();

    const onSave = () => {
        const data = { ...user };
        if (idx !== "ADD") {
            data.idx = idx;
        }
        if (!data.memType) {
            swal({ title: "알림", text: "회원구분을 선택해 주세요." });
            return;
        }
        if (!data.siteIdx && siteIdx) {
            data.siteIdx = siteIdx;
        }
        _API.post({
            path: "/v1/a/member/save",
            data: data,
        }).then((res) => {
            swal({ title: "알림", text: `회원이 ${idx === "ADD" ? "등록" : "수정"} 되었습니다.` }).then(() => {
                if (idx === "ADD") {
                    memberSiteSave(res.data && res.data.insertId);
                } else {
                    onChange && onChange();
                }
            });
        });
    };

    const memberSiteSave = (memberIdx) => {

        const data = {
              memberIdx       : memberIdx
            , siteIdxList     : [cfg.idx]
            , deleteIdxList   : []
        }

        _API.post({
            path: "/v1/a/member/site/save",
            data: data,
        }).then((res) => {
            onChange && onChange();
        });
    }

    const loadData = () => {
        if (idx === "ADD") {
            return;
        }
        const req = {
            path: "/v1/a/member/info",
            data: { idx },
        };
        _API.get(req).then((res) => {
            setUser({ ...res.data, passwd: "" });
        });
    };

    useEffect(() => {
        loadData();
    }, [idx]);

    return (
        <div className="ibox">
            <div className="ibox-title">회원 {idx === "ADD" ? "등록" : "수정"}</div>
            <div className="ibox-content">
                {/* {!siteIdx && <SelectForm title="사이트" name="siteIdx" items={sites} value={user?.siteIdx} onChange={(e) => setUser({ ...user, [e.target.name]: e.target.value })} nullTitle="사이트 선택" />} */}
                <SelectForm title="회원구분" name="memType" items={MEMBER_TYPES} value={user?.memType} onChange={(e) => setUser({ ...user, [e.target.name]: e.target.value })} nullTitle="구분 선택" />
                <Separator />
                <InputForm title="이름" name="name" value={user?.name} onChange={(e) => setUser({ ...user, [e.target.name]: e.target.value })} placeholder="이름을 입력해 주세요" />
                <InputForm title="이메일" type="email" name="email" value={user?.email} onChange={(e) => setUser({ ...user, [e.target.name]: e.target.value })} placeholder="이메일을 입력해 주세요" />
                <InputForm title="비밀번호" type="password" name="passwd" value={user?.passwd} onChange={(e) => setUser({ ...user, [e.target.name]: e.target.value })} placeholder="비밀번호를 입력해 주세요" />
                <Separator />
                <InputForm title="휴대폰" name="mobile" value={user?.mobile} onChange={(e) => setUser({ ...user, [e.target.name]: e.target.value })} placeholder="휴대폰 번호를 입력해 주세요" />
                {/* <Separator />
                <SegmentedForm title="상태" items={MEMBER_STATUS} value={user?.status} onClick={(v) => setUser({ ...user, status: v })} /> */}
                <Separator />
                <div className="row">
                    <div className="col-md-12 text-right">
                        <div className="btn-group">
                            <button type="button" className="btn btn-primary" onClick={onSave}>
                                <i className="fa fa-save"></i> {idx === "ADD" ? "등록" : "수정"}
                            </button>
                            <button type="button" className="btn btn-default" onClick={onChange}>
                                <i className="fa fa-times"></i> 취소
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const MemberSiteForm = (props) => {
    const { idx, onChange } = props;
    const { cfg } = useContext(AppContext);
    
    const [sites, setSites] = useState([]);
    const [selected, setSelected] = useState([cfg.idx]);
    const [user, setUser] = useState({});
    
    const [options, setOptions] = useState([]);

    const onSave = () => {
        if (!idx) {
            swal({ title: "알림", text: "회원을 먼저 선택해주세요." });
            return;
        }
        
        const deleteIdxList = options.filter(val => !selected.includes(val.value)).map((delIdx)=> delIdx.value );

        const data = {
              memberIdx       : idx
            , siteIdxList     : selected
            , deleteIdxList   : deleteIdxList
        }

        _API.post({
            path: "/v1/a/member/site/save",
            data: data,
        }).then((res) => {
            swal({ title: "알림", text: `회원 사이트 권한이 저장되었습니다.` }).then(() => {
                onChange && onChange();
            });
        });
    };

    const loadData = () => {
        if (idx === "ADD") {
            return;
        }
        const req = {
            path: "/v1/a/member/site/list",
            data: { memberIdx : idx },
        };
        _API.get(req).then((res) => {
            const { memberSiteList, siteList } = res.data;
            setOptions(siteList.map( (site) => { return { label : site.title, value : site.idx} } ));
            setSelected(memberSiteList.map( (site) => site.siteIdx ));
        });
    };

    const onSelected = (selected) => {
        setSelected(selected);
    }

    useEffect(() => {
        loadData();
    }, [idx]);

    return (
        <div className="ibox">
            <div className="ibox-title">
                사이트 권한 할당
            </div>
            <div className="ibox-content">
                
                <DualListBox
                    canFilter
                    selectedHeader="asdf"
                    showHeaderLabels
                    options={options}
                    selected={selected}
                    onChange={onSelected}
                    filterPlaceholder="사이트명..."
                    icons={{
                        moveLeft: <span className="fa fa-chevron-left" />,
                        moveAllLeft: [
                            <span key={0} className="fa fa-chevron-left" />,
                            <span key={1} className="fa fa-chevron-left" />,
                        ],
                        moveRight: <span className="fa fa-chevron-right" />,
                        moveAllRight: [
                            <span key={0} className="fa fa-chevron-right" />,
                            <span key={1} className="fa fa-chevron-right" />,
                        ],
                        moveDown: <span className="fa fa-chevron-down" />,
                        moveUp: <span className="fa fa-chevron-up" />,
                    }}
                />
                <Separator />
                <div className="row">
                    <div className="col-md-12 text-right">
                        <div className="btn-group">
                            <button type="button" className="btn btn-primary" onClick={onSave}>
                                <i className="fa fa-save"></i> {"저장"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};