import React, { Fragment, useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import swal from "sweetalert";

import Modal from "react-modal";
import { ModalCloseButton } from "../../../components/UI/Button";
import { Theme } from "../../../components/GlobalStyles";
import Template from "../../../components/UI/Template";
import Send from "../../../components/UI/Send";

import { _API, _U } from "../../../modules/utils";
import { _DEF, _CFG } from "../../../modules/config";

import Layout from "../Layout";
import { AppContext } from "../../../components/AppProvider";
import { SearchInput, SelectForm, InputForm, SegmentedForm, SearchCondition } from "../../../components/UI/Form";
import { Separator } from "../../../components/UI/Wrapper";

export default (props) => {
    const { cfg } = useContext(AppContext);
    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [eventIdx, setEventIdx] = useState("");
    const [sendType, setSendType] = useState("");

    const [siteList, setSiteList] = useState([]);
    const [eventList, setEventList] = useState([]);
    const [list, setList] = useState([]);
    const [summary, setSummary] = useState({});

    const loadList = () => {
        if (!siteIdx) return;
        const data = {
            siteIdx,
            eventIdx,
            sendType,
        };
        const req = { path: "/v1/a/statistics/send", data };
        _API.get(req).then((res) => {
            const { list, summary } = res.data;
            setList(list);
            setSummary(summary);
        });
    };


    useEffect(() => {
        loadList();
    }, [siteIdx, eventIdx, sendType]);

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-12">
                    <h2>발송관리</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/">HOME</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/send/manage/statistics">발송관리</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>발송집계</strong>
                        </li>
                    </ol>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className="col-md-12">
                        <div className="ibox">
                            <div className="ibox-title">발송집계</div>
                            <div className="ibox-content ibox-heading">
                                <div className="row" style={{ marginBottom: "10px" }}>
                                    <div className="col-md-2">
                                        <select value={sendType} onChange={(e) => setSendType(e.target.value)} className="form-control">
                                            <option value="">발송구분</option>
                                            <option value={"sms"}>문자(SMS)</option>
                                            <option value={"mail"}>메일</option>
                                        </select>
                                    </div>
                                </div>
                                <SearchCondition
                                    sync={true}
                                    eventYn={"Y"}
                                    keywordYn={"N"}
                                    onChange={(e) => {
                                        setEventIdx(e.eventIdx);
                                    }}
                                    value={{ siteIdx, eventIdx }}
                                    setEventList={setEventList}
                                />
                            </div>
                            {summary && (
                                <div className="ibox-content" style={{ marginBottom: "10px" }}>
                                    <div className="row">
                                        <div className="col-sm-12 table-responsive">
                                            <table className="table table-striped table-hover">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center">총발송수</th>
                                                        <th className="text-center">대기</th>
                                                        <th className="text-center">성공</th>
                                                        <th className="text-center">실패</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr >
                                                        <td className="text-center">{_U.numberFormat(summary.totalCnt)}</td>
                                                        <td className="text-center">{_U.numberFormat(summary.waitCnt)}</td>
                                                        <td className="text-center">{_U.numberFormat(summary.successCnt)}</td>
                                                        <td className="text-center">{_U.numberFormat(summary.failCnt)}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="ibox-content">
                                <div className="row">
                                    <div className="col-sm-12 table-responsive">
                                        <table className="table table-striped table-hover">
                                            <thead>
                                                <tr>
                                                    <th className="text-center">사이트</th>
                                                    <th className="text-center">이벤트</th>
                                                    <th className="text-center">발송구분</th>
                                                    <th className="text-center">템플릿</th>
                                                    <th className="text-center">발송수</th>
                                                    <th className="text-center">대기</th>
                                                    <th className="text-center">성공</th>
                                                    <th className="text-center">실패</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(!list || list.length === 0) && (
                                                    <tr><td colSpan="7" className="text-center">발송내역이 없습니다.</td></tr>
                                                )}
                                                {list.map((item, i) => (
                                                    <tr key={i}>
                                                        <td className="text-center">{item.siteName}</td>
                                                        <td className="text-center">{item.eventName}</td>
                                                        <td className="text-center">{item.sendType === "sms" ? "문자" : item.sendType === "mail" ? "메일" : ""}</td>
                                                        <td className="text-center">{item.templateName}</td>
                                                        <td className="text-center">{_U.numberFormat(item.totalCnt)}</td>
                                                        <td className="text-center">{_U.numberFormat(item.waitCnt)}</td>
                                                        <td className="text-center">{_U.numberFormat(item.successCnt)}</td>
                                                        <td className="text-center">{_U.numberFormat(item.failCnt)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};