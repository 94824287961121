import React, { useContext, useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import styled from "styled-components";
import moment from "moment";
import Modal from "react-modal";

import { _API, _U } from "../../../../modules/utils";
import { _DEF } from "../../../../modules/config";
import Socket from "../../../../components/UI/Socket";
import swal from "sweetalert";

import { Files, Participant, Quiz, Question, Chatting } from "../../../../components/UI/Monitor";

export default (props) => {
    const { history, location, match } = props;
    const { idx } = match.params;

    const [isConnect, setIsConnect] = useState(false);
    const [sessionList, setSessionList] = useState([]);
    const [lectureIdx, setLectureIdx] = useState("");
    const [sessionIdx, setSessionIdx] = useState("");
    const [sessionTitle, setSessionTitle] = useState("");
    const [lectureTitle, setLectureTitle] = useState("");
    const [isLoadSession, setIsLoadSession] = useState(false);
    const [channelInfo, setChannelInfo] = useState(null);
    const [viewModal, setViewModal] = useState("");

    const debugSocket = false; //_CFG.isLocal();
    const sendChannel = () => {
        _U.postMessage("SOCKET-SEND", {
            action: "CHANNEL",
            data: {
                type: "SPEAKER",
                channel: idx,
            },
        });
    };
    const onConnected = () => {
        setIsConnect(true);
        sendChannel();
    };
    const onDisconnected = () => {
        setIsConnect(false);
    };

    // const loadData = () => {
    //     const req = { path: "/v1/a/event/console/active", data: { idx } };
    //     _API.get(req).then((res) => {
    //         const { session, lecture } = res.data;

    //         if (session) {
    //             setSessionIdx(session?.idx);
    //             setSessionTitle(session?.title);
    //         }
    //         if (lecture) {
    //             setLectureIdx(lecture?.idx);
    //             setLectureTitle(lecture?.title);
    //         }
    //     });
    // };

    const loadData = (idx) => {
        const req = { path: "/v1/a/event/console", data: { idx, sessionIdx, lectureIdx } };
        _API.get(req).then((res) => {
            const { list, channelInfo, lecture: selectLecture, session: selectSession } = res.data;
            setSessionList(list);
            setChannelInfo(channelInfo);
            setSessionIdx(selectSession?.idx || null);
            setLectureIdx(selectLecture?.idx || null);
            setIsLoadSession(true);
        });
    };

    const onReceivedMessage = (e) => {
        _U.parseMessage(e)
            .then((data) => {
                switch (data.code) {
                    case "SOCKET-RECEIVED":
                        return data.data ? JSON.parse(data.data) : null;
                }
            })
            .then((data) => {
                // if (data?.mode === "CHANGE-LECTURE") {
                //     loadData();
                // }
            });
    };

    // const selectSession = () => {
    //     if (!isLoadSession || sessionList.length === 0) {
    //         setSessionIdx(null);
    //         setSessionTitle(null);
    //         return;
    //     }

    //     const now = moment();
    //     const day = now.format("YYYY-MM-DD");
    //     const fs = sessionList.filter((s) => {
    //         const start = moment(`${day} ${s.startTime}`, "YYYY-MM-DD HH:mm:ss");
    //         const end = moment(`${day} ${s.endTime}`, "YYYY-MM-DD HH:mm:ss");
    //         return now.isBetween(start, end);
    //     });
    //     if (fs.length === 0) {
    //         setSessionIdx(null);
    //         setSessionTitle(null);
    //         return;
    //     }
    //     // console.log(fs[0]);
    //     setSessionIdx(fs[0].idx);
    //     setSessionTitle(fs[0].title);
    //     selectLecture(fs[0].lectureList);
    // };

    // const selectLecture = (lectures) => {
    //     if (!isLoadSession || lectures.length === 0) {
    //         setLectureIdx(null);
    //         setLectureTitle(null);
    //         return;
    //     }

    //     const now = moment();
    //     const day = now.format("YYYY-MM-DD");
    //     const fs = lectures.filter((s) => {
    //         const start = moment(`${day} ${s.startTime}`, "YYYY-MM-DD HH:mm:ss");
    //         const end = moment(`${day} ${s.endTime}`, "YYYY-MM-DD HH:mm:ss");
    //         return now.isBetween(start, end);
    //     });
    //     if (fs.length === 0) {
    //         setLectureIdx(null);
    //         setLectureTitle(null);
    //         return;
    //     }

    //     setLectureIdx(fs[0].idx);
    //     setLectureTitle(fs[0].title);
    // };

    // useEffect(() => {
    //     if (isLoadSession) {
    //         selectSession();
    //         const ss = setInterval(selectSession, 30000);
    //         return () => clearInterval(ss);
    //     }
    // }, [isLoadSession]);

    useEffect(() => {
        loadData(idx);
        window.addEventListener("message", onReceivedMessage);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, [idx]);

    useEffect(() => {
        sendChannel();
        const timer = setInterval(sendChannel, 10000);
        return () => clearInterval(timer);
    }, [idx]);

    useEffect(() => {
        setLectureIdx(null);
    }, [sessionIdx]);

    return (
        <Wrapper>
            {/* <div className="col-lg-6">
                    <div className="row">
                        <Files idx={idx} lectureIdx={lectureIdx} sessionIdx={sessionIdx} sessionTitle={sessionTitle} lectureTitle={lectureTitle} isWaiting={!sessionIdx || !lectureIdx} isMonitor={true} />
                    </div>
                </div> */}
            <div className="consoleItem">
                <Quiz idx={idx} lectureIdx={lectureIdx} sessionIdx={sessionIdx}
                    isSpeaker={true}
                    sessionList={sessionList} channelInfo={channelInfo}
                    onViewModal={(sessionId, lectureId) => {
                        setSessionIdx(sessionId);
                        setLectureIdx(lectureId);
                        setViewModal("Quiz");
                    }}
                />
            </div>
            <SocketWrapper debug={debugSocket}>
                <Socket debug={debugSocket} onConnected={onConnected} onDisconnected={onDisconnected} />
            </SocketWrapper>
            {viewModal === "Quiz" && (
                <Modal isOpen={viewModal ? true : false} style={modalStyles} ariaHideApp={false}>
                    <Quiz idx={idx} lectureIdx={lectureIdx} sessionIdx={sessionIdx}
                        isModal={true}
                        isSpeaker={true}
                        sessionList={sessionList} channelInfo={channelInfo}
                        onViewModal={() => {
                            setViewModal("");
                        }}
                    />
                </Modal>
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    position: relative;
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    height: calc(100vh - 1rem - 25px);

    .consoleItem {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0 5px;

        .ibox {
            flex: 1;
            :nth-child(2) {
                margin-top: 30px;
            }
        }
    }
    .ibox-content {
        overflow-x: hidden;
        overflow-y: auto;
        height: 100%;
    }
`;

const SocketWrapper = styled.div`
    position: fixed;
    right: 0;
    bottom: 0;
    ${(props) =>
        props.debug &&
        `
        width:450px; height:250px;
    `}
`;
const modalStyles = {
    overlay: { zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.75)" },
    content: {
        top: "2%",
        left: "2%",
        right: "2%",
        bottom: "2%",
        borderRadius: "10px",
        paddingBottom: "3rem",
        border: "0",
        overflowX: "hidden",
    },
};
