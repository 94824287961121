import React, { useContext, useState, useEffect, Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import styled from "styled-components";
import moment from "moment";
import swal from "sweetalert";

import { _API, _U } from "../../modules/utils";
import { _DEF } from "../../modules/config";
import Socket from "./Socket";
import { AppContext } from "../../components/AppProvider";

import Input, { SearchInput, SelectForm, InputForm, SegmentedForm } from "./Form";

export const Files = (props) => {
    const { idx, sessionIdx, lectureIdx, isWaiting, isMonitor } = props;
    const { sessionTitle, lectureTitle } = props;
    const [list, setList] = useState([]);
    const [sessionMgr, setSessionMgr] = useState("");

    const loadData = () => {
        const req = { path: "/v1/a/event/console/lecture", data: { idx, sessionIdx, lectureIdx } };
        _API.get(req).then((res) => {
            const { list, sessionMgrName } = res.data;
            setList(list);
            setSessionMgr(sessionMgrName);
        });
    };

    useEffect(() => {
        loadData();
    }, [idx, sessionIdx, lectureIdx]);

    return (
        <div className="ibox">
            <div className="ibox-title">
                <h5>
                    강의자료
                    <small className="m-l-sm">
                        {sessionTitle} {lectureTitle ? ` - ${lectureTitle}` : ``}
                    </small>
                </h5>
                <div className="ibox-tools"></div>
            </div>
            <div className={cx({ "ibox-content": true, top: true })}>
                {sessionMgr && (
                    <div className="row mb-2">
                        <div className="col-lg-12 text-right">
                            <small className="m-l-sm">좌장 : {sessionMgr}</small>
                        </div>
                    </div>
                )}

                {isWaiting || !lectureIdx ? (
                    <Waiting>강의를 준비 중 입니다.</Waiting>
                ) : (
                    <div className="table-responsive">
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th className="text-center">강의자료</th>
                                    {isMonitor ? <th className="text-center">강의설명</th> : <th className="text-center">강의명</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {list?.map((item, index) => (
                                    <tr key={index}>
                                        <td className="text-left">
                                            <a href={item.signedUrl} target="_blank">
                                                {item.name}
                                            </a>
                                        </td>
                                        <td className="text-left">{isMonitor ? item.lectureDescription : item.lectureTitle}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};

export const Participant = (props) => {
    const { idx } = props;
    const [list, setList] = useState([]);
    const [info, setInfo] = useState({});

    const loadData = () => {
        _API.get({
            path: "/v1/a/event/console/users",
            data: {
                idx,
            },
        }).then((res) => {
            const { data, channelInfo } = res.data;
            setList(data);
            setInfo(channelInfo);
        });
    };

    const handleEmergencyNotice = () => {
        swal({ title: "긴급공지", content: "input" }).then((text) => {
            if (text) {
                _U.postMessage("SOCKET-SEND", {
                    action: "EMERGENCY",
                    data: {
                        mode: "EMERGENCY",
                        channel: idx,
                        message: text,
                    },
                });

                /* 공지사항 저장 
                 * eventIdx, siteIdx, bbsCategory, bbsCode, noticeYn, title, content, answer: answer||null, status: "Y", regIdx : author.idx
                 * */

                _API.post({
                    path: `/v1/a/board/new`,
                    data: { eventIdx: info?.eventIdx, siteIdx: info?.siteIdx, channelIdx: idx, bbsCategory: "EMERGENCY", bbsCode: "EMERGENCY", noticeYn: "N", title: "", content: text, answer: null, status: "Y" },
                }).then((res) => {
                    swal({ title: "알림", text: `긴급공지가 등록 되었습니다.` });
                });

            }
        });
    };

    const handleEmergencyVote = (type) => {
        swal({ title: `${type === "after" ? "사후" : "사전"}설문`, text: "발송 하시겠습니까?", buttons: ["취소", "전송"] }).then((isOk) => {
            if (isOk) {
                _U.postMessage("SOCKET-SEND", {
                    action: "VOTE",
                    data: {
                        mode: "VOTE",
                        channel: idx,
                        eventIdx: info?.eventIdx,
                        siteIdx: info?.siteIdx,
                        type,
                    },
                });
            }
        });
    };

    useEffect(() => {
        loadData();
        const timer = setInterval(loadData, 60000);
        return () => clearInterval(timer);
    }, [idx]);

    const getTypeText = (type) => {
        switch (type) {
            case "OPERATOR":
                return "운영자";
            case "PRINCIPAL":
                return "좌장";
            case "SPEAKER":
                return "연자";
            case "QUIZ-MONITOR":
                return "퀴즈 모니터";
            case "QUESTION-MONITOR":
                return "질문 모니터";
            default:
                return "";
        }
    };

    const onDeleteSurveyAnswer = () => {
        if (!idx) {
            swal("채널정보가 없습니다.");
        }
        _API.delete({
            path: "/v1/a/event/console/survey/answers",
            data: {
                channelIdx: idx,
            },
        }).then((res) => {
            swal("설문답변이 초기화 되었습니다.");
        });
    }

    return (
        <div className="ibox">
            <div className="ibox-title">
                <h5>
                    참여자 <small className="m-l-sm label label-primary">{list?.length}명 접속중</small>
                </h5>
                <div className="ibox-tools" style={{ top: "0px" }}>
                    <button className="btn btn-xs btn-warning" onClick={onDeleteSurveyAnswer}><i className="fa fa-refresh"></i> 설문답변 초기화</button>
                    <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-info": true })} onClick={(e) => handleEmergencyVote("before")} style={{ margin: "15px 5px 15px 5px" }}>
                        사전설문
                    </button>
                    <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-info": true })} onClick={(e) => handleEmergencyVote("after")} style={{ margin: "15px 5px 15px 5px" }}>
                        사후설문
                    </button>
                    <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-danger": true })} onClick={handleEmergencyNotice} style={{ margin: "15px 5px 15px 5px" }}>
                        <i className="fa fa-bullhorn"></i>
                    </button>
                </div>
            </div>
            <div className={cx({ "ibox-content": true, top: true })}>
                <UContent>
                    {list?.map((item, i) => (
                        <span
                            key={i}
                            className={cx({
                                label: true,
                                "label-info": item.siteEventMemberType === "PARTICIPANT",
                                "label-danger": item.siteEventMemberType === "OPERATOR",
                                "label-success": item.siteEventMemberType === "PRINCIPAL",
                                "label-primary": item.siteEventMemberType === "SPEAKER",
                                "label-warning": item.siteEventMemberType.endsWith("MONITOR"),
                            })}
                            style={{ margin: "0rem 0.5rem 0.5rem 0rem", display: "inline-block" }}
                        >
                            {item.name || "익명"}
                            {item.siteEventMemberType !== "PARTICIPANT" && <small>({getTypeText(item.siteEventMemberType)})</small>}
                        </span>
                    ))}
                </UContent>
            </div>
            {/* <div className="ibox-footer">
                <span className="float-right">left</span>
                right
            </div> */}
        </div>
    );
};

const UContent = styled.div`
    position: relative;
    width: 100%;
    height: 90%;
    overflow-x: hidden;
    overflow-y: auto;
`;

export const Quiz = (props) => {
    const { idx, sessionIdx: sessionIdxProps, lectureIdx: lectureIdxProps, margin } = props;
    // const { sessionTitle, lectureTitle } = props;
    const { sessionList, channelInfo } = props;
    const [sessionIdx, setSessionIdx] = useState(sessionIdxProps);
    const [lectureIdx, setLectureIdx] = useState(lectureIdxProps);

    const [list, setList] = useState([]);
    const [time, setTime] = useState(10);

    const loadData = (sessionIdxParam) => {
        const nSessionIdx = sessionIdxParam || sessionIdx;
        if (idx && nSessionIdx) {
            const req = { path: "/v1/a/event/console/quiz", data: { idx, sessionIdx: nSessionIdx } };
            _API.get(req).then((res) => {
                setList(res.data);
            });
        }
    };

    const onReceivedMessage = (e) => {
        _U.parseMessage(e)
            .then((data) => {
                switch (data.code) {
                    case "SOCKET-RECEIVED":
                        return data.data ? JSON.parse(data.data) : null;
                }
            })
            .then((data) => {
                if (data?.mode === "QUIZ") {
                    loadData(data.sessionIdx);
                }
                if (data?.mode === "CHANGE-LECTURE") {
                    loadData(data.sessionIdx);
                }
                if (data?.sessionIdx) setSessionIdx(data.sessionIdx);
            });
    };

    useEffect(() => {
        // loadData();
        window.addEventListener("message", onReceivedMessage);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, [idx]);

    const onPlayQuiz = (item) => {
        _API.post({ path: "/v1/a/event/console/quiz/monitor", data: { channelIdx: item.channelIdx, sessionIdx: item.sessionIdx, lectureIdx: item.lectureIdx, idx: item.idx } }).then((res) => {
            _U.postMessage("SOCKET-SEND", {
                action: "QUIZ",
                data: {
                    channel: item.channelIdx,
                    quiz: { ...item, time },
                    sessionIdx: item.sessionIdx,
                    lectureIdx: item.lectureIdx,
                },
            });
            _U.postMessage("SOCKET-SEND", {
                action: "QUIZ-MONITOR",
                data: {
                    channel: item.channelIdx,
                    time,
                    sessionIdx: item.sessionIdx,
                    lectureIdx: item.lectureIdx,
                },
            });
            _U.postMessage("SOCKET-SEND", {
                action: "OPERATOR",
                data: {
                    channel: item.channelIdx,
                    mode: "QUIZ",
                    sessionIdx: item.sessionIdx,
                    lectureIdx: item.lectureIdx,
                },
            });

            swal({ title: "알림", text: "퀴즈가 발송 되었습니다." }).then(() => {
                loadData();
            });
        });
    };

    const onSendMonitor = (item) => {
        if (!item?.idx) {
            return;
        }
        _API.post({ path: "/v1/a/event/console/quiz/monitor", data: { channelIdx: item.channelIdx, sessionIdx: item.sessionIdx, lectureIdx: item.lectureIdx, idx: item.idx } }).then((res) => {
            _U.postMessage("SOCKET-SEND", {
                action: "QUIZ-MONITOR",
                data: {
                    channel: item.channelIdx,
                    sessionIdx: item.sessionIdx,
                    lectureIdx: item.lectureIdx,
                },
            });
            _U.postMessage("SOCKET-SEND", {
                action: "OPERATOR",
                data: {
                    channel: item.channelIdx,
                    mode: "QUIZ",
                    sessionIdx: item.sessionIdx,
                    lectureIdx: item.lectureIdx,
                },
            });
            loadData();
        });
    };

    const onGraphStatusChange = (item) => {
        if (!item?.idx) {
            return;
        }
        _API.post({ path: "/v1/a/event/console/quiz/monitor/graph", data: { channelIdx: item.channelIdx, sessionIdx: item.sessionIdx, lectureIdx: item.lectureIdx, idx: item.idx } }).then((res) => {
            _U.postMessage("SOCKET-SEND", {
                action: "QUIZ-MONITOR",
                data: {
                    channel: item.channelIdx,
                    sessionIdx: item.sessionIdx,
                    lectureIdx: item.lectureIdx,
                },
            });
            _U.postMessage("SOCKET-SEND", {
                action: "OPERATOR",
                data: {
                    channel: item.channelIdx,
                    mode: "QUIZ",
                    sessionIdx: item.sessionIdx,
                    lectureIdx: item.lectureIdx,
                },
            });
            loadData();
        });
    };

    const onClearAnswers = (item) => {
        _API.delete({
            path: "/v1/a/event/console/quiz/answers",
            data: {
                channelIdx: item.channelIdx,
                sessionIdx: item.sessionIdx,
                lectureIdx: item.lectureIdx,
                idx: item.idx,
            },
        }).then((res) => {
            swal({ title: "알림", text: "답변 내용이 초기화 되었습니다." });
        });
    };
    const timeCounts = Array.from({ length: 60 }, (v, i) => i + 1);

    useEffect(() => {
        setSessionIdx(sessionIdxProps);
        setLectureIdx(null);
    }, [sessionIdxProps]);

    useEffect(() => {
        setLectureIdx(lectureIdxProps);
    }, [lectureIdxProps]);

    useEffect(() => {
        loadData();
    }, [sessionIdx, lectureIdx]);

    const getSessionTitle = () => {
        const session = sessionList?.filter((s) => {
            return s.idx == sessionIdx;
        })[0];
        return session?.title || "";
    }

    const getLectureTitle = () => {
        const session = sessionList?.filter((s) => {
            return s.idx == sessionIdx;
        })[0];
        const lecture = session?.lectureList?.filter((s) => {
            return s.idx == lectureIdx;
        })[0];
        return lecture?.title ? " - " + lecture?.title : "";
    }

    return (
        <div className="ibox" style={margin}>
            <div className="ibox-title">
                <h5>
                    퀴즈
                    <small className="m-l-sm">
                        {props.isSpeaker && props.channelInfo?.title ? "[" + props.channelInfo?.title + "]" : ""}
                        {getSessionTitle()}
                        {/* {getLectureTitle()} */}
                    </small>
                </h5>

                <div className="ibox-tools">
                    <div className="btn-group">
                        <button className="btn btn-xs" onClick={(e) => { props.onViewModal && props.onViewModal(sessionIdx, lectureIdx); }}>
                            {props.isModal ? <i className="fa fa-remove text-danger"></i> : <i className="fa fa-desktop text-danger"></i>}
                        </button>
                        <select value={time} onChange={(e) => setTime(e.target.value)} className="form-control">
                            {timeCounts.map((v, i) => (
                                <option key={i} value={v}>
                                    {v}초
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <div className={cx({ "ibox-content": true })}>
                <div className="table-responsive">
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th className="text-center">질문</th>
                                <th className="text-center">관리</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list?.map((item, index) => (
                                <tr key={index}>
                                    <td className="text-left">{item.question}</td>
                                    <td className="text-center">
                                        <button className="btn btn-xs btn-warning" onClick={onClearAnswers.bind(this, item)}>
                                            <i className="fa fa-refresh"></i> 답변초기화
                                        </button>
                                        <div className="btn-group ml-3">
                                            <button className="btn btn-xs btn-primary" onClick={onPlayQuiz.bind(this, item)}>
                                                <i className="fa fa-play-circle"></i>
                                            </button>
                                            <button className={cx({ btn: true, "btn-xs": true, "btn-default": item.monitorYn !== "Y", "btn-success": item.monitorYn === "Y" })} onClick={onSendMonitor.bind(this, item)}>
                                                <i className="fa fa-desktop"></i>
                                            </button>
                                            <button className={cx({ btn: true, "btn-xs": true, "btn-default": item.graphYn !== "Y", "btn-warning": item.graphYn === "Y" })} onClick={onGraphStatusChange.bind(this, item)}>
                                                <i className="fa  fa-pie-chart"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* <div className="ibox-footer">
                <span className="float-right">left</span>
                right
            </div> */}
        </div>
    );
};

export const Question = (props) => {
    const { idx, sessionIdx: sessionIdxProps, lectureIdx: lectureIdxProps, isWaiting, margin, isPrincipal, isSpeaker } = props;
    const { sessionList } = props;
    const [list, setList] = useState([]);
    const [channelInfo, setChannelInfo] = useState({});
    const [sessionIdx, setSessionIdx] = useState(sessionIdxProps);
    const [lectureIdx, setLectureIdx] = useState(lectureIdxProps);

    const loadData = (sessionId, lectureId) => {
        const nSessionIdx = sessionId || sessionIdx;
        const nLectureIdx = lectureId || lectureIdx;
        if (!nSessionIdx) return;
        const req = { path: "/v1/a/event/console/question", data: { idx, sessionIdx: nSessionIdx, lectureIdx: nLectureIdx, isPrincipal: isPrincipal ? "Y" : "N" } };
        _API.get(req).then((res) => {
            setList(res.data);
        });
    };

    const loadChannelInfo = () => {
        const req = { path: "/v1/a/event/console/channel", data: { idx } };
        _API.get(req).then((res) => {
            setChannelInfo(res.data);
        });
    };

    const onReceivedMessage = (e) => {
        _U.parseMessage(e)
            .then((data) => {
                switch (data.code) {
                    case "SOCKET-RECEIVED":
                        return data.data ? JSON.parse(data.data) : null;
                }
            })
            .then((data) => {
                if (data?.mode === "QUESTION") {
                    loadData(data.sessionIdx, data.lectureIdx);
                    loadChannelInfo();
                }
                if (data?.mode === "CHANGE-LECTURE") {
                    if (data?.sessionIdx) setSessionIdx(data.sessionIdx);
                    if (data?.lectureIdx) setLectureIdx(data.lectureIdx);
                }
            });
    };

    useEffect(() => {
        loadData();
        loadChannelInfo();
        window.addEventListener("message", onReceivedMessage);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, [idx]);

    const onPrincipalStatusChange = (item) => {
        if (!item?.idx) {
            return;
        }
        _API.post({ path: "/v1/a/event/console/question/principal", data: { channelIdx: item.channelIdx, sessionIdx: item.sessionIdx, lectureIdx: item.lectureIdx, idx: item.idx } }).then((res) => {
            _U.postMessage("SOCKET-SEND", {
                action: "OPERATOR",
                data: {
                    channel: item.channelIdx,
                    mode: "QUESTION",
                    sessionIdx: item.sessionIdx,
                    lectureIdx: item.lectureIdx,
                },
            });
            loadData();
        });
    };
    const onMonitorStatusChange = (item) => {
        if (!item?.idx) {
            return;
        }
        _API.post({ path: "/v1/a/event/console/question/monitor", data: { channelIdx: item.channelIdx, sessionIdx: item.sessionIdx, lectureIdx: item.lectureIdx, idx: item.idx } }).then((res) => {
            _U.postMessage("SOCKET-SEND", {
                action: "QUESTION-MONITOR",
                data: {
                    channel: item.channelIdx,
                },
            });
            _U.postMessage("SOCKET-SEND", {
                action: "OPERATOR",
                data: {
                    channel: item.channelIdx,
                    mode: "QUESTION",
                    sessionIdx: item.sessionIdx,
                    lectureIdx: item.lectureIdx,
                },
            });
            loadData();
        });
    };
    const onDelete = (item) => {
        if (!item?.idx) {
            return;
        }
        _API.delete({ path: "/v1/a/event/console/question", data: { channelIdx: item.channelIdx, sessionIdx: item.sessionIdx, lectureIdx: item.lectureIdx, idx: item.idx } }).then((res) => {
            _U.postMessage("SOCKET-SEND", {
                action: "QUESTION-MONITOR",
                data: {
                    channel: item.channelIdx,
                },
            });
            _U.postMessage("SOCKET-SEND", {
                action: "OPERATOR",
                data: {
                    channel: item.channelIdx,
                    mode: "QUESTION",
                    sessionIdx: item.sessionIdx,
                    lectureIdx: item.lectureIdx,
                },
            });
            loadData();
        });
    };

    const principalStateChange = (channelIdx) => {
        if (!channelIdx) {
            return;
        }
        _API.post({ path: "/v1/a/event/console/channel/qnastate", data: { channelIdx, qnaState: channelInfo?.qnaState === "PRINCIPAL" ? null : "PRINCIPAL" } }).then((res) => {
            _U.postMessage("SOCKET-SEND", {
                action: "QUESTION",
                data: {
                    channel: channelIdx,
                    sessionIdx: sessionIdx,
                    lectureIdx: lectureIdx,
                },
            });
        });
    }

    useEffect(() => {
        setSessionIdx(sessionIdxProps);
        setLectureIdx(null);
    }, [sessionIdxProps]);

    useEffect(() => {
        setLectureIdx(lectureIdxProps);
    }, [lectureIdxProps]);

    useEffect(() => {
        loadData();
    }, [sessionIdx, lectureIdx]);

    const getSessionTitle = () => {
        const session = sessionList?.filter((s) => {
            return s.idx == sessionIdx;
        })[0];
        return session?.title || "";
    }

    return (
        <div className="ibox" style={margin}>
            <div className="ibox-title">
                <h5>
                    질문
                    <small className="m-l-sm">
                        {(isPrincipal || isSpeaker) && channelInfo?.title ? "[" + channelInfo?.title + "]" : ""}
                        {getSessionTitle()}
                        {/* {lectureTitle ? ` - ${lectureTitle}` : ``} */}
                    </small>
                </h5>

                <div className="ibox-tools">
                    <div className="btn-group">
                        {!isPrincipal && (
                            <button className={cx({ btn: true, "btn-xs": true, "btn-default": channelInfo?.qnaState !== "PRINCIPAL", "btn-primary": channelInfo?.qnaState === "PRINCIPAL" })} style={{ marginRight: "8px" }} onClick={principalStateChange.bind(this, idx)}>
                                좌장 바로보기 {channelInfo?.qnaState === "PRINCIPAL" ? "ON" : "OFF"}
                            </button>
                        )}
                        <button className="btn btn-xs"
                            onClick={(e) => {
                                props.onViewModal && props.onViewModal(sessionIdx, lectureIdx);
                            }}>
                            {props.isModal ? <i className="fa fa-remove text-danger"></i> : <i className="fa fa-desktop text-danger"></i>}
                        </button>
                    </div>
                </div>

            </div>
            <div className={cx({ "ibox-content": true })}>
                {!sessionIdx ? (
                    <Waiting>강의를 준비 중 입니다.</Waiting>
                ) : (
                    <div className="table-responsive">
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th className="text-center">시간</th>
                                    <th className="text-center">질문</th>
                                    <th className="text-center">사용</th>
                                    <th className="text-center">관리</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list?.map((item, index) => (
                                    <tr key={index}>
                                        <td className="text-left">{moment(item.regDate).format("HH:mm")}</td>
                                        <td className="text-left">{item.question}</td>
                                        <td className="text-center">{item.useYn}</td>
                                        <td className="text-center">
                                            <div className="btn-group ml-3">
                                                {!isPrincipal && (
                                                    <button className={cx({ btn: true, "btn-xs": true, "btn-default": item.principalYn !== "Y", "btn-primary": item.principalYn === "Y" })} onClick={onPrincipalStatusChange.bind(this, item)}>
                                                        <i className="fa fa-user"></i>
                                                    </button>
                                                )}

                                                <button className={cx({ btn: true, "btn-xs": true, "btn-default": item.monitorYn !== "Y", "btn-success": item.monitorYn === "Y" })} onClick={onMonitorStatusChange.bind(this, item)}>
                                                    <i className="fa fa-desktop"></i>
                                                </button>
                                                {!isPrincipal && (
                                                    <button className={cx({ btn: true, "btn-xs": true, "btn-danger": true })} onClick={onDelete.bind(this, item)}>
                                                        <i className="fa  fa-remove"></i>
                                                    </button>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};

export const Chatting = (props) => {
    const { idx, margin, isPrincipal, channelInfo } = props;
    const [messages, setMessages] = useState(props.initMessages || []);
    const [message, setMessage] = useState("");
    const [isSendProc, setIsSendProc] = useState(false);
    const endRef = useRef(null);
    const { iam } = useContext(AppContext);
    const [targetMemType, setTargetMemType] = useState("");

    const onSend = () => {
        if (!message) {
            return;
        }
        if (isSendProc) {
            return;
        }
        setIsSendProc(true);

        _U.postMessage("SOCKET-SEND", {
            action: "CHAT",
            data: {
                mode: "CHAT",
                id: `${channelInfo?.siteIdx}_${channelInfo?.eventIdx}_${idx}_MEMBER_${iam?.idx}`,
                channel: idx,
                name: isPrincipal ? "좌장" : "운영자",
                memType: isPrincipal ? "PRINCIPAL" : "OPERATOR",
                targetMemType,
                message,
            },
        });
        setMessage("");
        setIsSendProc(false);
    };

    const onReceivedMessage = (e) => {
        _U.parseMessage(e)
            .then((data) => {
                switch (data.code) {
                    case "SOCKET-RECEIVED":
                        return data.data ? JSON.parse(data.data) : null;
                }
            })
            .then((data) => {
                if (data?.mode === "CHAT"
                    // && (data?.memType === "OPERATOR" || data?.memType === "PRINCIPAL")
                ) {
                    setMessages((o) => [...o.slice(-99), data]);
                }
            });
    };

    useEffect(() => {
        window.addEventListener("message", onReceivedMessage);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, [idx]);

    const onKeyUp = (e) => {
        if (e.key === "Enter" && !isSendProc) {
            onSend();
        }
    };

    useEffect(() => {
        if (endRef?.current) {
            endRef.current.scrollTop = endRef?.current?.scrollHeight;
        }
    }, [messages]);

    return (
        <div className="ibox" style={margin}>
            <div className="ibox-title">
                <h5>채팅</h5>
                <div className="ibox-tools">
                    <div className="btn-group">
                        <button className="btn btn-xs" onClick={(e) => { props.onViewModal && props.onViewModal(); }}>
                            {props.isModal ? <i className="fa fa-remove text-danger"></i> : <i className="fa fa-desktop text-danger"></i>}
                        </button>
                        <select value={targetMemType} onChange={(e) => setTargetMemType(e.target.value)} className="form-control">
                            <option value={""}>좌장&운영자</option>
                            <option value={"ALL"}>전체</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className={cx({ "ibox-content": true })}>
                <CSend>
                    <div className="input-group">
                        <input type="text" className="form-control" value={message || ""} onChange={(e) => setMessage(e.target.value)} onKeyUp={onKeyUp} />
                        <div className="input-group-append">
                            <button type="button" className="btn btn-info" onClick={onSend}>
                                <i className="fa fa-send"></i>
                            </button>
                        </div>
                    </div>
                </CSend>
                <div style={{ height: (isPrincipal || props.isModal) ? (window.innerHeight - 200) + "px" : ((window.innerHeight / 2) - 180) + "px", overflowY: "auto" }} id="messageWrapper" ref={endRef}>
                    {messages.map((item, i) => (
                        <CMessage
                            key={i}
                            onClick={() => {
                                swal(item.message);
                            }}
                        >
                            <span className={cx({ label: true, "label-info": item.memType === "MEMBER", "label-danger": item.memType === "OPERATOR", "label-success": item.memType === "PRINCIPAL" })}>{item.name}</span>
                            {item.message}
                        </CMessage>
                    ))}
                    {/* <div ref={endRef} /> */}
                </div>
            </div>
        </div>
    );
};

const CMessage = styled.div`
    margin-bottom: 0.5rem;
    span {
        margin-right: 0.5rem;
    }
    font-size: 14px;
    cursor: pointer;
`;

const Waiting = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    height: 100%;
`;

const CSend = styled.div`
    position: sticky;
    top: 0;
    margin-bottom: 10px;
`;

const ModalButton = styled.div`
    // position: absolute;
    // ${(props) => (props.top ? `top: ${props.top};` : `top: 1rem;`)}
    // ${(props) => (props.right ? `right: ${props.right};` : `right: 1rem;`)}
    // ${(props) => (props.left ? `left: ${props.left};` : ``)}
    
    cursor: pointer;
`;