import React, { Fragment, useContext, useEffect, useState } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";

import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U, _CK } from "../../../modules/utils";

import { ConnectionButton, H2, H3, HRDash, ItemEmpty } from "./UI/Components";

export default (props) => {
    const { cfg, auth, iam } = useContext(AppContext);
    const event = cfg.event || null;
    const [list, setList] = useState([]);
    const [info, setInfo] = useState("");

    const [toggle, setToggle] = useState({});

    if (!event) {
        return null;
    }

    const { siteIdx, eventIdx } = event;

    const loadList = () => {
        _API.get({
            path: "/v1/site/event/faq",
            data: {
                siteIdx,
                eventIdx,
            },
        }).then((res) => {
            const { list } = res.data;
            setList(list);
            list.length > 0 && setInfo(list[0]);
        });
    };

    if (!event) {
        return null;
    }

    const viewInfo = (item) => {
        setToggle({ ...toggle, [item.idx]: !toggle[item.idx] ? true : false });
    };

    useEffect(() => {
        loadList();
    }, []);

    return (
        <>
            <H2 style={{ textAlign: "center" }}>FAQ</H2>
            <Wrapper>
                <>
                    {list.map((item, i) => (
                        <Fragment key={i}>
                            <Article onClick={(e) => viewInfo(item)} key={i}>
                                <Title>
                                    <span>Q.</span>
                                    <div>{item.title}</div>
                                </Title>

                                {toggle[item.idx] && (
                                    <ArticleInfo
                                        dangerouslySetInnerHTML={{
                                            __html: item.content,
                                        }}
                                    ></ArticleInfo>
                                )}
                            </Article>
                        </Fragment>
                    ))}
                </>
            </Wrapper>
        </>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    margin: 0 auto;
    justify-content: space-around;
    flex-direction: column;
`;

const Article = styled.div`
    align-items: center;
    flex-basis: 30%;
    background-color: ${(props) => props.theme.basic.bg.background};
    cursor: pointer;

    :first-child {
        border-top: 1px solid ${(props) => props.theme.basic.line.darkHr};
    }
    border-bottom: 1px solid ${(props) => props.theme.basic.line.tr};

    :last-child {
        border-bottom: 1px solid ${(props) => props.theme.basic.line.darkHr};
    }
`;

const Title = styled.div`
    & span {
        float: left;
        font-family: ${(props) => props.theme.fontFamily.medium};
        font-size: 20px;
        color: ${(props) => props.theme.basic.color.primary};
        line-height: 25px;
        margin-right: 15px;
    }

    & div {
        font-size: 16px;
        color: ${(props) => props.theme.basic.color.primary};
        line-height: 33px;
    }

    padding: 10px 20px;
`;

const ArticleInfo = styled.div`
    border-top: dashed 1px ${(props) => props.theme.basic.line.default};

    padding: 20px;
    background: ${(props) => props.theme.basic.bg.boardContent};

    & p {
        margin-top: 14px;
        font-family: ${(props) => props.theme.fontFamily.medium};
        font-size: 14px;
        color: ${(props) => props.theme.basic.color.primary};

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin: 0px;
        padding: 0px;
    }

    opacity: "0";
    visibility: "hidden";
    transition: "opacity 300ms , visibility 300ms";
`;

const HRSolid = styled.hr`
    border-top: 1px solid #e7e7e7;
    width: 100%;
`;

const active = {
    opacity: "1",
    transition: "opacity 300ms",
};

const hidden = {
    opacity: "0",
    visibility: "hidden",
    transition: "opacity 300ms , visibility 300ms",
};
