import React, { Fragment, useContext, useEffect, useState } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";

import { isMobile } from "react-device-detect";

import Modal from "react-modal";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U, _CK } from "../../../modules/utils";
// import { _CFG } from "../../../modules/config"

import { H2 } from "./UI/Components";

import { Theme } from "../../../components/GlobalStyles";

export default (props) => {
    const { cfg, auth, iam } = useContext(AppContext);
    const event = cfg.event || null;
    const [list, setList] = useState([]);
    const [info, setInfo] = useState("");
    const [click, setClick] = useState(true);

    if (!event) {
        return null;
    }

    const { siteIdx, eventIdx } = event;

    const loadList = () => {
        _API.get({
            path: "/v1/site/event/board",
            data: {
                siteIdx,
                eventIdx,
            },
        }).then((res) => {
            const { list } = res.data;
            setList(list);
            console.log(list);
            if (!isMobile) {
                list.length > 0 && setInfo(list[0]);
            }
        });
    };

    const viewInfo = (item) => {
        setClick(false);

        setTimeout(() => {
            setInfo(item);
            setClick(true);
        }, 300);
    };

    useEffect(() => {
        loadList();
    }, []);

    return (
        <Wrapper>
            <ListWrapper>
                <H2>공지사항</H2>
                <BoardList>
                    {list?.map((item, index) => (
                        <>
                            <li
                                onClick={(e) => {
                                    viewInfo(item);
                                }}
                            >
                                <span className="notice_cnt">{index + 1}.</span>
                                <div>
                                    <div className="notice_title">{item.title}</div>
                                    <span className="notice_date">{item.regDate && moment(item.regDate).format("YYYY. MM. DD")}</span>
                                </div>
                            </li>
                            {isMobile && item.idx === info?.idx && (
                                <li className="content">
                                    <ContentWrap style={click && item.idx === info?.idx ? active : hidden}>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: item?.content,
                                            }}
                                        ></div>
                                        {info.bbsFileSignedUrl && (
                                            <div>
                                                <a href={info.bbsFileSignedUrl} target="_blank">
                                                    {info.bbsFileName}
                                                </a>
                                            </div>
                                        )}
                                    </ContentWrap>
                                </li>
                            )}
                        </>
                    ))}
                </BoardList>
            </ListWrapper>
            {!isMobile && (
                <ContentWrap style={click ? active : hidden}>
                    <ContentTitle>{info.title}</ContentTitle>
                    <hr />
                    <div
                        dangerouslySetInnerHTML={{
                            __html: info?.content,
                        }}
                    ></div>
                    {info.bbsFileSignedUrl && (
                        <div>
                            <a href={info.bbsFileSignedUrl} target="_blank">
                                {info.bbsFileName}
                            </a>
                        </div>
                    )}
                </ContentWrap>
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
`;

const ListWrapper = styled.div`
    flex: 2;
`;

const ContentWrap = styled.div`
    flex: 5;
    margin: 20px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 20px;

    & hr {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        margin-top: 0;
    }

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        border-left: none;
        padding: 10px 0;
        margin: 0px;
    }
`;

const BoardList = styled.ul`
    flex-direction: column;
    display: flex;
    flex-wrap: nowrap;
    padding-left: 0;
    list-style: none;
    margin-top: 30px;

    width: 100%;
    height: 90%;
    overflow-x: hidden;
    overflow-y: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }

    & li {
        display: flex;
        border-bottom: 1px solid #9a9a9a;
        padding: 20px 7px 7px 7px;
        cursor: pointer;

        .notice_cnt {
            margin-right: 5px;
            font-family: ${(props) => props.theme.fontFamily.medium};
            font-size: 20px;
            line-height: 28px;
            color: #000000;
        }

        .notice_title {
            font-family: ${(props) => props.theme.fontFamily.medium};
            font-size: 16px;
            text-align: left;
            color: ${(props) => props.theme.basic.primary};
        }

        .notice_date {
            font-family: ${(props) => props.theme.fontFamily.medium};
            font-size: 10px;
            color: #bbbbbb;
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        li.content {
            padding: 10px;
            background: ${(props) => props.theme.basic.bg.boardContent};
        }
    }
`;

const ContentTitle = styled.label`
    color: ${(props) => props.theme.basic.color.primary};
    font-size: 20px;
    font-family: ${(props) => props.theme.fontFamily.medium};
`;

const active = {
    opacity: "1",
    transition: "opacity 300ms",
};

const hidden = {
    opacity: "0",
    visibility: "hidden",
    transition: "opacity 300ms , visibility 300ms",
};
