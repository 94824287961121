import _CK from "../utils/cookie";
import _U from "../utils/utilities";

const config = {
    apiKey: "986d9e733298b314c86631c9ddaab23d8183400a420c957db784d77d3df03f8a",
    dm9ApiKey: "LlwVkfR6MWzviFpA03Oq7tT5HdJQmgK8",
    google: {
        maps: {
            apiKey: "AIzaSyByU4xgpSzrDltOxZQPCRCrml94ZtiXyow",
        },
    },
};
config.isLocal = () => {
    const { hostname } = window.location;
    if (hostname.startsWith("local")) {
        return true;
    } else {
        return false;
    }
};
config.apiHost = () => {
    const { hostname } = window.location;
    if (hostname.startsWith("local")) {
        return "http://172.22.248.134:3100";
    } else if (hostname.startsWith("test")) {
        return "https://api-webinar-test.dmon9.com";
    } else {
        return "https://api-webinar.dmon9.com";
    }
};
config.wsHost = () => {
    const { hostname } = window.location;
    const authors = {
        apikey: config.apiKey,
    };
    if (_CK.isLogin()) {
        authors.authorization = _CK.getAuth();
    }
    if (hostname.startsWith("local")) {
        return `ws://172.22.248.134:3300?auth=${_U.encode(authors)}`;
    } else if (hostname.startsWith("test")) {
        return `wss://ws-webinar-test.dmon9.com?auth=${_U.encode(authors)}`;
    } else if (hostname.startsWith("test")) {
        return `wss://ws-webinar-test.dmon9.com?auth=${_U.encode(authors)}`;
    } else {
        return `wss://ws-webinar.dmon9.com?auth=${_U.encode(authors)}`;
    }
};

config.APIUrl = () => {
    const { hostname } = window.location;

    if (hostname.startsWith("local")) {
        return "http://172.22.248.134:3100";
    } else if (hostname.startsWith("test") || hostname.startsWith("admin-test")) {
        return "https://api-webinar-test.dmon9.com";
    } else {
        return "https://api-webinar.dmon9.com";
    }
};

export default config;
