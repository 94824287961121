import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

import { AppContext } from "../../components/AppProvider";
import { _API, _CK, _U } from "../../modules/utils";

import Wrapper, { PageWrapper } from "../../components/UI/Wrapper";
import Button from "../../components/UI/Button";

export default (props) => {
    const { history, location, match } = props;
    const cp = { history, location, match };

    const { auth, iam } = useContext(AppContext);

    useEffect(() => {
        if (!_API.isFrontAdmin(iam.memType)) {
            document.location.href = "/#/";
        }
    }, [auth]);

    useEffect(() => {
        correctHeight();
        detectBody();
    }, [props]);

    useEffect(() => {
        $("body").css("backgroundColor", "#2f4050");
        $(window).bind("load resize", function () {
            correctHeight();
            detectBody();
        });

        $(".metismenu a").click(() => {
            setTimeout(() => {
                correctHeight();
            }, 300);
        });
        return () => {
            $("body").css("backgroundColor", "");
        };
    }, []);

    return (
        <Wrapper>
            <Navigation {...cp} />
            <PageWrapper>
                <Header />
                {props.children}
                <Footer />
            </PageWrapper>
        </Wrapper>
    );
};

const Navigation = (props) => {
    const { history, location, match } = props;
    const cp = { history, location, match };
    const { iam, siteIdx, siteEventIdx, cfg } = useContext(AppContext);

    const menu = useRef(null);

    useEffect(() => {
        try {
            $(menu.current).metisMenu("dispose");
        } catch (e) { }
        $(menu.current).metisMenu();
    }, [siteIdx, siteEventIdx]);

    const handleCloseNav = (e) => {
        $("body").toggleClass("mini-navbar");
        smoothlyMenu();
    };

    return (
        <nav className="navbar-default navbar-static-side" role="navigation">
            <div className="sidebar-collapse">
                <a className="close-canvas-menu" onClick={handleCloseNav.bind(this)}>
                    <i className="fa fa-times"></i>
                </a>
                <ul className="nav metismenu" id="side-menu" ref={menu}>
                    <li className="nav-header">
                        <div className="profile-element text-center">
                            <a href="#">
                                <span className="block m-t-xs font-bold">
                                    {iam.name} {iam.memType === "DEVELOPER" ? <small>(개발자)</small> : ""}
                                </span>
                            </a>
                        </div>
                        <div className="logo-element">D</div>
                    </li>
                    <li className={activeRoute(location, "/admin/dashboard")}>
                        <Link to={"/admin/dashboard"}>
                            <i className="fa fa-th-large"></i> <span className="nav-label">대시보드</span>
                        </Link>
                    </li>
                    <li className={activeRoute(location, "/admin/member")}>
                        <Link to={"/admin/member"}>
                            <i className="fa fa-users"></i> <span className="nav-label">회원관리</span>
                        </Link>
                    </li>
                    <li className={activeRoute(location, "/admin/lecturer")}>
                        <Link to={"/admin/lecturer/list"}>
                            <i className="fa fa-vcard-o"></i> <span className="nav-label">CV관리</span>
                        </Link>
                    </li>
                    <li className={activeRoute(location, "/admin/location")}>
                        <Link to={"/admin/location/list"}>
                            <i className="fa fa-map"></i> <span className="nav-label">장소관리</span>
                        </Link>
                    </li>
                    <li className={activeRoute(location, "/event")}>
                        <a href="#">
                            <i className="fa fa-indent"></i> <span className="nav-label">이벤트</span>
                            <span className="fa arrow"></span>
                        </a>
                        <ul className={secondLevelActive(location, "/admin/event")}>
                            <li className={activeRoute(location, "/admin/event/list") || activeRoute(location, "/admin/event/form")}>
                                <Link to="/admin/event/list">
                                    <i className="fa fa-etsy"></i> 이벤트
                                </Link>
                            </li>
                            <li className={activeRoute(location, "/admin/event/content")}>
                                <Link to="/admin/event/content">
                                    <i className="fa fa-th"></i> 컨텐츠
                                </Link>
                            </li>
                            {/* <li className={activeRoute(location, "/admin/event/ebooth")}>
                                <Link to="/admin/event/ebooth">
                                    <i className="fa fa-cube"></i> 이부스
                                </Link>
                            </li> */}
                            <li className={activeRoute(location, "/admin/event/channel", true)}>
                                <Link to="/admin/event/channel">
                                    <i className="fa fa-toggle-right"></i> 채널
                                </Link>
                            </li>
                            <li className={activeRoute(location, "/admin/event/channel/session", true)}>
                                <Link to="/admin/event/channel/session">
                                    <i className="fa fa-folder-open-o"></i> 세션
                                </Link>
                            </li>
                            <li className={activeRoute(location, "/admin/event/channel/session/lecture", true)}>
                                <Link to="/admin/event/channel/session/lecture">
                                    <i className="fa fa-film"></i> 강좌
                                </Link>
                            </li>
                            <li className={activeRoute(location, "/admin/event/channel/session/vod/log", true)}>
                                <Link to="/admin/event/channel/session/vod/log">
                                    <i className="fa fa-line-chart"></i> 다시보기 집계
                                </Link>
                            </li>
                            <li className={activeRoute(location, "/admin/event/channel/survey")}>
                                <Link to="/admin/event/channel/survey">
                                    <i className="fa fa-pencil-square-o"></i> 설문
                                </Link>
                            </li>
                            {cfg?.quizYn === "Y" && (
                                <li className={activeRoute(location, "/admin/event/channel/quiz")}>
                                    <Link to="/admin/event/channel/quiz">
                                        <i className="fa fa-question-circle"></i> 퀴즈
                                    </Link>
                                </li>
                            )}
                            {cfg?.qnaYn === "Y" && (
                                <li className={activeRoute(location, "/admin/event/channel/qna")}>
                                    <Link to="/admin/event/channel/qna">
                                        <i className="fa fa-quora"></i> Q&A
                                    </Link>
                                </li>
                            )}
                            {cfg?.registrationYn === "Y" && (
                                <li className={activeRoute(location, "/admin/event/registration")}>
                                    <Link to="/admin/event/registration">
                                        <i className="fa fa-ticket"></i> 사전등록
                                    </Link>
                                </li>
                            )}
                            <li className={activeRoute(location, "/admin/event/participant")}>
                                <Link to="/admin/event/participant">
                                    <i className="fa fa-slideshare"></i> 참여자
                                </Link>
                            </li>
                            {cfg?.eposterYn === "Y" && (
                                <li className={activeRoute(location, "/admin/event/abstract")}>
                                    <Link to="/admin/event/abstract">
                                        <i className="fa fa-graduation-cap"></i> <span className="nav-label">초록/포스터</span>
                                    </Link>
                                </li>
                            )}
                            {cfg?.eboothYn === "Y" && (
                                <li className={activeRoute(location, "/admin/event/ebooth/company")}>
                                    <Link to="/admin/event/ebooth/company">
                                        <i className="fa fa-graduation-cap"></i> <span className="nav-label">입점업체</span>
                                    </Link>
                                </li>
                            )}
                            {cfg?.event?.eboothGradeYn === "Y" && (
                                <li className={activeRoute(location, "/admin/event/ebooth/main")}>
                                    <Link to="/admin/event/ebooth/main">
                                        <i className="fa fa-bank"></i> <span className="nav-label">프리미엄 이부스</span>
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </li>
                    {cfg?.attendanceYn === "Y" && (
                        <li className={activeRoute(location, "/admin/attend")}>
                            <Link to={"/admin/attend/list"}>
                                <i className="fa fa-table"></i> <span className="nav-label">출결관리</span>
                            </Link>
                        </li>
                    )}
                    {cfg?.eboothYn === "Y" && (
                        <Fragment>
                            <li className={activeRoute(location, "/admin/company/access/list")}>
                                <Link to={"/admin/company/access/list"}>
                                    <i className="fa fa-table"></i> <span className="nav-label">이부스열람</span>
                                </Link>
                            </li>
                            <li className={activeRoute(location, "/admin/company/attend")}>
                                <Link to={"/admin/company/attend"}>
                                    <i className="fa fa-table"></i> <span className="nav-label">이부스출결</span>
                                </Link>
                            </li>
                        </Fragment>
                    )}
                    {/* <li className={activeRoute(location, "/board")}>
                        <Link to={"/admin/board"}>
                            <i className="fa fa-commenting-o" aria-hidden="true"></i>
                            <span className="nav-label">게시판</span>
                        </Link>
                    </li> */}
                    {iam.memType === "DEVELOPER" && (
                        <li className={activeRoute(location, "/admin/dev")}>
                            <a href="#">
                                <i className="fa fa-code"></i> <span className="nav-label">개발자</span>
                                <span className="fa arrow"></span>
                            </a>
                            <ul className={secondLevelActive(location, "/admin/dev")}>
                                <li className={activeRoute(location, "/admin/dev/dashboard")}>
                                    <Link to="/admin/dev/dashboard">
                                        <i className="fa fa-th-large"></i> 대시보드
                                    </Link>
                                </li>
                                <li className={activeRoute(location, "/admin/dev/api")}>
                                    <a href="#">
                                        <i className="fa fa-github"></i> API
                                        <span className="fa arrow"></span>
                                    </a>
                                    <ul className={thirdLevelActive(location, "/admin/dev/api")}>
                                        <li>
                                            <a href="https://api-webinar-test.dmon9.com/docs" target="_blank">
                                                Swagger
                                            </a>
                                        </li>
                                        <li className={activeRoute(location, "/admin/dev/api/encoder")}>
                                            <Link to="/admin/dev/api/encoder">인코딩/디코딩</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className={activeRoute(location, "/admin/dev/upload")}>
                                    <a href="#">
                                        <i className="fa fa-upload"></i> 업로드
                                        <span className="fa arrow"></span>
                                    </a>
                                    <ul className={thirdLevelActive(location, "/admin/dev/upload")}>
                                        <li className={activeRoute(location, "/admin/dev/upload/example")}>
                                            <Link to={"/admin/dev/upload/example"}>예제</Link>
                                        </li>
                                        <li className={activeRoute(location, "/admin/dev/upload/video")}>
                                            <Link to={"/admin/dev/upload/video"}>비디오</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className={activeRoute(location, "/admin/dev/wysiwyg")}>
                                    <Link to="/admin/dev/wysiwyg">
                                        <i className="fa fa-edit"></i> Wysiwyg 에디터
                                    </Link>
                                </li>
                                <li className={activeRoute(location, "/admin/dev/socket")}>
                                    <Link to="/admin/dev/socket">
                                        <i className="fa fa-flash"></i> 소켓
                                    </Link>
                                </li>
                                <li className={activeRoute(location, "/admin/dev/sample")}>
                                    <Link to="/admin/dev/sample">
                                        <i className="fa fa-th-large"></i> 샘플
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    )}
                    <li className={activeRoute(location, "/admin/bbs/notice")}>
                        <Link to={"/admin/bbs/notice"}>
                            <i className="fa fa-bell"></i> <span className="nav-label">공지사항</span>
                        </Link>
                    </li>
                    <li className={activeRoute(location, "/admin/emergency")}>
                        <Link to={"/admin/emergency"}>
                            <i className="fa fa-bullhorn"></i> <span className="nav-label">긴급공지</span>
                        </Link>
                    </li>
                    <li className={activeRoute(location, "/admin/bbs/faq")}>
                        <Link to={"/admin/bbs/faq"}>
                            <i className="fa fa-book"></i> <span className="nav-label">FAQ</span>
                        </Link>
                    </li>
                    <li className={activeRoute(location, "/admin/statistics/attend")}>
                        <Link to={"/admin/statistics/attend"}>
                            <i className="fa fa-pie-chart"></i> <span className="nav-label">접속통계</span>
                        </Link>
                    </li>

                    {(cfg?.siteEmailYn === "Y" || cfg?.siteSmsYn === "Y") && (
                        <li className={activeRoute(location, "/admin/send/manage")}>
                            <a href="#">
                                <i className="fa fa-envelope-open"></i> <span className="nav-label">발송관리</span>
                                <span className="fa arrow"></span>
                            </a>
                            <ul className={secondLevelActive(location, "/admin/send/manage")}>
                                {
                                    cfg?.siteSmsYn === "Y" && (
                                        <li className={activeRoute(location, "/admin/send/manage/sms")}>
                                            <Link to={"/admin/send/manage/sms"}>
                                                <i className="fa fa-mobile" style={{ fontSize: "20px", marginRight: "10px" }}></i> <span className="nav-label"> SMS</span>
                                            </Link>
                                        </li>
                                    )
                                }
                                {
                                    cfg?.siteEmailYn === "Y" && (
                                        <li className={activeRoute(location, "/admin/send/manage/mail")}>
                                            <Link to={"/admin/send/manage/mail"}>
                                                <i className="fa fa-envelope"></i> <span className="nav-label">MAIL</span>
                                            </Link>
                                        </li>
                                    )
                                }
                                <li className={activeRoute(location, "/admin/send/manage/statistics")}>
                                    <Link to={"/admin/send/manage/statistics"}>
                                        <i className="fa fa-line-chart"></i> <span className="nav-label"> 발송집계</span>
                                    </Link>
                                </li>

                            </ul>
                        </li>
                    )}

                </ul>
            </div>
        </nav>
    );
};

const Header = () => {
    const { saveAuth, saveIam } = useContext(AppContext);

    const toggleNavigation = (e) => {
        e.preventDefault();
        $("body").toggleClass("mini-navbar");
        smoothlyMenu();
    };
    const onLogoutClick = () => {
        _CK.clearAuth();
        saveIam(null);
        saveAuth("");
    };

    return (
        <div className="row border-bottom">
            <nav className="navbar navbar-static-top white-bg" role="navigation" style={{ marginBottom: 0 }}>
                <div className="navbar-header">
                    <a className="navbar-minimalize minimalize-styl-2 btn btn-primary " href="#" onClick={toggleNavigation.bind(this)}>
                        <i className="fa fa-bars"></i>{" "}
                    </a>
                </div>
                <ul className="nav navbar-top-links navbar-right">
                    <li>
                        <Button className="btn btn-link" onClick={onLogoutClick}>
                            <i className="fa fa-sign-out"></i> Log out
                        </Button>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

const Footer = (props) => {
    const { iam } = useContext(AppContext);
    return (
        <div className="footer">
            <div className="pull-right">Logined by {iam.name}</div>
            <div>
                <strong>Copyright</strong> 디몬나인 주식회사 &copy; 2021
            </div>
        </div>
    );
};

/**
 * Functions
 */
const activeRoute = (location, routeName, isStrict) => {
    if (isStrict) {
        return location.pathname === routeName ? "active" : "";
    }
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
};
const secondLevelActive = (location, routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "nav nav-second-level collapse in" : "nav nav-second-level collapse";
};
const thirdLevelActive = (location, routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "nav nav-third-level collapse in" : "nav nav-third-level collapse";
};

const correctHeight = () => {
    var pageWrapper = $("#page-wrapper");
    var navbarHeight = $("nav.navbar-default").height();
    var wrapperHeigh = pageWrapper.height();

    if (navbarHeight > wrapperHeigh) {
        pageWrapper.css("min-height", navbarHeight + "px");
    }

    if (navbarHeight <= wrapperHeigh) {
        if (navbarHeight < $(window).height()) {
            pageWrapper.css("min-height", $(window).height() + "px");
        } else {
            pageWrapper.css("min-height", navbarHeight + "px");
        }
    }

    if ($("body").hasClass("fixed-nav")) {
        if (navbarHeight > wrapperHeigh) {
            pageWrapper.css("min-height", navbarHeight + "px");
        } else {
            pageWrapper.css("min-height", $(window).height() - 60 + "px");
        }
    }
};

const detectBody = () => {
    if ($(document).width() < 769) {
        $("body").addClass("canvas-menu");
        $("body").addClass("body-small");
    } else {
        $("body").removeClass("canvas-menu");
        $("body").removeClass("body-small");
    }
};

const smoothlyMenu = () => {
    if (!$("body").hasClass("mini-navbar") || $("body").hasClass("body-small")) {
        $("#side-menu").hide();
        setTimeout(function () {
            $("#side-menu").fadeIn(400);
        }, 200);
    } else if ($("body").hasClass("fixed-sidebar")) {
        $("#side-menu").hide();
        setTimeout(function () {
            $("#side-menu").fadeIn(400);
        }, 100);
    } else {
        $("#side-menu").removeAttr("style");
    }
};
