import React, { useEffect, useState, useContext, useRef, Fragment } from "react";
import styled from "styled-components";
import nl2br from "nl2br";
import { isMobile } from "react-device-detect";
import cx from "classnames";
import ScriptTag from "react-script-tag";
import TimeAgo from "react-timeago";
import koStrings from "react-timeago/lib/language-strings/ko";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import moment from "moment";

import { _API, _U, _CK } from "../../../modules/utils";

import { AppContext } from "../../../components/AppProvider";

import { ICEnterOff, ICEnterOffSet, ICEnterOn, ICEnterOnSet, getBgSet } from "./asset";

export default (props) => {

    const { iam } = useContext(AppContext);
    const { abstractIdx, url } = props;

    const [comment, setComment] = useState("");
    const [isSendProc, setIsSendProc] = useState(false);
    const [list, setList] = useState([]);
    const [commentIdx, setCommentIdx] = useState("");
    const inputRef = useRef(null);
    const [page, setPage] = useState(1);
    const [endList, setEndList] = useState(false);

    const onKeyUp = (e) => {
        if (e.key === "Enter" && !isSendProc) {
            onSave();
        }
    };

    const onSave = () => {
        if (!comment) {
            return;
        }
        if (isSendProc) {
            return;
        }
        setIsSendProc(true);
        setPage(1);

        _API.post({ path: "/v1/site/event/eposter/comment", data: { abstractIdx, comment, idx: commentIdx } }).then((res) => {
            setComment("");
            setCommentIdx("");
            setIsSendProc(false);
        });
    };

    const loadList = () => {
        _API.get({ path: "/v1/site/event/eposter/comment", data: { abstractIdx, page, numPerPage: 10 } }).then((res) => {
            const { paging } = res.data;

            if (page > 1) {
                if (paging.finalPageNo >= page) {
                    const currentList = JSON.parse(JSON.stringify(list));
                    currentList.push.apply(currentList, res.data.list);
                    setList(currentList);
                } else {
                    setEndList(true);
                }
            } else {
                setList(res.data.list);
            }
        });
    };

    const onSetModify = (item) => {
        inputRef.current.focus();
        setComment(item.content);
        setCommentIdx(item.idx);
    };

    const onRemove = (idx) => {
        swal({
            title: "정말 삭제 하시겠습니까?",
            text: "삭제된 데이터는 복구가 불가합니다 .",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                setIsSendProc(true);
                _API.delete({ path: `/v1/site/event/eposter/comment`, data: { abstractIdx, idx } }).then((res) => {
                    setPage(1);
                    setIsSendProc(false);
                });
            }
        });
    };

    useEffect(() => {
        if (!isSendProc) {
            loadList();
        }
    }, [abstractIdx, isSendProc]);

    useEffect(() => {
        loadList();
    }, [page]);

    return (
        <CommentWrapper style={!url ? {float: "left", width: "22%"} : {}}>
            <CName>
                {iam?.name} {iam?.companyName && <>({iam?.companyName})</>}
            </CName>
            <CInputWrapper>
                <CInput placeholder="댓글을 입력하세요." onChange={(e) => setComment(e.target.value)} onKeyUp={onKeyUp} value={comment} ref={inputRef} />
                <CButton ison={comment ? true : false} onClick={onSave} />
            </CInputWrapper>
            <CListWrapper>
                {list?.map((item, i) => (
                    <CListItem key={i}>
                        <CName>
                            {item.name} {item.companyName && <>({item.companyName})</>}
                            <span>
                                <Time date={moment(item.regDate).toDate()} />
                            </span>
                            {item?.isMy > 0 && (
                                <span>
                                    <a onClick={onSetModify.bind(this, item)}>수정</a> | <a onClick={onRemove.bind(this, item.idx)}>삭제</a>
                                </span>
                            )}
                        </CName>
                        <CContent>{item.content}</CContent>
                    </CListItem>
                ))}
                {!endList && <CMore onClick={() => setPage(page + 1)}>More +</CMore>}
            </CListWrapper>
        </CommentWrapper>
    );
};
    
const CommentWrapper = styled.div`
    border-top: 1px solid ${(props) => props.theme.basic.line.default};
    width: 80%;
    margin: 30px auto;
    padding-bottom: 100px;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        width: 90% !important;
        float: none !important;
    }
`;

const CInputWrapper = styled.div`
    border-bottom: 1px solid ${(props) => props.theme.basic.line.header};
    width: 100%;
    display: grid;
    grid-template-columns: 90% 10%;
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        grid-template-columns: 80% 20%;
    }
`;

const CInput = styled.input.attrs({
    type: "text",
})`
    width: 100%;
    border: none;
    font-size: 14px;
    padding: 15px 0;
    grid-column-start: 1;
    grid-column-end: 2;
`;

const CButton = styled.div`
    grid-column-start: 2;
    grid-column-end: 3;
    cursor: pointer;

    ${(props) =>
        props.ison
            ? `
        background-image: ${`url(${ICEnterOn})`};
        background-image: -webkit-image-set(${getBgSet(ICEnterOnSet)});
    `
            : `
    background-image: ${`url(${ICEnterOff})`};
    background-image: -webkit-image-set(${getBgSet(ICEnterOffSet)});    
    
    `}
    background-repeat: no-repeat;
    background-position: center right 10px;
    background-size: 26px 17px;
`;

const CName = styled.div`
    margin-top: 15px;
    font-size: 14px;
    font-family: ${(props) => props.theme.fontFamily.bold};
    color: ${(props) => props.theme.basic.color.primary};

    span {
        font-size: 12px;
        font-family: ${(props) => props.theme.fontFamily.regular};
        color: ${(props) => props.theme.basic.color.off};
        margin-left: 8px;

        :nth-child(2) {
            color: ${(props) => props.theme.basic.color.primary};
            font-family: ${(props) => props.theme.fontFamily.medium};
            cursor: pointer;
        }
    }
`;

const CListWrapper = styled.div`
    margin-top: 20px;
`;

const CListItem = styled.div``;

const CContent = styled.div`
    font-size: 14px;
    font-family: ${(props) => props.theme.fontFamily.regular};
    color: ${(props) => props.theme.basic.color.primary};
`;

const CMore = styled.button.attrs({
    className: "btn btn-default btn-block",
})`
    width: 50%;
    margin: 10px auto;
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        width: 100%;
    }
`;

const Time = styled(TimeAgo).attrs((props) => {
    return {
        formatter: buildFormatter(koStrings),
    };
})``;