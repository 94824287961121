import React, { useContext, useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import swal from "sweetalert";
import Modal from "react-modal";

import { _API, _U } from "../../../modules/utils";
import { _DEF } from "../../../modules/config";

import Layout from "../Layout";

import { AppContext } from "../../../components/AppProvider";
import Common from "../../../components/UI/Common";
import { ModalCloseButton } from "../../../components/UI/Button";
import { Theme } from "../../../components/GlobalStyles";
import Input, { SearchInput, SelectForm, InputForm, SegmentedForm, FileForm, AddressForm } from "../../../components/UI/Form";
import Paging from "../../../components/UI/Paging";
import { Separator } from "../../../components/UI/Wrapper";

export default (props) => {
    const { history, location, match } = props;

    const { cfg } = useContext(AppContext);

    const [mode, setMode] = useState("");
    const [siteList, setSiteList] = useState([]);
    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [eventList, setEventList] = useState([]);
    const [eventIdx, setEventIdx] = useState("");

    const [keyword, setKeyword] = useState("");
    const [list, setList] = useState([]);
    const [paging, setPaging] = useState(null);

    const [data, setData] = useState(null);

    const loadList = (page) => {
        const data = {
            siteIdx,
            eventIdx,
            keyword,
            // page,
        };

        const req = { path: "/v1/a/event/company", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setList(list);
            setPaging(paging);
        });
    };

    const loadEventList = () => {
        if (!siteIdx) {
            setEventList([]);
            setEventIdx("");
            return;
        }

        const data = {
            siteIdx,
        };

        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list } = res.data;
            setEventList(list);
        });
    };

    useEffect(() => {
        loadList(1);
        loadEventList();
    }, []);

    useEffect(() => {
        loadList(1);
    }, [eventIdx]);

    const movePage = (page) => {
        loadList(page);
    };

    const onSearch = (e) => {
        loadList(1);
    };

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-8">
                    <h2>EBOOTH</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/admin">HOME</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to={`${location.pathname}`}>이벤트</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>EBOOTH</strong>
                        </li>
                    </ol>
                </div>
                <div className="col-sm-4">
                    <div className="title-action">
                        <button
                            type="button"
                            className="btn btn-primary ml-2"
                            onClick={(e) => {
                                setMode("ADD");
                                setData(null);
                            }}
                        >
                            <i className="fa fa-plus"></i> 추가
                        </button>
                    </div>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className={cx({ "col-md-12": !mode, "col-md-8": mode === "ADD" })}>
                        <div className="ibox">
                            <div className="ibox-title">입점 업체 목록</div>
                            <div className="ibox-content ibox-heading">
                                <div className="row">
                                    <div className="col-md-3">
                                        <SelectForm
                                            name="eventIdx"
                                            items={eventList.map((event) => {
                                                return { label: event.eventName, value: event.idx };
                                            })}
                                            value={eventIdx}
                                            onChange={(e) => {
                                                setEventIdx(e.target.value);
                                            }}
                                            nullTitle={eventList?.length > 0 ? `이벤트 선택` : `사이트를 먼저 선택하세요.`}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <SearchInput value={keyword} onChange={(e) => setKeyword(e.target.value)} onClick={onSearch} placeholder="검색어를 입력해 주세요." />
                                    </div>
                                </div>
                            </div>
                            <div className="ibox-content">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th className="text-center">이벤트명</th>
                                                <th className="text-center">업체명</th>
                                                {/* <th className="text-center">이메일</th>
                                                <th className="text-center">전화번호</th> */}
                                                <th className="text-center">주소</th>
                                                {/* <th className="text-center">상세주소</th> */}
                                                <th className="text-center">등급</th>
                                                <th className="text-center">EBOOTH</th>
                                                <th className="text-center">SATELLITE</th>
                                                <th className="text-center">순번</th>
                                                <th className="text-center">상태</th>
                                                <th className="text-center">관리</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list?.map((item, i) => (
                                                <ListItem
                                                    {...item}
                                                    key={i}
                                                    onModify={(data) => {
                                                        setData(data);
                                                        setMode("ADD");
                                                    }}
                                                    refresh={() => {
                                                        loadList(paging?.pageNo || 1);
                                                    }}
                                                />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {/* {paging && (
                                <div className="ibox-footer">
                                    <Paging paging={paging} pageCallback={movePage} />
                                </div>
                            )} */}
                        </div>
                    </div>
                    {mode === "ADD" && (
                        <div className="col-md-4">
                            <Form
                                data={data}
                                onClose={() => {
                                    setMode("");
                                }}
                                callback={() => {
                                    loadList(1);
                                    setMode("");
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
};

const ListItem = (props) => {
    const onChangeStatus = (status) => {
        swal({
            title: `EBOOTH`,
            text: `상태를 변경하시겠습니까?`,
            icon: "info",
            buttons: ["취소", "예"],
            dangerMode: status === "D" ? true : false,
        }).then((ok) => {
            if (ok) {
                const req = { path: "/v1/a/event/company/status", data: { idx: props.idx, status } };
                _API.post(req).then((res) => {
                    props.refresh && props.refresh();
                });
            }
        });
    };

    const onRemove = () => {
        swal({
            title: "정말 삭제 하시겠습니까?",
            text: "삭제된 데이터는 복구가 불가합니다 .",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                _API.post({ path: `/v1/a/event/company/status`, data: { idx: props.idx, status: "D" } }).then((res) => {
                    swal({ title: "알림", text: `ebooth 입점 업체가 삭제되었습니다.` }).then(() => {
                        props.refresh && props.refresh();
                    });
                });
            }
        });
    };

    const weights = [...Array(100).keys()];

    const onChangeSort = (e) => {
        const req = { path: "/v1/a/event/company/sort", data: { idx: props.idx, sort: e.target.value } };
        _API.post(req).then((res) => {
            props.refresh && props.refresh();
        });
    };

    const openEboothForm = (eboothItemIdx) => {
        window.location.href = `#/admin/event/ebooth/company/content/${props.idx}`;
    }

    const openSatelliteForm = () => {
        window.location.href = `#/admin/event/ebooth/company/satellite/${props.idx}`;
    }

    const isUsedSatellite = () => {
        return props.satelliteCnt;
    }

    return (
        <tr>
            <td className="text-center">{props.eventName}</td>
            <td className="text-center">{props.name}</td>
            {/* <td className="text-center">{props.email}</td>
            <td className="text-center">{props.phone}</td> */}
            <td className="text-center">{props.addr}</td>
            {/* <td className="text-center">{props.addrDtl}</td> */}
            <td className="text-center">{props.grade && _DEF.getDefinedLabel(_DEF.EBOOTH_GRADE, props.grade)}</td>
            <td className="text-center">
                <div className="btn-group">
                    {props.eboothItemIdx ?
                        (<button type="button" className="btn btn-warning ml-2" onClick={(e) => openEboothForm(props.eboothItemIdx)}>
                            <i className="fa fa-edit"></i> 수정
                        </button>) :
                        (<button type="button" className="btn btn-primary ml-2" onClick={(e) => openEboothForm()}>
                            <i className="fa fa-plus"></i> 등록
                        </button>)}
                </div>
            </td>
            <td className="text-center">
                {props.satelliteYn === "Y" && (
                    <div className="btn-group" style={{ paddingLeft: '10px' }}>
                        {isUsedSatellite() ?
                            (<button type="button" className="btn btn-warning ml-2" onClick={(e) => openSatelliteForm(props.eboothItemIdx)}>
                                <i className="fa fa-edit"></i> 수정
                            </button>) :
                            (<button type="button" className="btn btn-primary ml-2" onClick={(e) => openSatelliteForm()}>
                                <i className="fa fa-plus"></i> 등록
                            </button>)
                        }
                    </div>
                )}
            </td>

            <td className="text-center">
                <select name="sort" onChange={onChangeSort.bind(this)} value={props.sort} className="form-control">
                    {weights.map((i) => (
                        <option value={i + 1} key={i}>
                            {i + 1}
                        </option>
                    ))}
                </select>
            </td>
            {/* <td className="text-center">{props.mgrName}</td>
            <td className="text-center">{props.mgrPhone}</td>
            <td className="text-center">{props.mgrEmail}</td> */}
            <td className="text-center">
                <div className="btn-group">
                    <button className={cx({ btn: true, "btn-xs": true, "btn-default": props.status !== "Y", "btn-success": props.status === "Y" })} onClick={onChangeStatus.bind(this, "Y")}>
                        사용
                    </button>
                    <button className={cx({ btn: true, "btn-xs": true, "btn-default": props.status !== "N", "btn-success": props.status === "N" })} onClick={onChangeStatus.bind(this, "N")}>
                        사용안함
                    </button>
                </div>
            </td>
            <td className="text-center">
                <div className="btn-group">
                    <button className={cx({ btn: true, "btn-xs": true, "btn-warning": true })} onClick={props.onModify.bind(this, props)}>
                        <i className="fa fa-edit"></i>
                    </button>
                    <button className={cx({ btn: true, "btn-xs": true, "btn-danger": true })} onClick={onRemove}>
                        <i className="fa fa-trash"></i>
                    </button>
                </div>
            </td>
        </tr>
    );
};

const Form = (props) => {


    const { cfg } = useContext(AppContext);
    const [siteIdx, setSiteIdx] = useState(cfg.idx);

    const { onClose, data, callback } = props;

    const [eventList, setEventList] = useState([]);
    const [eventInfo, setEventInfo] = useState({});

    const [form, setForm] = useState({
        status: "Y",
        siteIdx
    });

    const onSave = () => {
        if (!form?.siteIdx) {
            swal("사이트를 선택하세요.");
            return;
        }
        if (!form?.eventIdx) {
            swal("이벤트를 선택하세요.");
            return;
        }
        if (!form?.name) {
            swal("업체명을 입력하세요.");
            return;
        }
        if (!form?.logoFileIdx) {
            swal("로고를 첨부하세요.");
            return;
        }
        if (!form?.email) {
            swal("대표 이메일을 입력하세요.");
            return;
        }
        if (form?.homepageUrl && form?.homepageUrl.indexOf("http") < 0) {
            swal({ title: "알림", text: "홈페이지 URL은 http:// 혹은 https:// 를 포함하여 등록해 주세요." });
            return;
        }

        const req = { path: "/v1/a/event/company", data: form };
        _API.post(req).then((res) => {
            setForm({
                status: "Y",
            });
            swal(form?.idx ? `수정 되었습니다.` : `저장 되었습니다.`).then(() => {
                try {
                    document.getElementById("logoFile").value = "";
                } catch (e) { }
                callback && callback();
            });
        });
    };

    const loadEventList = (siteIdx) => {
        if (!siteIdx) {
            setEventList([]);
            return;
        }

        const data = {
            siteIdx,
        };

        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list } = res.data;
            setEventList(list);
        });
    };

    useEffect(() => {
        loadEventList(siteIdx);
    }, [])

    useEffect(() => {
        if (data?.idx) {
            setForm(data);
            loadEventList(data.siteIdx || siteIdx);
        }
    }, [data]);

    useEffect(() => {
        if (data?.idx) {
            setForm(data);
            loadEventList(data.siteIdx || siteIdx);
        } else {
            setForm({ status: "Y", siteIdx });
            setEventList([]);
        }
    }, [data]);

    useEffect(() => {
        if (form?.eventIdx) {
            setEventInfo(eventList.filter(item => item.idx == form?.eventIdx)[0] || {});
        } else {
            setEventInfo({});

        }
    }, [form?.eventIdx, eventList]);

    const fileUpload = (name, item) => {
        const urlName = name.replace("FileIdx", "SignedUrl");
        setForm({ ...form, [name]: item.id, [urlName]: item.signedUrl, logoFileName: item.name });
    };

    const onAddressChange = (addr) => {
        setForm({ ...form, ...addr });
    };

    const weights = [...Array(100).keys()];

    return (
        <div className="ibox">
            <div className="ibox-title">EBOOTH 입점 업체 {form?.idx ? `수정` : `등록`}</div>
            <div className="ibox-content">
                <SelectForm
                    title="이벤트"
                    name="eventIdx"
                    items={eventList.map((event) => {
                        return { label: event.eventName, value: event.idx };
                    })}
                    value={form?.eventIdx}
                    onChange={(e) => {
                        setForm({ ...form, [e.target.name]: e.target.value });
                    }}
                    nullTitle={eventList?.length > 0 ? `이벤트 선택` : `사이트를 먼저 선택하세요.`}
                />

                <InputForm title="업체명" name="name" value={form?.name} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="입점 회사 이름 입력" />
                <SegmentedForm title="세틀라이트" items={_DEF.STATUS} value={form?.satelliteYn} onClick={(v) => setForm({ ...form, "satelliteYn": v })} />
                {eventInfo?.eboothGradeYn === "Y" && (
                    <SegmentedForm title="등급" items={_DEF.EBOOTH_GRADE} value={form?.grade} onClick={(v) => setForm({ ...form, "grade": v })} />
                )}
                <FileForm title="로고" onChange={(v) => fileUpload("logoFileIdx", v)} path={`EVENT/COMPANY/${moment().format("YYYY/MM/DD")}`} url={form?.finalSignedUrl} accept="image/*" fileName={form?.logoFileName} id="logoFile" description="*권장해상도 100 x 40" />
                <InputForm title="대표 이메일" name="email" value={form?.email} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="대표 이메일 입력" />
                <Separator />

                <InputForm title="홈페이지 URL" name="homepageUrl" value={form?.homepageUrl} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="홈페이지 URL 입력" description="http:// 혹은 https://를 포함하여 등록해 주세요." />
                <InputForm title="대표 전화번호" name="phone" type="number" value={form?.phone} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="대표 전화번호 입력" />
                <AddressForm title="주소" values={form} onChange={onAddressChange} />
                <InputForm title="담당자 이름" name="mgrName" value={form?.mgrName} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="담당자 이름 입력" />
                <InputForm title="담당자 전화번호" name="mgrPhone" type="number" value={form?.mgrPhone} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="담당자 전화번호 입력" />
                <InputForm title="담당자 이메일" name="mgrEmail" value={form?.mgrEmail} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="담당자 이메일 입력" />
                <Separator />
                <SegmentedForm title="이부스 입점" items={_DEF.STATUS} value={form?.eboothYn || "N"} onClick={(v) => setForm({ ...form, "eboothYn": v })} />
                <SegmentedForm title="채널 홍보여부" items={_DEF.STATUS} value={form?.channelYn || "N"} onClick={(v) => setForm({ ...form, "channelYn": v })} />
                <Separator />
                <SelectForm title="정렬순서" name="sort"
                    items={weights.map((sort) => {
                        return { label: sort + 1, value: sort + 1 };
                    })}
                    value={form?.sort}
                    onChange={(e) => {
                        setForm({ ...form, [e.target.name]: e.target.value });
                    }}
                    nullTitle={"정렬 순서를 선택하세요."}
                />

                {/* <SegmentedForm title="상태" items={_DEF.STATUS} value={form?.status} onClick={(v) => setForm({ ...form, status: v })} /> */}
                <div className="hr-line-dashed"></div>
                <div className="row">
                    <div className="col-md-12 text-right">
                        <button className="btn btn-primary ml-2" onClick={onSave}>
                            <i className="fa fa-save"></i> 저장
                        </button>
                        <button className="btn btn-default" onClick={onClose}>
                            <i className="fa fa-remove"></i> 취소
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
