import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { isMobile } from "react-device-detect";
import cx from "classnames";

import { AppContext } from "../../../components/AppProvider";
import { _U, _CK, _API } from "../../../modules/utils";
import { _CFG } from "../../../modules/config";

import { ICSoundOffBlack, ICSoundOnBlack } from "./asset";

// import Video from "../../../components/UI/Video";
import videojs from "video.js";
import "video.js/dist/video-js.min.css";

import * as _ from "lodash";

export default (props) => {
	const { auth, cfg, socketConnect } = useContext(AppContext);
	const { history, location, match, url, setIntroVodView } = props;
	const [muted, setMuted] = useState(true);

	const videoNode = useRef(null);
	const player = useRef(null);
	// const url = "http://local.dmon9.com:3100/stream/52/'%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%85%E1%85%A9%E1%86%AB%20%E1%84%86%E1%85%A5%E1%84%89%E1%85%B3%E1%84%8F%E1%85%B3(Elon%20Musk)'%20Full%20Story.m3u8";

	const videoEnd = (e) => {
		setIntroVodView && setIntroVodView(false);
	};

	const goNext = () => {
		videoEnd();
	};

	useEffect(() => {
		if (url) {
			if (!player.current) {
				player.current = videojs(videoNode.current);
			}
			player.current.src({ type: "application/x-mpegURL", src: `${url}?apikey=${encodeURIComponent(_CFG.apiKey)}` });
			player.current.autoplay("muted");
			player.current.on("ended", videoEnd);
		}

		return () => {
			if (player.current) {
				player.current.dispose();
				player.current = null;
			}
		};
	}, []);

	const onClickSound = (muted) => {
		player.current.muted(muted);
		setMuted(muted);
	};

	return (
		<Wrapper>
			<VideoWrapper
				ref={videoNode}
				className="video-js"
				controls={false}
				// controls={true}
				preload="auto"
				autoPlay={false}
				muted={false}
				playsInline
			/>
			<Sound>
				<button type="button" onClick={() => onClickSound(!muted)}>
					<i className={cx({ fa: true, "fa-volume-off": muted, "fa-volume-up": !muted })}></i>
				</button>
			</Sound>
			<SkipButton onClick={() => goNext()}>Skip</SkipButton>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	/* width: 100%; */
	/* height:100%; */
	width: 100vw;
	height: 100vh;
	/* overflow: hidden; */
	/* max-height: 100%; */
	@media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
		/* width: 100%; */
		/* height: 100%; */
	}
`;

const Sound = styled.div`
	position: absolute;
	right: 20px;
	top: 0px;

	> button {
		border: none;
		background: #000;
		color: #ffffff;
		font-size: 50px;
	}

	/* > button > img {
		width: 80px;
		height: auto;
	} */
`;

const VideoWrapper = styled.video`
	width: 100%;
	height: 100%;

	> video {
		min-height: 100%;
		min-width: 100%;
		height: 100% !important;
		width: 100% !important;
	}

	.vjs-big-play-button {
		top: 50%;
		left: 50%;
		margin-top: -0.75em;
		margin-left: -1.5em;
	}
`;

const SkipButton = styled.div`
	width: 120px;
	height: 42px;
	position: fixed;
	bottom: 36px;
	left: 50%;
	transform: translateX(-50%);
	cursor: pointer;
	color: rgb(255, 255, 255);
	background-color: rgb(57, 60, 111);
	border-radius: 21px;
	align-items: center;
	justify-content: center !important;
	text-align: center;
	font-family: "NotoSans-R";
	line-height: 42px;
`;
