import React, { useContext, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import SNEditor from "../../../components/UI/SNEditor";

import Layout from "../Layout";

export const AdminBoardDetailView = (props) => {

    const history = useHistory();

    const [content, setContent] = useState("<p>Hi~~</p>");
    
    const togleChatBot = (e) => {
        e.preventDefault();
        $(this).children().toggleClass('fa-comments').toggleClass('fa-times');
        $('.small-chat-box').toggleClass('active');
    }

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-12">
                    <h2>게시판 디테일</h2>
                    <div className="btn-group float-right">
                        <button className="btn btn-white btn-sm" onClick={(e) => history.replace("/admin/board")} ><i className="fa fa-chevron-left"></i></button>
                    </div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/admin">HOME</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/admin/board">게시판</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>게시판 디테일</strong>
                        </li>
                    </ol>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className="col-lg-3">
                        
                    <div className="ibox">
                        {/* <div className="ibox-title"></div> */}
                        <div className="ibox-content">
                        
                            {/* 컨첸츠 */}
                            <div className="form-group row"><label className="col-sm-2 col-form-label">제목:</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" placeholder="제목"/>
                                </div>
                            </div>
                            <div className="form-group row"><label className="col-sm-2 col-form-label">카테고리:</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" placeholder="카테고리"/>
                                </div>
                            </div>
                            <div className="form-group row"><label className="col-sm-2 col-form-label">작성자:</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" placeholder="작성자"/>
                                </div>
                            </div>
                            <div className="form-group row"><label className="col-sm-2 col-form-label">공개여부:</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" placeholder="공개여부"/>
                                </div>
                            </div>
                            <div className="form-group row"><label className="col-sm-2 col-form-label">삭제여부:</label>
                                <div className="col-sm-10">
                                    <div className="form-check abc-radio abc-radio-info form-check-inline">
                                        <input className="form-check-input" type="radio" value="Y" name="radioInline" />
                                        <label className="form-check-label" htmlFor="inlineRadio1"> Y </label>
                                    </div>
                                    <div className="form-check abc-radio abc-radio-info form-check-inline">
                                        <input className="form-check-input" type="radio" value="N" name="radioInline" defaultChecked />
                                        <label className="form-check-label" htmlFor="inlineRadio1"> N </label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row"><label className="col-sm-2 col-form-label">Description:</label>
                                <div className="col-sm-10">
                                    <div className="ibox-content no-padding">
                                        <SNEditor content={content} onChange={setContent} />
                                        {/* <div
                                            dangerouslySetInnerHTML={{
                                                __html: content,
                                            }}
                                        /> */}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row"><label className="col-sm-2 col-form-label">Meta Tag Title:</label>
                                <div className="col-sm-10"><input type="text" className="form-control" placeholder="..."/></div>
                            </div>

                            {/* 챗봇 */}
                            <div className="small-chat-box fadeInRight animated">

                                <div className="heading" draggable="true">
                                    <small className="chat-date float-right">
                                        02.19.2015
                                    </small>
                                    Small chat
                                </div>

                                <div className="content" style={{overflow: "scroll", height: "235px"}}>

                                    <div className="left">
                                        <div className="author-name">
                                            Monica Jackson <small className="chat-date">
                                            10:02 am
                                        </small>
                                        </div>
                                        <div className="chat-message active">
                                            Lorem Ipsum is simply dummy text input.
                                        </div>

                                    </div>
                                    <div className="right">
                                        <div className="author-name">
                                            Mick Smith
                                            <small className="chat-date">
                                                11:24 am
                                            </small>
                                        </div>
                                        <div className="chat-message">
                                            Lorem Ipsum is simpl.
                                        </div>
                                    </div>
                                    <div className="left">
                                        <div className="author-name">
                                            Alice Novak
                                            <small className="chat-date">
                                                08:45 pm
                                            </small>
                                        </div>
                                        <div className="chat-message active">
                                            Check this stock char.
                                        </div>
                                    </div>
                                    <div className="right">
                                        <div className="author-name">
                                            Anna Lamson
                                            <small className="chat-date">
                                                11:24 am
                                            </small>
                                        </div>
                                        <div className="chat-message">
                                            The standard chunk of Lorem Ipsum
                                        </div>
                                    </div>
                                    <div className="left">
                                        <div className="author-name">
                                            Mick Lane
                                            <small className="chat-date">
                                                08:45 pm
                                            </small>
                                        </div>
                                        <div className="chat-message active">
                                            I belive that. Lorem Ipsum is simply dummy text.
                                        </div>
                                    </div>


                                </div>
                                <div className="form-chat">
                                    <div className="input-group input-group-sm">
                                        <input type="text" className="form-control" />
                                        <span className="input-group-append"> <button
                                            className="btn btn-primary" type="button">Send
                                    </button> </span></div>
                                </div>

                            </div>
                            <div id="small-chat">

                                <span className="badge badge-warning float-right">5</span>
                                <a className="open-small-chat" href="#" onClick={togleChatBot}>
                                    <i className="fa fa-comments"></i>
                                </a>
                            </div>
                            {/* 챗봇 END */}

                            {/* 컨텐츠 END */}

                        </div>
                    </div>

                    </div>
                </div>
            </div>
        </Layout>
    );
};