import React, { useContext, useState, useEffect, Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import swal from "sweetalert";
import styled from "styled-components";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import Layout from "../Layout";

// 파일 업로드
import * as FilePond from "filepond";
import { FilePond as FilePondForm } from "react-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

// React Select
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import makeAnimated from "react-select/animated";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";
import { _DEF } from "../../../modules/config";

import Wrapper, { Row, FormGroup, Separator } from "../../../components/UI/Wrapper";
import { InputForm, SegmentedForm, FileForm, TimePicker, DatePicker } from "../../../components/UI/Form";

import EMPTY_PROFILE from "../../../resources/img/empty.png";

import { omit } from 'lodash';

export default (props) => {
    const { history, location, match } = props;
    const { companyIdx } = match.params;
    const { cfg } = useContext(AppContext);
    const [siteIdx, setSiteIdx] = useState(cfg.idx);

    const [satelliteIdx, setSatelliteIdx] = useState(null);
    const [satelliteData, setSatelliteData] = useState({ companyIdx });
    const [mode, setMode] = useState("");
    const [list, setList] = useState([]);

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [alreadySelectedDate, setAlreadySelectedDate] = useState([]);
    const [companyInfo, setCompanyInfo] = useState({});

    useEffect(() => {
        loadList();
        loadEventList();
        checkCompanyIdx();
    }, [])

    const checkCompanyIdx = () => {
        const data = {
            siteIdx,
            companyIdx
        };

        const req = { path: "/v1/a/event/company/check", data };
        _API.get(req).then((res) => {
        }).catch((err) => {
            window.location.href = "#/admin/event/ebooth/company"
        });
    }


    const openSatelliteLectureList = (satelliteIdx) => {
        setSatelliteIdx(satelliteIdx);
        setMode("LECTURE");
    }

    const loadList = () => {
        if (!companyIdx) {
            return;
        }
        const data = {
            companyIdx
        };
        const req = { path: "/v1/a/event/company/satellite/list", data };
        _API.get(req).then((res) => {
            const { list, companyInfo } = res.data;
            setList(list);
            setCompanyInfo(companyInfo);
            setAlreadySelectedDate(list.map(l => moment(l.progressDate).format("YYYY-MM-DD")));
        });
    }

    const loadEventList = (siteIdx) => {
        const data = {
            companyIdx
        };
        const req = { path: "/v1/a/event/company/satellite/date", data };
        _API.get(req).then((res) => {
            const { date } = res.data;
            date?.startDate && setStartDate(date?.startDate);
            date?.endDate && setEndDate(date?.endDate);
        });
    };

    const onRefresh = () => {
        setMode("");
    }

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-8">
                    <h2>SATELLITE 강좌</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/admin">HOME</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to={`${location.pathname}`}>이벤트</Link>
                        </li>
                        <li className="breadcrumb-item ">
                            <Link to={`/admin/event/ebooth/company`}>입점업체</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <strong>SATELLITE 강좌</strong>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>{companyInfo?.companyName}</strong>
                        </li>
                    </ol>
                </div>
                <div className="col-sm-4">
                    <div className="title-action">
                        <button
                            type="button"
                            className="btn btn-primary ml-2"
                            onClick={(e) => {
                                setMode("ADD");
                                setSatelliteData({ companyIdx });
                            }}
                        >
                            <i className="fa fa-plus"></i> 추가

                        </button>
                        <button className="btn btn-default" onClick={() => window.location.href = "#/admin/event/ebooth/company"}>
                            <i className="fa fa-remove"></i> 취소
                        </button>
                    </div>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className={cx({ "col-md-12": !mode, "col-md-6": (mode === "ADD" || mode === "LECTURE") })}>
                        <div className="ibox">
                            <div className="ibox-title">입점 업체 SATELLITE</div>
                            <div className="ibox-content">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th className="text-center">날짜</th>
                                                <th className="text-center">상태</th>
                                                <th className="text-center">강의</th>
                                                <th className="text-center">관리</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list?.map((item, i) => (
                                                <ListItem
                                                    {...item}
                                                    key={i}
                                                    onModify={(satelliteData) => {
                                                        setSatelliteData(satelliteData);
                                                        setMode("ADD");
                                                    }}
                                                    refresh={() => {
                                                        loadList();
                                                    }}
                                                    openSatelliteLectureList={openSatelliteLectureList}
                                                />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-12">
                        <div className="ibox">
                            <div className="ibox-title">
                                SATELLITE
                            </div>
                            <div className="ibox-content">
                                <SegmentedForm title="사용여부" items={_DEF.STATUS} value={companyInfo?.satelliteYn} onClick={(v) => setDataChange("satelliteYn", v)} />
                                <div className="title-action">
                                    <button type="button" className="btn btn-primary" onClick={onSaveCompanyInof}>
                                        <i className="fa fa-save"></i> 저장
                                    </button>
                                    <button className="btn btn-default" onClick={() => window.location.href = "#/event/ebooth/company"}>
                                        <i className="fa fa-remove"></i> 취소
                                    </button>
                                </div>
                            </div>
                        </div> 
                    </div>  */}

                    <div className="col-md-6">
                        {mode === "ADD" ? (
                            <SatelliteForm
                                idx={mode}
                                startDate={startDate}
                                endDate={endDate}
                                data={satelliteData}
                                alreadySelectedDate={alreadySelectedDate}
                                onClose={() => {
                                    setMode("");
                                }}
                                callback={() => {
                                    loadList(1);
                                    setMode("");
                                }}
                            />
                        ) : mode === "LECTURE" && (
                            <SatelliteLecture
                                companyIdx={companyIdx}
                                satelliteIdx={satelliteIdx}
                                onCancel={onRefresh}
                            />
                        )}
                    </div>

                    {/* <div className={cx({ "col-md-12": !mode, "col-md-8": mode })}> */}
                </div>
            </div>
        </Layout>
    );
};

const ListItem = (props) => {

    const onChangeSatelliteStatus = (status) => {
        swal({
            title: `SATELLITE`,
            text: `상태를 변경하시겠습니까?`,
            icon: "info",
            buttons: ["취소", "예"],
        }).then((ok) => {
            if (ok) {
                const req = { path: "/v1/a/event/company/satellite/status", data: { idx: props.idx, status } };
                _API.post(req).then((res) => {
                    props.refresh && props.refresh();
                });
            }
        });
    };

    const onRemove = () => {
        swal({
            title: "정말 삭제 하시겠습니까?",
            text: "삭제된 데이터는 복구가 불가합니다 .",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                _API.post({ path: '/v1/a/event/company/satellite/status', data: { idx: props.idx, status: "D" } }).then((res) => {
                    swal({ title: "알림", text: `SATELLITE가 삭제되었습니다.` }).then(() => {
                        props.refresh && props.refresh();
                    });
                });
            }
        });
    };

    return (
        <tr>
            <td className="text-center">{moment(props.progressDate).format("YYYY-MM-DD")}</td>
            <td className="text-center">
                <div className="btn-group">
                    <button className={cx({ btn: true, "btn-xs": true, "btn-default": props.status !== "Y", "btn-success": props.status === "Y" })} onClick={onChangeSatelliteStatus.bind(this, "Y")}>
                        사용
                    </button>
                    <button className={cx({ btn: true, "btn-xs": true, "btn-default": props.status !== "N", "btn-success": props.status === "N" })} onClick={onChangeSatelliteStatus.bind(this, "N")}>
                        사용안함
                    </button>
                </div>
            </td>
            <td className="text-center">
                <div className="btn-group" style={{ paddingLeft: '10px' }}>
                    {props.lectureCnt > 0 ?
                        (<button type="button" className="btn btn-warning ml-2" onClick={(e) => props.openSatelliteLectureList(props.idx)}>
                            <i className="fa fa-edit"></i> 수정
                        </button>) :
                        (<button type="button" className="btn btn-primary ml-2" onClick={(e) => props.openSatelliteLectureList(props.idx)}>
                            <i className="fa fa-plus"></i> 등록
                        </button>)
                    }
                </div>
            </td>
            <td className="text-center">
                <div className="btn-group">
                    <button className={cx({ btn: true, "btn-xs": true, "btn-warning": true })} onClick={props.onModify.bind(this, props)}>
                        <i className="fa fa-edit"></i>
                    </button>
                    <button className={cx({ btn: true, "btn-xs": true, "btn-danger": true })} onClick={onRemove}>
                        <i className="fa fa-trash"></i>
                    </button>
                </div>
            </td>
        </tr>
    );
};

const SatelliteForm = (props) => {
    const { idx, onClose, data, callback, alreadySelectedDate } = props;

    const [addDate, setAddDate] = useState({});
    const [form, setForm] = useState({ companyIdx: data?.companyIdx, status: "Y" });

    useEffect(() => {
    }, [form])

    useEffect(() => {
        setForm(data);
    }, [data]);

    const fileVodUpload = async (name, item) => {
        const urlName = name.replace("FileIdx", "SignedUrl");
        await _API.post({
            path: "/v1/a/video",
            data: item,
        }).then((res) => res.data)
            .then((data) => {
                swal({ title: "알림", text: "업로드 되었습니다." });
            });
        setForm({ ...form, [name]: item.id, [urlName]: item.signedUrl });
    }

    const onSaveSatellite = () => {
        const filterDate = Object.keys(addDate).map((key) => addDate[key]) || [];
        const progressDates = [form?.progressDate, ...filterDate].filter((i) => i != null && i != "");
        const param = { ...form, progressDates };

        if (!form?.progressDate) {
            swal({ title: "알림", text: "진행날짜를 등록해 주세요" });
            return;
        }
        if (!param?.idx && !form?.satelliteVodFileIdx) {
            swal({ title: "알림", text: "SATELLITE VOD를 등록해 주세요" });
            return;
        }

        _API.post({
            path: "/v1/a/event/company/satellite/save",
            data: param,
        }).then((res) => {
            setForm({
                companyIdx: data?.companyIdx, status: "Y"
            });
            swal({ title: "알림", text: `SATELLITE가 ${param?.idx ? "수정" : "등록"} 되었습니다.` }).then(() => {
                callback && callback();
            });
        });
    }

    const onSetDate = (v, key) => {
        setAddDate({
            ...addDate,
            [key]: v.value,
        });
    };

    const addDayHandler = (type, key) => {

        if (type === "ADD") {
            setAddDate({
                ...addDate,
                [Object.keys(addDate).length + 1]: "",
            });
        } else {
            setAddDate(omit(addDate, key));
        }
    };

    const isSelectedDate = (date) => {
        const filterDate = Object.keys(addDate).map((key) => addDate[key]) || [];
        const progressDates = [form?.progressDate, ...filterDate, ...alreadySelectedDate];
        return progressDates.includes(moment(date).format("YYYY-MM-DD")) ? false : true;
    };

    return (
        <div className="ibox">
            <div className="ibox-title">SATELLITE {form?.idx ? `수정` : `등록`}</div>
            <div className="ibox-content">

                {idx === "ADD" ? (
                    <>
                        <p style={{ marginBottom: "2rem" }}>
                            진행 날짜 추가를 원한다면{" "}
                            <code style={{ cursor: "pointer" }} onClick={(e) => addDayHandler("ADD", e)}>
                                추가
                            </code>
                            버튼을 눌러주세요.
                        </p>
                        <DatePicker title="진행날짜" value={form?.progressDate ? moment(form?.progressDate).format("YYYY-MM-DD") : ""} onChange={(v) => setForm({ ...form, progressDate: v.value })} minDate={moment(props?.startDate, "YYYY-MM-DD").toDate()} maxDate={moment(props?.endDate, "YYYY-MM-DD").toDate()} filterDate={isSelectedDate} />

                        {Object.entries(addDate).map(([dayId, dayData]) => {
                            return (
                                <Fragment key={dayId}>
                                    <DatePicker title=" " value={addDate[dayId] ? moment(addDate[dayId]).format("YYYY-MM-DD") : ""} onChange={(e) => onSetDate(e, dayId)} minDate={moment(props?.startDate, "YYYY-MM-DD").toDate()} maxDate={moment(props?.endDate, "YYYY-MM-DD").toDate()} filterDate={isSelectedDate}>
                                        <button className="btn btn-danger btn-circle btn-outline" onClick={(e) => addDayHandler("DEL", dayId)} style={{ marginRight: "25%", float: "right" }} type="button">
                                            <i className="fa fa-times"></i>
                                        </button>
                                    </DatePicker>
                                </Fragment>
                            );
                        })}
                    </>
                ) : (
                    <DatePicker title="진행날짜" value={form?.progressDate ? moment(form?.progressDate).format("YYYY-MM-DD") : ""} onChange={(v) => setForm({ ...form, progressDate: v.value })} minDate={moment(form?.startDate, "YYYY-MM-DD").toDate()} maxDate={moment(form?.endDate, "YYYY-MM-DD").toDate()} />
                )}

                <FileForm title="SATELLITE VOD" accept="video/*" onChange={(v) => fileVodUpload("satelliteVodFileIdx", v)} path={`EVENT/SATELLITE/VOD/${moment().format("YYYY/MM/DD")}`} url={form?.satelliteVodSignedUrl} fileName={form?.vodFileName} />

                <div className="hr-line-dashed"></div>
                <div className="row">
                    <div className="col-md-12 text-right">
                        <button className="btn btn-primary ml-2" onClick={onSaveSatellite}>
                            <i className="fa fa-save"></i> 저장
                        </button>
                        <button className="btn btn-default" onClick={onClose}>
                            <i className="fa fa-remove"></i> 취소
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const SatelliteLecture = (props) => {
    const { satelliteIdx, companyIdx, onCancel } = props;

    const [mode, setMode] = useState("");

    const [list, setList] = useState([]);
    const [companyInfo, setCompanyInfo] = useState({});
    const [data, setData] = useState({});

    const [desc, setDesc] = useState("");

    const [lectureMgrList, setLectureMgrList] = useState([]);

    const loadList = (page) => {
        const data = {
            satelliteIdx,
            companyIdx
        };
        const req = { path: "/v1/a/event/company/satellite/lecture/list", data };
        _API.get(req).then((res) => {
            const { list } = res.data;
            setList(list);
        });
    };

    const loadCompanyInfo = () => {
        const data = {
            companyIdx,
        };
        const req = { path: "/v1/a/event/company", data };
        _API.get(req).then((res) => {
            const { list } = res.data;
            setCompanyInfo(list[0]);
        });
    };

    const onRefresh = () => {
        loadList(1);
    };

    const onSaveChange = () => {
        setMode("");
        onRefresh();
    };

    useEffect(() => {
        loadCompanyInfo();
        loadList(1);
    }, [])

    useEffect(() => {
        loadCompanyInfo();
        loadList(1);
        setMode(-1);
    }, [satelliteIdx])


    const onChangeState = (item, col, val, e) => {
        if (e) e.preventDefault();

        swal({
            title: `강좌`,
            text: `상태를 변경하시겠습니까?`,
            icon: "info",
            buttons: ["취소", "예"],
            dangerMode: val === "D" ? true : false,
        }).then((ok) => {
            if (ok) {
                _API.post({
                    path: "/v1/a/event/company/satellite/lecture/status",
                    data: {
                        idx: item.idx,
                        status: val,
                    },
                }).then((res) => {
                    loadList(1);
                });
            }
        });
    };

    const onRemove = (item) => {
        swal({
            title: "정말 삭제 하시겠습니까?",
            text: "삭제된 데이터는 복구가 불가합니다 .",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                _API.post({ path: `/v1/a/event/company/satellite/lecture/status`, data: { idx: item.idx, status: "D" } }).then((res) => {
                    swal({ title: "알림", text: `SATELLITE 강좌가 삭제되었습니다.` }).then(() => {
                        loadList(1);
                    });
                });
            }
        });
    };

    const handleSave = () => {
        const param = { ...data, satelliteIdx };
        param.startTime = moment(param.startTime).format("HH:mm");
        param.endTime = moment(param.endTime).format("HH:mm");

        //임시등록 (cvIdx 값 "" 으로 초기화)
        param.lectureMgrList = data.lectureMgrList?.map((mgr) => ({ ...mgr, cvIdx: (mgr.isTemp ? null : mgr.cvIdx) })) || [];

        if (!param.title) {
            swal({ title: "알림", text: "강좌명을 입력해 주세요." });
            return;
        }
        if (!param.startTime || param.startTime === "Invalid date") {
            swal({ title: "알림", text: `세션 진행시간을 확인해주세요.\n\t[${param?.sessionStartTime} ~ ${param?.sessionEndTime}]` });
            return;
        }
        if (!param.endTime || param.endTime === "Invalid date") {
            swal({ title: "알림", text: `세션 진행시간을 확인해주세요.\n\t[${param?.sessionStartTime} ~ ${param?.sessionEndTime}]` });
            return;
        }
        if (param.startTime >= data.endTime) {
            swal({ title: "알림", text: "시작시간이 종료시간보다 클 수 없습니다." });
            return;
        }

        //임시등록 (cvIdx 값 "" 으로 초기화)
        param.lectureMgrList = lectureMgrList?.map((mgr) => ({ ...mgr, cvIdx: (mgr.isTemp ? null : mgr.cvIdx) })) || [];

        _API.post({
            path: "/v1/a/event/company/satellite/lecture/save",
            data: param,
        }).then((res) => {
            swal({ title: "알림", text: `강좌가 ${param?.idx ? "수정" : "등록"} 되었습니다.` }).then(() => {
                setMode(-1);
                setLectureMgrList([]);
                loadList(1);
            });
        });
    }

    const setDateTime = (time) => {
        return (moment(`${time}`, "HH:mm").toDate() || "");
    }


    useEffect(() => {
        if (!mode || mode == -1) {
            refSelect?.current && refSelect?.current.select.props.onChange([], { action: "select-option", option: [], name: undefined });
        }

        return (
            mode === -1 && setData({})
        )
    }, [mode])

    const onAddNewLecture = () => {
        setMode("ADD");
        setData({});
    }

    return (
        <div className="col-md-12">
            <div className="ibox">
                <div className="ibox-title" style={{ paddingRight: '15px' }}>
                    <div className="row">
                        <div className="col-md-6">
                            SATELLITE 강좌목록
                        </div>
                        <div className="col-md-6">
                            <div className="float-right">
                                <button onClick={onAddNewLecture} className="btn btn-primary mr-1">
                                    <i className="fa fa-save"></i> 강좌추가
                                </button>
                                <button onClick={onCancel} className="btn btn-default mr-1">
                                    <i className="fa fa-remove"></i> 취소
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ibox-content">
                    <div className="table-responsive" style={{ minHeight: '300px' }}>
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th className="text-center">강좌명</th>
                                    <th className="text-center">시작시간</th>
                                    <th className="text-center">종료시간</th>
                                    <th className="text-center">상태</th>
                                    <th className="text-center">편집</th>
                                </tr>
                                {mode === "ADD" && (
                                    <SatelliteLectureForm data={data} setData={setData} handleSave={handleSave} mode={mode} setMode={setMode} onSaveChange={onSaveChange} setLectureMgrList={setLectureMgrList} companyInfo={companyInfo} />
                                )}
                            </thead>
                            <tbody>
                                {list.map((item, i) => (
                                    <>
                                        {item.idx === data?.idx ? (
                                            <Fragment key={i}>
                                                <tr>
                                                    <th>
                                                        <input type="text" className="form-control form-control-sm" value={data?.title || ""} onChange={(e) => setData({ ...data, title: e.target.value })} />
                                                    </th>
                                                    <th>
                                                        <TimePicker value={data?.startTime} onChange={(v) => setData({ ...data, startTime: v.value })} />
                                                    </th>
                                                    <th>
                                                        <TimePicker value={data?.endTime} onChange={(v) => setData({ ...data, endTime: v.value })} />
                                                    </th>
                                                    <th>
                                                    </th>
                                                    <td className="text-center">
                                                        <div className="btn-group">
                                                            <button type="button" className="btn btn-sm btn-primary" onClick={handleSave}>
                                                                <i className="fa fa-save"></i>
                                                            </button>
                                                            <button type="button" className="btn btn-xs btn-default" onClick={(e) => setData({})}>
                                                                <i className="fa fa-times"></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr key={`${i}_infoForm`}>
                                                    <td colSpan="4">
                                                        <div className={cx({ "col-md-4": true })}>
                                                            <InfoForm idx={mode} onChange={onSaveChange} setLectureMgrList={setLectureMgrList} lecture={data} companyInfo={companyInfo} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </Fragment>
                                        ) : (
                                            <Fragment key={`${i}_form`}>
                                                <tr>
                                                    <td className="text-center">{item.title}</td>
                                                    <td className="text-center">{item.startTime}</td>
                                                    <td className="text-center">{item.endTime}</td>
                                                    <td className="text-center">
                                                        <div className="btn-group">
                                                            <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "Y", "btn-success": item.status === "Y" })} onClick={onChangeState.bind(this, item, "status", "Y")}>
                                                                사용
                                                            </button>
                                                            <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "N", "btn-success": item.status === "N" })} onClick={onChangeState.bind(this, item, "status", "N")}>
                                                                미사용
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="btn-group">
                                                            <button type="button" className="btn btn-xs btn-warning" onClick={(e) => { setMode(item.idx); setData({ ...item, startTime: setDateTime(item?.startTime), endTime: setDateTime(item?.endTime) }) }}>
                                                                <i className="fa fa-edit"></i>
                                                            </button>
                                                            <button className={cx({ btn: true, "btn-xs": true, "btn-danger": true })} onClick={onRemove.bind(this, item)}>
                                                                <i className="fa fa-trash"></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </Fragment>
                                        )}
                                    </>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

const SatelliteLectureForm = (props) => {
    const { data, setData, handleSave, mode, onSaveChange, setLectureMgrList, companyInfo, setMode } = props;
    return (
        <>
            <tr>
                <th>
                    <InputForm name="title" value={data?.title || ""} onChange={(e) => setData({ ...data, title: e.target.value })} placeholder="강좌명 입력해 주세요" />
                </th>
                <th>
                    <TimePicker value={data?.startTime} onChange={(v) => setData({ ...data, startTime: v.value })} />
                </th>
                <th>
                    <TimePicker value={data?.endTime} onChange={(v) => setData({ ...data, endTime: v.value })} />
                </th>
                <th>
                </th>
                <th className="text-center">
                    <div className="btn-group">
                        <button type="button" className="btn btn-sm btn-primary" onClick={handleSave}>
                            <i className="fa fa-save"></i>
                        </button>
                        <button type="button" className="btn btn-xs btn-default" onClick={(e) => setMode("")}>
                            <i className="fa fa-times"></i>
                        </button>
                    </div>
                </th>
            </tr>
            <tr>
                <td colSpan="4">
                    <div className={cx({ "col-md-4": true })}>
                        <InfoForm idx={mode} onChange={onSaveChange} setLectureMgrList={setLectureMgrList} siteIdx={companyInfo?.siteIdx} eventIdx={companyInfo?.eventIdx} />
                    </div>
                </td>
            </tr>
        </>
    )
}

const SessionTypeRadio = [
    { label: "LECTURE", value: "LECTURE" },
    { label: "REST", value: "REST" },
];
const STATUS_RADIO = [
    // { label: "대기", value: "W" },
    { label: "사용", value: "Y" },
    { label: "미사용", value: "N" },
];

const InfoForm = (props) => {
    const { idx, onChange, setLectureMgrList, lecture, siteIdx, eventIdx } = props;
    // const { siteIdx, iam } = useContext(AppContext);

    const [awsKey, setAwsKey] = useState(null);

    const [form, setForm] = useState({});

    const [desc, setDesc] = useState("");
    const [timeValid, setTimeValid] = useState(true);

    const initForm = {
        siteIdx: siteIdx || lecture?.siteIdx
    }

    useEffect(() => {
        setLectureMgrList(form.lectureMgrList)
    }, [form?.lectureMgrList])

    useEffect(() => {
        loadList();
    }, [lecture])

    useEffect(() => {
        if (siteIdx) {
            setForm(initForm)
        }
    }, [siteIdx])

    // 연자 
    const loadList = () => {
        if (!lecture?.idx) {
            return;
        }
        const data = {
            idx: lecture?.idx || -1
        };
        const req = { path: "/v1/a/event/company/satellite/lecture/list", data };
        _API.get(req).then((res) => {
            setForm({ ...res.data, ...initForm });
        });
    };

    const loadPrepare = () => {
        _API.get({ path: "/v1/upload" }).then((res) => {
            setAwsKey(res.data);
        });
    };

    useEffect(() => {
        setDesc("");
    }, [idx]);

    return (
        <div className="ibox" style={{ minWidth: '250px' }}>
            <CvList form={form} setForm={setForm} idx={idx} />
        </div>
    );
};

const LectureImageWrapper = styled.div.attrs({
    className: "col-md-10",
})`
    box-shadow: 0 18px 53px 0 rgb(0 0 0 / 30%);
    position: relative;
    border-radius: 20px;
    max-height: 15vh;
    padding: 10px;

    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
`;

let refSelect = "";

const CvList = (props) => {
    const { form, setForm, idx } = props;
    //test data

    const [list, setList] = useState([]);
    const [options, setOptions] = useState([]);

    const [mode, setMode] = useState("");

    const [toggle, setToggle] = useState(false);
    const [isInputMgrView, setIsInputMgrView] = useState(false);

    const animatedComponents = makeAnimated();

    const reactSelectRef = useRef(null);

    // CV 목록
    const loadCvList = (data) => {
        if (!data.siteIdx) {
            return;
        }
        const req = { path: "/v1/a/lecturer/list", data: { siteIdx: (data?.siteIdx || -1) } };
        _API.get(req).then((res) => {
            const { list, siteList, paging } = res.data;
            setOptions(labelData(list));
            setList(idx === -1 ? [] : labelData(form.lectureMgrList));
        });
    };

    const labelData = (list) => {
        return (
            list?.map((cv) => {
                return { ...cv, label: `${cv.lastName + " " + cv.firstName} `, value: cv.cvIdx ? cv.cvIdx : cv.idx, photoUrl: cv.photoUrl };
            }) || []
        );
    };

    const handleChange = (sel, e) => {
        setList(sel);
        setForm((obj) => ({ ...obj, lectureMgrList: sel }));
    };

    // UI 처리
    const setInputMgrView = () => {
        setIsInputMgrView(true);
    }

    // 숨기기 처리
    const toggleCollapse = (e) => {
        e.preventDefault();

        const $target = e.currentTarget;

        const ibox = $($target).closest("div.ibox");
        const button = $($target).find("i");
        const content = ibox.children(".ibox-content");

        content.slideToggle(200);
        button.toggleClass("fa-chevron-up").toggleClass("fa-chevron-down");
        ibox.toggleClass("").toggleClass("border-bottom");

        setTimeout(function () {
            ibox.resize();
            ibox.find("[id^=map-]").resize();
        }, 50);
    };

    const dot = (item = { photoUrl: EMPTY_PROFILE }) => ({
        alignItems: "center",
        display: "flex",
        ":before": {
            backgroundImage: `url(${item.photoUrl})`,
            backgroundSize: "cover",
            borderRadius: 8,
            content: '" "',
            display: "block",
            marginLeft: 8,
            height: 25,
            width: 25,
            marginRight: "8px",
        },
    });

    const colourStyles = {
        control: (styles) => ({ ...styles, backgroundColor: "white" }),
        input: (styles, { data }) => ({ ...styles /* , ...dot(data) */ }),
        placeholder: (styles) => ({ ...styles, ...dot() }),
        // singleValue: (styles, { data }) => ({ ...styles, ...dot(data) }),
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                ...dot(data),
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            margin: "5px",
            // ...dot(data),
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            // color: data.color,
            ":hover": {
                backgroundColor: data.color,
                color: "white",
            },
        }),
    };

    useEffect(() => {
        if (reactSelectRef) refSelect = reactSelectRef;

        return (
            loadCvList(form)
        )

    }, [form?.idx, reactSelectRef]);

    useEffect(() => {
        loadCvList(form);
    }, [form?.siteIdx]);

    return (
        <div className="ibox ">
            <div className="ibox-title">
                <h5>연자</h5>
                <span className="label label-info">{list?.length || 0}</span>
                <div className="ibox-tools">
                    <span className="label label-primary" onClick={(e) => setToggle(!toggle)} style={{ marginRight: "3px", cursor: "pointer" }}>
                        편집
                    </span>
                    <a className="collapse-link" href="" onClick={toggleCollapse}>
                        <i className="fa fa-chevron-up"></i>
                    </a>
                </div>
            </div>

            <div className="ibox-content">
                {toggle && (
                    <Fragment>
                        <p>
                            목록에 없다면 <code style={{ cursor: "pointer" }} onClick={setInputMgrView}>추가</code>버튼을 눌러주세요.
                        </p>
                        <CreatableSelect
                            closeMenuOnSelect={false}
                            placeholder={"사이트에 등록된 좌장을 선택해주세요."}
                            noOptionsMessage={() => "사이트에 할당된 좌장이 없습니다."}
                            components={animatedComponents}
                            isMulti
                            options={options}
                            defaultValue={list}
                            styles={colourStyles}
                            onChange={handleChange}
                            ref={reactSelectRef}
                        />
                        <Separator />
                        {isInputMgrView &&
                            <InputMgrForm handleChange={handleChange} setOptions={setOptions} form={form} setForm={setForm} setList={setList} list={list} reactSelectRef={reactSelectRef} />
                        }
                    </Fragment>
                )}
                <div>
                    <div className="feed-activity-list">
                        {list.map((item, i) => (
                            <div className="feed-element" key={i}>
                                <a className="float-left" href="" onClick={(e) => e.preventDefault()}>
                                    <img alt="image" className="rounded-circle" src={item.photoUrl ? item.photoUrl : EMPTY_PROFILE} />
                                </a>
                                <div className="media-body ">
                                    <small className="float-right">{item.title}</small>
                                    <strong>{item.lastName + " " + item.firstName}</strong> {item.companyName} <br />
                                    <small className="text-muted">{item.description}</small>
                                </div>
                            </div>
                        ))}
                    </div>

                    <button className="btn btn-primary btn-block m-t" onClick={toggleCollapse}>
                        <i className="fa fa-arrow-up"></i> 숨기기
                    </button>
                </div>
            </div>
        </div>
    );
};

const InputMgrForm = (props) => {
    const { handleChange, setOptions, list, setList } = props;
    const { form, setForm, reactSelectRef } = props;

    const [mgrForm, setMgrForm] = useState({});

    const addOptions = () => {
        const data = { ...mgrForm };

        if (!data.lastName) {
            swal({ title: "알림", text: "성을 입력해 주세요." });
            return;
        }
        if (!data.firstName) {
            swal({ title: "알림", text: "이름을 입력해 주세요." });
            return;
        }
        if (!data.companyName) {
            swal({ title: "알림", text: "소속을 입력해 주세요." });
            return;
        }

        const mgrFormData = {
            isTemp: true
            , label: `${mgrForm.lastName + " " + mgrForm.firstName} `
            , value: `TEMP_${mgrForm.lastName}${mgrForm.firstName}`
            , photoUrl: mgrForm.photoUrl || ""
            , title: "임시등록"
            , ...mgrForm
            // , description ,

        };

        // setOptions((cv) => [...cv, mgrFormData ]);
        // setForm((obj) => ({...obj , sessionMgrList : [...list, mgrForm]}));
        reactSelectRef?.current.select.props.onChange([...list, mgrFormData], { action: "select-option", option: mgrFormData, name: undefined });
        setMgrForm({});
    }

    useEffect(() => {
        setMgrForm({});
    }, [form]);

    return (
        <Fragment>
            <div className="row" style={{ marginBottom: "1rem" }}>
                <div className="col-md-12"><input type="text" name="companyName" value={mgrForm?.companyName || ""} placeholder="소속" className="form-control" onChange={(e) => setMgrForm({ ...mgrForm, [e.target.name]: e.target.value })} /></div>
            </div>
            <div className="row" style={{ marginBottom: "1rem" }}>
                <div className="col-md-5"><input type="text" name="lastName" value={mgrForm?.lastName || ""} placeholder="성(Last Name)" className="form-control" onChange={(e) => setMgrForm({ ...mgrForm, [e.target.name]: e.target.value })} /></div>
                <div className="col-md-7"><input type="text" name="firstName" value={mgrForm?.firstName || ""} placeholder="이름(First Name)" className="form-control" onChange={(e) => setMgrForm({ ...mgrForm, [e.target.name]: e.target.value })} /></div>
            </div>
            <div className="row">
                <div className="col-md-12 text-right">
                    <div className="btn-group">
                        <button type="button" className="btn btn-success" onClick={addOptions}>
                            <i className="fa fa-plus"></i> 추가
                        </button>
                    </div>
                </div>
            </div>
            <Separator />
        </Fragment>
    )
}