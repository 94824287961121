import React, { useContext, useEffect, useState } from "react";
import swal from "sweetalert";

import Wrapper, { Row, FormGroup, Separator } from "../../components/UI/Wrapper";
import Input from "../../components/UI/Form";
import { BlockButton } from "../../components/UI/Button";

import { AppContext } from "../../components/AppProvider";
import { _API, _CK } from "../../modules/utils";

export default (props) => {
    const { saveAuth, saveSiteIdx, saveIam } = useContext(AppContext);
    const [email, setEmail] = useState("");
    const [passwd, setPasswd] = useState("");
    // console.log("ADMIN LOGIN")
    useEffect(() => {
        $("body").addClass("gray-bg");
        return () => {
            $("body").removeClass("gray-bg");
        };
    }, []);

    const onLoginClicked = () => {
        if (!email) {
            swal("이메일을 입력해 주세요.");
            return;
        }
        if (!passwd) {
            swal("비밀번호를 입력해 주세요.");
            return;
        }
        const req = {
            path: "/v1/login",
            data: {
                email,
                passwd,
            },
        };
        _API.post(req)
            .then((res) => res.data)
            .then((data) => {
                if (data.data.status !== "Y") {
                    swal("접근 권한이 없습니다.");
                    return;
                }

                _CK.setAuth(data.accessKey);
                saveIam(data.data);
                saveAuth(data.accessKey);
                document.location.href = "/#/admin/dashboard";
            });
    };
    const onKeyPress = (e) => {
        if (e.key === "Enter") {
            onLoginClicked();
        }
    }

    return (
        <Wrapper>
            <div className="middle-box text-center loginscreen">
                <div>
                    <div>
                        <h1
                            className="logo-name"
                            style={{
                                fontSize: "100px",
                                letterSpacing: "0px",
                                textAlign: "center",
                            }}
                        >
                            <i className="fa fa-user"></i>
                        </h1>
                    </div>
                </div>
                <h3>웨비나</h3>
                <Separator />
                <FormGroup row>
                    <label className="col-md-4 col-form-label">이메일</label>
                    <div className="col-md-8">
                        <Input type="email" onChange={(e) => setEmail(e.target.value)} />
                    </div>
                </FormGroup>
                <FormGroup row>
                    <label className="col-md-4 col-form-label">비밀번호</label>
                    <div className="col-md-8">
                        <Input type="password" onChange={(e) => setPasswd(e.target.value)} onKeyPress={onKeyPress} />
                    </div>
                </FormGroup>
                <Row>
                    <div className="col-md-12">
                        <BlockButton className="btn-success" onClick={onLoginClicked}>
                            <i className="fa fa-sign-in"></i> 로그인
                        </BlockButton>
                    </div>
                </Row>
                <Separator />

                <p className="m-t">
                    {" "}
                    <small>Webinar provided by 디몬엠디 주식회사 &copy; 2021</small>{" "}
                </p>
            </div>
        </Wrapper>
    );
};
