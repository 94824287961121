import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";

import Modal from "react-modal";
import styled from "styled-components";
import swal from "sweetalert";
import moment from 'moment';
import { isMobile } from "react-device-detect";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U, _CK } from "../../../modules/utils";

import { H2, ModalVideoTitle } from "./UI/Components";

import { Theme } from "../../../components/GlobalStyles";

import Video from "../../../components/UI/Video";

import { RecommendOnImg, RecommendOnImgSet, RecommendOffImg, RecommendOffImgSet, getBgSet } from "./asset";

export default (props) => {
    const { code, contentType, videoUrl, title, description, vodEncodedUrl, videoData } = props;
    const { cfg, auth, iam } = useContext(AppContext);
    const event = cfg.event || null;

    const { siteIdx, eventIdx } = event;

    const [vodLogIdx, setVodLogIdx] = useState(null);
    const videoNode = useRef(null);


    if (!event) {
        return null;
    }

    useEffect(() => {
        saveVodLog();
        loadRecommendedVod();
    }, []);

    const isAutoPlay = () => {
        switch (contentType) {
            case "SATELLITE":
                return false;
            default:
                return true;
        }
    }

    const saveVodLog = () => {
        _API.post({
            path: "/v1/site/event/vod/log",
            data: {
                siteIdx,
                eventIdx,
                lectureIdx: videoData.idx,
                logType: "VIEW"
            },
        }).then((res) => {
        });
    };

    const loadRecommendedVod = () => {
        _API.get({
            path: "/v1/site/event/vod/recommend",
            data: {
                siteIdx,
                eventIdx,
                lectureIdx: videoData.idx,
            },
        }).then((res) => {
            const { vodLogIdx } = res.data;
            setVodLogIdx(vodLogIdx);
        });
    };

    const saveRecommendVod = () => {
        _API.post({
            path: "/v1/site/event/vod/log",
            data: {
                siteIdx,
                eventIdx,
                lectureIdx: videoData.idx,
                logType: "RECOMMEND"
            },
        }).then((res) => {
            const { result } = res.data;
            setVodLogIdx(result.insertId);
        });
    };

    const removeRecommendedVod = () => {
        _API.delete({
            path: "/v1/site/event/vod/recommend",
            data: {
                vodLogIdx
            },
        }).then((res) => {
            setVodLogIdx(null);
        });
    };

    const onClickRecommend = () => {
        if (vodLogIdx) {
            removeRecommendedVod();
        } else {
            saveRecommendVod();
        }
    }

    // const checkVideoTime = () => {
    //     console.log("aaa", videoNode.current.currentTime, videoNode.current.duration)
    // }

    // let sa = null;
    // useEffect(() => {
    //     if (videoNode) {
    //         // sa = setInterval(checkVideoTime, 1000);
    //         videoNode.current.addEventListener("ended", () => {
    //             clearInterval(sa);
    //         });
    //         videoNode.current.addEventListener("pause", () => {
    //             clearInterval(sa);
    //         });
    //         videoNode.current.addEventListener("play", () => {
    //             sa = setInterval(checkVideoTime, 1000);
    //         });

    //         return () => clearInterval(sa);
    //     }
    // }, [videoNode]);
    const vodHedight = window.innerHeight < 900 ? "561px" : "653px";


    return (
        <>
            {((isMobile && title) || (!videoData && title)) && (<ViewTitle>{title}</ViewTitle>)}
            {videoData && !isMobile && (
                <ViewVideoTitleWrap>
                    <ViewVideoSession>
                        <ViewVideoSessionItem>{videoData?.sessionTitle}</ViewVideoSessionItem>
                    </ViewVideoSession>
                    <ViewVideoLecture>
                        <ViewVideoLectureMgr>{videoData?.mgrName}</ViewVideoLectureMgr>
                        <ViewVideoLectureTitle>{videoData?.title}</ViewVideoLectureTitle>
                    </ViewVideoLecture>
                    {event.replayRecommendYn === "Y" &&
                        <RecommendWrapper onClick={onClickRecommend}>
                            <RecommendButton isRecommended={vodLogIdx} />
                        </RecommendWrapper>
                    }
                </ViewVideoTitleWrap>
            )}
            {/* {description && (<ViewDescription>{description}</ViewDescription>)} */}
            {/* <p>vodEncodedUrl:{vodEncodedUrl}</p>
            <p>videoUrl:{videoUrl}</p> */}
            <ContentVideo hasTitle={!!title} vodHedight={vodHedight}>
                {/* <video src={videoUrl} playsInline autoPlay={isAutoPlay()} height="100%" controls /> */}
                {vodEncodedUrl ? (
                    <Video url={vodEncodedUrl} autoPlay={true} removeCustomSize={true} width="100%" height="100%" fluid={false} paddingTop="56.25%" videoRefProp={videoNode} />
                ) : (
                    <video src={videoUrl} playsInline autoPlay={true} width="100%" height="100%" controls controlsList="nodownload" style={{ maxHeight: "100%" }} ref={videoNode} />
                )}
            </ContentVideo>
        </>
    );
};

const ContentVideo = styled.div`
    flex: 1;
    width: 100%;
    height: 100%;
    max-width:${props => props.vodHedight == "561px" ? "997px" : "100%"};
    margin:${props => props.vodHedight == "561px" ? `0px calc(100%/2 - 498px)` : "unset"};
    /* min-height: 40vh;
    max-height: ${props => props.hasTitle ? "57vh" : "90vh"}; */
    min-height: ${props => props.vodHedight ? props.vodHedight : "653px"};
    max-height: ${props => props.vodHedight ? props.vodHedight : "653px"};
    overflow-x: hidden;
    overflow-y: hidden;

    .image img {
        max-width: 100%;
    }
`;
const ViewTitle = styled.div`
    padding-left: 9px;
    font-size:16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x:hidden;
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        font-size:13px;
        padding-left: 5px;
        padding-bottom: 8px;
    }
`;
const ViewDescription = styled.div`
    padding-left: 9px;
    font-size:15px;
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        font-size:13px;
        padding-left: 5px;
        padding-bottom: 8px;
    }
`;

const ViewVideoTitleWrap = styled.div`
    font-size:16px;
    display: grid;
    grid-template-columns: auto 1fr 100px;
    padding-bottom: 10px;
    margin-bottom: 0px;
    border-bottom:1px solid #cbcbcb;
    
    
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        font-size:13px;
        padding-left: 5px;
        padding-bottom: 8px;
    }
`;

const ViewVideoSession = styled.div`
    max-width:200px;
    min-width: 150px;
    
    border-right:1px solid #e7e7e7;
    padding-right:10px;
    color:#393c6f;
    font-family:NotoSansCJKkr-M;
    display: flex;
    align-items: center;
    overflow: hidden;
    
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        
    }
`;

const ViewVideoSessionItem = styled.div`
    display: inline-block;
    display: -webkit-inline-box;
    vertical-align: middle;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        
    }
`;

const ViewVideoLecture = styled.div`
    padding-left:10px;
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        
    }
`;

const ViewVideoLectureMgr = styled.div`
    font-size: 14px;
    color:#777777;
    max-width: 900px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        
    }
`;

const ViewVideoLectureTitle = styled.div`
    font-size: 16px;
    color:#454545;
    max-width: 900px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        
    }
`;

const RecommendWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 10px;

    button{
        background-color: ${(props) => props.isRecommended ? props.theme.basic.bg.buttonSubmit2 : props.theme.basic.bg.buttonDefault};
        color: ${(props) => props.isRecommended ? props.theme.basic.color.buttonSubmit : props.theme.basic.color.buttonDefault};
        font-size: 16px;
        font-family: ${(props) => props.theme.fontFamily.medium};
        border: ${(props) => props.isRecommended ? 'none' : 'solid'};
        border-radius: 5px;
        padding: 8px;
    }
`

const RecommendButton = styled.label`
    width: 66px;
    height: 22px;
    margin-bottom: 0;
    background-image: 
        ${props => (props.isRecommended ? `url(${RecommendOnImg})` : `url(${RecommendOffImg})`)};
    background-image:
        ${props => (props.isRecommended ? `-webkit-image-set(${getBgSet(RecommendOnImgSet)})` : `-webkit-image-set(${getBgSet(RecommendOffImgSet)})`)};
    cursor: pointer;
`;
