import React, { useContext, useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import swal from "sweetalert";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import Layout from "../Layout";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";
import { _DEF } from "../../../modules/config";

import Wrapper, { Row, FormGroup, Separator } from "../../../components/UI/Wrapper";
import { SearchInput, SelectForm, InputForm, SegmentedForm, FileForm, DatePicker, TextForm, SearchCondition } from "../../../components/UI/Form";
import Button from "../../../components/UI/Button";
import SNEditor from "../../../components/UI/SNEditor";
import Paging, { ToPage, GetParams } from "../../../components/UI/Paging";

const CHANNEL_STATUS = [
    { label: "대기", value: "W" },
    { label: "정상", value: "Y" },
    { label: "차단", value: "N" },
];

const STREAM_STATUS = [
    { label: "LIVE", value: "LIVE" },
    { label: "ONDEMAND", value: "ONDEMAND" },
];

export default (props) => {
    const { history, location, match } = props;

    const [mode, setMode] = useState("");

    const { cfg } = useContext(AppContext);

    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [eventIdx, setEventIdx] = useState("");
    const [eventList, setEventList] = useState([]);

    const [keyword, setKeyword] = useState("");
    const [category, setCategory] = useState("");
    const [contentType, setContentType] = useState("");
    const [list, setList] = useState([]);
    const [paging, setPaging] = useState(null);

    const loadList = (page) => {
        // if(!eventIdx){
        //     swal({ title: "알림", text: "이벤트를 선택해 주세요." });
        //     return;
        // }
        const data = {
            siteIdx,
            eventIdx,
            keyword,
            category,
            contentType,
            page,
        };
        const req = { path: "/v1/a/event/channel/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setList(list);
            setPaging(paging);
        });
    };

    const movePage = (page) => {
        loadList(page);
    };

    const onModify = (idx) => {
        setMode(idx);
    };

    const onRefresh = () => {
        loadList(paging?.pageNo || 1);
    };

    const onSaveChange = () => {
        setMode("");
        onRefresh();
    };

    const onSearch = (e) => {
        loadList(1);
    };

    useEffect(() => {
        loadList(paging?.pageNo || 1);
    }, []);

    useEffect(() => {
        loadList(1);
    }, [eventIdx]);

    const onChangeState = (item, col, val, e) => {
        if (e) e.preventDefault();

        swal({
            title: `채널`,
            text: `상태를 변경하시겠습니까?`,
            icon: "info",
            buttons: ["취소", "예"],
            dangerMode: val === "D" ? true : false,
        }).then((ok) => {
            if (ok) {
                _API.post({
                    path: "/v1/a/event/category/status",
                    data: {
                        idx: item.idx,
                        category: "channel",
                        status: val,
                    },
                }).then((res) => {
                    loadList(paging?.pageNo || 1);
                });
            }
        });
    };

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-8">
                    <h2>채널</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/admin">HOME</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to={`${location.pathname}`}>이벤트</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>채널</strong>
                        </li>
                    </ol>
                </div>
                <div className="col-sm-4">
                    <div className="title-action">
                        <button type="button" className="btn btn-primary" onClick={(e) => setMode("ADD")}>
                            <i className="fa fa-plus"></i> 추가
                        </button>
                    </div>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className={cx({ "col-md-12": !mode, "col-md-8": mode })}>
                        <div className="ibox">
                            <div className="ibox-title">채널목록</div>
                            <div className="ibox-content ibox-heading">
                                <SearchCondition
                                    sync={true}
                                    eventYn={"Y"}
                                    sessionYn={"N"}
                                    channelYn={"N"}
                                    onChange={(e) => {
                                        setEventIdx(e.eventIdx);
                                        setKeyword(e.keyword);
                                    }}
                                    onSearch={onSearch}
                                    value={{ siteIdx, eventIdx, keyword }}
                                    setEventList={setEventList}
                                />
                            </div>
                            <div className="ibox-content">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th className="text-center">이벤트명</th>
                                                <th className="text-center">채널번호</th>
                                                <th className="text-center">채널명</th>
                                                {/* <th className="text-center">실시간구분</th> */}
                                                <th className="text-center">장소명</th>
                                                <th className="text-center">IVS/VOD명</th>
                                                <th className="text-center">기간</th>
                                                <th className="text-center">모니터</th>
                                                <th className="text-center">상태</th>
                                                <th className="text-center">등록일</th>
                                                <th className="text-center">편집</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.map((item, i) => (
                                                <tr key={i}>
                                                    <td className="text-center">{item.eventTitle}</td>
                                                    <td className="text-center">{item.idx}</td>
                                                    <td className="text-center">{item.title}</td>
                                                    {/* <td className="text-center">{item.liveType}</td> */}
                                                    <td className="text-center">{item.locationTitle}</td>
                                                    <td className="text-center">{item.ivsName}</td>
                                                    {/* <td className="text-center">{moment(item.START_DATE).format("YYYY-MM-DD") ~ moment(item.START_DATE).format("YYYY-MM-DD") }</td> */}
                                                    <td className="text-center">{moment(item.progressDate).format("YYYY-MM-DD")}</td>
                                                    <td className="text-center">
                                                        <div className="btn-group">
                                                            <button
                                                                className="btn btn-xs btn-primary"
                                                                onClick={() => {
                                                                    window.open(`/#/admin/event/console/monitor/${item.idx}`);
                                                                }}
                                                            >
                                                                운영
                                                            </button>
                                                            {item.qnaYn === "Y" && (
                                                                <button
                                                                    className="btn btn-xs btn-default"
                                                                    onClick={() => {
                                                                        window.open(`/#/admin/event/console/principal/${item.idx}`);
                                                                    }}
                                                                >
                                                                    좌장
                                                                </button>
                                                            )}
                                                            {item.quizYn === "Y" && (
                                                                <button
                                                                    className="btn btn-xs btn-default"
                                                                    onClick={() => {
                                                                        window.open(`/#/admin/event/console/speaker/${item.idx}`);
                                                                    }}
                                                                >
                                                                    연자
                                                                </button>
                                                            )}
                                                            {item.quizYn === "Y" && (
                                                                <button
                                                                    className="btn btn-xs btn-default"
                                                                    onClick={() => {
                                                                        window.open(`/#/admin/event/console/quiz/${item.idx}`);
                                                                    }}
                                                                >
                                                                    퀴즈
                                                                </button>
                                                            )}
                                                            {item.qnaYn === "Y" && (
                                                                <button
                                                                    className="btn btn-xs btn-default"
                                                                    onClick={() => {
                                                                        window.open(`/#/admin/event/console/question/${item.idx}`);
                                                                    }}
                                                                >
                                                                    질문
                                                                </button>
                                                            )}
                                                            {item.surveyYn === "Y" && (
                                                                <button
                                                                    className="btn btn-xs btn-default"
                                                                    onClick={() => {
                                                                        window.open(`/#/admin/event/console/survey/${item.idx}`);
                                                                    }}
                                                                >
                                                                    설문
                                                                </button>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="btn-group">
                                                            {/* <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "W", "btn-success": item.status === "W" })} onClick={onChangeState.bind(this, item, "status", "W")}>
                                                                대기
                                                            </button> */}
                                                            <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "Y", "btn-success": item.status === "Y" })} onClick={onChangeState.bind(this, item, "status", "Y")}>
                                                                정상
                                                            </button>
                                                            <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "N", "btn-success": item.status === "N" })} onClick={onChangeState.bind(this, item, "status", "N")}>
                                                                미사용
                                                            </button>
                                                            <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "D", "btn-success": item.status === "D" })} onClick={onChangeState.bind(this, item, "status", "D")}>
                                                                삭제
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">{moment(item.regDate).format("YYYY-MM-DD")}</td>
                                                    <td className="text-center">
                                                        <div className="btn-group">
                                                            <button type="button" className="btn btn-xs btn-warning" onClick={(e) => onModify && onModify(item.idx)}>
                                                                <i className="fa fa-edit"></i> 수정
                                                            </button>
                                                            {/* <Link to={`/lecturer/${item.idx}/career/list`} style={{textDecorationLine:"none", color: "inherit"}}>
                                                                <button type="button" className="btn btn-xs btn-info" onClick={(e) => onModify && onModify(item.idx)}>
                                                                    <i className="fa fa-mail-forward"></i> 경력
                                                                </button>
                                                            </Link> */}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {paging && (
                                <div className="ibox-footer">
                                    <Paging paging={paging} pageCallback={movePage} />
                                </div>
                            )}
                        </div>
                    </div>
                    {mode && (
                        <div className={cx({ "col-md-4": true })}>
                            <InfoForm idx={mode} onChange={onSaveChange} eventList={eventList} />
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
};

const InfoForm = (props) => {
    const { idx, onChange, eventList } = props;
    const { iam } = useContext(AppContext);

    const { cfg } = useContext(AppContext);

    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [locDtlList, setLocDtlList] = useState([]);
    const [ivsList, setIvsList] = useState([]);
    const [contentList, setContentList] = useState([]);
    const [form, setForm] = useState({ siteIdx: cfg.idx });
    const [locationIdx, setLocationIdx] = useState("");

    const [channelAlert, setChannelAlert] = useState("");

    const [addDate, setAddDate] = useState({});

    const onSave = () => {
        const filterDate = Object.keys(addDate).map((key) => addDate[key]) || [];

        //공백 제거 및 머지
        const progressDates = [form?.progressDate, ...filterDate].filter((i) => i != null && i != "");

        const data = { ...form, progressDates, channelAlert };
        if (idx !== "ADD") {
            data.idx = idx;
        }
        if (!data.siteIdx) {
            swal({ title: "알림", text: "사이트를 선택해 주세요." });
            return;
        }
        if (!data.eventIdx) {
            swal({ title: "알림", text: "이벤트를 선택해 주세요." });
            return;
        }
        if (!data.title) {
            swal({ title: "알림", text: "채널명을 입력해 주세요." });
            return;
        }
        // if (!data.liveType) {
        //     swal({ title: "알림", text: "실시간 구분을 선택해 주세요." });
        //     return;
        // }
        if (!data.locDtlIdx) {
            swal({ title: "알림", text: "세부장소를 선택해 주세요." });
            return;
        }
        if (!data.siteIdx && siteIdx) {
            data.siteIdx = siteIdx;
        }
        if (!(data.progressDates && data.progressDates.length)) {
            swal({ title: "알림", text: "진행날짜를 선택해 주세요." });
            return;
        }
        _API.post({
            path: "/v1/a/event/channel/save",
            data: data,
        }).then((res) => {
            swal({ title: "알림", text: `채널이 ${idx === "ADD" ? "등록" : "수정"} 되었습니다.` }).then(() => {
                onChange && onChange();
            });
        });
    };

    const loadData = () => {
        if (idx === "ADD") {
            setChannelAlert("");
            return;
        }
        const req = {
            path: "/v1/a/event/channel/list",
            data: { idx },
        };
        _API.get(req).then((res) => {
            setForm({ ...res.data });
            setChannelAlert(res.data.channelAlert);
        });
    };

    const loadLocDtlList = (data) => {
        const param = { ...data };
        const req = { path: "/v1/a/location/detail/list", data: param };
        _API.get(req).then((res) => {
            const { locationDetailList, info } = res.data;
            setLocDtlList(locationDetailList);
        });
    };

    const loadContentList = (data) => {
        const param = { ...data, contentType: "VOD_FILE", category: "ONDEMAND" };
        const req = { path: "/v1/a/event/content/list", data: param };
        _API.get(req).then((res) => {
            const { list } = res.data;
            setContentList(list);
        });
    };

    const loadIvsList = (data) => {
        const param = { ...data };
        const req = { path: "/v1/a/aws/ivs/list", data: param };
        _API.get(req).then((res) => {
            const { list } = res.data;
            setIvsList(list);
        });
    };

    const fileUpload = (name, item) => {
        const urlName = name.replace("FileIdx", "SignedUrl");
        setForm({ ...form, [name]: item.id, [urlName]: item.signedUrl, finalFileName: item.name });
    };

    const addDayHandler = (type, key) => {
        if (!form?.eventIdx) {
            swal({ title: "알림", text: `이벤트를 먼저 선택해주세요.` });
            return;
        }
        // console.log(type,key);
        if (type === "ADD") {
            setAddDate({
                ...addDate,
                [Object.keys(addDate).length + 1]: "",
            });
        } else {
            setAddDate(_.omit(addDate, key));
        }
    };

    const onSetDate = (v, key) => {
        setAddDate({
            ...addDate,
            [key]: v.value,
        });
    };

    const isSelectedDate = (date) => {
        const filterDate = Object.keys(addDate).map((key) => addDate[key]) || [];
        const progressDates = [form?.progressDate, ...filterDate];
        return progressDates.includes(moment(date).format("YYYY-MM-DD")) ? false : true;
    };

    useEffect(() => {
        loadData();
        loadIvsList({ siteIdx: siteIdx });
        idx === "ADD" && setForm({});
    }, [idx]);

    useEffect(() => {
        if (form?.eventIdx) {
            loadContentList({ eventIdx: form?.eventIdx });
            for (let i in eventList) {
                const event = eventList[i];
                if (form?.eventIdx == event.eventIdx) {
                    loadLocDtlList({ locationIdx: event.locIdx });
                    setForm({ ...form, startDate: eventList.filter((event) => form.eventIdx == event.idx)[0]?.startDate || "", endDate: eventList.filter((event) => form.eventIdx == event.idx)[0]?.endDate || "" });
                    break;
                }
            }
        } else {
            setContentList([]);
            setLocDtlList([]);
            setForm({ ...form, progressDate: "" });
        }
    }, [form?.eventIdx, eventList]);

    return (
        <div className="ibox">
            <div className="ibox-title">채널 {idx === "ADD" ? "등록" : "수정"}</div>
            <div className="ibox-content">
                <SelectForm
                    title="이벤트"
                    name="eventIdx"
                    items={eventList.map((event) => {
                        return { label: event.eventName, value: event.idx };
                    })}
                    value={form?.eventIdx}
                    onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                    nullTitle="이벤트 선택"
                />
                <Separator />
                <InputForm title="채널명" name="title" value={form?.title} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="채널명 입력해 주세요" />
                {/* <SegmentedForm title="실시간 구분" items={STREAM_STATUS} value={form?.liveType} onClick={(v) => setForm({ ...form, liveType: v })} /> */}
                <SelectForm
                    title="세부장소"
                    name="locDtlIdx"
                    items={locDtlList.map((locDtl) => {
                        return { label: locDtl.title, value: locDtl.idx };
                    })}
                    value={form?.locDtlIdx}
                    onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                    nullTitle="세부장소 선택"
                />
                <Separator />
                {idx === "ADD" ? (
                    <>
                        <p style={{ marginBottom: "2rem" }}>
                            진행 날짜 추가를 원한다면{" "}
                            <code style={{ cursor: "pointer" }} onClick={(e) => addDayHandler("ADD", e)}>
                                추가
                            </code>
                            버튼을 눌러주세요.
                        </p>
                        <DatePicker title="진행날짜" value={form?.progressDate ? moment(form?.progressDate).format("YYYY-MM-DD") : ""} onChange={(v) => setForm({ ...form, progressDate: v.value })} minDate={moment(form?.startDate, "YYYY-MM-DD").toDate()} maxDate={moment(form?.endDate, "YYYY-MM-DD").toDate()} filterDate={isSelectedDate} />

                        {Object.entries(addDate).map(([dayId, dayData]) => {
                            console.log(dayId, dayData);

                            return (
                                <Fragment key={dayId}>
                                    <DatePicker title=" " value={addDate[dayId] ? moment(addDate[dayId]).format("YYYY-MM-DD") : ""} onChange={(e) => onSetDate(e, dayId)} minDate={moment(form?.startDate, "YYYY-MM-DD").toDate()} maxDate={moment(form?.endDate, "YYYY-MM-DD").toDate()} filterDate={isSelectedDate}>
                                        <button className="btn btn-danger btn-circle btn-outline" onClick={(e) => addDayHandler("DEL", dayId)} style={{ marginRight: "25%", float: "right" }} type="button">
                                            <i className="fa fa-times"></i>
                                        </button>
                                    </DatePicker>
                                </Fragment>
                            );
                        })}
                    </>
                ) : (
                    <DatePicker title="진행날짜" value={form?.progressDate ? moment(form?.progressDate).format("YYYY-MM-DD") : ""} onChange={(v) => setForm({ ...form, progressDate: v.value })} minDate={moment(form?.startDate, "YYYY-MM-DD").toDate()} maxDate={moment(form?.endDate, "YYYY-MM-DD").toDate()} />
                )}
                <Separator />
                <SelectForm
                    title="스트리밍 정보"
                    name="ivsIdx"
                    items={ivsList.map((ivs) => {
                        return { label: ivs.name, value: ivs.idx };
                    })}
                    value={form?.ivsIdx}
                    onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                    nullTitle="스트리밍 정보 선택"
                />
                <SelectForm
                    title="다국어 스트리밍"
                    name="ivs2Idx"
                    items={ivsList.map((ivs) => {
                        return { label: ivs.name, value: ivs.idx };
                    })}
                    value={form?.ivs2Idx}
                    onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                    nullTitle="다국어 스트리밍 정보 선택"
                />
                <Separator />
                <SelectForm
                    title="온디맨드 영상"
                    name="eventContentIdx"
                    items={contentList.map((content) => {
                        return { label: content.title, value: content.idx };
                    })}
                    value={form?.eventContentIdx}
                    onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                    nullTitle="온디맨드 영상 선택"
                />
                <SelectForm
                    title="다국어 영상"
                    name="eventContent2Idx"
                    items={contentList.map((content) => {
                        return { label: content.title, value: content.idx };
                    })}
                    value={form?.eventContent2Idx}
                    onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                    nullTitle="다국어 영상 선택"
                />
                <Separator />
                <FileForm title="썸네일" onChange={(v) => fileUpload("thumbnailFileIdx", v)} path={`EVENT/CHANNEL/${moment().format("YYYY/MM/DD")}`} url={form?.thumbnailSignedUrl} accept="image/*" fileName={form?.thumbnailFileName} id="thumbnailFile" description="*권장해상도 1920 x 1080 (16x9 )" />
                {/* <SegmentedForm title="상태" items={CHANNEL_STATUS} value={form?.status} onClick={(v) => setForm({ ...form, status: v })} /> */}
                <Separator />
                <TextForm title="설명" name="description" placeholder="설명" value={form?.description} onChange={(e) => setForm({ ...form, description: e.target.value })} />
                <h4>경고안내문</h4>
                <SNEditor content={channelAlert} onChange={(c) => setChannelAlert(c)} height="200" />
                <Separator />
                <div className="row">
                    <div className="col-md-12 text-right">
                        <div className="btn-group">
                            <button type="button" className="btn btn-primary" onClick={onSave}>
                                <i className="fa fa-save"></i> {idx === "ADD" ? "등록" : "수정"}
                            </button>
                            <button type="button" className="btn btn-default" onClick={onChange}>
                                <i className="fa fa-times"></i> 취소
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
