import React, { Fragment, useContext, useEffect, useState } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U, _CK } from "../../../modules/utils";
import { Loading } from "../../../components/UI/Loading";

import Video from "../../../components/UI/Video";

import { ConnectionButton, H2, H3, HRDash, ItemEmpty } from "./UI/Components";
import { ICFile, ICFileSet, getBgSet } from "./asset";

export default (props) => {
    console.log(props);
    const { code, contentIdx: channelIdx } = props;

    const { cfg, auth, iam } = useContext(AppContext);
    const event = cfg.event || null;
    const { siteIdx, eventIdx, startDate, endDate } = event;

    const [isLoading, setIsLoading] = useState(true);
    const [channelDateList, setChannelDateList] = useState(event.channel?.map(item => { return item.progressDate }).sort((a, b) => (a > b ? 1 : a < b ? -1 : 0)));
    const [selectDate, setSelectDate] = useState(channelDateList?.filter(item => item === moment().format("YYYYMMDD"))[0] || channelDateList[0]);

    const [channel, setChannel] = useState([]);
    const [selectChannel, setSelectChannel] = useState(channelIdx);
    const [sessions, setSessions] = useState([]);

    const loadAgenda = () => {
        _API.get({
            path: "/v1/site/event/agenda",
            data: {
                siteIdx,
                eventIdx,
                date: selectDate,
                channelIdx: selectChannel,
            },
        }).then((res) => {
            const { channels, sessions } = res.data;

            setChannel(channels);
            if (channels.length > 0 && !selectChannel) {
                setSelectChannel(channels[0]?.idx);
            }
            setSessions(sessions);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        setIsLoading(true);
        if (selectDate || selectChannel) {
            loadAgenda();
        }
    }, [selectDate, selectChannel]);

    const getDateTab = () => {
        const result = [];

        for (let i in channelDateList) {
            const m = channelDateList[i];
            result.push(
                <DateTab
                    ison={selectDate == m}
                    key={i}
                    onClick={() => {
                        setSelectDate(m);
                        setSelectChannel(null);
                        setSessions([]);
                    }}
                >
                    {moment(m).format("YYYY.MM.DD(ddd)")}
                </DateTab>
            );
        }

        if (isMobile) {
            result.push(<ItemEmpty length={channelDateList?.length || 0} number={2} component={<DateTab />} />);
        }

        return result;
    };

    if (isLoading) {
        return <Loading title="일정표를 조회하고 있습니다." background="rgba(0, 0, 0, 0.1)" />;
    }

    const clickRepaly = (item, sessionTitle) => {
        _U.postMessage("ONLY-VIDEO", { ...item, videoSignedUrl: item.replaySignedUrl, contentType: "REPLAY", sessionTitle: sessionTitle, vodEncodedUrl: item.replayEncodedUrl });
    };

    return (
        <>
            <H2 style={{ textAlign: "center" }}>Program</H2>
            {!event || !startDate || !endDate ? (
                <EmptyWrapper>
                    <H3>설정된 일정이 없습니다.</H3>
                </EmptyWrapper>
            ) : (
                <Wrapper>
                    <>
                        {channel?.length > 0 && (
                            <ChannelWrapper>
                                {channel?.map((c, i) => (
                                    <CItem
                                        key={i}
                                        ison={selectChannel === c.idx}
                                        onClick={() => {
                                            setSelectChannel(c.idx);
                                        }}
                                    >
                                        {c.title}
                                    </CItem>
                                ))}
                            </ChannelWrapper>
                        )}
                        {sessions?.length > 0 && (
                            <SessionsWrapper>
                                {sessions?.map((s, i) => (
                                    <SessionItem key={i}>
                                        {s.sessionType === "LECTURE" ? (
                                            <STop sessionType={s.sessionType}>
                                                <STitle>
                                                    {s.title}

                                                    {/* <span>
                                                        {s.startTime} ~ {s.endTime}
                                                    </span> */}
                                                </STitle>
                                                <SMgr dangerouslySetInnerHTML={{ __html: s.mgrName?.replace(/,/gi, "<br/>") }} />
                                            </STop>
                                        ) : (
                                            <LectureWrapper type={s.sessionType}>
                                                <LTime>
                                                    {s.startTime} ~ {s.endTime}
                                                </LTime>
                                                <LTitle>
                                                    {s.title}
                                                    {isMobile && s.mgrName && <LMgr>{s.mgrName}</LMgr>}
                                                </LTitle>
                                                <LReplay></LReplay>
                                                {!isMobile && <LMgr>{s.mgrName}</LMgr>}
                                            </LectureWrapper>
                                        )}

                                        {s.lectures?.map((l, j) => (
                                            <LectureWrapper key={j}>
                                                <LTime>
                                                    {l.startTime} ~ {l.endTime}
                                                </LTime>
                                                <LTitle>
                                                    <div>{l.title}</div>


                                                    {l.files?.map((f, k) => (
                                                        <LFile
                                                            key={k}
                                                            alt={f.name}
                                                            num={k + 1}
                                                            onClick={() => {
                                                                window.open(f.signedUrl);
                                                            }}
                                                        />
                                                    ))}
                                                    {/* {isMobile && l.mgrName && <LMgr>{l.mgrName}</LMgr>} */}
                                                    {isMobile && l.replaySignedUrl && (
                                                        <LReplay>
                                                            {l.replaySignedUrl && (
                                                                <a onClick={(e) => clickRepaly(l, s?.title, e)} style={{ cursor: "pointer", backgroundColor: "#393c6f", opacity: "unset", color: "#ffffff" }}>
                                                                    VOD VIEW <i className="fa fa-play-circle-o"></i>
                                                                </a>
                                                            )}
                                                        </LReplay>
                                                    )}
                                                </LTitle>

                                                {!isMobile && (
                                                    <LReplay>
                                                        {l.replaySignedUrl && (
                                                            <a onClick={(e) => clickRepaly(l, s?.title, e)} style={{ cursor: "pointer", backgroundColor: "#393c6f", opacity: "unset", color: "#ffffff" }}>
                                                                VOD VIEW <i className="fa fa-play-circle-o"></i>
                                                            </a>
                                                        )}
                                                    </LReplay>
                                                )}

                                                {!isMobile && <LMgr dangerouslySetInnerHTML={{ __html: l.mgrName?.replace(/,/gi, "<br/>") }} />}
                                            </LectureWrapper>
                                        ))}
                                    </SessionItem>
                                ))}
                            </SessionsWrapper>
                        )}

                        {!sessions?.length > 0 && !selectChannel && (
                            <EmptyWrapper height={`calc(100% - 28px - 2rem - 42px)`}>
                                <H3>일정이 없습니다.</H3>
                            </EmptyWrapper>
                        )}
                    </>
                </Wrapper>
            )}
        </>
    );
};

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;

const TabWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap;
`;
const DateTab = styled.div`
    flex: 1;
    margin-right: 10px;
    font-size: 16px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 14px;
    cursor: pointer;

    ${(props) =>
        props.ison
            ? `
    color: ${props.theme.basic.color.white};    
    background-color: ${props.theme.basic.bg.default};
    border-radius:21px;
    `
            : `
    color: ${props.theme.basic.color.description};
    `}

    font-family: ${(props) => props.theme.fontFamily.regular};
    text-align: center;
    :last-child {
        margin-right: 0px;
    }
`;

const ChannelWrapper = styled.ul`
    padding: 0px;
    margin: 24px 0 0 0px;
    list-style-type: none;
    display: block;
`;
const CItem = styled.li`
    display: inline-block;
    position: relative;
    padding: 0 15px;
    font-size: 14px;
    cursor: pointer;

    ${(props) =>
        props.ison
            ? `
    color: ${props.theme.basic.color.title};
    font-family: ${props.theme.fontFamily.medium};    
    `
            : `
    color: ${props.theme.basic.color.off};
    font-family: ${props.theme.fontFamily.regular};
    `}

    :last-child {
        :after {
            content: unset;
        }
    }

    :after {
        content: "";
        position: absolute;
        top: 6px;
        right: 0;
        border-right: 1px solid ${(props) => props.theme.basic.line.bar};
        height: 50%;
    }
`;

const EmptyWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    /* ${(props) => (props.height ? `height:${props.height}` : `height:calc(100% - 28px - 2rem)`)} */

    height: ${(props) => (props.height ? props.height : `calc(100% - 28px - 2rem)`)};
`;

const SessionsWrapper = styled.div`
    margin-top: 15px;
`;
const SessionItem = styled.div``;

const STop = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;

    border-top: 1px solid ${(props) => props.theme.basic.line.agenda};
    border-bottom: 1px solid ${(props) => props.theme.basic.line.agenda};
    background-color: ${(props) => props.theme.basic.bg.agenda};

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        display: block;
    }
`;

const STitle = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.basic.color.title};
    font-family: ${(props) => props.theme.fontFamily.medium};
    font-size: 16px;

    span {
        color: ${(props) => props.theme.basic.color.off};
        font-family: ${(props) => props.theme.fontFamily.regular};
        font-size: 12px;
        margin-left: 17px;
        padding-top: 3px;
    }

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        width: 100%;
    }
`;
const SMgr = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;

    color: ${(props) => props.theme.basic.color.primary};
    font-family: ${(props) => props.theme.fontFamily.regular};
    font-size: 12px;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        width: 100%;
        margin-top: 10px;
    }
`;

const LectureWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 20px 0 0;
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        padding: 0px;
    }
    ${(props) =>
        props.type === "Break"
            ? `
                background-color: ${props.theme.basic.bg.break};

                & ${LTime}{
                    padding: 10px 0;
                }
                & ${LTitle}{
                    padding: 10px 0 10px 30px;
                    text-align: center;   

                    @media only screen and (max-width: ${props.theme.basic.mobileWidth}px) {
                        padding: 10px 0 10px 20px;
                    }
                    // font-size:12px;
                    // font-family: ${props.theme.fontFamily.medium};
                    // color: ${props.theme.basic.color.primary};
                }
            `
            : `
                border-bottom: 1px solid ${props.theme.basic.line.agendaLecture};
            `}
`;

const LTime = styled.div`
    flex: 1;
    flex-grow: 1;
    border-right: 1px solid ${(props) => props.theme.basic.line.agendaLecture};
    padding: 17px 0;
    text-align: center;
    /* font-size: 12px;
    font-family: ${(props) => props.theme.fontFamily.regular};
    color: ${(props) => props.theme.basic.color.off}; */
    font-size: 14px;
    font-family: ${(props) => props.theme.fontFamily.medium};
    color: ${(props) => props.theme.basic.color.primary};
    display:flex;
    align-items:center;
    justify-content:center;
`;
const LTitle = styled.div`
    flex: 1;
    flex-grow: 3;
    padding: 17px 20px;
    font-size: 14px;
    font-family: ${(props) => props.theme.fontFamily.medium};
    color: ${(props) => props.theme.basic.color.primary};
    display:flex;
    align-items:center;
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        flex-grow: 2.5;
        padding: 15px 0 15px 20px;
        font-size: 14px;
        display:block;

        span {
            margin-left: 0px;
            margin-right: 5px;
        }
    }
`;
const LMgr = styled.div`
    flex: 1;
    flex-grow: 2;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;

    color: ${(props) => props.theme.basic.color.primary};
    font-family: ${(props) => props.theme.fontFamily.regular};
    font-size: 12px;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        justify-content: flex-start;
        padding: 3px 0;
    }
`;

const LReplay = styled.div`
    flex: 1;
    flex-grow: 0.5;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        justify-content: flex-start;
        padding-top: 5px;
    }

    a {
        color: ${(props) => props.theme.basic.color.play};
        font-size: 10px;
        background: #000000;
        opacity: 0.6;
        border-radius: 14px;
        padding: 5px 8px 3px;

        @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
            padding: 3px 8px;
        }
    }
`;

const LFile = styled.span`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${(props) => (props.theme.basic.bg[`file${props.num}`] ? props.theme.basic.bg[`file${props.num}`] : props.theme.basic.bg.file1)};
    background-image: ${`url(${ICFile})`};
    background-image: -webkit-image-set(${getBgSet(ICFileSet)});
    background-repeat: no-repeat;
    background-size: 8px 10px;
    background-position: center center;
    display: inline-block;
    line-height: 30px;
    vertical-align: middle;
    margin-left: 10px;
    cursor: pointer;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        width: 20px;
        height: 20px;
        background-size: 8px 10px;
        margin-left: 5px;
        padding-top: 5px;
    }
`;

const ContentBooth = styled.div`
    flex: 1;
    width: 100%;
    min-height: 40vh;
    max-height: 90vh;
    overflow-x: hidden;
    overflow-y: auto;

    .image img {
        max-width: 100%;
    }
`;
