import React, { Fragment, useContext, useEffect, useState } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U, _CK } from "../../../modules/utils";

import { ConnectionButton, ProgramButton, H2, H3, ItemEmpty } from "./UI/Components";

export default (props) => {
    const { cfg, auth, iam } = useContext(AppContext);
    const event = cfg.event || null;
    const [channelList, setChannelList] = useState([]);

    if (!event) {
        return null;
    }

    const { startDate, endDate, channel } = event;
    if (!startDate || !endDate || channel?.length === 0) {
        return null;
    }

    const isExsitsChannel = () => {
        if (channel.filter((item) => item.progressDate == moment().format("YYYYMMDD")).length === 0) {
            console.log("당일 진행 채널 없음 : " + moment().format("YYYYMMDD"));
            return false;
        }

        return true;
    };

    const onEnter = (idx) => {
        if (!_CK.isLogin()) {
            swal("로그인 후 이용이 가능합니다").then(() => {
                window.location.reload();
            });
        } else {
            window.open(`/#/channel/${idx}`);
            _U.postMessage("CLOSE-MODAL");
        }
    };

    useEffect(() => {
        if (isExsitsChannel()) {
            const channels = channel.filter((item) => {
                if (item.progressDate == moment().format("YYYYMMDD")) {
                    return item;
                }
            });

            setChannelList(channels);
        }
    }, []);

    return (
        <>
            <BaseWrapper>
                <H2 style={{ textAlign: "center" }}>{moment().format("YYYY. MM. DD(ddd)")}</H2>
                <Wrapper>
                    {isExsitsChannel() ? (
                        <>
                            {channelList.map((item, i) => (
                                <Article key={i}>
                                    <ArticleInfo>
                                        <span>{item.title}</span>
                                        <Date>
                                            {moment(item.progressDate).format("YYYY.MM.DD(ddd) ")}
                                            {item.lectures?.length > 0 && item.lectures[0].sessionStartTime + "~" + item.lectures[item.lectures?.length - 1].sessionEndTime}
                                        </Date>
                                        <p>{item.description}</p>
                                    </ArticleInfo>
                                    <ButtonGroup>
                                        <ConnectionButton onClick={onEnter.bind(this, item.idx)}>입장하기</ConnectionButton>
                                        <div style={{padding : "5px"}}></div>
                                        <ProgramButton onClick={onEnter.bind(this, item.idx)}>program</ProgramButton>
                                    </ButtonGroup>
                                </Article>
                            ))}
                            <ItemEmpty length={channelList?.length} number={3} component={<Article />} />
                        </>
                    ) : (
                        <EmptyWrapper>
                            <H3>오늘 진행 예정 세션이 없습니다.</H3>
                        </EmptyWrapper>
                    )}
                </Wrapper>
            </BaseWrapper>
        </>
    );
};
const BaseWrapper = styled.div`
/* width: 1200px; */
width : 100%;
margin: 0 auto;
padding-top: 3rem;

@media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
    width: 100%;
    padding: 1rem;
}
`;

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: space-around;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        flex-direction: column;
    }
`;

const Date = styled.div`
    margin-top: 5px;
    font-family: ${(props) => props.theme.fontFamily.medium};
    font-size: 12px;
    text-align: left;
    color: ${(props) => props.theme.basic.color.primary};
`;

const Article = styled.div`
    align-items: center;
    flex-basis: 30%;
    background-color: ${(props) => props.theme.basic.bg.background};
    margin-bottom: 25px;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        flex-basis: 100%;
        flex: 1;
        width: 100%;
    }
`;

const ArticleInfo = styled.div`
    border: solid 0px ${(props) => props.theme.basic.line.default};
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 15px;

    box-shadow: 2.5px 4.3px 15px 0 rgba(0, 0, 0, 0.15);

    & span {
        font-family: ${(props) => props.theme.fontFamily.medium};
        font-size: 20px;
        color: ${(props) => props.theme.basic.color.primary};
    }

    & p {
        margin-top: 14px;
        font-family: ${(props) => props.theme.fontFamily.medium};
        font-size: 14px;
        color: ${(props) => props.theme.basic.color.primary};

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
`;

const EmptyWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ButtonGroup = styled.div`
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    width : 100%;

    & button {
        position: relative;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    }
`;