import React, { useContext, useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import swal from "sweetalert";
import * as FilePond from "filepond";
import { FilePond as FilePondForm } from "react-filepond";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import Layout from "../Layout";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";
import { _DEF } from "../../../modules/config";

import Wrapper, { Row, FormGroup, Separator } from "../../../components/UI/Wrapper";
import { SearchInput, SelectForm, InputForm, SegmentedForm, FileForm, DatePicker } from "../../../components/UI/Form";
import Button from "../../../components/UI/Button";
import Paging, { ToPage, GetParams } from "../../../components/UI/Paging";


export default (props) => {

    const { history, location, match } = props;

    const { cfg } = useContext(AppContext);

    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [eventIdx, setEventIdx] = useState("");
    const [keyword, setKeyword] = useState("");

    const [list, setList] = useState([]);
    const [paging, setPaging] = useState(null);
    const [eventList, setEventList] = useState([]);
    const [mode, setMode] = useState("");

    const loadList = (page) => {
        // if(!eventIdx){
        //     swal({ title: "알림", text: "이벤트를 선택해 주세요." });
        //     return;
        // }
        const data = {
            eventIdx,
            siteIdx,
            keyword,
            page,
        };
        const req = { path: "/v1/a/event/ebooth/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setList(list);
            setPaging(paging);
        });
    };

    const loadEventList = (siteIdx) => {
        const data = {
            siteIdx,
        };
        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setEventList(list);
        });
    };

    const movePage = (page) => {
        loadList(page);
    };

    const onSearch = (e) => {
        if (e) e.preventDefault();
        loadList(1);
    };

    useEffect(() => {
        loadList(1);
        loadEventList(siteIdx);
    }, []);

    const onForm = (idx, e) => {
        if (e) e.preventDefault();
        if (idx) history.push(`/admin/event/ebooth/form/${idx}`);
        else history.push(`/admin/event/ebooth/form`);
    }

    const onChangeState = (item, col, val, e) => {
        if (e) e.preventDefault();

        swal({
            title: `이부스`,
            text: `상태를 변경하시겠습니까?`,
            icon: "info",
            buttons: ["취소", "예"],
            dangerMode: val === "D" ? true : false,
        }).then((ok) => {
            if (ok) {
                _API.post({
                    path: "/v1/a/event/category/status",
                    data: {
                        idx: item.idx
                        , category: "ebooth"
                        , status: val
                    },
                }).then((res) => {
                    loadList(1);
                });
            }
        });
    }

    const changeMode = (mode) => {
        setMode(mode);
        loadList(1);
    }

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-8">
                    <h2>e부스</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/admin">HOME</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <strong>이벤트</strong>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>e부스</strong>
                        </li>
                    </ol>
                </div>
                <div className="col-sm-4">
                    <div className="title-action">
                        <button type="button" className="btn btn-primary" onClick={onForm.bind(this, "")}>
                            <i className="fa fa-plus"></i> 추가
                        </button>
                    </div>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className={cx({ "col-md-12": !mode, "col-md-6": mode })}>
                        <div className="ibox">
                            <div className="ibox-title">목록</div>
                            <div className="ibox-content ibox-heading">
                                <div className="row">
                                    <div className="col-md-2">
                                        <select value={eventIdx} onChange={(e) => setEventIdx(e.target.value)} className="form-control">
                                            <option value="">이벤트선택</option>
                                            {eventList?.map((item, i) => (
                                                <option key={i} value={item.eventIdx}>
                                                    {item.title}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-2">
                                        <SearchInput value={keyword} onChange={(e) => setKeyword(e.target.value)} onClick={onSearch} placeholder="검색어를 입력해 주세요." />
                                    </div>
                                </div>
                            </div>
                            <div className="ibox-content">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th className="text-center">ID</th>
                                                <th className="text-center">이벤트명</th>
                                                <th className="text-center">코드</th>
                                                <th className="text-center">제목</th>
                                                {/* <th className="text-center">URL</th> */}
                                                <th className="text-center">상태</th>
                                                <th className="text-center">등록일</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.map((item, i) => (
                                                <tr key={i}>
                                                    <td className="text-center">{item.idx}</td>
                                                    <td className="text-center">{item.eventName}</td>
                                                    <td className="text-center">{item.code}</td>
                                                    <td className="text-center">
                                                        <a href="" onClick={onForm.bind(this, item.idx)}>{item.title}</a>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="btn-group">
                                                            <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "W", "btn-success": item.status === "W" })} onClick={onChangeState.bind(this, item, "status", "W")}>
                                                                대기
                                                            </button>
                                                            <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "Y", "btn-success": item.status === "Y" })} onClick={onChangeState.bind(this, item, "status", "Y")}>
                                                                정상
                                                            </button>
                                                            <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "N", "btn-success": item.status === "N" })} onClick={onChangeState.bind(this, item, "status", "N")}>
                                                                미사용
                                                            </button>
                                                            <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "D", "btn-success": item.status === "D" })} onClick={onChangeState.bind(this, item, "status", "D")}>
                                                                삭제
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">{moment(item.regDate).format("YYYY-MM-DD")}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {paging && (
                                <div className="ibox-footer">
                                    <Paging paging={paging} pageCallback={movePage} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
