import React, { Fragment, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import cx from "classnames";
import reactCSS from "reactcss";
import swal from "sweetalert";
import { SketchPicker } from "react-color";
import { rgbaToAHex, aHexToRgba } from "hex-and-rgba";
import Datetime from "react-datetime";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import "moment/locale/ko";

import Modal from "react-modal";
import { Theme } from "../GlobalStyles";
import LoadingOverlay from "react-loading-overlay";

import { _CFG } from "../../modules/config";
import { _U, _API } from "../../modules/utils";
import CheckOn1x from "../../resources/image/basic/bt-check-01-on.png";
import CheckOn2x from "../../resources/image/basic/bt-check-01-on@2x.png";
import CheckOn3x from "../../resources/image/basic/bt-check-01-on@3x.png";
import Check1x from "../../resources/image/basic/bt-check-01.png";
import Check2x from "../../resources/image/basic/bt-check-01@2x.png";
import Check3x from "../../resources/image/basic/bt-check-01@3x.png";

const getBgSet = (set) => {
    let image = "";
    for (let i in set) {
        const img = set[i];
        const num = parseInt(i);
        if (num !== 0) {
            image += ", ";
        }
        image += `url(${img}) ${num + 1}x`;
    }
    return image;
};

const Input = styled.input.attrs((props) => {
    return {
        type: props.type || "text",
        className: props.className || "form-control",
        step: props.step || "",
    };
})``;

export default Input;
export const SearchInput = (props) => {
    const onKeyDown = (e) => {
        if (e.key === "Enter") {
            props.onClick && props.onClick();
        }
        props.onKeyDown && props.onKeyDown(e);
    };

    return (
        <div className="input-group">
            <Input name={props.name} id={props.id} value={props.value || ""} placeholder={props.placeholder} onChange={props.onChange} onKeyDown={onKeyDown} />
            <div className="input-group-append">
                <button type="button" className="btn btn-default" onClick={props.onClick}>
                    {props.buttonTitle || <i className="fa fa-search" />}
                </button>
            </div>
        </div>
    );
};

export const SearchForm = (props) => {
    const { title, hr, type, value, onChange, name } = props;

    const onKeyDown = (e) => {
        if (e.key === "Enter") {
            props.onClick && props.onClick();
        }
        props.onKeyDown && props.onKeyDown(e);
    };

    return (
        <Fragment>
            <div className="form-group row">
                {title && <label className="col-sm-3 control-label m-t-sm">{title}</label>}
                <div className={cx({ "col-sm-9": title, "col-sm-12": !title })}>
                    <div className="input-group">
                        <input type={type || "text"} value={value || ""} placeholder={props.placeholder} onChange={onChange} className="form-control" name={name} onKeyDown={onKeyDown} />
                        <div className="input-group-append">
                            <button type="button" className="btn btn-default" onClick={props.onClick}>
                                {props.buttonTitle || <i className="fa fa-search" />}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {hr && <div className="hr-line-dashed"></div>}
        </Fragment>
    );
};

export const InputForm = (props) => {
    const { title, hr, type, value, onChange, name, description, style, readOnly } = props;
    return (
        <Fragment>
            <div className="form-group row">
                {title && <label className="col-sm-3 control-label m-t-sm">{title}</label>}
                <div className={cx({ "col-sm-9": title, "col-sm-12": !title })}>
                    <input
                        type={type || "text"}
                        value={value || ""}
                        placeholder={props.placeholder}
                        onChange={onChange}
                        className="form-control"
                        name={name}
                        step={props.step}
                        style={style}
                        readOnly={readOnly && true}
                    />
                </div>
            </div>
            {description && (
                <div className="form-group row">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-9">{description}</div>
                </div>
            )}
            {hr && <div className="hr-line-dashed"></div>}
        </Fragment>
    );
};

export const TextForm = (props) => {
    const { title, hr, type, value, onChange, name, description, style, rows } = props;
    return (
        <Fragment>
            <div className="form-group row">
                {title && <label className="col-sm-3 control-label m-t-sm">{title}</label>}
                <div className={cx({ "col-sm-9": title, "col-sm-12": !title })}>
                    <textarea value={value || ""} placeholder={props.placeholder} onChange={onChange} className="form-control" name={name} step={props.step} style={style} rows={rows || 5} />
                </div>
            </div>
            {description && (
                <div className="form-group row">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-9">{description}</div>
                </div>
            )}
            {hr && <div className="hr-line-dashed"></div>}
        </Fragment>
    );
};

export const SegmentedForm = (props) => {
    const { title, hr, items, type, value, onClick, description } = props;
    return (
        <Fragment>
            <div className="form-group row">
                {title && <label className="col-sm-3 control-label m-t-sm">{title}</label>}
                <div className={cx({ "col-sm-9": title, "col-sm-12": !title, "btn-group": true })}>
                    {items.map((item, i) => (
                        <button key={i} type="button" onClick={onClick?.bind(this, item.value)} className={cx({ btn: true, "btn-default": value !== item.value, "btn-success": value === item.value })}>
                            {item.label}
                        </button>
                    ))}
                </div>
            </div>
            {description && (
                <div className="form-group row">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-9">{description}</div>
                </div>
            )}
            {hr && <div className="hr-line-dashed"></div>}
        </Fragment>
    );
};

export const SelectForm = (props) => {
    const { title, name, nullTitle, hr, items, value, onChange, readOnly } = props;
    return (
        <Fragment>
            <div className="form-group row">
                {title && <label className="col-sm-3 control-label m-t-sm">{title}</label>}
                <div className={cx({ "btn-group": true, "col-sm-9": title, "col-sm-12": !title })}>
                    <select name={name} value={value || ""} onChange={onChange} className="form-control" readOnly={readOnly && true}>
                        <option value="" disabled={readOnly && true}>
                            {nullTitle}
                        </option>
                        {items.map((item, i) => (
                            <option key={i} value={item.value} disabled={readOnly && true}>
                                {item.label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            {hr && <div className="hr-line-dashed"></div>}
        </Fragment>
    );
};

export const ColorPicker = (props) => {
    const { title, name, hr, value, onChange } = props;
    const [color, setColor] = useState({ r: "255", g: "255", b: "255", a: "1" });
    const [displayColorPicker, setDisplayColorPicker] = useState(false);

    const styles = reactCSS({
        default: {
            color: {
                width: "36px",
                height: "14px",
                borderRadius: "2px",
                background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
            },
            swatch: {
                marginTop: "0.5rem",
                padding: "5px",
                background: "#fff",
                borderRadius: "1px",
                boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                display: "inline-block",
                cursor: "pointer",
            },
            popover: {
                position: "absolute",
                zIndex: "2",
            },
            cover: {
                position: "fixed",
                top: "0px",
                right: "0px",
                bottom: "0px",
                left: "0px",
            },
        },
    });

    const onChooseColor = () => {
        setDisplayColorPicker(true);
    };

    const onClose = () => {
        setDisplayColorPicker(false);
    };

    const onColorSelected = (color) => {
        setColor(color.rgb);
        const { rgb } = color;
        onChange && onChange({ name, value: rgbaToAHex(rgb.r, rgb.g, rgb.b) });
    };

    useEffect(() => {
        if (value) {
            const rgb = aHexToRgba(value);
            setColor({ r: rgb[0], g: rgb[1], b: rgb[2], a: rgb[3] });
        }
    }, [value]);

    return (
        <Fragment>
            <div className="form-group row">
                {title && <label className="col-sm-3 control-label m-t-sm">{title}</label>}
                <div className={cx({ "col-sm-9": title, "col-sm-12": !title })}>
                    <div style={styles.swatch} onClick={onChooseColor}>
                        <div style={styles.color} />
                    </div>
                    {displayColorPicker && (
                        <div style={styles.popover}>
                            <div style={styles.cover} onClick={onClose} />
                            <SketchPicker color={color} onChange={onColorSelected} disableAlpha={true} />
                        </div>
                    )}
                </div>
            </div>
            {hr && <div className="hr-line-dashed"></div>}
        </Fragment>
    );
};

export const DateTimePicker = (props) => {
    const { title, name, hr, value, onChange } = props;
    const onDateChanged = (date) => {
        try {
            onChange && onChange({ name, value: date.toDate() });
        } catch (e) {
            onChange && onChange({ name, value: null });
        }
    };
    return (
        <Fragment>
            <div className="form-group row">
                {title && <label className="col-sm-3 control-label m-t-sm">{title}</label>}
                <div className={cx({ "col-sm-9": title, "col-sm-12": !title })}>
                    <Datetime value={value ? moment(value).toDate() : null} onChange={onDateChanged} dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss" readOnly={true} />
                </div>
            </div>
            {hr && <div className="hr-line-dashed"></div>}
        </Fragment>
    );
};

export const TimePicker = (props) => {
    const { title, name, hr, value, onChange, description } = props;
    const onDateChanged = (date) => {
        try {
            onChange && onChange({ name, value: date.toDate() });
        } catch (e) {
            onChange && onChange({ name, value: null });
        }
    };
    return (
        <Fragment>
            <div className="form-group row">
                {title && <label className="col-sm-3 control-label m-t-sm">{title}</label>}
                <div className={cx({ "col-sm-9": title, "col-sm-12": !title })}>
                    <Datetime
                        value={value ? moment(value).toDate() : null}
                        onChange={onDateChanged}
                        dateFormat={false}
                        timeFormat="HH:mm"
                        readOnly={true}
                        renderInput={(props) => {
                            return <input {...props} value={value ? props.value : ""} />;
                        }}
                    />
                </div>
            </div>
            {description && (
                <div className="form-group row">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-9" style={{ color: "red" }}>
                        {description}
                    </div>
                </div>
            )}
            {hr && <div className="hr-line-dashed"></div>}
        </Fragment>
    );
};

export const DatePicker = (props) => {
    const { title, name, hr, value, onChange, required } = props;
    const { minDate, maxDate, filterDate } = props;
    let dateValue = "";
    try {
        dateValue = value ? moment(value, "YYYY-MM-DD").toDate() : "";
        if (dateValue == "Invalid Date") {
            dateValue = "";
        }
    } catch (e) {
        console.log(e);
    }

    const onDateChanged = (date) => {
        onChange && onChange({ name, value: moment(date).format("YYYY-MM-DD") });
    };
    return (
        <Fragment>
            <div className="form-group row">
                {title && (
                    <label className="col-sm-3 control-label m-t-sm">
                        {title}
                        {required && <span style={{ color: "red" }}> *</span>}
                    </label>
                )}
                <div className={cx({ "col-sm-9": title, "col-sm-12": !title })}>
                    <ReactDatePicker
                        selected={dateValue}
                        onChange={(date) => onDateChanged(date)}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                        minDate={minDate}
                        maxDate={maxDate}
                        filterDate={filterDate}
                        style={{ float: "left" }}
                    />
                    {props.children}
                </div>
            </div>
            {hr && <div className="hr-line-dashed"></div>}
        </Fragment>
    );
};

export const AddressForm = (props) => {
    const { title, hr, values, isGeo, onChange } = props;
    const [addr, setAddr] = useState({ ...values });

    const onAddrOpen = () => {
        new daum.Postcode({
            oncomplete: (data) => {
                const address = {
                    zipcode: data.zonecode,
                    addr: data.address,
                    addrEng: data.addressEnglish,
                };
                setAddr(address);
                if (isGeo) {
                    _U.getGEO(_CFG.google.maps.apiKey, address.addr)
                        .then((geo) => {
                            const fa = { ...address, latitude: geo.lat, longitude: geo.lng };
                            setAddr(fa);
                            onChange && onChange(fa);
                        })
                        .catch((e) => {
                            swal({ title: "알림", text: e.message });
                        });
                } else {
                    onChange && onChange(address);
                }
            },
        }).open();
    };

    const onAddrChange = (e) => {
        const a = { ...addr, [e.target.name]: e.target.value };
        setAddr(a);
        onChange && onChange(a);
    };

    useEffect(() => {
        setAddr({ ...values });
    }, [values]);

    return (
        <Fragment>
            <div className="form-group row">
                {title && <label className="col-sm-3 control-label m-t-sm">{title}</label>}
                <div className={cx({ "col-sm-9": title, "col-sm-12": !title })}>
                    <div className="row">
                        <div className="col-sm-4">
                            <input type="text" value={addr.zipcode || ""} className="form-control" readOnly onClick={onAddrOpen} placeholder="우편번호" />
                        </div>
                        <div className="col-sm-8">
                            <input type="text" value={addr.addr || ""} className="form-control" readOnly onClick={onAddrOpen} placeholder="주소" />
                        </div>
                    </div>
                    <div className="row m-t-sm">
                        <div className="col-sm-12">
                            <input type="text" name="addrDtl" value={addr.addrDtl || ""} className="form-control" onChange={onAddrChange} placeholder="상세 주소" />
                        </div>
                    </div>
                </div>
            </div>
            {isGeo && (
                <div className="form-group row">
                    <label className="col-sm-3 control-label m-t-sm">GEO</label>
                    <div className="col-sm-4">
                        <input type="text" value={addr.latitude || ""} className="form-control" readOnly onClick={onAddrOpen} placeholder="위도" />
                    </div>
                    <div className="col-sm-4">
                        <input type="text" value={addr.longitude || ""} className="form-control" readOnly onClick={onAddrOpen} placeholder="경도" />
                    </div>
                </div>
            )}
            {hr && <div className="hr-line-dashed"></div>}
        </Fragment>
    );
};

export const FileForm = (props) => {
    const { title, hr, accept, onChange, description, path, url: urlInProps, fileName, id } = props;

    const [awsKey, setAwsKey] = useState(null);
    const [file, setFile] = useState(null);
    const [url, setUrl] = useState(urlInProps);

    const [loaded, setLoaded] = useState(true);
    const [loadPercent, setLoadPercent] = useState(0);
    const [fileKey, setFileKey] = useState();

    const DarkBackground = styled.div`
        display: none;
        position: relative;
        top: 50%;

        ${(props) =>
            props.disappear &&
            css`
                display: block; /* show */
            `}
    `;

    const loadKeys = () => {
        _API.loadUploadKeys()
            .then((res) => res.data)
            .then((data) => {
                setAwsKey(data);
            });
    };

    const handleFileChange = (e) => {
        if (e.target.files.length === 0) {
            return;
        }
        setFile(e.target.files[0]);
    };

    const handleFileUpload = () => {
        if (!file) {
            return;
        } else {
            setLoaded(false);
        }

        _API.upload({
            aws: awsKey,
            file,
            type: "SN",
            path: path ? path : `ETC/${moment().format("YYYY/MM/DD")}`,
            config: { setLoadPercent: setLoadPercent },
        })
            .then((res) => res.data)
            .then((data) => {
                setUrl(data.url);
                onChange && onChange(data);
                setLoaded(true);
            });
    };

    useEffect(() => {
        loadKeys();
    }, []);

    useEffect(() => {
        setUrl(urlInProps);
    }, [urlInProps]);

    useEffect(() => {
        setUrl(null);
        setFile(null);
        setFileKey(Math.random());
    }, [description]);

    const handleFileDelete = () => {
        setFileKey(Math.random());
        props.onFileDelete && props.onFileDelete();
    };

    return (
        <Fragment>
            <div className="form-group row">
                <label className="col-sm-3 control-label">{title}</label>
                <div className="col-sm-9">
                    <div className="input-group">
                        <input
                            type="file"
                            id={id}
                            key={fileKey || ""}
                            placeholder={props.placeholder}
                            className="form-control"
                            accept={accept}
                            style={{ overflow: "hidden" }}
                            onChange={handleFileChange}
                        />
                        <div className="input-group-append">
                            <button type="button" className="btn btn-primary" onClick={handleFileUpload}>
                                <i className="fa fa-upload"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {description && (
                <div className="form-group row">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-9">{description}</div>
                </div>
            )}
            {(url || urlInProps) && (
                <div className="form-group row">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-9">
                        <>
                            {/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test((url || urlInProps).split("?")[0]) ? (
                                <img src={url || urlInProps} alt="" style={{ maxWidth: "100%" }} />
                            ) : (
                                <a href={url || urlInProps} target="_blank">
                                    <i className="fa fa-download"></i> {fileName ? fileName : `다운로드`}
                                </a>
                            )}
                            {props.onFileDelete && (
                                <button type="button" class="btn btn-xs btn-danger" onClick={handleFileDelete}>
                                    <i class="fa fa-trash"></i>
                                </button>
                            )}
                        </>
                    </div>
                </div>
            )}
            {hr && <div className="hr-line-dashed"></div>}

            <Modal isOpen={!loaded} style={Theme.modal.loadingBar}>
                <DarkBackground disappear={!loaded}>
                    {/* <Loader
                    loaded={false}
                    lines={13}
                    length={20}
                    width={10}
                    radius={30}
                    corners={1}
                    rotate={0}
                    direction={1}
                    color="#a5d8ff"
                    speed={1}
                    trail={60}
                    shadow={false}
                    hwaccel={false}
                    className="spinner"
                    zIndex={2e9}
                    top="50%"
                    left="50%"
                    scale={1.0}
                    loadedClassName="loadedContent"
                    />*/}
                    <LoadingOverlay
                        active={true}
                        // spinner={<BounceLoader />}
                        spinner={true}
                        text={`파일을 업로드 중입니다... ${loadPercent}%`}
                    >
                        {/* <p>Some content or children or something.</p> */}
                    </LoadingOverlay>
                </DarkBackground>
            </Modal>
        </Fragment>
    );
};

/**
 * 검색 조건 (사이트, 이벤트, 채널, 세션)
 * @param {boolean} sync - true / false
 * @param {string} siteYn - "Y" / "N"
 * @param {string} eventYn - "Y" / "N"
 * @param {string} channelYn - "Y" / "N"
 * @param {string} sessionYn - "Y" / "N"
 * @param {string} keywordYn - "Y" / "N"
 * @param {object} value - 선행 된 값을 미리 셋팅 시 {siteIdx : "", eventIdx : "", channelIdx: "", sessionIdx : "", keyword : ""}
 * @event onChange - 값 변경 시 이벤트 핸들러
 * @event onSearch - 검색 시 이벤트 핸들러
 * @callback setSiteList - 셀렉트 박스의 Site List 옵션(options) 리턴
 * @callback setEventList - 셀렉트 박스의 Event List 옵션(options) 리턴
 * @callback setChannelList - 셀렉트 박스의 Channel List 옵션(options) 리턴
 * @callback setSessionList - 셀렉트 박스의 Session List 옵션(options) 리턴
 */
export const SearchCondition = ({ sync, siteYn, eventYn, channelYn, sessionYn, keywordYn, value, onChange, onSearch, setSiteList, setEventList, setChannelList, setSessionList, isRefresh }) => {
    const [values, setValues] = useState({ ...value });

    const [searchSiteList, setSearchSiteList] = useState([]);
    const [searchEventList, setSearchEventList] = useState([]);
    const [searchChannelList, setSearchChannelList] = useState([]);
    const [searchSessionList, setSearchSessionList] = useState([]);

    const loadSitetList = () => {
        const data = {};
        const req = { path: "/v1/a/site", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setSearchSiteList(list);
            setSiteList && setSiteList(list);
        });
    };

    const loadEventList = (page) => {
        let data = {};
        if (sync) {
            data = { ...values };
            if (!data.siteIdx) {
                setSearchEventList([]);
                return;
            }
        }
        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setSearchEventList(list);
            setEventList && setEventList(list);
        });
    };

    const loadChannelList = (page) => {
        let data = {};
        if (sync) {
            data = { ...values, sort: "TERM_DATE" };
            if (!data.eventIdx) {
                setSearchChannelList([]);
                return;
            }
        }
        const req = { path: "/v1/a/event/channel/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setSearchChannelList(list);
            setChannelList && setChannelList(list);
        });
    };

    const loadSessionList = (page) => {
        let data = {};
        if (sync) {
            data = { ...values, sort: "TERM_DATE" };
            if (!data.channelIdx) {
                setSearchSessionList([]);
                return;
            }
        }
        const req = { path: "/v1/a/event/session/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setSearchSessionList(list);
            setSessionList && setSessionList(list);
        });
    };

    const settingValue = (e) => {
        const param = values;
        if (sync) {
            switch (e.target.name) {
                case "siteIdx":
                    param.eventIdx = !e.target.value ? "" : param.eventIdx || "";
                    if (param.eventIdx == "") setSearchEventList([]);
                    break;
                case "eventIdx":
                    param.channelIdx = !e.target.value ? "" : param.channelIdx || "";
                    if (param.channelIdx == "") setSearchChannelList([]);
                    break;
                case "channelIdx":
                    param.sessionIdx = !e.target.value ? "" : param.sessionIdx || "";
                    if (param.sessionIdx == "") setSearchSessionList([]);
                    break;
            }
        }
        setValues({ ...param, [e.target.name]: e.target.value });
        onChange && onChange({ ...param, [e.target.name]: e.target.value });
    };

    const BasicSelectForm = (props) => {
        const { title, name, nullTitle, hr, items, value, onChange, readOnly } = props;
        return (
            <Fragment>
                <select name={name} value={value || ""} onChange={onChange} className="form-control" readOnly={readOnly && true}>
                    <option value="" disabled={readOnly && true}>
                        {nullTitle}
                    </option>
                    {items?.map((item, i) => (
                        <option key={i} value={item.value} disabled={readOnly && true}>
                            {item.label}
                        </option>
                    ))}
                </select>
            </Fragment>
        );
    };

    useEffect(() => {
        siteYn === "Y" && loadSitetList();
        eventYn === "Y" && loadEventList();
        channelYn === "Y" && loadChannelList();
        sessionYn === "Y" && loadSessionList();
    }, [location, values]);

    useEffect(() => {
        setValues({ siteIdx: values?.siteIdx ? values?.siteIdx : "", eventIdx: "", channelIdx: "", sessionIdx: "", keyword: "" });
    }, [isRefresh || false]);

    let dynamicSize = 12 / [siteYn, eventYn, channelYn, sessionYn].filter((item) => item === "Y").length || 0;

    return (
        <Fragment>
            <div className="row">
                {siteYn === "Y" && (
                    <div className={`col-md-${dynamicSize}`} style={{ marginBottom: "15px" }}>
                        <BasicSelectForm
                            title=""
                            name="siteIdx"
                            items={searchSiteList.map((site) => {
                                return { label: site.title, value: site.idx };
                            })}
                            value={values?.siteIdx}
                            onChange={settingValue}
                            nullTitle="사이트 선택"
                        />
                    </div>
                )}
                {eventYn === "Y" && (
                    <div className={`col-md-${dynamicSize}`} style={{ marginBottom: "15px" }}>
                        <BasicSelectForm
                            title=""
                            name="eventIdx"
                            items={searchEventList.map((event) => {
                                return { label: event.eventName, value: event.idx };
                            })}
                            value={values?.eventIdx}
                            onChange={settingValue}
                            nullTitle="이벤트 선택"
                        />
                    </div>
                )}
            </div>
            <div className="row">
                {channelYn === "Y" && (
                    <div className={`col-md-${dynamicSize}`} style={{ marginBottom: "15px" }}>
                        <BasicSelectForm
                            title=""
                            name="channelIdx"
                            items={searchChannelList.map((event) => {
                                return { label: event.title + " (" + moment(event.progressDate).format("YYYY-MM-DD") + ")", value: event.idx };
                            })}
                            value={values?.channelIdx}
                            onChange={settingValue}
                            nullTitle="채널 선택"
                        />
                    </div>
                )}
                {sessionYn === "Y" && (
                    <div className={`col-md-${dynamicSize}`} style={{ marginBottom: "15px" }}>
                        <BasicSelectForm
                            title=""
                            name="sessionIdx"
                            items={searchSessionList.map((event) => {
                                return { label: event.title + " (" + event.startTime + " ~ " + event.endTime + ")", value: event.idx };
                            })}
                            value={values?.sessionIdx}
                            onChange={settingValue}
                            nullTitle="세션 선택"
                        />
                    </div>
                )}
            </div>
            {keywordYn != "N" && (
                <div className="row">
                    <div className="col-md-9">
                        {/* <div className="form-group row">
                            <label className="col-sm-3 control-label m-t-sm">{"검색"}</label>
                            <div className="col-sm-9 btn-group"> */}
                        <SearchInput name="keyword" value={values?.keyword} onChange={settingValue} onClick={onSearch} placeholder="검색어를 입력해 주세요." />
                        {/* </div>
                        </div> */}
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export const CheckMultiForm = (props) => {
    const { title, hr, values, onChange, name, description, style, placeholder, step, readOnly, items: itemProps } = props;
    const items = itemProps;
    for (let i in items) {
        const item = items[i];
        for (let j in values) {
            const value = values[j];
            if (item.value == value) {
                item.checked = true;
            }
        }
        if (!item.checked) item.checked = false;
    }

    const SubTitleLabel = styled.div`
        position: relative;
        display: block;
        width: 100%;
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        cursor: pointer;
        color: #333;
        box-sizing: border-box;
    `;
    const CheckBoxLabel = styled.label`
        width: 25px;
        height: 25px;
        background-image: ${(props) => (props.checked == true ? `url(${CheckOnImg})` : `url(${CheckOffImg})`)};
        background-image: ${(props) => (props.checked == true ? `-webkit-image-set(${getBgSet(CheckOnImgSet)})` : `-webkit-image-set(${getBgSet(CheckOffImgSet)})`)};
        cursor: pointer;
        float: left;
        margin-right: 10px;
    `;

    const SubTitle = styled.div`
        font-size: 13px;
        /* color: ${(props) => props.theme.basic.color.primary}; */
        /* width: 135px; */
        /* width: 90%; */
        line-height: 25px;
        font-weight: 500;
        float: left;
        padding-right: 10px;
    `;

    const CheckOffImg = Check1x;
    const CheckOffImgSet = [Check1x, Check2x, Check3x];
    const CheckOnImg = CheckOn1x;
    const CheckOnImgSet = [CheckOn1x, CheckOn2x, CheckOn3x];

    return (
        <Fragment>
            <div className="form-group row">
                {title && <label className="col-sm-3 control-label m-t-sm">{title}</label>}
                <div className={cx({ "col-sm-9": title, "col-sm-12": !title })}>
                    {items &&
                        items.map((item, i) => (
                            <SubTitleLabel key={i} onClick={(e) => onChange({ ...item, checked: !item.checked })}>
                                <CheckBoxLabel
                                    // onClick={(e) => {
                                    //     console.log("ckckck", e);
                                    // }}
                                    checked={item.checked || false}
                                />
                                <SubTitle>{item.label}</SubTitle>
                            </SubTitleLabel>
                        ))}
                </div>
            </div>
            {description && (
                <div className="form-group row">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-9">{description}</div>
                </div>
            )}
            {hr && <div className="hr-line-dashed"></div>}
        </Fragment>
    );
};
