import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import swal from "sweetalert";

import { AppContext } from "../../../components/AppProvider";

import { InputForm, InputViewForm, SelectForm, H2, HRDash, ButtonSubmit, ButtonDefault } from "./UI/Components";

import { _API, _U } from "../../../modules/utils";

import { _DEF } from "../../../modules/config";

export default (props) => {
    const { code, history } = props;
    const { cfg } = useContext(AppContext);

    const [item, setItem] = useState({});

    const onSetItem = (e) => {
        const target = e.target;
        setItem((o) => {
            return { ...o, [target.name]: target.value };
        });
    };

    const onBack = () => {
        history?.push("/registration");
    };

    const onCancel = () => {
        props.onCancel && props.onCancel();
    };

    const onComplete = () => {
        props.onSetMode && props.onSetMode("REGISTRATION_DETAIL");
    };

    useEffect(() => {
        _U.postMessage("LOCATION-CHANGED");
    }, []);

    return (
        <Wrapper>
            <H2 style={{ textAlign: "center" }}>사전등록 조회</H2>
            {/* <Content code="registration" /> */}
            <Form {...props} onComplete={onComplete} onCancel={onCancel} item={item} />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const Form = (props) => {
    const { cfg } = useContext(AppContext);
    const [item, setItem] = useState({});
    const [regTypeName, setRegTypeName] = useState("");
    const { onCancel, onComplete } = props;
    const { emailYn, phoneYn, nameYn, companyYn, licenseYn, regTypeYn } = cfg.event;
    const { companyEsYn, emailEsYn, licenseEsYn, nameEsYn, phoneEsYn, regTypeEsYn } = cfg.event;
    const { loginType } = cfg.event;
    const { passwdYn } = cfg.event;

    useEffect(() => {
        if (props.item) {
            setItem(props.item);
            setRegTypeName(props.item.regTypeName);
        }
    }, [props.item]);

    const onSetItem = (e) => {
        const target = e.target;
        setItem((o) => {
            return { ...o, [target.name]: target.value };
        });
    };

    const onCheck = () => {
        // if (passwdYn !== "Y" && nameYn === "Y" && nameEsYn === "Y" && !item.name) {
        //     swal("성명을 입력하세요.");
        //     return false;
        // }
        if (loginType === "EMAIL" && !item.email) {
            swal("이메일을 입력하세요.");
            return false;
        }
        if (loginType === "PHONE" && !item.phone) {
            swal("휴대폰을 입력하세요.");
            return false;
        }
        if (loginType === "NAME" && !item.name) {
            swal("이름을 입력하세요.");
            return false;
        }
        if (passwdYn === "Y" && !item.passwd) {
            swal("비밀번호를 입력하세요.");
            return false;
        }

        const data = { ...item, siteIdx: cfg.idx, eventIdx: cfg.event?.eventIdx };

        _API.get({
            path: "/v1/site/event/registration/check",
            data: data,
        }).then((res) => {
            if (res.error === 0) {
                // window.sessionStorage.setItem("_REGISTRATION_CHECK", res.data);
                _U.setSession("_REGISTRATION_CHECK", res.data);
                // history?.push("/registration/view");
                onComplete && onComplete();
            } else {
                swal(res.message);
            }
        });
    };




    useEffect(() => {
        if (item.regType === "ETC") {
            setItem({ ...item, regTypeName: "regTypeName" });
        }
    }, [item.regType]);

    return (
        <Fragment>
            {/* {passwdYn !== "Y" && nameYn === "Y" && <InputForm title="성명" name="name" value={item.name || ""} onChange={onSetItem} placeholder="사전등록시 입력한 성명을 입력하세요." />} */}
            {emailYn === "Y" && loginType === "EMAIL" && <InputForm title="이메일" name="email" value={item.email || ""} onChange={onSetItem} placeholder="사전등록시 입력한 이메일을 입력하세요." />}
            {phoneYn === "Y" && loginType === "PHONE" && <InputForm title="휴대전화번호" name="phone" value={item.phone || ""} onChange={onSetItem} placeholder="사전등록시 입력한 휴대전화번호를 입력하세요." />}
            {nameYn === "Y" && loginType === "NAME" && <InputForm title="이름" name="name" value={item.name || ""} onChange={onSetItem} placeholder="사전등록시 입력한 이름을 입력하세요." />}
            {passwdYn === "Y" && <InputForm title="비밀번호" type="password" name="passwd" value={item.passwd || ""} onChange={onSetItem} />}

            <HRDash />
            <FormFooter>
                <ButtonDefault onClick={onCancel}>취소</ButtonDefault>
                <ButtonSubmit onClick={onCheck}>확인</ButtonSubmit>
            </FormFooter>
        </Fragment>
    );
};

const FormFooter = styled.div`
    display: flex;
    justify-content: center;

    & button {
        flex-basis: 28%;

        :last-child {
            margin-left: 2%;
        }

        @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
            flex-basis: 48%;
        }
    }
`;
