import React, { useContext, useEffect, useState, Fragment } from "react";
import styled from "styled-components";
import cx from "classnames";

import { ICClose, ICCloseSet, getBgSet, CheckOffImg, CheckOnImg, CheckOffImgSet, CheckOnImgSet } from "../asset";

const Input = styled.input.attrs((props) => {
    return {
        type: props.type || "text",
        step: props.step || "",
    };
})`
    width: 100%;
    padding: 9px 0 9px 1rem;
    border-radius: 5px;
    border: solid 1px ${(props) => props.theme.basic.line.input.default};
    background-color: #ffffff;
    padding-left: 1rem;
    height: unset;

    :focus {
        border: solid 1px ${(props) => props.theme.basic.line.input.active};
    }
`;

const Description = styled.div`
    font-size: 0.813rem;
    margin-top: 5px;
    color: ${(props) => props.theme.basic.color.description};
`;

export const InputBox = (props) => {
    const { title, hr, type, value, onChange, name, description, style, placeholder, step } = props;
    return (
        <>
            <Input type={type || "text"} value={value || ""} placeholder={placeholder} onChange={onChange} name={name} step={step} style={style} />
            {description && <Description>{description}</Description>}
        </>
    );
};

export const InputForm = (props) => {
    const { title, hr, type, value, onChange, name, description, style, placeholder, step, readOnly } = props;
    return (
        <Fragment>
            <div className="form-group row">
                {title && <label className="col-sm-3 control-label m-t-sm">{title}</label>}
                <div className={cx({ "col-sm-9": title, "col-sm-12": !title })}>
                    <Input type={type || "text"} value={value || ""} placeholder={placeholder} onChange={onChange} name={name} className="form-control" step={step} style={style} readOnly={readOnly && true} />
                </div>
            </div>
            {description && (
                <div className="form-group row">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-9">{description}</div>
                </div>
            )}
            {hr && <div className="hr-line-dashed"></div>}
        </Fragment>
    );
};

export const BasicInputForm = (props) => {
    const { title, hr, type, value, onChange, name, description, style, placeholder, step } = props;
    return (
        <Fragment>
            <div className="form-group row">
                {title && <label className="col-sm-12 control-label m-t-sm">{title}</label>}
                <div className={"col-sm-12"}>
                    <Input type={type || "text"} value={value || ""} placeholder={placeholder} onChange={onChange} name={name} className="form-control" step={step} style={style} />
                </div>
            </div>
            {description && (
                <div className="form-group row">
                    <div className="col-sm-12">{description}</div>
                </div>
            )}
            {hr && <div className="hr-line-dashed"></div>}
        </Fragment>
    );
};

export const InputViewForm = (props) => {
    const { title, hr, value, description } = props;
    return (
        <Fragment>
            <FormControl>
                {title && <Label>{title}</Label>}
                <FormCol>{value}</FormCol>
            </FormControl>
            {description && (
                <FormControl>
                    <FormCol>{description}</FormCol>
                </FormControl>
            )}
            {hr && <HRDash />}
        </Fragment>
    );
};

const FormCol = styled.div`
    font-size: 0.9rem;
    padding: 0;
    margin: 0;
    font-weight: 400;
`;

const Label = styled.label`
    font-size: 1rem;
    padding: 0;
    margin: 0;
    font-weight: 500;
`;

export const FormControl = styled.div`
    width: 100%;
    padding: 9px 0 9px 1rem;
    /* border-radius: 5px;
    border: solid 1px ${(props) => props.theme.basic.line.input.default}; */
    background-color: #ffffff;
    padding-left: 1rem;
    height: unset;
`;

export const ButtonSubmit = styled.button.attrs({
    type: "button",
})`
    background-color: ${(props) => props.theme.basic.bg.buttonSubmit};
    color: ${(props) => props.theme.basic.color.buttonSubmit};
    text-align: center;
    border-radius: 22px;
    width: 100%;
    border: none;
    padding: 10px 0;
    ${(props) =>
        props.margin
            ? `
        margin:${props.margin};
    `
            : ``}
`;

export const ButtonDefault = styled.button.attrs({
    type: "button",
})`
    background-color: ${(props) => props.theme.basic.bg.buttonDefault};
    color: ${(props) => props.theme.basic.color.buttonDefault};
    border: 1px solid ${(props) => props.theme.basic.line.buttonDefault};
    text-align: center;
    border-radius: 22px;
    width: 100%;
    padding: 10px 0;
`;

export const CloseButton = styled.div`
    width: 23px;
    height: 24px;
    background-image: ${`url(${ICClose})`};
    background-image: -webkit-image-set(${getBgSet(ICCloseSet)});
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    top: -35px;
    right: 0px;
    cursor: pointer;
`;

export const ModalVideoTitle = styled.label`
    position: absolute;
    top: -43px;
    left: 0px;
    color: ${(props) => props.theme.basic.color.white};
    font-size: 24px;
`;

export const ConnectionButton = styled.button.attrs({
    type: "button",
})`
    height: 42px;
    background-color: ${(props) => props.theme.basic.color.buttonConnection};
    color: ${(props) => props.theme.basic.color.buttonSubmit};
    text-align: center;
    border: none;
    font-family: ${(props) => props.theme.fontFamily.regular};
    border-radius: 21px;
    font-size :  ${(props) => props.theme.basic.fontSize}px ;
    ${(props) =>
        props.margin
            ? `
        margin:${props.margin};
    `
            : ``};
    ${(props) =>
        props.width
            ? `
        width:${props.margin};
    `
            : `width:100%`};
`;

export const ProgramButton = styled.button.attrs({
    type: "button",
})`
    height: 42px;
    background-color: ${(props) => props.theme.basic.color.white};
    color: ${(props) => props.theme.basic.color.buttonConnection};
    text-align: center;
    border: solid 1px ${(props) => props.theme.basic.color.buttonConnection};
    font-family: ${(props) => props.theme.fontFamily.regular};
    border-radius: 21px;
    font-size :  ${(props) => props.theme.basic.fontSize}px;
    ${(props) =>
        props.margin
            ? `
        margin:${props.margin};
    `
            : ``};
    ${(props) =>
        props.width
            ? `
        width:${props.margin};
    `
            : `width:100%`};
`;

export const ButtonSubmitSmall = styled.button.attrs({
    type: "button",
})`
    background-color: ${(props) => props.theme.basic.bg.buttonSubmit};
    color: ${(props) => props.theme.basic.color.buttonSubmit};
    text-align: center;
    border-radius: 3px;
    border: none;
    padding: 4px 15px;
    font-family: ${(props) => props.theme.fontFamily.regular};
    font-size:11px;
    ${(props) =>
        props.margin
            ? `
        margin:${props.margin};
    `
            : ``};
`;

export const ButtonDefaultSmall = styled.button.attrs({
    type: "button",
})`
    background-color: ${(props) => props.theme.basic.bg.buttonDefault};
    color: ${(props) => props.theme.basic.color.buttonDefault};
    border: 1px solid ${(props) => props.theme.basic.line.buttonDefault};
    text-align: center;
    border-radius: 3px;
    padding: 8px 15px;
    ${(props) =>
        props.margin
            ? `
        margin:${props.margin};
    `
            : ``};
`;

export const ButtonExitSmall = styled.button.attrs({
    type: "button",
})`
    background-color: ${(props) => props.theme.basic.bg.buttonDefault};
    color: ${(props) => props.theme.basic.color.buttonDefault};
    border: 1px solid ${(props) => props.theme.basic.line.buttonDefault};
    text-align: center;
    border-radius: 3px;
    padding-right: 25px;

    width: 110px;
    height: 34px;

    ${(props) =>
        props.margin
            ? `
        margin:${props.margin};
    `
            : ``};
`;

export const SelectForm = (props) => {
    const { title, name, nullTitle, hr, items, value, onChange, readOnly } = props;
    return (
        <Fragment>
            <div className="form-group row">
                {title && <label className="col-sm-3 control-label m-t-sm">{title}</label>}
                <div className="col-sm-9 btn-group">
                    <select name={name} value={value || ""} onChange={onChange} className="form-control" readOnly={readOnly && true} style={{ padding: "9px 12px", height: "unset", borderRadius: "5px" }}>
                        <option value="" disabled={readOnly && true}>
                            {nullTitle}
                        </option>
                        {items.map((item, i) => (
                            <option key={i} value={item.value} disabled={readOnly && true}>
                                {item.label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            {hr && <div className="hr-line-dashed"></div>}
        </Fragment>
    );
};

export const BasicSelectForm = (props) => {
    const { title, name, nullTitle, hr, items, value, onChange, readOnly } = props;
    return (
        <Fragment>
            <div className="form-group row">
                {title && <label className="col-sm-12 control-label m-t-sm">{title}</label>}
                <div className="col-sm-12 btn-group">
                    <select name={name} value={value || ""} onChange={onChange} className="form-control" readOnly={readOnly && true} style={{ padding: "9px 12px", height: "unset", borderRadius: "5px" }}>
                        <option value="" disabled={readOnly && true}>
                            {nullTitle}
                        </option>
                        {items.map((item, i) => (
                            <option key={i} value={item.value} disabled={readOnly && true}>
                                {item.label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            {hr && <div className="hr-line-dashed"></div>}
        </Fragment>
    );
};

export const H2 = styled.h2`
    color: ${(props) => props.theme.basic.color.title};
    font-size: 20px;
    font-family: ${(props) => props.theme.fontFamily.medium};
    margin-top: 0px;
    margin-bottom: 2rem;
`;

export const H3 = styled.h2`
    color: ${(props) => props.theme.basic.color.title};
    font-size: 20px;
    font-family: ${(props) => props.theme.fontFamily.medium};
    margin-top: 0px;
    margin-bottom: 2rem;
`;

export const HRDash = styled.hr`
    border-top: 1px dashed rgba(0, 0, 0, 0.1);
`;

export const ItemEmpty = ({ length, number, component }) => {
    let remainer = length % number;
    const rtn = [];

    remainer = remainer > 0 ? number - remainer : remainer;

    if (remainer <= 0) {
        return "";
    }

    for (let i = 0; i < remainer; i++) {
        rtn.push(<Fragment key={i}>{component}</Fragment>);
    }

    return rtn;
};

export const CheckMultiForm = (props) => {
    const { title, hr, values, onChange, name, description, style, placeholder, step, readOnly, items: itemProps } = props;
    const items = itemProps;
    for (let i in items) {
        const item = items[i];
        for (let j in values) {
            const value = values[j];
            if (item.value == value) {
                item.checked = true;
            }
        }
        if (!item.checked) item.checked = false;
    }

    const SubTitleLabel = styled.div`
        position: relative;
        display: block;
        width: 100%;
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        cursor: pointer;
        color: #333;
        box-sizing: border-box;
    `;
    const CheckBoxLabel = styled.label`
        width: 25px;
        height: 25px; 
        background-image: 
            ${props => (props.checked == true ? `url(${CheckOnImg})` : `url(${CheckOffImg})`)};
        background-image:
            ${props => (props.checked == true ? `-webkit-image-set(${getBgSet(CheckOnImgSet)})` : `-webkit-image-set(${getBgSet(CheckOffImgSet)})`)};
        cursor: pointer;
        float: left;
        margin-right: 10px;
        
    `;

    const SubTitle = styled.div`
        font-size: 13px;
        color: ${(props) => props.theme.basic.color.primary};
        /* width: 135px; */
        /* width: 90%; */
        line-height: 25px;
        font-weight: 500;
        float: left;
        padding-right: 10px;
    `;
    return (
        <Fragment>
            <div className="form-group row">
                {title && <label className="col-sm-3 control-label m-t-sm">{title}</label>}
                <div className={cx({ "col-sm-9": title, "col-sm-12": !title })}>
                    {items && items.map((item, i) => (
                        <SubTitleLabel key={i} onClick={(e) => onChange({ ...item, checked: !item.checked })}>
                            <CheckBoxLabel
                                // onClick={(e) => {
                                //     console.log("ckckck", e);
                                // }}
                                checked={item.checked || false} />
                            <SubTitle>
                                {item.label}
                            </SubTitle>
                        </SubTitleLabel>
                    ))}
                </div>
            </div>
            {description && (
                <div className="form-group row">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-9">{description}</div>
                </div>
            )}
            {hr && <div className="hr-line-dashed"></div>}
        </Fragment>
    );
};


