import React, { Fragment, useContext, useEffect, useState } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";
import styled, { css } from "styled-components";
import Modal from "react-modal";
import moment from "moment";
import cx from "classnames";
import { isMobile } from "react-device-detect";

import { AppContext } from "../../../components/AppProvider";

import { _API, _U, _CK } from "../../../modules/utils";
import { _CFG } from "../../../modules/config";

import Socket from "../../../components/UI/Socket";
import { Theme } from "../../../components/GlobalStyles";

import * as _ from "lodash";

import MainView from "./main";
import LoginView from "./login";
import MypageView from "./mypage";
import SatelliteView from "./satelliteView";
import EboothView from "./ebooth.in";
import ChannelView from "./channel.New";
import ChannelReplayView from "./channel.Replay";
import Contents from "./Components.Content";
import PreregView from "./Components.Registration.page";
import BoothPopup from "./BoothPopup";
import EposterPdfView from "./Eposter.Pdf";
import EposterView from "./eposter";
import LectureView from "./lecture.in";
import LectureReplayView from "./lecture.replay";
import ModalVideo from "../../../components/UI/ModalVideo";
import IntroView from "./intro";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { ButtonSubmitSmall, CloseButton, ModalVideoTitle } from "./UI/Components";
import { ICCalendar, ICCalendarSet, ICLogout, ICLogoutSet, getBgSet } from "./asset";
import { Checkboard } from "react-color";

import { PdfViewerPrimium, PdfViewerBasic } from "./Eposter.Pdf";

export default (props) => {
    // const { match } = props;
    const { cfg, auth, iam } = useContext(AppContext);
    const event = cfg.event || null;
    const [content, setContent] = useState("");
    const [contentType, setContentType] = useState("");
    const [contentIdx, setContentIdx] = useState("");

    const [videoUrl, setVideoUrl] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [videoData, setVideoData] = useState();

    const [data, setData] = useState({});
    const [imageIndex, setImageIndex] = useState(0);
    const [vodEncodedUrl, setVodEncodedUrl] = useState("");

    const exceptModalArray = ["IMAGE", "LINK", "PDF", "VIDEO"];

    const [isPremium, setIsPremium] = useState((event.pdfEbookYn || 'N') === 'Y' ? true : false);
    const [isHeaderShowCnt, setIsHeaderShowCnt] = useState(0);
    const [loginAlertClose, setLoginAlertClose] = useState(_CK.get("loginAlertClose") || false);
    const [modalData, setModalData] = useState(null);
    const [introVodView, setIntroVodView] = useState(true);

    const onReceivedMessage = (e) => {
        _U.parseMessage(e).then(async (data) => {
            if (!data || !data.data) return;
            const { url, contentType: contentTypeProp, itemType, cid } = data.data;
            // console.log("GET MESSAGE", data.code, data.data);
            switch (data.code) {
                case "SELECT-MENU":
                    if ("ATTENDANCE" === url && contentTypeProp === "link") {
                        window.location.href = "/#/mypage";
                        setIsHeaderShowCnt(isHeaderShowCnt + 1);
                        break;
                    }
                    if ("EPOSTER" === url && contentTypeProp === "link") {
                        window.location.href = "/#/eposter";
                        setIsHeaderShowCnt(isHeaderShowCnt + 1);
                        break;
                    }
                    if ("EBOOTH" === url && contentTypeProp === "link") {
                        window.location.href = "/#/ebooth";
                        setIsHeaderShowCnt(isHeaderShowCnt + 1);
                        break;
                    }
                    if ("LECTURE" === url && contentTypeProp === "link") {
                        window.location.href = "/#/lecture";
                        setIsHeaderShowCnt(isHeaderShowCnt + 1);
                        break;
                    }

                    const contentData = await loadContent(cid, exceptModalArray.includes(itemType) ? itemType : contentType);
                    let res = contentData?.data?.data;
                    // console.log(cid, exceptModalArray.includes(itemType) ? itemType : contentType);
                    // console.log("res", res)
                    setModalData({
                        contentType: cid ? (res?.contentType || (exceptModalArray.includes(itemType) ? itemType : contentType)) : "",
                        contentIdx: cid,
                        content: url,
                    });

                    if (res?.contentType === "IMAGE") {
                        setData(res);
                        break;
                    } else if (res?.contentType === "PDF") {
                        _CK.set("pdfContent", res);
                        window.open("/#/pdf");
                        break;
                    } else if (res?.contentType === "LINK") {
                        window.open(res.content);
                        break;
                    }

                    break;
                case "AGENDA-LECTURE":
                    setModalData({
                        contentIdx: data?.data.cid, // channelIdx;
                        content: data.code,
                    });
                    break;
                case "EBOOTH-BASIC":
                    setModalData({
                        contentIdx: data?.data.cid, // companyIdx;
                        content: data.code,
                    });
                    break;
                case "SATELLITE-AGENDA":
                    setModalData({
                        contentIdx: data?.data.satelliteIdx,
                        content: data.code,
                    });
                    break;
                case "ONLY-VIDEO":
                    console.log("onn", data?.data)
                    setModalData({
                        videoUrl: data?.data.videoSignedUrl,
                        vodEncodedUrl: data?.data.vodEncodedUrl,
                        contentType: data?.data.contentType,
                        title: data?.data.title || "",
                        description: data?.data.description || "",
                        content: data.code,
                        videoData: data?.data,
                    });
                    break;
                case "CLOSE-MODAL":
                    setModalData({
                        contentIdx: "",
                        content: "",
                        contentType: "",
                    });
                    break;
                case "ENTER-VR-MAIN":
                    _CK.set("clickedEnter", true);
                    break;
            }
        });
    };

    const loadContent = async (cid, conType) => {
        const { siteIdx, eventIdx } = cfg.event;
        const param = { siteIdx, eventIdx, contentIdx: cid, contentType: conType };
        if (!cid) {
            return null;
        }
        const result = await _API.getContent({ data: param });
        return result;
    };

    const setModalStyle = (isMobileProp, contentTypeProp) => {
        console.log(modalData?.content, isMobileProp, contentTypeProp);
        const cType = contentTypeProp === "VOD_FILE" || contentTypeProp === "LINK" ? "VIDEO" : contentTypeProp;
        let modalMobileStyle = _.cloneDeep(Theme.basic.modal.contents.mobile);
        let modalPcStyle = _.cloneDeep(Theme.basic.modal.contents.pc);

        if (modalData?.content === "EBOOTH-BASIC") {
            modalMobileStyle.content.bottom = "auto";
            modalPcStyle.content.bottom = "auto";
        } else if (modalData?.content === "ONLY-VIDEO") {
            // modalPcStyle.content["maxHeight"] = "70%";
            if (window.innerHeight < 900) {
                modalPcStyle.content["top"] = "3rem";
                modalPcStyle.content["maxHeight"] = "653px";
                modalPcStyle.content["minHeight"] = "653px";
            } else {
                modalPcStyle.content["maxHeight"] = "745px";
                modalPcStyle.content["minHeight"] = "745px";
            }

        } else if (contentTypeProp === "VOD_FILE" || contentTypeProp === "REPLAY") {
            if (window.innerHeight < 760) {
                modalPcStyle.content["top"] = "3rem";
            }
            modalPcStyle.content["maxHeight"] = "675px";
            modalPcStyle.content["minHeight"] = "675px";

        }
        let newStyle = "";
        if (isMobileProp) {
            if (modalData?.content === "content" && contentTypeProp === "VOD_FILE") {
                newStyle = modalMobileStyle;
            } else {
                newStyle = _.merge(modalMobileStyle, cType === "VIDEO" ? { content: { borderRadius: "0", padding: "0", paddingBottom: "0" } } : {})
            }
        } else {
            newStyle = _.merge(modalPcStyle, cType === "VIDEO" ? { content: { borderRadius: "0", padding: "0", paddingBottom: "0" } } : {});
        }
        return newStyle;
    };

    useEffect(() => {
        window.addEventListener("message", onReceivedMessage);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, []);

    if (!_CK.isLogin() && cfg.event?.loginYn === "Y") {

        // 사전등록 페이지
        if ((window.location.href || window.location.hash || "").indexOf("/prereg") > 0) {
            return <PreregView />;
        }

        return <LoginView />;
    }
    if(introVodView && event?.introVodYn == "Y" && ( event?.introVodEncodedUrl || event?.introVodSignedUrl ) && ((window.location.hash || "") == "" || (window.location.hash || "") == "#/") ) {
        return <IntroView url={event?.introVodEncodedUrl || event?.introVodSignedUrl} setIntroVodView={setIntroVodView} />;
    }

    const onLoginAlertClose = () => {
        _CK.set("loginAlertClose", true);
        setLoginAlertClose(true);
    }
    // useEffect(() => {
    //     console.log("modalData change", modalData);
    //     console.log(modalData?.content && !exceptModalArray.includes(modalData?.contentType));
    // }, [modalData]);

    return (
        <Router>
            <Switch>
                <Route path="/ebooth/popup/:companyIdx" exact component={BoothPopup} />
                <Route path="/eposter/pdf" exact component={EposterPdfView} />
                <Route path="/channel/:idx" exact component={ChannelView} />
                <Route path="/channel/replay/:idx" exact component={ChannelReplayView} />
                <Route path="/lecture/replay/:channelIdx/:sessionIdx/:lectureIdx" exact component={LectureReplayView} />
                <Route path="/pdf" exact component={EposterPdfView} />

                <Wrapper >
                    <Header onModalMenuClick={(menu) => setModalData({ content: menu })} isHeaderShowCnt={isHeaderShowCnt} setIsHeaderShowCnt={setIsHeaderShowCnt} />
                    {/* <Container> */}
                    <Route path="/" exact component={MainView} />
                    <Route path="/mypage" exact component={MypageView} />
                    <Route path="/ebooth" exact component={EboothView} />
                    <Route path="/satellite" exact component={SatelliteView} />
                    <Route path="/eposter" exact component={EposterView} />
                    <Route path="/lecture" exact component={LectureView} />
                    {/* </Container> */}
                    {/* <Footer /> */}
                </Wrapper>
            </Switch>
            {(auth || cfg.event?.loginYn !== "Y") && <Socket />}
            {modalData?.content && modalData?.contentType === "IMAGE" && data?.content?.length > 0 && (
                <Lightbox
                    reactModalStyle={{ overlay: { zIndex: 10000 } }}
                    imageTitle={data.title}
                    mainSrc={data.content[imageIndex]?.signedUrl}
                    nextSrc={data.content[[imageIndex + 1] % data.content.length]?.signedUrl}
                    prevSrc={data.content[(imageIndex + data.content.length - 1) % data.content.length]?.signedUrl}
                    onCloseRequest={() => {
                        setImageIndex(0);
                        setModalData({ content: "", contentType: "", contentIdx: "" });
                        setData(null);
                    }}
                    onMovePrevRequest={() => setImageIndex((imageIndex + data.content.length - 1) % data.content.length)}
                    onMoveNextRequest={() => setImageIndex((imageIndex + 1) % data.content.length)}
                />
            )}
            {modalData?.content && !exceptModalArray.includes(modalData?.contentType) && (
                <>
                    {((modalData?.contentType === "VOD_FILE" || modalData?.contentType === "REPLAY") && isMobile) ? (
                        <Modal isOpen={((modalData?.contentType === "VOD_FILE" || modalData?.contentType === "REPLAY") && isMobile) ? true : false} ariaHideApp={false} style={Theme.basic.modal.contents.mobileVideo}>
                            <CloseButton
                                onClick={() => {
                                    setModalData({ content: "", contentType: "", contentIdx: "" });
                                    setData(null);
                                }}
                                contentType={"VOD_FILE"}
                            />
                            <ModalVideo code={modalData?.content} contentType={modalData?.contentType} contentIdx={modalData?.contentIdx} videoUrl={modalData?.videoUrl} title={modalData?.title} description={modalData?.description} vodEncodedUrl={modalData?.vodEncodedUrl} videoData={modalData?.videoData} onCancel={() => setModalData({ content: "", contentType: "", contentIdx: "" })} />
                        </Modal>
                    ) : (
                        <Modal isOpen={modalData?.content && !exceptModalArray.includes(modalData?.contentType) ? true : false} ariaHideApp={false} style={setModalStyle(isMobile, modalData?.contentType)}>
                            <CloseButton
                                onClick={() => {
                                    setModalData({ content: "", contentType: "", contentIdx: "" });
                                    setData(null);
                                }}
                            />
                            <Contents code={modalData?.content} contentType={modalData?.contentType} contentIdx={modalData?.contentIdx} videoUrl={modalData?.videoUrl} title={modalData?.title} description={modalData?.description} vodEncodedUrl={modalData?.vodEncodedUrl} videoData={modalData?.videoData} onCancel={() => setModalData({ content: "", contentType: "", contentIdx: "" })} />
                        </Modal>
                    )}
                </>
            )}
            {!loginAlertClose && event?.loginAlert && (
                <Modal isOpen={!loginAlertClose && event?.loginAlert ? true : false} ariaHideApp={false} style={setModalStyle(isMobile, "")}>
                    <CloseButton
                        onClick={() => {
                            onLoginAlertClose();
                            setModalData({ content: "", contentType: "", contentIdx: "" });
                            setData(null);
                        }}
                    />
                    <Contents code={"LOGIN-ALERT"} title={"공지"} content={event?.loginAlert} />
                </Modal>
            )}
        </Router>
    );
};

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
`;

const Container = styled.div`
    // min-height: calc(100% - 192px);
    // height: auto;
`;

const Header = (props) => {
    const { onModalMenuClick } = props;
    const { cfg, saveAuth, saveIam, iam } = useContext(AppContext);
    const event = cfg.event || null;
    const [subMenu, setSubMenu] = useState("");
    const [menu, setMenu] = useState({});
    const [activeMenu, setActiveMenu] = useState("");
    const [addDesc, setAddDesc] = useState(_U.jsonParse(event.addDesc));
    const [isHide, setIsHide] = useState(false);

    useEffect(() => {
        loadMenuList();
        const hash = window.location.hash?.split("/")[1]?.toUpperCase();
        if (hash) {
            switch (hash) {
                case "INTRODUCE":
                case "AGENDA":
                case "NOTICE":
                case "FAQ":
                    break;
                case "EBOOTH":
                    setActiveMenu("E-BOOTH");
                    break;
                case "ATTEND":
                    setActiveMenu("MYPAGE");
                    break;
                default:
                    setActiveMenu(hash);
                    break;
            }
        }
    }, []);

    const onLogout = () => {
        _CK.clearAuth();
        saveAuth(null);
        saveIam(null);
    };

    const onMenuClick = (menu) => {
        switch (menu) {
            case "MYPAGE":
            case "ATTEND":
            case "E-BOOTH":
            case "LECTURE":
            case "SATELLITE":
            case "EPOSTER":
                setSubMenu("");
                break;
            default:
                setSubMenu("");
                onModalMenuClick(menu);
                break;
        }
        // setActiveMenu(menu);
        const newMenu = menu.startsWith("ADDDESC") ? "INTRODUCE" : menu;
        switch (newMenu) {
            case "INTRODUCE":
            case "AGENDA":
            case "NOTICE":
            case "FAQ":
                // setActiveMenu("INFORMATION");
                break;
            case "EBOOTH":
                break;
            case "ATTEND":
                setActiveMenu("MYPAGE");
                break;
            default:
                setActiveMenu(menu);
                break;
        }

        if (!menu) {
            setSubMenu("");
            setActiveMenu("");
        }
    };

    const loadMenuList = () => {
        if (!event.eventIdx) {
            return;
        }

        const param = { eventIdx: event.eventIdx };

        const req = { path: `/v1/event/menu`, data: param };
        _API.get(req)
            .then((res) => res.data)
            .then((data) => {
                setMenu(
                    data.reduce((acc, cur) => {
                        return { ...acc, ...{ [cur.code.toLowerCase()]: cur } };
                    }, {})
                );
            });
    };

    useEffect(() => {
        console.log("event?.headerShowYn", event);
        if (event?.headerShowYn == "N") {
            setIsHide(true);
        }
        else if (location.hash === "#/" && event?.main3DPath && event?.main3DPcYn === "Y" && !isMobile) {
            setIsHide(true);
        }
        else {
            setIsHide(false);
        }
    }, [location.hash, props.isHeaderShowCnt]);

    return (
        <>
            <HeaderWrapper isHide={isHide}>
                <LogoWrapper>
                    <LogoContainer>
                        {event?.logoTopPcUrl && (
                            <Logo to={"/"} onClick={() => {
                                onMenuClick("");
                                props.setIsHeaderShowCnt && props.setIsHeaderShowCnt(0);
                            }}>
                                <img src={event?.logoTopPcUrl} />
                            </Logo>
                        )}
                    </LogoContainer>
                </LogoWrapper>
                <MenuWrapper>
                    <MenuItem onClick={() => setSubMenu(subMenu === "INFORMATION" ? "" : "INFORMATION")}
                        width={"180"} activeMenu={activeMenu === "INFORMATION" ? activeMenu : ""}>
                        {menu.information && menu.information.name ? menu.information.name : "INFORMATION"}
                        {!isMobile && subMenu === "INFORMATION" && (
                            <SubMenuWrapper>
                                <ul>
                                    {addDesc && addDesc.length > 0 && addDesc.map((item, i) => (
                                        <li key={i} onClick={onMenuClick.bind(this, `ADDDESC_${i}`)}>{item.label}</li>
                                    ))}
                                    {(!addDesc || addDesc.length == 0) && (
                                        <li onClick={onMenuClick.bind(this, "INTRODUCE")}>인사말</li>
                                    )}
                                    <li onClick={onMenuClick.bind(this, "AGENDA")}>{menu.agenda && menu.agenda.name ? menu.agenda.name : "아젠다"}</li>
                                    <li onClick={onMenuClick.bind(this, "NOTICE")}>공지사항</li>
                                    {/* <li onClick={onMenuClick.bind(this, "FAQ")}>FAQ</li> */}
                                    {/* <li onClick={onMenuClick.bind(this, "PROGRAM")}>프로그램소개</li> */}
                                    {/* <li onClick={onMenuClick.bind(this, "LOCATION")}>행사장소</li> */}
                                </ul>
                            </SubMenuWrapper>
                        )}
                    </MenuItem>
                    <MenuItemLink to={"/lecture"} onClick={onMenuClick.bind(this, "LECTURE")}
                        width={"140"} activeMenu={activeMenu === "LECTURE" ? activeMenu : ""}>
                        {menu.lecture && menu.lecture.name ? menu.lecture.name : "LECTURE"}
                    </MenuItemLink>
                    {event?.satelliteYn === "Y" && (
                        <MenuItemLink to={"/satellite"} onClick={onMenuClick.bind(this, "SATELLITE")}
                            width={"230"} activeMenu={activeMenu === "SATELLITE" ? activeMenu : ""}>
                            {menu.satellite && menu.satellite.name ? menu.satellite.name : "SATELLITE SESSION"}
                        </MenuItemLink>
                    )}
                    {event?.eposterYn === "Y" && (
                        <MenuItemLink to={"/eposter"} onClick={onMenuClick.bind(this, "EPOSTER")}
                            width={"150"} activeMenu={activeMenu === "EPOSTER" ? activeMenu : ""}>
                            {menu.eposter && menu.eposter.name ? menu.eposter.name : "E-POSTER"}
                        </MenuItemLink>
                    )}
                    {event?.eboothYn === "Y" && (
                        // <MenuItem onClick={onMenuClick.bind(this, "EBOOTH")}>{menu.ebooth && menu.ebooth.name ? menu.ebooth.name : "E-BOOTH"}</MenuItem>
                        <MenuItemLink to={"/ebooth"} onClick={onMenuClick.bind(this, "E-BOOTH")}
                            width={"145"} activeMenu={activeMenu === "E-BOOTH" ? activeMenu : ""}>
                            {menu.ebooth && menu.ebooth.name ? menu.ebooth.name : "E-BOOTH"}
                        </MenuItemLink>
                    )}
                    {event?.loginYn === "Y" && event?.attendanceYn === "Y" && (
                        // <MenuItemLink to={"/mypage"} onClick={onMenuClick.bind(this, "MYPAGE")}>
                        //     {menu.mypage && menu.mypage.name ? menu.mypage.name : "MY PAGE"}
                        // </MenuItemLink>
                        <MenuItem onClick={() => setSubMenu(subMenu === "MYPAGE" ? "" : "MYPAGE")}
                            width={"140"} activeMenu={activeMenu === "MYPAGE" ? activeMenu : ""}>
                            {menu.mypage && menu.mypage.name ? menu.mypage.name : "MY PAGE"}
                            {!isMobile && subMenu === "MYPAGE" && (
                                <SubMenuWrapper>
                                    <ul>
                                        <li>
                                            <Link to={"/mypage"} onClick={onMenuClick.bind(this, "ATTEND")}>
                                                참여시간
                                            </Link>
                                        </li>
                                        {event?.eboothYn === "Y" && (
                                            <li onClick={onMenuClick.bind(this, "EBOOTH")}>이부스이벤트</li>
                                        )}
                                    </ul>
                                </SubMenuWrapper>
                            )}
                        </MenuItem>
                    )}


                </MenuWrapper>
                <My>
                    {isMobile ? (
                        <MLogout onClick={onLogout} />
                    ) : (
                        cfg.event?.loginYn === "Y" && (
                            <>
                                {iam?.name ? `${iam?.name} 님` : ``}
                                <ButtonSubmitSmall margin={"0 0 0 15px"} onClick={onLogout}>
                                    Logout
                                </ButtonSubmitSmall>
                            </>
                        )
                    )}
                </My>
                {/* {isMobile && <MMypage to={"/mypage"} onClick={onMenuClick.bind(this, "MYPAGE")} />} */}
            </HeaderWrapper>
            {isMobile && subMenu === "INFORMATION" && (
                <SubMenuWrapper>
                    <ul>
                        {addDesc && addDesc.length > 0 && addDesc.map((item, i) => (
                            <li key={i} onClick={onMenuClick.bind(this, `ADDDESC_${i}`)}>{item.label}</li>
                        ))}
                        {(!addDesc || addDesc.length == 0) && (
                            <li onClick={onMenuClick.bind(this, "INTRODUCE")}>인사말</li>
                        )}
                        {/* <li onClick={onMenuClick.bind(this, "PROGRAM")}>프로그램소개</li> */}
                        <li onClick={onMenuClick.bind(this, "AGENDA")}>{menu.agenda && menu.agenda.name ? menu.agenda.name : "아젠다"}</li>
                        <li onClick={onMenuClick.bind(this, "NOTICE")}>공지사항</li>
                        {/* <li onClick={onMenuClick.bind(this, "LOCATION")}>행사장소</li> */}
                        {/* <li onClick={onMenuClick.bind(this, "FAQ")}>FAQ</li> */}
                        {/* <li>
                        <Link to={"/"} onClick={onMenuClick.bind(this, "TEST")}>
                            TEST
                        </Link>
                    </li> */}
                    </ul>
                </SubMenuWrapper>
            )}
            {isMobile && subMenu === "MYPAGE" && (
                <SubMenuWrapper>
                    <ul>
                        <li>
                            <Link to={"/mypage"} onClick={onMenuClick.bind(this, "ATTEND")}>
                                참여시간
                            </Link>
                        </li>
                        {event?.eboothYn === "Y" && (
                            <li onClick={onMenuClick.bind(this, "EBOOTH")}>이부스이벤트</li>
                        )}
                    </ul>
                </SubMenuWrapper>
            )}
        </>
    );
};

const HeaderWrapper = styled.div`
    width: 100%;
    height: 74px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${(props) => props.theme.basic.line.header};
    ${(props) => {
        if (props.isHide) {
            return `display:none;`;
        }
        else {
            if (isMobile) {
                return css`
                    @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                        display: block;
                        position: relative;
                        height: 95px;
                    }
                    @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                        display: block;
                        position: relative;
                        height: 95px;
                    }
                    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
                        display: block;
                        position: relative;
                        height: 95px;
                    }
                `;
            } else {
                return css`
                    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
                        display: block;
                        position: relative;
                        height: 95px;
                    }
                `;
            }
        }
    }}
    
`;

const LogoWrapper = styled.div`
    flex: 1;
    flex-grow: 1;

    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                width: 100%;
                text-align: center;
                padding: 10px 0 13px;
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                width: 100%;
                text-align: center;
                padding: 10px 0 13px;
            }
          `;
        }
    }}
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        width: 100%;
        text-align: center;
        padding: 10px 0 13px;
    }
`;

const LogoContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                max-height: 30px;
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                max-height: 30px;
            }
          `;
        }
    }}
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        max-height: 30px;
    }
`;

const Logo = styled(Link)`
    img {
        min-width: 144px;
        max-width: 144px;
        min-height: 30px;
        max-height: 90px;
    }
`;

const MenuWrapper = styled.div`
    flex: 1;
    flex-grow: 7;
    display: block;
    line-height: 74px;
    position: relative;
    margin-left: 35px;

    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                line-height: 38px;
                margin-left: 0px;
                padding: 0 10px;
                display: block;
                overflow-y: hidden;
                overflow-x: auto;
                white-space: nowrap;

                -ms-overflow-style: none;
                scrollbar-width: none;
                ::-webkit-scrollbar {
                    display: none;
                }
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                line-height: 38px;
                margin-left: 0px;
                padding: 0 10px;
                display: block;
                overflow-y: hidden;
                overflow-x: auto;
                white-space: nowrap;

                -ms-overflow-style: none;
                scrollbar-width: none;
                ::-webkit-scrollbar {
                    display: none;
                }
            }
          `;
        }
    }}

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        line-height: 38px;
        margin-left: 0px;
        padding: 0 10px;
        display: block;
        overflow-y: hidden;
        overflow-x: auto;
        white-space: nowrap;

        -ms-overflow-style: none;
        scrollbar-width: none;
        ::-webkit-scrollbar {
            display: none;
        }
    }
`;

const MenuItem = styled.div`
    display: inline-block;
    font-family: ${(props) => props.theme.fontFamily.regular};
    font-size: 16px;
    color: ${(props) => props.theme.basic.color.primary};
    /* margin-left: 70px; */
    padding: 0 35px;
    vertical-align: middle;
    cursor: pointer;
    position:relative;
    height:74px;
    width: ${props => props.width}px;

    ${props => {
        if (props.activeMenu && !isMobile) {
            return css`
            border-bottom: 4px solid ${(props) => props.theme.basic.bg.default} !important;
            font-family: ${(props) => props.theme.fontFamily.bold};
            color: ${(props) => props.theme.basic.bg.default};

            &:hover{
                border-bottom: 4px solid ${(props) => props.theme.basic.bg.default} !important;
                font-family: ${(props) => props.theme.fontFamily.bold};
                color: ${(props) => props.theme.basic.bg.default};
            }    
          `;
        } else if (!isMobile) {
            return css`
            &:hover{
                border-bottom: 4px solid ${(props) => props.theme.basic.bg.default} !important;
                font-family: ${(props) => props.theme.fontFamily.bold};
                color: ${(props) => props.theme.basic.bg.default};
            }    
            `;
        }
    }}

    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                font-size: 14px;
                padding: 0px;
                flex: 1;
                justify-content: center;
                text-align: center;
                margin: 0 1rem 0 0;
                height:38px;

                :last-child {
                    margin: 0 0 0 0;
                }
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                font-size: 14px;
                padding: 0px;
                flex: 1;
                justify-content: center;
                text-align: center;
                margin: 0 1rem 0 0;
                height:38px;

                :last-child {
                    margin: 0 0 0 0;
                }
            }
          `;
        }
    }}

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        font-size: 14px;
        padding: 0px;
        flex: 1;
        justify-content: center;
        text-align: center;
        margin: 0 1rem 0 0;
        height:38px;

        :last-child {
            margin: 0 0 0 0;
        }
    }
`;

const MenuItemLink = styled(Link)`
    display: inline-block;
    font-family: ${(props) => props.theme.fontFamily.regular};
    font-size: 16px;
    color: ${(props) => props.theme.basic.color.primary};
    /* margin-left: 70px; */
    padding: 0 35px;
    vertical-align: middle;
    cursor: pointer;
    height:74px;
    width: ${props => props.width}px;
    
    ${props => {
        if (props.activeMenu && !isMobile) {
            return css`
            border-bottom: 4px solid ${(props) => props.theme.basic.bg.default} !important;
            font-family: ${(props) => props.theme.fontFamily.bold};
            color: ${(props) => props.theme.basic.bg.default};

            &:hover{
                border-bottom: 4px solid ${(props) => props.theme.basic.bg.default} !important;
                font-family: ${(props) => props.theme.fontFamily.bold};
                color: ${(props) => props.theme.basic.bg.default};
            }    
          `;
        } else if (!isMobile) {
            return css`
            &:hover{
                border-bottom: 4px solid ${(props) => props.theme.basic.bg.default} !important;
                font-family: ${(props) => props.theme.fontFamily.bold};
                color: ${(props) => props.theme.basic.bg.default};
            }    
            `;
        }
    }}

    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                font-size: 14px;
                padding: 0px;
                flex: 1;
                justify-content: center;
                text-align: center;
                margin: 0 1rem 0 0;
                height:38px;

                :last-child {
                    margin: 0 0 0 0;
                }
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                font-size: 14px;
                padding: 0px;
                flex: 1;
                justify-content: center;
                text-align: center;
                margin: 0 1rem 0 0;
                height:38px;

                :last-child {
                    margin: 0 0 0 0;
                }
            }
          `;
        }
    }}

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        font-size: 14px;
        padding: 0px;
        flex: 1;
        justify-content: center;
        text-align: center;
        margin: 0 1rem 0 0;
        height:38px;

        :last-child {
            margin: 0 0 0 0;
        }
    }
`;

const My = styled.div`
    flex: 1;
    flex-grow: 1;
    font-family: ${(props) => props.theme.fontFamily.regular};
    color: ${(props) => props.theme.basic.color.primary};
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MLogout = styled.div`
    position: absolute;
    right: 17px;
    top: 17px;
    background-image: ${`url(${ICLogout})`};
    background-image: -webkit-image-set(${getBgSet(ICLogoutSet)});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    width: 15px;
    height: 18px;
`;

const MMypage = styled(Link)`
    position: absolute;
    left: 17px;
    top: 17px;
    background-image: ${`url(${ICCalendar})`};
    background-image: -webkit-image-set(${getBgSet(ICCalendarSet)});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    width: 18px;
    height: 18px;
`;

const SubMenuWrapper = styled.div`
    position: absolute;
    top: 72px;
    left: 0px;
    min-width: 170px;
    padding: 0 35px;
    text-align: center;
    width: auto;
    border: 1px solid ${(props) => props.theme.basic.line.header};
    border-top: none;
    background-color: ${(props) => props.theme.basic.bg.background};
    z-index: 2;

    ul {
        list-style-type: none;
        padding: 0px;
        margin: 0px;
        width: 100%;
    }

    li {
        line-height: 25px;
        padding: 5px 0;
        text-align: center;
        cursor: pointer;
        font-size:14px;
        color: ${(props) => props.theme.basic.color.primary};

        a {
            color: ${(props) => props.theme.basic.color.primary};
        }

        :first-child {
            padding-top: 15px;
        }

        :last-child {
            padding-bottom: 15px;
        }
    }

    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                width: 100%;
                padding: 0;
                top: 95px;
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

                ul {
                    display: flex;
                    flex-wrap: wrap;
                }
                li {
                    flex-basis: 25%;

                    :first-child {
                        padding-top: 5px;
                    }

                    :last-child {
                        padding-bottom: 0;
                    }
                }
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                width: 100%;
                padding: 0;
                top: 95px;
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

                ul {
                    display: flex;
                    flex-wrap: wrap;
                }
                li {
                    flex-basis: 25%;

                    :first-child {
                        padding-top: 5px;
                    }

                    :last-child {
                        padding-bottom: 0;
                    }
                }
            }
          `;
        }
    }}

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        width: 100%;
        padding: 0;
        top: 95px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

        ul {
            display: flex;
            flex-wrap: wrap;
        }
        li {
            flex-basis: 25%;

            :first-child {
                padding-top: 5px;
            }

            :last-child {
                padding-bottom: 0;
            }
        }
    }
`;

const Footer = (props) => {
    const { cfg } = useContext(AppContext);
    const { event } = cfg;

    return <FooterWrapper dangerouslySetInnerHTML={{ __html: event?.footer }} />;
};

const VodModalWrapper = styled.div`
    background-color: rgba(0, 0, 0, 0.75);
    .ReactModal__Content{
        background: rgba(0, 0, 0, 0.75);
    }
`;
