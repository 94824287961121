import React, { Fragment, useEffect, useContext, useState, useRef } from "react";
import styled from "styled-components";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";

const _COLORS = {
    mouse: "#bababa",
    line: "#ff0000",
};
const _STROKE_WIDTH = 1;
const _ELEMENT_FONT_MARGIN = 20;
const _ELEMENT_FONT_SIZE = 16;

export default (props) => {
    const { cfg } = useContext(AppContext);

    const { idx: siteIdx } = cfg;
    const { idx: siteEventIdx } = cfg.event;
    const { booth } = props;

    const { debug, content: contentInProps, onClick, isPopup } = props;
    const [content, setContent] = useState(null);
    const [elements, setElements] = useState([]);
    const [bg, setBg] = useState("");
    const [mousePos, setMousePos] = useState(null);

    const guideRef = useRef(null);
    const canvasRef = useRef(null);
    const [context, setContext] = useState(null);
    const [size, setSize] = useState(null);

    const wSize = useWindowSize();

    const onDraw = (ctx) => {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.strokeWidth = _STROKE_WIDTH;
        ctx.font = `${_ELEMENT_FONT_SIZE}px Arial`;

        if (bg) {
            ctx.drawImage(bg, 0, 0, bg.width, bg.height, 0, 0, ctx.canvas.width, ctx.canvas.height);
        }
        if (debug && elements && elements.length > 0) {
            elements.map((o) => drawObject({ ctx, ...o }));
        }
        if (debug && mousePos) {
            drawMouse({ ctx, pos: mousePos });
        }
    };

    const onMouseDown = (e) => {
        if (e.button !== 0) {
            return;
        }

        const pos = getPosition(e, canvasRef.current);
        const element = fitInElement(pos, content[wSize.ratio], canvasRef.current);
        if (element) {
            console.log("onclick!")
            onClick && onClick(element);
        }
    };

    const onMouseMove = (e) => {
        const pos = getPosition(e, canvasRef.current);
        setMousePos(pos);
        const element = fitInElement(pos, content[wSize.ratio], canvasRef.current);

        if (element) {
            e.target.style.cursor = "pointer";
        } else {
            e.target.style.cursor = "default";
        }
    };

    useEffect(() => {
        // setContent(JSON.parse(contentInProps));
        setContent(JSON.parse(cfg.event.content));
    }, [contentInProps]);

    useEffect(() => {
        const handleResize = () => {
            if (wSize) {
                const s = getSize(guideRef.current.offsetWidth, wSize?.ratio, context);
                setSize(s);
                if (debug) {
                    console.log(`:: Debug :: - Resize w:${s.width}, h:${s.height}`);
                }
            }
        };

        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [wSize, context]);

    useEffect(() => {
        if (wSize && content) {
            const url = content.bg[wSize.ratio];
            if (url) {
                const image = new Image();
                image.src = url;
                image.onload = () => {
                    setBg(image);
                };
            }
            setElements(content[wSize.ratio]);
        }
    }, [content, wSize]);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        setContext(context);
        let animationFrameId;

        const render = () => {
            onDraw(context);
            animationFrameId = window.requestAnimationFrame(render);
        };
        render();

        return () => {
            window.cancelAnimationFrame(animationFrameId);
        };
    }, [onDraw]);

    return (
        <Container debug={debug}>
            <CanvasWrapper isPopup={isPopup}>
                <Canvas {...{ ...size, debug }} ref={canvasRef} onMouseDown={onMouseDown} onMouseMove={onMouseMove} />
            </CanvasWrapper>
            <div ref={guideRef} />
        </Container>
    );
};

const drawObject = ({ ctx, id, title, cid, type, start, end }) => {
    if (!ctx || !type || !start || !end) {
        return;
    }

    const s = getLocation(start, ctx);
    const e = getLocation(end, ctx);
    const x = Math.min(s.x, e.x);
    const y = Math.min(s.y, e.y);
    const w = Math.abs(e.x - s.x);
    const h = Math.abs(e.y - s.y);

    ctx.fillStyle = _COLORS.line;
    ctx.strokeStyle = _COLORS.line;
    if (id) {
        ctx.fillText(`ID : ${id.substring(0, 20)}...`, x + _ELEMENT_FONT_MARGIN / 2, y + _ELEMENT_FONT_MARGIN);
    }
    if (cid && title) {
        ctx.fillText(`CID : ${cid}`, x + _ELEMENT_FONT_MARGIN / 2, y + _ELEMENT_FONT_MARGIN * 2);
        ctx.fillText(`TITLE : ${title}`, x + _ELEMENT_FONT_MARGIN / 2, y + _ELEMENT_FONT_MARGIN * 3);
    }

    switch (type) {
        case "RECTANGLE":
            ctx.strokeRect(x, y, w, h);
            break;
    }
};

const drawMouse = ({ ctx, pos }) => {
    if (!ctx || !pos) {
        return;
    }
    const p = getLocation(pos, ctx);

    ctx.strokeStyle = _COLORS.mouse;
    ctx.beginPath();
    ctx.moveTo(p.x, 0);
    ctx.lineTo(p.x, ctx.canvas.height);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(0, p.y);
    ctx.lineTo(ctx.canvas.width, p.y);
    ctx.stroke();
};

const getPosition = (e, cvs) => {
    const rect = cvs.getBoundingClientRect();
    return {
        x: (e.clientX - rect.left) / rect.width,
        y: (e.clientY - rect.top) / rect.height,
    };
};

const getLocation = (pos, ctx) => {
    return {
        x: pos.x * ctx.canvas.width,
        y: pos.y * ctx.canvas.height,
    };
};

const getLocationFromCanvas = (pos, cvs) => {
    const rect = cvs.getBoundingClientRect();
    return {
        x: pos.x * rect.width,
        y: pos.y * rect.height,
    };
};

const fitInElement = (pos, elements, cvs) => {
    if (!pos || !elements || elements.length === 0 || !cvs) {
        return null;
    }
    const e = getLocationFromCanvas(pos, cvs);
    const fitIn = elements.filter((el) => {
        const start = getLocationFromCanvas(el.start, cvs);
        const end = getLocationFromCanvas(el.end, cvs);
        const x = Math.min(start.x, end.x);
        const y = Math.min(start.y, end.y);
        const w = Math.abs(end.x - start.x);
        const h = Math.abs(end.y - start.y);
        const x1 = x;
        const x2 = x + w;
        const y1 = y;
        const y2 = y + h;
        if (e.x >= x1 && e.x <= x2 && e.y >= y1 && e.y <= y2) {
            return el;
        }
    });
    if (fitIn.length > 0) {
        return fitIn[0];
    }
    return null;
};

const getSize = (w, ratio, ctx) => {
    if (!w || !ratio) {
        return 0;
    }
    const rs = ratio.split("x");
    const rw = parseInt(rs[0]);
    const rh = parseInt(rs[1]);
    let width = parseInt(w);
    let height = parseInt((w * rh) / rw);

    const min = Math.min(window.innerWidth, window.innerHeight);

    if (ctx?.canvas?.height >= min) {
        height = min;
        width = parseInt((min * rw) / rh);
    }

    return { width, height };
};

const useWindowSize = () => {
    const isClient = typeof window === "object";

    const getSize = (w, h) => {
        const width = isClient ? (w ? w : window.innerWidth) : undefined;
        const height = isClient ? (h ? h : window.innerHeight) : undefined;
        if (width && height) {
            const orient = width > height ? "LAND" : "PORT";
            const max = Math.max(width, height);
            const min = Math.min(width, height);
            const ratioValue = max / min;
            let ratio = width > height ? "16x9" : "9x16";
            // if (ratioValue > 1.7) {
            //     // 1.77777
            //     ratio = orient === "LAND" ? "16x9" : "9x16";
            // } else if (ratioValue > 1.3) {
            //     // 1.33333
            //     ratio = orient === "LAND" ? "4x3" : "3x4";
            // } else {
            //     /// 1
            //     ratio = "1x1";
            // }
            return {
                width,
                height,
                orient,
                ratio,
            };
        }
        return {
            width,
            height,
        };
    };

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        if (!isClient) {
            return false;
        }

        const handleResize = () => {
            setWindowSize(getSize());
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return windowSize;
};

const Canvas = styled.canvas`
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    ${(props) => props.debug && `border:#cccccc solid 1px;`}
    cursor:pointer;
`;

const Container = styled.div`
    ${(props) => props.debug && `border:black dashed 1px;`}
`;

const CanvasWrapper = styled.div`
    display: flex;
    ${(props) =>
        props.isPopup
            ? ``
            : `
            align-items: center;
            justify-content: center;    
    `}
    width: 100vw;
    height: 100vh;
`;
