import React, { Fragment, useContext, useEffect, useState } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";
import styled, { css } from "styled-components";
import Modal from "react-modal";
import moment from "moment";
import cx from "classnames";
import { isMobile } from "react-device-detect";

import { AppContext } from "../../../components/AppProvider";

import { _API, _U, _CK } from "../../../modules/utils";
import { _CFG } from "../../../modules/config";

import { Theme } from "../../../components/GlobalStyles";

import * as _ from "lodash";

import EboothView from "./ebooth.in";
import Contents from "./Components.Content";
import BoothPopup from "./BoothPopup";


import { CloseButton } from "./UI/Components";


export default (props) => {
    // const { match } = props;
    const { cfg, auth, iam } = useContext(AppContext);
    const event = cfg.event || null;
    const [content, setContent] = useState("");
    const [contentType, setContentType] = useState("");
    const [contentIdx, setContentIdx] = useState("");

    const [videoUrl, setVideoUrl] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");

    const [data, setData] = useState({});
    const [imageIndex, setImageIndex] = useState(0);
    const [vodEncodedUrl, setVodEncodedUrl] = useState("");

    const exceptModalArray = ["IMAGE", "LINK", "PDF", "VIDEO"];

    const [isPremium, setIsPremium] = useState((event.pdfEbookYn || 'N') === 'Y' ? true : false);
    const [isHeaderShowCnt, setIsHeaderShowCnt] = useState(0);

    const onReceivedMessage = (e) => {
        console.log("GET MESSAGE", e);
        _U.parseMessage(e).then((data) => {
            if (!data || !data.data) return;
            const { url, contentType, itemType, cid } = data.data;
            switch (data.code) {
                case "SELECT-MENU":
                    if ("ATTENDANCE" === url && contentType === "link") {
                        window.location.href = "/#/mypage";
                        setIsHeaderShowCnt(isHeaderShowCnt + 1);
                        break;
                    }
                    if ("EPOSTER" === url && contentType === "link") {
                        window.location.href = "/#/eposter";
                        setIsHeaderShowCnt(isHeaderShowCnt + 1);
                        break;
                    }
                    if ("EBOOTH" === url && contentType === "link") {
                        window.location.href = "/#/ebooth";
                        setIsHeaderShowCnt(isHeaderShowCnt + 1);
                        break;
                    }
                    if ("LECTURE" === url && contentType === "link") {
                        window.location.href = "/#/lecture";
                        setIsHeaderShowCnt(isHeaderShowCnt + 1);
                        break;
                    }

                    if (contentType) {
                        setContentType(exceptModalArray.includes(itemType) ? itemType : contentType);
                        loadContent(cid, exceptModalArray.includes(itemType) ? itemType : contentType);
                    }
                    if (cid) {
                        setContentIdx(cid);
                    }
                    setContent(url);

                    break;
                case "AGENDA-LECTURE":
                    setContentIdx(data?.data.cid); // channelIdx;
                    setContent(data.code);
                    break;
                case "EBOOTH-BASIC":
                    setContentIdx(data?.data.cid); // companyIdx;
                    setContent(data.code);
                    break;
                case "SATELLITE-AGENDA":
                    setContentIdx(data?.data.satelliteIdx);
                    setContent(data.code);
                    break;
                case "ONLY-VIDEO":
                    setVideoUrl(data?.data.videoSignedUrl);
                    setVodEncodedUrl(data?.data.vodEncodedUrl);
                    setContentType(data?.data.contentType);
                    setTitle(data?.data.title || "");
                    setDescription(data?.data.description || "");
                    setContent(data.code);
                    break;
                case "CLOSE-MODAL":
                    setContent("");
                    setContentType("");
                    setContentIdx("");
                    break;
                case "ENTER-VR-MAIN":
                    _CK.set("clickedEnter", true);
                    break;
            }
        });
    };

    const loadContent = (cid, conType) => {
        const { siteIdx, eventIdx } = cfg.event;
        const param = { siteIdx, eventIdx, contentIdx: cid, contentType: conType };
        if (!cid) {
            setContentType("");
            return;
        }
        _API.getContent({ data: param }).then((res) => {
            const { data: content, files } = res.data;
            setContentType(content.contentType);
            switch (content.contentType) {
                case "IMAGE":
                    setData(content);
                    break;
                case "PDF":
                    // setData(content);
                    // console.log(content);
                    _CK.set("pdfContent", content);
                    window.open("/#/pdf");
                    break;
                case "LINK":
                    window.open(content.content);
                    break;
            }
            // setFiles(files);
        });
    };

    const setModalStyle = (isMobileProp, contentTypeProp) => {
        const cType = contentTypeProp === "VOD_FILE" || contentTypeProp === "LINK" ? "VIDEO" : contentTypeProp;
        let modalMobileStyle = _.cloneDeep(Theme.basic.modal.contents.mobile);
        let modalPcStyle = _.cloneDeep(Theme.basic.modal.contents.pc);

        if (content === "EBOOTH-BASIC") {
            modalMobileStyle.content.bottom = "auto";
            modalPcStyle.content.bottom = "auto";
        }

        return isMobileProp ? _.merge(modalMobileStyle, cType === "VIDEO" ? { content: { borderRadius: "0", padding: "0", paddingBottom: "0" } } : {}) : _.merge(modalPcStyle, cType === "VIDEO" ? { content: { borderRadius: "0", padding: "0", paddingBottom: "0" } } : {});
    };

    useEffect(() => {
        window.addEventListener("message", onReceivedMessage);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, []);

    return (
        <Router>
            <Switch>
                <Route path="/ebooth/popup/:companyIdx" exact component={BoothPopup} />

                <Wrapper >
                    {/* <Container> */}
                    <Route path="/ebooth" exact component={EboothView} />
                    <Route path="/" exact component={EboothView} />
                </Wrapper>
            </Switch>
            {content && !exceptModalArray.includes(contentType) && (
                <Modal isOpen={content && !exceptModalArray.includes(contentType) ? true : false} ariaHideApp={false} style={setModalStyle(isMobile, contentType)}>
                    <CloseButton
                        onClick={() => {
                            setContent("");
                            setContentType("");
                        }}
                    />
                    <Contents code={content} contentType={contentType} contentIdx={contentIdx} videoUrl={videoUrl} title={title} description={description} vodEncodedUrl={vodEncodedUrl} onCancel={() => setContent("")} />
                </Modal>
            )}
        </Router>
    );
};

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
`;








