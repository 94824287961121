import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import swal from "sweetalert";
import Modal from "react-modal";
import readXlsxFile from "read-excel-file";

import Layout from "../Layout";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";
import { _DEF } from "../../../modules/config";

import { Separator } from "../../../components/UI/Wrapper";
import Input, { SearchInput, SelectForm, InputForm, SegmentedForm } from "../../../components/UI/Form";
import { ModalCloseButton } from "../../../components/UI/Button";
import Paging from "../../../components/UI/Paging";
import { Theme } from "../../../components/GlobalStyles";

export default (props) => {
    const { history, location, match } = props;

    const { cfg } = useContext(AppContext);

    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [eventIdx, setEventIdx] = useState("");
    const [siteList, setSiteList] = useState([]);
    const [eventList, setEventList] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [selectItem, setSelectItem] = useState(null);

    const [list, setList] = useState([]);
    const [paging, setPaging] = useState(null);
    const [summary, setSummary] = useState({});

    const loadEventList = (siteIdx) => {
        if (!siteIdx) {
            setEventList([]);
            setEventIdx("");
            return;
        }

        const data = {
            siteIdx,
        };

        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list } = res.data;
            setEventList(list);
        });
    };

    const loadList = (page) => {
        const data = {
            siteIdx,
            eventIdx,
            keyword,
            page,
        };
        const req = { path: "/v1/a/event/registration", data };
        _API.get(req).then((res) => {
            const { list, paging, summary } = res.data;
            setList(list);
            setPaging(paging);
            setSummary(summary);
        });
    };

    useEffect(() => {
        loadList(1);
        loadEventList(siteIdx);
    }, []);

    const movePage = (page) => {
        loadList(page);
    };

    const onSearch = (e) => {
        loadList(1);
    };

    const onRefresh = () => {
        loadList(paging.pageNo);
    };

    useEffect(() => {
        loadList(1);
    }, [eventIdx])

    return (
        <>
            <Layout {...props}>
                <div className="row wrapper border-bottom white-bg page-heading">
                    <div className="col-sm-8">
                        <h2>사전등록</h2>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/admin">HOME</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to={`${location.pathname}`}>이벤트</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <strong>사전등록</strong>
                            </li>
                        </ol>
                    </div>
                    <div className="col-sm-4">
                        <div className="title-action"></div>
                    </div>
                </div>
                <div className="wrapper wrapper-content">
                    <div className="row">
                        <div className={cx({ "col-md-12": true })}>
                            <div className="ibox">
                                <div className="ibox-title">사전등록자 목록</div>
                                <div className="ibox-content ibox-heading">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <SelectForm
                                                name="eventIdx"
                                                items={eventList.map((event) => {
                                                    return { label: event.eventName, value: event.idx };
                                                })}
                                                value={eventIdx}
                                                onChange={(e) => {
                                                    setEventIdx(e.target.value);
                                                }}
                                                nullTitle={eventList?.length > 0 ? `이벤트 선택` : `사이트를 먼저 선택하세요.`}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <SearchInput value={keyword} onChange={(e) => setKeyword(e.target.value)} onClick={onSearch} placeholder="검색어를 입력해 주세요." />
                                        </div>
                                    </div>
                                    <div style={{ textAlign: "right", marginRight: "15px" }}>
                                        전체 : {_U.numberFormat(summary?.totalCnt || 0)}명, 금일등록 : {_U.numberFormat(summary?.todayRegCnt || 0)}명
                                    </div>
                                </div>
                                <div className="ibox-content">
                                    <List list={list} onChange={onRefresh} />
                                </div>
                                {paging && (
                                    <div className="ibox-footer">
                                        <Paging paging={paging} pageCallback={movePage} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};

const List = (props) => {
    const { list, onChange } = props;
    return (
        <div className="table-responsive">
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        {/* <th className="text-center">사이트명</th> */}
                        <th className="text-center">이벤트명</th>
                        <th className="text-center">이름</th>
                        <th className="text-center">이메일</th>
                        <th className="text-center">전화번호</th>
                        <th className="text-center">소속</th>
                        <th className="text-center">부서</th>
                        <th className="text-center">직책</th>
                        <th className="text-center">면허번호</th>
                        <th className="text-center">등록구분</th>
                        <th className="text-center">등록일</th>
                        <th className="text-center">관리</th>
                    </tr>
                </thead>
                <tbody>
                    {list.map((item, i) => (
                        <ListItem {...item} key={i} onChange={onChange} />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const ListItem = (props) => {
    const { onChange, onModify } = props;

    const onDelete = (status) => {
        swal({
            title: "정말 삭제 하시겠습니까?",
            text: "삭제된 데이터는 복구가 불가합니다 .",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                _API.delete({
                    path: "/v1/a/event/registration",
                    data: {
                        idx: props.idx,
                        siteIdx: props.siteIdx,
                        eventIdx: props.eventIdx,
                        status,
                    },
                }).then((res) => {
                    onChange && onChange();
                });
            }
        });
    };

    return (
        <tr>
            {/* <td className="text-center">{props.siteName}</td> */}
            <td className="text-center">{props.eventName}</td>
            <td className="text-center">{props.name}</td>
            <td className="text-center">{props.email}</td>
            <td className="text-center">{props.phone}</td>
            <td className="text-center">{props.companyName}</td>
            <td className="text-center">{props.deptName}</td>
            <td className="text-center">{props.position}</td>
            <td className="text-center">{props.licenseNumber}</td>
            <td className="text-center">{props.regTypeName}</td>
            <td className="text-center">{moment(props.regDate).format("YYYY-MM-DD")}</td>
            <td className="text-center">
                <div className="btn-group">
                    <button type="button" onClick={onDelete.bind(this, "D")} className={cx({ btn: true, "btn-xs": true, "btn-danger": true })}>
                        <i className="fa fa-trash"></i> 삭제
                    </button>
                </div>
            </td>
        </tr>
    );
};
