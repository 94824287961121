import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { v4 as uuid } from "uuid";

const _COLORS = {
    mouse: "#bababa",
    basic: "#000000",
    pointer: "#00ff00",
    select: "#0000ff",
};
const _TRACKER_SIZE = 15;
const _STROKE_WIDTH = 1;
const _ELEMENT_FONT_MARGIN = 20;
const _ELEMENT_FONT_SIZE = 16;

let _POINTERS = [];

export default (props) => {
    const { ratio, debug, elements: elementsInProps, bg: bgInProps, onChange } = props;

    const guideRef = useRef(null);
    const canvasRef = useRef(null);
    const [size, setSize] = useState(0);

    const [elType, setElType] = useState("RECTANGLE");
    const [elements, setElements] = useState({});
    const [bg, setBg] = useState("");

    const [mousePos, setMousePos] = useState(null);
    const [startPos, setStartPos] = useState(null);
    const [endPos, setEndPos] = useState(null);
    const [selectedPointer, setSelectedPointer] = useState(null);
    const [selectedElement, setSelectedElement] = useState(null);

    const onDraw = (ctx) => {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.strokeWidth = _STROKE_WIDTH;
        ctx.font = `${_ELEMENT_FONT_SIZE}px Arial`;

        if (bg) {
            ctx.drawImage(bg, 0, 0, bg.width, bg.height, 0, 0, ctx.canvas.width, ctx.canvas.height);
        }

        const els = elements[ratio] || [];
        if (els.length > 0) {
            els.map((o) => drawObject({ ctx, ...o }));
        }

        if (!selectedPointer && !selectedElement) {
            if (startPos && endPos) {
                drawObject({ ctx, type: elType, start: startPos, end: endPos, pointer: true });
            }
        }

        if (mousePos) {
            drawMouse({ ctx, pos: mousePos });
        }
    };

    const onMouseDown = (e) => {
        if (e.button !== 0) {
            return;
        }

        const pos = getPosition(e, canvasRef.current);
        setMousePos(pos);
        setStartPos(pos);
        setEndPos(pos);

        const pointer = fitInPointer(pos, canvasRef.current);
        if (pointer) {
            if (pointer.type === "rm") {
                setMousePos(null);
                setStartPos(null);
                setEndPos(null);
                onRemoveCurrent();
            } else {
                setSelectedPointer(pointer);
            }
            return;
        }

        const element = fitInElement(pos, elements[ratio], canvasRef.current);
        if (element) {
            onSelectElement(element);
        }
    };
    const onMouseMove = (e) => {
        const pos = getPosition(e, canvasRef.current);
        setMousePos(pos);
        if (!startPos) {
            return;
        }

        if (selectedPointer) {
            const list = movePointer(selectedPointer, pos, elements[ratio]);
            setElements({ ...elements, [ratio]: list });
        } else if (selectedElement) {
            const list = moveElement(selectedElement, pos, endPos, elements[ratio]);
            setElements({ ...elements, [ratio]: list });
        }

        setEndPos(pos);
    };
    const onMouseUp = (e) => {
        if (!startPos) {
            return;
        }

        const pos = getPosition(e, canvasRef.current);
        const start = {
            x: Math.min(startPos.x, pos.x),
            y: Math.min(startPos.y, pos.y),
        };
        const end = {
            x: Math.max(startPos.x, pos.x),
            y: Math.max(startPos.y, pos.y),
        };

        if (selectedPointer) {
            onResizeElement(pos);
            setSelectedPointer(null);
        } else if (selectedElement) {
            onMovedElement(pos);
            setSelectedElement(null);
        } else {
            onAddElement({ type: elType, start, end });
        }
        setMousePos(pos);
        setStartPos(null);
        setEndPos(null);
    };

    const onAddElement = (el) => {
        const list = elements[ratio] || [];
        for (let i in list) {
            list[i].select = false;
        }
        list.push({ ...el, select: true, id: uuid() });

        const els = { ...elements, [ratio]: list };
        onChange && onChange(els);
    };

    const onResizeElement = (pos) => {
        const list = movePointer(selectedPointer, pos, elements[ratio]);
        for (let i in list) {
            if (list[i].select) {
                const el = list[i];
                const start = {
                    x: Math.min(el.start.x, el.end.x),
                    y: Math.min(el.start.y, el.end.y),
                };
                const end = {
                    x: Math.max(el.start.x, el.end.x),
                    y: Math.max(el.start.y, el.end.y),
                };
                el.start = start;
                el.end = end;
            }
        }
        const els = { ...elements, [ratio]: list };
        onChange && onChange(els);
    };

    const onMovedElement = () => {
        onChange && onChange({ ...elements });
    };

    const onSelectElement = (element) => {
        setSelectedElement(element);
        const list = elements[ratio].map((el) => {
            el.select = el.id === element.id;
            return el;
        });
        const els = { ...elements, [ratio]: list };
        onChange && onChange(els);
    };

    const onRemoveCurrent = () => {
        const list = elements[ratio].filter((e) => e.select === false);
        const els = { ...elements, [ratio]: list };
        onChange && onChange(els);
    };

    useEffect(() => {
        const handleResize = () => {
            const s = getSize(guideRef.current?.offsetWidth, ratio);
            setSize(s);
            if (debug) {
                console.log(`:: Debug :: - Resize w:${s.width}, h:${s.height}`);
            }
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [ratio]);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        let animationFrameId;

        //Our draw came here
        const render = () => {
            onDraw(context);
            animationFrameId = window.requestAnimationFrame(render);
        };
        render();

        return () => {
            window.cancelAnimationFrame(animationFrameId);
        };
    }, [onDraw]);

    useEffect(() => {
        setElements(elementsInProps);
    }, [elementsInProps]);

    useEffect(() => {
        if (bgInProps && bgInProps[ratio]) {
            const url = bgInProps[ratio];
            const image = new Image();
            image.src = url;
            image.onload = () => {
                setBg(image);
            };
        } else {
            setBg(null);
        }
    }, [bgInProps, ratio]);

    return (
        <Fragment>
            <CVS {...{ ...size, debug }} ref={canvasRef} onMouseDown={onMouseDown} onMouseMove={onMouseMove} onMouseUp={onMouseUp} />
            <div ref={guideRef} />
        </Fragment>
    );
};

const CVS = styled.canvas`
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    ${(props) => props.debug && `border:#cccccc solid 1px;`}
`;

const drawMouse = ({ ctx, pos }) => {
    if (!ctx || !pos) {
        return;
    }
    const p = getLocation(pos, ctx);

    ctx.strokeStyle = _COLORS.mouse;
    ctx.beginPath();
    ctx.moveTo(p.x, 0);
    ctx.lineTo(p.x, ctx.canvas.height);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(0, p.y);
    ctx.lineTo(ctx.canvas.width, p.y);
    ctx.stroke();
};

const drawObject = ({ ctx, id, title, cid, type, start, end, select, pointer, contentType, url }) => {
    if (!ctx || !type || !start || !end) {
        return;
    }

    const s = getLocation(start, ctx);
    const e = getLocation(end, ctx);
    const x = Math.min(s.x, e.x);
    const y = Math.min(s.y, e.y);
    const w = Math.abs(e.x - s.x);
    const h = Math.abs(e.y - s.y);

    ctx.fillStyle = pointer ? _COLORS.pointer : select ? _COLORS.select : _COLORS.basic;
    ctx.strokeStyle = pointer ? _COLORS.pointer : select ? _COLORS.select : _COLORS.basic;
    if (id) {
        ctx.fillText(`ID : ${id.substring(0, 20)}...`, x + _ELEMENT_FONT_MARGIN / 2, y + _ELEMENT_FONT_MARGIN);
    }
    if (contentType) {
        ctx.fillText(`CTYPE : ${contentType}`, x + _ELEMENT_FONT_MARGIN / 2, y + _ELEMENT_FONT_MARGIN * 2);
    }
    if (title) {
        ctx.fillText(`TITLE : ${title}`, x + _ELEMENT_FONT_MARGIN / 2, y + _ELEMENT_FONT_MARGIN * 3);
    }
    if (cid) {
        ctx.fillText(`CID : ${cid}`, x + _ELEMENT_FONT_MARGIN / 2, y + _ELEMENT_FONT_MARGIN * 4);
    } else if (url) {
        ctx.fillText(`URL : ${url}`, x + _ELEMENT_FONT_MARGIN / 2, y + _ELEMENT_FONT_MARGIN * 4);
    }

    switch (type) {
        case "RECTANGLE":
            ctx.strokeRect(x, y, w, h);
            break;
    }
    if (pointer || select) {
        drawTracker({ ctx, x, y, width: w, height: h, pointer, select });
    }
};

const drawTracker = ({ ctx, x, y, width, height, pointer }) => {
    ctx.strokeStyle = pointer ? _COLORS.pointer : _COLORS.select;

    const tracker = (t) => {
        ctx.strokeRect(t.x, t.y, t.width, t.height);
    };

    const tl = { x: x - _TRACKER_SIZE / 2, y: y - _TRACKER_SIZE / 2, width: _TRACKER_SIZE, height: _TRACKER_SIZE, type: "tl" };
    const tc = { x: x + width / 2 - _TRACKER_SIZE / 2, y: y - _TRACKER_SIZE / 2, width: _TRACKER_SIZE, height: _TRACKER_SIZE, type: "tc" };
    const tr = { x: x + width - _TRACKER_SIZE / 2, y: y - _TRACKER_SIZE / 2, width: _TRACKER_SIZE, height: _TRACKER_SIZE, type: "tr" };
    const ml = { x: x - _TRACKER_SIZE / 2, y: y + height / 2 - _TRACKER_SIZE / 2, width: _TRACKER_SIZE, height: _TRACKER_SIZE, type: "ml" };
    const mr = { x: x + width - _TRACKER_SIZE / 2, y: y + height / 2 - _TRACKER_SIZE / 2, width: _TRACKER_SIZE, height: _TRACKER_SIZE, type: "mr" };
    const bl = { x: x - _TRACKER_SIZE / 2, y: y + height - _TRACKER_SIZE / 2, width: _TRACKER_SIZE, height: _TRACKER_SIZE, type: "bl" };
    const bc = { x: x + width / 2 - _TRACKER_SIZE / 2, y: y + height - _TRACKER_SIZE / 2, width: _TRACKER_SIZE, height: _TRACKER_SIZE, type: "bc" };
    const br = { x: x + width - _TRACKER_SIZE / 2, y: y + height - _TRACKER_SIZE / 2, width: _TRACKER_SIZE, height: _TRACKER_SIZE, type: "br" };

    const rm = { x: x + width - _TRACKER_SIZE * 2, y: y + height - _TRACKER_SIZE * 2, width: _TRACKER_SIZE, height: _TRACKER_SIZE, type: "rm" };

    _POINTERS = [tl, tc, tr, ml, mr, bl, bc, br, rm];
    _POINTERS.map((t) => tracker(t));

    ctx.fillText(`X`, rm.x + _ELEMENT_FONT_MARGIN * 0.1, rm.y + _ELEMENT_FONT_MARGIN * 0.66);
};

const fitInPointer = (pos, cvs) => {
    const e = getLocationFromCanvas(pos, cvs);
    const fitIn = _POINTERS.filter((p) => {
        var x1 = p.x;
        var x2 = p.x + p.width;
        var y1 = p.y;
        var y2 = p.y + p.height;

        if (e.x >= x1 && e.x <= x2 && e.y >= y1 && e.y <= y2) {
            return p;
        }
    });
    if (fitIn.length > 0) {
        return fitIn[0];
    }
    return null;
};

const movePointer = (pointer, pos, old) => {
    if (!pointer || !pos || !old || old.length === 0) {
        return [];
    }

    const list = old.map((obj) => {
        if (obj.select) {
            switch (pointer.type) {
                case "tl":
                    obj.start = pos;
                    break;
                case "tc":
                    obj.start.y = pos.y;
                    break;
                case "tr":
                    obj.start.y = pos.y;
                    obj.end.x = pos.x;
                    break;
                case "ml":
                    obj.start.x = pos.x;
                    break;
                case "mr":
                    obj.end.x = pos.x;
                    break;
                case "bl":
                    obj.start.x = pos.x;
                    obj.end.y = pos.y;
                    break;
                case "bc":
                    obj.end.y = pos.y;
                    break;
                case "br":
                    obj.end = pos;
                    break;
            }
        }
        return obj;
    });
    return list;
};

const fitInElement = (pos, elements, cvs) => {
    if (!pos || !elements || elements.length === 0 || !cvs) {
        return null;
    }
    const e = getLocationFromCanvas(pos, cvs);
    const fitIn = elements.filter((el) => {
        const start = getLocationFromCanvas(el.start, cvs);
        const end = getLocationFromCanvas(el.end, cvs);
        const x = Math.min(start.x, end.x);
        const y = Math.min(start.y, end.y);
        const w = Math.abs(end.x - start.x);
        const h = Math.abs(end.y - start.y);
        const x1 = x;
        const x2 = x + w;
        const y1 = y;
        const y2 = y + h;
        if (e.x >= x1 && e.x <= x2 && e.y >= y1 && e.y <= y2) {
            return el;
        }
    });
    if (fitIn.length > 0) {
        return fitIn[0];
    }
    return null;
};

const moveElement = (el, pos, oldPos, old) => {
    if (!el || !pos || !oldPos || !old || old.length === 0) {
        return old || [];
    }

    const list = old.map((o) => {
        if (o.select) {
            o.start.x = o.start.x + pos.x - oldPos.x;
            o.start.y = o.start.y + pos.y - oldPos.y;
            o.end.x = o.end.x + pos.x - oldPos.x;
            o.end.y = o.end.y + pos.y - oldPos.y;
        }
        return o;
    });

    return list;
};

const getPosition = (e, cvs) => {
    const rect = cvs.getBoundingClientRect();
    return {
        x: (e.clientX - rect.left) / rect.width,
        y: (e.clientY - rect.top) / rect.height,
    };
};

const getLocation = (pos, ctx) => {
    return {
        x: pos.x * ctx.canvas.width,
        y: pos.y * ctx.canvas.height,
    };
};

const getLocationFromCanvas = (pos, cvs) => {
    const rect = cvs.getBoundingClientRect();
    return {
        x: pos.x * rect.width,
        y: pos.y * rect.height,
    };
};

const getSize = (w, ratio) => {
    if (!w || !ratio) {
        return 0;
    }
    const rs = ratio.split("x");
    const rw = parseInt(rs[0]);
    const rh = parseInt(rs[1]);
    const width = parseInt(w);
    const height = parseInt((w * 8) / rw);
    return { width, height };
};
