import React, { useEffect, useState, useRef } from "react";
import swal from "sweetalert";

import ReactSummernote from "react-summernote";
import "react-summernote/lang/summernote-ko-KR";

import { _CFG } from "../../modules/config";
import { _API } from "../../modules/utils";
import reactSummernote from "react-summernote";

const defaultOptions = {
    lang: "ko-KR",
    height: 400,
    dialogsInBody: true,
    toolbar: [
        // ["style", ["style"]],
        ["font", ["bold", "underline", "clear"]],
        // ["fontsize", ["fontsize"]],
        ["color", ["color"]],
        ["fontname", ["fontname"]],
        ["para", ["ul", "ol", "paragraph"]],
        ["table", ["table"]],
        ["insert", ["link", "picture", "video"]],
        ["view", ["fullscreen", "codeview"]],
    ],
};

export default class SNEditor extends React.Component {
    constructor(props) {
        super(props);
        const state = { aws: null, isLoad : false, isCodeView : false };
        this.state = state;
    }

    componentDidMount = () => {
        this.uploadPrepare();
        // this.state.editorContent = "";
        this.state.isLoad = true;

        this.summernote.editor.on('summernote.blur.codeview', { this: this }, function(event) {
            // console.log('Codeview focus blur!!',event,reactSummernote,$(event.target));
            if(event.data.this.state.isCodeView)
                event.data.this.summernote.editor.summernote('code',event.data.this.summernote.editor.summernote('code'));
        });

        this.summernote.editor.on('summernote.codeview.toggled', { this: this }, function(event) {
            // console.log('Codeview toggled!!',event,reactSummernote,$(event.target));
            event.data.this.summernote.editor.summernote('code',event.data.this.summernote.editor.summernote('code'));
            event.data.this.setIsCodeView();
        });
    };

    componentWillUpdate = (nextProps, nextState) => {
        // console.log("Update!! ", nextState.isCodeView)
        if(this.props.content !== nextProps.content){
            this.handleOnChange(nextProps.content);
            if(nextState.isCodeView){
                this.summernote.editor.summernote('code',nextProps.content);
                // this.setIsCodeView();
            } 
        }
    }

    setIsCodeView = () => {
        this.state.isCodeView = !this.state.isCodeView;
    }

    uploadPrepare = () => {
        _API.get({ path: "/v1/upload" }).then((res) => {
            this.setState({ aws: res.data });
        });
    };

    handleOnChange = (content) => {
        this.props.onChange && this.props.onChange(content);
    };

    handleOnImageSelected = (files) => {
        if (!files || files.length === 0) {
            return;
        }
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file.size > 1024 * 1024 * 2) {
                swal("2MB 이하의 파일을 선택해 주세요.");
                return;
            }
        }

        const { aws } = this.state;
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const req = { aws, file, type: "SN", prefix: "SN" };
            _API.upload(req)
                .then((res) => res.data)
                .then((data) => {
                    const { url } = data;
                    this.summernote && this.summernote.insertImage(url);
                });
        }
    };

    render() {
        const { options, height, content } = this.props;
        const snOptions = options || defaultOptions;
        if (height) {
            snOptions.height = height;
        }
        return <ReactSummernote value={ this.state.isLoad ? content : ""} options={snOptions} onChange={this.handleOnChange.bind(this)} onImageUpload={this.handleOnImageSelected.bind(this)} ref={(ref) => (this.summernote = ref)} />;
    }
}
