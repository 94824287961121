import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import swal from "sweetalert";

import { AppContext } from "../../../components/AppProvider";

import { InputForm, InputViewForm, SelectForm, H2, HRDash, ButtonSubmit, ButtonDefault } from "./UI/Components";

import { _API, _U } from "../../../modules/utils";

import { _DEF } from "../../../modules/config";

export default (props) => {
    const { code, history } = props;
    const { cfg } = useContext(AppContext);
    const [idx, setIdx] = useState("");
    const [item, setItem] = useState({});

    const sessionCode = "_REGISTRATION_CHECK";

    useEffect(() => {
        const session = _U.getSession(sessionCode);
        if (!session) {
            props.onSetMode && props.onSetMode("REGISTRATION_CHECK");
            return;
        }

        if (session.code !== sessionCode || !session || !session.idx) {
            props.onSetMode && props.onSetMode("REGISTRATION_CHECK");
            return;
        }

        setIdx(session.idx);
    }, []);

    const loadData = () => {
        if (idx) {
            const data = { idx: idx, siteIdx: cfg.idx, eventIdx: cfg.event.eventIdx };

            _API.get({
                path: "/v1/site/event/registration/item",
                data: data,
            }).then((res) => {
                if (res.error === 0) {
                    setItem(res.data);
                } else {
                    swal(res.message);
                }
            });
        }
    };

    const onCancel = () => {
        props.onCancel && props.onCancel();
    };

    const onComplete = () => {
        _U.setSession("_REGISTRATION_MODIFY", { code: "_REGISTRATION_MODIFY", idx: idx });
        props.onSetMode && props.onSetMode("REGISTRATION");
    };

    useEffect(() => {
        _U.postMessage("LOCATION-CHANGED");

        loadData();
    }, [idx]);

    return (
        <Wrapper>
            <H2 style={{ textAlignLast: "center" }}>사전등록 내역</H2>
            <Form {...props} onComplete={onComplete} onCancel={onCancel} item={item} />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const Form = (props) => {
    const { cfg } = useContext(AppContext);
    const [item, setItem] = useState({});
    const { idx, onComplete, onCancel } = props;
    const { emailYn, phoneYn, nameYn, companyYn, licenseYn, regTypeYn } = cfg.event;
    const { registrationItemList } = cfg.event;
    const { companyEsYn, emailEsYn, licenseEsYn, nameEsYn, phoneEsYn, regTypeEsYn } = cfg.event;
    const [participantInfoList, setParticipantInfoList] = useState([]);

    useEffect(() => {
        // console.log(props.item);
        // console.log("registrationItemList", registrationItemList);
        setItem(props.item);

        const newRegistrationItemList = [];
        for (let i in registrationItemList) {
            const elRegistration = registrationItemList[i];
            for (let j in props.item?.infoItemList) {
                const elParticipant = props.item.infoItemList[j];
                if (elRegistration.registrationItemIdx === elParticipant.registrationItemIdx) {
                    elRegistration.answer = elParticipant.answer;
                    elRegistration.participantIdx = elParticipant.participantIdx;
                    elRegistration.participantInfoIdx = elParticipant.participantInfoIdx;
                }
            }
            newRegistrationItemList.push(elRegistration);
        }
        // console.log("newRegistrationItemList", newRegistrationItemList);
        setParticipantInfoList(newRegistrationItemList);
    }, [props.item]);

    return (
        <Fragment>
            {nameYn === "Y" && <InputForm title="성명" value={item?.name} readOnly={true} />}
            {emailYn === "Y" && <InputForm title="이메일" value={item?.email} readOnly={true} />}
            {phoneYn === "Y" && <InputForm title="휴대전화번호" value={item?.phone} readOnly={true} />}
            {companyYn === "Y" && <InputForm title="소속" value={item?.companyName} readOnly={true} />}
            {/* {companyYn === "Y" && <InputForm title="진료과" value={item?.deptName} readOnly={true} />} */}
            {/* {companyYn === "Y" && <InputForm title="직책" value={item?.position} readOnly={true} /> }  */}
            {/* {companyYn === "Y" && <InputForm title="부서명" value={item?.department} readOnly={true} />} */}
            {licenseYn === "Y" && <InputForm title="면허번호" value={item?.licenseNumber} readOnly={true} />}
            {regTypeYn === "Y" && item?.regType !== "ETC" && <InputForm title="등록구분" value={_DEF.getDefinedLabel(_DEF.PARTICIPANT_REG_TYPE, item?.regType) || item?.regTypeName} readOnly={true} />}
            {item?.regType === "ETC" && (
                <InputForm title="등록구분" name="regTypeName" value={item?.regTypeName} readOnly={true} />
            )}
            {participantInfoList?.map((addItem, i) => (
                <InputForm key={i} title={addItem.labelName} value={addItem.answer} readOnly={true} />
            ))}


            {<InputForm title="승인여부" value={_DEF.getDefinedLabel(_DEF.PARTICIPANT_STATUS, item?.status)} readOnly={true} />}
            <HRDash />
            <FormFooter>
                <ButtonDefault onClick={onCancel}>취소</ButtonDefault>
                <ButtonSubmit onClick={onComplete}>수정</ButtonSubmit>
            </FormFooter>
        </Fragment>
    );
};

const FormFooter = styled.div`
    display: flex;
    justify-content: center;

    & button {
        flex-basis: 28%;

        :last-child {
            margin-left: 2%;
        }

        @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
            flex-basis: 48%;
        }
    }
`;
