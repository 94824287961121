import React, { useContext, useState, useEffect, Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import swal from "sweetalert";
import moment from "moment";

import { AppContext } from "../../components/AppProvider";

import { _API, _U } from "../../modules/utils";
import { _DEF } from "../../modules/config";
import Paging from "./Paging";

import * as _ from 'lodash';

import SNEditor from "./SNEditor";

import Input, { SearchInput, SelectForm, InputForm, SegmentedForm, TextForm } from "./Form";

import Tooltip from '@material-ui/core/Tooltip';

export default (props) => {

    const { cfg } = useContext(AppContext);

    const { code, callback, sendType } = props;

    const [eventList, setEventList] = useState([]);

    const [keyword, setKeyword] = useState("");

    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [eventIdx, setEventIdx] = useState("");

    const [list, setList] = useState([]);
    const [validList, setValidList] = useState([]);
    const [inValidList, setInValidList] = useState([]);
    const [paging, setPaging] = useState(null);
    const [data, setData] = useState(null);

    const [isEye, setIsEye] = useState(true);
    const [isCheckView, setIsCheckView] = useState(false);

    const [checkList, setCheckList] = useState([]);

    const [sendProc, setSendProc] = useState("LIST");

    const BTN_TYPE = { "LIST": "다음", "TEMPLATE": "발송" };

    const loadEventList = (siteIdx) => {
        console.log(siteIdx)
        const data = {
            siteIdx,
        };

        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list } = res.data;
            setEventList(list);
        });
    };

    const loadParticipant = () => {
        if (list.length > 0) {
            swal({
                title: "[ 이미 목록이 있습니다 ]",
                text: "참가자 목록을 불러오시겠습니까?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((isTrue) => {
                if (isTrue) {
                    getParticipant();
                }
            });
        } else {
            getParticipant();
        }
        setAllCheck(false);
    };

    const getParticipant = () => {
        if (!eventIdx) {
            swal("이벤트를 선택하세요.");
            return;
        }

        const data = {
            siteIdx,
            eventIdx,
            sendType,
        };

        const req = { path: "/v1/a/send/manage/participant", data };
        _API.get(req).then((res) => {
            const { data, paging } = res;

            if (data.length == 0) swal("데이터가 없습니다.");

            validationList([], data);
        });
    }

    const checkHandler = (targetIndex, isChecked) => {
        let changeList = list.map((item) => { if (item.index == targetIndex) item.isChecked = isChecked; return item });
        let changeInValidList = inValidList.map((item) => { if (item.index == targetIndex) item.isChecked = isChecked; return item });

        setList(changeList);
        setInValidList(changeInValidList);
    }

    const setAllCheck = (isChecked) => {
        let changeList = list.map((item) => { item.isChecked = isChecked; return item });
        let changeInValidList = inValidList.map((item) => { item.isChecked = isChecked; return item });

        setList(changeList);
        setInValidList(changeInValidList);
    }

    //목록 추가
    const addList = (newList) => {
        // setList([ ...newList, ...list ]);
        validationList(list, newList);
    }

    // 벨리데이션 ( 중복 )
    const validationList = (list, newList) => {

        const uniqColumn = (sendType === "sms" ? "phone" : "email");
        const allList = [...newList, ...list];

        // ex) { 01055551111: 0, 01033332222: 0, ... }
        const uniqKeyObj = {};
        _.uniq(allList, uniqColumn)?.map((item) => item[uniqColumn])?.filter(item => item)?.forEach((item) => uniqKeyObj[item] = 0);
        // const emptyList = allList.filter(item => !item[uniqColumn]);

        const inValid = [];
        // const inValidEmpty = [];

        // 벨리데이션
        allList?.forEach((item, index) => {
            /* 
             * 1. 고유 인덱싱 ( 키 값 추가 )
             * 2. 중복 체크 & 필수 값 체크 
            **/

            // 고유 인덱싱 추가
            item.index = index;
            item.isChecked = false;

            const isExist = Object.keys(uniqKeyObj).includes(item[uniqColumn]);
            if (isExist) {
                if (uniqKeyObj[item[uniqColumn]] > 0) {
                    // 중복
                    item.err = uniqColumn;
                    inValid.push(item);
                } else {
                    uniqKeyObj[item[uniqColumn]] = uniqKeyObj[item[uniqColumn]] + 1;
                }
            } else {
                // 필수 값 부재
                item.err = uniqColumn;
                inValid.push(item);
            }

        })
        // console.log(allList);

        setList(allList || []);
        setInValidList(inValid || []);

        return inValid.length > 0 ? false : true;
    }

    const onTrashHandler = () => {

        if ((list.filter(item => item.isChecked)?.length || 0) === 0) {
            swal("삭제할 대상을 선택 후 진행바랍니다.");
            return;
        }

        setList(list.filter(item => !item.isChecked));
        validationList(list.filter(item => !item.isChecked), []);
    }

    //참가자 조회
    const onAddParticipant = () => {

    }
    const onSendClick = (isBack, fn) => {
        if (((list.filter(item => item.err)?.length > 0) || 0) > 0) {
            swal("발송대상 목록을 확인 후 진행바랍니다.");
            return;
        }

        if ((list?.length || 0) == 0) {
            swal("발송대상을 추가 후 진행바랍니다.");
            return;
        }

        switch (sendProc) {
            case "LIST": setSendProc("TEMPLATE");
                break;
            case "TEMPLATE": if (isBack) setSendProc("LIST"); else setSendProc("FINISH");
                break;
        }

        fn && fn();
    }

    const movePage = (page) => {
    };

    const onSearch = (e) => {
    };

    const initPage = () => {
        setKeyword("");
        // setSiteIdx("");
        // setEventIdx("");
        setIsEye(true);
        setIsCheckView(false);
        setSendProc("LIST");
    }

    useEffect(() => {
        loadEventList(cfg.idx);
    }, []);

    useEffect(() => {
        setValidList(list?.filter((item) => !item?.err) || []);
    }, [inValidList])

    // useEffect(() => {
    //     setAllCheck(false);
    // }, [list])

    // useEffect(() => {
    // }, [siteIdx,eventIdx])

    return (
        <div className="wrapper wrapper-content">
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-8">
                    <h2>발송 설정 {sendType === "sms" ? "(SMS)" : "(EMAIL)"}</h2>
                </div>
                <div className="col-sm-4">
                    <div className="title-action">
                        {sendProc === "TEMPLATE" &&
                            <button type="button" className="btn btn-default" onClick={onSendClick.bind(this)} style={{ marginRight: "5px" }}>
                                <i className="fa fa-arrow-left"></i> 이전
                            </button>
                        }
                        {sendProc !== "TEMPLATE" &&
                            <button type="button" className="btn btn-primary" onClick={onSendClick.bind(this)}>
                                <i className={`fa fa-arrow-right`}></i> {BTN_TYPE[sendProc]}
                            </button>
                        }
                    </div>
                </div>
            </div>

            <div className="row mt-3">

                {sendProc == "LIST" &&
                    <Fragment>
                        <div className="col-md-8">
                            <div className="ibox">
                                <div className="ibox-title">
                                    <h5>발송 목록</h5>
                                    <span className="label label-info">{list?.length || 0}</span>
                                    <a onClick={(e) => e.preventDefault()} style={{ marginLeft: "10px", cursor: "default" }}>( <i className="fa fa-circle text-navy"></i> 정상 <span className="label label-primary">{validList?.length || 0}</span></a>
                                    <a onClick={(e) => e.preventDefault()} style={{ marginLeft: "10px", cursor: "default" }}><i className="fa fa-circle text-danger"></i> 오류 <span className="label label-danger">{inValidList?.length || 0}</span> )</a>
                                    <a onClick={(e) => e.preventDefault()} style={{ marginLeft: "10px", cursor: "default" }}><i className="fa fa-circle text-warning"></i> 선택(<i className="fa fa-check"></i>)<span className="label label-warning"> {list.filter((item) => item.isChecked)?.length || 0}</span> )</a>
                                    <div className="hr-line-dashed"></div>
                                    <div className="mail-tools tooltip-demo m-t-md">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <SelectForm
                                                    title="이벤트"
                                                    name="eventIdx"
                                                    sendType={sendType}
                                                    items={eventList.map((event) => {
                                                        return { label: event.eventName, value: event.idx };
                                                    })}
                                                    value={eventIdx}
                                                    onChange={(e) => {
                                                        setEventIdx(e.target.value);
                                                    }}
                                                    nullTitle={`이벤트 선택`}
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <div className="ibox-tools" style={{ top: "8px" }}>
                                                    <span className="label label-info" onClick={(e) => loadParticipant()} style={{ marginRight: "3px", cursor: "pointer", fontSize: "13px" }} >
                                                        <i className="fa fa-folder-open"></i> 참가자 불러오기
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="hr-line-dashed" style={{ marginTop: "-6px" }}></div>
                                    <div className="mail-tools tooltip-demo m-t-md" style={{ marginTop: "-5px", marginBottom: "5px" }}>
                                        {/* <button className="btn btn-white btn-sm" data-toggle="tooltip" data-placement="left" title="" data-original-title="Refresh inbox"><i className="fa fa-refresh"></i> Refresh</button> */}
                                        {isEye ?
                                            <button className="btn btn-white btn-sm" onClick={(e) => setIsEye(false)}><i className="fa fa-eye"></i> </button>
                                            : <button className="btn btn-white btn-sm" onClick={(e) => setIsEye(true)}><i className="fa fa-eye-slash"></i> </button>
                                        }
                                        {!isCheckView ?
                                            <button className="btn btn-white btn-sm" onClick={(e) => setIsCheckView(true)} style={{ marginLeft: "5px" }}><i className="fa fa-check-square-o"></i> </button>
                                            : <button className="btn btn-white btn-sm" onClick={(e) => setIsCheckView(false)} style={{ marginLeft: "5px" }}><i className="fa fa-check-square"></i> </button>
                                        }
                                        {/* <button className="btn btn-white btn-sm" data-toggle="tooltip" data-placement="top" title="" data-original-title="Mark as important"><i className="fa fa-exclamation"></i> </button> */}
                                        <button className="btn btn-white btn-sm" onClick={(e) => { /* setInValidList([]); setList(validList); */ onTrashHandler(); }} style={{ marginLeft: "5px" }}><i className="fa fa-trash-o"></i> </button>
                                    </div>
                                </div>

                                <div className="ibox-content ibox-heading">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <SearchInput value={keyword} onChange={(e) => setKeyword(e.target.value)} onClick={onSearch} placeholder="검색어를 입력해 주세요." />
                                        </div>
                                    </div>
                                </div>

                                <div className="ibox-content" style={{ maxHeight: "295px", overflow: "auto" }}>
                                    <div className="table-responsive">
                                        <table className="table table-striped table-hover">
                                            <thead>
                                                <tr>
                                                    <th className="check-mail">
                                                        <input type="checkbox" onChange={(e) => setAllCheck(e.target.checked ? true : false)} className="i-checks" />
                                                    </th>
                                                    <th className="text-center">성명</th>
                                                    <th className="text-center">소속</th>
                                                    <th className="text-center">부서</th>
                                                    <th className="text-center">모바일</th>
                                                    <th className="text-center">이메일</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isEye && list?.filter((item, i) => { if (isCheckView) { return item.isChecked } else return true; })?.map((item, i) => (
                                                    <ListItem
                                                        {...item}
                                                        key={i}
                                                        sendType={sendType}
                                                        onModify={(data) => {
                                                            setData(data);
                                                        }}
                                                        refresh={() => {
                                                            loadList(paging?.pageNo || 1);
                                                        }}
                                                        keyword={keyword}
                                                        isCheckView={isCheckView}
                                                        setCheckList={setCheckList}
                                                        setList={setList}
                                                        checkHandler={checkHandler}
                                                    />
                                                ))}

                                                {!isEye && inValidList?.filter((item, i) => { if (isCheckView) { return item.isChecked } else return true; })?.map((item, i) => (
                                                    <ListItem
                                                        {...item}
                                                        key={i}
                                                        sendType={sendType}
                                                        onModify={(data) => {
                                                            setData(data);
                                                        }}
                                                        refresh={() => {
                                                            loadList(paging?.pageNo || 1);
                                                        }}
                                                        keyword={keyword}
                                                        isCheckView={isCheckView}
                                                        setCheckList={setCheckList}
                                                        setList={setInValidList}
                                                        checkHandler={checkHandler}
                                                    />
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {paging && (
                                    <div className="ibox-footer">
                                        <Paging paging={paging} pageCallback={movePage} />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="col-md-4">
                            <Form
                                {...props}
                                data={data}
                                callback={() => {
                                    loadList(paging?.pageNo || 1);
                                }}
                                list={list}
                                addList={addList}
                            />
                        </div>
                    </Fragment>
                }

                {sendProc === "TEMPLATE" &&
                    <div className="col-md-12">
                        <SendForm
                            {...props}
                            list={list}
                            callback={initPage}
                            sendType={sendType}
                        />
                    </div>
                }

                {sendProc === "FINISH" &&
                    <div className="col-md-12">
                        발송완료
                    </div>
                }

            </div>
        </div>
    );
};

const ListItem = (props) => {

    const [isChange, setIsChange] = useState("");

    const onChangeStatus = (status) => {
        const req = { path: "/v1/a/send/manage/template/status", data: { idx: props.idx, status } };
        _API.post(req).then((res) => {
            props.refresh && props.refresh();
        });
    };

    const onRemove = () => {
        swal({
            title: "정말 삭제 하시겠습니까?",
            text: "삭제된 데이터는 복구가 불가합니다 .",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                _API.post({ path: `/v1/a/send/manage/template/status`, data: { idx: props.idx, status: "D" } }).then((res) => {
                    swal({ title: "알림", text: `항목이 삭제되었습니다.` }).then(() => {
                        props.refresh && props.refresh();
                    });
                });
            }
        });
    };

    // useEffect(() => {
    //     console.log(props.isChecked)
    // }, [props.isChecked])

    const weights = [...Array(100).keys()];
    if (props.keyword) {
        if (
            String(props.name).indexOf(props.keyword) > -1 ||
            String(props.companyName).indexOf(props.keyword) > -1 ||
            String(props.deptName).indexOf(props.keyword) > -1 ||
            String(props.phone).indexOf(props.keyword) > -1 ||
            String(props.email).indexOf(props.keyword) > -1
        ) {
            return (
                <tr>
                    <td className="check-mail">
                        <input type="checkbox" className="i-checks" onChange={(e) => props.checkHandler(props.index, e.target.checked)} checked={props.isChecked ? true : false} />
                    </td>
                    <td className="text-center" style={props.err ? { backgroundColor: "red" } : {}}>{props.name}</td>
                    <td className="text-center" style={props.err ? { backgroundColor: "red" } : {}}>{props.companyName}</td>
                    <td className="text-center" style={props.err ? { backgroundColor: "red" } : {}}>{props.deptName}</td>
                    <td className="text-center" style={props.err ? { backgroundColor: "red" } : {}}>{props.phone}</td>
                    <td className="text-center" style={props.err ? { backgroundColor: "red" } : {}}>{props.email}</td>
                </tr>
            );
        } else {
            return "";
        }
    }

    return (
        <tr>
            <td className="check-mail">
                <input type="checkbox" className="i-checks" onChange={(e) => props.checkHandler(props.index, e.target.checked)} checked={props.isChecked ? true : false} />
            </td>
            <td className="text-center" style={props.err ? { backgroundColor: "red" } : {}}>{props.name}</td>
            <td className="text-center" style={props.err ? { backgroundColor: "red" } : {}}>{props.companyName}</td>
            <td className="text-center" style={props.err ? { backgroundColor: "red" } : {}}>{props.deptName}</td>
            <td className="text-center" style={props.err ? { backgroundColor: "red" } : {}}>{props.phone}</td>
            <td className="text-center" style={props.err ? { backgroundColor: "red" } : {}}>{props.email}</td>
        </tr>
    );
};

const Form = (props) => {
    const { callback, data, sendType, addList, list } = props;

    let contentRef = useRef();
    const [content, setContent] = useState("");

    const [form, setForm] = useState({
        status: "Y",
        TYPE: "NEW",
    });

    const [eventList, setEventList] = useState([]);

    const onAdd = () => {

        if (!form?.name) {
            swal("성명을 입력하세요.");
            return;
        }

        // if (!form?.companyName) {
        //     swal("소속을 입력하세요.");
        //     return;
        // }
        // if (!form?.deptName) {
        //     swal("부서를 입력하세요.");
        //     return;
        // }

        if (sendType === "sms" && !form?.phone) {
            swal("모바일을 입력하세요.");
            return;
        }

        if (sendType === "mail" && !form?.email) {
            swal("이메일을 입력하세요.");
            return;
        }

        if (sendType === "sms" && list?.filter((item) => item.phone == form.phone).length > 0) {
            swal("이미 등록된 전화번호입니다.");
            return;
        }

        if (sendType === "mail" && list?.filter((item) => item.email == form.email).length > 0) {
            swal("이미 등록된 전화번호입니다.");
            return;
        }

        addList && addList([form]);
    };

    const weights = [...Array(100).keys()];

    return (
        <div className="ibox">

            <div className="ibox-title">
                <h5>{form?.idx ? `수정` : `등록`}</h5>
                {/* <span className="label label-info">{list?.length || 0}</span> */}
                <div className="ibox-tools" style={{ marginTop: "-5px" }}>
                    <span className="btn btn-white btn-sm" onClick={(e) => setForm({ status: "Y", TYPE: "NEW", })} style={{ marginRight: "3px", cursor: "pointer" }} >
                        <i className="fa fa-refresh"></i> 초기화
                    </span>
                </div>
            </div>

            <div className="ibox-content">
                <InputForm title="성명" name="name" value={form?.name} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="성명 입력" />
                <InputForm title="모바일" name="phone" value={form?.phone} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="e.g 01012345555 모바일 입력 - 없이" />
                <InputForm title="이메일" name="email" value={form?.email} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="이메일 입력" />
                <InputForm title="소속" name="companyName" value={form?.companyName} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="소속 입력" />
                <InputForm title="부서" name="deptName" value={form?.deptName} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="부서 입력" />

                <div className="hr-line-dashed"></div>
                <div className="row">
                    <div className="col-md-12 text-right">
                        <button className="btn btn-primary" onClick={onAdd}>
                            <i className="fa fa-add"></i> 추가
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

const SendForm = (props) => {

    const { cfg } = useContext(AppContext);

    const { list, sendType, callback } = props;

    const [form, setForm] = useState({});
    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [eventIdx, setEventIdx] = useState("");
    const [eventName, setEventName] = useState("");
    const [eventList, setEventList] = useState([]);
    const [templateList, setTemplateList] = useState([]);

    const [sender, setSender] = useState(cfg[sendType === "sms" ? "phone" : "email"] || "");
    const [senderInfo, setSenderInfo] = useState(cfg || {});

    const [defaultSender, setDefaultSender] = useState(cfg[sendType === "sms" ? "phone" : "email"] || "");

    const [certYn, setCertYn] = useState(cfg[sendType === "sms" ? "phoneConfirm" : "emailConfirm"] || "Y");

    const [content, setContent] = useState("");
    let contentRef = useRef();

    const onTagClick = (e, tagName) => {
        e.preventDefault();

        setContent(`${content || ""}#{${tagName}}`);
        if (sendType === "mail") contentRef.summernote.focus();
        else contentRef.current.focus();
    }

    const loadEventList = (siteIdx) => {
        const data = {
            siteIdx,
        };

        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list } = res.data;
            setEventList(list);
        });
    };

    const loadList = (page) => {
        const data = {
            siteIdx,
            eventIdx,
            sendType,
        };

        const req = { path: "/v1/a/send/manage/template", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setTemplateList(list);
        });
    };

    const onSave = () => {

        if (!eventIdx) {
            swal("이벤트를 선택해주세요. ");
            return;
        }

        if (certYn !== "Y") {
            swal("인증되지 않은 발송자 입니다.");
            return;
        }

        if (!sender) {
            swal("발송자 정보가 없습니다.");
            return;
        }

        if (!form?.title) {
            swal("제목을 입력하세요.");
            return;
        }

        if (!form?.templeteIdx) {
            swal("양식을 선택해주세요.");
            return;
        }

        if (!content) {
            swal("양식을 입력해주세요.");
            return;
        }

        const req = { path: "/v1/a/send/manage/send", data: { siteIdx, eventIdx, ...form, list, sender, senderInfo, sendType, content, eventName } };
        _API.post(req).then((res) => {
            setForm({});
            setContent("");
            swal(`발송 되었습니다.`).then(() => {
                callback && callback();
            });
        });

    };

    const makeTo = (list) => {
        let str = "";
        str += "( 총 : " + (list?.length || 0) + " 명) ";
        str += list.length > 1 ? list[0].name + " 외 " + (list.length - 1) + " 명" : list[0].name;
        return str;
    }

    useEffect(() => {
        if (siteIdx && eventIdx) loadList();
    }, [eventIdx])

    useEffect(() => {
        loadEventList(cfg.idx);
    }, [])

    useEffect(() => {
        if (sendType === "mail") setCertYn("Y");
    }, [sendType])

    return (
        <div className="ibox">

            <div className="ibox-title">
                <h5>{`발송 템플릿 설정`}</h5>
                {/* <span className="label label-info">{list?.length || 0}</span> */}
                {/* <div className="ibox-tools" style={{marginTop: "5px"}}>
                        <span className="btn btn-white btn-sm" onClick={(e) => setForm({status: "Y",TYPE: "NEW",})} style={{ marginRight: "3px", cursor: "pointer" }} >
                            <i className="fa fa-refresh"></i> 초기화
                        </span>
                    </div> */}
            </div>

            <div className="ibox-content">

                <div className="row">

                    <label className="col-sm-3 control-label m-t-sm">발송자 : </label>
                    <div className="col-md-2">
                        <SelectForm
                            // title=" "
                            name="eventIdx"
                            sendType={sendType}
                            items={eventList.map((event) => {
                                return { label: event.eventName, value: event.idx };
                            })}
                            value={eventIdx}
                            onChange={(e) => {
                                setEventIdx(e.target.value);
                                setEventName(eventList.filter(item => item.idx == e.target.value)[0]["eventName"] || "");
                                setForm({ ...form, templeteIdx: "" })
                            }}
                            nullTitle={`이벤트 선택`}
                        />
                        {certYn === "" && <><p style={{ marginBottom: "0px" }}><i className="fa fa-question-circle" style={{ fontSize: "15px", color: "#eaba2e" }}></i>&nbsp;<code>사이트를 선택해주세요. </code></p> </>}
                        {certYn === "Y" && <><p style={{ marginBottom: "0px" }}><i className="fa fa-check-circle-o" style={{ fontSize: "15px", color: "blue" }}></i>&nbsp;<span className="badge badge-success" style={{ fontSize: "12px" }}> (인증) 발송 가능합니다.</span></p></>}
                        {certYn === "N" && <><p style={{ marginBottom: "0px" }}><i className="fa fa-ban" style={{ fontSize: "15px", color: "red" }}></i>&nbsp;<code> (미인증) 인증을 먼저 받아주세요. </code></p></>}
                    </div>
                </div>

                <div className="hr-line-dashed"></div>

                <InputForm title={`발송 ${sendType === "mail" ? "이메일" : "번호"}`} type={`${sendType === "mail" ? "email" : "number"}`} name="sender" value={sender} onChange={(e) => setSender(e.target.value)} readOnly={(sendType === "mail" && !defaultSender) ? false : true} placeholder="" />

                <div className="hr-line-dashed"></div>

                <div className="form-group row">
                    <label className="col-sm-3 control-label m-t-sm">To : </label>
                    <div className="col-sm-9" style={{ fontWeight: "bold", lineHeight: "35px" }}>{makeTo(list)}</div>
                </div>

                <InputForm title="제목 : " name="title" value={form?.title} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="제목을 입력해주세요." />

                <div className="hr-line-dashed"></div>

                <div className="row">
                    <label className="col-sm-3 control-label m-t-sm">템플릿(양식) : </label>
                    <div className="col-md-4">
                        <SelectForm
                            // title="템플릿(양식) : "
                            name="templeteIdx"
                            items={[{ label: "수동입력 양식", value: "-1" }, ...templateList.map((temp) => {
                                return { label: temp.title, value: temp.idx };
                            })]}
                            value={form.templeteIdx || ""}
                            onChange={(e) => {
                                setForm({ ...form, [e.target.name]: e.target.value });
                                if (e.target.value && e.target.value != -1) setContent(templateList.filter(temp => temp.idx == e.target.value)[0].content)
                            }}
                            nullTitle="템플릿(양식) [사이트 > 이벤트] 선택"
                        />
                    </div>
                </div>

                {form?.templeteIdx == -1 &&
                    <div className="form-group row">
                        <label className="col-sm-3 control-label m-t-sm">변수정보</label>
                        <div className="col-sm-9">
                            <p><code> 라벨을 선택하면 해당 정보가 양식에 추가됩니다. </code></p>
                            <ul className="tag-list" style={{ padding: "0" }}>
                                <li><a onClick={(e) => onTagClick(e, "name")}><i className="fa fa-tag"></i>참가자명</a></li>
                                <li><a onClick={(e) => onTagClick(e, "mobile")}><i className="fa fa-tag"></i>참가자 전화번호</a></li>
                                <li><a onClick={(e) => onTagClick(e, "email")}><i className="fa fa-tag"></i>참가자 이메일</a></li>
                                <li><a onClick={(e) => onTagClick(e, "companyName")}><i className="fa fa-tag"></i>참가자 소속</a></li>
                                <li><a onClick={(e) => onTagClick(e, "deptName")}><i className="fa fa-tag"></i>참가자 부서</a></li>
                                <li><a onClick={(e) => onTagClick(e, "eventName")}><i className="fa fa-tag"></i>이벤트명</a></li>
                                <li><a onClick={(e) => onTagClick(e, "url")}><i className="fa fa-tag"></i>사이트 URL</a></li>
                            </ul>
                        </div>

                    </div>
                }
                {sendType === "mail" && (form.templeteIdx == -1 || form.templeteIdx) &&
                    <div className="form-group row" id="editerDiv">
                        <label className="col-sm-3 control-label">발송양식</label>
                        <div className="col-sm-9">
                            <SNEditor content={content} onChange={(v) => setContent(v)} ref={(ref) => (contentRef = ref)} />
                        </div>
                    </div>
                }

                {sendType !== "mail" && (form.templeteIdx == -1 || form.templeteIdx) && <TextForm title="발송양식" value={content} onChange={(e) => setContent(e.target.value)} placeholder="양식을 선택해 주세요" ref={contentRef} />}

                {/* <TextForm title="설명" value={form?.description} onChange={(e) => setForm({...form , "description" :  e.target.value})} placeholder="내용을 입력해 주세요" /> */}

                <div className="hr-line-dashed"></div>

                <div className="row">
                    <div className="col-md-12 text-right">
                        <button className="btn btn-primary" onClick={onSave}>
                            <i className="fa fa-add"></i> 발송
                        </button>
                    </div>
                </div>

            </div>
        </div>
    );
};
