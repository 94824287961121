import React, { useContext, Fragment, useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import swal from "sweetalert";

import Layout from "./Layout";

import { AppContext } from "../../components/AppProvider";
import { _DEF } from "../../modules/config";
import { _API, _U } from "../../modules/utils";

import Wrapper, { Row, FormGroup, Separator } from "../../components/UI/Wrapper";
import { SearchInput, SelectForm, InputForm, SegmentedForm, SearchCondition, FileForm } from "../../components/UI/Form";
import SNEditor from "../../components/UI/SNEditor";

import Paging, { ToPage, GetParams } from "../../components/UI/Paging";

export default (props) => {

    const { history, location, match } = props;
    const cp = { history, location, match };

    let { bbsCategory } = useParams();

    const [mode, setMode] = useState("");

    const { cfg } = useContext(AppContext);

    const [siteList, setSiteList] = useState([]);
    const [eventList, setEventList] = useState([]);
    const [siteIdx, setSiteIdx] = useState(cfg.idx);

    // const loadSitetList = () => {
    //     const data = {};
    //     const req = { path: "/v1/a/site", data };
    //     _API.get(req).then((res) => {
    //         const { list, paging } = res.data;
    //         setSiteList(list);
    //     });
    // };

    const loadEventList = (page) => {
        const data = {
            siteIdx
        };
        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setEventList(list);
        });
    };

    const handleAddClick = () => {
        setMode("ADD");
    };

    const handleModifyClick = (idx) => {
        setMode(idx);
    };

    const handleDeleteClick = (idx) => {
        swal({
            title: "정말 삭제 하시겠습니까?",
            text: "삭제된 데이터는 복구가 불가합니다 .",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                _API.delete({ path: `/v1/a/board/${idx}` }).then((res) => {
                    swal({ title: "알림", text: `게시판이 삭제되었습니다.` }).then(() => {
                        _U.postMessage("NOTICE-CHANGED");
                    });
                });
            }
        });
    };

    const onBoardChange = () => {
        setMode("");
        _U.postMessage("NOTICE-CHANGED");
    };

    useEffect(() => {
        setMode("");
        // loadSitetList();
        loadEventList();
    }, [bbsCategory])

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-8">
                    <h2>{_DEF.getDefinedLabel(_DEF.BOARD_TYPE, bbsCategory.toUpperCase())}</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/admin">HOME</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>{_DEF.getDefinedLabel(_DEF.BOARD_TYPE, bbsCategory.toUpperCase())}</strong>
                        </li>
                    </ol>
                </div>
                <div className="col-sm-4">
                    {bbsCategory?.toUpperCase() !== "EMERGENCY" && (
                        <div className="title-action">
                            <button type="button" className="btn btn-primary" onClick={handleAddClick.bind(this)}>
                                <i className="fa fa-plus"></i> 등록
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className={cx({ "col-md-12": !mode, "col-md-8": mode })}>
                        <List {...cp} mode={mode}
                            listTitle={_DEF.getDefinedLabel(_DEF.BOARD_TYPE, bbsCategory.toUpperCase())}
                            handleModifyClick={handleModifyClick}
                            handleDeleteClick={handleDeleteClick}
                            bbsCategory={bbsCategory}
                            siteList={siteList}
                            eventList={eventList}
                            history={history}
                        />
                    </div>
                    {mode && (
                        <div className={cx({ "col-md-4": true })}>
                            <BoardForm idx={mode} onChange={onBoardChange} siteList={siteList} eventList={eventList} bbsCategory={bbsCategory} history={history} />
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
};


const List = (props) => {
    const { mode, listTitle, handleModifyClick, handleDeleteClick, bbsCategory, siteList, eventList, history } = props;

    const { cfg } = useContext(AppContext);

    const [keyword, setKeyword] = useState("");
    const [list, setList] = useState([]);
    const [paging, setPaging] = useState(null);
    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [eventIdx, setEventIdx] = useState(null);

    const loadList = (page, bbsCategory) => {
        _API.get({
            path: "/v1/a/board",
            data: {
                keyword,
                bbsCategory,
                page,
                siteIdx,
                eventIdx
            },
        }).then((res) => {
            const { paging, list } = res.data;
            setList(list);
            setPaging(paging);
        });
    };

    const onSearch = (e) => {
        loadList(paging?.pageNo || 1, bbsCategory);
    };

    const onChangeState = (item, status) => {
        if (bbsCategory?.toUpperCase() === "EMERGENCY") {
            alert("긴급공지는 수정이 불가 합니다.");
            return;
        }
        swal({
            title: `공지사항`,
            text: `게시글의 상태를 변경 하시겠습니까?`,
            icon: "info",
            dangerMode: status === "D" ? true : false,
            buttons: ["취소", "예"],
        }).then((ok) => {
            if (ok) {
                const req = { path: `/v1/a/board/${item.idx}/status`, data: { status } };
                _API.post(req).then((res) => {
                    // props.onChange && props.onChange();
                    onSearch();
                });
            }
        });

    };

    const onChangeNotice = (item, noticeYn) => {
        if (bbsCategory?.toUpperCase() === "EMERGENCY") {
            alert("긴급공지는 수정이 불가 합니다.");
            return;
        }
        swal({
            title: `${_DEF.getDefinedLabel(_DEF.BOARD_TYPE, bbsCategory.toUpperCase())}`,
            text: `공지사항${noticeYn === "Y" ? "으로 등록 하시겠습니까?" : "을 내리시겠습니까?"}`,
            icon: "info",
            buttons: ["취소", "예"],
            // dangerMode: true,
        }).then((ok) => {
            if (ok) {
                const req = { path: `/v1/a/board/${item.idx}/notice`, data: { noticeYn } };
                _API.post(req).then((res) => {
                    // props.onChange && props.onChange();
                    onSearch();
                });
            }
        });
    };

    const onReceivedMessage = (e) => {
        _U.parseMessage(e).then((data) => {
            switch (data.code) {
                case "NOTICE-CHANGED":
                    try {
                        const params = _U.decode(window.location.href.split("q=")[1]);
                        //GetParams(location);
                        if (params) {
                            setKeyword(params.keyword);
                            loadList(params?.page || 1, bbsCategory);
                        } else {
                            loadList(1, bbsCategory);
                        }
                    } catch (error) {
                        loadList(1, bbsCategory);
                    }
                    break;
            }
        });
    };

    useEffect(() => {
        loadList(1, bbsCategory);
    }, [eventIdx]);

    useEffect(() => {
        setKeyword("");
        setEventIdx(null);
        loadList(1, bbsCategory);
        window.addEventListener("message", onReceivedMessage);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };

    }, [bbsCategory]);

    let virtualNo = paging ? paging.virtualRecordNo : -1;

    const movePage = (page) => {
        ToPage({ pathname: "" }, history, { keyword, page, bbsCategory });
        loadList(page, bbsCategory);
    };

    const modifyClick = (idx) => {
        if (bbsCategory?.toUpperCase() === "EMERGENCY") {
            alert("긴급공지는 수정이 불가 합니다.");
            return;
        }
        handleModifyClick && handleModifyClick(idx);
    }

    return (
        <div className="ibox">
            <div className="ibox-title">{listTitle} 목록</div>
            <div className="ibox-content ibox-heading">
                <div className="row">
                    <div className={cx({ "col-md-8": !mode, "col-md-12": mode })}>
                        <SearchCondition
                            sync={true}
                            eventYn={"Y"}
                            onChange={(e) => {
                                setEventIdx(e.eventIdx);
                                setKeyword(e.keyword);
                            }}
                            onSearch={onSearch}
                            value={{ siteIdx, eventIdx, keyword }}
                            isRefresh={bbsCategory}
                        />
                    </div>
                </div>
            </div>
            <div className="ibox-content">
                <div className="table-responsive">
                    <table className="table table-striped table-hover">
                        <thead>
                            {bbsCategory?.toUpperCase() !== "EMERGENCY" ? (
                                <tr>
                                    <th className="text-center">번호</th>
                                    <th className="text-center">사이트명</th>
                                    <th className="text-center">이벤트명</th>
                                    <th className="text-center">제목</th>
                                    {!mode && (
                                        <Fragment>
                                            {/* <th className="text-center">코드</th> */}
                                            <th className="text-center">공지여부</th>
                                            <th className="text-center">상태</th>
                                            <th className="text-center">등록일자</th>
                                        </Fragment>
                                    )}
                                    <th className="text-center">관리</th>
                                </tr>
                            ) : (
                                <tr>
                                    <th className="text-center">번호</th>
                                    <th className="text-center">사이트명</th>
                                    <th className="text-center">이벤트명</th>
                                    <th className="text-center">내용</th>
                                    <th className="text-center">등록일자</th>
                                </tr>
                            )}
                        </thead>
                        {bbsCategory?.toUpperCase() !== "EMERGENCY" ? (
                            <tbody>
                                {list?.map((item, index) => (
                                    <tr key={index}>
                                        <td className="text-center">{item.noticeYn === "Y" ? <span className="label " style={{ backgroundColor: "#2f4050", color: "white" }}>공지</span> : virtualNo--}</td>
                                        <td className="text-center">{item.siteTitle}</td>
                                        <td className="text-center">{item.eventTitle}</td>
                                        <td className="text-center">{item.title}</td>
                                        {!mode && (
                                            <Fragment>
                                                {/* <td className="text-center">{item.bbsCode}</td> */}
                                                <td className="text-center">
                                                    <div className="btn-group">
                                                        <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.noticeYn !== "Y", "btn-info": item.noticeYn === "Y" })} onClick={onChangeNotice.bind(this, item, "Y")}>
                                                            <i className="fa fa-bullhorn"></i>공지
                                                        </button>
                                                        <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.noticeYn !== "N", "btn-info": item.noticeYn === "N" })} onClick={onChangeNotice.bind(this, item, "N")}>
                                                            일반
                                                        </button>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="btn-group">
                                                        {/* <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "W", "btn-success": item.status === "W" })} onClick={onChangeState.bind(this, item, "W")}>
                                                            대기
                                                        </button> */}
                                                        <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "Y", "btn-success": item.status === "Y" })} onClick={onChangeState.bind(this, item, "Y")}>
                                                            정상
                                                        </button>
                                                        <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "N", "btn-success": item.status === "N" })} onClick={onChangeState.bind(this, item, "N")}>
                                                            차단
                                                        </button>
                                                    </div>
                                                </td>
                                                <td className="text-center">{item.regDate && moment(item.regDate).format("YYYY-MM-DD")}</td>
                                            </Fragment>
                                        )}
                                        <td className="text-center">
                                            <div className="btn-group">
                                                <button type="button" className="btn btn-xs btn-warning" onClick={() => modifyClick(item.idx)}>
                                                    <i className="fa fa-edit"></i> 수정
                                                </button>
                                                <button type="button" className="btn btn-xs btn-danger" onClick={() => handleDeleteClick(item.idx)}>
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </div>
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        ) : (
                            <tbody>
                                {list?.map((item, index) => (
                                    <tr key={index}>
                                        <td className="text-center">{item.noticeYn === "Y" ? <span className="label " style={{ backgroundColor: "#2f4050", color: "white" }}>공지</span> : virtualNo--}</td>
                                        <td className="text-center">{item.siteTitle}</td>
                                        <td className="text-center">{item.eventTitle}</td>
                                        <td className="text-center"><div dangerouslySetInnerHTML={{ __html: item.content }} /></td>
                                        {/* {!mode && (
                                            <Fragment>
                                                <td className="text-center">
                                                    <div className="btn-group">
                                                        <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.noticeYn !== "Y", "btn-info": item.noticeYn === "Y" })} onClick={onChangeNotice.bind(this, item, "Y")}>
                                                            <i className="fa fa-bullhorn"></i>공지
                                                        </button>
                                                        <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.noticeYn !== "N", "btn-info": item.noticeYn === "N" })} onClick={onChangeNotice.bind(this, item, "N")}>
                                                            일반
                                                        </button>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="btn-group">
                                                        <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "Y", "btn-success": item.status === "Y" })} onClick={onChangeState.bind(this, item, "Y")}>
                                                            정상
                                                        </button>
                                                        <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "N", "btn-success": item.status === "N" })} onClick={onChangeState.bind(this, item, "N")}>
                                                            차단
                                                        </button>
                                                    </div>
                                                </td>
                                            </Fragment>
                                        )} */}
                                        <td className="text-center">{item.regDate && moment(item.regDate).format("YYYY-MM-DD")}</td>
                                        {/* <td className="text-center">
                                            <div className="btn-group">
                                                <button type="button" className="btn btn-xs btn-danger" onClick={() => handleDeleteClick(item.idx)}>
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </div>
                                        </td> */}

                                    </tr>
                                ))}
                            </tbody>
                        )}
                    </table>
                </div>
            </div>
            {paging && (
                <div className="ibox-footer">
                    <Paging paging={paging} pageCallback={movePage} />
                </div>
            )}
        </div>
    );
};

const BoardForm = (props) => {
    const { idx, onChange, siteList, eventList, bbsCategory } = props;
    const { iam, cfg } = useContext(AppContext);

    const [form, setForm] = useState({});

    const [answer, setAnswer] = useState("");
    const [content, setContent] = useState("");

    const [siteIdx, setSiteIdx] = useState(cfg.idx);

    const onSave = () => {
        const data = { ...form, answer: content, content, bbsCategory };
        if (idx !== "ADD") {
            data.idx = idx;
        }
        if (!data.eventIdx) {
            swal({ title: "알림", text: "이벤트를 선택해주세요." });
            return;
        }
        // if (!data.noticeYn) {
        //     swal({ title: "알림", text: "공지여부 선택해 주세요." });
        //     return;
        // }
        if (!data.title) {
            swal({ title: "알림", text: "제목을 입력해 주세요." });
            return;
        }
        // if (!data.status) {
        //     swal({ title: "알림", text: "상태를 선택해 주세요." });
        //     return;
        // }
        if (!data.siteIdx && siteIdx) {
            data.siteIdx = siteIdx;
        }
        _API.post({
            path: `/v1/a/board/${data.idx || "new"}`,
            data: data,
        }).then((res) => {
            swal({ title: "알림", text: `게시판이 ${idx === "ADD" ? "등록" : "수정"} 되었습니다.` }).then(() => {
                onChange && onChange();
            });
        });
    };

    const onEventChange = (item, e) => {
        const targetItem = item.filter((target, index) => target.idx == e.target.value)[0];
        if (targetItem) {
            setForm({ ...form, siteIdx, [e.target.name]: e.target.value });
        } else {
            setForm({ ...form, siteIdx, [e.target.name]: "" });
        }
    }

    const loadData = () => {
        if (idx === "ADD") {
            setForm({});
            setAnswer("");
            setContent("");
            return;
        }
        const req = {
            path: `/v1/a/board/${idx}`,
        };
        _API.get(req).then((res) => {
            setForm({ ...res.data });
            setContent(res.data.content);
            setAnswer(res.data.answer);
        });
    };

    useEffect(() => {
        loadData();
    }, [idx, bbsCategory]);

    const fileUpload = (name, item) => {
        setForm({ ...form, [name]: item.id, "bbsFileSignedUrl": item.signedUrl, "bbsFileName": item.name });
    };

    return (
        <div className="ibox">
            <div className="ibox-title">게시판 {idx === "ADD" ? "등록" : "수정"}</div>
            <div className="ibox-content">
                {/* <SelectForm title="사이트" name="siteIdx" items={siteList.map( (site) => { return { label : site.title, value : site.idx} } )} value={form?.siteIdx} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} nullTitle="사이트 선택" readOnly /> */}
                <SelectForm title="이벤트" name="eventIdx" items={eventList.map((event) => { return { label: event.eventName, value: event.idx } })} value={form?.eventIdx} onChange={(e) => { onEventChange(eventList, e); }} nullTitle="이벤트 선택" />
                <Separator />
                <SegmentedForm title="공지여부" items={_DEF.NOTICE_YN} value={form?.noticeYn} onClick={(v) => setForm({ ...form, noticeYn: v })} />
                <Separator />
                <InputForm title="제목" name="title" value={form?.title} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="이름을 입력해 주세요" />
                <Separator />
                <h4>내용</h4>
                <SNEditor content={content} onChange={(c) => setContent(c)} height="200" />
                <Separator />
                {bbsCategory === "faq" &&
                    <Fragment>
                        <h4>답변</h4>
                        <SNEditor content={answer} onChange={(c) => setAnswer(c)} height="200" />
                        <Separator />
                    </Fragment>
                }
                {bbsCategory === "notice" &&
                    <FileForm title="첨부파일" onChange={(v) => fileUpload("fileIdx", v)} path={`BBS/NOTICE/${moment().format("YYYY/MM/DD")}`} url={form?.bbsFileSignedUrl} fileName={form?.bbsFileName} />
                }
                {/* <SegmentedForm title="상태" items={_DEF.BOARD_STATUS} value={form?.status} onClick={(v) => setForm({ ...form, status: v })} /> */}
                <Separator />
                <div className="row">
                    <div className="col-md-12 text-right">
                        <div className="btn-group">
                            <button type="button" className="btn btn-primary" onClick={onSave}>
                                <i className="fa fa-save"></i> {idx === "ADD" ? "등록" : "수정"}
                            </button>
                            <button type="button" className="btn btn-default" onClick={onChange}>
                                <i className="fa fa-times"></i> 취소
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
