import React, { Fragment, useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import swal from "sweetalert";

import Modal from "react-modal";
import { ModalCloseButton } from "../../../components/UI/Button";
import { Theme } from "../../../components/GlobalStyles";
import Template from "../../../components/UI/Template";
import Send from "../../../components/UI/Send";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";
import { _DEF, _CFG } from "../../../modules/config";

import Layout from "../Layout";

import { SearchInput, SelectForm, InputForm, SegmentedForm } from "../../../components/UI/Form";
import { Separator } from "../../../components/UI/Wrapper";

import { List } from "./mail.list";

export default (props) => {
    const [isShow, setIsShow] = useState(true);

    const [mode, setMode] = useState("");

    const onRefresh = () => {
        setIsShow(false);
        setTimeout(() => setIsShow(true), 10);
    };

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-8">
                    <h2>발송관리</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/">HOME</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/send/manage/mail">발송관리</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>발송내역</strong>
                        </li>
                    </ol>
                </div>
                <div className="col-sm-4">
                    <div className="title-action">
                        <button
                            type="button"
                            className="btn btn-warning"
                            onClick={(e) => {
                                setMode("TEMPLATE");
                            }}
                        >
                            <i className="fa fa-gear"></i> 템플릿 설정
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary ml-2"
                            onClick={(e) => {
                                setMode("SEND");
                            }}
                        >
                            <i className="fa fa-plus"></i> 발송
                        </button>
                    </div>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className="col-md-12">
                        <List />
                    </div>
                    {/* <div className="col-md-4">
                        <Request sendType="mail" />
                    </div> */}
                </div>
            </div>
            {mode === "TEMPLATE" && (
                <Modal isOpen={mode == "TEMPLATE"} style={Theme.modal.template}>
                    <ModalCloseButton
                        className="btn btn-default"
                        onClick={() => {
                            setMode("");
                        }}
                    >
                        <i className="fa fa-remove"></i>
                    </ModalCloseButton>
                    <Template sendType="mail" />
                </Modal>
            )}
            {mode === "SEND" && (
                <Modal isOpen={mode == "SEND"} style={Theme.modal.template}>
                    <ModalCloseButton
                        className="btn btn-default"
                        onClick={() => {
                            setMode("");
                        }}
                    >
                        <i className="fa fa-remove"></i>
                    </ModalCloseButton>
                    <Send sendType="mail" />
                </Modal>
            )}
        </Layout>
    );
};

const CertList = (props) => {
    const [keyword, setKeyword] = useState("");
    const [list, setList] = useState([]);
    const [nextId, setNextId] = useState(null);

    const loadPage = (id) => {
        const req = { path: "https://api.dm9.io/v1/email/cert", data: { nextId: id, keyword }, apiKey: _CFG.dm9ApiKey, };
        _API.get(req)
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                const { list, nextId } = data;
                if (list.length === 0) {
                    swal({ title: "알림", text: "모든 정보를 조회 하였습니다." });
                } else {
                    if (!id) {
                        setList(list);
                    } else {
                        setList((o) => [...o, ...list]);
                    }
                }
                setNextId(nextId);
            });
    };

    const onNext = () => {
        if (!nextId) {
            return;
        }
        loadPage(nextId);
    };

    const onSearch = (e) => {
        setList([]);
        setNextId(null);
        loadPage();
    };

    const onReceivedMessage = (e) => {
        _U.parseMessage(e).then((data) => {
            switch (data.code) {
                case "EMAIL-CERT-CHANGED":
                    setList([]);
                    setNextId(null);
                    loadPage();
                    break;
            }
        });
    };

    useEffect(() => {
        loadPage();
        window.addEventListener("message", onReceivedMessage);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, []);

    return (
        <div className="ibox">
            <div className="ibox-title">발신이메일 인증</div>
            <div className="ibox-content ibox-heading">
                <div className="row">
                    <div className="col-md-6">
                        <SearchInput value={keyword} onChange={(e) => setKeyword(e.target.value)} onClick={onSearch} placeholder="검색어를 입력해 주세요." />
                    </div>
                </div>
            </div>
            <div className="ibox-content">
                <div className="table-responsive">
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th className="text-center">No.</th>
                                <th className="text-center">제목</th>
                                <th className="text-center">이메일</th>
                                <th className="text-center">등록일</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((item, i) => (
                                <tr key={i}>
                                    <td className="text-center">{i + 1}</td>
                                    <td className="text-center">{item.title}</td>
                                    <td className="text-center">{item.id}</td>
                                    <td className="text-center">{moment(item.regDate, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {nextId && (
                <div className="ibox-footer">
                    <button type="button" className="btn btn-block btn-default" onClick={onNext}>
                        <i className="fa fa-arrow-down"></i> 추가 조회
                    </button>
                </div>
            )}
        </div>
    );
};

const Request = (props) => {
    const { sendType } = props;

    const [isRequest, setIsRequest] = useState(false);
    const [sender, setSender] = useState("");
    const [requestId, setRequestId] = useState("");
    const [certYn, setCertYn] = useState("");
    
    const [comment, setComment] = useState("");

    const [siteIdx, setSiteIdx] = useState("");
    const [siteList, setSiteList] = useState([]);

    const [defaultSender, setDefaultSender] = useState("");

    const loadSiteList = () => {
        const data = {};
        const req = { path: "/v1/sa/site", data };
        _API.get(req).then((res) => {
            const { list } = res.data;
            setSiteList(list);
        });
    };

    const handleRequest = () => {
        try {
            if (!siteIdx) {
                throw { message: "사이트를 선택해주세요." };
            }
            if (!sender) {
                throw { message: "이메일을 입력해 주세요." };
            }
            if (!_U.isEmail(sender)) {
                throw { message: "올바른 형식의 이메일을 입력해 주세요." };
            }
            _API.post({
                path: "https://api.dm9.io/v1/email/cert/request",
                data: {
                    email : sender,
                }
                , apiKey: _CFG.dm9ApiKey,
            })
                .then((res) => res.data)
                .then((data) => {
                    setRequestId(data);
                    setIsRequest(true);
                });
        } catch (e) {
            swal({ title: "알림", text: e.message });
        }
    };

    const handleCert = () => {
        _API.post({
            path: "https://api.dm9.io/v1/email/cert/complete",
            data: {
                title : comment,
                email : sender,
                requestId,
            },
            apiKey: _CFG.dm9ApiKey,
        }).then((res) => {
            setIsRequest(false);

            setComment("");
            // setSender("");
            
            swal("인증되었습니다.");
            setCertYn("Y");

            saverCert("Y");
        });
    };

    const saverCert = (yN) => {
        
        _API.post({
            path: "/v1/sa/send/manage/cert",
            data: {
                siteIdx,
                [sendType === "sms" ? "phone" : "email"] : sender,
                [sendType === "sms" ? "phoneConfirm" : "emailConfirm"] : yN,
                sendType
            },
        }).then((res) => {
            const { status } = res;
            _U.postMessage("EMAIL-CERT-CHANGED");
        });
    }

    useEffect(() => {
        loadSiteList();
    }, [])

    return (
        <div className="ibox">
            <div className="ibox-title">발신메일 인증</div>
            <div className="ibox-content">
                <SelectForm
                    title="사이트"
                    name="siteIdx"
                    items={siteList.map((site) => {
                        return { label: site.title, value: site.idx };
                    })}
                    value={siteIdx}
                    onChange={(e) => {
                        setSiteIdx(e.target.value);
                        setIsRequest(false);
                        if( e.target.value ){
                            setComment(siteList.filter(item => item.idx == e.target.value)[0]?.title || "");
                            setSender(siteList.filter(item => item.idx == e.target.value)[0][sendType === "sms" ? "phone" : "email"] || "");
                            setDefaultSender(siteList.filter(item => item.idx == e.target.value)[0][sendType === "sms" ? "phone" : "email"] || "");
                            setCertYn(siteList.filter(item => item.idx == e.target.value)[0][sendType === "sms" ? "phoneConfirm" : "emailConfirm"] || "N");
                        } else {
                            setComment("");
                            setSender("");
                            setCertYn("");
                            setDefaultSender("");
                        }
                    }}
                    nullTitle="사이트 선택"
                />
                <InputForm title="이메일" type="email" name="sender" value={sender} onChange={(e) => setSender(e.target.value)} readOnly={ defaultSender ? true : false } placeholder="이메일을 입력해 주세요" />
                <div className="form-group row">
                    <label className="col-sm-3 control-label m-t-sm">
                        { !isRequest ? 
                            "인증여부 " 
                        : "" 
                        }
                        { !isRequest && certYn === ""  && <i className="fa fa-question-circle" style={{fontSize: "15px", color: "#eaba2e"}}></i> }
                        { !isRequest && certYn === "Y" && <i className="fa fa-check-circle-o" style={{fontSize: "15px", color: "blue"}}></i> }
                        { !isRequest && certYn === "N" && <i className="fa fa-ban" style={{ fontSize: "15px", color: "red" }}></i> }
                    </label>

                    {!siteIdx && <div className="col-sm-9"><p style={{marginBottom: "0px", paddingTop: "10px"}}><code> 사이트를 선택해주세요. </code></p></div>}

                    {siteIdx && 
                        (
                            <Fragment>
                                {/* <SegmentedForm title="상태" items={_DEF.SMS_CERT_TYPE} value={type} onClick={(v) => setType(v)} /> */}
                                {/* <Separator /> */}
                                {!isRequest && (
                                    <div className="col-md-9">
                                        { certYn != "Y" && (
                                            <button type="button" className="btn btn-block btn-primary" onClick={handleRequest}>
                                                <i className="fa fa-send"></i> 요청
                                            </button>
                                        )}
                                        { certYn === "Y" && (
                                            <span className="badge badge-success" style={{ marginTop: "9px", fontSize: "14px"}}>인증된 메일입니다.</span>
                                        )}
                                    </div>
                                )}
                                {isRequest && (
                                        <div className="col-md-9">
                                            <button type="button" className="btn btn-block btn-primary" onClick={handleRequest}>
                                                <i className="fa fa-send"></i> 재요청
                                            </button>
                                        </div>
                                )}
                            </Fragment>
                        )
                    }

                </div>

                {isRequest && (
                    <div className="row">
                        <label className="col-sm-3 control-label m-t-sm">&nbsp;</label>
                        <div className="col-md-9">
                            <button type="button" className="btn btn-block btn-success" onClick={handleCert}>
                                <i className="fa fa-check"></i> 인증
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
