import React, { Fragment, useEffect, useContext, useState } from "react";
import styled from "styled-components";
import moment from "moment";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";

import { Content, SelectForm, ContentFiles } from "./Components";

export default (props) => {
    const { cfg } = useContext(AppContext);

    const { idx: siteIdx } = cfg;
    const { eventIdx: siteEventIdx } = cfg.event;

    const [date, setDate] = useState([]);
    const [channelList, setChannelList] = useState([]);
    const [channel, setChannel] = useState("");

    const loadData = () => {
        _API.get({path:"/v1/site/event/sessions", data:{
            siteIdx, siteEventIdx
        }}).then(res=>{
            const { channelList } = res.data;
            setChannelList(res.data.channelList);
        })
    }

    const getPrincipal = (session) => {
        const { sessionMgrList } = session;
        return session.sessionMgrList.map((mgr)=> ( `${mgr.lastName} ${mgr.firstName}(${mgr.companyName})` )).join(", ");
    }

    const isLectureExsist = (session) => {
        const { lectureList } = session;
        return lectureList.length > 0;
    }

    useEffect(() => {
        _U.postMessage("LOCATION-CHANGED");
        loadData()
    }, []);

    return (
        <Fragment>
            <ChannelTitle><h2>{channelList?.filter((c)=> c.idx == channel)[0]?.title || ""}</h2></ChannelTitle>
            <SelectForm title="" name="channel" nullTitle="채널을 선택해주세요." items={channelList.map((c)=>({label:c.title, value:c.idx}))} value={channel} onChange={(e) => {setChannel(e.target.value)}} style={selectStyle} />
            <Content code="agenda" />
            {channelList.length > 0 && channelList.filter((c)=> c.idx == channel)[0]?.sessionList?.map((item, i)=> {
                return isLectureExsist(item) &&
                (
                    <Wrapper key={i}>
                        <Date>
                            {item.startDate && moment(item.startDate).format("YYYY-MM-DD")} ~ {item.endDate && moment(item.endDate).format("YYYY-MM-DD")} 
                            {/* <span> - {item.title}</span> */}
                            <Principal dangerouslySetInnerHTML={{__html: getPrincipal(item)}} />
                            <ContentFiles>
                                {item.files?.map((file, i) => (
                                    <li key={i}>
                                        <a href={file.signedUrl} target="_blank">
                                            <i className="fa fa-window-restore"></i>&nbsp;{file.name}
                                        </a>
                                    </li>
                                ))}
                            </ContentFiles>
                        </Date>
                        {item.lectureList.map((s, j)=>(
                            <Session key={j}>
                                <Time>
                                    {moment(s.startTime, "HH:mm:ss").format("HH:mm")} ~ {moment(s.endTime, "HH:mm:ss").format("HH:mm")}
                                </Time>
                                <Info>
                                    <Title>
                                        {s.title} /
                                    </Title>
                                    {s.lectureMgrList?.length > 0 && s.lectureMgrList?.map((mgr,i)=> (
                                        <Speaker key={i}>
                                            연자 : <span dangerouslySetInnerHTML={{__html:`${mgr.lastName} ${mgr.firstName}(${mgr.companyName})`}}/>
                                        </Speaker>
                                    ))}
                                    {s.location && (
                                        <Location>
                                            장소 : {s.location}
                                        </Location>
                                    )}
                                    
                                </Info>
                            </Session>
                        ))}
                    </Wrapper>
                )
            })}
        </Fragment>
    );
};

const Wrapper = styled.div`
    margin:1rem 0 0;
`;
const Date = styled.h3`
    padding-bottom:0.5rem;
    font-size:1rem;
    font-weight:bold;
    border-bottom:#cccccc solid 1px;
`
const Principal = styled.small`
    font-weight:normal;
    font-size:0.8rem;
    float:right;
    clear:right;
`
const Session = styled.div`
    margin:0.7rem 0;
    padding-bottom:0.5rem;
    font-weight:normal;
    font-size:0.9rem;
    display:flex;
    border-bottom:#cccccc solid 1px;
`
const Time = styled.span`
    flex-basis:8rem;
    
`;
const Info = styled.div`
    flex:1;
    span {
        display:block;
    }
`
const Title = styled.span`
`;
const ChannelTitle = styled.div`
    h2 {
        font-size: 28px;
        font-weight: 600;
        padding-top: 10px;
        padding-bottom: 15px;
    }
`;
const Speaker = styled.span`
`;
const Location = styled.span`
    text-align:right;

    @media only screen and (max-width: 768px) {
        display:none !important;
    }
`;

const selectStyle = {
      padding: 0
    , margin: 0
    , border: "1px solid #ccc"
    , width: "120px"
    , borderRadius: "3px"
    , overflow: "hidden"
    , backgroundColor: "#fff"
    , background: "#fff"
    , position: "relative"
    , ":after" : {
        top: "50%",
        left: "85%",
        border: "solid transparent",
        content: " ",
        height: 0,
        width: 0,
        position: "absolute",
        pointerEvents: "none",
        borderColor: "rgba(0, 0, 0, 0)",
        borderTopColor: "#000000",
        borderWidth: "5px",
        marginTop: "-2px",
        zIndex: "100",
    }
}

