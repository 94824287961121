import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import moment from "moment";
import Modal from "react-modal";
import { Theme } from "../../../components/GlobalStyles";

import { isMobile } from "react-device-detect";

import { AppContext } from "../../../components/AppProvider";
import { _U, _CK, _API } from "../../../modules/utils";

import { ButtonSubmitSmall, ButtonExitSmall, ButtonDefaultSmall, H3 } from "../basic/UI/Components";
import { Loading } from "../../../components/UI/Loading";
import { ICFile, ICFileSet, ICLogout, ICLogoutSet, NoImg, NoImgSet, ICSellect, ICSellectSet, ICExit, ICExitSet, getBgSet, } from "./asset";

import Video from "../../../components/UI/Video";

import * as _ from "lodash";

export default (props) => {
    const { auth, cfg, socketConnect } = useContext(AppContext);
    const { history, location, match } = props;
    const { channelIdx, sessionIdx, lectureIdx } = match.params;
    const { idx: siteIdx, event } = cfg;
    const { eventIdx } = event;

    const [isLoadSession, setIsLoadSession] = useState(false);
    const [channel, setChannel] = useState({});
    const [session, setSession] = useState({});
    const [lecture, setLecture] = useState({});
    const [mgrList, setMgrList] = useState([]);

    const [isAlertShow, setIsAlertShow] = useState(false);
    const [channelAlert, setChannelAlert] = useState(false);
    const [channelAlertCnt, setChannelAlertCnt] = useState(0);

    const loadSessions = () => {
        setIsLoadSession(false);
        _API.get({
            path: "/v1/site/event/console/info",
            data: {
                siteIdx,
                eventIdx,
                channelIdx,
            },
        }).then((res) => {
            const { channelInfo, sessionList, mgrList } = res.data;
            setChannel(channelInfo);
            if (sessionList) {
                const s = sessionList.filter((item) => item.idx == sessionIdx)[0];
                setSession(s);
                const l = s?.lectureList?.filter((item) => item.idx == lectureIdx)[0];
                setLecture(l);
            }
            if (mgrList) {
                const newMgrList = mgrList.filter((item) => item.sessionIdx == sessionIdx || item.lectureIdx == lectureIdx);
                setMgrList(newMgrList);
            }
            if (channelInfo?.channelAlert) {
                setIsAlertShow(true);
                setChannelAlert(channelInfo.channelAlert);
            } else {
                setIsAlertShow(false);
                setChannelAlert("");
            }
            setIsLoadSession(true);
        });
    };

    const sendAlive = (isClose) => {
        if (isClose) {
            window.self.close();
        }
    };

    useEffect(() => {
        loadSessions();
        onResizeWindow();
    }, [channelIdx, sessionIdx, lectureIdx]);

    useEffect(() => {
        if (!isAlertShow && isLoadSession) {
            setChannelAlertCnt(1);
        }
    }, [isAlertShow]);


    const [layerSize, setLayerSize] = useState({});
    const onResizeWindow = () => {
        // console.log("window.size", window.innerWidth, window.innerHeight);
        let leftWidth = (window.innerWidth / 7) * 5;
        let rightWidth = (window.innerWidth / 7) * 2;
        let videoHeight = window.innerHeight - 198;
        let videoWidth = (videoHeight / 9) * 16;
        let padding = 0;

        // console.log("videoWidth ori", videoWidth, "vodHeight ori", videoHeight);
        if (videoWidth > leftWidth) {
            videoWidth = leftWidth;
            videoHeight = (videoWidth / 16) * 9;
        }

        padding = (leftWidth - videoWidth) / 2;

        setLayerSize({
            videoWidth,
            videoHeight,
            padding,
        });

        // console.log("left", leftWidth, "right", rightWidth);
        // console.log("videoWidth", videoWidth, "vodHeight", videoHeight);
        // console.log("padding", padding);
    }

    if (!isLoadSession) {
        return <Loading title="강좌를 조회하고 있습니다." background="rgba(0, 0, 0, 0.1)" />;
    }

    return (
        <Wrapper>
            <Header channel={channel} onExit={sendAlive} />
            <Container>
                <Lefts channel={channel} siteIdx={siteIdx} eventIdx={eventIdx} lecture={lecture} layerSize={layerSize} />
                <Rights channel={channel} session={session} mgrList={mgrList} lecture={lecture} layerSize={layerSize} />
            </Container>

            {isMobile && <CompayLogoAutoSlider siteIdx={siteIdx} eventIdx={eventIdx} />}
            {isAlertShow && channelAlert && channelAlertCnt == 0 && (
                <Modal isOpen={channelAlert ? true : false} ariaHideApp={false} style={isMobile ? Theme.basic.modal.registrationCheck.mobile : Theme.basic.modal.registrationCheck.pc} >
                    <div
                        dangerouslySetInnerHTML={{
                            __html: channelAlert,
                        }}
                        style={{ maxHeight: "400px", minHeight: "280px" }}
                    ></div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <button className="btn btn-success" onClick={() => setIsAlertShow(false)} >위 내용을 확인하였고 동의합니다.</button>
                    </div>
                </Modal>
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        overflow: unset;
        height: 100%;
    }
`;

const Container = styled.div`
    width: 100%;
    /* height: 100%; */
    height: calc(100% - 90px);
    display: flex;
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        display: block;
        height: 100%;
    }
`;

const Waiting = styled.div`
    margin: 10rem 0rem;
    text-align: center;
    width: 100%;
    font-family: ${(props) => props.theme.fontFamily.medium};
    color: ${(props) => props.theme.basic.color.primary};
    font-size: 2rem;
`;

const WaitingInConsole = styled.div`
    margin: 1rem 0rem;
    text-align: center;
    width: 100%;
    font-family: ${(props) => props.theme.fontFamily.medium};
    color: ${(props) => props.theme.basic.color.primary};
    font-size: 2rem;
`;

const Header = (props) => {
    const { channel, onExit } = props;
    const { cfg } = useContext(AppContext);
    const event = cfg.event || null;

    const onExitClick = () => {
        swal({
            title: "현재 강의 에서 퇴장하시겠습니까?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((result) => {
            if (result) {
                onExit & onExit(true);
            }
        });
    };

    return (
        <HeaderWrapper>
            {!isMobile && (
                <LogoWrapper>
                    <LogoContainer>
                        {event?.logoTopPcUrl && (
                            <Logo
                                to={"/"}
                                onClick={() => {
                                    window.location.href = "/";
                                }}
                            >
                                <img src={event?.logoTopPcUrl} />
                            </Logo>
                        )}
                    </LogoContainer>
                </LogoWrapper>
            )}
            <MenuWrapper>
                <div>
                    <CDescription>{channel?.title}</CDescription>
                    <CDate>
                        {channel?.progressDate && moment(channel?.progressDate).format("YYYY.MM.DD(ddd) ")}
                    </CDate>
                </div>
            </MenuWrapper>
            <My>{isMobile ? <MLogout onClick={onExitClick.bind(this)} /> : <ButtonExitSmall onClick={onExitClick.bind(this)}>퇴장하기 <ExitSpan /></ButtonExitSmall>}</My>
            {/* <My>{isMobile && <MLogout onClick={onExitClick.bind(this)} />}</My> */}
        </HeaderWrapper>
    );
};

const HeaderWrapper = styled.div`
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${(props) => props.theme.basic.line.header};

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        display: block;
        position: relative;
        height: auto;
        min-height: 60px;
    }
`;

const LogoWrapper = styled.div`
    flex: 1;
    flex-grow: 1;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        width: 100%;
        text-align: center;
    }
`;

const LogoContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Logo = styled(Link)`
    img {
        min-width: 144px;
        max-width: 144px;
        min-height: 30px;
        max-height: 90px;
    }
`;

const MenuWrapper = styled.div`
    flex: 1;
    flex-grow: 7;
    display: flex;
    /* padding-top: 10px; */
    align-items: center;
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        padding: 10px;
    }
`;

const CTitle = styled.div`
    font-size: 12px;
    font-family: ${(props) => props.theme.fontFamily.regular};
    color: ${(props) => props.theme.basic.color.primary};
`;
const CDescription = styled.div`
    font-size: 18px;
    font-family: ${(props) => props.theme.fontFamily.regular};
    color: ${(props) => props.theme.basic.color.primary};

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        font-size: 16px;
    }
`;
const CDate = styled.div`
    font-size: 11px;
    font-family: ${(props) => props.theme.fontFamily.medium};
    color: ${(props) => props.theme.basic.color.date};
`;

const My = styled.div`
    flex: 1;
    flex-grow: 1;
    font-family: ${(props) => props.theme.fontFamily.regular};
    color: ${(props) => props.theme.basic.color.primary};
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        position: absolute;
        right: 0px;
        top: 0px;
    }
`;

const Lefts = (props) => {
    const { channel, siteIdx, eventIdx, lecture, layerSize } = props;
    const { cfg } = useContext(AppContext);
    const { event } = cfg;
    const videoNode = useRef(null);

    const getUrl = () => {
        if (!channel) {
            return;
        }

        return lecture?.lectureReplayEncodedUrl || lecture?.lectureReplaySignedUrl;
    };

    const getThumbnailUrl = () => {
        if (!channel) {
            return;
        }
        return lecture?.lectureReplayThumbnailUrl;
    };

    const checkVideoTime = () => {
        if (isMobile) console.log("mobile", videoNode.current.currentTime(), videoNode.current.duration());
        if (!isMobile) console.log("pc", videoNode.current.currentTime, videoNode.current.duration);
        _API.post({
            path: "/v1/site/event/lecture/replay/log",
            data: {
                siteIdx,
                eventIdx,
                channelIdx: channel.idx,
                sessionIdx: lecture?.sessionIdx,
                lectureIdx: lecture?.idx,
                playTime: isMobile ? videoNode.current.currentTime() : videoNode.current.currentTime,
                totalTime: isMobile ? videoNode.current.duration() || 0 : videoNode.current.duration || 0,
            },
        }).then((res) => {
        });
    }

    let sa = null;
    useEffect(() => {
        if (videoNode) {
            // sa = setInterval(checkVideoTime, 1000);
            if (isMobile) {
                videoNode.current.on("ended", () => {
                    console.log("mobile ended")
                    clearInterval(sa);
                    checkVideoTime();
                });
                videoNode.current.on("pause", () => {
                    console.log("mobile pause")
                    clearInterval(sa);
                });
                videoNode.current.on("play", () => {
                    console.log("mobile play")
                    checkVideoTime();
                    sa = setInterval(checkVideoTime, 30000);
                });
            } else {
                videoNode.current.addEventListener("ended", () => {
                    console.log("ended")
                    clearInterval(sa);
                    checkVideoTime();
                });
                videoNode.current.addEventListener("pause", () => {
                    console.log("pause")
                    clearInterval(sa);
                });
                videoNode.current.addEventListener("play", () => {
                    console.log("play")
                    checkVideoTime();
                    sa = setInterval(checkVideoTime, 30000);
                });
            }


            return () => clearInterval(sa);
        }
    }, [videoNode]);

    return (
        <LeftsWrapper layerSize={layerSize}>
            <StreamingWrapper layerSize={layerSize}>
                <Video url={getUrl()} autoPlay={true} muted={false} poster={getThumbnailUrl()} controls={true} getStreamType={() => "OWN"}
                    paddingTop={"56.2%"} videoRefProp={videoNode}
                />
            </StreamingWrapper>
            {!isMobile && <CompayLogoAutoSlider siteIdx={siteIdx} eventIdx={eventIdx} eventInfo={event} layerSize={layerSize} />}
        </LeftsWrapper>
    );
};

const CompayLogoAutoSlider = ({ siteIdx, eventIdx, eventInfo, layerSize }) => {

    const [companyLogoList, setCompanyLogoList] = useState([]);

    useEffect(() => {
        loadCompanyLogo();
    }, [])


    const loadCompanyLogo = () => {
        _API.get({
            path: "/v1/event/company/logo",
            data: {
                siteIdx,
                eventIdx,
            },
        }).then((res) => {
            const { list } = res.data;
            setCompanyLogoList(list);
            // const tempL = list.map(l => l.finalSignedUrl);
            // setCompanyLogoList([...tempL, ...tempL, ...tempL, ...tempL, ...tempL]);
        });
    };

    const LogoWrapper = styled.div`
    `

    return (
        <LogoWrapper>
            <AutoSlider list={companyLogoList} eventInfo={eventInfo} layerSize={layerSize} />
        </LogoWrapper>
    )
}

const AutoSlider = ({ list, eventInfo, layerSize }) => {

    const [itemList, setItemList] = useState([]);
    const [repeatNum, setRepeatNum] = useState(2);
    const { cfg } = useContext(AppContext);
    const { event } = cfg;

    const getRepeatNum = () => {
        const len = list?.length;

        // if (len > 20) {
        //     return 1;
        // }

        switch (len) {
            case 6:
            case 5:
                return 3;
            case 4:
            case 3:
                return 5;
            case 2:
                return 7;
            case 1:
                return 12;
            default:
                return 2;
        }
    }

    useEffect(() => {
        if (eventInfo?.footerFlowYn === "Y") {
            const temList = []
            const rN = getRepeatNum();
            setRepeatNum(rN);
            for (let i = 0; i < rN; i++) {
                temList.push(...list);
            }
            setItemList(temList);
        } else {
            setItemList(list);
        }

    }, []);

    useEffect(() => {
    }, [itemList, repeatNum])

    const goUrl = (url) => {

        if (!url) {
            return;
        }

        window.open(url);
    }

    return (
        <Slider layerSize={layerSize}>
            {event?.footerFlowYn === "Y" ? (
                <SlideBox len={itemList?.length} repeatNum={repeatNum}>
                    {itemList.map((l, i) => (
                        <SliderItem key={i} onClick={() => goUrl(l.homepageUrl)}>
                            <img src={l.finalSignedUrl} />
                        </SliderItem>
                    ))}
                </SlideBox>
            ) : (
                <NoSlideBox len={itemList?.length} repeatNum={repeatNum}>
                    {itemList.map((l, i) => (
                        <SliderItem key={i} onClick={() => goUrl(l.homepageUrl)}>
                            <img src={l.finalSignedUrl} />
                        </SliderItem>
                    ))}
                </NoSlideBox>
            )}
        </Slider>
    );
}

const slide = (len, repeatNum) => keyframes`
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-156px * ${len / repeatNum}));
    }
`;

const slideMobile = (len, repeatNum) => keyframes`
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-146px * ${len / repeatNum}));
    }
`;

const Slider = styled.div`
        height: 108px;
        width: ${(props) => !isMobile && props.layerSize ? `${props.layerSize.videoWidth}px;` : `${window.innerWidth}px`};
    /* padding: 25px; */
        display: flex;
        justify-content: start;
        align-items: center;    
        @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
            height: 70px;
        }
    `;

const SlideBox = styled.div`
        display: grid;
        grid-auto-flow: column;
        column-gap: 40px;

        width: calc(((116+40) * ${props => props.len})-40);
        animation: ${props => slide(props.len, props.repeatNum)} calc(2s * ${props => props.len / props.repeatNum}) linear infinite;

        &:hover {
            animation-play-state: paused;
        }

        @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
            width: calc(((116+30) * ${props => props.len})-30);
            column-gap: 30px;
            animation: ${props => slideMobile(props.len, props.repeatNum)} calc(2s * ${props => props.len / props.repeatNum}) linear infinite;
            &:hover {
                animation-play-state: unset;
            }
        }
    `;

const NoSlideBox = styled.div`
        display: grid;
        grid-auto-flow: column;
        column-gap: 40px;

        width: calc(((116+40) * ${props => props.len})-40);
        // animation: ${props => slide(props.len, props.repeatNum)} calc(2s * ${props => props.len / props.repeatNum}) linear infinite;

        // &:hover {
        //     animation-play-state: paused;
        // }

        @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
            width: calc(((116+30) * ${props => props.len})-30);
            column-gap: 30px;
            // animation: ${props => slideMobile(props.len, props.repeatNum)} calc(2s * ${props => props.len / props.repeatNum}) linear infinite;
            // &:hover {
            //     animation-play-state: unset;
            // }
        }
    `;

const SliderItem = styled.div`
        width: 116px;
        height: 54px;
        line-height: 100px;

        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        cursor: pointer;

        & Img {
            width: 100%;
            max-height: 54px; 
        }
        :first-child{
            margin-left:15px;
        }
    `;

const LeftsWrapper = styled.div`
    flex: 1;
    flex-grow: 5;
    height: 100%;
    overflow: hidden;
    margin-left: ${(props) => !isMobile && props.layerSize ? `${props.layerSize.padding}px;` : `0px`};
    max-width: ${(props) => !isMobile && props.layerSize ? `${props.layerSize.videoWidth}px;` : `100%`};

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        display: block;
        height: 220px;
        position: sticky;
        top: 0;
    }
`;

const StreamingWrapper = styled.div`
    height: calc(100% - 108px);
    max-height: ${(props) => !isMobile && props.layerSize ? `${props.layerSize.videoHeight}px;` : `calc(100% - 108px)`};
    width: ${(props) => !isMobile && props.layerSize ? `${props.layerSize.videoWidth}px;` : `100%`};

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        height: 100%;
    }
`;
const Rights = (props) => {
    const { cfg } = useContext(AppContext);
    const { idx: siteIdx, event } = cfg;
    const { eventIdx, speakerYn } = event;
    const { channel, session, lecture, layerSize } = props;
    const [menu, setMenu] = useState("PROGRAM");

    const onQuestion = () => {
        if (!session || !channel || !lecture) {
            return;
        }

        var textarea = document.createElement('textarea');
        textarea.rows = 3;
        textarea.className = 'swal-content__textarea';

        // Set swal return value every time an onkeyup event is fired in this textarea
        textarea.onkeyup = function () {
            swal.setActionValue({
                confirm: this.value
            });
        };
        swal({
            text: "질문을 입력해 주세요.",
            // content: "input",
            content: textarea,
            buttons: ["취소", "보내기"],
        }).then((q, w, e) => {
            if (q) {
                _API.post({
                    path: "/v1/site/event/question",
                    data: {
                        siteIdx,
                        eventIdx,
                        channelIdx: channel?.idx,
                        sessionIdx: session?.idx,
                        lectureIdx: lecture?.idx,
                        question: q,
                    },
                }).then((res) => {
                    swal({ title: "알림", text: "질문을 등록했습니다." });
                });
            }
        });
    };

    return (
        <RightsWrapper layerSize={layerSize}>
            <RTop>
                <QuestionButton onClick={onQuestion}>Question</QuestionButton>
                <Menus>
                    <MenuItem onClick={() => setMenu("PROGRAM")} ison={menu === "PROGRAM"} chatYn={"N"} speakerYn={speakerYn}>
                        프로그램
                    </MenuItem>
                    {speakerYn === "Y" && (
                        <MenuItem onClick={() => setMenu("MGR")} ison={menu === "MGR"} chatYn={"N"} speakerYn={speakerYn}>
                            연자소개
                        </MenuItem>
                    )}
                </Menus>
                <Hr />
            </RTop>
            {menu === "MGR" && <Mgr {...props} />}
            {menu === "PROGRAM" && <Program {...props} />}
            {event && event.channelDesc && (
                <ServiceInfoWrapper dangerouslySetInnerHTML={{ __html: event.channelDesc }} />
            )}
        </RightsWrapper>
    );
};

const RightsWrapper = styled.div`
    flex: 1;
    flex-grow: 2;
    height: 100%;
    background-color: ${(props) => props.theme.basic.bg.white};
    display: flex;
    flex-direction: column;
    margin-right: ${(props) => !isMobile && props.layerSize ? `${props.layerSize.padding}px` : `0px`};
    min-width: ${(props) => !isMobile && props.layerSize ? `324px` : ``};

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        display: block;
        height: unset;
    }
`;

const RTop = styled.div`
    padding: 10px 15px 0;
`;

const Menus = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const MenuItem = styled.button.attrs({
    type: "button",
})`
    flex-basis: ${(props) => (props.chatYn === "Y" && props.speakerYn === "Y" ? "31%" : props.chatYn === "N" && props.speakerYn === "N" ? "98%" : "48%")};
    background-color: ${(props) => (props.ison ? props.theme.basic.bg.channelTabOn : props.theme.basic.bg.channelTab)};
    color: ${(props) => props.theme.basic.color.white};
    font-size: 14px;
    font-family: ${(props) => props.theme.fontFamily.medium};
    text-align: center;
    padding: 8px 0;
    text-align: center;
    border: none;
    border-radius: 21px;
    margin-bottom: 0px;

    /* @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        padding: 20px 10px;
    } */
`;

const Hr = styled.hr`
    border-top: 1px solid ${(props) => props.theme.basic.line.darkHr};
    width: 100%;
`;
const HrDash = styled.hr`
    border-top: 1px solid ${(props) => props.theme.basic.line.darkHr};
    border-style: dashed;
    width: 100%;
`;

const MLogout = styled.div`
    position: absolute;
    right: 17px;
    top: 17px;
    background-image: ${`url(${ICLogout})`};
    background-image: -webkit-image-set(${getBgSet(ICLogoutSet)});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    width: 15px;
    height: 18px;
`;
const ServiceInfoWrapper = styled.div`
    height:108px;
    /* border: 1px solid #e7e7e7; */
    /* padding:10px; */
    overflow: auto;
    p {
        margin-bottom:2px;
    }
`;

const QuestionButton = styled.button.attrs({
    type: "button",
})`
    width: 100%;
    background-color: ${(props) => props.theme.basic.bg.skyblueBackground};
    color: ${(props) => props.theme.basic.color.buttonSubmit};
    font-size: 18px;
    font-family: ${(props) => props.theme.fontFamily.medium};
    text-align: center;
    padding: 13px 0px;
    border: none;
    border-radius: 5px;
    margin-bottom: 10px;
`;


const Mgr = (props) => {
    const { mgrList } = props;

    return (
        <MgrWrapper>
            {mgrList?.map((item, i) => (
                <Fragment key={i}>
                    <MgrItem>
                        {item.photoUrl && (<MImage src={item.photoUrl ? item.photoUrl : NoImg} />)}
                        <MInfo photo={item.photoUrl}>
                            <MName>
                                {item.lectureIdx ? "연자" : "좌장"}
                            </MName>
                            <br />
                            <MName>
                                {item.lastName} {item.firstName}
                            </MName>
                            {item.companyName && <MCompany>{item.companyName}</MCompany>}
                            {item.description && <MDesc dangerouslySetInnerHTML={{ __html: item.description?.replace(/\n/gi, "<br/>") }} />}
                        </MInfo>
                    </MgrItem>
                    <HrDash />
                </Fragment>
            ))}
        </MgrWrapper>
    );
};

const MgrWrapper = styled.div`
    padding: 0 15px;
    overflow-y: auto;
`;
const MgrItem = styled.div`
    display: grid;
    grid-template-columns: 110px auto;
    gap: 10px;
    margin-bottom: 15px;
`;
const MImage = styled.div`
    grid-column-start: 1;
    grid-column-end: 2;
    width: 110px;
    height: 146px;

    background-image: url(${(props) => (props.src ? `${props.src}` : "")});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
`;
const MInfo = styled.div`
    grid-column-start: ${(props) => props.photo ? 2 : 1};
    grid-column-end: 3;
    padding-top: 3px;
`;
const MName = styled.div`
    color: ${(props) => props.theme.basic.color.title};
    font-family: ${(props) => props.theme.fontFamily.regular};
    font-size: 14px;
`;
const MCompany = styled.div`
    color: ${(props) => props.theme.basic.color.primary};
    font-family: ${(props) => props.theme.fontFamily.medium};
    font-size: 16px;
`;
const MDesc = styled.div`
    color: ${(props) => props.theme.basic.color.off};
    font-family: ${(props) => props.theme.fontFamily.regular};
    font-size: 14px;
`;

const Program = (props) => {
    const { cfg, iam } = useContext(AppContext);
    const { idx: siteIdx, event } = cfg;
    const { eventIdx } = event;
    const { channel, session: s } = props;

    return (
        <ProgramWrapper>
            <SessionsWrapper>
                {s && (
                    <SessionItem >
                        {s.sessionType === "LECTURE" ? (
                            <STop sessionType={s.sessionType}>
                                <STitle>
                                    {s.title}
                                </STitle>
                                <SMgr>
                                    {s.sessionMgrList?.map((sMgr, j) => (
                                        <span key={j}>{j > 0 ? "," : ""}{sMgr.lastName}{sMgr.firstName}{sMgr.companyName ? "(" + sMgr.companyName + ")" : ""}</span>
                                    ))}
                                </SMgr>
                            </STop>
                        ) : (
                            <LectureWrapper type={s.sessionType}>
                                <LTime>
                                    {s.startTime} ~ {s.endTime}
                                </LTime>
                                <LTitle>
                                    {s.title}
                                    <LMgr>
                                        {s.sessionMgrList?.map((sMgr, j) => (
                                            <span key={j}>{j > 0 ? "," : ""}{sMgr.lastName}{sMgr.firstName}{sMgr.companyName ? "(" + sMgr.companyName + ")" : ""}</span>
                                        ))}
                                    </LMgr>
                                </LTitle>
                            </LectureWrapper>
                        )}

                        {s.lectureList?.map((l, j) => (
                            <LectureWrapper key={j}>
                                <LTime>
                                    {l.startTime} ~ {l.endTime}
                                </LTime>
                                <LTitle>
                                    {l.title}

                                    {l.lectureFileList?.length > 0 && <br />}
                                    {l.lectureFileList?.map((f, k) => (
                                        <LFile
                                            key={k}
                                            alt={f.name}
                                            num={k + 1}
                                            onClick={() => {
                                                window.open(f.signedUrl);
                                            }}
                                        />
                                    ))}
                                    {/* {l.mgrName && <LMgr dangerouslySetInnerHTML={{ __html: l.mgrName?.replace(/,/gi, "<br/>") }} />} */}
                                    <LMgr>
                                        {l.lectureMgrList?.map((mgr, k) => (
                                            <Fragment key={k} >
                                                {k > 0 && (<br />)}
                                                {mgr.lastName}{mgr.firstName}{mgr.companyName ? "(" + mgr.companyName + ")" : ""}
                                            </Fragment>
                                        ))}
                                    </LMgr>
                                </LTitle>
                            </LectureWrapper>
                        ))}
                    </SessionItem>
                )}
            </SessionsWrapper>

            {
                !s && (
                    <ProgramEmptyWrapper height={`calc(100% - 28px - 2rem - 42px)`}>
                        <H3>일정이 없습니다.</H3>
                    </ProgramEmptyWrapper>
                )
            }
        </ProgramWrapper >
    );
};

const ProgramWrapper = styled.div`
    width: 100%;
    // height: 100%;
    padding: 0 15px;
    height: ${(props) => (props.height ? props.height : `calc(100% - 28px - 2rem - 42px)`)};
    overflow-y:auto;
`;

const ProgramEmptyWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    /* ${(props) => (props.height ? `height:${props.height}` : `height:calc(100% - 28px - 2rem)`)} */

    height: ${(props) => (props.height ? props.height : `calc(100% - 28px - 2rem)`)};
`;

const SessionsWrapper = styled.div``;
const SessionItem = styled.div``;

const STop = styled.div`
    display: block;
    justify-content: space-between;
    padding: 15px 20px;

    border-top: 1px solid ${(props) => props.theme.basic.line.agenda};
    border-bottom: 1px solid ${(props) => props.theme.basic.line.agenda};
    background-color: ${(props) => props.theme.basic.bg.agenda};
`;

const STitle = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.basic.color.title};
    font-family: ${(props) => props.theme.fontFamily.medium};
    font-size: 13px;

    span {
        color: ${(props) => props.theme.basic.color.off};
        font-family: ${(props) => props.theme.fontFamily.regular};
        font-size: 12px;
        margin-left: 17px;
        padding-top: 3px;
    }
`;
const SMgr = styled.div`
    width: 100%;
    margin-top: 10px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    color: ${(props) => props.theme.basic.color.primary};
    font-family: ${(props) => props.theme.fontFamily.regular};
    font-size: 11px;
`;

const LectureWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0px;

    ${(props) =>
        props.type === "Break"
            ? `
                background-color: ${props.theme.basic.bg.break};

                & ${LTime}{
                    padding: 10px 0;
                }
                & ${LTitle}{
                    padding: 10px 0 10px 20px;
                    text-align: center;   

                    font-size:12px;
                    font-family: ${props.theme.fontFamily.medium};
                    color: ${props.theme.basic.color.primary};
                }
            `
            : `
                border-bottom: 1px solid ${props.theme.basic.line.agendaLecture};
            `}
`;

const LTime = styled.div`
    flex: 1;
    flex-grow: 1;
    /* border-right: 1px solid ${(props) => props.theme.basic.line.agendaLecture}; */
    padding: 17px 0;
    text-align: center;
    font-size: 11px;
    font-family: ${(props) => props.theme.fontFamily.regular};
    color: ${(props) => props.theme.basic.color.off};
`;
const LTitle = styled.div`
    flex: 1;
    font-family: ${(props) => props.theme.fontFamily.medium};
    color: ${(props) => props.theme.basic.color.primary};

    flex-grow: 2.5;
    padding: 15px 0 15px 10px;
    font-size: 13px;

    span {
        margin-left: 0px;
        margin-right: 5px;
    }
`;
const LMgr = styled.div`
    flex: 1;
    flex-grow: 1;
    justify-content: flex-start;
    padding: 3px 0;
    display: flex;
    align-items: center;

    color: ${(props) => props.theme.basic.color.primary};
    font-family: ${(props) => props.theme.fontFamily.regular};
    font-size: 12px;
`;

const LReplay = styled.div`
    flex: 1;
    flex-grow: 0.5;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    a {
        color: ${(props) => props.theme.basic.color.play};
        font-size: 10px;
        background: #000000;
        opacity: 0.6;
        border-radius: 14px;
        padding: 3px 8px;
    }
`;

const LFile = styled.span`
    width: 20px;
    height: 20px;

    border-radius: 50%;
    background-color: ${(props) => (props.theme.basic.bg[`file${props.num}`] ? props.theme.basic.bg[`file${props.num}`] : props.theme.basic.bg.file1)};
    background-image: ${`url(${ICFile})`};
    background-image: -webkit-image-set(${getBgSet(ICFileSet)});
    background-repeat: no-repeat;
    background-size: 8px 10px;
    background-position: center center;
    display: inline-block;
    line-height: 30px;
    vertical-align: middle;
    margin-left: 5px;
    cursor: pointer;
`;

const ExitSpan = styled.span`
position: absolute;

margin-left: 5px;

background-image: ${`url(${ICExit})`};
background-image: -webkit-image-set(${getBgSet(ICExitSet)});

background-repeat: no-repeat;
background-position: center 2px;
background-size: 100%;

width: 18px;
height: 37px;

/* background-size: 100%; */
`;

const QuestionSpan = styled.span`
position: absolute;

margin-left: 5px;

background-image: ${`url(${ICSellect})`};
background-image: -webkit-image-set(${getBgSet(ICSellectSet)});

background-repeat: no-repeat;
background-position: center 2px;
background-size: 100%;

width: 18px;
height: 37px;

/* background-size: 100%; */
`;
