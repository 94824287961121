import React, { useContext, useEffect, useState, Fragment } from "react";
import styled from "styled-components";

import { AppContext } from "../../../components/AppProvider";

import { _API, _U, _CK } from "../../../modules/utils";

import { default as BoothView, Booth as BoothDetailView } from "./Booth";

export const Content = (props) => {
    const { code } = props;
    const { cfg, auth } = useContext(AppContext);

    const { idx: siteIdx } = cfg;
    const { eventIdx: siteEventIdx } = cfg.event;

    const [data, setData] = useState({});
    const [html, setHtml] = useState("");
    const [files, setFiles] = useState([]);

    const loadData = (data) => {
        const param = { siteIdx, siteEventIdx, code };
        const req = { path: "/v1/site/event/content", data : param };
        _API.get(req).then((res) => {
            const { data : content, files } = res.data;
            if(content.contentType === "HTML") setHtml(content.content);
            if(code === "test") console.log(res);
            setData(content);
            setFiles(files);
        });
    };

    useEffect(() => {
        _U.postMessage("LOCATION-CHANGED");
        loadData();
    }, [siteIdx, siteEventIdx, code, auth]);

    return (
        <Fragment>
            <Wrapper dangerouslySetInnerHTML={{ __html: html }} />
            {
                code === "test" && data.content && 
                <BoothView booth={data.content} />
            }
            { code === "cv" && data.length > 0 &&
                <div className="row">
                {
                    data.map((item,i) => (
                        <div className="col-lg-3" key={i}>
                            <div className="contact-box center-version">
                                <a href="profile.html">
                                    <img alt="image" className="rounded-circle" src={`${item.photoUrl}`}/>
                                    <h3 className="m-b-xs"><strong>{item.lastName + " " + item.firstName}</strong></h3>
                                    <div className="font-bold">Graphics designer</div>
                                    <address className="m-t-md">
                                        <strong>{item.companyName}</strong><br/>
                                        {item.description}<br/>
                                        SITE. <abbr title="Phone">P:</abbr>
                                        {item.phone}<br/>
                                        {item.email}<br/>
                                    </address>
                                </a>
                                {/* <div className="contact-box-footer">
                                    <div className="m-t-xs btn-group">
                                        <a href=""  className="btn btn-xs btn-white"><i className="fa fa-phone"></i> Call </a>
                                        <a href=""  className="btn btn-xs btn-white"><i className="fa fa-envelope"></i> Email</a>
                                        <a href=""  className="btn btn-xs btn-white"><i className="fa fa-user-plus"></i> Follow</a>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    ))
                }
                </div>
            }
            {_CK.isLogin() && files.length > 0 && (
                <ContentFiles>
                    {files.map((item, i) => (
                        <li key={i}>
                            <a href={item.url} target="_blank">
                                {item.name}
                            </a>
                        </li>
                    ))}
                </ContentFiles>
            )}
        </Fragment>
    );
};

export const Wrapper = styled.div`
    img {
        max-width: 100%;
    }
    iframe {
        max-width: 100%;
    }
`;

export const ContentFiles = styled.ul`
    margin: 1rem 0 0;
    list-style-type: none;
    li {
        margin-bottom: 0.3rem;
    }
    a {
        padding: 0.3rem 1rem;
        display: block;
        color: ${(props) => props.theme.basic.color.primary};
        border: #ffffff solid 1px;
        border-radius: 0.3rem;
        background: #ffffff;
        box-shadow: 0 3px 6px 0 rgba(51, 51, 51, 0.25);
    }
`;

export const H2 = styled.h2``;

const Input = styled.input.attrs((props) => {
    return {
        type: props.type || "text",
        step: props.step || "",
    };
})``;

export const InputForm = (props) => {
    const { title, hr, type, value, onChange, name, description, readOnly} = props;
    return (
        <Fragment>
            <FormControl>
                {title && <Label>{title}</Label>}
                <FormCol>
                    <Input type={type || "text"} value={value || ""} placeholder={props.placeholder} onChange={onChange} name={name} step={props.step} readOnly={readOnly ? readOnly : false}/>
                </FormCol>
            </FormControl>
            {description && (
                <FormControl>
                    <FormCol>{description}</FormCol>
                </FormControl>
            )}
            {hr && <HRDash />}
        </Fragment>
    );
};

export const SelectForm = (props) => {
    const { title, name, nullTitle, hr, items, value, onChange } = props;
    return (
        <Fragment>
            <FormControl>
                {title && <Label>{title}</Label>}
                <FormCol>
                    <select name={name} value={value || ""} onChange={onChange} className="">
                        <option value="">{nullTitle}</option>
                        {items.map((item, i) => (
                            <option key={i} value={item.value}>
                                {item.label}
                            </option>
                        ))}
                    </select>
                </FormCol>
            </FormControl>
            {hr && <HRDash />}
        </Fragment>
    );
};

const Button = styled.button.attrs({ type: "button" })`
    ${(props) => (props.type === "SUBMIT" ? `` : ``)}
`;

export const ButtonForm = (props) => {
    const { title, onClick, type } = props;

    return (
        <Fragment>
            <Button onClick={onClick} type={type}>
                {title}
            </Button>
        </Fragment>
    );
};

export const InputViewForm = (props) => {
    const { title, hr, value, description } = props;
    return (
        <Fragment>
            <FormControl>
                {title && <Label>{title}</Label>}
                <FormCol>{value}</FormCol>
            </FormControl>
            {description && (
                <FormControl>
                    <FormCol>{description}</FormCol>
                </FormControl>
            )}
            {hr && <HRDash />}
        </Fragment>
    );
};

const FormCol = styled.div``;

const Label = styled.label``;

export const FormControl = styled.div``;

export const HRDash = styled.hr`
    border-top: 1px dashed rgba(0, 0, 0, 0.1);
`;

const GridDiv = styled.div`
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    row-gap: 1rem;
    column-gap: 1rem;
    @media only screen and (max-width: 768px) {
        display: block;
    }
`;