import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import swal from "sweetalert";

import { AppContext } from "../../../components/AppProvider";

import { InputForm, SelectForm, H2, HRDash, ButtonSubmit, ButtonDefault, CheckMultiForm } from "./UI/Components";

import { _API, _U } from "../../../modules/utils";

import { _DEF } from "../../../modules/config";

import { CheckOffImg, CheckOnImg, CheckOffImgSet, CheckOnImgSet, getBgSet } from "./asset";

import { DatePicker } from "../../../components/UI/Form";

export default (props) => {
    const { code, history, location, match } = props;

    const { cfg } = useContext(AppContext);
    const [idx, setIdx] = useState(null);
    const [item, setItem] = useState({});

    const sessionCode = "_REGISTRATION_MODIFY";

    useEffect(() => {
        _U.postMessage("LOCATION-CHANGED");

        const session = _U.getSession(sessionCode);

        if (session) {
            if (session.code === sessionCode && session && session.idx) {
                setIdx(session.idx);
            }
        }
        _U.removeSession(sessionCode);
    }, []);

    const loadData = () => {
        if (idx) {
            const data = { idx: idx, siteIdx: cfg.idx, eventIdx: cfg.event?.eventIdx };

            _API.get({
                path: "/v1/site/event/registration/item",
                data: data,
            }).then((res) => {
                if (res.error === 0) {
                    setItem(res.data);
                } else {
                    swal(res.message);
                }
            });
        }
    };

    useEffect(() => {
        loadData();
    }, [idx]);

    const onComplete = () => {
        props.onCancel && props.onCancel();
    };

    return (
        <Wrapper>
            <H2 style={{ textAlign: "center" }}>사전등록 {idx ? `수정` : ``}</H2>
            {/* <Content code="registration" /> */}
            <Form {...props} onComplete={onComplete} item={item} />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const Form = (props) => {
    const { cfg } = useContext(AppContext);
    const [item, setItem] = useState({});
    const [regTypeName, setRegTypeName] = useState("");
    const { onCancel } = props;
    const { emailYn, phoneYn, nameYn, companyYn, licenseYn, regTypeYn, termsPrivacy } = cfg.event;
    const { companyEsYn, emailEsYn, licenseEsYn, nameEsYn, phoneEsYn, regTypeEsYn } = cfg.event;
    const { licenseDesc, lastDesc } = cfg.event;
    const { registrationItemList } = cfg.event;
    const { companyLabelName } = cfg.event;
    const { passwdYn } = cfg.event;

    const [participantInfoList, setParticipantInfoList] = useState([]);

    useEffect(() => {
        if (props.item) {
            setItem(props.item);
            setRegTypeName(props.item.regTypeName);
        }
        const newRegistrationItemList = [];
        for (let i in registrationItemList) {
            const elRegistration = registrationItemList[i];
            for (let j in props.item?.infoItemList) {
                const elParticipant = props.item.infoItemList[j];
                if (elRegistration.registrationItemIdx === elParticipant.registrationItemIdx) {
                    elRegistration.answer = elParticipant.answer;
                    elRegistration.participantIdx = elParticipant.participantIdx;
                    elRegistration.participantInfoIdx = elParticipant.participantInfoIdx;
                }
            }
            newRegistrationItemList.push(_U.jsonParse(JSON.stringify(elRegistration)));
        }
        setParticipantInfoList(newRegistrationItemList);
    }, [props.item]);

    const onSetItem = (e) => {
        const target = e.target;
        setItem((o) => {
            return { ...o, [target.name]: target.value };
        });
    };

    const onSave = () => {
        // 필수 여부 체크
        if (nameYn === "Y" && nameEsYn === "Y" && !item.name) {
            swal("성명을 입력하세요.");
            return false;
        }
        if (emailYn === "Y" && emailEsYn === "Y" && !item.email) {
            swal("이메일을 입력하세요.");
            return false;
        }

        if (emailYn === "Y" && emailEsYn === "Y" && !_U.isEmail(item.email)) {
            swal("올바른 이메일을 입력하세요.");
            return false;
        }

        if (phoneYn === "Y" && phoneEsYn === "Y" && !item.phone) {
            swal("휴대폰을 입력하세요.");
            return false;
        }

        if (passwdYn === "Y" && !item.passwd) {
            swal("비밀번호를 입력하세요.");
            return false;
        }

        if (companyYn === "Y" && companyEsYn === "Y" && !item.companyName) {
            swal("소속을 입력하세요.");
            return false;
        }

        if (licenseYn === "Y" && licenseEsYn === "Y" && !item.licenseNumber) {
            swal("의사면허번호를 입력하세요.");
            return false;
        }

        if (regTypeYn === "Y" && regTypeEsYn === "Y" && !item.regType) {
            swal("등록구분을 선택하세요.");
            return false;
        }

        if (regTypeYn === "Y" && regTypeEsYn === "Y" && item.regType === "ETC" && regTypeName) {
            swal("등록구분(기타)를 입력하세요.");
            return false;
        }

        if (item.termsPrivacy !== "Y" && !item.idx) {
            swal("약관에 동의를 해주세요.");
            return false;
        }
        for (let i in participantInfoList) {
            const addItem = participantInfoList[i];
            if (addItem.esYn === "Y" && !addItem.answer) {
                swal(addItem.labelName + "의 값을 입력하세요.");
                return;
            }
        }

        const data = { ...item, siteIdx: cfg.idx, eventIdx: cfg.event?.eventIdx, regTypeName, participantInfoList };

        _API.post({
            path: "/v1/site/event/registration",
            data: data,
        }).then((res) => {
            const passCode = item.passCode ? "" : res.data.passCode;
            swal({ title: "알림", text: (item.idx ? `사전등록 수정이 완료 되었습니다.` : `사전등록이 완료 되었습니다.`) + (passCode ? `\n인증코드:${passCode}\n발급된 인증코드로 로그인 해 주세요` : "") }).then(() => {
                props.onComplete && props.onComplete();
            });
        });
    };

    const onBack = () => {
        history.back();
    };

    const regTypeNameHandler = (e) => {
        onSetItem(e);
        setRegTypeName(e.target.value === "ETC" ? "" : e.target[e.target.selectedIndex].innerText);
    };

    useEffect(() => {
        if (item.regType === "ETC") {
            setItem({ ...item, regTypeName: "regTypeName" });
        }
    }, [item.regType]);

    const changeInfoList = (addItem, value) => {
        const newParticipantInfoList = [];
        for (let i in participantInfoList) {
            const newItem = participantInfoList[i];
            if (addItem.registrationItemIdx === newItem.registrationItemIdx) {
                newItem.answer = value;
            }
            newParticipantInfoList.push(newItem);
        }
        setParticipantInfoList(newParticipantInfoList);
    }

    const changeInfoListFromCheck = (addItem, item) => {
        const newParticipantInfoList = [];
        for (let i in participantInfoList) {
            const newItem = participantInfoList[i];
            if (addItem.registrationItemIdx === newItem.registrationItemIdx) {
                const answers = _U.jsonParse(newItem.answer) || [];
                let newAnswers = [];
                if (item.checked) {
                    answers.push(item.value);
                    newAnswers = [...answers];
                } else {
                    for (let j in answers) {
                        const answer = answers[j];
                        if (answer !== item.value) {
                            newAnswers.push(answer);
                        }
                    }
                }
                newItem.answer = JSON.stringify(newAnswers);
            }
            newParticipantInfoList.push(newItem);
        }
        setParticipantInfoList(newParticipantInfoList);
    }

    const termSubTitle = " 본 사이트에서 제공하는 서비스 이용과 관련한 개인정보에 대한 동의합니다.";
    return (
        <Fragment>
            {!item?.idx && (
                <TermsArea title={"약관"} subTitle={termSubTitle} name={"termsPrivacy"} onChange={setItem} subTitleEsYn={"Y"} value={item.idx ? "Y" : item.termsPrivacy} htmlValue={termsPrivacy} />
            )}
            {nameYn === "Y" && <InputForm title="성명" name="name" value={item.name || ""} onChange={onSetItem} />}
            {emailYn === "Y" && <InputForm title="이메일" name="email" value={item.email || ""} onChange={onSetItem} />}
            {phoneYn === "Y" && <InputForm title="휴대전화번호" name="phone" value={item.phone || ""} onChange={onSetItem} description={"-없이 숫자만 입력"} />}
            {passwdYn === "Y" && <InputForm title="비밀번호" type="password" name="passwd" value={item.passwd || ""} onChange={onSetItem} />}
            {companyYn === "Y" && <InputForm title={companyLabelName || "소속"} name="companyName" value={item.companyName || ""} onChange={onSetItem} />}
            {/* {companyYn === "Y" && <InputForm title="진료과" name="deptName" value={item.deptName || ""} onChange={onSetItem} />} */}
            {/* {companyYn === "Y" && <InputForm title="직책" name="position" value={item.position || ""} onChange={onSetItem} />} */}
            {licenseYn === "Y" && <InputForm title="면허번호" name="licenseNumber" value={item.licenseNumber} onChange={onSetItem} placeholder="의사면허번호를 입력해 주세요" description={licenseDesc} />}
            {regTypeYn === "Y" && (
                <>
                    <SelectForm title="등록구분" name="regType" items={_DEF.PARTICIPANT_REG_TYPE} value={item.regType} onChange={regTypeNameHandler} nullTitle="등록구분 선택" />
                    {item.regType === "ETC" && (
                        <div className="row">
                            <div className="col-sm-12">
                                <InputForm title=" " name="regTypeName" value={regTypeName} onChange={(e) => setRegTypeName(e.target.value)} placeholder={`[등록구분 - 기타] 입력해 주세요.`} />
                            </div>
                        </div>
                    )}
                </>
            )}
            {participantInfoList?.map((addItem, i) => (
                <Fragment key={i} >
                    {
                        addItem.questionType === "SUBJECTIVE" && (
                            <InputForm title={addItem.labelName} value={addItem.answer} onChange={(e) => changeInfoList(addItem, e.target.value)} />
                        )
                    }
                    {
                        addItem.questionType === "MULTIPLE" && (
                            <CheckMultiForm title={addItem.labelName} values={_U.jsonParse(addItem.answer)} items={_U.jsonParse(addItem.choiceItems)} onChange={(e) => changeInfoListFromCheck(addItem, e)} />
                        )
                    }
                    {
                        addItem.questionType === "SINGLE" && (
                            <SelectForm title={addItem.labelName} value={addItem.answer} items={_U.jsonParse(addItem.choiceItems)} onChange={(e) => changeInfoList(addItem, e.target.value)} nullTitle={`${addItem.labelName}을 선택해 주세요.`} />
                        )
                    }
                    {
                        addItem.questionType === "DATE" && (
                            <DatePicker title={addItem.labelName} value={addItem.answer} onChange={(e) => changeInfoList(addItem, e.value)} />
                        )
                    }
                </Fragment>
            ))}
            {lastDesc && (
                <>
                    <HRDash />
                    <div dangerouslySetInnerHTML={{ __html: lastDesc }} />
                </>
            )}
            <HRDash />
            <FormFooter>
                <ButtonDefault onClick={onCancel}>취소</ButtonDefault>
                <ButtonSubmit onClick={onSave}>확인</ButtonSubmit>
            </FormFooter>
        </Fragment>
    );
};

const TermsArea = (props) => {
    const { title, subTitle, subTitleEsYn, name, nullTitle, hr, items, value, onChange, readOnly, htmlValue } = props;

    const [checkYn, setCheckYn] = useState("");

    const SubTitleLabel = styled.div`
        position: relative;
        display: block;
        width: 100%;
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        cursor: pointer;
        color: #333;
        box-sizing: border-box;
    `;

    const EsStyleSpan = styled.span`
        /* position: absolute; */
        line-height: 25px;
        font-size: 16px;
        font-weight: 400;
        display: inline-block;
        // color: ${(props) => props.theme.basic.line.default};
        color: ${(props) => props.theme.basic.color.title};
    `;

    const onCheck = (e) => {
        //라벨로 인한 이벤트 중복 방지
        if (!e.target.name) document.getElementById(name).click();

        const checkedYn = !value ? "Y" : (value === "Y" ? "N" : "Y");
        setCheckYn(checkedYn);
        // console.log(e.target.getAttribute('name'), checkedYn);

        onChange && onChange((o) => {
            return { ...o, [e.target.getAttribute('name')]: checkedYn };
        });
    };

    useEffect(() => {
        setCheckYn(value);
    }, [value]);

    return (
        <Fragment>
            <div className="form-group row">
                {title && (
                    <Fragment>
                        <label className="col-sm-12 control-label">{title}</label>
                    </Fragment>
                )}
                <div className="col-sm-12 btn-group">
                    {subTitle && (
                        <SubTitleLabel onClick={onCheck} >
                            <Fragment>
                                <TermCheckBox
                                    id={name}
                                    name={name}
                                    onClick={(e) => {
                                        onCheck(e);
                                    }}
                                    checked={checkYn === "Y" ? true : false}
                                />

                                <SubTitle>
                                    {subTitleEsYn && <EsStyleSpan>(필수) </EsStyleSpan>}
                                    {subTitle}
                                </SubTitle>
                            </Fragment>
                        </SubTitleLabel>
                    )}
                </div>
            </div>
            {htmlValue && (
                <AgreementHtmlWrapper className="form-group row">
                    <div
                        className="ibox-content"
                        dangerouslySetInnerHTML={{
                            __html: htmlValue,
                        }}
                        style={{ width: "100%", borderBottom: "0px", borderTop: "0px" }}
                    ></div>
                </AgreementHtmlWrapper>
            )}
        </Fragment>
    );
};

const AgreementHtmlWrapper = styled.div`
    img {
        width: 100%;
    }
    border-top: 2px dashed rgba(0,0,0,0.1);
    border-bottom: 2px dashed rgba(0,0,0,0.1);
`;

const FormFooter = styled.div`
    display: flex;
    justify-content: center;

    & button {
        flex-basis: 28%;

        :last-child {
            margin-left: 2%;
        }
    }
`;

const TermCheckBox = styled.label`
    width: 25px;
    height: 25px; 
    background-image: 
        ${props => (props.checked == true ? `url(${CheckOnImg})` : `url(${CheckOffImg})`)};
    background-image:
        ${props => (props.checked == true ? `-webkit-image-set(${getBgSet(CheckOnImgSet)})` : `-webkit-image-set(${getBgSet(CheckOffImgSet)})`)};
    cursor: pointer;
    float: left;
    margin-right: 10px;
    
`;

const SubTitle = styled.div`
    font-size: 13px;
    color: ${(props) => props.theme.basic.color.primary};
    /* width: 135px; */
    width: 90%;
    line-height: 25px;
    font-weight: 500;
    float: left;
`;