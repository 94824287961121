import React, { useContext, useState, useEffect, Fragment, useRef } from "react";

import { AppContext } from "../../components/AppProvider";
import { _API, _U } from "../../modules/utils";
import { _DEF } from "../../modules/config";

export default (props) => {

    const {contentType, keyword, contentIdx, category, contentStatus, siteIdx, eventIdx, startDate, endDate, companyIdx} = props;

    const [ivsList, setIvsList] = useState([]);
    const [contentList, setContentList] = useState([]);

    const loadContentList = (data) => {
        if(!siteIdx || !eventIdx){
            setContentList([]);
            return;
        }

        const param = {
              contentType      : contentType
            , keyword          : keyword || ""
            , contentIdx       : contentIdx || ""
            , category         : category || ""
            , contentStatus    : contentStatus || ""
            , siteIdx          : siteIdx || ""
            , eventIdx         : eventIdx || ""
            , startDate        : startDate || ""
            , endDate          : endDate || ""
            , companyIdx       : companyIdx || ""
        };
        const req = { path: "/v1/a/event/content/list", data: param };
        _API.get(req).then((res) => {
            const { list } = res.data;
            setContentList(list);
        });
    };

    const loadIvsList = (data) => {
        if(!siteIdx || !eventIdx){
            setIvsList([]);
            return;
        }
        
        const param = { ...data };
        const req = {
            path: "/v1/a/aws/ivs/list",
            data: {
                siteIdx: param && param.siteIdx ? data.siteIdx : null,
            },
        };
        _API.get(req).then((res) => {
            const { list } = res.data;
            setIvsList(list);
        });
    };
  
    useEffect(() => {
        loadContentList(props);
        loadIvsList(props);
    }, [siteIdx, eventIdx])

    return ({
        loadContentList, loadIvsList, ivsList, contentList
    })
};