import React, { Fragment, useContext, useEffect, useState } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";

import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U, _CK } from "../../../modules/utils";

import { ConnectionButton, H2, H3, ItemEmpty } from "./UI/Components";
import MyWindowPortal from "./MyWindowPortal";
import Booth from "../../../components/Booth";

import badge from "../../../resources/image/basic/flag@3x.png";

export default (props) => {
    const { cfg, auth, iam } = useContext(AppContext);
    const event = cfg.event || null;
    const [isShown, setIsShown] = useState({});
    const [list, setList] = useState([]);
    const [showWindow, setShowWindow] = useState(false);
    const [clickedItem, setClickedItem] = useState({});

    if (!event) {
        return null;
    }

    const { siteIdx, eventIdx, channel, startDate, endDate } = event;

    if (!startDate || !endDate || channel?.length === 0) {
        return null;
    }
    useEffect(() => {
        loadList();
    }, []);

    const loadList = () => {

        const data = {
            siteIdx,
            eventIdx,
        };

        _API.get({ path: "/v1/site/event/sessions", data }).then(res => {
            const { list } = res.data;
            setList(list?.filter(session => session.satelliteYn === "Y"));
        })
    }

    const onClickLogo = async (item) => {
        if (!_CK.isLogin()) {
            swal("로그인 후 이용이 가능합니다").then(() => {
                window.location.reload();
            });
        } else {
            window.open(`/#/channel/${item.channelIdx}`);
            _U.postMessage("CLOSE-MODAL");
        }
    };

    // const clickedItemShowBadge = (item) => {
    //     setList(
    //         list.map((c) => {
    //             if (c.idx === item.idx) {
    //                 c.isClicked = true;
    //             }
    //             return c;
    //         })
    //     );
    // };

    // const rtnPopupSpec = () => {
    //     const popupWidth = 1600;
    //     const popupHeight = 900;

    //     const popupX = window.screen.width / 2 - popupWidth / 2;
    //     const popupY = window.screen.height / 2 - popupHeight / 2;

    //     return `width=${popupWidth},height=${popupHeight},left=${popupX},top=${popupY},location=no,status=no`;
    // };

    return (
        <Wrapper>
            <>
                {list.map((item, i) => (
                    <Article key={i} onMouseEnter={() => setIsShown({ [item.idx]: true })} onMouseLeave={() => setIsShown({ ...isShown, [item.idx]: false })}>
                        <ArticleInfo> 
                            {item.isClicked && (
                                <ClickedCompany>
                                    <img src={badge} alt="" />
                                </ClickedCompany>
                            )}
                            <EboothImg>
                                <img src={item.companyLogoSignedUrl} />
                            </EboothImg>
                        </ArticleInfo>
                        {isShown[item.idx] && <HoverEffect onClick={(e) => onClickLogo(item)}>{item.companyName}</HoverEffect>}
                    </Article>
                ))}
                <ItemEmpty length={list?.length} number={3} component={<Article />} />

                {/* { list?.length === 0 && 
                    <EmptyWrapper>
                        <H3>오늘 진행중인 세션이 없습니다.</H3>
                    </EmptyWrapper>
                } */}
            </>
        </Wrapper>
    );
};

const ClickedCompany = styled.div`
    position: absolute;
    top: 10px;
    left: 0px;
    height: 22px;

    & img {
        height: 100%;
    }
`;

const BoothWrapper = styled.div`
    widows: 100%;
    height: 100%;
`;

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: flex-start;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        justify-content: space-around;
    }
`;

const EboothImg = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: ${(props) => props.theme.fontFamily.medium};
    font-size: 12px;
    text-align: center;
    color: ${(props) => props.theme.basic.color.primary};
    width: 106px;
    height: 106px;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        width: 100%;
        height: 100%;
        min-height: 80px;
    }

    & img {
        width: 100%;
        min-height: 3rem;
        /* height: 100%; */

        @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
            width: 80%;
        }
    }
`;

const Article = styled.div`
    align-items: center;
    flex-basis: 12%;
    background-color: ${(props) => props.theme.basic.bg.background};
    margin: 8px 8px 8px 9px;
    position: relative;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        flex-basis: 31%;
        margin: 0 0 8px 0;
    }
`;

const ArticleInfo = styled.div`
    position: relative;
    border: solid 1px ${(props) => props.theme.basic.line.default};
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    height: 100%;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        padding: 5px;
    }
`;

const HoverEffect = styled.div`
    position: absolute;
    padding-top: 10px;
    top: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    color: #ffffff !important;
    font-size: 14px;
    background-color: #000000;
    border-radius: 10px;
`;

const EmptyWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
