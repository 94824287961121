import React, { Fragment, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import swal from "sweetalert";

import { AppContext } from "../../../components/AppProvider";

import { InputForm, SelectForm, ButtonForm, HRDash, Wrapper, H2 } from "./Components";
import { _API, _U } from "../../../modules/utils";

export default (props) => {
    const { code, history } = props;
    const { cfg } = useContext(AppContext);

    const [item, setItem] = useState({});

    const onSetItem = (e) => {
        const target = e.target;
        setItem((o) => {
            return { ...o, [target.name]: target.value };
        });
    };

    const onBack = () => {
        history?.push("/registration");
    };

    const onSave = () => {
        if (!item.name) {
            swal("성명을 입력하세요.");
            return false;
        }
        if (!item.email) {
            swal("이메일을 입력하세요.");
            return false;
        }
        if (!item.mobile) {
            swal("휴대폰을 입력하세요.");
            return false;
        }

        const data = { ...item, siteIdx: cfg.idx, eventIdx: cfg.event?.eventIdx };

        _API.get({
            path: "/v1/site/event/registration/check",
            data: data,
        }).then((res) => {
            if (res.error === 0) {
                // window.sessionStorage.setItem("_REGISTRATION_CHECK", res.data);
                _U.setSession("_REGISTRATION_CHECK", res.data);
                history?.push("/registration/view");
            } else {
                swal(res.message);
            }
        });
    };

    useEffect(() => {
        _U.postMessage("LOCATION-CHANGED");
    }, []);

    return (
        <Wrapper>
            <H2 style={{ textAlign: "center" }}>사전등록 조회</H2>
            <InputForm title="성명" name="name" value={item.name || ""} onChange={onSetItem} placeholder="사전등록시 입력한 성명을 입력하세요." />
            <InputForm title="이메일" name="email" value={item.email || ""} onChange={onSetItem} placeholder="사전등록시 입력한 이메일을 입력하세요." />
            <InputForm title="휴대전화번호" name="mobile" value={item.mobile || ""} onChange={onSetItem} placeholder="사전등록시 입력한 휴대전화번호를 입력하세요." />
            <HRDash />
            <ButtonForm title="확인" type="SUBMIT" onClick={onSave} />
            <ButtonForm title="취소" type="RESET" onClick={onBack} />
        </Wrapper>
    );
};
