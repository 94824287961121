import React, { useContext, useEffect, useState, Fragment } from "react";
import styled from "styled-components";
import swal from "sweetalert";
import moment from 'moment';

import { AppContext } from "../../../components/AppProvider";

import { _API, _U, _CK } from "../../../modules/utils";

export default (props) => {
    const { cfg } = useContext(AppContext);
    const event = cfg.event || null;
    console.log("Components.Enter!");
    if (!event) {
        return null;
    }
    const {startDate, endDate, channel} = event;
    if (!startDate || !endDate || channel?.length === 0) {
        return null;
    }
    
    if (event.channel.filter((item)=>item.progressDate == moment().format("YYYYMMDD")).length === 0) {
        console.log("당일 진행 채널 없음 : " + moment().format("YYYYMMDD"))
        return null;
    }
    
    // const now = moment();
    // const sdate = moment(`${now.format('YYYY-MM-DD')} ${startTime}`, 'YYYY-MM-DD HH:mm:ss');
    // const edate = moment(`${now.format('YYYY-MM-DD')} ${endTime}`, 'YYYY-MM-DD HH:mm:ss');
    // const isPlaying = now.isBetween(sdate, edate);

    // if (!isPlaying) {
    //     return null;
    // }

    const [isChoose, setIsChoose] = useState(false);

    const onEnter = (idx) => {
        if (event.loginYn === "Y" && !_CK.isLogin()) {
            swal({title:"알림", text:"로그인 후 이용해 주세요."}).then(()=>{
                _U.postMessage("OPEN-LOGIN-FORM");
            })
            return;
        }
        if (!idx) {
            if (channel.length === 1) {
                window.location.href = `#/channel/${channel[0].idx}`;
            } else {
                setIsChoose(true);
            }
        } else {
            window.location.href = `#/channel/${idx}`;
        }
    }

    const onCancel = () => {
        setIsChoose(false);
    }

    return (
        <EnterWrapper>
            입장하기
            <EnterButton onClick={onEnter.bind(this, '')}><i className="fa fa-laptop"></i> 입장하기</EnterButton>
            {isChoose && (
                <CContainer>
                    <CWrapper>
                        <CTitle>채널 선택</CTitle>
                        {channel.map((item, i)=>(
                            <CItem key={i}>
                                <CButton dark onClick={onEnter.bind(this, item.idx)}>{item.title}</CButton>    
                            </CItem>
                        ))}
                        <CItem>
                            <CButton onClick={onCancel}><i className="fa fa-times"></i> 취소</CButton>
                        </CItem>
                    </CWrapper>
                </CContainer>
            )}
        </EnterWrapper>
    )
}

const EnterWrapper = styled.div`
    margin:0 0 1rem;
`;
const EnterButton = styled.button`
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    font-weight: normal;
    background: #ffffff;
    border: ${(props) => props.theme.color.secondary} solid 1px;
    border-radius: 0.3rem;
    display:block;
    width:100%;
`;

const CContainer = styled.div`
    padding: 1rem;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    flex-direction: column;
        
    background: rgba(0, 0, 0, 0.7);
    border: none;
    z-index: 2000;
    align-items: center;
    justify-content: center;
`;
const CTitle = styled.h3`
    margin-bottom: 1rem;
`;
const CWrapper = styled.div`
    padding: 1rem;
    min-width:16rem;
    border: #cccccc solid 1px;
    border-radius: 0.5rem;
    background: #ffffff;
`;
const CItem = styled.div`
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
`;
const CButton = styled.button`
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    font-weight: normal;
    ${props => props.dark ? `
        color:#ffffff;
        border: #333333 solid 1px;
        background:#333333;
    ` : `
        border: #ddddd3 solid 1px;
        background:#ffffff;
    `}
    border-radius: 0.3rem;
    flex:1;
`;
