import React, { useContext, useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import swal from "sweetalert";

import Layout from "../Layout";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";
import { _DEF } from "../../../modules/config";

import { SearchInput, SelectForm, InputForm, SegmentedForm, TextForm, DatePicker } from "../../../components/UI/Form";
import Paging, { ToPage, GetParams } from "../../../components/UI/Paging";
import { Separator } from "../../../components/UI/Wrapper";

import EMPTY_PROFILE from "../../../resources/img/empty.png";

import DualListBox from 'react-dual-listbox';

import qs from "query-string";

const MEMBER_TYPES = [
    { label: "개발자", value: "DEVELOPER" },
    { label: "슈퍼어드민", value: "SUPERADMIN" },
    { label: "어드민", value: "ADMIN" },
    { label: "운영자", value: "OPERATOR" },
    { label: "CV", value: "MEMBER" },
];

const CV_STATUS = [
    { label: "대기", value: "W" },
    { label: "정상", value: "Y" },
    { label: "사용안함", value: "N" },
];

const CAREER_TYPE = [
    { label: "EDU", value: "EDU" },
    { label: "EXP", value: "EXP" },
];

export default (props) => {

    const { cfg } = useContext(AppContext);
    const { history, location, match } = props;

    const [mode, setMode] = useState("");
    const [keyword, setKeyword] = useState("");
    const [list, setList] = useState([]);
    const [sites, setSites] = useState([]);
    const [paging, setPaging] = useState(null);
    const [siteIdx, setSiteIdx] = useState(cfg.idx);

    const onAddClick = () => {
        setMode("ADD");
    };

    const onModify = (idx) => {
        setMode(idx);
    };

    const loadPage = (data) => {
        data.siteIdx = siteIdx;

        const req = { path: "/v1/a/lecturer/list", data };
        _API.get(req).then((res) => {
            const { list, siteList, paging } = res.data;
            setList(list);
            // setSites(siteList);
            setPaging(paging);
        });
    };

    const movePage = (page) => {
        ToPage(location, history, { keyword, page });
    };

    const onSearch = (e) => {
        ToPage(location, history, { keyword, page: 1 });
    };

    const onRefresh = () => {
        loadPage({ keyword, page: paging.pageNo });
    };

    const onMemberChange = () => {
        setMode("");
        onRefresh();
    };

    useEffect(() => {
        const params = GetParams(location);
        if (params) {
            setKeyword(params.keyword);
            loadPage(params);
        } else {
            loadPage({ page: getBeforePageNo() });
        }
    }, [location]);

    const getBeforePageNo = () => {
        const search = location?.search;
        if (!search) return 1;

        const params = qs.parse(search);
        return (params?.page || 1);
    }

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-8">
                    <h2>CV 목록</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/admin">HOME</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to={`${location.pathname}`}>CV 관리</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>CV 목록</strong>
                        </li>
                    </ol>
                </div>
                <div className="col-sm-4">
                    <div className="title-action">
                        <button type="button" className="btn btn-primary" onClick={onAddClick.bind(this)}>
                            <i className="fa fa-plus"></i> 추가
                        </button>
                    </div>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className={cx({ "col-md-12": !mode, "col-md-8": mode })}>
                        <div className="ibox">
                            <div className="ibox-title">CV목록</div>
                            <div className="ibox-content ibox-heading">
                                <div className="row">
                                    <div className="col-md-4">
                                        <SearchInput value={keyword} onChange={(e) => setKeyword(e.target.value)} onClick={onSearch} placeholder="검색어를 입력해 주세요." />
                                    </div>
                                </div>
                            </div>
                            <div className="ibox-content">
                                <LecturerList list={list} paging={paging} onChange={onRefresh} onModify={onModify} />
                            </div>
                            {paging && (
                                <div className="ibox-footer">
                                    <Paging paging={paging} pageCallback={movePage} />
                                </div>
                            )}
                        </div>
                    </div>
                    {mode && (
                        <div className={cx({ "col-md-4": true })}>
                            <LecturerForm idx={mode} sites={sites} onChange={onMemberChange} />
                        </div>
                    )}
                </div>

            </div>
        </Layout>
    );
};

const LecturerList = (props) => {
    const { list, paging, onModify, onChange } = props;

    const onChangeState = (item, col, val, e) => {
        if (e) e.preventDefault();

        swal({
            title: `CV관리`,
            text: `상태를 변경하시겠습니까?`,
            icon: "info",
            buttons: ["취소", "예"],
            dangerMode: val === "D" ? true : false,
        }).then((ok) => {
            if (ok) {
                _API.post({
                    path: "/v1/a/lecturer/status",
                    data: {
                        idx: item.idx
                        , status: val
                    },
                }).then((res) => {
                    onChange && onChange()
                });
            }
        });
    }

    return (
        <div className="table-responsive">
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th className="text-center">ID</th>
                        <th className="client-avatar"></th>
                        <th className="text-center">이름</th>
                        <th className="text-center">소속</th>
                        <th className="text-center">사이트명</th>
                        <th className="text-center">등록일</th>
                        <th className="text-center">상태</th>
                        <th className="text-center">편집</th>
                    </tr>
                </thead>
                <tbody>
                    {list.map((item, i) => (
                        <tr key={i}>
                            <td className="text-center">{item.idx}</td>
                            <td className="client-avatar"><img alt="image" src={item.photoUrl ? item.photoUrl : EMPTY_PROFILE} /></td>
                            <td className="text-center">{item.lastName + " " + item.firstName}</td>
                            <td className="text-center">{item.companyName}</td>
                            <td className="text-center">{item.title}</td>
                            <td className="text-center">{moment(item.regDate).format("YYYY-MM-DD")}</td>
                            <td className="text-center">
                                <div className="btn-group">
                                    <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "Y", "btn-success": item.status === "Y" })} onClick={onChangeState.bind(this, item, "status", "Y")}>
                                        정상
                                    </button>
                                    <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "N", "btn-success": item.status === "N" })} onClick={onChangeState.bind(this, item, "status", "N")}>
                                        미사용
                                    </button>
                                    <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "D", "btn-success": item.status === "D" })} onClick={onChangeState.bind(this, item, "status", "D")}>
                                        삭제
                                    </button>
                                </div>
                            </td>
                            <td className="text-center">
                                <div className="btn-group">
                                    <button type="button" className="btn btn-xs btn-warning" onClick={(e) => onModify && onModify(item.idx)}>
                                        <i className="fa fa-edit"></i> 수정
                                    </button>
                                    <Link to={`/admin/lecturer/${item.idx}/career/list?before_page=${paging?.pageNo || 1}`} style={{ textDecorationLine: "none", color: "inherit" }}>
                                        <button type="button" className="btn btn-xs btn-info" onClick={(e) => onModify && onModify(item.idx)}>
                                            <i className="fa fa-mail-forward"></i> 경력
                                        </button>
                                    </Link>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const LecturerForm = (props) => {
    const { idx, sites, onChange } = props;
    const { iam, cfg } = useContext(AppContext);

    const [user, setUser] = useState({ status: 'Y' });

    const [awsKey, setAwsKey] = useState(null);

    const loadPrepare = () => {
        _API.get({ path: "/v1/upload" }).then((res) => {
            setAwsKey(res.data);
        });
    };

    const onDelete = (idxKey, urlKey) => {
        onSave({ ...user, [idxKey]: null, [urlKey]: null });
    };

    const onUploaded = (file) => {
        setUser({ ...user, ...file });
        // onSave({ ...user, ...file });
    };

    const onSave = (param) => {
        let data = { ...user, siteIdx: cfg.idx };
        if (!param.nativeEvent) {
            data = { ...user, ...param };
        }
        if (idx !== "ADD") {
            data.idx = idx;
        }
        if (!data.siteIdx) {
            swal({ title: "알림", text: "사이트를 선택해주세요." });
            return;
        }
        if (!data.lastName) {
            swal({ title: "알림", text: "이름[성] 을 입력해주세요." });
            return;
        }
        if (!data.firstName) {
            swal({ title: "알림", text: "이름을 입력해주세요." });
            return;
        }
        if (!data.companyName) {
            swal({ title: "알림", text: "CV 소속명을 입력해주세요." });
            return;
        }
        if (!data.status) {
            swal({ title: "알림", text: "상태를 선택해주세요." });
            return;
        }
        _API.post({
            path: "/v1/a/lecturer/save",
            data: data,
        }).then((res) => {
            swal({ title: "알림", text: `CV가 ${idx === "ADD" ? "등록" : "수정"} 되었습니다.` }).then(() => {
                onChange && onChange();
            });
        });
    };

    const loadData = () => {
        if (idx === "ADD") {
            setUser({ status: 'Y' });
            return;
        }
        const req = {
            path: "/v1/a/lecturer/info",
            data: { idx },
        };
        _API.get(req).then((res) => {
            setUser({ ...res.data });
        });
    };

    useEffect(() => {
        loadData();
    }, [idx]);

    useEffect(() => {
        loadPrepare();
    }, []);

    return (
        <div className="ibox">
            <div className="ibox-title">CV {idx === "ADD" ? "등록" : "수정"}</div>
            <div className="ibox-content">
                {/* <SelectForm title="사이트" name="siteIdx" items={sites.map( (site) => { return { label : site.title, value : site.idx} } )} value={user?.siteIdx} onChange={(e) => setUser({ ...user, [e.target.name]: e.target.value })} nullTitle="사이트 선택" /> */}
                <InputForm title="성" name="lastName" value={user?.lastName} onChange={(e) => setUser({ ...user, [e.target.name]: e.target.value })} placeholder="이름[성]을 입력해 주세요" />
                <InputForm title="이름" name="firstName" value={user?.firstName} onChange={(e) => setUser({ ...user, [e.target.name]: e.target.value })} placeholder="이름을 입력해 주세요" />
                <InputForm title="소속명" name="companyName" value={user?.companyName} onChange={(e) => setUser({ ...user, [e.target.name]: e.target.value })} placeholder="소속명을 입력해 주세요" />
                <Separator />
                {awsKey && (
                    <LecturerUpload title="사진" aws={awsKey} idxKey="lecturerFileIdx" urlKey="lecturerFileUrl" url={user?.lecturerFileUrl} onUploaded={onUploaded} onDelete={onDelete} description="*권장해상도 110 x 146 (3x4)" />
                )}
                {/* <Separator />
                <SegmentedForm title="상태" items={CV_STATUS} value={user?.status} onClick={(v) => setUser({ ...user, status: v })} /> */}
                <Separator />
                <TextForm title="설명" name="description" placeholder="설명" value={user?.description} onChange={(e) => setUser({ ...user, [e.target.name]: e.target.value })} />
                <Separator />
                <div className="row">
                    <div className="col-md-12 text-right">
                        <div className="btn-group">
                            <button type="button" className="btn btn-primary" onClick={onSave}>
                                <i className="fa fa-save"></i> {idx === "ADD" ? "등록" : "수정"}
                            </button>
                            <button type="button" className="btn btn-default" onClick={onChange}>
                                <i className="fa fa-times"></i> 취소
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const LecturerUpload = (props) => {
    const { title, aws, idxKey, urlKey, url, onUploaded, onDelete, description } = props;

    const [file, setFile] = useState(null);
    const onUpload = () => {
        if (!file) {
            swal("파일을 선택해 주세요.");
            return;
        }
        const req = { aws, file };
        _API.upload(req)
            .then((res) => {
                const { data: img } = res;
                onUploaded && onUploaded({ [idxKey]: img.id, [urlKey]: img.signedUrl });
            })
            .catch((e) => {
                swal(e.message);
            });
    };

    return (
        <Fragment>
            <div className="form-group row">
                <label className="col-sm-3 control-label m-t-sm">{title}</label>
                <div className="col-sm-9">
                    <div className="input-group">
                        <input type="file" className="form-control" onChange={(e) => setFile(e.target.files[0])} accept="image/*" />
                        <span className="input-group-append">
                            <button type="button" className="btn btn-default" onClick={onUpload}>
                                업로드
                            </button>
                        </span>
                    </div>
                </div>
            </div>
            {description && (
                <div className="form-group row">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-9">{description}</div>
                </div>
            )}
            {url && (
                <Fragment>
                    <div className="form-group row">
                        <div className="col-sm-3 text-right">
                            <button className="btn btn-xs btn-danger" onClick={onDelete.bind(this, idxKey, urlKey)}>
                                <i className="fa fa-trash"></i> 삭제
                            </button>
                        </div>
                        <div className="col-sm-9">
                            <img src={url} alt="" className="img-fluid" />
                        </div>
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};