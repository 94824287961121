import React, { Fragment, useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import swal from "sweetalert";

import Modal from "react-modal";
import { AppContext } from "../../../components/AppProvider";
import { ModalCloseButton } from "../../../components/UI/Button";
import { Theme } from "../../../components/GlobalStyles";
import Template from "../../../components/UI/Template";
import Send from "../../../components/UI/Send";

import { _API, _U } from "../../../modules/utils";
import { _DEF, _CFG } from "../../../modules/config";

import Layout from "../Layout";

import { SearchInput, SelectForm, InputForm, SegmentedForm } from "../../../components/UI/Form";
import { Separator } from "../../../components/UI/Wrapper";

import { List } from "./sms.list";

export default (props) => {
    const { cfg } = useContext(AppContext);

    const [isShow, setIsShow] = useState(true);

    const [mode, setMode] = useState("");

    const onRefresh = () => {
        setIsShow(false);
        setTimeout(() => setIsShow(true), 10);
    };

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-8">
                    <h2>발송관리</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/">HOME</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/send/manage/sms">발송관리</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>발송내역</strong>
                        </li>
                    </ol>
                </div>
                <div className="col-sm-4">
                    <div className="title-action">
                        <button
                            type="button"
                            className="btn btn-warning"
                            onClick={(e) => {
                                setMode("TEMPLATE");
                            }}
                        >
                            <i className="fa fa-gear"></i> 템플릿 설정
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary ml-2"
                            onClick={(e) => {
                                setMode("SEND");
                            }}
                        >
                            <i className="fa fa-plus"></i> 발송
                        </button>
                    </div>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className="col-md-8">
                        {/* {isShow && <Certs />} */}
                        <List/>
                    </div>
                    <div className="col-md-4">
                        <CertRequest onChange={onRefresh} sendType="sms" />
                        {/* {isShow && <CertListFromAPIStore sendType="sms" />} */}
                    </div>
                </div>
            </div>
            {mode === "TEMPLATE" && (
                <Modal isOpen={mode == "TEMPLATE"} style={ Theme.modal.template }>
                    <ModalCloseButton
                        className="btn btn-default"
                        onClick={() => {
                            setMode("");
                        }}
                    >
                        <i className="fa fa-remove"></i>
                    </ModalCloseButton>
                    <Template sendType="sms" />
                </Modal>
            )}
            {mode === "SEND" && (
                <Modal isOpen={mode == "SEND"} style={Theme.modal.template}>
                    <ModalCloseButton
                        className="btn btn-default"
                        onClick={() => {
                            setMode("");
                        }}
                    >
                        <i className="fa fa-remove"></i>
                    </ModalCloseButton>
                    <Send sendType="sms" />
                </Modal>
            )}
        </Layout>
    );
};

const Certs = (props) => {
    const { cfg } = useContext(AppContext);

    const [keyword, setKeyword] = useState("");
    const [list, setList] = useState([]);
    const [nextId, setNextId] = useState(null);

    const loadPage = (id) => {
        const req = { path: "https://api.dm9.io/v1/sms/cert", data: { nextId: id, keyword }, apiKey: _CFG.dm9ApiKey,};
        _API.get(req)
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                const { list, nextId } = data;
                if (list.length === 0) {
                    swal({ title: "알림", text: "모든 정보를 조회 하였습니다." });
                } else {
                    if (!id) {
                        setList(list);
                    } else {
                        setList((o) => [...o, ...list]);
                    }
                }
                setNextId(nextId);
            });
    };

    const onNext = () => {
        if (!nextId) {
            return;
        }
        loadPage(nextId);
    };

    const onSearch = (e) => {
        setList([]);
        setNextId(null);
        loadPage();
    };

    useEffect(() => {
        loadPage();
    }, []);

    return (
        <div className="ibox">
            <div className="ibox-title">발신번호 인증</div>
            <div className="ibox-content ibox-heading">
                <div className="row">
                    <div className="col-md-6">
                        <SearchInput value={keyword} onChange={(e) => setKeyword(e.target.value)} onClick={onSearch} placeholder="검색어를 입력해 주세요." />
                    </div>
                </div>
            </div>
            <div className="ibox-content">
                <div className="table-responsive">
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th className="text-center">No.</th>
                                <th className="text-center">제목</th>
                                <th className="text-center">번호</th>
                                <th className="text-center">등록일</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((item, i) => (
                                <tr key={i}>
                                    <td className="text-center">{i + 1}</td>
                                    <td className="text-center">{item.title}</td>
                                    <td className="text-center">{item.id}</td>
                                    <td className="text-center">{moment(item.regDate, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {nextId && (
                <div className="ibox-footer">
                    <button type="button" className="btn btn-block btn-default" onClick={onNext}>
                        <i className="fa fa-arrow-down"></i> 추가 조회
                    </button>
                </div>
            )}
        </div>
    );
};

const CertRequest = (props) => {
    const { cfg } = useContext(AppContext);

    const { onChange, sendType } = props;

    const [isRequest, setIsRequest] = useState(false);
    const [type, setType] = useState("SMS");
    const [code, setCode] = useState("");
    
    const [sender, setSender] = useState(cfg[sendType === "sms" ? "phone" : "email"] || "");
    const [defaultSender, setDefaultSender] = useState(cfg[sendType === "sms" ? "phone" : "email"] || "");

    const [comment, setComment] = useState(cfg.title);
    const [certYn, setCertYn] = useState(cfg[sendType === "sms" ? "phoneConfirm" : "emailConfirm"] || "N");
    
    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [siteList, setSiteList] = useState([]);

    const [minutes, setMinutes] = useState(parseInt(3));
    const [seconds, setSeconds] = useState(parseInt(0));

    const pintypeRadio = [
        { label: "문자인증", value: "SMS" },
        { label: "유선전화", value: "VMS" },
    ];
    
    const handleRequest = () => {

        if( !comment) {
            swal("사이트를 선택해주세요.");
            return;
        }
        
        if( !sender) {
            swal("번호를 선택해주세요.");
            return;
        }

        _API.post({
            path: "https://api.dm9.io/v1/sms/cert/request",
            data: {
                number : sender,
                comment,
                type,
            },
            apiKey: _CFG.dm9ApiKey,
        }).then((res) => {
            console.log(res.data);
            const { result_code } = res.data;

            if (result_code === "500") {
                swal("이미 인증된 번호 입니다.");
            } else {
                setMinutes(3);
                setSeconds(0);
                setIsRequest(true);
            }
        });
    };
    
    const handleCert = () => {
        _API.post({
            path: "https://api.dm9.io/v1/sms/cert/complete",
            data: {
                number : sender,
                comment,
                type,
                code,
            },
            apiKey: _CFG.dm9ApiKey,
        }).then((res) => {
            console.log(res.data);
            const { result_code } = res.data;

            if (result_code === "600") {
                swal("인증코드가 일치하지 않습니다.");
            } else {
                swal("인증되었습니다.");
                setCertYn("Y");

                setIsRequest(false);
                // setSender("");
                setType("SMS");
                setCode("");

                setMinutes(0);
                setSeconds(0);

                saverCert("Y");
            }
        });
    };

    const saverCert = (yN) => {
        
        _API.post({
            path: "/v1/sa/send/manage/cert",
            data: {
                siteIdx,
                [sendType === "sms" ? "phone" : "email"] : sender,
                [sendType === "sms" ? "phoneConfirm" : "emailConfirm"] : yN,
                sendType
            },
        }).then((res) => {
            const { status } = res;
                onChange && onChange();
        });
    }

    const timeOutCert = () => {
        setSeconds(0);
        setMinutes(0);
        swal("인증시간이 초과되었습니다. 재요청하여 인증 바랍니다.");
    }

    useEffect(() => {
        setSeconds(0);
        setMinutes(0);
        setIsRequest(false);
    }, [type])

    return (
        <div className="ibox">
            <div className="ibox-title">발신번호 인증</div>
            <div className="ibox-content">
                <InputForm title="발송번호" type="number" name="sender" value={sender} onChange={(e) => setSender(e.target.value)} readOnly={ defaultSender ? true : false } placeholder="발송번호를 입력해 주세요" />
                { certYn === "N" && <SegmentedForm title="인증구분" items={pintypeRadio} value={type} onClick={(v) => setType(v)} /> }
                <div className="form-group row">
                    <label className="col-sm-3 control-label m-t-sm">
                    { !isRequest ? 
                         "인증여부 " 
                       : "" 
                    }
                    { !isRequest && certYn === ""  && <i className="fa fa-question-circle" style={{fontSize: "15px", color: "#eaba2e"}}></i> }
                    { !isRequest && certYn === "Y" && <i className="fa fa-check-circle-o" style={{fontSize: "15px", color: "blue"}}></i> }
                    { !isRequest && certYn === "N" && <i className="fa fa-ban" style={{ fontSize: "15px", color: "red" }}></i> }

                    </label>
                    
                    {!siteIdx && <div className="col-sm-9"><p style={{marginBottom: "0px", paddingTop: "10px"}}><code> 사이트를 선택해주세요. </code></p></div>}

                    {siteIdx && 
                        (
                            <Fragment>
                                {/* <SegmentedForm title="상태" items={_DEF.SMS_CERT_TYPE} value={type} onClick={(v) => setType(v)} /> */}
                                {/* <Separator /> */}
                                {!isRequest && (
                                    <div className="col-md-9">
                                        { certYn != "Y" && (
                                            <button type="button" className="btn btn-block btn-primary" onClick={handleRequest}>
                                                <i className="fa fa-send"></i> 요청
                                            </button>
                                        )}
                                        { certYn === "Y" && (
                                            <span className="badge badge-success" style={{ marginTop: "9px", fontSize: "14px"}}>인증된 번호입니다.</span>
                                        )}
                                    </div>
                                )}
                                {isRequest && (
                                        <div className="col-md-9">
                                            <button type="button" className="btn btn-block btn-primary" onClick={handleRequest}>
                                                <i className="fa fa-send"></i> 재요청
                                            </button>
                                        </div>
                                )}
                            </Fragment>
                        )
                    }
                    
                </div>
                {isRequest && (
                    <Fragment>
                        <div className="form-group row" style={{marginBottom: "0px"}}>
                            <label className="col-sm-3 control-label m-t-sm">제한시간</label>
                            <div className="col-sm-9" style={{lineHeight: "40px"}}>
                                <Timer mm={minutes} ss={seconds} timeOutCert={timeOutCert}/>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 control-label m-t-sm">&nbsp;</label>
                            <div className="col-sm-9">
                                <input type="number" name="code" className="form-control" value={code} onChange={(e) => setCode(e.target.value)} placeholder="인증번호를 입력해 주세요" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 control-label m-t-sm">&nbsp;</label>
                            <div className="col-md-9">
                                <button type="button" className="btn btn-block btn-success" onClick={handleCert}>
                                    <i className="fa fa-check"></i> 인증
                                </button>
                            </div>
                        </div>
                    </Fragment>
                )}
            </div>
        </div>
    );
};

const CertListFromAPIStore = (props) => {
    const [list, setList] = useState([]);

    const loadData = () => {
        _API.get({ path: "https://api.dm9.io/v1/sms/cert/apistore", apiKey: _CFG.dm9ApiKey, })
            .then((res) => res.data)
            .then((data) => setList(data));
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="ibox">
            <div className="ibox-title">
                인증번호 목록 <small>(API Store 기준)</small>
            </div>
            <div className="ibox-content">
                <div className="table-responsive">
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th className="text-center">No.</th>
                                <th className="text-center">제목</th>
                                <th className="text-center">발송번호</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((item, i) => (
                                <tr key={i}>
                                    <td className="text-center">{i + 1}</td>
                                    <td className="text-center">{item.comment}</td>
                                    <td className="text-center">{item.sendnumber}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

const Timer = ({ mm, ss, timeOutCert }) => {
    const [minutes, setMinutes] = useState(parseInt(mm));
    const [seconds, setSeconds] = useState(parseInt(ss));
  
    const init = () => {
        timeOutCert();
    }

    useEffect(() => {

        const countdown = setInterval(() => {
          if (parseInt(seconds) > 0) {
            setSeconds(parseInt(seconds) - 1);
          }
          if (parseInt(seconds) === 0) {
            if (parseInt(minutes) === 0) {
                init();
                clearInterval(countdown);
            } else {
              setMinutes(parseInt(minutes) - 1);
              setSeconds(59);
            }
          }

        }, 1000);

        return () => {
            clearInterval(countdown);
            
        }
      }, [minutes, seconds]);

      useEffect(() => {
        setMinutes(mm); setSeconds(ss);
      }, [mm, ss])
      
      
    return (
      <div style={{fontSize: "15px", color: "red"}}>
        {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
      </div>
    );
  };