import React, { Fragment, useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import * as FilePond from "filepond";
import { FilePond as FilePondForm } from "react-filepond";
import moment from "moment";
import ReactHlsPlayer from "react-hls-player";

import { AppContext } from "../../../components/AppProvider";
import { _CFG } from "../../../modules/config";
import { _API, _U, _CK } from "../../../modules/utils";

import { SearchInput } from "../../../components/UI/Form";
import Paging, { ToPage, GetParams } from "../../../components/UI/Paging";
import { Separator } from "../../../components/UI/Wrapper";

import Modal from "react-modal";
import { Theme } from "../../../components/GlobalStyles";
import LoadingOverlay from "react-loading-overlay";

import Layout from "../Layout";

export const Example = (props) => {
    const [awsKey, setAwsKey] = useState(null);

    const loadPrepare = () => {
        _API.get({ path: "/v1/upload" }).then((res) => {
            setAwsKey(res.data);
        });
    };

    useEffect(() => {
        loadPrepare();
    }, []);

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-12">
                    <h2>예제</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/dashboard">HOME</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/dev/dashboard">개발자</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/dev/upload/example">업로드</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>예제</strong>
                        </li>
                    </ol>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox">
                            <div className="ibox-title">
                                <h5>인증키 목록</h5>
                            </div>
                            <div className="ibox-content">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th className="text-center">Access Key</th>
                                                <th className="text-center">Secret Key</th>
                                                <th className="text-center">Region</th>
                                                <th className="text-center">Bucket</th>
                                                <th className="text-center">Url</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="text-center">{awsKey?.accessKey}</td>
                                                <td className="text-center">{awsKey?.secretKey}</td>
                                                <td className="text-center">{awsKey?.region}</td>
                                                <td className="text-center">{awsKey?.bucket}</td>
                                                <td className="text-center">{awsKey?.url}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {awsKey && (
                    <div className="row">
                        <div className="col-md-6">
                            <S3Uploader aws={awsKey} />
                        </div>
                        <div className="col-md-6">
                            <S3MultiUploader aws={awsKey} />
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    );
};

const S3Uploader = (props) => {
    const [file, setFile] = useState(null);
    const [feedback, setFeedback] = useState(null);

    const fileSelected = (e) => {
        if (e.target.files.length > 0) {
            setFile(e.target.files[0]);
        }
    };

    const upload = () => {
        if (!file) {
            swal("파일을 선택해 주세요.");
            return;
        }

        const { aws } = props;
        const req = { aws, file };
        _API.upload(req)
            .then((res) => {
                setFeedback(res.data);
            })
            .catch((e) => {
                swal(e.message);
            });
    };

    return (
        <div className="ibox">
            <div className="ibox-title">
                <h5>S3 업로드 (5MB 초과)</h5>
            </div>
            <div className="ibox-content">
                <div className="row">
                    <div className="col-md-12">
                        <div className="input-group">
                            <input type="file" className="form-control" onChange={fileSelected} />
                            <span className="input-group-append">
                                <button type="button" className="btn btn-default" onClick={upload}>
                                    업로드
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
                {feedback && (
                    <div className="row">
                        <div className="col-md-12" style={{ wordBreak: "break-all" }}>
                            {JSON.stringify(feedback)}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const S3MultiUploader = (props) => {
    const { aws } = props;
    const [feedbacks, setFeedbacks] = useState([]);
    useEffect(() => {
        FilePond.setOptions({
            server: {
                process: (fieldName, file, metadata, load, error, progress, abort) => {
                    const req = { aws, file };
                    _API.upload(req).then((res) => {
                        load();
                        setFeedbacks((o) => [...o, res.data]);
                    });
                },
                revert: (uniqueFieldId, load, error) => {},
            },
        });
    }, []);

    return (
        <div className="ibox">
            <div className="ibox-title">
                <h5>S3 업로드 (다중 파일)</h5>
            </div>
            <div className="ibox-content">
                <FilePondForm allowMultiple={true} />
                {feedbacks.map((item, i) => (
                    <div key={i} className="row">
                        <div className="col-md-12" style={{ wordBreak: "break-all" }}>
                            {JSON.stringify(item)}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

/* Video ****************************************************/

export const Video = (props) => {
    const { history, location, match } = props;

    const [aws, setAws] = useState(null);
    const [file, setFile] = useState(null);

    const [keyword, setKeyword] = useState("");
    const [list, setList] = useState([]);
    const [paging, setPaging] = useState(null);

    const [loaded, setLoaded] = useState(true);

    const DarkBackground = styled.div`
        display: none;
        position: relative;
        top: 50%;

        /* background-color: rgb(0, 0, 0); 
        background-color: rgba(0, 0, 0, 0.4); */

        ${props =>
            props.disappear &&
            css`
            display: block; /* show */
        `}
    `;

    const handleFileSelect = (e) => {
        if (e.target.files.length === 0) {
            return;
        }
        setFile(e.target.files[0]);
    };
    const handleFileUpload = (e) => {
        if (!aws) {
            swal({ title: "알림", text: "AWS 정보를 가져오지 못했습니다." });
            return;
        }
        if (!file) {
            swal({ title: "알림", text: "파일을 선택해 주세요." });
            return;
        } else {
            setLoaded(false);
        }

        _API.upload({
            aws,
            file,
            location: `VIDEO/${moment().format("YYYY/MM/DD")}`,
        })
            .then((res) => res.data)
            .then((data) => {
                _API.post({
                    path: "/v1/a/video",
                    data,
                })
                    .then((res) => res.data)
                    .then((data) => {
                        setLoaded(true);
                        swal({ title: "알림", text: "업로드 되었습니다." }).then(() => onRefresh());
                    });
            });
    };

    const loadKeys = () => {
        _API.loadUploadKeys().then((res) => {
            setAws(res.data);
        });
    };

    const loadPage = (data) => {
        const req = { path: "/v1/a/video", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setList(list);
            setPaging(paging);
        });
    };

    const movePage = (page) => {
        ToPage(location, history, { keyword, page });
    };

    const onSearch = (e) => {
        ToPage(location, history, { keyword, page: 1 });
    };

    const onRefresh = () => {
        loadPage({ keyword, page: paging.pageNo });
    };

    useEffect(loadKeys, []);
    useEffect(() => {
        const params = GetParams(location);
        if (params) {
            setKeyword(params.keyword);
            loadPage(params);
        } else {
            loadPage({ page: 1 });
        }
    }, [location]);
    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-12">
                    <h2>비디오</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/dashboard">HOME</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/dev/dashboard">개발자</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/dev/upload/example">업로드</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>비디오</strong>
                        </li>
                    </ol>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className="col-md-12">
                        <div className="ibox">
                            <div className="ibox-title">목록</div>
                            <div className="ibox-content ibox-heading">
                                <div className="row">
                                    <div className="col-md-6">
                                        <SearchInput value={keyword} onChange={(e) => setKeyword(e.target.value)} onClick={onSearch} placeholder="검색어를 입력해 주세요." />
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-group">
                                            <input type="file" className="form-control" accept="video/*" onChange={handleFileSelect} />
                                            <div className="input-group-append">
                                                <button type="button" className="btn btn-primary" onClick={handleFileUpload}>
                                                    <i className="fa fa-upload"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <List list={list} paging={paging} />
                </div>
                {paging && (
                    <div className="row">
                        <div className="col-md-12">
                            <Paging paging={paging} pageCallback={movePage} />
                            <Separator />
                        </div>
                    </div>
                )}
            </div>
            
            {/* <button onClick={() => setLoaded(!loaded)}>Start Loading</button> */}
            <Modal isOpen={!loaded} style={Theme.modal.loadingBar}>
                <DarkBackground disappear={!loaded}>
                    {/* <Loader
                    loaded={false}
                    lines={13}
                    length={20}
                    width={10}
                    radius={30}
                    corners={1}
                    rotate={0}
                    direction={1}
                    color="#a5d8ff"
                    speed={1}
                    trail={60}
                    shadow={false}
                    hwaccel={false}
                    className="spinner"
                    zIndex={2e9}
                    top="50%"
                    left="50%"
                    scale={1.0}
                    loadedClassName="loadedContent"
                    />*/}
                    <LoadingOverlay
                    active={true}
                    // spinner={<BounceLoader />}
                    spinner={true}
                    text="VOD 파일을 업로드 중입니다..."
                    >
                    {/* <p>Some content or children or something.</p> */}
                    </LoadingOverlay>
                </DarkBackground>
            </Modal>

        </Layout>
    );
};

const List = (props) => {
    const { iam } = useContext(AppContext);
    const { list, paging } = props;

    const hlsConfig = {
        debug: _CFG.isLocal(),
        xhrSetup: (xhr, url) => {
            // xhr.withCredentials = true; // do send cookie
            xhr.setRequestHeader("apikey", _CFG.apiKey);
            xhr.setRequestHeader("authorization", _CK.getAuth());
        },
    };

    let virtualNo = paging ? paging.virtualRecordNo : -1;

    return (
        <Fragment>
            {list.map((item, i) => (
                <div className="col-md-4" key={i}>
                    <div className="ibox">
                        <div className="ibox-title" style={{ wordBreak: "break-all" }}>
                            {item.name}
                        </div>
                        <div className="ibox-content">
                            <div className="row">
                                <div className="col-sm-12">{item.encoded === "Y" && <ReactHlsPlayer url={item.encodedUrl} autoplay={false} controls={true} width="100%" height="auto" videoProps={{ poster: `${iam.cdn?.cfUrl}${item.thumbnailPath}` }} hlsConfig={hlsConfig} />}</div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">{item.signedThumbnailUrl && <img src={item.signedThumbnailUrl} alt="" style={{ maxWidth: "100%", maxHeight: 100 }} />}</div>
                                <div className="col-sm-4">{item.signedThumbnailPointUrl && <img src={item.signedThumbnailPointUrl} style={{ maxWidth: "100%", maxHeight: 100 }} alt="" />}</div>
                                <div className="col-sm-4">{item.signedThumbnailGifUrl && <img src={item.signedThumbnailGifUrl} style={{ maxWidth: "100%", maxHeight: 100 }} alt="" />}</div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <ul>
                                        <li style={{ wordBreak: "break-all" }}>URL : {item.encodedUrl}</li>
                                        <li>등록일 : {moment(item.regDate).format("YYYY-MM-DD HH:mm:ss")}</li>
                                        <li>수정일 : {moment(item.modDate).format("YYYY-MM-DD HH:mm:ss")}</li>
                                    </ul>

                                    <a href={item.signedUrl} target="_blank" className="btn btn-block btn-success">
                                        <i className="fa fa-download"></i> 원본 다운로드
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </Fragment>
    );
};
