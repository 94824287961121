import React, { useContext, useState, useEffect, Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import swal from "sweetalert";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

// 파일 업로드
import * as FilePond from "filepond";
import { FilePond as FilePondForm } from "react-filepond";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

// React Select
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';

import Layout from "../Layout";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";
import { _DEF } from "../../../modules/config";

import Wrapper, { Row, FormGroup, Separator } from "../../../components/UI/Wrapper";
import { SearchInput, SelectForm, InputForm, SegmentedForm, FileForm, DatePicker, DateTimePicker, TimePicker, TextForm, SearchCondition } from "../../../components/UI/Form";
import Button from "../../../components/UI/Button";
import Paging, { ToPage, GetParams } from "../../../components/UI/Paging";

import EMPTY_PROFILE from "../../../resources/img/empty.png";

import { default as useContent } from "../../common/content";

export default (props) => {

    const { history, location, match } = props;

    const [mode, setMode] = useState("");

    const { cfg } = useContext(AppContext);

    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [eventIdx, setEventIdx] = useState("");
    const [channelIdx, setChannelIdx] = useState("");
    const [keyword, setKeyword] = useState("");
    const [category, setCategory] = useState("");
    const [contentType, setContentType] = useState("");
    const [list, setList] = useState([]);
    const [paging, setPaging] = useState(null);
    const [eventList, setEventList] = useState([]);
    const [channelList, setChannelList] = useState([]);

    const loadList = (page) => {
        // if(!eventIdx){
        //     swal({ title: "알림", text: "이벤트를 선택해 주세요." });
        //     return;
        // }
        const data = {
            siteIdx,
            eventIdx,
            channelIdx,
            keyword,
            category,
            contentType,
            page,
        };
        const req = { path: "/v1/a/event/session/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setList(list);
            setPaging(paging);
        });
    };

    const loadEventList = (page) => {
        const data = {
            siteIdx,
        };
        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setEventList(list);
        });
    };

    const loadChannelList = (page) => {
        const data = {
            siteIdx,
        };
        const req = { path: "/v1/a/event/channel/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            setChannelList(list);
        });
    };

    const movePage = (page) => {
        loadList(page);
    };

    const onModify = (idx) => {
        setMode(idx);
    };

    const onRefresh = () => {
        loadList(1);
    };

    const onSaveChange = () => {
        setMode("");
        onRefresh();
    }

    const onSearch = (e) => {
        loadList(paging?.pageNo || 1);
    };

    useEffect(() => {
        loadList(1);
        loadEventList();
        loadChannelList();
    }, []);

    useEffect(() => {
        loadList(1);
    }, [eventIdx, channelIdx])

    const onChangeState = (item, col, val, e) => {
        if (e) e.preventDefault();

        swal({
            title: `세션`,
            text: `상태를 변경하시겠습니까?`,
            icon: "info",
            buttons: ["취소", "예"],
            dangerMode: val === "D" ? true : false,
        }).then((ok) => {
            if (ok) {
                _API.post({
                    path: "/v1/a/event/category/status",
                    data: {
                        idx: item.idx,
                        category: "session",
                        status: val,
                    },
                }).then((res) => {
                    loadList(paging?.pageNo || 1);
                });
            }
        });
    }

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-8">
                    <h2>세션</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/admin">HOME</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to={`${location.pathname}`}>이벤트</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>세션</strong>
                        </li>
                    </ol>
                </div>
                <div className="col-sm-4">
                    <div className="title-action">
                        <button type="button" className="btn btn-primary" onClick={(e) => setMode("ADD")}>
                            <i className="fa fa-plus"></i> 추가
                        </button>
                    </div>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className={cx({ "col-md-12": !mode, "col-md-8": mode })}>
                        <div className="ibox">
                            <div className="ibox-title">세션목록</div>
                            <div className="ibox-content ibox-heading">
                                <SearchCondition
                                    sync={true}
                                    eventYn={"Y"}
                                    channelYn={"Y"}
                                    sessionYn={"N"}
                                    onChange={(e) => {
                                        setEventIdx(e.eventIdx);
                                        setChannelIdx(e.channelIdx);
                                        setKeyword(e.keyword);
                                    }}
                                    onSearch={onSearch}
                                    value={{ siteIdx, eventIdx, channelIdx, keyword }}
                                    setEventList={setEventList}
                                    setChannelList={setChannelList}
                                />
                            </div>
                            <div className="ibox-content">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th className="text-center">이벤트명</th>
                                                <th className="text-center">채널명명</th>
                                                <th className="text-center">세션구분</th>
                                                <th className="text-center">세션명</th>
                                                <th className="text-center">기간</th>
                                                <th className="text-center">상태</th>
                                                <th className="text-center">등록일</th>
                                                <th className="text-center">편집</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.map((item, i) => (
                                                <tr key={i}>
                                                    <td className="text-center">
                                                        <a href={`/#/admin/event/form/${item.eventIdx}`} >{item.eventTitle}</a>
                                                    </td>
                                                    <td className="text-center">{item.channelTitle}</td>
                                                    <td className="text-center">{item.sessionType}</td>
                                                    <td className="text-center">{item.title}</td>
                                                    <td className="text-center">{item.startTime} ~ {item.endTime}</td>
                                                    <td className="text-center">
                                                        <div className="btn-group">
                                                            {/* <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "W", "btn-success": item.status === "W" })} onClick={onChangeState.bind(this, item, "status", "W")}>
                                                                대기
                                                            </button> */}
                                                            <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "Y", "btn-success": item.status === "Y" })} onClick={onChangeState.bind(this, item, "status", "Y")}>
                                                                사용
                                                            </button>
                                                            <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "N", "btn-success": item.status === "N" })} onClick={onChangeState.bind(this, item, "status", "N")}>
                                                                미사용
                                                            </button>
                                                            <button type="button" className={cx({ btn: true, "btn-xs": true, "btn-default": item.status !== "D", "btn-success": item.status === "D" })} onClick={onChangeState.bind(this, item, "status", "D")}>
                                                                삭제
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">{moment(item.regDate).format("YYYY-MM-DD")}</td>
                                                    <td className="text-center">
                                                        <div className="btn-group">
                                                            <button type="button" className="btn btn-xs btn-warning" onClick={(e) => onModify && onModify(item.idx)}>
                                                                <i className="fa fa-edit"></i> 수정
                                                            </button>
                                                            {/* <Link to={`/lecturer/${item.idx}/career/list`} style={{textDecorationLine:"none", color: "inherit"}}>
                                                                <button type="button" className="btn btn-xs btn-info" onClick={(e) => onModify && onModify(item.idx)}>
                                                                    <i className="fa fa-mail-forward"></i> 경력
                                                                </button>
                                                            </Link> */}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {paging && (
                                <div className="ibox-footer">
                                    <Paging paging={paging} pageCallback={movePage} />
                                </div>
                            )}
                        </div>
                    </div>
                    {mode && (
                        <div className={cx({ "col-md-4": true })}>
                            <InfoForm idx={mode} onChange={onSaveChange} eventList={eventList} channelList={channelList} />
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
};


const SessionTypeRadio = [
    { label: "LECTURE", value: "LECTURE" }
    , { label: "Break", value: "Break" }
]
const SatelliteYn = [
    // { label: "대기", value: "W" },
    { label: "사용", value: "Y" },
    { label: "미사용", value: "N" },
];
const STATUS_RADIO = [
    // { label: "대기", value: "W" },
    { label: "사용", value: "Y" },
    { label: "미사용", value: "N" },
];

const InfoForm = (props) => {
    const { idx, onChange } = props;
    const [awsKey, setAwsKey] = useState(null);

    const { cfg } = useContext(AppContext);

    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [locDtlList, setLocDtlList] = useState([]);
    const [form, setForm] = useState({});

    const [locationIdx, setLocationIdx] = useState("");

    const [eventList, setEventList] = useState([]);
    const [channelList, setChannelList] = useState([]);
    const [timeValid, setTimeValid] = useState(true);

    const { ivsList, contentList } = useContent({ siteIdx: cfg.idx || "", eventIdx: form?.eventIdx || "" });

    const loadEventList = () => {
        const data = {
            siteIdx,
        };
        const req = { path: "/v1/a/event/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            const newEventList = [];
            for (let i in list) {
                newEventList.push({ label: list[i].title, value: list[i].idx });
            }
            setEventList(newEventList);
        });
    };

    const loadChannelList = (eventIdx) => {
        const data = {
            eventIdx,
            sort: "TERM_DATE",
        };
        const req = { path: "/v1/a/event/channel/list", data };
        _API.get(req).then((res) => {
            const { list, paging } = res.data;
            const newList = [];
            for (let i in list) {
                newList.push({ label: list[i].title + " (" + moment(list[i].progressDate).format("YYYY-MM-DD") + ")", value: list[i].idx });
            }
            setChannelList(newList);
        });
    };

    const onSave = () => {
        const data = { ...form };
        data.startTime = data.startTime ? moment(data.startTime).format("HH:mm") : null;
        data.endTime = data.endTime ? moment(data.endTime).format("HH:mm") : null;

        //임시등록 (cvIdx 값 "" 으로 초기화)
        data.sessionMgrList = data.sessionMgrList?.map((mgr) => ({ ...mgr, cvIdx: (mgr.isTemp ? null : mgr.cvIdx) })) || [];

        if (idx !== "ADD") {
            data.idx = idx;
        }
        if (!data.channelIdx) {
            swal({ title: "알림", text: "채널을 선택해 주세요." });
            return;
        }
        if (!data.title) {
            swal({ title: "알림", text: "채널명을 입력해 주세요." });
            return;
        }
        if (!data.sessionType) {
            swal({ title: "알림", text: "세션 구분을 선택해 주세요." });
            return;
        }
        if (!data.startTime || data.startTime === "Invalid date") {
            swal({ title: "알림", text: "시작 시간을 입력해주세요." });
            return;
        }
        if (!data.endTime || data.endTime === "Invalid date") {
            swal({ title: "알림", text: "종료 시간을 입력해주세요." });
            return;
        }
        if (data.startTime >= data.endTime) {
            swal({ title: "알림", text: "시작시간이 종료시간보다 클 수 없습니다." });
            return;
        }
        if (!timeValid) {
            swal({ title: "알림", text: "해당채널에 같은시간대의 세션이 존재합니다." });
            return;
        }
        if (!data.siteIdx && siteIdx) {
            data.siteIdx = siteIdx;
        }
        _API.post({
            path: "/v1/a/event/session/save",
            data: data,
        }).then((res) => {
            swal({ title: "알림", text: `세션이 ${idx === "ADD" ? "등록" : "수정"} 되었습니다.` }).then(() => {
                onChange && onChange();
            });
        });
    };

    const loadData = () => {
        if (idx === "ADD") {
            setForm({ siteIdx });
            return;
        }
        const req = {
            path: "/v1/a/event/session/list",
            data: { idx },
        };
        _API.get(req).then((res) => {

            loadChannelList(res.data?.eventIdx);
            setForm({
                ...res.data
                , startTime: moment(`${res.data.startTime}`, "HH:mm").toDate() || ""
                , endTime: moment(`${res.data.endTime}`, "HH:mm").toDate() || ""
                , ivsIdx: res.data.sessionIvsIdx
            });
        });
    };

    const loadLocDtlList = (data) => {
        const param = { ...data, locationIdx: locationIdx === "all" ? "" : locationIdx };
        const req = { path: "/v1/a/location/detail/list", data: param };
        _API.get(req).then((res) => {
            const { locationDetailList, info } = res.data;
            setLocDtlList(locationDetailList);
        });
    };

    const loadPrepare = () => {
        _API.get({ path: "/v1/upload" }).then((res) => {
            setAwsKey(res.data);
        });
    };

    const fileUpload = (name, item) => {
        const urlName = name.replace("FileIdx", "SignedUrl");
        setForm({ ...form, [name]: item.id, [urlName]: item.signedUrl, finalFileName: item.name });
    };

    useEffect(() => {
        loadEventList();
        loadData();
        loadLocDtlList();
        loadPrepare();

        if (idx === "ADD") {
            setForm({});
            setChannelList([]);
        }
    }, [idx]);

    const handleDataChange = (e) => {
        let { name, value } = e.target;

        if (name === "eventIdx") {
            if (value) loadChannelList(value);
            else setChannelList([]);
            setForm({ ...form, [name]: value, channelIdx: null });
        } else {
            setForm({ ...form, [name]: value });
        }
    };

    const validSessionTime = () => {
        if (form?.channelIdx && (form?.startTime || form?.endTime)) {
            const req = {
                path: "/v1/a/event/session/list",
                data: {
                    channelIdx: form?.channelIdx,
                    startTime: form?.startTime ? moment(form?.startTime).format("HH:mm") : null,
                    endTime: form?.endTime ? moment(form?.endTime).format("HH:mm") : null,
                    valid: "TIME_CHECK",
                    notInSessionIdx: form?.idx || null,
                },
            };
            _API.get(req).then((res) => {
                const sessionList = res.data.list;
                if (sessionList && sessionList.length > 0) {
                    setTimeValid(false);
                } else {
                    setTimeValid(true);
                }
            });
        }
    };

    useEffect(() => {
        validSessionTime();
    }, [form?.channelIdx, form?.startTime, form?.endTime]);

    const clearSatellite = { satelliteYn: "N", companyName: "", companyLogoFileIdx: null, companyLogoSignedUrl: "", companyLogoFileName: "" };

    return (
        <div className="ibox">
            <div className="ibox-title">세션 {idx === "ADD" ? "등록" : "수정"}</div>
            <div className="ibox-content">
                <SelectForm title="이벤트" name="eventIdx" items={eventList} value={form?.eventIdx} onChange={handleDataChange} nullTitle="이벤트 선택" />
                <SelectForm title="채널" name="channelIdx" items={channelList} value={form?.channelIdx} onChange={handleDataChange} nullTitle="채널 선택" />
                <Separator />
                <InputForm title="세션명" name="title" value={form?.title} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="세션명을 입력해 주세요" />
                <SegmentedForm title="세션구분" items={SessionTypeRadio} value={form?.sessionType} onClick={(v) => { v === "LECTURE" ? setForm({ ...form, sessionType: v, ...clearSatellite }) : setForm({ ...form, sessionType: v }) }} />

                <Separator />
                <SelectForm
                    title="스트리밍 정보"
                    name="ivsIdx"
                    items={ivsList.map((ivs) => {
                        return { label: ivs.name, value: ivs.idx };
                    })}
                    value={form?.ivsIdx}
                    onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                    nullTitle="스트리밍 정보 선택"
                />
                <Separator />
                <SelectForm
                    title="온디맨드 영상"
                    name="eventContentIdx"
                    items={contentList.map((content) => {
                        return { label: content.title, value: content.idx };
                    })}
                    value={form?.eventContentIdx}
                    onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                    nullTitle="온디맨드 영상 선택"
                />
                <Separator />

                {/* {form?.sessionType === "Break" && (
                    <SegmentedForm title="세틀라이트" items={SatelliteYn} value={form?.satelliteYn} onClick={(v) => setForm({ ...form, satelliteYn: v })} />
                )} */}
                {form?.satelliteYn === "Y" && (
                    <>
                        <InputForm title="회사명" name="companyName" value={form?.companyName} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} placeholder="회사명을 입력해 주세요" />
                        <FileForm title="로고" onChange={(v) => fileUpload("companyLogoFileIdx", v)} path={`EVENT/SESSION/COMPANY/${moment().format("YYYY/MM/DD")}`} url={form?.companyLogoSignedUrl} accept="image/*" fileName={form?.companyLogoFileName} id="companyLogoFile" />
                    </>
                )}
                <TimePicker title="시간" value={form?.startTime} onChange={(v) => setForm({ ...form, startTime: v.value })} />
                <TimePicker title=" " value={form?.endTime} onChange={(v) => setForm({ ...form, endTime: v.value })} description={!timeValid ? "해당채널에 같은시간대의 세션이 존재합니다." : null} />
                <TextForm title="설명" name="description" placeholder="설명" value={form?.description} onChange={(e) => setForm({ ...form, description: e.target.value })} />
                {/* <Separator />
                {awsKey && (
                    <S3MultiUploader aws={awsKey} form={form} setForm={setForm} mode={idx} />
                )} */}
                <Separator />
                <CvList form={form} setForm={setForm} siteIdx={siteIdx} />
                <Separator />
                <SegmentedForm title="상태" items={STATUS_RADIO} value={form?.status} onClick={(v) => setForm({ ...form, status: v })} />
                <Separator />
                <div className="row">
                    <div className="col-md-12 text-right">
                        <div className="btn-group">
                            <button type="button" className="btn btn-primary" onClick={onSave}>
                                <i className="fa fa-save"></i> {idx === "ADD" ? "등록" : "수정"}
                            </button>
                            <button type="button" className="btn btn-default" onClick={onChange}>
                                <i className="fa fa-times"></i> 취소
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const S3MultiUploader = (props) => {
    const { aws } = props;
    const { form, setForm, mode } = props;
    const [filePondFiles, setFilePondFiles] = useState();
    const [uploadFileList, setUploadFileList] = useState([]);

    FilePond.registerPlugin(FilePondPluginFileValidateType);

    const deleteFile = (fileIdx, e) => {
        if (e) e.preventDefault();
        const newUploadFileList = [];
        uploadFileList?.map((item, i) => {
            if (item.fileIdx !== fileIdx) {
                newUploadFileList.push(item);
            }
        });
        setUploadFileList(newUploadFileList);
    };

    const previewStyle = {
        position: "relative",
        marginLeft: "15px",
        width: "200px",
        maxWidth: "15vw",
        height: "10vh",
        borderRadius: "20px",
        backgroundSize: "cover",
        backgroundPosition: "50%",
        boxShadow: "0 18px 53px 0 rgb(0 0 0 / 30%)",
    }

    useEffect(() => {
        FilePond.setOptions({
            onaddfile: (error, file) => {
                const req = { aws, file: file.file, path: "EVENT/SESSION/FILE" };
                _API.upload(req).then((res) => {
                    setUploadFileList((o) => [...o, { fileIdx: res.data.id, path: res.data.path, pondId: file.id, signedUrl: res.data.signedUrl }]);
                });
            },
        });
    }, []);

    useEffect(() => {
        setForm({ ...form, sessionFileList: uploadFileList });
    }, [uploadFileList])

    useEffect(() => {
        setUploadFileList(form?.sessionFileList || []);
    }, [form?.idx])

    return (

        <div className="ibox">
            <div className="ibox-title">
                <h5>첨부자료</h5>
            </div>
            <div className="ibox-content">
                <FilePondForm allowMultiple={true}
                    acceptedFileTypes={['image/*']}
                    files={filePondFiles}
                    onupdatefiles={setFilePondFiles}
                />
                {form.sessionFileList?.map((item, i) => (
                    <div className="row" key={i}>
                        <div className="col-md-10" style={previewStyle}>
                            <img
                                src={item.signedUrl}
                                alt=""
                                style={{ maxWidth: "100%", height: "10vh" }} />
                        </div>
                        <div className="col-md-2">
                            <button type="button" className="btn btn-xs btn-danger" onClick={deleteFile.bind(this, item.fileIdx)}><i className="fa fa-trash"></i></button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};


const CvList = (props) => {
    const { form, setForm, siteIdx } = props;

    const [list, setList] = useState([]);
    const [options, setOptions] = useState([]);

    const [mode, setMode] = useState("");

    const [toggle, setToggle] = useState(false);
    const [isInputMgrView, setIsInputMgrView] = useState(false);

    const animatedComponents = makeAnimated();

    const reactSelectRef = useRef(null);

    // CV 목록
    const loadCvList = (data) => {
        const req = { path: "/v1/a/lecturer/list", data: { siteIdx: (siteIdx || -1) } };
        _API.get(req).then((res) => {
            const { list, siteList, paging } = res.data;
            setOptions(labelData(list));
            setList(labelData(form.sessionMgrList));
        });
    };

    const labelData = (list) => {
        return list?.map(
            (cv) => { return { ...cv, label: `${cv.lastName + " " + cv.firstName} `, value: cv.cvIdx ? cv.cvIdx : cv.idx, photoUrl: cv.photoUrl } }
        ) || [];
    }

    const handleChange = (sel, e) => {
        // console.log(reactSelectRef);
        setList(sel);
        setForm((obj) => ({ ...obj, sessionMgrList: sel }))
    }

    // UI 처리
    const setInputMgrView = () => {
        setIsInputMgrView(true);
    }

    const toggleCollapse = (e) => {
        e.preventDefault();

        const $target = e.currentTarget;

        const ibox = $($target).closest('div.ibox');
        const button = $($target).find('i');
        const content = ibox.children('.ibox-content');

        content.slideToggle(200);
        button.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
        ibox.toggleClass('').toggleClass('border-bottom');

        setTimeout(function () {
            ibox.resize();
            ibox.find('[id^=map-]').resize();
        }, 50);
    }

    const dot = (item = { photoUrl: EMPTY_PROFILE }) => ({
        alignItems: 'center',
        display: 'flex',
        ':before': {
            backgroundImage: `url(${item.photoUrl})`,
            backgroundSize: "cover",
            borderRadius: 8,
            content: '" "',
            display: 'block',
            marginLeft: 8,
            height: 25,
            width: 25,
            marginRight: "8px",
        },
    });

    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: 'white' }),
        input: (styles, { data }) => ({ ...styles/* , ...dot(data) */ }),
        placeholder: styles => ({ ...styles, ...dot() }),
        // singleValue: (styles, { data }) => ({ ...styles, ...dot(data) }),
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                ...dot(data),
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            margin: "5px",
            // ...dot(data),
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            // color: data.color,
            ':hover': {
                backgroundColor: data.color,
                color: 'white',
            },
        }),
    };

    useEffect(() => {
        loadCvList(form);
    }, [form.idx, form.siteIdx, reactSelectRef])

    return (
        <div className="ibox ">
            <div className="ibox-title">
                <h5>좌장</h5>
                <span className="label label-info">{list?.length || 0}</span>
                <div className="ibox-tools">
                    <span className="label label-primary" onClick={(e) => setToggle(!toggle)} style={{ marginRight: "3px", cursor: "pointer" }} >
                        편집
                    </span>
                    <a className="collapse-link" href="" onClick={toggleCollapse}>
                        <i className="fa fa-chevron-up"></i>
                    </a>
                </div>
            </div>

            <div className="ibox-content">
                {toggle &&
                    <Fragment>
                        <p>
                            목록에 없다면 <code style={{ cursor: "pointer" }} onClick={setInputMgrView}>추가</code>버튼을 눌러주세요.
                        </p>
                        <CreatableSelect
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={options}
                            defaultValue={list}
                            styles={colourStyles}
                            onChange={handleChange}
                            ref={reactSelectRef}
                            placeholder={"사이트에 등록된 좌장을 선택해주세요."}
                            noOptionsMessage={() => "사이트에 할당된 좌장이 없습니다."}
                        />
                        <Separator />
                        {isInputMgrView &&
                            <InputMgrForm handleChange={handleChange} setOptions={setOptions} form={form} setForm={setForm} setList={setList} list={list} reactSelectRef={reactSelectRef} />
                        }
                    </Fragment>
                }
                <div>
                    <div className="feed-activity-list">

                        {list.map((item, i) => (
                            <div className="feed-element" key={i}>
                                <a className="float-left" href="" onClick={(e) => e.preventDefault()}>
                                    <img alt="image" className="rounded-circle" src={item.photoUrl ? item.photoUrl : EMPTY_PROFILE} />
                                </a>
                                <div className="media-body ">
                                    <small className="float-right">{item.title}</small>
                                    <strong>{item.lastName + " " + item.firstName}</strong> {item.companyName} <br />
                                    <small className="text-muted">{item.description}</small>
                                </div>
                            </div>
                        ))}

                    </div>

                    <button className="btn btn-primary btn-block m-t" onClick={toggleCollapse} ><i className="fa fa-arrow-up"></i> 숨기기</button>

                </div>

            </div>
        </div>
    )
}

const InputMgrForm = (props) => {
    const { handleChange, setOptions, list, setList } = props;
    const { form, setForm, reactSelectRef } = props;

    const [mgrForm, setMgrForm] = useState({});

    const addOptions = () => {
        const data = { ...mgrForm };

        if (!data.lastName) {
            swal({ title: "알림", text: "성을 입력해 주세요." });
            return;
        }
        if (!data.firstName) {
            swal({ title: "알림", text: "이름을 입력해 주세요." });
            return;
        }
        if (!data.companyName) {
            swal({ title: "알림", text: "소속을 입력해 주세요." });
            return;
        }

        const mgrFormData = {
            isTemp: true
            , label: `${mgrForm.lastName + " " + mgrForm.firstName} `
            , value: `TEMP_${mgrForm.lastName}${mgrForm.firstName}`
            , photoUrl: mgrForm.photoUrl || ""
            , title: "임시등록"
            , ...mgrForm
            // , description ,

        };

        // setOptions((cv) => [...cv, mgrFormData ]);
        // setForm((obj) => ({...obj , sessionMgrList : [...list, mgrForm]}));
        reactSelectRef?.current.select.props.onChange([...list, mgrFormData], { action: "select-option", option: mgrFormData, name: undefined });
        setMgrForm({});
    }

    useEffect(() => {
        setMgrForm({});
    }, [form]);

    return (
        <Fragment>
            <div className="row" style={{ marginBottom: "1rem" }}>
                <div className="col-md-12"><input type="text" name="companyName" value={mgrForm?.companyName || ""} placeholder="소속" className="form-control" onChange={(e) => setMgrForm({ ...mgrForm, [e.target.name]: e.target.value })} /></div>
            </div>
            <div className="row" style={{ marginBottom: "1rem" }}>
                <div className="col-md-5"><input type="text" name="lastName" value={mgrForm?.lastName || ""} placeholder="성(Last Name)" className="form-control" onChange={(e) => setMgrForm({ ...mgrForm, [e.target.name]: e.target.value })} /></div>
                <div className="col-md-7"><input type="text" name="firstName" value={mgrForm?.firstName || ""} placeholder="이름(First Name)" className="form-control" onChange={(e) => setMgrForm({ ...mgrForm, [e.target.name]: e.target.value })} /></div>
            </div>
            <div className="row">
                <div className="col-md-12 text-right">
                    <div className="btn-group">
                        <button type="button" className="btn btn-success" onClick={addOptions}>
                            <i className="fa fa-plus"></i> 추가
                        </button>
                    </div>
                </div>
            </div>
            <Separator />
        </Fragment>
    )
}