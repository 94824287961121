import React, { useContext, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import Layout from "../Layout";

const testDate = [
    {
        type : "important",
        label : "A",
        openYn : "Y",
        title : "BlahBlah 블라블라.",
        regIdNm : "작성자",
        regDt : "6.10 AM",
        delYn : "N",
    },
    {
        type : "important",
        label : "B",
        openYn : "N",
        title : "Lorem ipsum dolor noretek imit set.",
        regIdNm : "작성자",
        regDt : "2/15",
        delYn : "N",
    },
    {
        label : "A",
        openYn : "Y",
        title : "그랬대요.",
        regIdNm : "작성자",
        regDt : "2/16",
        delYn : "N",
    },
    {
        type : "important",
        label : "C",
        openYn : "N",
        title : "휴가기간 부재알림 메일설정 및 수신메일 자동전달",
        regIdNm : "작성자",
        regDt : "2/17",
        delYn : "Y",
    },
    {
        label : "C",
        openYn : "Y",
        title : "Lorem ipsum dolor noretek imit set.",
        regIdNm : "Anna Smith",
        regDt : "6.10 AM",
        delYn : "N",
    },
]

function Board(props) {

    const [list, setList] = useState(testDate);

    const [condition, setCondition] = useState({
        openYn : "",
        label : "",
    });

    const fnRefresh = () => {
        setList(testDate);
    }

    const fnFilter = (field,value) => {
        // console.log(field,value,list);
        setCondition((o) => {
            return { ...o, [field]:value };
        });
    }

    useEffect(() => {
        setList((row) => {
            let filterdRow = testDate;
            for(let propt in condition){
                if(condition[propt]){
                    filterdRow = filterdRow.filter((r)=>r[propt] === condition[propt]);
                }
            }
            return filterdRow;
        })

        return () => {

        }

    }, [condition])

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-12">
                    <h2>게시판</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/admin">HOME</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>게시판</strong>
                        </li>
                    </ol>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                
                <div className="row">
                    <div className="col-lg-3">
                        <div className="ibox ">
                            <div className="ibox-content mailbox-content">
                                <div className="file-manager">
                                    <Link to={"/admin/board/detail"} className="btn btn-block btn-primary compose-mail">
                                        작성하기
                                    </Link>
                                    <div className="space-25"></div>
                                    <h5>폴더</h5>
                                    <ul className="folder-list m-b-md" style={{padding: "0"}}>
                                        <li><a href="" onClick={(e) => { e.preventDefault(); alert("준비중"); }}> <i className="fa fa-certificate"></i> Important <span className="label label-warning float-right">{testDate.filter((row) => row.type === "important").length || 0}</span></a></li>
                                        <li><a href="" onClick={(e) => { e.preventDefault(); alert("준비중"); }}> <i className="fa fa-trash-o"></i> Trash <span className="label label-danger float-right">{testDate.filter((row) => row.delYn === "Y").length || 0}</span></a></li>
                                    </ul>
                                    <h5>종류</h5>
                                    <ul className="category-list" style={{padding: "0"}}>
                                        <li><a href="#"> <i className="fa fa-circle text-navy"></i> 공개 </a></li>
                                        <li><a href="#"> <i className="fa fa-circle text-warning"></i> 비공개</a></li>
                                    </ul>

                                    <h5 className="tag-title">카테고리</h5>
                                    <ul className="tag-list" style={{padding: "0"}}>
                                        <li><a href="" onClick={(e) => { e.preventDefault(); fnFilter("label","A"); }} ><i className="fa fa-tag"></i>A세미나</a></li>
                                        <li><a href="" onClick={(e) => { e.preventDefault(); fnFilter("label","B"); }} ><i className="fa fa-tag"></i>학술</a></li>
                                        <li><a href="" onClick={(e) => { e.preventDefault(); fnFilter("label","C"); }} ><i className="fa fa-tag"></i>부스</a></li>
                                    </ul>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9 animated fadeInRight">
                        <div className="mail-box-header">

                            <form method="get" action="index.html" className="float-right mail-search">
                                <div className="input-group">
                                    <input type="text" className="form-control form-control-sm" name="search" placeholder="제목, 작성자, 내용"/>
                                    <div className="input-group-append">
                                        <button type="submit" className="btn btn-sm btn-primary">
                                            검색
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <h2>
                                게시중 ({list.length || 0})
                            </h2>
                            <div className="mail-tools tooltip-demo m-t-md">
                                {/* <div className="btn-group float-right">
                                    <button className="btn btn-white btn-sm"><i className="fa fa-arrow-left"></i></button>
                                    <button className="btn btn-white btn-sm"><i className="fa fa-arrow-right"></i></button>
                                </div> */}

                                <button className="btn btn-white btn-sm" data-toggle="tooltip" data-placement="left" title="Refresh inbox" onClick={fnRefresh}><i className="fa fa-refresh"></i>새로고침</button>
                                {/* <button className="btn btn-white btn-sm" data-toggle="tooltip" data-placement="top" title="Mark as read"><i className="fa fa-eye"></i> </button>
                                <button className="btn btn-white btn-sm" data-toggle="tooltip" data-placement="top" title="Mark as important"><i className="fa fa-eye-slash"></i> </button> */}
                                <button className="btn btn-white btn-sm" data-toggle="tooltip" data-placement="top" title="Mark as read" onClick={(e) => { fnFilter("openYn","Y"); }} ><i className="fa fa-circle text-navy"></i> </button>
                                <button className="btn btn-white btn-sm" data-toggle="tooltip" data-placement="top" title="Mark as important" onClick={(e) => { fnFilter("openYn","N"); }} ><i className="fa fa-circle text-warning"></i> </button>
                                <button className="btn btn-white btn-sm" data-toggle="tooltip" data-placement="top" title="Move to trash"><i className="fa fa-trash-o"></i> </button>

                            </div>
                        </div>
                        <div className="mail-box">

                                <table className="table table-hover table-mail">
                                <tbody>
                                    {list.map((row,index)=>(
                                        <tr className={row.openYn === "Y" ? "read" : "unread"} key={index}>
                                            <td className="check-mail">
                                                <input type="checkbox" className="i-checks"/>
                                            </td>
                                            <td className=""><i className={`fa fa-circle ${row.openYn === "Y" ? "text-navy" : "text-warning"}`}></i></td>
                                            <td className="mail-subject"><a href="mail_detail.html">{row.title}</a></td>
                                            <td className=""><i className="fa fa-paperclip"></i></td>
                                            <td className="mail-ontact"><a href="mail_detail.html">{row.regIdNm}</a></td>
                                            <td className="text-right mail-date">{row.regDt}</td>
                                        </tr>
                                    ))}
                                </tbody>
                                </table>

                        </div>
                    </div>
                </div>

            </div>
        </Layout>
    )
}

export default Board;