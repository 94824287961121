import React, { useContext, useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import styled from "styled-components";
import Socket from "../../../../components/UI/Socket";

import { _API, _U } from "../../../../modules/utils";

export default (props) => {
    const { history, location, match } = props;
    const { idx } = match.params;

    const [isConnect, setIsConnect] = useState(false);
    const [question, setQuestion] = useState(null);
    const [baseSize, setBaseSize] = useState(6);
    const [isSizeControl, setIsSizeControl] = useState(false);

    const debugSocket = false; // _CFG.isLocal();
    const sendChannel = () => {
        _U.postMessage("SOCKET-SEND", {
            action: "CHANNEL",
            data: {
                type: "QUESTION-MONITOR",
                channel: idx,
            },
        });
    };

    const loadData = () => {
        _API.get({
            path: "/v1/a/event/console/question/monitor",
            data: {
                channelIdx: idx,
            },
        })
            .then((res) => res.data)
            .then((data) => {
                setQuestion(data);
            });
    };

    const onReceivedMessage = (e) => {
        _U.parseMessage(e)
            .then((data) => {
                switch (data.code) {
                    case "SOCKET-RECEIVED":
                        return data.data ? JSON.parse(data.data) : null;
                }
            })
            .then((data) => {
                if (data?.mode === "QUESTION-MONITOR") {
                    loadData();
                }
                else if (data?.mode === "CHANGE-LECTURE") {
                    loadData();
                }

            });
    };

    useEffect(() => {
        loadData();
        window.addEventListener("message", onReceivedMessage);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, []);

    const onConnected = () => {
        setIsConnect(true);
        sendChannel();
    };
    const onDisconnected = () => {
        setIsConnect(false);
    };

    useEffect(() => {
        sendChannel();
        const timer = setInterval(sendChannel, 10000);
        return () => clearInterval(timer);
    }, [idx]);

    let fontSize = baseSize;
    // const korean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
    // if (korean.test(question)) {
    //     if (question?.length > 24) {
    //         fontSize *= 24 / question?.length;
    //     }
    // } else {
    //     if (question?.length > 36) {
    //         fontSize *= 36 / question?.length;
    //     }
    // }

    return (
        <Container>
            {question ? (
                <Wrapper>
                    <Title>
                        <span onClick={(e) => setIsSizeControl(!isSizeControl)}>Q &amp; A</span>
                        {isSizeControl && (
                            <div className="input-group">
                                <input type="number" className="form-control" value={baseSize} onChange={(e) => setBaseSize(e.target.value)} />
                                <div className="input-group-append">
                                    <div className="btn-group">
                                        <button className="btn btn-success" onClick={(e) => setBaseSize(parseFloat((baseSize + 0.1).toFixed(1)))}>
                                            <i className="fa fa-plus"></i>
                                        </button>
                                        <button className="btn btn-danger" onClick={(e) => setBaseSize(parseFloat((baseSize - 0.1).toFixed(1)))}>
                                            <i className="fa fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Title>
                    <Question fontSize={fontSize}>{question.question}</Question>
                </Wrapper>
            ) : (
                <Wait>
                    <div>Q &amp; A</div>
                </Wait>
            )}
            <SocketWrapper debug={debugSocket}>
                <Socket debug={debugSocket} onConnected={onConnected} onDisconnected={onDisconnected} />
            </SocketWrapper>
        </Container>
    );
};

const Container = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    padding: 2rem;
    background-color: #0b75df;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Wait = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;

    align-items: center;
    justify-content: center;

    div {
        padding: 1vh 3vw;
        // background: #3e3e3e;
        font-size: 10vw;
        font-weight: bold;
        color: #ffffff;
    }
`;
const SocketWrapper = styled.div`
    position: fixed;
    right: 0;
    bottom: 0;
    ${(props) =>
        props.debug &&
        `
        width:450px; height:250px;
    `}
`;

const Title = styled.h1`
    position: absolute;
    margin: 0 0 0 -15vw;
    padding: 1vh;
    width: 30vw;
    height: 6vh;
    top: 1vh;
    left: 50%;
    background: #ffffff;
    text-align: center;
    z-index: 1;
    border-radius: 3vh;

    span {
        font-size: 2rem;
        color: #0b75df;
    }

    img {
        cursor: pointer;
        max-width: 100%;
        max-height: 100%;
    }
    button {
        font-size: 0.8rem;
    }
`;
const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    border: white solid 1rem;
`;

const Question = styled.div`
    flex: 1;
    font-size: ${(props) => (props.fontSize ? `${props.fontSize}rem` : "10rem")};
    word-break: break-all;
    text-align: center;
    color: white;
`;
