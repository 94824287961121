import React, { Fragment, useContext, useEffect, useState } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";

import Modal from "react-modal";
import styled from "styled-components";
import swal from "sweetalert";
import moment from 'moment';

import { AppContext } from "../../../components/AppProvider";
import { _API, _U, _CK } from "../../../modules/utils";

import { H2 } from "./UI/Components";

import { Theme } from "../../../components/GlobalStyles";

export default (props) => {
    const { cfg, auth, iam } = useContext(AppContext);
    const event = cfg.event || null;
    const [content, setContent] = useState("");

    if (!event) {
        return null;
    }

    useEffect(() => {
        setContent(event?.introduce);
    }, [event?.introduce])

    return (
        <>
            <H2>인사말</H2>
            <div
                className=""
                dangerouslySetInnerHTML={{
                    __html: content,
                }}
            ></div>
        </>
    );
};