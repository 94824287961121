import React, { useContext, useEffect, useState, useRef, Fragment } from "react";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";
import ReactHlsPlayer from "react-hls-player";

import { AppContext } from "../../../components/AppProvider";

import { _API, _U, _CK } from "../../../modules/utils";
import { _CFG } from "../../../modules/config";

import { Loading } from "../../../components/UI/Loading";
import AmazonIVSWorkaround from "../../../components/AmazonIVSWorkaround";

export default (props) => {
    const { match } = props;
    const { channel } = match.params;
    const { auth, cfg, iam, socketConnect } = useContext(AppContext);
    const { idx: siteIdx, event } = cfg;
    const { eventIdx } = event;

    const [isLogined, setIsLogined] = useState(false);
    const [isLoadSession, setIsLoadSession] = useState(false);
    const [sessions, setSessions] = useState([]);
    const [session, setSession] = useState(null);
    const [lecture, setLecture] = useState(null);
    const [quiz, setQuiz] = useState(null);
    const [changeLecture, setChangeLecture] = useState(false);

    const sendChannel = () => {
        _U.postMessage("SOCKET-SEND", {
            action: "CHANNEL",
            data: {
                type: "PARTICIPANT",
                channel: channel,
            },
        });
    };

    const loadSessions = () => {
        _API.get({
            path: "/v1/site/event/sessions",
            data: {
                siteIdx,
                eventIdx,
                channelIdx: channel,
            },
        }).then((res) => {
            const { list } = res.data;
            setSessions(list);
            setIsLoadSession(true);
            setChangeLecture(true);
        });
    };

    const selectSession = () => {
        if (!isLoadSession || sessions?.length === 0) {
            setSession(null);
            return;
        }
        // const now = moment();
        // const day = now.format("YYYY-MM-DD");
        const fs = sessions?.list?.filter((s) => {
            // const start = moment(`${day} ${s.startDate}`, "YYYY-MM-DD HH:mm:ss");
            // const end = moment(`${s.endDate}`, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD");
            // return now.isBefore(end);
            if (s.monitorYn === "Y") {
                return s;
            }
        });

        // if (fs.length === 0) {
        //     setSession(null);
        //     return;
        // }
        setSession(sessions[0]);
        selectLecture(fs[0]);
    };

    const selectLecture = (session) => {
        if (!session) {
            return;
        }

        const fs = session?.lectureList?.filter((s) => {
            if (s.monitorYn === "Y") {
                return s;
            }
        });

        if (fs.length === 0) {
            setLecture(null);
            return;
        }

        setLecture(session[0]);
    };

    const sendAlive = () => {
        if (!session || !isLogined) {
            return;
        }
        _API.post({
            path: "/v1/site/event/alive",
            data: {
                siteIdx,
                eventIdx,
                channelIdx: channel,
            },
        }).then((res) => {
            sendChannel();
        });
    };

    const onQuestion = () => {
        if (!session) {
            return;
        }

        swal({
            text: "질문을 입력해 주세요.",
            content: "input",
            buttons: ["취소", "보내기"],
        }).then((q) => {
            if (q) {
                _API.post({
                    path: "/v1/site/event/question",
                    data: {
                        siteIdx,
                        eventIdx,
                        channelIdx: channel,
                        sessionIdx: session.idx,
                        lectureIdx: lecture.idx,
                        question: q,
                    },
                }).then((res) => {
                    _U.postMessage("SOCKET-SEND", {
                        action: "QUESTION",
                        data: {
                            channel: channel,
                        },
                    });
                    swal({ title: "알림", text: "질문을 등록했습니다." });
                });
            }
        });
    };

    const onReceivedMessage = (e) => {
        _U.parseMessage(e)
            .then((data) => {
                switch (data.code) {
                    case "SOCKET-RECEIVED":
                        return data.data ? JSON.parse(data.data) : null;
                }
            })
            .then((data) => {
                if (data?.mode === "QUIZ") {
                    setQuiz(data.quiz);
                }
                if (data?.mode === "CHANGE-LECTURE") {
                    loadSessions();
                }
            });
    };

    useEffect(() => {
        window.addEventListener("message", onReceivedMessage);

        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, []);

    useEffect(() => {
        if (socketConnect && !session) {
            sendChannel();
            const sa = setInterval(sendAlive, 10000);
            return () => clearInterval(sa);
        }
    }, [socketConnect]);

    useEffect(() => {
        if (event.useLogin === "Y" && !_CK.isLogin()) {
            swal({ title: "알림", text: "로그인 후 이용이 가능합니다." }).then(() => {
                window.channel.href = "#/";
            });
            return;
        }
        loadSessions();
        setIsLogined(_CK.isLogin());
    }, [auth]);

    useEffect(() => {
        if (changeLecture) {
            selectSession(sessions);
            setChangeLecture(false);
        }
    }, [changeLecture, sessions]);

    // useEffect(() => {
    //     if (isLoadSession) {
    //         selectSession(sessions);
    //         const ss = setInterval(selectSession, 30000);
    //         return () => clearInterval(ss);
    //     }
    // }, [isLoadSession, sessions]);

    useEffect(() => {
        if (session) {
            sendAlive();
            const sa = setInterval(sendAlive, 10000);
            return () => clearInterval(sa);
        }
    }, [session]);

    useEffect(() => {
        if (quiz) {
            setTimeout(() => {
                setQuiz(null);
            }, 15000);
        }
    }, [quiz]);

    const isIvs = (playbackUrl) => {
        return playbackUrl?.includes("live-video.net");
    };

    if (event.useLogin === "Y" && !_CK.isLogin()) {
        return null;
    }

    if (!isLoadSession) {
        return <Loading title="세션을 조회하고 있습니다." background="rgba(0, 0, 0, 0.1)" />;
    }

    if (!session) {
        return <Waiting>강의를 준비중입니다.</Waiting>;
    }

    const hlsConfig = {
        debug: _CFG.isLocal(),
        xhrSetup: (xhr, url) => {
            // xhr.withCredentials = true; // do send cookie
            xhr.setRequestHeader("apikey", _CFG.apiKey);
            xhr.setRequestHeader("authorization", _CK.getAuth());
        },
    };

    return (
        <Fragment>
            <Title>
                {session?.title}
                <Principal>
                    좌장 : <span dangerouslySetInnerHTML={{ __html: session?.principal || getPrincipal(sessions) }} />
                </Principal>
            </Title>
            <Console>
                <CPWrapper>
                    <CPlayer>
                        {isIvs(getStreamUrl(event, channel)) ? (
                            <AmazonIVSWorkaround url={getStreamUrl(event, channel)} />
                        ) : (
                            <video
                                width="320"
                                height="240"
                                src={
                                    "https://cdn-webinar.dmon9.com/TEMP/EVENT/CONTENT/VIDEO_FILE/a2fdad090f854c699f3e6a715e0b0d72.mp4?Expires=1615374041&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4td2ViaW5hci5kbW9uOS5jb20vVEVNUC9FVkVOVC9DT05URU5UL1ZJREVPX0ZJTEUvYTJmZGFkMDkwZjg1NGM2OTlmM2U2YTcxNWUwYjBkNzIubXA0IiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNjE1Mzc0MDQxfX19XX0_&Signature=IY1uM0LfC3ibV4V0n7HCiv32weGJiNElO9P883wTuqI8vlh33EMyLZ6BJ8MevO03fEWAh7h~JhskiAjkqlG3RxY4WzATu2dF5k1purO5zOyz~Mlo94AswRLIh~n8LAZm82vj9v1KUMwrCvCZsrVi-Z94GLTpLxOcoDKX1nx~vdOamQRKfWEM6kygcJiTEeep94C40IcmE869lv7ds5l9cN4o4Rv2PbsmRcQxmeGA0o1ATsYlYjgZ7ybbx0hnvTpn7DuTFcWkLV1y3lU6o9uXRM5M2kr~bxRtCLaZcfwkGGe8vBc-Z-HmN-WVQrOM04x~5-Vu3P9YJaNyiU0olLf6vQ__&Key-Pair-Id=APKAIPDWK5SA7M4TMD7A"
                                }
                                controls
                                autoPlay
                            ></video>
                        )}
                    </CPlayer>
                    {/* <div className="col-sm-12"><ReactHlsPlayer url={getStreamUrl(event, channel)} autoplay={false} controls={true} width="100%" height="auto" hlsConfig={hlsConfig} /></div> */}
                </CPWrapper>
                <CIWrapper>
                    {lecture ? (
                        <CIQuestion onClick={onQuestion}>
                            <i className="fa fa-question"></i> 질문하기
                        </CIQuestion>
                    ) : (
                        <CIQuestion>강의가 준비중 입니다.</CIQuestion>
                    )}

                    {lecture?.description && (
                        <CIItem>
                            <CITitle>강의내용</CITitle>
                            <CIDescription dangerouslySetInnerHTML={{ __html: lecture.description }} />
                        </CIItem>
                    )}

                    {lecture?.lectureFileList.length > 0 && (
                        <CIItem>
                            <CITitle>강의자료</CITitle>
                            <CIFiles>
                                {lecture.lectureFileList.map((item, i) => (
                                    <li key={i}>
                                        {item.type === "C" && <span className="cv">CV</span>}
                                        <a href={`${item.signedUrl}`} target="_blank">
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </CIFiles>
                        </CIItem>
                    )}

                    {session?.files.length > 0 && (
                        <CIItem>
                            <CITitle>세션자료</CITitle>
                            <CIFiles>
                                {session.files.map((item, i) => (
                                    <li key={i}>
                                        {item.type === "C" && <span className="cv">CV</span>}
                                        <a href={`${item.signedUrl}`} target="_blank">
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </CIFiles>
                        </CIItem>
                    )}
                    {session?.description && (
                        <CIItem>
                            <CITitle>세션내용</CITitle>
                            <CIDescription dangerouslySetInnerHTML={{ __html: session.description }} />
                        </CIItem>
                    )}
                </CIWrapper>
                <Chat {...{ siteIdx, eventIdx, siteEventSessionIdx: session.idx, channelIdx: channel }} />
            </Console>
            {quiz && <Quiz {...{ siteIdx, eventIdx, channel }} quiz={quiz} onSended={() => setQuiz(null)} />}
        </Fragment>
    );
};

const Chat = (props) => {
    const { iam } = useContext(AppContext);
    const { siteIdx, eventIdx, siteEventSessionIdx, channelIdx } = props;
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState("");
    const [isSendProc, setIsSendProc] = useState(false);
    const endRef = useRef(null);

    const scrollToBottom = () => {
        endRef?.current?.scrollIntoView({ behavior: "smooth" });
    };

    const onKeyDown = (e) => {
        if (e.key === "Enter") {
            onSend();
        }
    };

    const onSend = () => {
        if (!message) {
            return;
        }
        if (isSendProc) {
            return;
        }
        setIsSendProc(true);

        _U.postMessage("SOCKET-SEND", {
            action: "CHAT",
            data: {
                mode: "CHAT",
                id: `${siteIdx}_${eventIdx}_${channelIdx}_PARTICIPANT_${iam?.idx}`,
                channel: channelIdx,
                name: iam?.name || "익명",
                memType: "PARTICIPANT",
                message,
            },
        });
        setMessage("");
        setIsSendProc(false);
    };

    const onReceivedMessage = (e) => {
        _U.parseMessage(e)
            .then((data) => {
                switch (data.code) {
                    case "SOCKET-RECEIVED":
                        return data.data ? JSON.parse(data.data) : null;
                }
            })
            .then((data) => {
                if (data?.mode === "CHAT") {
                    setMessages((o) => [...o.slice(-99), data]);
                }
            });
    };

    useEffect(() => {
        window.addEventListener("message", onReceivedMessage);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, []);

    useEffect(scrollToBottom, [messages]);

    return (
        <CCWrapper>
            <CCTitle>채팅</CCTitle>
            <CCContent>
                {messages.map((item, i) => (
                    <CCMessage key={i} isAdmin={item.memType === "OPERATOR"} isMe={item.semi === iam?.idx}>
                        <span>{item.name}</span>
                        {item.message}
                    </CCMessage>
                ))}
                <div ref={endRef} />
            </CCContent>
            <CCSend>
                <input type="text" placeholder="내용을 입력해 주세요" value={message || ""} onChange={(e) => setMessage(e.target.value)} onKeyDown={onKeyDown} />
                <button type="button" onClick={onSend}>
                    <i className="fa fa-send"></i>
                </button>
            </CCSend>
        </CCWrapper>
    );
};

const Quiz = (props) => {
    const { siteIdx, eventIdx, channel: channelIdx, quiz, onSended } = props;

    const onQuizClick = (answer) => {
        _API.post({
            path: "/v1/site/event/quiz",
            data: {
                siteIdx,
                eventIdx,
                channelIdx,
                quizIdx: quiz.idx,
                answer,
            },
        }).then((res) => {
            swal({ title: "알림", text: "참여해 주셔서 감사합니다." }).then(() => {
                onSended && onSended();
            });
        });
    };

    return (
        <QContainer>
            <QWrapper>
                <QTitle dangerouslySetInnerHTML={{ __html: quiz.question }} />
                {quiz.item1 && <QAnswer onClick={onQuizClick.bind(this, 1)}>{quiz.item1}</QAnswer>}
                {quiz.item2 && <QAnswer onClick={onQuizClick.bind(this, 2)}>{quiz.item2}</QAnswer>}
                {quiz.item3 && <QAnswer onClick={onQuizClick.bind(this, 3)}>{quiz.item3}</QAnswer>}
                {quiz.item4 && <QAnswer onClick={onQuizClick.bind(this, 4)}>{quiz.item4}</QAnswer>}
                {quiz.item5 && <QAnswer onClick={onQuizClick.bind(this, 5)}>{quiz.item5}</QAnswer>}
            </QWrapper>
        </QContainer>
    );
};

const getPrincipal = (list) => {
    const fl = list.filter((item) => !!item.principal);
    if (fl.length === 0) {
        return "";
    }
    return fl[0].principal;
};

const getStreamUrl = (event, channel) => {
    const { channels } = event;
    const thisChannel = event.channel.filter((c) => c.idx === parseInt(channel))[0];

    const fl = thisChannel.lectures;
    return fl.length === 0 ? "" : fl[0].ivsPlaybackUrl;
};

/**** Styled-Components *******************/
const Waiting = styled.div`
    margin: 10rem 0rem;
    text-align: center;
`;
const Title = styled.h2`
    font-size: 1.25rem;
`;
const Principal = styled.small`
    float: right;
    clear: right;
    font-size: 0.9rem;

    @media only screen and (max-width: 768px) {
        float: none;
        clear: none;
        display: block;
        text-align: right;
    }
`;

const Console = styled.div`
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    row-gap: 1rem;
    column-gap: 1rem;
    @media only screen and (max-width: 768px) {
        display: block;
    }
`;
const CPWrapper = styled.div`
    margin: 0rem;
    grid-column: 1 / span 4;
    border: black dashed 1px;
    @media only screen and (max-width: 768px) {
        margin: 0;
    }
`;
const CPlayer = styled.div`
    min-height: 25rem;
    border: blue solid 1px;
    border-radius: 0.5rem;
    background: skyblue;
    text-align: center;

    @media only screen and (max-width: 768px) {
        min-height: 13.5rem;
    }
`;

const CIWrapper = styled.div`
    margin: 0rem;
    grid-column: 1 / span 2;
    border: black dashed 1px;
    @media only screen and (max-width: 768px) {
        margin: 1rem 0;
    }
`;

const CIQuestion = styled.button`
    margin-bottom: 1rem;
    padding: 0.5rem;
    width: 100%;
    display: block;
    background-color: #fff;
    border: ${(props) => props.theme.color.primary} solid 1px;
    border-radius: 0.3rem;
`;

const CIItem = styled.div`
    margin-bottom: 1rem;
`;
const CITitle = styled.h4`
    margin-bottom: 0.5rem;
`;
const CIFiles = styled.ol`
    list-style: decimal;
    padding-left: 1.5rem;
    font-size: 0.9rem;
    li {
        margin-bottom: 0.5rem;
    }
    a {
        color: ${(props) => props.theme.color.primary};
        word-break: break-all;
    }
    a:hover {
        text-decoration: underline;
    }
    .cv {
        padding: 0.1rem 0.5rem 0.2rem;
        margin-right: 0.5rem;
        line-height: 0.5rem;
        font-size: 0.5rem;
        font-weight: normal;
        background: red;
        color: #fff;
        border-radius: 0.3rem;
    }
`;
const CIDescription = styled.div`
    max-height: 15rem;
    overflow-x: hidden;
    overflow-y: auto;
`;

const CCWrapper = styled.div`
    border: #cccccc solid 1px;
    height: 25rem;
    grid-column: 3 / span 2;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 768px) {
        height: 20rem;
    }
`;

const CCTitle = styled.h3``;
const CCContent = styled.div`
    padding: 1rem 0;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
`;
const CCMessage = styled.div`
    margin-bottom: 0.8rem;
    font-size: 0.8rem;
    ${(props) => props.isMe && `text-align:right;`}
    span {
        margin-right: 0.5rem;
        padding: 0rem 0.5rem;
        ${(props) => props.isMe && `display:none;`}
        border:#cccccc solid 1px;
        border-radius: 0.3rem;
    }
`;
const CCSend = styled.div``;

const QContainer = styled.div`
    padding: 1rem;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    flex-direction: column;

    background: rgba(0, 0, 0, 0.7);
    border: none;
    z-index: 2000;
    align-items: center;
    justify-content: center;
`;

const QWrapper = styled.div`
    padding: 1rem;
    width: 100%;
    max-width: 30rem;
    border: #cccccc solid 1px;
    border-radius: 0.5rem;
    background: #ffffff;
`;

const QTitle = styled.div`
    margin: 0 0 1rem;
`;
const QAnswer = styled.button`
    margin: 0 0 0.5rem;
    padding: 0.5rem 0;
    width: 100%;
    display: block;
    border: #cccccc solid 1px;
    border-radius: 0.3rem;
`;
