import React from "react";
import cx from "classnames";
import styled from "styled-components";

export const PageWrapper = styled.div.attrs({ id: "page-wrapper", className: "gray-bg" })``;
export const Row = styled.div.attrs((props) => {
    return {
        className: props.className || "row",
    };
})``;
export const FormGroup = styled.div.attrs((props) => {
    return {
        className: cx({ "form-group": true, row: props.row }),
    };
})``;
export const Separator = styled.div.attrs({ className: "hr-line-dashed" })``;

const Wrapper = styled.div.attrs({ id: "wrapper" })``;
export default Wrapper;
