import React, { Fragment, useContext, useEffect, useState } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";

import Modal from "react-modal";
import styled from "styled-components";
import swal from "sweetalert";
import moment from 'moment';

import { AppContext } from "../../../components/AppProvider";
import { _API, _U, _CK } from "../../../modules/utils";

import { H2, ModalVideoTitle } from "./UI/Components";

import { Theme } from "../../../components/GlobalStyles";

import Video from "../../../components/UI/Video";

export default (props) => {
    const { cfg, auth, iam } = useContext(AppContext);
    const event = cfg.event || null;

    const { code, contentIdx } = props;

    const { siteIdx, eventIdx } = event;

    const [contentType, setContentType] = useState("");
    const [data, setData] = useState({});
    const [html, setHtml] = useState("");
    const [files, setFiles] = useState([]);

    if (!event) {
        return null;
    }

    const loadData = () => {
        const param = { siteIdx, eventIdx, code, contentIdx };
        _API.getContent({ data: param }).then((res) => {
            const { data: content, files } = res.data;
            console.log("content", content);
            setContentType(content.contentType);
            switch (content.contentType) {
                case "HTML":
                    setHtml(content.content);
                    break;
                case "IMAGE":
                case "LINK":
                case "VOD_FILE":
                    setData(content);
                    break;
            }
            setFiles(files);
        });
    };

    const getUrl = () => {
        return data?.vodEncodedUrl || data?.vodSignedUrl;
    };

    useEffect(() => {
        // _U.postMessage("LOCATION-CHANGED");
        loadData();
    }, [siteIdx, eventIdx, contentIdx, auth]);

    return (
        <>
            {html && <div dangerouslySetInnerHTML={{ __html: html }} />}

            {contentType === "VOD_FILE" && (
                <ContentBooth>
                    {data?.vodEncodedUrl ? (
                        <Video url={data?.vodEncodedUrl} autoPlay={false} removeCustomSize={true} width="100%" height="100%" fluid={false} paddingTop="55.25%" />
                    ) : (
                        <video src={data?.vodSignedUrl} playsInline autoPlay={false} width="100%" height="100%" controls />
                    )}
                </ContentBooth>
            )}

            {/* { contentType === "IMAGE" && data.content?.length > 0 && 
                <Lightbox
                    // reactModalStyle={{ overlay: { zIndex: 10000 } }}
                    imageTitle={data.title}
                    mainSrc={data.content[imageIndex]?.signedUrl}
                    nextSrc={data.content[[imageIndex + 1] % data.content.length]?.signedUrl}
                    prevSrc={data.content[(imageIndex + data.content.length - 1) % data.content.length]?.signedUrl}
                    onCloseRequest={() => {
                        setImageIndex(0);
                        setIsMenuView(null);
                    }}
                    onMovePrevRequest={() => setImageIndex((imageIndex + data.content.length - 1) % data.content.length)}
                    onMoveNextRequest={() => setImageIndex((imageIndex + 1) % data.content.length)}
                />
            } */}

            {(contentType === "VOD_FILE" || contentType === "LINK") && <ModalVideoTitle>{data.title}</ModalVideoTitle>}

            {files.map((item, i) => (
                <li key={i}>
                    <a href={item.url} target="_blank">
                        {item.name}
                    </a>
                </li>
            ))}
        </>
    );
};

const ContentBooth = styled.div`
    flex: 1;
    width: 100%;
    height: 100%;
    min-height: 40vh;
    max-height: 90vh;
    overflow-x: hidden;
    overflow-y: hidden;

    .image img {
        max-width: 100%;
    }
`;