import DefaultVisual1x from "../../../resources/image/basic/visual.png";
import DefaultVisual2x from "../../../resources/image/basic/visual@2x.png";
import DefaultVisual3x from "../../../resources/image/basic/visual@3x.png";

export const DefaultVisual = DefaultVisual1x;
export const DefaultVisualSet = [DefaultVisual1x, DefaultVisual2x, DefaultVisual3x];

import ICClose1x from "../../../resources/image/basic/ic-close.png";
import ICClose2x from "../../../resources/image/basic/ic-close@2x.png";
import ICClose3x from "../../../resources/image/basic/ic-close@3x.png";

export const ICClose = ICClose1x;
export const ICCloseSet = [ICClose1x, ICClose2x, ICClose3x];

import File1x from "../../../resources/image/basic/file.png";
import File2x from "../../../resources/image/basic/file@2x.png";
import File3x from "../../../resources/image/basic/file@3x.png";

import CheckOn1x from "../../../resources/image/basic/bt-check-01-on.png";
import CheckOn2x from "../../../resources/image/basic/bt-check-01-on@2x.png";
import CheckOn3x from "../../../resources/image/basic/bt-check-01-on@3x.png";
import Check1x from "../../../resources/image/basic/bt-check-01.png";
import Check2x from "../../../resources/image/basic/bt-check-01@2x.png";
import Check3x from "../../../resources/image/basic/bt-check-01@3x.png";

import home1x from "../../../resources/image/basic/ci-home.png";
import home2x from "../../../resources/image/basic/ci-home@2x.png";
import home3x from "../../../resources/image/basic/ci-home@3x.png";

import mode1x from "../../../resources/image/basic/ic-mode.png";
import mode2x from "../../../resources/image/basic/ic-mode@2x.png";
import mode3x from "../../../resources/image/basic/ic-mode@3x.png";

import phone1x from "../../../resources/image/basic/ic-phone.png";
import phone2x from "../../../resources/image/basic/ic-phone@2x.png";
import phone3x from "../../../resources/image/basic/ic-phone@3x.png";

import message1x from "../../../resources/image/basic/ic-message.png";
import message2x from "../../../resources/image/basic/ic-message@2x.png";
import message3x from "../../../resources/image/basic/ic-message@3x.png";

import noImg1x from "../../../resources/img/noimg_1.png";
import noImg2x from "../../../resources/img/noimg_2.png";
import noImg3x from "../../../resources/img/noimg_3.png";

export const NoImg = noImg1x;
export const NoImgSet = [noImg1x, noImg2x, noImg3x];

export const PhoneImg = phone1x;
export const PhoneImgSet = [phone1x, phone2x, phone3x];

export const MessageImg = message1x;
export const MessageImgSet = [message1x, message2x, message3x];

export const HomeImg = home1x;
export const HomeImgSet = [home1x, home2x, home3x];

export const ModeImg = mode1x;
export const ModeImgSet = [mode1x, mode2x, mode3x];

export const ICFile = File1x;
export const ICFileSet = [File1x, File2x, File3x];

export const CheckOffImg = Check1x;
export const CheckOffImgSet = [Check1x, Check2x, Check3x];
export const CheckOnImg = CheckOn1x;
export const CheckOnImgSet = [CheckOn1x, CheckOn2x, CheckOn3x];

import Calendar1x from "../../../resources/image/basic/calendar.png";
import Calendar2x from "../../../resources/image/basic/calendar@2x.png";
import Calendar3x from "../../../resources/image/basic/calendar@3x.png";

export const ICCalendar = Calendar1x;
export const ICCalendarSet = [Calendar1x, Calendar2x, Calendar3x];

import Logout1x from "../../../resources/image/basic/logout.png";
import Logout2x from "../../../resources/image/basic/logout@2x.png";
import Logout3x from "../../../resources/image/basic/logout@3x.png";

export const ICLogout = Logout1x;
export const ICLogoutSet = [Logout1x, Logout2x, Logout3x];

import ICBottom1x from "../../../resources/image/basic/ic-bottom.png";
import ICBottom2x from "../../../resources/image/basic/ic-bottom@2x.png";
import ICBottom3x from "../../../resources/image/basic/ic-bottom@3x.png";

export const ICBottom = ICBottom1x;
export const ICBottomSet = [ICBottom1x, ICBottom3x, ICBottom2x];

import ICExit1x from "../../../resources/image/basic/ic-exit.png";
import ICExit2x from "../../../resources/image/basic/ic-exit@2x.png";
import ICExit3x from "../../../resources/image/basic/ic-exit@3x.png";

export const ICExit = ICExit1x;
export const ICExitSet = [ICExit1x, ICExit2x, ICExit3x];

import ICSellect1x from "../../../resources/image/basic/ic-select.png";
import ICSellect2x from "../../../resources/image/basic/ic-select@2x.png";
import ICSellect3x from "../../../resources/image/basic/ic-select@3x.png";

export const ICSellect = ICSellect1x;
export const ICSellectSet = [ICSellect1x, ICSellect2x, ICSellect3x];

import ICTop1x from "../../../resources/image/basic/ic-top.png";
import ICTop2x from "../../../resources/image/basic/ic-top@2x.png";
import ICTop3x from "../../../resources/image/basic/ic-top@3x.png";

export const ICTop = ICTop1x;
export const ICTopSet = [ICTop1x, ICTop2x, ICTop3x];

import ICFavoriteOn1x from "../../../resources/image/basic/ic-favorite-on.png";
import ICFavoriteOn2x from "../../../resources/image/basic/ic-favorite-on@2x.png";
import ICFavoriteOn3x from "../../../resources/image/basic/ic-favorite-on@3x.png";

export const ICFavoriteOn = ICFavoriteOn1x;
export const ICFavoriteOnSet = [ICFavoriteOn1x, ICFavoriteOn2x, ICFavoriteOn3x];

import ICFavoriteOff1x from "../../../resources/image/basic/ic-favorite-off.png";
import ICFavoriteOff2x from "../../../resources/image/basic/ic-favorite-off@2x.png";
import ICFavoriteOff3x from "../../../resources/image/basic/ic-favorite-off@3x.png";

export const ICFavoriteOff = ICFavoriteOff1x;
export const ICFavoriteOffSet = [ICFavoriteOff1x, ICFavoriteOff2x, ICFavoriteOff3x];

import ICEnterOff1x from "../../../resources/image/basic/ic-enter-off.png";
import ICEnterOff2x from "../../../resources/image/basic/ic-enter-off@2x.png";
import ICEnterOff3x from "../../../resources/image/basic/ic-enter-off@3x.png";

export const ICEnterOff = ICEnterOff1x;
export const ICEnterOffSet = [ICEnterOff1x, ICEnterOff2x, ICEnterOff3x];

import ICEnterOn1x from "../../../resources/image/basic/ic-enter-on.png";
import ICEnterOn2x from "../../../resources/image/basic/ic-enter-on@2x.png";
import ICEnterOn3x from "../../../resources/image/basic/ic-enter-on@3x.png";

export const ICEnterOn = ICEnterOn1x;
export const ICEnterOnSet = [ICEnterOn1x, ICEnterOn2x, ICEnterOn3x];

export const getSrcSet = (set) => {
    let image = "";
    for (let i in set) {
        const img = set[i];
        const num = parseInt(i);
        if (num !== 0) {
            image += ", ";
        }
        image += `${img} ${num + 1}x`;
    }
    return image;
};

export const getBgSet = (set) => {
    let image = "";
    for (let i in set) {
        const img = set[i];
        const num = parseInt(i);
        if (num !== 0) {
            image += ", ";
        }
        image += `url(${img}) ${num + 1}x`;
    }
    return image;
};
