import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import swal from "sweetalert";

import { AppContext } from "../../../components/AppProvider";

import { BasicInputForm, BasicSelectForm, H2, HRDash, ButtonSubmit, ButtonDefault } from "./UI/Components";

import { _API, _U } from "../../../modules/utils";

import { _DEF } from "../../../modules/config";

import { CheckOffImg, CheckOnImg, CheckOffImgSet, CheckOnImgSet, getBgSet } from "./asset";

export default (props) => {
    const { code, history, location, match } = props;

    const { cfg } = useContext(AppContext);
    const [idx, setIdx] = useState(null);
    const [item, setItem] = useState({});

    const sessionCode = "_REGISTRATION_MODIFY";

    useEffect(() => {
        _U.postMessage("LOCATION-CHANGED");

        const session = _U.getSession(sessionCode);

        if (session) {
            if (session.code === sessionCode && session && session.idx) {
                setIdx(session.idx);
            }
        }
        _U.removeSession(sessionCode);
    }, []);

    const loadData = () => {
        if (idx) {
            const data = { idx: idx, siteIdx: cfg.idx, eventIdx: cfg.event?.eventIdx };

            _API.get({
                path: "/v1/site/event/registration/item",
                data: data,
            }).then((res) => {
                if (res.error === 0) {
                    setItem(res.data);
                } else {
                    swal(res.message);
                }
            });
        }
    };

    useEffect(() => {
        loadData();
    }, [idx]);

    const onComplete = () => {
        props.onCancel && props.onCancel();
    };

    return (
        <Wrapper>
            <H2 style={{ textAlign: "center" }}>사전등록 {idx ? `수정` : ``}</H2>
            {/* <Content code="registration" /> */}
            <Form {...props} onComplete={onComplete} item={item} />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const Form = (props) => {
    const { cfg } = useContext(AppContext);
    const [item, setItem] = useState({});
    const [regTypeName, setRegTypeName] = useState("");
    const { onCancel } = props;
    const { emailYn, phoneYn, nameYn, companyYn, licenseYn, regTypeYn, termsPrivacy } = cfg.event;
    const { companyEsYn, emailEsYn, licenseEsYn, nameEsYn, phoneEsYn, regTypeEsYn } = cfg.event;

    useEffect(() => {
        if (props.item) {
            setItem(props.item);
            setRegTypeName(props.item.regTypeName);
        }
    }, [props.item]);

    const onSetItem = (e) => {
        const target = e.target;
        setItem((o) => {
            return { ...o, [target.name]: target.value };
        });
    };

    const onSave = () => {
        // 필수 여부 체크
        console.log(nameYn, nameEsYn);
        if (nameYn === "Y" && nameEsYn === "Y" && !item.name) {
            swal("성명을 입력하세요.");
            return false;
        }
        if (emailYn === "Y" && emailEsYn === "Y" && !item.email) {
            swal("이메일을 입력하세요.");
            return false;
        }

        if (emailYn === "Y" && emailEsYn === "Y" && !_U.isEmail(item.email)) {
            swal("올바른 이메일을 입력하세요.");
            return false;
        }

        if (phoneYn === "Y" && phoneEsYn === "Y" && !item.phone) {
            swal("휴대폰을 입력하세요.");
            return false;
        }

        if (companyYn === "Y" && companyEsYn === "Y" && !item.companyName) {
            swal("소속을 입력하세요.");
            return false;
        }

        if (licenseYn === "Y" && licenseEsYn === "Y" && !item.licenseNumber) {
            swal("면허번호를 입력하세요.");
            return false;
        }

        if (regTypeYn === "Y" && regTypeEsYn === "Y" && !item.regType) {
            swal("등록구분을 선택하세요.");
            return false;
        }

        if (regTypeYn === "Y" && regTypeEsYn === "Y" && item.regType === "ETC" && regTypeName) {
            swal("등록구분(기타)를 입력하세요.");
            return false;
        }

        if (item.termsPrivacy !== "Y") {
            swal("약관에 동의를 해주세요.");
            return false;
        }

        const data = { ...item, siteIdx: cfg.idx, eventIdx: cfg.event?.eventIdx, regTypeName };

        _API.post({
            path: "/v1/site/event/registration",
            data: data,
        }).then((res) => {
            swal({ title: "알림", text: item.idx ? `사전등록 수정이 완료 되었습니다.` : `사전등록이 완료 되었습니다.` }).then(() => {
                props.onComplete && props.onComplete();
            });
        });
    };

    const onBack = () => {
        history.back();
    };

    const regTypeNameHandler = (e) => {
        onSetItem(e);
        setRegTypeName(e.target.value === "ETC" ? "" : e.target[e.target.selectedIndex].innerText);
    };

    useEffect(() => {
        if (item.regType === "ETC") {
            setItem({ ...item, regTypeName: "regTypeName" });
        }
    }, [item.regType]);

    return (
        <Fragment>
            {nameYn === "Y" && <BasicInputForm title="성명" name="name" value={item.name || ""} onChange={onSetItem} />}
            {emailYn === "Y" && <BasicInputForm title="이메일" name="email" value={item.email || ""} onChange={onSetItem} />}
            {phoneYn === "Y" && <BasicInputForm title="휴대전화번호" name="phone" value={item.phone || ""} onChange={onSetItem} />}
            {companyYn === "Y" && <BasicInputForm title="소속" name="companyName" value={item.companyName || ""} onChange={onSetItem} />}
            {companyYn === "Y" && <BasicInputForm title="진료과" name="deptName" value={item.deptName || ""} onChange={onSetItem} />}
            {/* {companyYn === "Y" && <BasicInputForm title="직책" name="position" value={item.position || ""} onChange={onSetItem} />} */}
            {licenseYn === "Y" && <BasicInputForm title="면허번호" name="licenseNumber" value={item.licenseNumber} onChange={onSetItem} placeholder="면허번호를 입력해 주세요" />}
            {regTypeYn === "Y" && (
                <>
                    <BasicSelectForm title="등록구분" name="regType" items={_DEF.PARTICIPANT_REG_TYPE} value={item.regType} onChange={regTypeNameHandler} nullTitle="등록구분 선택" />
                    {item.regType === "ETC" && (
                        <div className="row">
                            <div className="col-sm-12">
                                <BasicInputForm title="" name="regTypeName" value={regTypeName} onChange={(e) => setRegTypeName(e.target.value)} placeholder={`[등록구분 - 기타] 입력해 주세요.`} />
                            </div>
                        </div>
                    )}
                </>
            )}
            {!item?.idx && <TermsArea title={"약관"} subTitle={"개인정보 취급동의"} name={"termsPrivacy"} onChange={setItem} subTitleEsYn={"Y"} value={item.idx ? "Y" : item.termsPrivacy} htmlValue={termsPrivacy} />}
            {/* <HRDash /> */}
            <FormFooter>
                <ButtonDefault onClick={onCancel}>취소</ButtonDefault>
                <ButtonSubmit onClick={onSave}>확인</ButtonSubmit>
            </FormFooter>
        </Fragment>
    );
};

const TermsArea = (props) => {
    const { title, subTitle, subTitleEsYn, name, nullTitle, hr, items, value, onChange, readOnly, htmlValue } = props;

    const [checkYn, setCheckYn] = useState("");

    const SubTitleLabel = styled.div`
        position: relative;
        display: block;
        width: 100%;
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        cursor: pointer;
        color: #333;
        box-sizing: border-box;
    `;

    const EsStyleSpan = styled.span`
        position: absolute;
        line-height: 25px;
        font-size: 16px;
        font-weight: 400;
        display: inline-block;
        color: ${(props) => props.theme.basic.line.default};
    `;

    const onCheck = (e) => {
        //라벨로 인한 이벤트 중복 방지
        if (!e.target.name) document.getElementById(name).click();

        const checkedYn = !value ? "Y" : value === "Y" ? "N" : "Y";
        setCheckYn(checkedYn);
        // console.log(e.target.getAttribute('name'), checkedYn);

        onChange &&
            onChange((o) => {
                return { ...o, [e.target.getAttribute("name")]: checkedYn };
            });
    };

    useEffect(() => {
        setCheckYn(value);
    }, [value]);

    return (
        <Fragment>
            <div className="form-group row">
                {title && (
                    <Fragment>
                        <label className="col-sm-12 control-label">{title}</label>
                    </Fragment>
                )}
                <div className="col-sm-12 btn-group">
                    {subTitle && (
                        <SubTitleLabel onClick={onCheck}>
                            <Fragment>
                                <TermCheckBox
                                    id={name}
                                    name={name}
                                    onClick={(e) => {
                                        onCheck(e);
                                    }}
                                    checked={checkYn === "Y" ? true : false}
                                />
                                <SubTitle>{subTitle}</SubTitle>
                                {subTitleEsYn && <EsStyleSpan> (필수)</EsStyleSpan>}
                            </Fragment>
                        </SubTitleLabel>
                    )}
                </div>
            </div>
            {htmlValue && (
                <AgreementHtmlWrapper className="form-group row">
                    <div
                        className="ibox-content"
                        dangerouslySetInnerHTML={{
                            __html: htmlValue,
                        }}
                        style={{ borderBottom: "0px", borderTop: "0px" }}
                    ></div>
                </AgreementHtmlWrapper>
            )}
        </Fragment>
    );
};

const AgreementHtmlWrapper = styled.div`
    img {
        width: 100%;
    }
    border-top: 2px dashed rgba(0, 0, 0, 0.1);
    border-bottom: 2px dashed rgba(0, 0, 0, 0.1);
`;

const FormFooter = styled.div`
    display: flex;
    justify-content: center;

    & button {
        flex-basis: 28%;

        :last-child {
            margin-left: 2%;
        }

        @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
            flex-basis: 48%;
        }
    }
`;

const TermCheckBox = styled.label`
    width: 25px;
    height: 25px;
    background-image: ${(props) => (props.checked == true ? `url(${CheckOnImg})` : `url(${CheckOffImg})`)};
    background-image: ${(props) => (props.checked == true ? `-webkit-image-set(${getBgSet(CheckOnImgSet)})` : `-webkit-image-set(${getBgSet(CheckOffImgSet)})`)};
    cursor: pointer;
    float: left;
    margin-right: 10px;
`;

const SubTitle = styled.div`
    font-size: 16px;
    color: ${(props) => props.theme.basic.color.primary};
    width: 135px;
    line-height: 25px;
    font-weight: 500;
    float: left;
`;
