import React, { useContext, Fragment, useEffect, useState, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import styled from "styled-components";
import swal from "sweetalert";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";

import Layout from "./Layout";
import { AppContext } from "../../components/AppProvider";
import { _DEF } from "../../modules/config";
import { _API, _U } from "../../modules/utils";

import { SelectForm, SearchCondition, DatePicker } from "../../components/UI/Form";

import { Line } from 'react-chartjs-2';

export default (props) => {

    const { history, location, match } = props;
    const cp = { history, location, match };

    const [form, setForm] = useState({ status: 'Y' });

    const { cfg } = useContext(AppContext);
    const [siteIdx, setSiteIdx] = useState(cfg.idx);
    const [eventIdx, setEventIdx] = useState("");
    const [channelIdx, setChannelIdx] = useState("");

    const [siteList, setSiteList] = useState([]);
    const [eventList, setEventList] = useState([]);
    const [channelList, setChannelList] = useState([]);
    const [exportExcelList, setExportExcelList] = useState([]);
    let excel = useRef(null);

    const [summary, setSummary] = useState(null);
    const [checkDate, setCheckDate] = useState(null);

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [{
            label: "",
            data: [],
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
        }]
    });

    const options = {
        legend: {
            display: true
        },
        scales: {
            yAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: 'Attend Count'
                },
                ticks: {
                    min: 0,
                    stepSize: 1
                },
            }],
            xAxes: [{
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'Time'
                },
            }]
        }
    }

    // const getTimeLabels = (strtTime, endTime, interval) => {
    //     const sT = typeof strtTime === 'number' ? strtTime : parseInt(strtTime);
    //     const eT = typeof endTime === 'number' ? endTime : parseInt(endTime);
    //     const iT = typeof interval === 'number' ? interval : parseInt(interval);

    //     if (isNaN((sT&&eT&&iT))) { return 0; }

    //     const timeArr = [];
    //     let tempTime = sT;

    //     while (tempTime <= eT) {
    //         timeArr.push(getTimeFormat(tempTime));
    //         tempTime = tempTime + iT;
    //     }

    //     return timeArr;
    // }

    // const getTimeFormat = time => {
    //     return (""+time).length == 1 ? "0"+time :  time;
    // }

    const setChart = (xAxis = [], yAxis = [], name = "") => {
        setChartData({
            labels: xAxis,
            datasets: [{
                label: name,
                data: yAxis,
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1
            }]
        });
    }

    const loadList = () => {
        if (!siteIdx || !eventIdx) return;
        const data = {
            siteIdx,
            eventIdx,
            channelIdx,
            checkDate: checkDate ? checkDate.replace(/-/gi, "") : moment().format("YYYYMMDD"),
        };
        const req = { path: "/v1/a/statistics/attend", data };
        _API.get(req).then((res) => {
            const { list, summary } = res.data;
            setChart(list.map(i => i.time), list.map(i => i.participantCnt), (list ? list[0]?.channelName || "" : ""));
            setSummary(summary);
        });
    };

    useEffect(() => {
        loadList();
    }, [siteIdx, eventIdx, channelIdx, checkDate]);

    const onExcelDown = async () => {
        if (!siteIdx || !eventIdx || !channelIdx) {
            swal({ title: "알림", text: "채널을 선택해 주세요." });
            return;
        }
        const data = {
            siteIdx,
            eventIdx,
            channelIdx,
        };

        const req = { path: "/v1/a/statistics/attend", data };
        _API.get(req).then((res) => {
            const excelList = res.data.list;
            setExportExcelList(excelList || null);
            if (excelList) {
                excel && excel.current.save();
            }
        });
    };

    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-8">
                    <h2>접속통계</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/admin">HOME</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>접속통계</strong>
                        </li>
                    </ol>
                </div>
                <div className="col-sm-4">
                    <div className="title-action">
                        <button
                            type="button"
                            className="btn btn-warning ml-1"
                            onClick={(e) => {
                                onExcelDown();
                            }}
                        >
                            <i className="fa fa-file-excel-o"></i> 엑셀다운
                        </button>
                    </div>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className="col-md-12">
                        <div className="ibox">
                            <div className="ibox-title">접속통계</div>
                            <div className="ibox-content ibox-heading">
                                <SearchCondition
                                    sync={true}
                                    eventYn={"Y"}
                                    sessionYn={"N"}
                                    channelYn={"Y"}
                                    keywordYn={"N"}
                                    onChange={(e) => {
                                        setEventIdx(e.eventIdx);
                                        setChannelIdx(e.channelIdx);
                                    }}
                                    value={{ siteIdx, eventIdx, channelIdx }}
                                    setEventList={setEventList}
                                />
                            </div>
                            {summary && (
                                <div className="ibox-content">
                                    <div className="row">
                                        <div className="col-sm-2" style={{ display: "flex", alignItems: "center" }}>
                                            <InputWrapper>
                                                <DatePicker name="checkDate" value={checkDate || moment().format("YYYY-MM-DD")} onChange={(e) => setCheckDate(e.value)} />
                                            </InputWrapper>
                                        </div>
                                        <div className="col-sm-10 table-responsive">
                                            <table className="table table-striped table-hover">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center">총 참여자</th>
                                                        <th className="text-center">방문자수</th>
                                                        <th className="text-center">가입자수</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="text-center">{summary?.totalCnt}</td>
                                                        <td className="text-center">{summary?.todayAccessCnt}</td>
                                                        <td className="text-center">{summary?.todayRegCnt}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* <div className="col-sm-2" style={{ display: "flex", alignItems: "center" }}>
                                            <button type="button" className="btn btn-xs btn-warning" onClick={(e) => { console.log(attendSessionList); setIsStatisticsDetail(true); }}>
                                                <i className="fa fa-eye"></i> 상세통계
                                            </button>
                                        </div> */}
                                    </div>
                                </div>
                            )}
                            <div className="ibox-content">
                                <Line data={chartData} options={options} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ExcelDownloader data={exportExcelList} ref={excel} />
        </Layout>
    );
};

const InputWrapper = styled.div`
    .form-group {
        margin-bottom: 0px;
    }
`;

class ExcelDownloader extends React.Component {
    constructor(props) {
        super(props);

        const state = {
            list: props.data || [],
        };
        this.state = state;
    }

    componentWillReceiveProps = (props) => {
        this.setState({ list: props.data });
    };

    save() {
        this.excel && this.excel.save();
    }

    render() {
        const list = this.state.list;
        let excelName = "접속통계";
        const today = moment().format("YYYYMMDDHHmmss");
        if (list && list.length > 0) {
            excelName += "-" + list[0].eventName + "_" + list[0].channelName;
        }

        if (!list) {
            return "";
        }

        return (
            <ExcelExport data={list} fileName={`${excelName}_${today}.xlsx`} ref={(ref) => (this.excel = ref)}>
                <ExcelExportColumn field="channelName" title="채널명" width={250} />
                <ExcelExportColumn field="time" title="시간" width={250} />
                <ExcelExportColumn field="participantCnt" title="참가자수" width={250} />
            </ExcelExport>
        );
    }
}

