import React, { Fragment, useContext, useEffect, useState } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";

import styled, { css } from "styled-components";
import swal from "sweetalert";
import moment from "moment";
import { isMobile } from "react-device-detect";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U, _CK } from "../../../modules/utils";

import { ConnectionButton, H2, H3, ItemEmpty } from "./UI/Components";
import MyWindowPortal from "./MyWindowPortal";
import Booth from "../../../components/Booth";

import badge from "../../../resources/image/basic/flag@3x.png";

export default (props) => {
    const { cfg, auth, iam } = useContext(AppContext);
    const event = cfg.event || null;

    const [isShown, setIsShown] = useState({});
    const [list, setList] = useState([]);
    const [showWindow, setShowWindow] = useState(false);
    const [clickedItem, setClickedItem] = useState({});
    const [showCompanyCnt, setShowCompantCnt] = useState(0);

    if (!event) {
        return null;
    }

    const { siteIdx, eventIdx, channel, startDate, endDate } = event;

    if (!startDate || !endDate || channel?.length === 0) {
        return null;
    }
    useEffect(() => {
        loadList();
    }, []);

    const loadList = () => {
        const data = {
            siteIdx,
            eventIdx,
            eboothYn: "Y",
        };

        const req = { path: "/v1/event/company", data };
        _API.get(req).then((res) => {
            const { list } = res.data;
            const newList = list.filter((item) => item.eboothItemIdx || item.homepageUrl);
            let cnt = 0;
            for (let i in newList) {
                if (newList[i].isClicked) cnt++;
            }
            setList(newList);
            setShowCompantCnt(cnt);
        });
    };

    // const onEnter = (idx) => {
    //     if (!_CK.isLogin()) {
    //         swal("로그인 후 이용이 가능합니다").then(() => {
    //             window.location.reload();
    //         });
    //     } else {
    //         window.open(`/#/channel/${idx}`);
    //         _U.postMessage("CLOSE-MODAL");
    //     }
    // };

    const companyLog = (item) => {
        return new Promise((resolve, reject) => {
            const data = {
                companyIdx: item.idx,
            };
            const req = { path: "/v1/event/company/log", data };
            _API.get(req).then((res) => {
                resolve(true);
            });
        });
    };

    const onClickLogo = async (item) => {
        setIsShown({});
        if (item.eboothItemIdx) {
            window.open(`/#/ebooth/popup/${item.idx}`);
        } else {
            window.open(item.homepageUrl);
            await companyLog(item);
        }
        clickedItemShowBadge(item);
        // console.log(item.isClicked);
        setShowCompantCnt(showCompanyCnt + 1);
    };

    const clickedItemShowBadge = (item) => {
        setList(
            list.map((c) => {
                if (c.idx === item.idx) {
                    c.isClicked = true;
                }
                return c;
            })
        );
    };

    const rtnPopupSpec = () => {
        const popupWidth = 1600;
        const popupHeight = 900;

        const popupX = window.screen.width / 2 - popupWidth / 2;
        const popupY = window.screen.height / 2 - popupHeight / 2;

        return `width=${popupWidth},height=${popupHeight},left=${popupX},top=${popupY},location=no,status=no`;
    };

    return (
        <>
            <ViewLayer>{`부스이벤트 ${list?.length || 0}개중 `}<ViewLog>{showCompanyCnt || 0}</ViewLog>{`건의 부스를 열람하였습니다.`}</ViewLayer>
            <Wrapper>
                {list.map((item, i) => (
                    // <Article key={i} onMouseEnter={() => setIsShown({ [item.idx]: true })} onMouseLeave={() => setIsShown({ ...isShown, [item.idx]: false })}>
                    <Article key={i} >
                        <ArticleInfo>
                            {item.isClicked && (
                                <ClickedCompany>
                                    <img src={badge} alt="" />
                                </ClickedCompany>
                            )}
                            <EboothImg>
                                <img src={item.finalSignedUrl} />
                            </EboothImg>
                        </ArticleInfo>
                        {/* {isShown[item.idx] && <HoverEffect onClick={(e) => onClickLogo(item)}>{item.name}</HoverEffect>} */}
                    </Article>
                ))}
                <ItemEmpty length={list?.length} number={3} component={<Article />} />
            </Wrapper>
        </>
    );
};

const ClickedCompany = styled.div`
    position: absolute;
    top: 10px;
    left: 0px;
    height: 22px;

    & img {
        height: 100%;
    }
`;

const BoothWrapper = styled.div`
    widows: 100%;
    height: 100%;
`;

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: flex-start;

    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                justify-content: space-around;
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                justify-content: space-around;
            }
        `;
        }
    }}

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        justify-content: space-around;
    }
`;

const EboothImg = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: ${(props) => props.theme.fontFamily.medium};
    font-size: 12px;
    text-align: center;
    color: ${(props) => props.theme.basic.color.primary};
    /* width: 106px;
    height: 106px; */

    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                min-height: 80px;
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                min-height: 80px;
            }
        `;
        }
    }}
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        /* width: 100%;
        height: 100%;*/
        min-height: 80px;
    }

    & img {
        width: 100%;
        /* min-height: 3rem; */
        object-fit: contain;
        /* height: 100%; */

        @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
            /* width: 80%; */
        }
    }
`;

const Article = styled.div`
    align-items: center;
    flex-basis: 12%;
    background-color: ${(props) => props.theme.basic.bg.background};
    margin: 8px 8px 8px 9px;
    position: relative;

    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                flex-basis: 31%;
                margin: 0 0 8px 0;
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                flex-basis: 31%;
                margin: 0 0 8px 0;
            }
        `;
        }
    }}
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        flex-basis: 31%;
        margin: 0 0 8px 0;
    }
`;

const ArticleInfo = styled.div`
    position: relative;
    border: solid 1px ${(props) => props.theme.basic.line.default};
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    display: flex;

    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                padding: 5px;
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                padding: 5px;
            }
        `;
        }
    }}
    @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
        padding: 5px;
    }
`;

const HoverEffect = styled.div`
    position: absolute;
    padding-top: 10px;
    top: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    color: #ffffff !important;
    font-size: 14px;
    background-color: #000000;
    border-radius: 10px;
`;

const ViewLayer = styled.div`
    padding-left: 9px;
    font-size:15px;

    ${props => {
        if (isMobile) {
            return css`
            @media only screen and (max-width: ${(props) => props.theme.basic.padMaxLandWidth}px) {
                font-size:13px;
                padding-left: 5px;
                padding-bottom: 8px;
            }
            @media only screen and (max-width: ${(props) => props.theme.basic.padWidth}px) {
                font-size:13px;
                padding-left: 5px;
                padding-bottom: 8px;
            }
        `;
        }
    }}

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        font-size:13px;
        padding-left: 5px;
        padding-bottom: 8px;
    }
`;

const ViewLog = styled.span`
    font-weight:600;
    color:red;
`;
