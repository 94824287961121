import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

import styled from "styled-components";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U, _CK } from "../../../modules/utils";
import { Loading } from "../../../components/UI/Loading";

import { H2, H3 } from "./UI/Components";

export default ({ contentIdx }) => {
    const { cfg, auth, iam } = useContext(AppContext);
    const event = cfg.event || null;
    const { siteIdx, eventIdx } = event;

    const [isLoading, setIsLoading] = useState(true);

    const [selectChannel, setSelectChannel] = useState(null);
    const [list, setList] = useState([]);

    const loadSatelliteAgenda = () => {

        if (!contentIdx) {
            return;
        }

        _API.get({
            path: "/v1/event/satellite/lecture",
            data: {
                siteIdx,
                eventIdx,
                satelliteIdx: contentIdx
            },
        }).then((res) => {
            setList(res.data);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        loadSatelliteAgenda();
    }, [contentIdx])

    if (isLoading) {
        return <Loading title="일정표를 조회하고 있습니다." background="rgba(0, 0, 0, 0.1)" />;
    }

    return (
        <>
            <H2 style={{ textAlign: "center" }}>Program</H2>
            <Wrapper>
                <>
                    <SessionsWrapper>
                        <STop>
                            <TimeTitle>시간</TimeTitle>
                            <ProgramTitle>
                                프로그램
                                {isMobile && (<LMgr> (연자)</LMgr>)}
                            </ProgramTitle>
                            {!isMobile && (<MgrTitle>연자</MgrTitle>)}
                        </STop>
                        <SessionItem>
                            {list?.map((l, j) => (
                                <LectureWrapper key={j}>
                                    <LTime>
                                        {l.startTime} ~ {l.endTime}
                                    </LTime>
                                    <LTitle>
                                        {l.title}
                                        {/* {isMobile && l.mgrName && <LMgr>{l.mgrName}</LMgr>} */}
                                    </LTitle>
                                    {!isMobile && <LMgr dangerouslySetInnerHTML={{ __html: l.mgrName?.replace(/,/gi, "<br/>") }} />}
                                </LectureWrapper>
                            ))}
                        </SessionItem>
                    </SessionsWrapper>
                    {!list?.length > 0 && !selectChannel && (
                        <EmptyWrapper height={`calc(100% - 28px - 2rem - 42px)`}>
                            <H3>일정이 없습니다.</H3>
                        </EmptyWrapper>
                    )}
                </>
            </Wrapper>
        </>
    );
};

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;

const EmptyWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    height: ${(props) => (props.height ? props.height : `calc(100% - 28px - 2rem)`)};
`;

const SessionsWrapper = styled.div`
    margin-top: 15px;
`;
const SessionItem = styled.div``;

const STop = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 20px 0 0;
    border-top: 1px solid ${(props) => props.theme.basic.line.agenda};
    border-bottom: 1px solid ${(props) => props.theme.basic.line.agenda};
    background-color: ${(props) => props.theme.basic.bg.agenda};

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        display: flex;
        padding: 0px;
    }

`;

const TimeTitle = styled.div`

    flex: 1;
    flex-grow: 1;
    padding: 15px 0;

    color: ${(props) => props.theme.basic.color.title};
    font-size: 16px;
    font-family: ${(props) => props.theme.fontFamily.medium};

    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        flex-grow: 1;
        padding: 15px 0 15px 0;
        font-size: 14px;
    }
`;

const ProgramTitle = styled.div`
    flex: 1;
    flex-grow: 3;
    padding: 15px 0;
    color: ${(props) => props.theme.basic.color.title};
    font-size: 16px;
    font-family: ${(props) => props.theme.fontFamily.medium};

    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        flex-grow: 2.5;
        padding: 15px 0 15px 20px;
        font-size: 14px;

        span {
            margin-left: 0px;
            margin-right: 5px;
        }
    }
`;

const MgrTitle = styled.div`
    flex: 1;
    flex-grow: 2;
    padding: 15px 0;
    font-size: 16px;
    color: ${(props) => props.theme.basic.color.title};
    font-family: ${(props) => props.theme.fontFamily.medium};

    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        justify-content: flex-start;
        padding: 3px 0;
    }
`;

const LectureWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 20px 0 0;
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        padding: 0px;
    }
    ${(props) =>
        props.type === "Break"
            ? `
                background-color: ${props.theme.basic.bg.break};

                & ${LTime}{
                    padding: 10px 0;
                }
                & ${LTitle}{
                    padding: 10px 0 10px 30px;
                    text-align: center;   

                    @media only screen and (max-width: ${props.theme.basic.mobileWidth}px) {
                        padding: 10px 0 10px 20px;
                    }
                    // font-size:12px;
                    // font-family: ${props.theme.fontFamily.medium};
                    // color: ${props.theme.basic.color.primary};
                }
            `
            : `
                border-bottom: 1px solid ${props.theme.basic.line.agendaLecture};
            `}
`;

const LTime = styled.div`
    flex: 1;
    flex-grow: 1;
    border-right: 1px solid ${(props) => props.theme.basic.line.agendaLecture};
    padding: 17px 0;
    text-align: center;
    /* font-size: 12px;
    font-family: ${(props) => props.theme.fontFamily.regular};
    color: ${(props) => props.theme.basic.color.off}; */
    font-size: 14px;
    font-family: ${(props) => props.theme.fontFamily.medium};
    color: ${(props) => props.theme.basic.color.primary};
    display:flex;
    align-items:center;
    justify-content:center;
`;

const LTitle = styled.div`
    flex: 1;
    flex-grow: 3;
    padding: 17px 20px;
    font-size: 14px;
    font-family: ${(props) => props.theme.fontFamily.medium};
    color: ${(props) => props.theme.basic.color.primary};
    display:flex;
    align-items:center;
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        flex-grow: 2.5;
        padding: 15px 0 15px 20px;
        font-size: 14px;

        span {
            margin-left: 0px;
            margin-right: 5px;
        }
    }
`;
const LMgr = styled.div`
    flex: 1;
    flex-grow: 2;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;

    color: ${(props) => props.theme.basic.color.primary};
    font-family: ${(props) => props.theme.fontFamily.regular};
    font-size: 12px;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        justify-content: flex-start;
        padding: 3px 0;
    }
`;

