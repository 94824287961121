import React, { useEffect, useState, useContext, useRef, Fragment } from "react";
import styled from "styled-components";
import nl2br from "nl2br";
import { isMobile } from "react-device-detect";
import cx from "classnames";
import ScriptTag from "react-script-tag";
import TimeAgo from "react-timeago";
import koStrings from "react-timeago/lib/language-strings/ko";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import moment from "moment";

import { _API, _U, _CK } from "../../../modules/utils";

import { AppContext } from "../../../components/AppProvider";
import { Loading } from "../../../components/UI/Loading";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { fullScreenPlugin } from "@react-pdf-viewer/full-screen";
import { zoomPlugin } from "@react-pdf-viewer/zoom";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/full-screen/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";

import { ConnectionButton, H2, H3, ItemEmpty } from "./UI/Components";
import { ICFavoriteOn, ICFavoriteOnSet, ICFavoriteOff, ICFavoriteOffSet, ICEnterOff, ICEnterOffSet, ICEnterOn, ICEnterOnSet, getBgSet } from "./asset";

import { default as Comment } from "./Eposter.Comment";

export default (props) => {
    const { cfg, auth, iam } = useContext(AppContext);
    const event = cfg.event || null;
    const { siteIdx, eventIdx } = event;
    const idx = _CK.get("eposterIdx");

    const [item, setItem] = useState(null);
    const [isFavorite, setIsFavorite] = useState(false);
    const [isPremium, setIsPremium] = useState((event.pdfEbookYn || "N") === "Y" ? true : false);

    const [isMainView, setIsMainView] = useState(false);
    const [pdfUrl, setPdfUrl] = useState("");

    const onFavorite = () => {
        _API.post({
            path: "/v1/site/event/eposter/favorite",
            data: {
                siteIdx,
                eventIdx,
                abstractIdx: idx,
            },
        }).then((res) => {
            setIsFavorite(res.data === "I");
        });
    };

    const loadData = () => {
        _API.get({
            path: "/v1/site/event/eposter/item",
            data: {
                siteIdx,
                eventIdx,
                idx,
            },
        }).then((res) => {
            setItem(res.data);
            setIsFavorite(res.data.isFavorite);
        });
    };

    useEffect(() => {
        if (window.location.hash !== "#/pdf") {
            if (siteIdx && eventIdx) {
                loadData();
            }
        }
    }, [idx]);

    useEffect(() => {
        if (window.location.hash === "#/pdf") {
            const content = _CK.get("pdfContent");
            if (!item && content) {
                setItem({ ...content, finalFileSignedUrl: content.pdfSignedUrl });
            }
            setIsMainView(true);
        } else {
            setIsMainView(false);
        }
    }, [item]);

    return (
        <>
            {item && (
                <Wrapper>
                    <Container>
                        <H2 style={{ padding: "0 1rem" }}>
                            {item.title}
                            {!isMainView && event.eposterOptYn === "Y" && <FavoriteButton onClick={onFavorite} ison={isFavorite} />}
                        </H2>
                        {!isMainView && (
                            <Author>
                                {item.name} {item.companyName ? ` (${item.companyName})` : ""}
                            </Author>
                        )}
                        {!isMainView && item.description && <Desc dangerouslySetInnerHTML={{ __html: nl2br(item.description) }} />}
                    </Container>
                    {isPremium && !isMobile ? <PdfViewerPrimium {...item} isMainView={isMainView} isComment={(!isMainView && event.eposterOptYn === "Y") ? true : false} /> : <PdfViewerBasic {...item} isMainView={isMainView} isComment={(!isMainView && event.eposterOptYn === "Y") ? true : false} />}
                    {!isMainView && event.eposterOptYn === "Y" && <Comment abstractIdx={idx} style={{ float: "left", width: "25%" }} />}
                </Wrapper>
            )}
        </>
    );
};

const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    margin: 0 auto;
`;

const Container = styled.div`
    width: 80%;
    padding: 2rem 0 1rem;
    margin: 0 auto;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        width: 100%;
        padding: 1rem;
    }
`;

const Author = styled.div`
    color: ${(props) => props.theme.basic.color.off};
    font-family: ${(props) => props.theme.fontFamily.regular};
    font-size: 14px;
    padding: 0 1rem 1rem;
`;

const Desc = styled.p`
    color: ${(props) => props.theme.basic.color.primary};
    font-family: ${(props) => props.theme.fontFamily.regular};
    font-size: 14px;
    padding: 0 1rem;
`;

const FavoriteButton = styled.span`
    cursor: pointer;
    margin-left: 1rem;
    display: inline-block;

    ${(props) =>
        props.ison
            ? `
            background-image: ${`url(${ICFavoriteOn})`};
            background-image: -webkit-image-set(${getBgSet(ICFavoriteOnSet)});
        `
            : `
            background-image: ${`url(${ICFavoriteOff})`};
            background-image: -webkit-image-set(${getBgSet(ICFavoriteOffSet)});    
        `}

    background-repeat: no-repeat;
    background-position: center 2px;
    background-size: 100%;
    width: 16px;
    height: 22px;
`;

export const PdfViewerBasic = (props) => {
    const fullScreenPluginInstance = fullScreenPlugin();
    const { EnterFullScreenButton } = fullScreenPluginInstance;
    const zoomPluginInstance = zoomPlugin();
    const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;


    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.js" style={(!isMobile && props.isComment) ? { float: "left", width: "75%" } : {}}>
            <PdfBasicWrapper>
                <PButtons>
                    <PButton>
                        <EnterFullScreenButton />
                    </PButton>
                    <PButton>
                        <ZoomOutButton />
                        <ZoomPopover />
                        <ZoomInButton />
                    </PButton>
                </PButtons>
                <PItem>
                    <Viewer defaultScale={isMobile ? 0.5 : 1} fileUrl={props?.finalFileSignedUrl} plugins={[fullScreenPluginInstance, zoomPluginInstance]}
                        characterMap={{ isCompressed: true, url: 'https://unpkg.com/pdfjs-dist@2.6.347/cmaps/' }}
                    />
                </PItem>
            </PdfBasicWrapper>
        </Worker>
    );
};

const PdfBasicWrapper = styled.div`
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: calc(100% - 20vh);
    border: 1px solid ${(props) => props.theme.basic.line.default};

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        width: 90%;
    }
`;

const PButtons = styled.div`
    background-color: ${(props) => props.theme.basic.bg.break};
    padding: 4px;
    border-bottom: 1px solid ${(props) => props.theme.basic.line.default};
    display: flex;
`;

const PButton = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;

    :last-child {
        justify-content: flex-end;
    }
`;

const PItem = styled.div`
    flex: 1;
    overflow: hidden;
`;

export const PdfViewerPrimium = (props) => {
    return (
        <div style={(!isMobile && props.isComment) ? { float: "left", width: "75%" } : {}}>
            <PdfPremiumWrapper>
                <div className="_df_book" height="80%" webgl="true" backgroundcolor="#fff" source={props?.finalFileSignedUrl} id="df_manual_book" scrollWheel="false" soundEnable="false">
                    <PdfLoading>Loading...</PdfLoading>
                </div>
            </PdfPremiumWrapper>
            <ScriptTag isHydrating={false} type="text/javascript" src={"https://cdn.dmon.kr/plugins/dflip/js/dflip.min.js"} />
        </ div>
    );
};

const PdfPremiumWrapper = styled.div`
    width: 100%;
    height: 100%;
    div.df-ui-share {
        display: none;
    }
    a.ti-download {
        display: none;
    }
`;

const PdfLoading = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 620px;
    display: flex;
    z-index: -1;
    font-size: 20px;
    justify-content: center;
    align-items: center;
`;
