export const STATUS = [
    { label: "사용", value: "Y" },
    { label: "사용안함", value: "N" },
];
export const STATUS_WITH_REMOVE = [
    { label: "사용", value: "Y" },
    { label: "사용안함", value: "N" },
    { label: "삭제", value: "D" },
];

export const TEMPLATE = [
    // { label: "테스트", value: "TEST" },
    { label: "기본", value: "BASIC" },
    { label: "이부스전용", value: "EBOOTH" },
];

export const MEMBER_TYPES = [
    // { label: "개발자", value: "DEVELOPER" },
    { label: "관리자", value: "ADMIN" },
    { label: "운영자", value: "OPERATOR" },
    { label: "회원", value: "MEMBER" },
];

export const getMemberTypes = (type) => {
    switch (type) {
        case "DEVELOPER":
            return MEMBER_TYPES;
        case "ADMIN":
            return [
                { label: "관리자", value: "ADMIN" },
                { label: "운영자", value: "OPERATOR" },
                { label: "회원", value: "MEMBER" },
            ];
        case "OPERATOR":
            return [
                { label: "운영자", value: "OPERATOR" },
                { label: "회원", value: "MEMBER" },
            ];
        default:
            return [{ label: "회원", value: "MEMBER" }];
    }
};
export const MEMBER_STATUS = [
    { label: "대기", value: "W" },
    { label: "정상", value: "Y" },
    { label: "차단", value: "N" },
];

export const FEEDBACK_CATEGORY = [
    { label: "사전", value: "BEFORE" },
    { label: "사후", value: "AFTER" },
];

export const FEEDBACK_TYPE = [
    { label: "객관식", value: "SINGLE" },
    { label: "객관식 (다중선택)", value: "MULTIPLE" },
    { label: "주관식", value: "ANSWER" },
];

export const getRange = (start, end, options) => {
    const prefix = options?.prefix || "";
    const suffix = options?.suffix || "";
    return Array(end - start + 1)
        .fill()
        .map((_, idx) => {
            return {
                label: `${prefix}${start + idx}${suffix}`,
                value: start + idx,
            };
        });
};

export const SESSION_TYPE = [
    { label: "강의", value: "L" },
    { label: "휴식", value: "B" },
];

export const SESSION_FILE_TYPE = [
    { label: "강의자료", value: "F" },
    { label: "CV자료", value: "C" },
];

export const GENDER = [
    { label: "남성", value: "M" },
    { label: "여성", value: "F" },
];

export const SEND_TARGET = [
    { label: "참여자", value: "A" },
    { label: "사전등록자", value: "P" },
];

export const EB_RATIO = [
    { label: "PC", value: "16x9" },
    // { label: "4:3", value: "4x3" },
    // { label: "1:1", value: "1x1" },
    { label: "MOBILE", value: "9x16" },
    // { label: "3:4", value: "3x4" },
];

export const EB_CONTENT_TYPE = [
    { label: "HTML", value: "HTML" },
    { label: "링크", value: "LINK" },
    // { label: "영상경로", value: "VOD_URL" },
    { label: "영상파일", value: "VOD_FILE" },
    { label: "이미지", value: "IMAGE" },
    { label: "PDF", value: "PDF" },
];

export const EB_CONTENT_CATEGORY = [
    // { label: "E-BOOTH", value: "EBOOTH" },
    // { label: "E-POSTER", value: "EPOSTER" },
    { label: "온디맨드영상", value: "ONDEMAND" },
    { label: "이벤트메인", value: "EMAIN" },
    { label: "기타", value: "ETC" },
];

export const IVS_LATENCY_MODE = [
    { label: "표준지연시간", value: "NORMAL" },
    { label: "매우짧은지연시간", value: "LOW" },
];

export const IVS_TYPE = [
    { label: "표준", value: "STANDARD" },
    { label: "기본", value: "BASIC" },
];

export const LOGIN_TYPE = [
    { label: "이메일", value: "EMAIL" },
    { label: "전화번호", value: "PHONE" },
    { label: "인증코드", value: "PASSCODE" },
    { label: "이름", value: "NAME" },
];
export const ESSENTIAL_TYPE = [
    { label: "필수", value: "Y" },
    { label: "미필수", value: "N" },
];

export const PARTICIPANT_REG_TYPE = [
    { label: "의사", value: "DOCTOR" },
    { label: "간호사", value: "NURSE" },
    { label: "foreginer", value: "FOREGINER" },
    { label: "기타", value: "ETC" },
];

export const PARTICIPANT_STATUS = [
    { label: "대기", value: "W" },
    { label: "승인", value: "Y" },
    { label: "거절", value: "N" },
];

export const INPUT_TYPE = [
    { label: "TEXT", value: "SUBJECTIVE" },
    { label: "SELECT", value: "SINGLE" },
    { label: "CHECKBOX", value: "MULTIPLE" },
    { label: "DATE", value: "DATE" },
];

export const getDefinedLabel = (arr, value) => {
    if (arr && value) {
        for (let i in arr) {
            if (value === arr[i].value) {
                return arr[i].label;
            }
        }
    }
    return "";
};

export const getDefinedValue = (arr, label) => {
    if (arr && label) {
        for (let i in arr) {
            if (label === arr[i].label) {
                return arr[i].value;
            }
        }
    }
    return "";
};
export const QUESTIONER_TYPE = [
    { label: "참가자", value: "PATICIPANT" },
    { label: "운영자", value: "MEMBER" },
];
export const ANSWER_COMPLETE_TYPE = [
    { label: "완료", value: "Y" },
    { label: "미완료", value: "N" },
];

export const BOARD_TYPE = [
    { label: "긴급공지", value: "EMERGENCY" },
    { label: "FAQ", value: "FAQ" },
    { label: "공지사항", value: "NOTICE" },
];

export const BOARD_STATUS = [
    { label: "대기", value: "W" },
    { label: "정상", value: "Y" },
    { label: "차단", value: "N" },
];

export const NOTICE_YN = [
    { label: "예", value: "Y" },
    { label: "아니오", value: "N" },
];

export const ABSTRACT_TYPE = [
    // { label: "ORAL", value: "ORAL" }, 추후 추가
    { label: "E-POSTER", value: "POSTER" },
];

export const MENU_BASE_URL = [
    // { label: "프로그램소개", value: "PROGRAM", sort :2 },
    // { label: "장소안내", value: "LOCATION", sort: 4 },
    // { label: "FAQ", value: "FAQ", sort: 6 },
    // { label: "이포스터", value: "EPOSTER", sort:9 },
    // { label: "이부스", value: "EBOOTH", sort:10 },
    // { label: "출결확인", value: "ATTENDANCE", sort:11 },
    { label: "인사말", value: "INTRODUCE", sort: 1 },
    { label: "아젠다", value: "AGENDA", sort: 3 },
    { label: "공지사항", value: "NOTICE", sort: 5 },
    { label: "SATELLITE SESSION", value: "SATELLITE", sort: 7 },
    { label: "강좌", value: "LECTURE", sort: 8 },
    { label: "이벤트 컨텐츠", value: "content", sort: 12 },
];

export const SEND_STATUS_TYPES = [
    { label: "대기", value: "W" },
    { label: "발송완료", value: "Y" },
    { label: "오류", value: "E" },
];

// export const EPOSTER_SEARCH = [
//     { label: "ALL", value: "ALL" },
//     { label: "Favorite", value: "FAVORITE" },
// ];

export const EBOOTH_GRADE = [
    { label: "PLATINUM", value: "PLATINUM" },
    { label: "GOLD", value: "GOLD" },
    { label: "SILVER", value: "SILVER" },
    { label: "BRONZE", value: "BRONZE" },
];
