import React, { Fragment, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { isMobile } from "react-device-detect";
import moment from "moment";

import { AppContext } from "../../../components/AppProvider";

import { _U, _CK } from "../../../modules/utils";
import { _CFG } from "../../../modules/config";

import Login from "./Components.Login";

import { DefaultVisual, DefaultVisualSet, getBgSet } from "./asset";
import { ButtonDefault, CloseButton } from "./UI/Components";
import { Theme } from "../../../components/GlobalStyles";
import Registration from "./Components.Registration";
import RegistrationTest from "./Components.Registration.Test";
import RegistrationCheck from "./Components.Registration.Check";
import RegistrationDetail from "./Components.Registration.Detail";

export default (props) => {
    const { cfg, auth, iam } = useContext(AppContext);
    const event = cfg.event || null;

    const [mode, setMode] = useState("");

    const isRegistration = () => {
        if (event.registrationYn === "Y" && event.registOptIdx) {
            if (event.periodLimitYn === "Y") {
                if (event?.registStartDate && event?.registEndDate) {
                    if (moment(event?.registStartDate) <= moment() && moment(event?.registEndDate + "235959", "YYYYMMDDHHmmss") >= moment()) {
                        return true;
                    }
                }
            } else {
                return true;
            }
        } else {
            return false;
        }
        return false;
    };

    const isEventLogin = () => {
        // console.log(event);
        if (event.loginYn === "Y") {
            if ((event?.startDate && event?.endDate)) {
                if ((moment(event?.startDate) <= moment() && moment(event?.endDate + "235959", "YYYYMMDDHHmmss") >= moment())) {
                    return true;
                }
            }
        }
        return false;
    };

    const getMainVisualUrl = () => {
        if (event.registrationYn === "Y" && event.registOptIdx) {
            if (event.periodLimitYn === "Y") {
                if (event?.registStartDate && event?.registEndDate) {
                    if (moment(event?.registStartDate) > moment()) {
                        //사전등록 전 
                        return (isMobile ? event?.mVisualMbUrl : event?.mVisualPcUrl) || event?.mVisualPcUrl;
                    } else if (moment(event?.registStartDate) <= moment() && moment(event?.registEndDate + "235959", "YYYYMMDDHHmmss") >= moment()) {
                        //사전등록기간
                        return (isMobile ? event?.bgMbUrl : event?.bgPcUrl) || event?.bgPcUrl;
                    }
                }
            }
        }
        if (event.loginYn === "Y") {
            if (event?.startDate && event?.endDate) {
                if (moment(event?.startDate) <= moment() && moment(event?.endDate + "235959", "YYYYMMDDHHmmss") >= moment()) {
                    //이벤트 기간
                    return (isMobile ? event?.bgMbUrl : event?.bgPcUrl) || event?.bgPcUrl;
                }
            }
        }
        return (isMobile ? event?.mVisualMbUrl : event?.mVisualPcUrl) || event?.mVisualPcUrl;
    };

    return (
        <Wrapper>
            <Container>
                <Visual src={getMainVisualUrl()} />
                {isEventLogin() && (
                    <Login />
                )}
                {isRegistration() && (
                    <>
                        <RegistrationWrapper>
                            <ButtonDefault
                                onClick={() => {
                                    // setMode("REGISTRATION");
                                    // window.location.href = "/prereg";
                                    window.open(`/#/prereg`);
                                }}
                            >
                                사전등록
                            </ButtonDefault>
                            <ButtonDefault
                                onClick={() => {
                                    setMode("REGISTRATION_CHECK");
                                }}
                            >
                                사전등록확인
                            </ButtonDefault>
                        </RegistrationWrapper>

                        {mode === "REGISTRATION" && (
                            <Modal isOpen={mode === "REGISTRATION"} style={isMobile ? Theme.basic.modal.registration.mobile : Theme.basic.modal.registration.pc}>
                                <CloseButton onClick={() => setMode("")} />
                                {/* <Registration onCancel={() => setMode("")} /> */}
                                <RegistrationTest onCancel={() => setMode("")} />
                            </Modal>
                        )}
                        {mode === "REGISTRATION_CHECK" && (
                            <Modal isOpen={mode === "REGISTRATION_CHECK"} style={isMobile ? Theme.basic.modal.registrationCheck.mobile : Theme.basic.modal.registrationCheck.pc}>
                                <CloseButton onClick={() => setMode("")} />
                                <RegistrationCheck onCancel={() => setMode("")} onSetMode={setMode} />
                            </Modal>
                        )}
                        {mode === "REGISTRATION_DETAIL" && (
                            <Modal isOpen={mode === "REGISTRATION_DETAIL"} style={isMobile ? Theme.basic.modal.registration.mobile : Theme.basic.modal.registration.pc}>
                                <CloseButton onClick={() => setMode("")} />
                                <RegistrationDetail onCancel={() => setMode("")} onSetMode={setMode} />
                            </Modal>
                        )}
                    </>
                )}
            </Container>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    font-size: ${(props) => props.theme.basic.fontSize}px;

    .ReactModal__Content {
        :after {
            content: "123";
            position: absolute;
            right: 0;
            top: -20px;
        }
    }
`;

const Container = styled.div`
    /* width: 1600px; */
    width: 100%;
    height: 100%;
    margin: 0 auto;
    // padding-top: 120px;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        width: 100%;
    }
`;

const Visual = styled.div`
    /* width: 1594px; */
    width: 100%;
    height: 544px;

    ${(props) =>
        props.src
            ? `
        background-image: ${`url(${props.src})`};
    `
            : `
        background-image: ${`url(${DefaultVisual})`};
        background-image: -webkit-image-set(${getBgSet(DefaultVisualSet)});    
    `}

    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        width: 100%;
        height: 300px;
    }
`;

const RegistrationWrapper = styled.div`
    width: 480px;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;

    & ${ButtonDefault} {
        flex-basis: 48%;
    }

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        width: 90%;
    }
`;
