import React, { useContext } from "react";
import { Link } from "react-router-dom";

import Layout from "./Layout";

export default (props) => {
    
    return (
        <Layout {...props}>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-12">
                    <h2>대시보드</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/admin">HOME</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>대시보드</strong>
                        </li>
                    </ol>
                </div>
            </div>
            <div className="wrapper wrapper-content">
                <div className="row">
                    <div className="col-md-12">대시보드</div>
                </div>
            </div>
        </Layout>
    );
};
