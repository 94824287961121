import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import moment from "moment";
import Modal from "react-modal";
import { Theme } from "../../../components/GlobalStyles";

import { isMobile } from "react-device-detect";

import { AppContext } from "../../../components/AppProvider";
import { _U, _CK, _API } from "../../../modules/utils";

import { ButtonSubmitSmall, ButtonExitSmall, ButtonDefaultSmall, H3 } from "../basic/UI/Components";
import { Loading } from "../../../components/UI/Loading";
import { ICFile, ICFileSet, ICLogout, ICLogoutSet, NoImg, NoImgSet, ICSellect, ICSellectSet, ICExit, ICExitSet, getBgSet, } from "./asset";

import Video from "../../../components/UI/Video";

import * as _ from "lodash";
import swal from "sweetalert";

export default (props) => {
    const { auth, cfg, socketConnect, iam } = useContext(AppContext);
    const { history, location, match } = props;
    const { idx } = match.params;
    const { idx: siteIdx, event } = cfg;
    const { eventIdx } = event;
    const { channel } = event;

    const [isLogined, setIsLogined] = useState(false);
    const [isLoadSession, setIsLoadSession] = useState(false);
    const [sessions, setSessions] = useState([]);
    const [session, setSession] = useState(null);
    const [lecture, setLecture] = useState(null);
    const [quiz, setQuiz] = useState(null);
    const [survey, setSurvey] = useState(null);
    const [changeLecture, setChangeLecture] = useState(false);
    const [mgrList, setMgrList] = useState([]);

    const [isAlertShow, setIsAlertShow] = useState(false);
    const [channelAlert, setChannelAlert] = useState(false);
    const [ivsStreamId, setIvsStreamId] = useState();
    const [channelAlertCnt, setChannelAlertCnt] = useState(0);

    const [sessionStartDate, setSessionStartDate] = useState("");

    const sendChannel = () => {
        _U.postMessage("SOCKET-SEND", {
            action: "CHANNEL",
            data: {
                type: "PARTICIPANT",
                channel: idx,
            },
        });
    };

    const loadSessions = () => {
        setIsLoadSession(false);
        _API.get({
            path: "/v1/site/event/sessions",
            data: {
                siteIdx,
                eventIdx,
                channelIdx: idx,
            },
        }).then((res) => {
            const { list, lectureMgrList } = res.data;
            setSessions(list);
            setMgrList(lectureMgrList);
            setIsLoadSession(true);
            setChangeLecture(true);

            //현재 채널 정보
            const channelInfo = channel.filter((c) => c.idx == idx) || [];
            if (channelInfo.length > 0) {
                if (channelInfo[0].channelAlert) {
                    setIsAlertShow(true);
                    setChannelAlert(channelInfo[0].channelAlert);
                } else {
                    setIsAlertShow(false);
                    setChannelAlert("");
                }
            }
        });
    };

    const loadSessionsStartDate = () => {
        _API.get({
            path: "/v1/site/event/alive/startDate",
            data: {
                siteIdx,
                eventIdx,
                channelIdx: idx,
                sessionIdx: session?.idx,
            },
        }).then((res) => {
            const { sessionStartDate } = res.data;
            setSessionStartDate(moment(sessionStartDate).format("HH:mm:ss"));
        });
    };

    const selectSession = () => {
        if (!isLoadSession || sessions?.length === 0) {
            setSession(null);
            return;
        }

        const fs = sessions?.filter((s) => {
            if (s.monitorYn === "Y") {
                return s;
            }
        });

        // const now = moment();
        // const day = now.format("YYYY-MM-DD");
        // const fs = sessions.filter((s) => {
        //     const start = moment(`${day} ${s.startTime}`, "YYYY-MM-DD HH:mm:ss");
        //     const end = moment(`${day} ${s.endTime}`, "YYYY-MM-DD HH:mm:ss");
        //     return now.isBetween(start, end);
        // });
        if (fs?.length > 0) {
            setSession(fs[0]);
            selectLecture(fs[0]);
        } else {
            setSession(sessions[0]);
            selectLecture(sessions[0]);
        }
    };

    const selectLecture = (session) => {
        if (!session) {
            return;
        }

        const fs = session?.lectureList?.filter((s) => {
            if (s.monitorYn === "Y") {
                return s;
            }
        });

        // const now = moment();
        // const day = now.format("YYYY-MM-DD");
        // const fs = session?.lectureList.filter((s) => {
        //     const start = moment(`${day} ${s.startTime}`, "YYYY-MM-DD HH:mm:ss");
        //     const end = moment(`${day} ${s.endTime}`, "YYYY-MM-DD HH:mm:ss");
        //     return now.isBetween(start, end);
        // });

        if (fs.length === 0) {
            setLecture(null);
            return;
        }

        setLecture(fs[0]);
    };

    const sendAlive = (isClose, param) => {
        if (!session || !isLogined) {
            if (isClose) {
                window.self.close();
            }
            return;
        }
        const nowChannel = channel?.filter((item) => {
            if (item.idx == idx) {
                return true;
            }
            return false;
        })[0];
        if (nowChannel?.progressDate === moment().format("YYYYMMDD")) {
            _API.post({
                path: "/v1/site/event/alive",
                data: {
                    siteIdx,
                    eventIdx,
                    channelIdx: idx,
                    sessionIdx: session?.idx,
                },
            }).then((res) => {
                if (param && param.isFirst) {
                    loadSessionsStartDate();
                }
                sendChannel();
                if (isClose) {
                    window.self.close();
                }
            });
        } else {
            sendChannel();
            loadSessionsStartDate();
            if (isClose) {
                window.self.close();
            }
        }

    };

    const loadSurvey = (param) => {
        const data = {
            siteIdx,
            eventIdx,
            channelIdx: idx,
            sessionIdx: session?.idx,
            surveyCategory: param.type.toUpperCase(),
        };
        const req = { path: "/v1/site/event/survey", data };
        _API.get(req).then((res) => {
            // console.log(res);
            const { list, paging } = res.data;
            list.length > 0 && setSurvey(list);
        });
    };

    const onReceivedMessage = (e) => {
        _U.parseMessage(e)
            .then((data) => {
                switch (data.code) {
                    case "SOCKET-RECEIVED":
                        return data.data ? JSON.parse(data.data) : null;
                }
            })
            .then((data) => {
                // console.log("message receive", data);
                if (data?.mode === "QUIZ") {
                    setQuiz(data.quiz);
                }
                if (data?.mode === "VOTE") {
                    loadSurvey(data);
                }
                if (data?.mode === "CHANGE-LECTURE") {
                    // console.log("CHANGE-LECTURE", data)
                    // selectSession(data?.sessionIdx);
                    loadSessions();

                }
            });
    };

    useEffect(() => {
        if (isLoadSession) {
            selectSession();
            // const ss = setInterval(selectSession, 30000);
            // return () => clearInterval(ss);
        }
    }, [isLoadSession]);

    useEffect(() => {
        onResizeWindow();
        window.addEventListener("message", onReceivedMessage);
        window.addEventListener("resize", onResizeWindow);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
            window.removeEventListener("resize", onResizeWindow);
        };
    }, []);

    useEffect(() => {
        if (socketConnect) {
            sendChannel();
        }
    }, [socketConnect]);

    useEffect(() => {
        if (event.useLogin === "Y" && !_CK.isLogin()) {
            swal({ title: "알림", text: "로그인 후 이용이 가능합니다." }).then(() => {
                window.location.href = "/";
            });
            return;
        }
        loadSessions();
        setIsLogined(_CK.isLogin());
    }, [auth]);

    // useEffect(() => {
    //     if (changeLecture) {
    //         selectSession(sessions);
    //         setChangeLecture(false);

    //         isAlertShow && channelAlert && setIsAlertShow(true);
    //     }
    // }, [changeLecture, sessions]);

    useEffect(() => {
        if (session) {
            sendAlive(null, { isFirst: true });
            const sa = setInterval(sendAlive, 10000);
            return () => clearInterval(sa);
        }
    }, [session]);

    useEffect(() => {
        if (quiz) {
            const time = quiz.time || 15;
            setTimeout(() => {
                setQuiz(null);
            }, time * 1000);
        }
    }, [quiz]);

    useEffect(() => {
        // console.log("isAlertShow", isAlertShow, "isLoadSession", isLoadSession)
        if (!isAlertShow && isLoadSession) {
            setChannelAlertCnt(1);
        }
    }, [isAlertShow]);

    const [layerSize, setLayerSize] = useState({});
    const onResizeWindow = () => {
        // console.log("window.size", window.innerWidth, window.innerHeight);
        let leftWidth = (window.innerWidth / 7) * 5;
        let rightWidth = (window.innerWidth / 7) * 2;
        let videoHeight = window.innerHeight - 198;
        let videoWidth = (videoHeight / 9) * 16;
        let padding = 0;

        // console.log("videoWidth ori", videoWidth, "vodHeight ori", videoHeight);
        if (videoWidth > leftWidth) {
            videoWidth = leftWidth;
            videoHeight = (videoWidth / 16) * 9;
        }

        padding = (leftWidth - videoWidth) / 2;

        setLayerSize({
            videoWidth,
            videoHeight,
            padding,
        });

        // console.log("left", leftWidth, "right", rightWidth);
        // console.log("videoWidth", videoWidth, "vodHeight", videoHeight);
        // console.log("padding", padding);
    }

    if (!isLoadSession) {
        return <Loading title="세션을 조회하고 있습니다." background="rgba(0, 0, 0, 0.1)" />;
    }


    return (
        <Wrapper>
            <Header session={session} lecture={lecture} onExit={sendAlive} sessionStartDate={sessionStartDate} iam={iam} />
            <Container>
                {!session ? (
                    <Waiting>강의를 준비중입니다.</Waiting>
                ) : (
                    <>
                        <Lefts session={session} lecture={lecture} siteIdx={siteIdx} eventIdx={eventIdx} ivsStreamId={ivsStreamId} layerSize={layerSize} />
                        <Rights idx={idx} session={session} lecture={lecture} mgrList={mgrList} setIvsStreamId={setIvsStreamId} ivsStreamId={ivsStreamId} layerSize={layerSize} />
                    </>
                )}
            </Container>
            {/* {isMobile && <FooterWrapper dangerouslySetInnerHTML={{ __html: event?.footer }}></FooterWrapper>} */}

            {isMobile && <CompayLogoAutoSlider siteIdx={siteIdx} eventIdx={eventIdx} />}
            {quiz && <Quiz idx={idx} quiz={quiz} onSended={() => setQuiz(null)} />}
            {survey && <Survey idx={idx} survey={survey} onSended={() => setSurvey(null)} />}

            {isAlertShow && channelAlert && channelAlertCnt == 0 && (
                <Modal isOpen={channelAlert ? true : false} ariaHideApp={false} style={isMobile ? Theme.basic.modal.registrationCheck.mobile : Theme.basic.modal.registrationCheck.pc} >
                    <div
                        dangerouslySetInnerHTML={{
                            __html: channelAlert,
                        }}
                        style={{ maxHeight: "400px", minHeight: "280px" }}
                    ></div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <button className="btn btn-success" onClick={() => setIsAlertShow(false)} >위 내용을 확인하였고 동의합니다.</button>
                    </div>
                </Modal>
            )}

        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        overflow: unset;
        height: 100%;
    }
`;

const Container = styled.div`
    width: 100%;
    /* height: 100%; */
    height: calc(100% - 90px);
    display: flex;
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        display: block;
        height: 100%;
    }
`;

const Waiting = styled.div`
    margin: 10rem 0rem;
    text-align: center;
    width: 100%;
    font-family: ${(props) => props.theme.fontFamily.medium};
    color: ${(props) => props.theme.basic.color.primary};
    font-size: 2rem;
`;

const WaitingInConsole = styled.div`
    margin: 1rem 0rem;
    text-align: center;
    width: 100%;
    font-family: ${(props) => props.theme.fontFamily.medium};
    color: ${(props) => props.theme.basic.color.primary};
    font-size: 2rem;
`;

const Header = (props) => {
    const { session, onExit, lecture, sessionStartDate, iam } = props;
    const { cfg } = useContext(AppContext);
    const event = cfg.event || null;

    useEffect(() => {
    }, [sessionStartDate])

    const onExitClick = () => {
        swal({
            title: "현재 강의 에서 퇴장하시겠습니까?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((result) => {
            if (result) {
                if(!document.referrer || document.referrer.indexOf('/lecture') == -1 ){
                    window.location.href = '/';
                } else {
                    onExit & onExit(true);
                }
            }
        });
    };
    // console.log("Header session", session, "lecture", lecture);
    return (
        <HeaderWrapper>
            {!isMobile && (
                <LogoWrapper>
                    <LogoContainer>
                        {event?.logoTopPcUrl && (
                            <Logo
                                to={"/"}
                                onClick={() => {
                                    window.location.href = "/";
                                }}
                            >
                                <img src={event?.logoTopPcUrl} />
                            </Logo>
                        )}
                    </LogoContainer>
                </LogoWrapper>
            )}
            <MenuWrapper>
                <div>
                    <CTitle>{session?.channelTitle}</CTitle>
                    <CDescription>{session?.title ? `${session?.title}` : "Break Time"}</CDescription>
                    {/* <CDate>
                    {session?.progressDate && moment(session?.progressDate).format("YYYY.MM.DD(ddd) ")}
                    {session?.startTime && session?.endTime && <>{session?.startTime + "~" + session?.endTime}</>}
                </CDate> */}
                </div>
            </MenuWrapper>

            <My>
                {iam && iam.name && !isMobile ? (
                    <>
                        <MyNameWrapper>{iam?.name ? `${iam?.name}님` : ''}</MyNameWrapper>
                        <SessionStartDateWrapper>
                            <div>최초 입장시간</div>
                            <span>{sessionStartDate}</span>
                        </SessionStartDateWrapper>
                    </>
                ) : (
                    <>
                        <MyNameWrapper />
                        <SessionStartDateWrapper />
                    </>
                )}
                {isMobile ? <MLogout onClick={onExitClick.bind(this)} />
                    : <ButtonExitSmall onClick={onExitClick.bind(this)}>퇴장하기 <ExitSpan /></ButtonExitSmall>}
            </My>
        </HeaderWrapper >
    );
};

const MyNameWrapper = styled.div`
    font-size: 20px;
`;

const SessionStartDateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
        color: red;
    }
`;

const HeaderWrapper = styled.div`
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${(props) => props.theme.basic.line.header};

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        display: block;
        position: relative;
        height: auto;
        min-height: 60px;
    }
`;

const LogoWrapper = styled.div`
    flex: 1;
    flex-grow: 1;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        width: 100%;
        text-align: center;
    }
`;

const LogoContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Logo = styled(Link)`
    img {
        min-width: 144px;
        max-width: 144px;
        min-height: 30px;
        max-height: 90px;
    }
`;

const MenuWrapper = styled.div`
    flex: 1;
    flex-grow: 7;
    display: flex;
    /* padding-top: 10px; */
    align-items: center;
    ${(props) => (isMobile && `padding:10px;`)}

`;

const CTitle = styled.div`
    font-size: 12px;
    font-family: ${(props) => props.theme.fontFamily.regular};
    color: ${(props) => props.theme.basic.color.primary};
`;
const CDescription = styled.div`
    font-size: 18px;
    font-family: ${(props) => props.theme.fontFamily.regular};
    color: ${(props) => props.theme.basic.color.primary};

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        font-size: 16px;
    }
`;
const CDate = styled.div`
    font-size: 11px;
    font-family: ${(props) => props.theme.fontFamily.medium};
    color: ${(props) => props.theme.basic.color.date};
`;

const My = styled.div`
    flex: 1;
    flex-grow: 2.3;
    font-family: ${(props) => props.theme.fontFamily.regular};
    color: ${(props) => props.theme.basic.color.primary};
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        position: absolute;
        right: 0px;
        top: 0px;
    }
`;

const Lefts = (props) => {
    const { session, lecture, siteIdx, eventIdx, ivsStreamId, layerSize } = props;
    const { cfg } = useContext(AppContext);
    const { event } = cfg;

    const getUrl = () => {
        if (!session) {
            return;
        }
        // console.log("vodvod ss", session)
        // console.log("vodvod ll", lecture)
        // console.log("vodvod ivsStreamId", ivsStreamId)

        // 영상 우선 순위 ( [영상 -> 라이브], [Lecture -> Session -> Channel] )
        if (lecture?.vodEncodedUrl) {
            // console.log("11111")
            return lecture?.vodEncodedUrl;
        } else if (lecture?.lectureIvsPlaybackUrl) {
            // console.log("2222")
            return lecture?.lectureIvsPlaybackUrl;
        } else if (session?.sessionVodEncodedUrl) {
            // console.log("3333")
            return session?.sessionVodEncodedUrl;
        } else if (session?.sessionIvsPlaybackUrl) {
            // console.log("4444")
            return session?.sessionIvsPlaybackUrl;
        } else if (session?.vodEncodedUrl) {
            // console.log("5555")
            return session?.vodEncodedUrl;
        } else if (session?.ivsPlaybackUrl || session?.channelIvs2PlaybackUrl) {
            if (ivsStreamId === "Foreign" && session?.channelIvs2PlaybackUrl) {
                // console.log("6666")
                return session?.channelIvs2PlaybackUrl;
            } else {
                // console.log("7777")
                return session?.ivsPlaybackUrl;
            }
        }

        return;
    };

    const getStreamType = () => {
        if (!session) {
            return null;
        }
        if (lecture?.vodEncodedUrl) {
            return null;
        } else if (lecture?.lectureIvsPlaybackUrl) {
            return lecture?.lectureIvsStreamType;
        } else if (session?.sessionVodEncodedUrl) {
            return null;
        } else if (session?.sessionIvsPlaybackUrl) {
            return session?.sessionIvsStreamType;
        } else if (session?.vodEncodedUrl) {
            return null;
        } else if (session?.ivsPlaybackUrl || session?.channelIvs2PlaybackUrl) {
            if (ivsStreamId === "Foreign" && session?.channelIvs2PlaybackUrl) {
                return session?.channelIvs2StreamType;
            } else {
                return session?.ivsStreamType;
            }
        }
        return null;
    };

    // useEffect(() => {
    //     console.log("session", session);
    //     console.log("lecture", lecture);
    //     console.log("session", getUrl());
    //     console.log("getStreamType", getStreamType());
    // }, [session]);

    return (
        <LeftsWrapper layerSize={layerSize}>
            <StreamingWrapper layerSize={layerSize}>
                <Video url={getUrl()} autoPlay={true} poster={session?.vodThumbnailPointUrl || session?.vodThumbnailUrl} loop={true} controls={true} getStreamType={getStreamType}
                    paddingTop={"56.25%"}
                />
            </StreamingWrapper>
            {/* {!isMobile && <FooterWrapper dangerouslySetInnerHTML={{ __html: event?.footer }}></FooterWrapper>} */}
            {!isMobile && <CompayLogoAutoSlider siteIdx={siteIdx} eventIdx={eventIdx} eventInfo={event} layerSize={layerSize} />}
        </LeftsWrapper>
    );
};

const CompayLogoAutoSlider = ({ siteIdx, eventIdx, eventInfo, layerSize }) => {

    const [companyLogoList, setCompanyLogoList] = useState([]);

    useEffect(() => {
        loadCompanyLogo();
    }, [])


    const loadCompanyLogo = () => {
        _API.get({
            path: "/v1/event/company/logo",
            data: {
                siteIdx,
                eventIdx,
            },
        }).then((res) => {
            const { list } = res.data;
            setCompanyLogoList(list);
            // const tempL = list.map(l => l.finalSignedUrl);
            // setCompanyLogoList([...tempL, ...tempL, ...tempL, ...tempL, ...tempL]);
        });
    };

    const LogoWrapper = styled.div``;

    return (
        <LogoWrapper>
            <AutoSlider list={companyLogoList} eventInfo={eventInfo} layerSize={layerSize} />
        </LogoWrapper>
    )
}

const AutoSlider = ({ list, eventInfo, layerSize }) => {

    const [itemList, setItemList] = useState([]);
    const [repeatNum, setRepeatNum] = useState(2);
    const { cfg } = useContext(AppContext);
    const { event } = cfg;

    const getRepeatNum = () => {
        const len = list?.length;

        // if (len > 20) {
        //     return 1;
        // }

        switch (len) {
            case 6:
            case 5:
                return 3;
            case 4:
            case 3:
                return 5;
            case 2:
                return 7;
            case 1:
                return 12;
            default:
                return 2;
        }
    }

    useEffect(() => {
        if (eventInfo?.footerFlowYn === "Y") {
            const temList = []
            const rN = getRepeatNum();
            setRepeatNum(rN);
            for (let i = 0; i < rN; i++) {
                temList.push(...list);
            }
            setItemList(temList);
        } else {
            setItemList(list);
        }

    }, []);

    useEffect(() => {
    }, [itemList, repeatNum])

    const goUrl = (url) => {

        if (!url) {
            return;
        }

        window.open(url);
    }

    return (
        <Slider layerSize={layerSize}>
            {event?.footerFlowYn === "Y" ? (
                <SlideBox len={itemList?.length} repeatNum={repeatNum}>
                    {itemList.map((l, i) => (
                        <SliderItem key={i} onClick={() => goUrl(l.homepageUrl)}>
                            <img src={l.finalSignedUrl} />
                        </SliderItem>
                    ))}
                </SlideBox>
            ) : (
                <NoSlideBox len={itemList?.length} repeatNum={repeatNum}>
                    {itemList.map((l, i) => (
                        <SliderItem key={i} onClick={() => goUrl(l.homepageUrl)}>
                            <img src={l.finalSignedUrl} />
                        </SliderItem>
                    ))}
                </NoSlideBox>
            )}
        </Slider>
    );
}

const slide = (len, repeatNum) => keyframes`
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-156px * ${len / repeatNum}));
    }
`;

const slideMobile = (len, repeatNum) => keyframes`
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-146px * ${len / repeatNum}));
    }
`;

const Slider = styled.div`
        height: 108px;
        width: ${(props) => !isMobile && props.layerSize ? `${props.layerSize.videoWidth}px;` : `${window.innerWidth}px`};
        overflow-x: hidden;
    /* padding: 25px; */
        display: flex;
        justify-content: start;
        align-items: center;    
        @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
            height: 70px;
        }
    `;

const SlideBox = styled.div`
        display: grid;
        grid-auto-flow: column;
        column-gap: 40px;

        width: calc(((116+40) * ${props => props.len})-40);
        animation: ${props => slide(props.len, props.repeatNum)} calc(2s * ${props => props.len / props.repeatNum}) linear infinite;

        &:hover {
            animation-play-state: paused;
        }

        @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
            width: calc(((116+30) * ${props => props.len})-30);
            column-gap: 30px;
            animation: ${props => slideMobile(props.len, props.repeatNum)} calc(2s * ${props => props.len / props.repeatNum}) linear infinite;
            &:hover {
                animation-play-state: unset;
            }
        }
    `;

const NoSlideBox = styled.div`
        display: grid;
        grid-auto-flow: column;
        column-gap: 40px;

        width: calc(((116+40) * ${props => props.len})-40);
        // animation: ${props => slide(props.len, props.repeatNum)} calc(2s * ${props => props.len / props.repeatNum}) linear infinite;

        // &:hover {
        //     animation-play-state: paused;
        // }

        @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
            width: calc(((116+30) * ${props => props.len})-30);
            column-gap: 30px;
            // animation: ${props => slideMobile(props.len, props.repeatNum)} calc(2s * ${props => props.len / props.repeatNum}) linear infinite;
            // &:hover {
            //     animation-play-state: unset;
            // }
        }
    `;

const SliderItem = styled.div`
        width: 116px;
        height: 54px;
        line-height: 100px;

        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        cursor: pointer;

        & Img {
            width: 100%;
            max-height: 54px; 
        }
        :first-child{
            margin-left:15px;
        }
    `;

const LeftsWrapper = styled.div`
    flex: 1;
    flex-grow: 5;
    height: 100%;
    overflow: hidden;
    margin-left: ${(props) => !isMobile && props.layerSize ? `${props.layerSize.padding}px;` : `0px`};
    max-width: ${(props) => !isMobile && props.layerSize ? `${props.layerSize.videoWidth}px;` : `100%`};

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        display: block;
        /* height: 220px; */
        position: sticky;
        top: 0;
    }
`;

const StreamingWrapper = styled.div`
    height: calc(100% - 108px);
    max-height: ${(props) => !isMobile && props.layerSize ? `${props.layerSize.videoHeight}px;` : `calc(100% - 108px)`};
    width: ${(props) => !isMobile && props.layerSize ? `${props.layerSize.videoWidth}px;` : `100%`};

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        height: 100%;
    }
`;
const FooterWrapper = styled.div`
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px;
    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        display: block;
        position: relative;
    }
`;

const Rights = (props) => {
    const { cfg } = useContext(AppContext);
    const { idx: siteIdx, event } = cfg;
    const { eventIdx } = event;
    const { chatYn, speakerYn } = event;

    const { idx, session, lecture, layerSize } = props;
    const [menu, setMenu] = useState("PROGRAM");

    const onQuestion = () => {
        if (!session) {
            return;
        }

        var textarea = document.createElement('textarea');
        textarea.rows = 3;
        textarea.className = 'swal-content__textarea';

        // Set swal return value every time an onkeyup event is fired in this textarea
        textarea.onkeyup = function () {
            swal.setActionValue({
                confirm: this.value
            });
        };
        swal({
            text: "질문을 입력해 주세요.",
            // content: "input",
            content: textarea,
            buttons: ["취소", "보내기"],
        }).then((q, w, e) => {
            if (q) {
                _API.post({
                    path: "/v1/site/event/question",
                    data: {
                        siteIdx,
                        eventIdx,
                        channelIdx: idx,
                        sessionIdx: session?.idx,
                        lectureIdx: lecture?.idx,
                        question: q,
                    },
                }).then((res) => {
                    _U.postMessage("SOCKET-SEND", {
                        action: "QUESTION",
                        data: {
                            channel: idx,
                            sessionIdx: session?.idx,
                            lectureIdx: lecture?.idx,
                        },
                    });
                    _U.postMessage("SOCKET-SEND", {
                        action: "OPERATOR",
                        data: {
                            channel: idx,
                            mode: "QUESTION",
                            sessionIdx: session?.idx,
                            lectureIdx: lecture?.idx,
                        },
                    });
                    swal({ title: "알림", text: "질문을 등록했습니다." });
                });
            }
        });
    };

    const [streamId, setStreamId] = useState(props.ivsStreamId || "Native");
    const streamChange = (id) => {
        // console.log("steam chante", id)
        setStreamId(id);
        props.setIvsStreamId && props.setIvsStreamId(id);
    }

    const isMultiStream = () => {
        if (!session) {
            return false;
        }

        // 영상 우선 순위 ( [영상 -> 라이브], [Lecture -> Session -> Channel] )
        if (lecture?.vodEncodedUrl) {
            return false;
        } else if (lecture?.lectureIvsPlaybackUrl) {
            return false;
        } else if (session?.sessionVodEncodedUrl) {
            return false;
        } else if (session?.sessionIvsPlaybackUrl) {
            return false;
        } else if (session?.vodEncodedUrl) {
            return false;
        } else if (session?.channelIvs2PlaybackUrl) {
            return true;
        }
        return false;
    };

    return (
        <RightsWrapper layerSize={layerSize}>
            <RTop>
                {isMultiStream() && (
                    <Fragment>
                        <Menus>
                            <MenuItem onClick={() => streamChange("Native")} ison={streamId === "Native"} >
                                Korean
                            </MenuItem>
                            <MenuItem onClick={() => streamChange("Foreign")} ison={streamId === "Foreign"} >
                                English
                            </MenuItem>
                        </Menus>
                        <Hr />
                    </Fragment>
                )}
                {!session || event?.qnaYn !== "Y" ? <WaitingInConsole></WaitingInConsole> : <QuestionButton onClick={onQuestion}>Question <QuestionSpan /></QuestionButton>}
                <Menus>
                    <MenuItem onClick={() => setMenu("PROGRAM")} ison={menu === "PROGRAM"} chatYn={chatYn} speakerYn={speakerYn}>
                        프로그램
                    </MenuItem>
                    {speakerYn === "Y" && (
                        <MenuItem onClick={() => setMenu("MGR")} ison={menu === "MGR"} chatYn={chatYn} speakerYn={speakerYn}>
                            연자소개
                        </MenuItem>
                    )}
                    {chatYn === "Y" && (
                        <MenuItem onClick={() => setMenu("CHAT")} ison={menu === "CHAT"} chatYn={chatYn} speakerYn={speakerYn}>
                            라이브챗
                        </MenuItem>
                    )}
                </Menus>
                <Hr />
            </RTop>
            {chatYn === "Y" && <Chat {...props} menu={menu} />}
            {menu === "MGR" && speakerYn === "Y" && <Mgr {...props} />}
            {menu === "PROGRAM" && <Program {...props} />}
            {event && event.channelDesc && (
                <ServiceInfoWrapper dangerouslySetInnerHTML={{ __html: event.channelDesc }} />
            )}
        </RightsWrapper>
    );
};

const RightsWrapper = styled.div`
    flex: 1;
    flex-grow: 2;
    height: 100%;
    background-color: ${(props) => props.theme.basic.bg.white};
    display: flex;
    flex-direction: column;
    margin-right: ${(props) => !isMobile && props.layerSize ? `${props.layerSize.padding}px` : `0px`};
    min-width: ${(props) => !isMobile && props.layerSize ? `324px` : ``};

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        display: block;
        height: unset;
    }
`;

const RTop = styled.div`
    padding: 10px 15px 0;
`;

const Menus = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const MenuItem = styled.button.attrs({
    type: "button",
})`
    flex-basis: ${(props) => (props.chatYn === "Y" && props.speakerYn === "Y" ? "31%" : props.chatYn === "N" && props.speakerYn === "N" ? "98%" : "48%")};
    background-color: ${(props) => (props.ison ? props.theme.basic.bg.channelTabOn : props.theme.basic.bg.channelTab)};
    color: ${(props) => props.theme.basic.color.white};
    font-size: 14px;
    font-family: ${(props) => props.theme.fontFamily.medium};
    text-align: center;
    padding: 8px 0;
    text-align: center;
    border: none;
    border-radius: 21px;
    margin-bottom: 0px;

    /* @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        padding: 20px 10px;
    } */
`;

const QuestionButton = styled.button.attrs({
    type: "button",
})`
    width: 100%;
    background-color: ${(props) => props.theme.basic.bg.skyblueBackground};
    color: ${(props) => props.theme.basic.color.buttonSubmit};
    font-size: 18px;
    font-family: ${(props) => props.theme.fontFamily.medium};
    text-align: center;
    padding: 13px 0px;
    border: none;
    border-radius: 5px;
    margin-bottom: 10px;
`;

const Hr = styled.hr`
    border-top: 1px solid ${(props) => props.theme.basic.line.darkHr};
    width: 100%;
`;
const HrDash = styled.hr`
    border-top: 1px solid ${(props) => props.theme.basic.line.darkHr};
    border-style: dashed;
    width: 100%;
`;

const ServiceInfoWrapper = styled.div`
    height:108px;
    /* border: 1px solid #e7e7e7; */
    /* padding:10px; */
    overflow: auto;
    p {
        margin-bottom:2px;
    }
`;

const Chat = (props) => {
    const { cfg, iam } = useContext(AppContext);
    const { idx: siteIdx, event } = cfg;
    const { eventIdx } = event;
    const { idx } = props;
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState("");
    const [isSendProc, setIsSendProc] = useState(false);
    const endRef = useRef(null);
    const intoRef = useRef(null);

    const scrollToBottom = () => {
        intoRef.current.scrollTop = intoRef.current.scrollHeight;
        endRef?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    };

    const onKeyUp = (e) => {
        if (e.key === "Enter") {
            onSend();
        }
    };

    const onSend = () => {
        if (!message) {
            return;
        }
        if (isSendProc) {
            return;
        }
        setIsSendProc(true);

        _U.postMessage("SOCKET-SEND", {
            action: "CHAT",
            data: {
                mode: "CHAT",
                id: `${siteIdx}_${eventIdx}_${idx}_PARTICIPANT_${iam?.idx}`,
                channel: idx,
                name: iam?.name || "익명",
                memType: "PARTICIPANT",
                message,
            },
        });
        setMessage("");
        setIsSendProc(false);
    };

    const onReceivedMessage = (e) => {
        // console.log("receive message00", e);
        _U.parseMessage(e)
            .then((data) => {
                // console.log("receive message11", data);
                // try {
                //     console.log(data.data ? JSON.parse(data.data) : null);
                // } catch (e) {
                //     console.log("eee", e)
                // }
                switch (data.code) {
                    case "SOCKET-RECEIVED":
                        return data.data ? JSON.parse(data.data) : null;
                }
            })
            .then((data) => {
                // console.log("receive message", data);
                if (data?.mode === "CHAT"
                    && ((data?.memType !== "OPERATOR" && data?.memType !== "PRINCIPAL") || data?.targetMemType === "ALL")
                ) {
                    setMessages((o) => [...o.slice(-99), data]);
                }
            });
    };

    useEffect(() => {
        window.addEventListener("message", onReceivedMessage);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, []);

    useEffect(scrollToBottom, [messages]);

    return (
        <ChatWrapper menu={props.menu}>
            <ChatMessageWrapper ref={intoRef}>
                {messages.map((item, i) => (
                    <ChatMessage key={i} isAdmin={item.memType === "OPERATOR"} isMe={item.id === `${siteIdx}_${eventIdx}_${idx}_PARTICIPANT_${iam?.idx}`}>
                        {item.id === `${siteIdx}_${eventIdx}_${idx}_PARTICIPANT_${iam?.idx}` ? (
                            <>
                                <div></div>
                                <div>{item.message}</div>
                                <div>{item.name}</div>
                            </>
                        ) : item.memType === "PARTICIPANT" ? (
                            <>
                                <div style={{ textAlign: "center" }}>{item.name}</div>
                                <div>{item.message}</div>
                            </>
                        ) : (
                            <>
                                <div className={"label-danger"} style={{ textAlign: "center", color: "#ffffff", display: "flex", justifyContent: "center", alignItems: "center" }}>{item.name}</div>
                                <div style={{ color: "#ed5565" }}>{item.message}</div>
                            </>
                        )
                        }
                    </ChatMessage>
                ))}
            </ChatMessageWrapper>
            <ChatInputWrapper>
                <InputWrapper>
                    {!isMobile && <CName>{iam?.name}</CName>}
                    <CInput>
                        <input type="text" placeholder="내용을 입력해 주세요" value={message || ""} onChange={(e) => setMessage(e.target.value)} onKeyUp={onKeyUp} />
                    </CInput>
                </InputWrapper>
                <ButtonWrapper>
                    <button type="button" onClick={onSend}>
                        전송
                    </button>
                </ButtonWrapper>
            </ChatInputWrapper>
            <div ref={endRef} />
        </ChatWrapper>
    );
};

const ChatWrapper = styled.div`
    flex: 1;
    overflow: hidden;
    ${(props) => props.menu !== "CHAT" && `display:none;`}

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        overflow: auto;
    }
`;
const ChatMessageWrapper = styled.div`
    height: calc(100% - 120px);
    overflow: auto;
    margin: 0 15px;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        height: 300px;
    }
`;
const ChatMessage = styled.div`
    width: 100%;
    color: ${(props) => props.theme.basic.color.primary};
    font-size: 16px;
    margin-bottom: 10px;
    display: flex;
    font-family: ${(props) => props.theme.fontFamily.regular};

    :last-child {
        margin-bottom: 0px;
    }

    div {
        flex: 1;
        line-height: 22px;
    }

    ${(props) =>
        props.isMe
            ? `
        justify-content:flex-end;
        div:first-child {
            flex-grow: 2;
        }

        div:nth-child(2) {
            flex-grow: 7;
            margin-right: 10px;            
            display:flex;
            justify-content: flex-end;    
        }

        div:last-child {
            color: ${props.theme.basic.color.off};
            font-size: 14px;
            flex-grow: 1.5;
            font-family: ${props.theme.fontFamily.medium};
            line-height: 24px;
        }        
    `
            : `
        div:first-child {
            color: ${props.theme.basic.color.off};
            font-size: 14px;
            margin-right: 10px;
            flex-grow: 1.5;
            font-family: ${props.theme.fontFamily.medium};
            line-height: 24px;
        }

        div:last-child {
            flex-grow: 7;
        }    
    `}
`;
const ChatInputWrapper = styled.div`
    height: 120px;
    display: flex;
    justify-content: space-between;
    background: ${(props) => props.theme.basic.bg.chat};
    padding: 25px 15px 0;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        height: 70px;
        padding: 13px 15px 0;
    }
`;

const InputWrapper = styled.div`
    flex: 1;
    flex-grow: 7;

    @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
        flex-grow: 9;
    }
`;

const CName = styled.h3`
    font-family: ${(props) => props.theme.fontFamily.medium};
    color: ${(props) => props.theme.basic.color.primary};
    font-size: 16px;
`;
const CInput = styled.div`
    input {
        width: 100%;
        background: none;
        border: none;
        border-bottom: 1px solid ${(props) => props.theme.basic.line.inputBottom};
        padding-bottom: 10px;
        color: ${(props) => props.theme.basic.color.date};

        @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
            /* background-color: ${(props) => props.theme.basic.bg.mobileInput};*/
            border: 1px solid ${(props) => props.theme.basic.line.input.mobileChat};
            border-radius: 23px;
            padding: 12px 0 10px 10px;
        }
    }
`;

const ButtonWrapper = styled.div`
    flex: 1;
    flex-grow: 3;
    text-align: right;

    button {
        background-color: ${(props) => props.theme.basic.bg.buttonSubmit2};
        color: ${(props) => props.theme.basic.color.buttonSubmit};
        font-size: 16px;
        font-family: ${(props) => props.theme.fontFamily.medium};
        border: none;
        border-radius: 5px;
        padding: 25px;

        @media only screen and (max-width: ${(props) => props.theme.basic.mobileWidth}px) {
            padding: 11px;
        }
    }
`;

const Quiz = (props) => {
    // const { siteIdx, eventIdx, channel: channelIdx, quiz, onSended } = props;

    const { cfg } = useContext(AppContext);
    const { idx: siteIdx, event } = cfg;
    const { eventIdx } = event;
    const { idx, quiz, onSended } = props;

    const onQuizClick = (answer) => {
        _API.post({
            path: "/v1/site/event/quiz",
            data: {
                siteIdx,
                eventIdx,
                channelIdx: idx,
                quizIdx: quiz.idx,
                answer,
            },
        }).then((res) => {
            swal({ title: "알림", text: "참여해 주셔서 감사합니다." }).then(() => {
                onSended && onSended();
            });
        });
    };

    return (
        <QContainer>
            <QWrapper>
                <QTitle dangerouslySetInnerHTML={{ __html: quiz.question }} />
                {quiz.item1 && <QAnswer onClick={onQuizClick.bind(this, 1)}>{quiz.item1}</QAnswer>}
                {quiz.item2 && <QAnswer onClick={onQuizClick.bind(this, 2)}>{quiz.item2}</QAnswer>}
                {quiz.item3 && <QAnswer onClick={onQuizClick.bind(this, 3)}>{quiz.item3}</QAnswer>}
                {quiz.item4 && <QAnswer onClick={onQuizClick.bind(this, 4)}>{quiz.item4}</QAnswer>}
                {quiz.item5 && <QAnswer onClick={onQuizClick.bind(this, 5)}>{quiz.item5}</QAnswer>}
            </QWrapper>
        </QContainer>
    );
};

const Survey = (props) => {

    const { cfg } = useContext(AppContext);
    const { idx: siteIdx, event } = cfg;
    const { eventIdx } = event;
    const { idx, survey, onSended } = props;

    const [answer, setAnswer] = useState({});

    const onSurveyClick = (idx, ans, type) => {

        switch (type) {
            case "SINGLE":
            case "ANSWER":
                setAnswer({ ...answer, [idx]: ans });
                break;
            case "MULTIPLE":
                if (answer[idx] || "") {
                    // if(answer[idx]?.indexOf(ans) != -1) answer[idx].splice(answer[idx]?.indexOf(ans),1);
                    // else answer[idx].push(ans);
                    const beforeAnswer = answer[idx];
                    const set = new Set(beforeAnswer);
                    const uniqueArr = [...set];

                    if (uniqueArr.includes(ans)) {
                        uniqueArr.splice(uniqueArr.indexOf(ans), 1);
                        setAnswer({ ...answer, [idx]: [...uniqueArr] });
                    } else {
                        setAnswer({ ...answer, [idx]: [...uniqueArr, ans] });
                    }
                }
                else {
                    setAnswer({ ...answer, [idx]: [ans] });
                }
                break;
        }

    };

    const onSurveySubmit = () => {
        _API.post({
            path: "/v1/site/event/survey",
            data: {
                siteIdx,
                eventIdx,
                channelIdx: idx,
                answer,
            },
        }).then((res) => {
            swal({ title: "알림", text: "참여해 주셔서 감사합니다." }).then(() => {
                _U.postMessage("SOCKET-SEND", {
                    action: "SURVEY-MONITOR",
                    data: {
                        channel: idx,
                    },
                });

                onSended && onSended();
            });
        });
    }

    const QText = styled.input`
        width: 100%;
        background: none;
        border: none;
        border-bottom: 1px solid #3c3c3c;
        margin-bottom: 10px;
        padding-bottom: 10px;
        color: #a7a7a7;
    `;

    const QSingleAnswer = styled.button`
        margin: 0 0 0.5rem;
        padding: 0.5rem 0;
        width: 100%;
        display: block;
        border: #cccccc solid 1px;
        border-radius: 0.3rem;

        ${(props) => props.isCurrent && "background-color:#4682b4;"}
    `;

    const QMultipleAnswer = styled.button`
        margin: 0 0 0.5rem;
        padding: 0.5rem 0;
        width: 100%;
        display: block;
        border: #cccccc solid 1px;
        border-radius: 0.3rem;

        ${(props) => props.isCurrent && "background-color:#4682b4;"}
    `;

    const QTextStyle = {
        width: "100%",
        background: "none",
        border: "none",
        borderBottom: "1px solid #3c3c3c",
        marginBottom: "10px",
        paddingBottom: "10px",
        color: "black"
    };

    return (
        <QContainer>
            <QWrapper>
                {survey?.map((item, i) => (
                    <Fragment key={i}>
                        {i > 0 && (
                            <div style={{ margin: "20px 0", borderTop: "1px dotted #1c84c6" }}></div>
                        )}
                        {item.thumbnailFileSignedUrl && (
                            <QTitle>
                                <img src={item.thumbnailFileSignedUrl} style={{ width: "100%" }} />
                            </QTitle>
                        )}
                        {item.headDescription && (<QTitle dangerouslySetInnerHTML={{ __html: item.headDescription }} />)}
                        <QTitle dangerouslySetInnerHTML={{ __html: item.question }} />
                        {item.questionType === "ANSWER" && (
                            // <QText type="text" placeholder="답변을 입력해 주세요" value={answer[item.idx] ||""} onChange={(e) => setAnswer({...answer, [item.idx] : e.target.value})} />
                            <input type="text" placeholder="답변을 입력해 주세요" value={answer[item.idx] || ""} onChange={(e) => setAnswer({ ...answer, [item.idx]: e.target.value })} style={QTextStyle} />
                        )}
                        {item.questionType === "SINGLE" && (
                            <Fragment>
                                {item.item1 && <QSingleAnswer onClick={() => onSurveyClick(item.idx, 1, item.questionType)} isCurrent={answer[item.idx] == 1 ? true : false} >{item.item1}</QSingleAnswer>}
                                {item.item2 && <QSingleAnswer onClick={() => onSurveyClick(item.idx, 2, item.questionType)} isCurrent={answer[item.idx] == 2 ? true : false} >{item.item2}</QSingleAnswer>}
                                {item.item3 && <QSingleAnswer onClick={() => onSurveyClick(item.idx, 3, item.questionType)} isCurrent={answer[item.idx] == 3 ? true : false} >{item.item3}</QSingleAnswer>}
                                {item.item4 && <QSingleAnswer onClick={() => onSurveyClick(item.idx, 4, item.questionType)} isCurrent={answer[item.idx] == 4 ? true : false} >{item.item4}</QSingleAnswer>}
                                {item.item5 && <QSingleAnswer onClick={() => onSurveyClick(item.idx, 5, item.questionType)} isCurrent={answer[item.idx] == 5 ? true : false} >{item.item5}</QSingleAnswer>}
                            </Fragment>
                        )}
                        {item.questionType === "MULTIPLE" && (
                            <Fragment>
                                {/* {JSON.stringify(answer[item.idx])} */}
                                {item.item1 && <QMultipleAnswer onClick={() => onSurveyClick(item.idx, 1, item.questionType)} isCurrent={answer[item.idx]?.includes(1) ? true : false}>{item.item1}</QMultipleAnswer>}
                                {item.item2 && <QMultipleAnswer onClick={() => onSurveyClick(item.idx, 2, item.questionType)} isCurrent={answer[item.idx]?.includes(2) ? true : false}>{item.item2}</QMultipleAnswer>}
                                {item.item3 && <QMultipleAnswer onClick={() => onSurveyClick(item.idx, 3, item.questionType)} isCurrent={answer[item.idx]?.includes(3) ? true : false}>{item.item3}</QMultipleAnswer>}
                                {item.item4 && <QMultipleAnswer onClick={() => onSurveyClick(item.idx, 4, item.questionType)} isCurrent={answer[item.idx]?.includes(4) ? true : false}>{item.item4}</QMultipleAnswer>}
                                {item.item5 && <QMultipleAnswer onClick={() => onSurveyClick(item.idx, 5, item.questionType)} isCurrent={answer[item.idx]?.includes(5) ? true : false}>{item.item5}</QMultipleAnswer>}
                            </Fragment>
                        )}


                    </Fragment>
                ))}
                <button className="btn btn-success" style={{ marginTop: "10px", width: "100%" }} onClick={() => onSurveySubmit()} >제출</button>
            </QWrapper>
        </QContainer>
    );
};

const QContainer = styled.div`
    padding: 1rem;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    flex-direction: column;

    background: rgba(0, 0, 0, 0.7);
    border: none;
    z-index: 2000;
    align-items: center;
    justify-content: center;
`;

const QWrapper = styled.div`
    padding: 1rem;
    width: 100%;
    max-width: 50rem;
    max-height: 740px;
    overflow-y: auto;
    border: #cccccc solid 1px;
    border-radius: 0.5rem;
    background: #ffffff;
`;

const QTitle = styled.div`
    margin: 0 0 1rem;
`;
const QAnswer = styled.button`
    margin: 0 0 0.5rem;
    padding: 0.5rem 0;
    width: 100%;
    display: block;
    border: #cccccc solid 1px;
    border-radius: 0.3rem;
`;

const MLogout = styled.div`
    position: absolute;
    right: 17px;
    top: 17px;
    background-image: ${`url(${ICLogout})`};
    background-image: -webkit-image-set(${getBgSet(ICLogoutSet)});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    width: 15px;
    height: 18px;
`;

const Mgr = (props) => {
    const { mgrList } = props;
    const [noDupMgrList, setNoDupMgrList] = useState([]);

    useEffect(() => {
        const list = props.session?.sessionMgrList.map((sessionMgr) => { return ({ ...sessionMgr, type: "좌장" }) }).concat(mgrList)?.filter((mgr) => mgr?.sessionIdx === props?.session?.idx);
        const newMgrList = [];
        for (let i in list) {
            const item = list[i];
            let dupCnt = 0;
            for (let j in newMgrList) {
                const newItem = newMgrList[j];
                if (item.lastName == newItem.lastName && item.firsttName == newItem.firsttName && item.companyName == newItem.companyName) {
                    dupCnt++;
                    break;
                }
            }
            if (dupCnt === 0 || i == 0) {
                newMgrList.push(item);
            }
        }
        setNoDupMgrList(newMgrList);
    }, []);

    return (
        <MgrWrapper>
            {noDupMgrList?.map((item, i) => (
                <Fragment key={i}>
                    <MgrItem>
                        {item.photoUrl && (<MImage src={item.photoUrl ? item.photoUrl : NoImg} />)}
                        <MInfo photo={item.photoUrl}>
                            <MName>
                                {item.type ? item.type : "연자"}
                            </MName>
                            <br />
                            <MName>
                                {item.lastName} {item.firstName}
                            </MName>
                            {item.companyName && <MCompany>{item.companyName}</MCompany>}
                            {item.description && <MDesc dangerouslySetInnerHTML={{ __html: item.description?.replace(/\n/gi, "<br/>") }} />}
                        </MInfo>
                    </MgrItem>
                    <HrDash />
                </Fragment>
            ))}
        </MgrWrapper>
    );
};

const MgrWrapper = styled.div`
    padding: 0 15px;
    overflow-y: auto;
`;
const MgrItem = styled.div`
    display: grid;
    grid-template-columns: 110px auto;
    gap: 10px;
    margin-bottom: 15px;
`;
const MImage = styled.div`
    grid-column-start: 1;
    grid-column-end: 2;
    width: 110px;
    height: 146px;

    background-image: url(${(props) => (props.src ? `${props.src}` : "")});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
`;
const MInfo = styled.div`
    grid-column-start: ${(props) => props.photo ? 2 : 1};
    grid-column-end: 3;
    padding-top: 3px;
`;
const MName = styled.div`
    color: ${(props) => props.theme.basic.color.title};
    font-family: ${(props) => props.theme.fontFamily.regular};
    font-size: 14px;
`;
const MCompany = styled.div`
    color: ${(props) => props.theme.basic.color.primary};
    font-family: ${(props) => props.theme.fontFamily.medium};
    font-size: 16px;
`;
const MDesc = styled.div`
    color: ${(props) => props.theme.basic.color.off};
    font-family: ${(props) => props.theme.fontFamily.regular};
    font-size: 14px;
`;

let apiTimer = null;

const Program = (props) => {
    const { cfg, iam } = useContext(AppContext);
    const { idx: siteIdx, event } = cfg;
    const { eventIdx } = event;
    const { idx, mgrList } = props;

    const [channelDateList, setChannelDateList] = useState(
        event.channel
            ?.map((item) => {
                return item.progressDate;
            })
            .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
    );
    const [selectDate, setSelectDate] = useState(channelDateList?.filter((item) => item === moment().format("YYYYMMDD"))[0] || channelDateList[0]);

    const [channel, setChannel] = useState([]);
    const [selectChannel, setSelectChannel] = useState(idx);
    const [sessions, setSessions] = useState([]);

    const loadAgenda = () => {
        _API.get({
            path: "/v1/site/event/agenda",
            data: {
                siteIdx,
                eventIdx,
                date: selectDate,
                channelIdx: selectChannel,
            },
        }).then((res) => {
            const { channels, sessions } = res.data;
            setChannel(channels);
            if (channels.length > 0 && !selectChannel) {
                setSelectChannel(channels[0]?.idx);
            }
            if (sessions) {
                for (let i in sessions) {
                    const item = sessions[i];
                    if (props.session?.idx == item.idx) {
                        setSessions([item]);
                        break;
                    }
                }
            }
        });
    };

    useEffect(() => {
        if (apiTimer) {
            clearTimeout(apiTimer)
        }
        apiTimer = setTimeout(() => {
            loadAgenda();
        }, 500)
    }, [props.session]);

    return (
        <ProgramWrapper>
            {sessions?.length > 0 && (
                <SessionsWrapper>
                    {sessions?.map((s, i) => (
                        <SessionItem key={i}>
                            {s.sessionType === "LECTURE" ? (
                                <STop sessionType={s.sessionType}>
                                    <STitle>
                                        {s.title}

                                        {/* <span>
                                            {s.startTime} ~ {s.endTime}
                                        </span> */}
                                    </STitle>
                                    <SMgr>{s.mgrName}</SMgr>
                                </STop>
                            ) : (
                                <LectureWrapper type={s.sessionType}>
                                    <LTime>
                                        {s.startTime} ~ {s.endTime}
                                    </LTime>
                                    <LTitle>
                                        {s.title}
                                        {s.mgrName && <LMgr>{s.mgrName}</LMgr>}
                                    </LTitle>
                                </LectureWrapper>
                            )}

                            {s.lectures?.map((l, j) => (
                                <LectureWrapper key={j}>
                                    <LTime>
                                        {l.startTime} ~ {l.endTime}
                                    </LTime>
                                    <LTitle>
                                        {l.title}

                                        {l.files?.length > 0 && <br />}
                                        {l.files?.map((f, k) => (
                                            <LFile
                                                key={k}
                                                alt={f.name}
                                                num={k + 1}
                                                onClick={() => {
                                                    window.open(f.signedUrl);
                                                }}
                                            />
                                        ))}
                                        {l.mgrName && <LMgr dangerouslySetInnerHTML={{ __html: l.mgrName?.replace(/,/gi, "<br/>") }} />}
                                    </LTitle>
                                </LectureWrapper>
                            ))}
                        </SessionItem>
                    ))}
                </SessionsWrapper>
            )}

            {!sessions?.length > 0 && !selectChannel && (
                <ProgramEmptyWrapper height={`calc(100% - 28px - 2rem - 42px)`}>
                    <H3>일정이 없습니다.</H3>
                </ProgramEmptyWrapper>
            )}
        </ProgramWrapper>
    );
};

const ProgramWrapper = styled.div`
    /* width: 100%; */
    /* height: 100%; */
    padding: 0 15px;
    /* height: ${(props) => (props.height ? props.height : `calc(100% - 28px - 2rem - 42px)`)}; */
    /* overflow-y:auto; */
    flex: 1;
    overflow: auto;
`;

const ProgramEmptyWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    /* ${(props) => (props.height ? `height:${props.height}` : `height:calc(100% - 28px - 2rem)`)} */

    height: ${(props) => (props.height ? props.height : `calc(100% - 28px - 2rem)`)};
`;

const SessionsWrapper = styled.div``;
const SessionItem = styled.div``;

const STop = styled.div`
    display: block;
    justify-content: space-between;
    padding: 15px 20px;

    border-top: 1px solid ${(props) => props.theme.basic.line.agenda};
    border-bottom: 1px solid ${(props) => props.theme.basic.line.agenda};
    background-color: ${(props) => props.theme.basic.bg.agenda};
`;

const STitle = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.basic.color.title};
    font-family: ${(props) => props.theme.fontFamily.medium};
    font-size: 13px;

    span {
        color: ${(props) => props.theme.basic.color.off};
        font-family: ${(props) => props.theme.fontFamily.regular};
        font-size: 12px;
        margin-left: 17px;
        padding-top: 3px;
    }
`;
const SMgr = styled.div`
    width: 100%;
    margin-top: 10px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    color: ${(props) => props.theme.basic.color.primary};
    font-family: ${(props) => props.theme.fontFamily.regular};
    font-size: 11px;
`;

const LectureWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0px;

    ${(props) =>
        props.type === "Break"
            ? `
                background-color: ${props.theme.basic.bg.break};

                & ${LTime}{
                    padding: 10px 0;
                }
                & ${LTitle}{
                    padding: 10px 0 10px 20px;
                    text-align: center;   

                    font-size:12px;
                    font-family: ${props.theme.fontFamily.medium};
                    color: ${props.theme.basic.color.primary};
                }
            `
            : `
                border-bottom: 1px solid ${props.theme.basic.line.agendaLecture};
            `}
`;

const LTime = styled.div`
    flex: 1;
    flex-grow: 1;
    /* border-right: 1px solid ${(props) => props.theme.basic.line.agendaLecture}; */
    padding: 17px 0;
    text-align: center;
    font-size: 11px;
    font-family: ${(props) => props.theme.fontFamily.regular};
    color: ${(props) => props.theme.basic.color.off};
`;
const LTitle = styled.div`
    flex: 1;
    font-family: ${(props) => props.theme.fontFamily.medium};
    color: ${(props) => props.theme.basic.color.primary};

    flex-grow: 2.5;
    padding: 15px 0 15px 10px;
    font-size: 13px;

    span {
        margin-left: 0px;
        margin-right: 5px;
    }
`;
const LMgr = styled.div`
    flex: 1;
    flex-grow: 1;
    justify-content: flex-start;
    padding: 3px 0;
    display: flex;
    align-items: center;

    color: ${(props) => props.theme.basic.color.primary};
    font-family: ${(props) => props.theme.fontFamily.regular};
    font-size: 12px;
`;

const LReplay = styled.div`
    flex: 1;
    flex-grow: 0.5;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    a {
        color: ${(props) => props.theme.basic.color.play};
        font-size: 10px;
        background: #000000;
        opacity: 0.6;
        border-radius: 14px;
        padding: 3px 8px;
    }
`;

const LFile = styled.span`
    width: 20px;
    height: 20px;

    border-radius: 50%;
    background-color: ${(props) => (props.theme.basic.bg[`file${props.num}`] ? props.theme.basic.bg[`file${props.num}`] : props.theme.basic.bg.file1)};
    background-image: ${`url(${ICFile})`};
    background-image: -webkit-image-set(${getBgSet(ICFileSet)});
    background-repeat: no-repeat;
    background-size: 8px 10px;
    background-position: center center;
    display: inline-block;
    line-height: 30px;
    vertical-align: middle;
    margin-left: 5px;
    cursor: pointer;
`;

const ExitSpan = styled.span`
position: absolute;

margin-left: 5px;

background-image: ${`url(${ICExit})`};
background-image: -webkit-image-set(${getBgSet(ICExitSet)});

background-repeat: no-repeat;
background-position: center 2px;
background-size: 100%;

width: 18px;
height: 37px;

/* background-size: 100%; */
`;

const QuestionSpan = styled.span`
position: absolute;

margin-left: 5px;

background-image: ${`url(${ICSellect})`};
background-image: -webkit-image-set(${getBgSet(ICSellectSet)});

background-repeat: no-repeat;
background-position: center 2px;
background-size: 100%;

width: 18px;
height: 37px;

/* background-size: 100%; */
`;
